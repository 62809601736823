/** @format */

import React, { useEffect, useRef, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import { Badge, Button, Icon, ListItemButton } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import SimpleSelectAvailDates from "../components/UI helpers/SimpleSelectAvailDates";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import VenueRating from "../components/MiscUIComponents/VenueRating";
import LikeButton from "../components/MiscUIComponents/LikeButton";
import { VenueInfoModal } from "../components/Modals/VenueInfoModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { storeSortVenuesBy } from "../features/venueFilter/venueFilterSlice";
// import FlatVenueCard from "../features/create tour/FlatVenueCard";

const ListVenues = (props) => {
  const sortVenuesBy = useSelector((state) => state.counter.sortVenuesBy);

  // const [venues, setVenues] = useState([]);
  // const [filteredVenues, setFilteredVenues] = useState([]);
  var [dateIsSet, setDateIsSet] = useState(0);
  //   const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);
  const [showVenueInfo, setShowVenueInfo] = useState(false);
  const [liked, setLiked] = useState();
  const [sortedVenues, setSortedVenues] = useState();
  const dispatch = useDispatch();
  if (props.startDate !== "" && props.endDate !== "") setDateIsSet = 1;

  useEffect(() => console.log("date is set: "), [dateIsSet]);

  useEffect(() => {
    const sortVenues = (venues) => {
      const sorted = venues.sort((a, b) => {
        if (a.venueCity < b.venueCity) {
          return -1;
        } else if (a.venueCity > b.venueCity) {
          return 1;
        } else {
          return 0;
        }
      });
      return sorted;
    };
    sortVenuesBy && setSortedVenues(sortVenues(props.tempVenues));
  }, [props.tempVenues]);

  const handleClick = (venue) => {
    console.log(venue);
  };

  //   const handleSortChange = (e) => {
  //     const sortValue = e.target.value;
  //     dispatch(storeSortVenuesBy(sortValue));
  //   };

  //   console.log(props.tempVenues);

  // return loaded ? (

  const toggleSort = () => {
    return {
      /* <div style={{ position: "fixed", right: "10px", top: "0px", zIndex: "3" }}>
        <ToggleButtonGroup value={sortVenuesBy} onChange={handleSortChange} exclusive aria-label="text alignment" size="small">
          <ToggleButton value="venueCity" aria-label="left aligned">
            City
          </ToggleButton>
          <ToggleButton value="capacity" aria-label="justified">
            Capacity
          </ToggleButton>
        </ToggleButtonGroup>
      </div> */
    };
  };

  // return
  // <div>
  //   {props.tempVenues.map((venue) => (
  // <FlatVenueCard Venues={venue}/> )
  // </div>

  // )};

  return (
    <div>
      {props.tempVenues.map((venue) => (
        <div
          style={{
            width: "100%",
            height: "100px",
            background: "#11111199",
            borderRadius: "50px",
            backdropFilter: "blur(10px)",
            marginBottom: "10px",
          }}
        >
          <Badge color="info" badgeContent={venue.numberOfAvails - 1}>
            {/* <ListItemButton
              style={{ width: "100%" }}
              onClick={() => {
                handleClick(venue);
              }}
            > */}
            <div
              style={{
                width: "100px",
                height: "100px",
                overflow: "hidden",
                // borderRadius: "50px 0px 0px 50px",
                borderRadius: "50px",
                float: "left",
                marginLeft: "0px",
                marginRight: "15px",
                marginTop: "0px",
                border: "2px solid #777",
              }}
            >
              <img src={venue.venueImage} width="auto" height="130px" />
            </div>

            <div>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "900",
                  letterHeight: "10px",
                  marginBottom: "0px",
                  height: "22px",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                {venue.venue}
              </p>

              <VenueRating />
              <div style={{ fontSize: "12px" }}>
                <PlaceIcon />
                {venue.venueCity}
                <PeopleIcon />
                {venue.capacity}
                <PointOfSaleIcon />
                {venue.price} kr
              </div>
            </div>
            <div style={{ float: "right", marginRight: "20px" }}>
              <LikeButton liked={setLiked} />
            </div>
            {/* </ListItemButton>{" "} */}
            <div style={{ float: "right", marginRight: "20px" }}>
              <LikeButton liked={setLiked} />
            </div>
          </Badge>
        </div>
      ))}
    </div>
  );
};

export default connect()(ListVenues);
