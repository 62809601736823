/**
 * Old FilterVenues
 *
 * @format
 */

/** @format */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListVenues from "../../helpers/ListVenues";
import NuListVenues from "../../helpers/NuListVenues";
import { increment, decrement, storeAvailableVenuesAndDates } from "./venueFilterSlice";

const FilterVenues = (props) => {
  const count = useSelector((state) => state.counter.count);
  const capMin = useSelector((state) => state.counter.capMin);
  const capMax = useSelector((state) => state.counter.capMax);
  const startDate = useSelector((state) => state.counter.startDate);
  const endDate = useSelector((state) => state.counter.endDate);
  const chosenDateRange = useSelector((state) => state.counter.chosenDateRange);
  const prefDays = useSelector((state) => state.counter.prefDays);
  const venues = useSelector((state) => state.counter.venues);
  const bookings = useSelector((state) => state.counter.bookings);

  //   const sortVenuesBy = useSelector((state) => state.counter.sortVenuesBy);
  // const tempVenues = useSelector((state) => state.counter.tempVenues);
  console.log(bookings);
  // console.log(venues);

  var prefDaysInIndex = [];

  prefDays.map((day) => {
    if (day == "Sunday") prefDaysInIndex.push(0);
    if (day == "Monday") prefDaysInIndex.push(1);
    if (day == "Tuesday") prefDaysInIndex.push(2);
    if (day == "Wednesday") prefDaysInIndex.push(3);
    if (day == "Thursday") prefDaysInIndex.push(4);
    if (day == "Friday") prefDaysInIndex.push(5);
    if (day == "Saturday") prefDaysInIndex.push(6);
  });

  // const preferredDaysIndex = 0;
  // const daysConsidered = [];
  // function checkDay(d){
  // 	var dayIndex = new Date(d).getDay();
  // 	preferredDaysIndex.forEach(element => {
  // 		if(dayIndex == element) daysConsidered.push(d);
  // 	});
  // log(preferredDaysIndex);
  // }

  var availsTemp = [];
  var availableDates = [];
  availableDates.length = 0;
  var availableDatesOnPrefDays = [];
  // var filteredBookings = []
  // var filteredAvailableDates = []

  const dispatch = useDispatch();
  var filteredByCap = [1, 2, 3];
  var filteredByDateRange = [1, 2, 3];
  var unconfirmedDates = [];
  // var nextDate = []
  const [filteredVenues, setFilteredVenues] = useState([
    { venue: "Arenan", capacity: 500 },
    { venue: "Scenen", capacity: 200 },
  ]);
  const [tempVenues, setTempVenues] = useState([]);
  // const [ loaded, setLoaded ] = useState(false)

  var tempList = [];
  tempList.length = 0;
  var numberOfAvails = 0;
  let theList = [];

  const filterByCapacity = (listToFilter) => {
    const venuesFiltered = listToFilter.filter((listToFilter) => listToFilter.capacity >= capMin && listToFilter.capacity <= capMax);
    return venuesFiltered;
  };

  // Kolla vilka lediga datum som finns på respektive venue
  const filterByDateRange = (currentVenues) => {
    var filteredBookings = [];
    var bookedDates = [];

    // konfirmerade bokningar inom datumscope
    const bookingsWithinRange = bookings.filter(
      (bookings) => bookings.date >= startDate && bookings.date <= endDate && bookings.status == "Confirmed"
    );

    // ännu okonfirmerade bokningar inom datumscope
    const unconfirmedBookingsWithinRange = bookings.filter(
      (bookings) => bookings.date >= startDate && bookings.date <= endDate && bookings.status == "Awaiting Artist"
      // (bookings) => bookings.date >= startDate && bookings.date <= endDate
    );

    // Sätt bookedDates för aktuell venue
    unconfirmedDates = [1, 2, 3];
    unconfirmedBookingsWithinRange.map((request) => {
      unconfirmedDates.push(request.date);
    });

    // requests, ännu obesvarade

    // Kolla bokningar för respektive venue
    currentVenues.map((venue) => {
      const bookingsOnListedVenues = bookingsWithinRange.filter((bookingsWithinRange) => bookingsWithinRange.venueid == venue.venueid);
      // console.log('🚩  🚩  🚩',venue.venue, bookingsOnListedVenues, venue.venueid)
      // Sätt bookedDates för aktuell venue
      bookedDates.length = 0;
      bookingsOnListedVenues.map((booking) => {
        if (booking.venueid == venue.venueid) bookedDates.push(booking.date);
      });
      // console.log(venue.venue, bookedDates)

      //Sätt available dates
      availsTemp.length = 0;
      availsTemp = chosenDateRange.filter(function (e) {
        return this.indexOf(e) < 0;
      }, bookedDates);
      availableDates = [""];

      // Sätt available dates utifrån preferred days
      availsTemp.map((day) => {
        // console.log('CHECK DAY FOR', day, availableDates)
        checkDay(day);
      });
      numberOfAvails = availableDates.length;
      //  availsTemp = availableDates

      function checkDay(d) {
        // console.log('DAY', d)
        const dayIndex = new Date(d).getDay();
        prefDaysInIndex.map((exists) => {
          // if(dayIndex == exists) availableDates.push(d);
          dayIndex == exists ? availableDates.push(d) : console.log();
          // console.log('availableDates', venue.venue, availableDates)
        });
      }

      //Sätt available dates on preferred days
      // availableDatesOnPrefDays =  availableDates.filter(checkDay(availableDates));
      // availableDatesOnPrefDays =  checkDay(availableDates);
      // console.log(availableDatesOnPrefDays);

      // Fyll på listan med venues, avails, requested mm
      // theList.push({venue:venue.venue, capacity:venue.capacity, numberOfAvails:numberOfAvails, avails:availableDates, unconfirmedDates:unconfirmedDates, priceWknd:venue.priceWknd, priceWkday:venue.priceWkday, venueLat:venue.venueLat, venueLng:venue.venueLng});

      // console.log('FILTERVENUS', {
      // 	...venue,
      // 	numberOfAvails: numberOfAvails,
      // 	avails: availableDates,
      // }
      const listToAdd = {
        ...venue,
        numberOfAvails: numberOfAvails,
        avails: availableDates,
      };
      theList = [...theList, listToAdd];
      //   console.log("the list inside inside: ", theList);
      // theList.push({
      // 	...venue,
      // 	numberOfAvails: numberOfAvails,
      // 	avails: availableDates,
      // });
    });
    return theList;
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(async () => {
      filteredByCap = await filterByCapacity(venues);
      filteredByDateRange = await filterByDateRange(filteredByCap);
      // filteredByAvail = filterByDateRange(filteredByDateRange);
      // dispatch(storeAvailableVenuesAndDates(filteredByDateRange))
      // console.log('filteredByDateRange',filteredByDateRange)
      setFilteredVenues(await filteredByCap);
      setTempVenues(await filteredByDateRange);
    }, 500);
    return () => (mounted = false);
  }, [capMax, capMin, startDate, endDate, prefDays]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("* * * * * * * * >>", tempVenues);
  //   }, 1000);

  // dispatch(storeAvailableVenuesAndDates(tempVenues));
  // props.numberOfVenues(tempVenues.length);
  // console.log('tempVenues', tempVenues);
  // }, [tempVenues]);

  useEffect(() => {
    dispatch(storeAvailableVenuesAndDates(tempVenues));
    props.numberOfVenues(tempVenues.length);
    // console.log('tempVenues', tempVenues);
  }, [tempVenues]);

  return (
    <section>
      <div>
        {/* <ListVenues
          capMin={capMin}
          capMax={capMax}
          startDate={startDate}
          endDate={endDate}
          venues={filteredVenues}
          tempVenues={tempVenues}
          prefDays={prefDays}
        /> */}

        <NuListVenues
          capMin={capMin}
          capMax={capMax}
          startDate={startDate}
          endDate={endDate}
          venues={filteredVenues}
          tempVenues={tempVenues}
          prefDays={prefDays}
        />
      </div>
      {/* {console.log(bookings)} */}
    </section>
  );
};

export default FilterVenues;
