/** @format */

import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { set } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import CheckRole from "../components/Authentication/CheckRole";
import SidebarOwner from "../components/Sidebar/SidbarOwner";
import VenueSpeedDial from "../components/Sidebar/VenueSpeedDial";
import { userObj } from "../dbObjects.js/dbUser";
import DisplayObject from "../helpers/displayObject";
import GeneralForm from "../helpers/GeneralForm";

import GetArtists from "../helpers/GetArtists";
import { listenToOwnedVenuesBookings } from "../helpers/Listeners";
import TimeClockAmPm from "../helpers/TestClock";
import OwnerSignup from "./OwnerSignup";

const Owner = () => {
  // const userRole = useSelector((state) => state.counter.userRole);
  // const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  // const currentVenue = useSelector((state) => state.counter.currentVenue);

  // THIIS!!
  // userRole != "owner" ? navigate("/Home") : console.log("Access granted");
  // if (userRole == "none") navigate("/Home");

  // listenToOwnedVenuesBookings();
  //   useEffect(() => {
  //     listenToOwnedVenuesBookings();
  //   }, []);

  return (
    <div>
      {/* THIS ! ! ! */}
      {/* <CheckRole /> */}
      {/* <GetArtists /> */}
      {/* {userRole && userRole != "owner" ? navigate("/Home") : console.log("Access granted")} */}
      {/*DESKTOP*/}
      <Grid item container xs={12} p={{ xs: 0, sm: 4 }} spacing={0} style={{ height: "100vh", overflow: "none" }}>
        {/* <div style={{ background: "blue", zIndex: "1000000", width: "500px", backdropFilter: "blur(10px)" }}>
          <DisplayObject data={currentVenue.bookings} />
        </div> */}
        <Grid item sx={{ display: { width: "200px", xs: "none", md: "block", sm: "block" } }} className="sidebar">
          <SidebarOwner />
        </Grid>

        <Grid
          p={{ xs: 0, sm: 0 }}
          item
          mt={3}
          xs={12}
          sm={12}
          // md={8}
          // lg={8}
          id="outlet"
          marginLeft={{ xs: 0, md: "210px" }}
          sx={{
            height: "50vh",
            overflow: "scroll",
            zIndex: "0",
            background: "",
            // marginLeft: "200px",
          }}
          className="outlet"
        >
          <Outlet />
        </Grid>
      </Grid>

      {/*MOBILE*/}
      <Grid
        item
        sx={{
          display: { width: "200px", xs: "block", md: "none" },
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
      >
        <VenueSpeedDial />
      </Grid>
    </div>
  );
};

export default Owner;
