/** @format */

import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Slider, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const BasicInfo = ({ handleChange, formData }) => {
  const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentYear = new Date().getFullYear() - 1;

  useEffect(() => {
    handleChange("year", currentYear);
  }, [currentYear]);

  return (
    <div>
      {/* <h7>Basic Info</h7> */}
      <Grid item flexDirection="row" style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
        <Select value={formData.year} defaultValue={currentYear} onChange={(e) => handleChange("year", e.target.value)} size="small">
          {years.map((year) => (
            <MenuItem value={year}>{year === currentYear ? <b style={{ color: "#999" }}>{year}</b> : <>{year}</>}</MenuItem>
          ))}
        </Select>
        <TextField
          size="small"
          style={{ width: "150px" }}
          label="Square meter area of the venue"
          type="number"
          defaultValue={formData.propertySize ? formData.propertySize : 0}
          value={formData.propertySize}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChange("propertySize", e.target.value)}
          InputProps={{ style: { background: "transparent" }, endAdornment: <InputAdornment position="end">m2</InputAdornment> }}
        />
        <TextField
          size="small"
          style={{ width: "150px" }}
          label="Visitor capacity"
          defaultValue={currentVenue.capacity ? currentVenue.capacity : 0}
          value={formData.capacity}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChange("capacity", e.target.value)}
        />
      </Grid>
    </div>
  );
};

export default BasicInfo;
