import React, { useState } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const DAYS = [
	{
		key: 'monday',
		label: 'Monday',
	},
	{
		key: 'tuesday',
		label: 'Tuesday',
	},
	{
		key: 'wednesday',
		label: 'Wednesday',
	},
	{
		key: 'thursday',
		label: 'Thursday',
	},
	{
		key: 'friday',
		label: 'Friday',
	},
	{
		key: 'saturday',
		label: 'Saturday',
	},
	{
		key: 'sunday',
		label: 'Sunday',
	},
];

// const StyledToggleButtonGroup = withStyles((theme) => ({
// 	grouped: {
// 		margin: theme.spacing(2),
// 		padding: theme.spacing(0, 1),
// 		'&:not(:first-child)': {
// 			border: '1px solid',
// 			borderColor: '#692B7C',
// 			borderRadius: '50%',
// 		},
// 		'&:first-child': {
// 			border: '1px solid',
// 			borderColor: '#692B7C',
// 			borderRadius: '50%',
// 		},
// 	},
// }))(ToggleButtonGroup);

// const StyledToggle = withStyles({
// 	root: {
// 		color: '#692B7C',
// 		'&$selected': {
// 			color: 'white',
// 			background: '#692B7C',
// 		},
// 		'&:hover': {
// 			borderColor: '#BA9BC3',
// 			background: '#BA9BC3',
// 		},
// 		'&:hover$selected': {
// 			borderColor: '#BA9BC3',
// 			background: '#BA9BC3',
// 		},
// 		minWidth: 32,
// 		maxWidth: 32,
// 		height: 32,
// 		textTransform: 'unset',
// 		fontSize: '0.75rem',
// 	},
// 	selected: {},
// })(ToggleButton);

export const DaysToggler = (props) => {
	const [days, setDays] = useState([0, 2, 3]);
	return (
		<>
			<ToggleButtonGroup
				size='small'
				arial-label='Days of the week'
				value={props.formValues.prefDays}
				// onChange={(event, value) => setDays(value)}
				onChange={(event, value) =>
					props.setFormValues({
						...props.formValues,
						prefDays: value,
					})
				}
			>
				{DAYS.map((day, index) => (
					<ToggleButton
						key={day.key}
						value={index}
						aria-label={day.key}
					>
						{day.label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</>
	);
};
