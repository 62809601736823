/** @format */

import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Slider, TextField } from "@mui/material";
import React from "react";
import DisplayObject from "../../helpers/displayObject";
import { numberFormatted } from "../../helpers/Numbers";
import PieChartCenteredLabel from "../UI helpers/PieChartCenteredLabel";

const Waste = ({ handleChange, formData }) => {
  const width = "300px";
  const height = "auto";

  return (
    <div className="visualData">
      <h7>Waste</h7>
      <div className="vdPie">
        <PieChartCenteredLabel
          width={width}
          height={height}
          data={[
            { value: formData.recycledMetal ? formData.recycledMetal : "0", label: "Metal", color: "#00ffd499" },
            { value: formData.recycledPlastic, label: "Plastic", color: "#00ffd433" },
            { value: formData.recycledPaper, label: "Paper", color: "#ffa50044" },
            { value: formData.recycledElectric, label: "Electric", color: "#ffa50099" },
            { value: formData.mixedHousehold, label: "Mixed", color: "#00ffd4" },
            { value: formData.landfilledWaste, label: "Landfilled", color: "#ffa500" },
          ]}
          label="Waste"
        />
      </div>
      {/* <div flexDirection="row" style={{ display: "flex", gap: "15px", flexWrap: "wrap" }} className="dataInput"> */}
      <Grid container>
        <Grid item style={{ marginBottom: "10px" }}>
          <TextField
            size="small"
            label="Recycled metal waste"
            type="number"
            variant="outlined"
            defaultValue={0}
            value={formData.recycledMetal ? formData.recycledMetal : 0}
            onChange={(e) => handleChange("recycledMetal", e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "160px", marginRight: "10px" }}
          />
          {formData["recycledMetalCO2e"] && <div className="CO2eDisplay">CO2e: {numberFormatted(formData["recycledMetalCO2e"])} kg</div>}
        </Grid>
        <Grid item style={{ marginBottom: "10px" }}>
          <TextField
            size="small"
            label="Recycled plastic waste"
            type="number"
            variant="outlined"
            value={formData.recycledPlastic ? formData.recycledPlastic : 0}
            onChange={(e) => handleChange("recycledPlastic", e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "160px", marginRight: "10px" }}
          />
          {formData["recycledPlasticCO2e"] && (
            <div className="CO2eDisplay">CO2e: {numberFormatted(formData["recycledPlasticCO2e"])} kg</div>
          )}
        </Grid>
        <Grid item style={{ marginBottom: "10px" }}>
          <TextField
            size="small"
            label="Recycled paper waste"
            type="number"
            variant="outlined"
            value={formData.recycledPaper ? formData.recycledPaper : 0}
            onChange={(e) => handleChange("recycledPaper", e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "160px", marginRight: "10px" }}
          />
          {formData["recycledPaperCO2e"] && <div className="CO2eDisplay">CO2e: {numberFormatted(formData["recycledPaperCO2e"])} kg</div>}
        </Grid>
        <Grid item style={{ marginBottom: "10px" }}>
          {" "}
          <TextField
            size="small"
            label="Recycled electric waste"
            type="number"
            variant="outlined"
            value={formData.recycledElectric ? formData.recycledElectric : 0}
            onChange={(e) => handleChange("recycledElectric", e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "160px", marginRight: "10px" }}
          />
          {formData["recycledElectricCO2e"] && (
            <div className="CO2eDisplay">CO2e: {numberFormatted(formData["recycledElectricCO2e"])} kg</div>
          )}
        </Grid>
        <Grid item style={{ marginBottom: "10px" }}>
          <TextField
            size="small"
            label="Mixed household waste"
            type="number"
            variant="outlined"
            value={formData.mixedHousehold ? formData.mixedHousehold : 0}
            onChange={(e) => handleChange("mixedHousehold", e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "160px", marginRight: "10px" }}
          />
          {formData["mixedHouseholdCO2e"] && <div className="CO2eDisplay">CO2e: {numberFormatted(formData["mixedHouseholdCO2e"])} kg</div>}
        </Grid>
        <Grid item style={{ marginBottom: "10px" }}>
          <TextField
            size="small"
            label="Landfilled waste"
            type="number"
            variant="outlined"
            value={formData.landfilledWaste ? formData.landfilledWaste : 0}
            onChange={(e) => handleChange("landfilledWaste", e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "160px", marginRight: "10px" }}
          />
          {formData["landfilledWasteCO2e"] && (
            <div className="CO2eDisplay">CO2e: {numberFormatted(formData["landfilledWasteCO2e"])} kg</div>
          )}
        </Grid>
      </Grid>
      {/* </div> */}
    </div>
  );
};

export default Waste;
