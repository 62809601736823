/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { storeCurrentArtist, storePrefDays } from "../features/venueFilter/venueFilterSlice";
import { listenAllBookings } from "./Listeners";
import { current } from "@reduxjs/toolkit";
import { Autocomplete, Avatar, Button, Grid, TextField } from "@mui/material";
import { ka } from "date-fns/locale";
import { Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import GetArtists from "./GetArtists";
import DisplayObject from "./displayObject";

export default function FindArtist({ setEvent }) {
  const [age, setAge] = React.useState("");
  const [artistList, setArtistList] = useState([]);
  const [newEvent, setNewEvent] = useState();
  const artists = useSelector((state) => state.counter.artists);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const prefDays = useSelector((state) => state.counter.prefDays);

  const dispatch = useDispatch();
  const handleChange = async (event) => {
    await dispatch(storeCurrentArtist(artists[artists.findIndex((x) => x.id === event.target.value)]));
    setArtistName(event.target.value.artist);
    // dispatch(storePrefDays("Monday"));
    // setPrefDays();
  };
  const [value, setValue] = useState("");
  const [artistName, setArtistName] = useState("Enter Artist or Event Name");

  // Uncomment the useEffect below to see the original code showing the artist list
  // useEffect(() => {
  //   //Remove isOfficial:false objects (only show real artists)
  //   let onlyOfficialArtists = artists.filter((item) => item.isOfficial !== false);

  //   //set the artistList content
  //   let tmp = onlyOfficialArtists.map((artist) => ({
  //     label: artist.artist,
  //     artistid: artist.artistid,
  //     image: artist.image,
  //   }));

  //   //Sort the artistlist
  //   tmp.sort((a, b) => a.label.localeCompare(b.label));

  //   //set the artistsList
  //   setArtistList(tmp); //This is the original line
  // }, [artists]);

  const handleInputChange = (event, newValue) => {
    newValue !== null && setValue(newValue);
    let selectedArtist = artistList.find((artist) => artist.label === newValue);
    if (selectedArtist) {
      const artistid = selectedArtist.artistid;
      console.log(selectedArtist);
      setNewEvent(null);
      setEvent({ artist: newValue, ...selectedArtist });
      // Do something with the artistid
      console.log("Selected artistid:", artistid);
    } else {
      setNewEvent(newValue);
      setEvent({ artist: newValue, artistid: null, image: null });
    }
  };

  console.log("artists:", artists);

  return (
    <div>
      {/* <GetArtists /> */}
      {artistList && (
        <Autocomplete
          value={value}
          onChange={(event, newValue) => newValue !== null && setValue(newValue)}
          inputValue={value}
          onInputChange={handleInputChange}
          options={artistList}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Artist or event name" variant="outlined" />}
        />
      )}
      {console.log("newEvent", newEvent)}
    </div>
  );
}
