/** @format */

export const bookingsObj = [
  { section: "Document ID", type: "Section" },
  {
    section: "Document",
    placeholder: "Document ID",
    label: "Document ID",
    id: "documentid",
    type: "text",
    disabled: true,
  },
  // {
  //   section: " ",
  //   name: "searchartist",
  //   placeholder: "Find Artist",
  //   id: "eventArtistInput",
  //   type: "text",
  //   priority: 1000,
  //   size: "small",
  // },
  {
    section: "Type of event",
    name: "Type of event",
    label: "Type of event",
    placeholder: "Type of event",
    id: "typeOfEvent",
    type: "select",
    options: ["Concert small", "Concert full", "Theatrical small", "Theatrical full", "Private event"],
    priority: 1000,
    size: "small",
    style: { width: "200px" },
  },
  {
    section: "Artist",
    type: "Section",
  },
  {
    section: "Artist",
    name: "artist",
    placeholder: "Artist",
    label: "Artist",
    id: "artist",
    type: "search",
    priority: 1,
    size: "small",
    search: true,
    // source: {searchableArtists},
  },
  {
    section: "Artist",
    name: "artistid",
    label: "Artist ID",
    id: "artistid",
    source: "Artists",
    type: "text",
    disabled: true,
    priority: 1000,
    size: "small",
  },
  {
    section: "Artist",
    name: "artistImage",
    label: "Artist Image",
    id: "artistImage",
    source: "Artists",
    type: "imageUpload",
    disabled: true,
    priority: 10000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Artist",
    name: "artistLng",
    label: "Artist Longitude",
    id: "artistLng",
    source: "Artists",
    type: "text",
    disabled: true,
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Artist",
    name: "artistLat",
    label: "Artist Latitude",
    id: "artistLat",
    source: "Artists",
    type: "text",
    disabled: true,
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Status",
    type: "Section",
  },
  {
    section: "Status",
    // name:'status',
    label: "Status",
    placeholder: "Status",
    id: "status",
    source: "Booking",
    disable: true,
    options: ["Pending Request", "Awaiting Artist", "Confirmed"],
    type: "select",
    priority: 2,
    size: "small",
    xs: 12,
    sm: 6,
    style: { width: "200px" },
  },
  {
    section: "Event",
    type: "Section",
  },
  {
    section: "Date of Event",
    label: "Date of Event",
    placeholder: "Date of Event",
    id: "date",
    type: "text",
    disabled: "false",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Pricing",
    name: "price",
    placeholder: "Price",
    label: "Price",
    id: "price",
    source: "Booking",
    type: "number",
    priority: 4,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Pricing",
    name: "discount",
    placeholder: "Discount",
    label: "Discount",
    defaultValue: 0,
    id: "discount",
    type: "number",
    priority: 4,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Ticket Price",
    name: "tickets",
    label: "Ticket Price",
    placeholder: "Ticket Price",
    id: "tickets",
    type: "number",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Rider",
    type: "Section",
  },
  {
    section: "Rider",
    name: "rider",
    placeholder: "Upload Rider",
    id: "rider",
    type: "fileUpload",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Customer",
    type: "Section",
  },
  {
    section: "Contact Agent",
    name: "agent",
    label: "Agent Name",
    placeholder: "Agent Name",
    id: "agent",
    type: "text",
    priority: 3,
    size: "small",
    xs: 12,
    sm: 6,
    // onChange: (e) => handleChange('agent', e.target.value),
  },
  {
    section: "Contact Agent",
    name: "agentid",
    placeholder: "Agent ID",
    id: "agentid",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Contact Agent",
    name: "agentPhone",
    label: "Phone",
    placeholder: "Telephone Agent",
    id: "agentPhone",
    type: "phone",
    priority: 3,
  },
  {
    section: "Contact Agent",
    name: "agentEmail",
    label: "Email Agent",
    placeholder: "Email Agent",
    id: "agentEmail",
    type: "text",
    priority: 3,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Contact Agent",
    name: "agentStreetAddress",
    label: "Street Address",
    placeholder: "Street Address",
    id: "agentStreetAddress",
    source: "Agents",
    type: "text",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Contact Agent",
    name: "agentZipCode",
    label: "Zip Code",
    placeholder: "Zip Code",
    id: "agentZipCode",
    source: "Agents",
    type: "text",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Contact Agent",
    name: "agentCity",
    label: "City",
    placeholder: "City",
    id: "agentCity",
    source: "Agents",
    type: "text",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Contact Agent",
    name: "agentCountry",
    label: "Country",
    placeholder: "Country",
    id: "agentCountry",
    source: "Agents",
    type: "text",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Corporate ID Number",
    name: "corporateId",
    label: "Corporate ID Number",
    placeholder: "Corporate ID Number",
    id: "corporateId",
    type: "text",
    source: "Agents",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Account",
    type: "Section",
  },
  {
    section: "Account",
    name: "userid",
    placeholder: "UserID",
    id: "uid",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Account",
    name: "bookingid",
    label: "Booking ID",
    placeholder: "Booking ID",
    id: "bookingid",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Account",
    name: "venue",
    label: "Venue",
    placeholder: "Venue",
    id: "venue",
    ogId: "venue",
    type: "text",
    source: "Venues",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Account",
    name: "venueid",
    placeholder: "VenueID",
    id: "venueid",
    ogId: "venueid",
    type: "text",
    source: "Venues",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Account",
    name: "userid",
    placeholder: "UserID",
    id: "uid",
    class: "form",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Account",
    name: "product",
    placeholder: "Product",
    id: "product",
    type: "text",
    source: "Venues",
    disabled: "true",
    priority: 4,
  },
  {
    section: "Location",
    type: "Section",
  },
  {
    section: "Location Venue",
    name: "venueStreetAddress",
    label: "Street Address",
    placeholder: "Street Address",
    id: "venueStreetAddress",
    ogId: "streetAddress",
    source: "Venues",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Location Venue",
    name: "venueZipCode",
    label: "Zip Code",
    placeholder: "Zip Code",
    id: "venueZipCode",
    ogId: "zipCode",
    source: "Venues",
    type: "number",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Location Venue",
    name: "venueCity",
    label: "City",
    placeholder: "City",
    id: "venueCity",
    ogId: "city",
    source: "Venues",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Location Venue",
    name: "venueCountry",
    label: "Country",
    placeholder: "Country",
    id: "venueCountry",
    ogId: "country",
    source: "Venues",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Location Venue",
    name: "venueImage",
    label: "Venue Image",
    placeholder: "Venue Image",
    id: "venueImage",
    ogId: "image",
    source: "Venues",
    type: "text",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Location Venue",
    name: "venueLat",
    label: "Latitude",
    placeholder: "Latitude",
    id: "venueLat",
    ogId: "lat",
    source: "Venues",
    type: "number",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Location Venue",
    name: "venueLng",
    label: "Longitude",
    placeholder: "Longitude",
    id: "venueLng",
    ogId: "lng",
    source: "Venues",
    type: "number",
    disabled: "true",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  // {
  // 	section:'numbers',
  // 	name:'slots',
  // 	placeholder:'Number of slots',
  // 	id:'slots',
  // 	class:'form',
  // 	type:'number'
  // },
  {
    section: "submitta",
    name: "submit",
    placeholder: "Submit this Venue",
    id: "send",
    class: "form col-md-3",
    type: "submit",
    priority: 1000,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Standard Running Times",
    type: "Section",
  },
  {
    section: "Standard Running Times",
    placeholder: "Load In Weekdays",
    label: "Load In Weekdays",
    id: "loadInWeekdays",
    isWeekend: "no",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "14:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Load In Weekends",
    label: "Load In Weekends",
    id: "loadInWeekends",
    isWeekend: "yes",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "14:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Soundcheck Weekdays",
    label: "Soundcheck Weekdays",
    id: "soundcheckWeekdays",
    isWeekend: "no",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "15:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Soundcheck Weekends",
    label: "Soundcheck Weekends",
    id: "soundcheckWeekends",
    isWeekend: "yes",
    source: "Venues",
    type: "time",
    priority: 10,
    defaultValue: "15:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Doors",
    label: "Doors",
    id: "doors",
    isWeekend: "both",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "18:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Show",
    label: "Show",
    id: "show",
    isWeekend: "both",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "20:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Curfew Weekdays",
    label: "Curfew Weekdays",
    id: "curfewWeekdays",
    isWeekend: "no",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "23:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Curfew Weekends",
    label: "Curfew Weekends",
    id: "curfewWeekends",
    isWeekend: "yes",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "01:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Load In",
    label: "Load In",
    id: "loadIn",
    isWeekend: "yes",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "01:00",
  },
  {
    section: "Standard Running Times",
    placeholder: "Load Out",
    label: "Load Out",
    id: "loadOut",
    isWeekend: "yes",
    type: "time",
    source: "Venues",
    priority: 10,
    defaultValue: "01:00",
  },
  {
    section: "Booking",
    type: "Section",
  },
  {
    section: "Booking",
    label: "Security Details",
    placeholder: "Security Details",
    id: "securityDetails",
    type: "text",
    disabled: "true",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Booking",
    label: "Venue confirmed",
    placeholder: "Venue confirmed",
    id: "venueAcceptedDate",
    type: "text",
    disabled: "true",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  {
    section: "Booking",
    label: "Agent confirmed",
    placeholder: "Agent confirmed",
    id: "agentAcceptedDate",
    type: "text",
    disabled: "true",
    priority: 10,
    size: "small",
    xs: 12,
    sm: 6,
  },
  { section: "", type: "Section" },
  {
    id: "update",
    type: "update",
    label: "Save",
    collection: "Testing",
    documentId: "bookingid",
  },
  {
    id: "create",
    type: "create",
    label: "Add",
    collection: "Testing",
    documentId: "bookingid",
  },
  {
    id: "delete",
    type: "delete",
    label: "Delete Booking",
    collection: "Testing",
    documentId: "bookingid",
  },
];
