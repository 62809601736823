/** @format */

import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import SidebarVenue from "../components/Sidebar/SidebarVenue";
import { BrowserRouter as Router, Routes, Route, Link, Outlet } from "react-router-dom";
import GetArtists from "../helpers/GetArtists";
import CheckUserRole from "../components/Authentication/CheckUserRole";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GetBookings from "../helpers/GetBookings";
import DnDResource from "../components/Sidebar/DnDResource";
import NewCal from "../components/Sidebar/NewCal";
import ReloadBookings from "../helpers/ReloadBookings";
import StepperTest from "../components/Forms/StepperTest";
import { reloadCalendarBookings } from "../helpers/Listeners";
import SpeedDial from "../components/Sidebar/VenueSpeedDial";
import VenueSpeedDial from "../components/Sidebar/VenueSpeedDial";
import SelectVenue from "../helpers/SelectVenue";

const Venue = () => {
  const userRole = useSelector((state) => state.counter.userRole);
  const navigate = useNavigate();

  // THIIS!!
  // userRole != 'editor' ? navigate("/Home") : console.log('Access granted')

  return (
    <div>
      <GetArtists />
      {/*DESKTOP*/}
      <Grid container xs={12} spacing={0}>
        <Grid
          item
          sx={{
            display: { width: "250px", xs: "none", md: "block" },
          }}
          className="sidebar"
        >
          <SidebarVenue />
        </Grid>

        <Grid item mt={3} xs={12} md={10} lg={10} id="outlet" sx={{ height: "85vh", overflow: "scroll", zIndex: "0" }} className="outlet">
          <Outlet />
        </Grid>
      </Grid>
      {/*MOBILE*/}
      <Grid
        item
        sx={{
          display: { width: "250px", xs: "block", md: "none" },
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
      >
        <VenueSpeedDial />
        {/* <SelectVenue className="SVonTop" /> */}
      </Grid>
    </div>
  );
};

export default Venue;
