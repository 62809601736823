/** @format */

import { FormControl, Grid, InputLabel, MenuItem, Select, Slider, TextField } from "@mui/material";
import React from "react";
import DisplayObject from "../../helpers/displayObject";
import { numberFormatted } from "../../helpers/Numbers";

const HeatingAndFuel = ({ handleChange, formData }) => {
  return (
    <div className="visualData">
      <h7>Heating and fuel</h7>
      <Grid item flexDirection="row" style={{ display: "flex", gap: "15px" }} className="dataInput">
        <FormControl variant="outlined">
          <InputLabel shrink>Type of heating</InputLabel>
          <Select
            value={formData.heatingUsed ? formData.heatingUsed : 0}
            onChange={(e) => handleChange("heatingUsed", e.target.value)}
            style={{ minWidth: "150px" }}
            size="small"
          >
            <MenuItem value="dont know">I don't know</MenuItem>
            <MenuItem value="not">Not applicable</MenuItem>
            <MenuItem value="district heating">District heating</MenuItem>
            <MenuItem value="natural gas">Natural gas</MenuItem>
            <MenuItem value="electricity heating">Electricity heating</MenuItem>
            <MenuItem value="lng">LNG</MenuItem>
            <MenuItem value="lpg">LPG</MenuItem>
            <MenuItem value="cng">CNG</MenuItem>
            <MenuItem value="burning oil">Burning oil</MenuItem>
            <MenuItem value="diesel">Diesel</MenuItem>
          </Select>
        </FormControl>
        {formData.heatingUsed !== ("dont know" || "not") && (
          <>
            <TextField
              size="small"
              label="Quantity"
              type="number"
              value={formData.heatingQuantity ? formData.heatingQuantity : 0}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange("heatingQuantity", e.target.value)}
              style={{ width: "100px" }}
            />

            <FormControl variant="outlined">
              <InputLabel shrink>Unit</InputLabel>
              <Select
                size="small"
                label="Unit"
                value={formData.heatingUnit ? formData.heatingUnit : 0}
                onChange={(e) => handleChange("heatingUnit", e.target.value)}
                style={{ minWidth: "50px" }}
              >
                <MenuItem value="tonnes">Tonnes</MenuItem>
                <MenuItem value="litres">Litres</MenuItem>
                <MenuItem value="kWh">KWh</MenuItem>
              </Select>
            </FormControl>
            {formData["heatingQuantityCO2e"] && (
              <div className="CO2eDisplay">CO2e: {numberFormatted(formData["heatingQuantityCO2e"])} kg</div>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default HeatingAndFuel;
