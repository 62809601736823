/** @format */

// TODO
// - check if user is logged in already.
// - fix modal styling for Signup and Login MUI.
// -
// - Fix create user with signup modal.
// - Fix send request.
// - Add verify identity on send request.

import { Send } from "@mui/icons-material";
import { Button, Grid, StyledEngineProvider, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { set } from "date-fns";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoginMUI from "../components/Authentication/LoginMUI";
import CreateArtists from "../features/Admin Backend/CreateArtists";
import { storeCurrentAgent, storeCurrentVenue, storeWantedList } from "../features/venueFilter/venueFilterSlice";
import { createId, db, signup } from "../firebase";
import DisplayObject from "../helpers/displayObject";
import GetArtists from "../helpers/GetArtists";
import SelectArtist from "../helpers/SelectArtist";
import SendBatchRequest from "../helpers/SendBatchRequest";
import SendMultiRequest from "../helpers/SendMultiRequest";
import { SendRequest } from "../helpers/SendRequest";
import SimpleSignup from "../helpers/simpleSignup";
import SetEventInfo from "./SetEventInfo";
import { generateOCR } from "../helpers/generateOCR";

const RequestAvails = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const currentVenue = useSelector((state) => state.counter.currentVenue);

  const currentAgent = useSelector((state) => state.counter.currentAgent);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const wantedList = useSelector((state) => state.counter.wantedList);
  var urlParams = new URLSearchParams(window.location.search);
  let search = window.location.search;
  let tmp = [];
  let month = "none";
  const [availDates, setAvailDates] = useState([]);
  const availsid = urlParams.get("availsid");
  const [exists, setExists] = useState(urlParams.get("exists"));
  const venue = urlParams.get("venue");
  const [availData, setAvailData] = useState();
  const [Dates, setDates] = useState([]);
  const [chosenDateRange, setChosenDateRange] = useState([]);
  const [avails, setAvails] = useState([]);
  const [awaiting, setAwaiting] = useState([]);
  const [readyToSend, setReadyToSend] = useState(false);
  const [done, setDone] = useState(false);
  const [agreementId, setAgreementId] = useState(createId());
  const navigate = useNavigate();
  // const email = avails.email;
  const [email, setEmail] = useState();
  // const [dayAvails, setDayAvails] = useState([]);
  // const [dayAwaiting, setDayAwaiting] = useState([]);

  const [openSignup, setOpenSignup] = useState(false);
  const [openLogin, setOpenLogin] = useState();

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  // const availsid = urlParams.get("availsid");
  useEffect(() => {
    async function getData() {
      // Get availsData
      const docSnap = await getDoc(doc(db, "nuAvails", availsid));
      setAvailData(docSnap.data());
      setChosenDateRange(docSnap.data().chosenDateRange);
      setAvails(docSnap.data().avails);
      setAwaiting(docSnap.data().awaiting);
      setEmail(docSnap.data().email);

      // Get venueData
      const venueDocSnap = await getDoc(doc(db, "Venues", docSnap.data().venueid));
      dispatch(storeCurrentVenue(venueDocSnap.data()));
      // setNightAvails(docSnap.data().avails);
      // setDayAvails(docSnap.data().avails.dayAvails);
      // setDayAwaiting(docSnap.data().avails.dayAwaiting);
    }
    getData();
  }, [availsid]);

  useEffect(() => {
    availData && availData.avails.map((date) => tmp.push({ date, type: "nightAvail" }));

    // availData && availData.avails.nightAwaiting.map((date) => tmp.push({ date, type: "nightAwaiting" }));
    // availData && availData.avails.dayAvails.map((date) => tmp.push({ date, type: "dayAvail" }));
    // availData && availData.avails.dayAwaiting.map((date) => tmp.push({ date, type: "dayAwaiting" }));

    tmp.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    setAvailDates(tmp);
    // console.log("tmp", tmp);
  }, [availData]);

  const handleDates = (event, newDates) => {
    if (newDates.length) {
      setDates(newDates);
      // console.log(Dates);
    }
  };

  // Show month header
  let currentMonth = null;

  const showCalendar = chosenDateRange.map((date) => {
    //How many (blank) days to put in the calendar before the first in dateRange.
    const firstDayIs = new Date(date).getDay() + 7 - 2;
    // const firstDayIs = new Date(date).getDay();
    const fillDays = Array.from({ length: firstDayIs + 1 }, (_, i) => i);
    const showFillDays = fillDays.map((date) => <div className="availButtonDate">{/* {date}-{firstDayIs}-{fillDays} */}</div>);

    const monthName = new Date(date).toLocaleString("default", { month: "long" });
    let monthHeader = null;
    if (currentMonth !== monthName) {
      monthHeader = (
        <>
          <availMonth>{monthName}</availMonth>
          <div style={{ width: "100vw", display: "flex" }} className="dayOfTheWeekHeader">
            {days.map((day) => (
              <div className="availCalendarDayOfTheWeek">{day.slice(0, 1)}</div>
            ))}
          </div>
          {showFillDays}
        </>
      );
      currentMonth = monthName;
    }

    return (
      <>
        {monthHeader}
        <ToggleButtonGroup
          value={Dates}
          onChange={handleDates}
          className="availButtonGroup"
          color="primary"
          sx={{
            width: "100%",
            display: "contents",
            flexWrap: "wrap",
            "& > *": {
              width: "calc(100% / 7)",
            },
          }}
        >
          {!avails.includes(date) && !awaiting.includes(date) && (
            <ToggleButton disabled className="availButtonDate" style={{ color: "#777" }}>
              <div className="availCalendarDate">{new Date(date).getDate()}</div>
            </ToggleButton>
          )}
          {avails.includes(date) && (
            <ToggleButton value={date} className="availButtonDate" style={{ color: "white", fontWeight: "900" }}>
              <div className="availCalendarDate">{new Date(date).getDate()}</div>
            </ToggleButton>
          )}
          {awaiting.includes(date) && (
            <Tooltip title="This date is preliminary booked.">
              <ToggleButton value={date} className="availButtonDate" style={{ color: "orange", fontWeight: "900" }}>
                <div className="availCalendarDate">{new Date(date).getDate()}</div>
              </ToggleButton>
            </Tooltip>
          )}
        </ToggleButtonGroup>
      </>
    );
  });

  // async function signupNewUser() {

  // }

  async function handleSubmit() {
    // console.log(exists, openSignup);

    // If user is not signed up or logged in (exists is read from URL)
    if (exists === "false" && !!currentUser.userid === false) setOpenSignup(true);
    if (exists === "true" && !!currentUser.userid === false) setOpenLogin(true);

    // when user is logged in
    if (!!currentUser.userid === true) {
      // Set wanted venue (wantedList)
      // console.log("a-Data", availData);
      let tmp = [];
      async function getData() {
        // get venue availData to put in bookings
        const venueDoc = await getDoc(doc(db, "Venues", availData.venueid));
        let smallVenue = venueDoc.data();
        console.log("smallVenue", smallVenue);
        smallVenue.products = null;
        console.log("smallVenue", smallVenue);
        // map through choosen dates
        Dates.map((proposedDate) => {
          tmp.push({
            // ...venueDoc.data(),
            agreementId: agreementId,
            proposedDate: proposedDate,
            product: availData.currentProduct.product,
            productid: availData.currentProduct.productid,
            getIn: availData.currentProduct.getIn,
            loadIn: availData.currentProduct.loadIn,
            soundcheck: availData.currentProduct.soundcheck,
            doors: availData.currentProduct.doors,
            show: availData.currentProduct.show,
            loadOut: availData.currentProduct.loadOut,
            curfew: availData.currentProduct.curfew,
            price: availData.currentProduct.price,
            priceIncVat: availData.currentProduct.priceIncVat,
            email: availData.email,
            phone: availData.phone,
            venueid: venueDoc.data().venueid,
            venue: venueDoc.data(),
            upfront: venueDoc.upfront,
            upfrontAt: venueDoc.upfrontAt,
            preCharge: venueDoc.preCharge,
            preChargeAt: venueDoc.preChargeAt,
            balance: venueDoc.balance,
            balanceAt: venueDoc.balanceAt,
            earlyCancelation: venueDoc.earlyCancelation,
            earlyCancelationAt: venueDoc.earlyCancelationAt,
            lateCancelation: venueDoc.lateCancelation,
            lateCancelationAt: venueDoc.lateCancelationAt,
            // venue: smallVenue,
          });
          // SendRequest(currentArtist, currentUser, venueDoc, proposedDate);
        });
        console.log("🥰", tmp);

        // store in redux in order to make requests
        await dispatch(storeWantedList(tmp));
        await dispatch(storeCurrentAgent(currentUser));
      }
      // console.log("THIS", currentArtist);

      await getData();
      setReadyToSend(true);
    }
  }

  function finishUp(currentArtist, currentAgent, wantedList) {
    navigate("/Requests");
  }

  useEffect(() => {
    // Create agreement post in db collection "Agreements"
    // readyToSend && alert("Ready to send");
    readyToSend && createPostInAgreements(agreementId);

    async function createPostInAgreements(agreementId) {
      const OCR = await generateOCR();
      const data = {
        OCR: OCR,
        documentid: agreementId,
        agreementId: agreementId,
        status: "Pending Request",
        email: wantedList[0].email,
        // phone: wantedList[0].phone,
        venueid: wantedList[0].venueid,
        venue: wantedList[0].venue.venue,
        upfront: wantedList[0].venue.upfront,
        upfrontAt: wantedList[0].venue.upfrontAt,
        preCharge: wantedList[0].venue.preCharge,
        preChargeAt: wantedList[0].venue.preChargeAt,
        balance: wantedList[0].venue.balance,
        balanceAt: wantedList[0].venue.balanceAt,
        earlyCancelation: wantedList[0].venue.earlyCancelation,
        earlyCancelationAt: wantedList[0].venue.earlyCancelationAt,
        lateCancelation: wantedList[0].venue.lateCancelation,
        lateCancelationAt: wantedList[0].venue.lateCancelationAt,
        // earlyCancelation: 50,
        // earlyCancelationAt: 180,
        // lateCancelation: 100,
        // lateCancelationAt: 60,
      };
      console.log(agreementId, data);
      await setDoc(doc(db, "Agreements", agreementId), { ...data });
      // await setDoc(doc(db, "Agreements", agreementId), { data: "hej" });
      sendIt();
    }

    async function sendIt() {
      console.log("AGREEMENTID", agreementId);
      var result = readyToSend && (await SendBatchRequest(currentArtist, currentAgent, wantedList, agreementId));
      result === "done" && finishUp(currentArtist, currentAgent, wantedList);
    }
  }, [readyToSend]);

  // useEffect(() => {
  // console.log(openSignup);
  // }, [openSignup]);

  // useEffect(() => {
  //   // wantedList && console.log(wantedList);
  //   // console.log(currentUser);
  //   // console.log(currentAgent);
  //   // SendMultiRequest();
  // }, [readyToSend]);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ background: "linearGradient 30deg black #555" }} xs={12}>
      {/* {console.log("availData", availData)} */}
      <Grid item xs={11} sm={8} md={6} py={5}>
        <Grid item xs={12}>
          <h4 style={{ textAlign: "center" }}>{availData ? <>Available dates at {availData.venue}</> : <></>}</h4>
          {availData ? <h6 style={{ textAlign: "center" }}>Pick your dates and Send Request</h6> : <></>}
        </Grid>
        <Grid
          className="availsCalendarContainer"
          item
          sx={{
            maxHeight: "50vh",
            overflow: "scroll",
          }}
        >
          <Grid item className="availsCalendar">
            {chosenDateRange && showCalendar}
          </Grid>
        </Grid>
        {currentUser.userid && (
          <Grid item xs={12}>
            <SetEventInfo />
          </Grid>
        )}
        <Grid item p={0} mt={4} style={{ width: "100%" }} xs={12}>
          <div>{Dates.length} picked dates:</div>
          {Dates.map((date) => (
            <span
              style={{
                color: "#ffffff99",
                background: "#00ffd422",
                padding: "0px 5px",
                borderRadius: "5px",
                marginLeft: "5px",
                display: "tableColumn",
              }}
            >
              {date}
            </span>
          ))}

          {/* {currentArtist && <DisplayObject data={currentArtist} />}
          {currentUser && <DisplayObject data={currentUser} />} */}
          {/* {readyToSend && <SendMultiRequest />} */}
          <Grid item xs={12}>
            <Button
              disabled={Dates.length === 0}
              fullWidth
              variant={"contained"}
              mt={3}
              sx={{
                marginTop: "50px",
                // maxWidth: "400px",
                // minWidth: "300px",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {Dates.length === 0 ? "Pick dates" : "Send Request"}
            </Button>
          </Grid>
          {/* <DisplayObject data={wantedList} /> */}
          {/* {console.log("currentProduct", currentProduct)}
          {console.log(openSignup, email, availData)} */}
          {availData && <SimpleSignup openSignup={openSignup} setOpenSignup={setOpenSignup} mailAddress={availData.email} role="agent" />}
          {availData && <LoginMUI setOpenLogin={setOpenLogin} openLogin={openLogin} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestAvails;
