/** @format */

import { Button, Tooltip } from "@mui/material";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import AvailableSlots from "../../features/avails/AvailableSlots";
import checkAvails from "../../features/avails/CheckAvails";
import { time } from "../../features/avails/time";
import { createSmallId, db } from "../../firebase";
import { SendRequest } from "../../helpers/SendRequest";
import { productColors } from "../UI helpers/productColors";

export function timeDifference(startTime, endTime) {
  const startParts = startTime.split(":");
  const endParts = endTime.split(":");

  const start = new Date();
  start.setHours(parseInt(startParts[0]), parseInt(startParts[1]), 0);

  const end = new Date();
  end.setHours(parseInt(endParts[0]), parseInt(endParts[1]), 0);

  if (end < start) {
    end.setDate(end.getDate() + 1); // Increment end time to the next day
  }

  const timeDifferenceMs = end.getTime() - start.getTime();
  const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60); // Convert milliseconds to minutes

  return timeDifferenceMinutes;
}

const ProductsGraph = ({ products, selectedDate, venue }) => {
  const today = new Date().toLocaleDateString();

  // let productColor = ["#ff00d4", "#00ffd4", "orange", "green", "lightblue", "red", "yellow", "pink", "purple"];
  const bookingsThisDate =
    venue.bookings &&
    venue.bookings.filter((booking) => new Date(booking.date).toLocaleString() === new Date(selectedDate).toLocaleString());
  const blockedThisDate = bookingsThisDate && bookingsThisDate.filter((booking) => booking.status === "Blocked");

  // console.log("bookings this date", bookingsThisDate);
  // console.log("Blocked this date", blockedThisDate);

  const hours = () => {
    for (let i = 0; i <= 23; i++) {
      return { i };
    }
  };

  const [allBlocked, setAllBlocked] = useState(false);
  const blockDate = () => {
    // Block all products this date
    products.map(async (product) => {
      const documentid = createSmallId();
      const booking = {
        documentid: documentid,
        bookingid: documentid,
        date: selectedDate,
        created: today, //Important
        status: "Blocked",
        venueid: venue.venueid,
        venue: venue.venue,
        product: product.product,
        loadIn: product.loadIn,
        loadOut: product.loadOut,
        artist: { image: venue.venueImage },
      };
      await setDoc(doc(db, "Bookings", documentid), { ...booking });
      setAllBlocked(true);
    });
  };

  const unblockAll = () => {
    blockedThisDate.map(async (booking) => {
      await deleteDoc(doc(db, "Bookings", booking.bookingid));
    });
  };

  const handleBlock = (product) => {
    const hasMatchingValues = blockedThisDate.some((obj) => obj.loadIn === product.loadIn && obj.loadOut === product.loadOut);
    if (!hasMatchingValues) {
      // create a "Blocked" booking for this specific date and timeslot
      const documentid = createSmallId();
      const booking = {
        documentid: documentid,
        bookingid: documentid,
        date: selectedDate,
        created: today, //Important
        status: "Blocked",
        venueid: venue.venueid,
        venue: venue.venue,
        product: product.product,
        loadIn: product.loadIn,
        loadOut: product.loadOut,
        artist: { image: venue.venueImage },
      };
      setDoc(doc(db, "Bookings", documentid), { ...booking });
    } else {
      // Find out wich bookingid matches the product to unblock and delete it from "Bookings"
      const matchingBooking = bookingsThisDate.find(
        (booking) => booking.loadIn === product.loadIn && booking.loadOut === product.loadOut && booking.status === "Blocked"
      );
      const bookingid = matchingBooking ? matchingBooking.bookingid : null;
      bookingid && deleteDoc(doc(db, "Bookings", bookingid));
    }
  };

  const productsGraphWidth = 23 + products && products.length * 25;

  // const checkIfBlocked = (product) => {
  //   const hasMatchingValues = blockedThisDate.some((obj) => obj.loadIn === product.loadIn && obj.loadOut === product.loadOut);
  //   console.log(hasMatchingValues);
  //   return hasMatchingValues;
  // };

  const checkIfBlocked = (product) => {
    let blockedHours = [];
    let productHours = [];
    const startHour = parseInt(product.loadIn.split(":")[0]);
    const endHour = parseInt(product.loadOut.split(":")[0]);

    // set productHours
    for (let hour = startHour; hour <= endHour; hour++) {
      productHours.push(hour);
      // console.log("productHours", productHours);
    }

    blockedThisDate &&
      blockedThisDate.forEach((booking) => {
        //get tim in the format 0-24
        const loadInHour = new Date(time(booking.loadIn)).getUTCHours() + 1;
        const loadOutHour = new Date(time(booking.loadOut)).getUTCHours() + 1;
        // console.log(loadInHour, loadOutHour);
        for (let hour = loadInHour; hour <= loadOutHour; hour++) {
          blockedHours.push(hour);
          // console.log("blockedHours", blockedHours);
        }
      });

    // Remove duplicates by creating a Set and then converting back to an array
    blockedHours = [...new Set(blockedHours)];
    // Sort blockedHours
    blockedHours.sort((a, b) => a - b);
    // console.log("blockedHours", blockedHours);

    const hasBlockedHours = productHours.some((hour) => blockedHours.includes(hour));
    return hasBlockedHours;
  };

  const checkIfAllBlocked = () => {
    let tmp = [];
    products &&
      products.map((product) => {
        tmp.push(checkIfBlocked(product));
      });
    // console.log("tmp", tmp);
    if (tmp.includes(false)) {
      setAllBlocked(false);
    } else {
      setAllBlocked(true);
    }
  };

  useEffect(() => {
    // checkBlockedTime();
    checkIfAllBlocked();
  }, [blockedThisDate]);

  //   checkDateAvailability(currentVenue, selectedDate);

  return (
    <>
      <AvailableSlots />
      <div className="productsGraph" style={{ float: "right", width: `${productsGraphWidth}px` }}>
        <div style={{ float: "right", width: "10px", fontSize: "8px", top: "0px", marginLeft: "10px", opacity: "0.5" }}>
          00 01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 01 02 03
        </div>
        {products &&
          products.map((product, index) => (
            <Tooltip title={`${product.product} (${product.loadIn}-${product.loadOut})`} followCursor>
              <div
                className="productBar"
                style={{
                  position: "relative",
                  opacity: "0.6",
                  marginLeft: "3px",
                  float: "left",
                  // background: hovered === product.productid ? "#111" : productColors[index],
                  background: checkIfBlocked(product) ? "#00ffd444" : "#00ffd4",
                  backgroundImage: checkIfBlocked(product)
                    ? "linear-gradient(45deg, yellow 25%, transparent 25%, transparent 50%, yellow 50%, yellow 75%, transparent 75%, transparent)"
                    : null,
                  height: `${timeDifference(product.loadIn, product.loadOut) / 5}px`,
                  marginTop: `${parseFloat(product.loadIn) * 12.23}px`,
                  width: "10px !important",
                }}
              ></div>
            </Tooltip>
          ))}
      </div>
      <div style={{ height: "30px" }}>
        {products &&
          products.map((product, index) => (
            <span
              className="productGraphBlock"
              onClick={() => handleBlock(product)}
              style={{ background: checkIfBlocked(product) ? "#00ffd455" : "#444" }}
            >
              ⊘
            </span>
          ))}
        <span
          className="productGraphBlock"
          onClick={allBlocked ? () => unblockAll() : () => blockDate()}
          style={{ background: allBlocked ? "#00ffd444" : "#444" }}
        >
          ⊘ All
        </span>
      </div>
      {/* <div className="blockDate" onClick={() => blockDate()}>
        Block Date
      </div> */}
    </>
  );
};

export default ProductsGraph;
