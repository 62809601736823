/** @format */

// import VenueForm from "../components/Forms/VenueForm";

export let customerAccountObj = [
  { section: "Document ID", type: "Section" },
  {
    section: "Document",
    placeholder: "Document ID",
    label: "Document ID",
    id: "documentid",
    type: "text",
    priority: 10,
    disabled: true,
  },
  { section: "User ID", type: "Section" },
  {
    section: "User ID",
    placeholder: "User ID",
    label: "User ID",
    id: "userid",
    type: "text",
    priority: 10,
    disabled: true,
  },
  { section: "Role", type: "Section" },
  {
    section: "Role",
    placeholder: "Role",
    label: "Role",
    id: "userRole",
    type: "select",
    options: ["owner", "editor", "agent", "artist", "admin"],
    style: { width: "150px" },
    priority: 10,
    disabled: true,
  },
  {
    section: "User",
    type: "Section",
    priority: 1,
  },
  {
    section: "User",
    placeholder: "First Name",
    label: "First Name",
    id: "firstName",
    required: true,
    priority: 1,
    type: "text",
  },
  {
    section: "User",
    placeholder: "Last Name",
    label: "Last Name",
    id: "lastName",
    required: true,
    priority: 1,
    type: "text",
  },
  {
    section: "User",
    placeholder: "Telephone",
    label: "Telephone",
    id: "phone",
    required: true,
    priority: 1,
    type: "phone",
  },
  {
    section: "User",
    placeholder: "Email",
    label: "Email",
    id: "email",
    priority: 1,
    required: true,
    type: "email",
    disabled: true,
    checkIfEmailExists: false,
    info: "Your email address will be permanent and can not be changed later.",
  },
  { section: "Social Security Id", type: "Section", priority: 1 },
  {
    section: "Social Security Id",
    placeholder: "Social Security Id",
    label: "Social Security Id",
    id: "socialId",
    priority: 1,
    type: "encrypted",
    encrypt: true,
    required: true,
    info: "This will be encrypted, in order to sign you in with Bank Id.",
  },
  { section: "Password", type: "Section", priority: 2 },
  {
    section: "Password",
    placeholder: "Password",
    label: "Password",
    id: "password",
    required: true,
    priority: 2,
    type: "encrypted",
    encrypt: true,
  },
  { section: "Confirm password", type: "Section", priority: 2 },
  {
    section: "Confirm password",
    placeholder: "Confirm password",
    label: "Confirm password",
    id: "passwordConfirm",
    required: true,
    priority: 2,
    type: "encrypted",
    encrypt: true,
  },
  { section: "Company", type: "Section", priority: 1 },
  {
    section: "Company",
    placeholder: "Company Name",
    label: "Company Name",
    id: "company",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  {
    section: "Company",
    placeholder: "Organization Number",
    label: "Organization Number",
    id: "orgNumber",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  {
    section: "Company",
    placeholder: "Company IBAN",
    label: "Company IBAN",
    id: "orgNumber",
    required: true,
    priority: 10,
    type: "text",
    encrypt: true,
  },
  { section: "Postal Address", type: "Section", priority: 1 },
  {
    section: "Postal Address",
    placeholder: "Street Address",
    label: "Street Address",
    id: "streetAddress",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  {
    section: "Postal Address",
    placeholder: "Zip Code",
    label: "Zip Code",
    id: "zipCode",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  {
    section: "Postal Address",
    placeholder: "City",
    label: "City",
    id: "city",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  {
    section: "Postal Address",
    placeholder: "Country",
    label: "Country",
    id: "country",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  { section: "Access", type: "Section", priority: 2 },
  {
    section: "Access",
    placeholder: "Venues",
    label: "Venues",
    id: "venueAccess",
    priority: 2,
    type: "text",
    disabled: true,
  },
  { section: "", type: "Section", priority: 1 },
  {
    section: "Store",
    id: "update",
    priority: 1,
    type: "update",
    label: "update",
    collection: "Users",
    // documentId: "userid",
  },
  {
    section: "",
    id: "goback",
    priority: 1,
    type: "goback",
    label: "Cancel",
  },

  // VenueForm,
];
