/** @format */

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { InputAdornment, Tooltip } from "@mui/material";
import { EnhancedEncryption } from "@mui/icons-material";
import { AES, enc } from "crypto-js";

const isValidBIC = (bic) => {
  // Perform BIC validation logic here
  // For simplicity, we will just check if the BIC has a length of 8 or 11 characters
  return /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/.test(bic) && (bic.length === 8 || bic.length === 11);
};

const BICTextField = ({ formValue, setBIC }) => {
  const [decryptedValue, setDecryptedValue] = useState("");
  const [error, setError] = useState(false);
  const secretKey = process.env.REACT_APP_FORM_KEY; // Replace with your secret key

  const handleChange = (event) => {
    const newBIC = event.target.value;
    // Automatically convert the characters to uppercase
    const formattedBIC = newBIC.toUpperCase();

    // Encrypt before returning
    const encryptedBIC = formattedBIC && AES.encrypt(formattedBIC, secretKey).toString();
    setBIC(encryptedBIC);
  };

  useEffect(() => {
    setDecryptedValue(formValue && AES.decrypt(formValue, secretKey).toString(enc.Utf8));
  }, [formValue]);

  useEffect(() => {
    // Check if the BIC has a valid format
    if (decryptedValue && isValidBIC(decryptedValue)) {
      setError(false);
    } else {
      setError(true);
    }
  }, [decryptedValue]);

  return (
    <div>
      <TextField
        size="small"
        // style={{ width: "200px" }}
        label="BIC Number"
        variant="outlined"
        value={decryptedValue}
        onChange={handleChange}
        error={error}
        helperText={error ? "Insert valid BIC" : ""}
        InputProps={{
          endAdornment: <InputAdornment position="end">{!error && <CheckCircle style={{ color: "#0080006b" }} />}</InputAdornment>,
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Encrypted">
                <EnhancedEncryption style={{ color: "#555", zIndex: "100000" }} />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default BICTextField;
