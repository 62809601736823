/** @format */

import { Box, Grid, ToggleButtonGroup } from "@mui/material";
import React from "react";
import ShowDb from "../features/Admin Backend/ShowDb";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CreateArtists from "../features/Admin Backend/CreateArtists";
import CreateBookings from "../features/Admin Backend/CreateBookings";
import ShowCollection from "../features/Admin Backend/ShowCollection";
import CompareDocs from "../features/Admin Backend/CompareDocs";

const AdminBackend = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container p={4} sx={12} height={70}>
      <h1>Admin</h1>
      <Grid item sx={12} style={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="DB Collections" value="1" />
              <Tab label="Compare Documents" value="2" />
              <Tab label="Create Agents" value="3" />
              <Tab label="Create Artists" value="4" />
              <Tab label="Create Bookings" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {/* <ShowDb /> */}
            <ShowCollection />
          </TabPanel>
          <TabPanel value="2">
            <h4>Compare Documents</h4>
            <CompareDocs />
          </TabPanel>

          <TabPanel value="3">
            <h4>Create Agents</h4>
          </TabPanel>
          <TabPanel value="4">
            <h4>Create Artists</h4>
            <CreateArtists />
          </TabPanel>
          <TabPanel value="5">
            <h4>Create Bookings</h4>
            <CreateBookings />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default AdminBackend;
