/** @format */

import axios from "axios";
export const createStripeCheckout = async (currentVenue, selectedSubscription, monthlyValue) => {
  const oldSubscriptionId = currentVenue.subscription.id ? currentVenue.subscription.id : "";
  const body = {
    customer: currentVenue.email,
    item: {
      price: monthlyValue,
      name: selectedSubscription,
      description: `Venuehub ${selectedSubscription}`,
      currency: currentVenue.currency,
    },
    paymentMode: "subscription",
    onSuccessUrl: `https://venuehub.live/Subscribed?venueid=${currentVenue.venueid}&oldSubscriptionId=${oldSubscriptionId}&plan=${selectedSubscription}&service=Stripe&id={CHECKOUT_SESSION_ID}`,
    onCancelUrl: "https://venuehub.live/Venue",
    customerMetaData: {
      orderId: currentVenue.venueid,
    },
  };
  try {
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/createStripeCheckout`, body);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

//   const body = {
//     customer: "simon+1@qte.se",
//     item: {
//       price: 995,
//       name: "PRO",
//       description: "PRO Sub",
//     },
//     paymentMode: "subscription",
//     onSuccessUrl: "https://venuehub.live/",
//     onCancelUrl: "https://stripe.com/docs/api",
//     customerMetaData: {
//       orderId: 123213212,
//     },
//   };
