/** @format */

import React from "react";
import { collection, addDoc, updateDoc, doc, setDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { createId, db } from "../../firebase";
import { Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { storeCurrentVenue } from "../../features/venueFilter/venueFilterSlice";
import { venueObj } from "../../dbObjects.js/dbVenue";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import { useNavigate } from "react-router-dom";
let newVenue = [];

const AddVenue = ({ location }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   let venueid = "";

  const currentVenue = useSelector((state) => state.counter.currentVenue);
  console.log(newVenue);

  async function addVenue() {
    // fill up the object newVenue with empty och defaultValue from "venueObj".
    await venueObj.map((item) => {
      if (item.defaultValue) newVenue[item.id] = item.defaultValue;
      else newVenue[item.id] = "";
    });

    //Create documentid
    const newID = await createId();
    newVenue.venueid = newID;
    newVenue.documentid = newID;
    newVenue.country = "Sweden";
    const newProductID = await createId();
    newVenue.products = [
      {
        preferredDays: ["Thursday", "Friday", "Saturday"],
        product: "Night Event",
        productid: newProductID,
        documentid: newProductID,
        price: "10 000",
        getIn: "10:00",
        loadIn: "12:00",
        soundcheck: "15:00",
        doors: "18:00",
        show: "19:00",
        loadOut: "22:00",
        curfew: "23:00",
        typeOfEvent: ["Band"],
        product: "Night Event",
        sectionsToShow: { "Preferred Days": true, Pricing: true, Product: true, "Standard Running Times": true, "Type of Event": true },
      },
    ];
    await dispatch(storeCurrentVenue(newVenue));
    newVenue = [];
    console.log("currentVenue", currentVenue);
    console.log("newVenue", newVenue);
  }

  async function addVenueAndStore() {
    // fill up the object newVenue with empty och defaultValue from "venueObj".
    await venueObj.map((item) => {
      if (item.defaultValue) newVenue[item.id] = item.defaultValue;
      else newVenue[item.id] = "";
    });

    //Create documentid
    const newID = await createId();
    newVenue.venueid = newID;
    newVenue.documentid = newID;
    newVenue.country = "Sweden";
    newVenue.venueName = "New Venue";
    newVenue.upfront = 0;
    newVenue.upfrontAt = 10000; //Direct!
    newVenue.preCharge = 50;
    newVenue.preChargeAt = 180;
    // newVenue.charge = 50;
    // newVenue.chargeAt = 30;
    newVenue.balance = 50;
    newVenue.balanceAt = 30;
    newVenue.earlyCancelation = 50;
    newVenue.earlyCancelationAt = 180;
    newVenue.lateCancelation = 100;
    newVenue.lateCancelationAt = 60;
    newVenue.currency = "SEK";

    const newProductID = await createId();
    newVenue.products = [
      {
        preferredDays: ["Thursday", "Friday", "Saturday"],
        product: "Night Event",
        productid: newProductID,
        documentid: newProductID,
        price: "10 000",
        getIn: "10:00",
        loadIn: "12:00",
        soundcheck: "15:00",
        doors: "18:00",
        show: "19:00",
        loadOut: "22:00",
        curfew: "23:00",
        typeOfEvent: ["Band"],
        product: "Night Event",
        sectionsToShow: { "Preferred Days": true, Pricing: true, Product: true, "Standard Running Times": true, "Type of Event": true },
      },
    ];
    await dispatch(storeCurrentVenue(newVenue));
    newVenue = [];
    console.log("currentVenue", currentVenue);
    console.log("newVenue", newVenue);

    // await setDoc(doc(db, "Venues", newVenue.venueid), {
    //   ...newVenue,
    // });

    navigate("/Owner/Venue");

    return;
  }

  return (
    <>
      {location === "venueForm" && (
        <ConfirmDialog
          noButton
          onConfirm={() => addVenue()}
          size={"small"}
          variant={"outlined"}
          // color={"secondary"}
          header={"Add another Venue?"}
          message={"If you have unsaved changes to your current venue they will disappear."}
          // optionA={"Continue"}
          // optionB={"Cancel"}
          style={{ color: "#00ffd499", fontSize: "14px" }}
          size="small"
        >
          {/* <Button
        size="small"
        style={{ color: "#777", margin: "25px" }}
        onClick={() => {
          addVenue();
        }}
      > */}
          <icon>
            <AddIcon />
          </icon>
          Add new venue
        </ConfirmDialog>
      )}
      {location === "selectVenue" && (
        <>
          {currentVenue && (
            <span onClick={() => addVenueAndStore()} className="addEditButton">
              <AddIcon size={10} /> Add New
            </span>
          )}
        </>
      )}
    </>
  );
};

export default AddVenue;
