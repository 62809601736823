/** @format */

import React, { useEffect, useRef, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import { Badge, Button, Icon, ListItemButton } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import SimpleSelectAvailDates from "../components/UI helpers/SimpleSelectAvailDates";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import VenueRating from "../components/MiscUIComponents/VenueRating";
import LikeButton from "../components/MiscUIComponents/LikeButton";
import { VenueInfoModal } from "../components/Modals/VenueInfoModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { storeSortVenuesBy } from "../features/venueFilter/venueFilterSlice";
import FlatVenueCard from "../features/create tour/FlatVenueCard";
import StandardVenueCard from "../features/create tour/StandardVenueCard";

const NuListVenues = (props) => {
  const sortVenuesBy = useSelector((state) => state.counter.sortVenuesBy);

  // const [venues, setVenues] = useState([]);
  // const [filteredVenues, setFilteredVenues] = useState([]);
  var [dateIsSet, setDateIsSet] = useState(0);
  //   const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);
  const [showVenueInfo, setShowVenueInfo] = useState(false);
  const [liked, setLiked] = useState();
  const [sortedVenues, setSortedVenues] = useState();
  const dispatch = useDispatch();
  var previousVenueCity = "none";
  const [tempHeight, setTempHeight] = useState(80);
  if (props.startDate !== "" && props.endDate !== "") setDateIsSet = 1;

  useEffect(() => console.log("date is set: "), [dateIsSet]);

  useEffect(() => {
    const sortVenues = (venues) => {
      const sorted = venues.sort((a, b) => {
        if (a.venueCity < b.venueCity) {
          return -1;
        } else if (a.venueCity > b.venueCity) {
          return 1;
        } else {
          return 0;
        }
      });
      return sorted;
    };
    console.log("= =< = =>>>", props.tempVenues);
    sortVenuesBy && setSortedVenues(sortVenues(props.tempVenues));
  }, [props.tempVenues]);

  const handleClick = (venue) => {
    console.log(venue);
  };

  const handleSortChange = (e) => {
    const sortValue = e.target.value;
    dispatch(storeSortVenuesBy(sortValue));
  };

  setTimeout(() => {
    setTempHeight(100);
  }, 3000);

  //   console.log(props.tempVenues);

  // return loaded ? (

  const toggleSort = () => {
    return (
      <div style={{ position: "fixed", right: "10px", top: "0px", zIndex: "3" }}>
        <ToggleButtonGroup value={sortVenuesBy} onChange={handleSortChange} exclusive aria-label="text alignment" size="small">
          <ToggleButton value="venueCity" aria-label="left aligned">
            City
          </ToggleButton>
          <ToggleButton value="capacity" aria-label="justified">
            Capacity
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  const showCitySection = (City) => {
    if (City === previousVenueCity) return <></>;
    else if (City !== previousVenueCity) {
      previousVenueCity = City;
      return <h6 style={{ width: "100%", float: "left", textTransform: "uppercase", fontSize: "14", paddingTop: "20px" }}>{City}</h6>;
    }
  };

  return (
    <div>
      {/* {toggleSort} */}

      <div>
        {props.tempVenues ? (
          props.tempVenues.map((venue) => (
            <div>
              {showCitySection(venue.venueCity)}
              {/* <FlatVenueCard venue={venue} /> */}
              <StandardVenueCard venue={venue} />
            </div>
          ))
        ) : (
          <h1 style={{ background: "red" }}>Hej</h1>
        )}
      </div>
      <h1 style={{ height: `${tempHeight}vh`, width: "100%", background: "#red" }}></h1>
    </div>
  );
};

export default connect()(NuListVenues);
