/** @format */
// Since a lot of numbers are stored formatted as textstrings in order to have thousand delimiters,
// for calculations we're using makeNumber() to calculate and then numberFormatted() to display result.
// example:  value={numberFormatted(makeNumber(booking.price) * (1 + Number(venueVat.slice(0, -1)) / 100))}

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { summaryObj } from "../dbObjects.js/dbSummary";
import { db } from "../firebase";
import { loadBookingFromBookingId } from "../helpers/loadBookingFromBookingId";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import { createPayment } from "../finshark/createPayment";
import VenueInvoice from "../finshark/VenueInvoice";
import DisplayObject from "../helpers/displayObject";
import { storeCurrentBooking } from "../features/venueFilter/venueFilterSlice";
import SendInvoiceEmail from "../features/avails/SendInvoiceEmail";
import { getPaymentBody } from "../finshark/finsharkPaymentBody";
import { useNavigate } from "react-router-dom";
import { makeNumber, numberFormatted, removePercentage } from "../helpers/Numbers";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { checkBookingForPreviousPayments, storePaymentInBooking } from "../finshark/checkBookingForPreviousPayments";
import BookingPayments from "../finshark/BookingPayments";
import { countries } from "../dbObjects.js/dbCountries";
import BookingFeaturesSummary from "./BookingFeaturesSummary";
import Settlement from "../components/Documents/Settlement";
import { calculateBookingPrice } from "../components/MiscUIComponents/calculateBookingPrice";
import { calculateAdditionalCosts } from "../components/MiscUIComponents/calculateAdditionalCosts";
import getTicketEventIdFromBookingId from "../components/MiscUIComponents/getTicketEventIdFromBookingId";
import getAllTicketSalesFromAgreementId from "../components/MiscUIComponents/getAllTicketSalesFromAgreementId";

export default function BookingSummary() {
  const [loaded, setLoaded] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const bookingid = urlParams.get("bookingid");
  const [booking, setBooking] = useState([{}]);
  const [summaryData, setSummaryData] = useState([{ type: "Additional...", quantity: 0, cost: 0, vat: "25%", sum: 0 }]);

  const [ticketData, setTicketData] = useState([{ type: "Ticket A", quantity: 0, cost: 0, vat: "6%", sum: 0 }]);
  const [bookingSummary, setBookingSummary] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const [venueVat, setVenueVat] = useState("25%");
  const [saving, setSaving] = useState(false);

  const [deductFromTickets, setDeductFromTickets] = useState(false); //If venue deducts rental fee and other from ticket sales
  const [ticketsSold, setTicketsSold] = useState(0);
  const [ticketsTotal, setTicketsTotal] = useState(0);
  const [ticketsVat, setTicketsVat] = useState("6%");
  const [ticketPrice, setTicketPrice] = useState(0);
  const [ticketsFree, setTicketsFree] = useState(0);
  const [ticketSummary, setTicketSummary] = useState({});
  const [paymentId, setPaymentId] = useState(false);
  const [clientId, setClientId] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(false);
  const [CO2eVenue, setCO2eVenue] = useState(false);
  const [CO2ePosts, setCO2ePosts] = useState(false);
  const [CO2eTickets, setCO2eTickets] = useState(false);
  const [CO2eSum, setCO2eSum] = useState(false);
  const [totalCosts, setTotalCosts] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalIncVat, setTotalIncVat] = useState(0);
  const [totalTickets, setTotalTickets] = useState([]);
  const [amountToBePaid, setAmountToBePaid] = useState(0);
  const [amountAlreadyPaid, setAmountAlreadyPaid] = useState(0);
  const [OCR, setOCR] = useState("");
  const [totalAmountToBePaidByNow, setTotalAmountToBePaidByNow] = useState(0);
  const [totalPrepaidConsidered, setTotalPrepaidConsidered] = useState(0);
  const [additionalCosts, setAdditionalCosts] = useState(0);
  const [missingInBalance, setMissingInBalance] = useState(0);
  const [remainingToPay, setRemainingToPay] = useState(0);
  const [administrativeFees, setAdministrativeFees] = useState(0);
  const [fees, setFees] = useState([]);
  const [bookingFeatures, setBookingFeatures] = useState([]);
  const [bookingFeaturesSummaryIncVat, setBookingFeaturesSummaryIncVat] = useState([]);
  const [bookingFeaturesSummaryExclVat, setBookingFeaturesSummaryExclVat] = useState([]);
  const [bookingFeatureSummaryVat, setBookingFeatureSummaryVat] = useState([]);
  const [settlementFee, setSettlementFee] = useState({ sum: 0 });
  const [additionalCostsIncVat, setAdditionalCostsIncVat] = useState(0);
  const [additionalCostsExclVat, setAdditionalCostsExclVat] = useState(0);
  const [additionalCostsVat, setAdditionalCostsVat] = useState(0);
  const [agreement, setAgreement] = useState(null);
  const [allTickets, setAllTickets] = useState(null);
  const [allTicketsWithinAgreement, setAllTicketsWithinAgreement] = useState(null);

  // useEffect(() => {
  //   setTotalIncVat({ ticketSummary, summaryData, ticketData, totalTickets });
  // }, [ticketSummary, summaryData, ticketData, totalTickets]);

  // useEffect(() => {
  //   console.log("totalIncVat", totalIncVat);
  // }, [totalIncVat]);

  const bookingFee = 0.45 / 100;
  const transactionFee = 0.54 / 100;

  const currentVenue = useSelector((state) => state.counter.currentVenue);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const ticketTypes = [
    { label: "Ticket A", id: 1, vat: "6%" },
    { label: "Ticket B", id: 2, vat: "6%" },
    { label: "Ticket C", id: 3, vat: "6%" },
    { label: "Ticket D", id: 4, vat: "6%" },
    { label: "Ticket E", id: 5, vat: "6%" },
    { label: "Ticket F", id: 6, vat: "6%" },
    { label: "Free", id: 7, vat: "6%" },
    { label: "VIP", id: 8, vat: "6%" },
    { label: "", id: 9, vat: "25%" },
  ];
  const postTypes = [
    { label: "Staff", id: 1, vat: "25%" },
    { label: "Sound Engineer", id: 2, vat: "0%" },
    { label: "Security", id: 3, vat: "25%" },
    { label: "Hump", id: 4, vat: "25%" },
    { label: "Wardrobe", id: 5, vat: "25%" },
    { label: "Beverages", id: 6, vat: "12%" },
    { label: "Food", id: 7, vat: "12%" },
    { label: "Transfer", id: 8, vat: "25%" },
    { label: "Transportation", id: 9, vat: "25%" },
    { label: "Damages", id: 10, vat: "25%" },
    { label: "Ticket Service Fee", id: 11, vat: "25%" },
    { label: "Extra Hours", id: 12, vat: "25%" },
  ];

  const [postVat, setPostVat] = useState([
    { label: "0%", id: 1 },
    { label: "6%", id: 2 },
    { label: "12%", id: 3 },
    { label: "25%", id: 4 },
  ]);

  useEffect(() => {
    // Get postVat dynamically from "countries" depending on the choosen country of the venue. Check dbCountries to add or remove countries
    async function wait() {
      await setTimeout(() => {
        console.log("waiting");
      }, 500);
    }
    if (currentVenue.country) {
      console.log("currentVenue.country", currentVenue.country);

      const land = countries.find((country) => country.country === currentVenue.country) || "Sweden";
      !land.vatOptions ? wait() : console.log("COUNTRY", land);
      setPostVat(land.vatOptions.map((item, index) => ({ id: index, label: item.vatRate, info: item.info })));
    } else wait();
  }, [currentVenue]);

  // If ticket data is fetched from a service provider (like Tixly) ticketService is "true"
  const [ticketService, setTicketService] = useState(false);
  useEffect(() => {
    // If tixlyId exists set Ticket Service === true. Add other ticketId's when added
    (booking.tixlyId || booking.nortickId || booking.bilettoId || booking.ticksterId) && setTicketService(true);
  }, [booking]);

  useEffect(() => {
    async function getTicketDataFromTixly() {
      const docSnap = await getDoc(doc(db, "TicketsTest", booking.tixlyId));
      let summarizedData = {}; // Use an empty object instead of an array

      //Create "Free Tickets" as a type of tickets
      summarizedData["Free Tickets"] = {
        cost: 0,
        quantity: 0,
        type: "Free Tickets",
        sum: 0,
        vat: "6%",
      };

      if (docSnap.exists()) {
        const Data = docSnap.data();
        console.log("❤️‍🩹 Document data:", Data);

        // Iterate over properties of the Data object
        for (const key in Data) {
          const item = Data[key];
          if (!summarizedData[item.PriceZone]) {
            summarizedData[item.PriceZone] = {
              cost: item.Price,
              quantity: 0,
              type: item.PriceZone,
              sum: 0,
              vat: "6%",
              // Add more fields as needed
            };
          }

          if (item.Price !== 0) {
            summarizedData[item.PriceZone].quantity += 1; // Increment quantity for each ticket
            summarizedData[item.PriceZone].sum += item.Price;
          }
          if (item.Price === 0) {
            summarizedData["Free Tickets"].quantity += 1; // Increment quantity for each ticket
            summarizedData["Free Tickets"].sum = 0;
            console.log("Free tickets", item.Price, item.PriceZone, summarizedData["Free Tickets"].sum);
          }
        }
      } else {
        console.log("No such document!");
      }

      console.log("***********", summarizedData);
      console.log(ticketData);
      setTicketData(summarizedData);
    }

    if (booking.tixlyId) {
      getTicketDataFromTixly(booking.tixlyId);
    }
    if (booking.ticketEventId) {
      console.log("TicketEventId", booking.ticketEventId, booking.ticketVenueId);
      getDataFromTicketSummaries(booking.ticketEventId);
    }
    console.log(booking.ticketEventId);
    if (booking.tixlyId === undefined && booking.ticketEventId === undefined) {
      setTicketsTotal(0);
      setTotalTickets({ sum: 0, count: 0 });
    }

    async function getDataFromTicketSummaries(ticketEventId) {
      const docSnap = await getDoc(doc(db, "TicketSummaries", booking.ticketVenueId));
      if (docSnap.exists()) {
        const Data = docSnap.data();
        setAllTickets(Data);
        console.log("❤️‍🩹 Document biljettdata:", ticketEventId, Data);

        // only keep the data for the specific event
        const filteredData = Object.values(Data).filter((item) => item.ticketEventId === ticketEventId);
        if (filteredData[0] === undefined) return;

        setTicketSummary(filteredData[0].summary);

        let eventTickets = 0;
        let eventTicketSummary = 0;

        if (filteredData.length > 0) {
          eventTickets = filteredData[0].tickets || 0;
          eventTicketSummary = filteredData[0].summary || 0;
        }

        console.log("EventTickets", eventTickets);
        let summarizedData = {}; // Use an empty object instead of an array
        //Create "Free Tickets" as a type of tickets
        summarizedData["Free Tickets"] = {
          cost: 0,
          quantity: 0,
          type: "Free Tickets",
          sum: 0,
          vat: "6%",
        };

        eventTickets.length &&
          eventTickets.forEach((item) => {
            if (!summarizedData[item.type]) {
              summarizedData[item.type] = {
                cost: item.cost,
                quantity: item.count,
                type: item.type,
                sum: item.sum,
                vat: item.vat,
                // Add more fields as needed
              };
            }
          });
        console.log("***********", summarizedData);
        console.log(ticketData);

        // booking.tickets = summarizedData;
        // booking.ticketSummary = eventTicketSummary;

        setTicketData(summarizedData);
        console.log(eventTicketSummary, "eventTicketSummary");
        setTicketsTotal(eventTicketSummary.sum);
        setTotalTickets(eventTicketSummary);
      } else {
        console.log("No such document!");
      }
    }
  }, [booking]);

  function handleTicketData(key, value, index, type) {
    const newData = [...ticketData]; // create a copy of the array
    if (type === "number") {
      const newVal = numberFormatted(value);
      newData[index] = { ...newData[index], [key]: newVal }; // update the type field
    } else {
      newData[index] = { ...newData[index], [key]: value }; // update the type field
    }
    setTicketData(newData); // update the state with the new array
  }

  useEffect(() => {
    getAgreement();
  }, [booking]);

  useEffect(() => {
    if (!agreement) return;
    if (!agreement.agreementId) return;
    async function getTicketsWithinsAgreement() {
      setAllTicketsWithinAgreement(await getAllTicketSalesFromAgreementId(agreement.agreementId));
    }
    getTicketsWithinsAgreement();
  }, [agreement]);

  // useEffect(() => {
  //   if (!agreement) return;
  //   if (!allTickets) return;
  //   console.log("allTickets", allTickets);
  //   let tmpTicketsSold = 0;

  //   for (const tmpBookingId of agreement.bookingids) {
  //     const tmpTicketEventId = getTicketEventIdFromBookingId(tmpBookingId);
  //     console.log("tmpTicketEventId", tmpTicketEventId);
  //     console.log(
  //       "✈️",
  //       Object.values(allTickets).filter((post) => post.ticketEventId === tmpTicketEventId)
  //     );

  //     // tmpTicketsSold += Object.values(allTickets).filter((event) => event.ticketEventId === tmpTicketEventId).summary.sum;
  //   }
  //   console.log("AllTicketsSold", tmpTicketsSold);
  // }, [allTickets, agreement]);

  useEffect(() => {
    // let tmpTicketsSold = ticketData.reduce((acc, post) => acc + Number(post.quantity), 0);
    // let tmpTicketSum = ticketData.reduce((acc, post) => acc + post.sum, 0);
    // setTicketData((prevTicketData) => [...prevTicketData, { ticketsSold: tmpTicketsSold, ticketSum: tmpTicketSum }]);
    // setTicketData((prev) => ({ ...prev, ticketsSold: tmpTicketsSold, ticketSum: tmpTicketSum }));
    // console.log("ticketSum", tmpTicketSum, "ticketsSold", tmpTicketsSold);
  }, [ticketData]);

  function handleChange(key, value, index, type) {
    const newData = [...summaryData]; // create a copy of the array
    if (type === "number") {
      newData[index] = { ...newData[index], [key]: value }; // update and format the type field (for numbers)
    } else {
      newData[index] = { ...newData[index], [key]: value }; // update the type field
    }
    setSummaryData(newData); // update the state with the new array
  }

  function addTicketPost(index) {
    setTicketData([
      ...ticketData.slice(0, index + 1),
      { type: "...", quantity: "1", cost: "0", vat: "6%", sum: "0" },
      ...ticketData.slice(index + 1),
    ]);
  }

  function deleteTicketPost(index) {
    if (ticketData.length === 1) return; //Always keep one row left.
    setTicketData(ticketData.filter((_, i) => i !== index));
  }

  function addPost(index) {
    setSummaryData([
      ...summaryData.slice(0, index + 1),
      { type: "...", quantity: "1", cost: "0", vat: "25%", sum: "0" },
      ...summaryData.slice(index + 1),
    ]);
  }

  function deletePost(index) {
    if (summaryData.length === 1) return; //Always keep one row left.
    setSummaryData(summaryData.filter((_, i) => i !== index));
  }

  async function saveForLater() {
    await saveSummary();
    // navigate("/Owner/Home");
    navigate(-1);
  }

  async function saveSummary() {
    setSaving(true);
    //Get agentdata inorder to update booking with latest known info (in case it's been updated or somehow isn't already in the booking).
    // const agentid = booking.agent && booking.agent.documentid;
    // const docSnapAgent = await getDoc(doc(db, "Users", agentid));

    // booking.summary = summaryData;
    let summaryTotal = {
      totalExclVat: total - totalVat,
      vat: totalVat,
      totalInclVat: total,
    };

    await updateDoc(doc(db, "Bookings", bookingid), {
      // agent: docSnapAgent.data(),   THIS!!
      tickets: { ...ticketData },
      ticketSummary: ticketSummary,
      summary: summaryData,
      summaryTotal: summaryTotal,
    });
    // await setBooking((prev) => ({ ...prev, summary: summaryData, summaryTotal: summaryTotal, agent: docSnapAgent.data() }));
    await setBooking((prev) => ({ ...prev, summary: summaryData, summaryTotal: summaryTotal }));
    setSaving(false);
  }

  async function getFeesFromPaymentPosts() {
    // alert("OCR" + OCR);
    let tmp = [];
    const q = query(collection(db, "PaymentPosts"), where("OCR", "==", OCR));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data().fees !== undefined && tmp.push({ [doc.data().type]: doc.data().fees });
      doc.data().fees !== undefined &&
        doc.data().fees.map((fee) => {
          tmp.push(fee);
        });
    });
    setFees(...tmp);
    console.log("tmp fees", tmp);
    const totalAdministrativeFees = tmp.reduce((acc, fee) => {
      return acc + fee.sum;
    }, 0);
    setAdministrativeFees(totalAdministrativeFees);
    console.log("totalAdministrativeFees", totalAdministrativeFees);
    console.log("tmp fees", tmp);
  }

  async function finalize() {
    // alert("Creating payment message link" + amount);
    //first store all data to booking
    console.log("summaryData", summaryData);
    await saveSummary();
    setShowInvoice(true);

    //then send invoice email (booking now holds all the data including summary and totalSummary)
    // await SendInvoiceEmail(booking);
    // checkEmailStatus(booking.bookingid);
    // createPayment({ bodyData, setPaymentId, setClientId });
  }

  // FLOW: get payment body => create new Payment => and when paymentId and clientId returns send Invoice Email.
  let body = {};
  async function handleSend(url) {
    console.log("HANDLESEND");
    //from venueInvoice
    setInvoiceUrl(url);
    body = await getPaymentBody(booking);
    console.log("body", body);
    await newPayment();
  }

  async function newPayment() {
    // command is returned when checking for previous payments in order to only create new payments if necessary.
    //THIS!!!!
    // const command = await checkBookingForPreviousPayments(booking, "summary");
    const command = "create new payment";
    // const command = "create new payment";
    // console.log("COMMAND", command && (await command));
    if (command && command === "create new payment") {
      console.log("🤍", body);
      console.log("💚", JSON.stringify(body));

      // fetch(`https://europe-west3-venuehub-64e72.cloudfunctions.net/createPayment?bodyData=${JSON.stringify(body)}`) //The old request with body in the URL
      fetch("https://europe-west3-venuehub-64e72.cloudfunctions.net/createPayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        // body: body,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Payment ID: ", data.paymentId);
          console.log("Client ID: ", data.clientId);
          console.log("Response Data 💚", data.responseData);
          setPaymentId(data.paymentId);
          setClientId(data.clientId);

          //Store new payment to booking
          //THIS!!!!
          // storePaymentInBooking(booking, "summary", data.paymentId, data.responseData);
        })
        .catch((error) => console.error(error));
    }

    if (command === "create additional payment") {
      // fetch(`https://europe-west3-venuehub-64e72.cloudfunctions.net/createPayment?bodyData=${JSON.stringify(body)}`) // Old request with body in the URL
      fetch("https://europe-west3-venuehub-64e72.cloudfunctions.net/createPayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Payment ID: ", data.paymentId);
          console.log("Client ID: ", data.clientId);
          console.log("Response Data 💚", data.responseData);
          setPaymentId(data.paymentId);
          setClientId(data.clientId);
          // THIS!!!!
          // storePaymentInBooking(booking, "additionalPayment", data.paymentId);
        })
        .catch((error) => console.error(error));
    }
  }

  useEffect(() => {
    if (paymentId && clientId) {
      SendInvoiceEmail(booking, paymentId, clientId, invoiceUrl);
    }
  }, [paymentId, clientId]);

  useEffect(() => {
    setBookingSummary((prev) => ({ summaryData }));
  }, [summaryData]);

  useEffect(() => {
    // saveSummary();
  }, []);

  // calculate total
  useEffect(() => {
    setAdditionalCostsExclVat(calculateAdditionalCosts(summaryData, "totalSum"));
    setAdditionalCostsVat(calculateAdditionalCosts(summaryData, "totalVat"));
    setAdditionalCostsIncVat(calculateAdditionalCosts(summaryData, "totalIncVat"));
    console.log("summaryData", summaryData);
    const summaryDataSum = summaryData.reduce((acc, post) => acc + (post.sum || 0), 0);
    // const summaryDataSum = summaryData.reduce((acc, post) => acc + ((!post.type === "Settlement Fee" && post.sum) || 0), 0); //let's don't include SettlementFee in the total.
    setAdditionalCosts(summaryDataSum);
    // setAdditionalCosts(40000);
    console.log("summaryData", summaryData);
    console.log("summaryDataSum", summaryDataSum);
    const tmpSum = summaryDataSum + makeNumber(booking.price) * 1.25;
    console.log("tmpSum", tmpSum, "summaryData", summaryData, "booking.price", booking.price);

    // alert(tmpSum);
    // setTotalCosts(tmpSum);
  }, [summaryData]);

  // useEffect(() => {
  //   setAdditionalCosts(summaryDataSum);
  // }, [summaryDataSum]);

  useEffect(() => {
    console.log(
      "additionalCostsExclVat",
      additionalCostsExclVat,
      "additionalCostsVat",
      additionalCostsVat,
      "additionalCostsIncVat",
      additionalCostsIncVat
    );
    setTotalCosts(bookingFeaturesSummaryIncVat + additionalCostsIncVat);
  }, [additionalCostsExclVat, additionalCostsVat, additionalCostsIncVat, bookingFeaturesSummaryIncVat]);

  useEffect(() => {
    console.log("totally", total);
  }, [total]);

  // useEffect(() => {
  //   const tmp = totalCosts - totalTickets.sum;
  //   setAmountToBePaid(tmp);
  // }, [ticketsTotal, totalCosts]);

  useEffect(() => {}, [amountToBePaid]);

  // calculate total vat (adding the venue rent at the end)
  const totalVat =
    summaryData &&
    summaryData.reduce((acc, post) => {
      const vat = post.vat && parseFloat(post.vat.replace("%", "")) / 100; // remove the % in order to calculate
      return acc + post.cost * post.quantity * vat;
    }, 0) + calculateBookingPrice(bookingFeatures, "totalVat");
  // makeNumber(booking.price) * 0.25; // this is wrong since vat may be different for different features included in the rent

  const handleTickets = () => {
    setDeductFromTickets(!deductFromTickets);
  };

  const handleCloseInvoice = () => {
    setShowInvoice(false);
  };

  // If "Tickets" are to be deducted, calculate tickets total.
  useEffect(() => {
    let sum = ticketsSold * ticketPrice;
    let vat = (parseFloat(ticketsVat.replace("%", "")) / 100) * sum;
    let total = sum + vat;
    // setTicketsTotal(total);
  }, [ticketsSold, ticketPrice, ticketsVat]);

  // Load the specific booking from Bookings
  useEffect(() => {
    getBooking();
    async function getBooking() {
      const docSnap = await getDoc(doc(db, "Bookings", bookingid));
      setBooking(docSnap.data());
      // console.log("BOOKING 💜", docSnap.data().agent.email);
      setBookingFeatures(docSnap.data().bookingFeatures);

      //setSummarydata
      let previousAdditionalCosts = docSnap.data().summary && docSnap.data().summary;

      //add settlementFee to summaryData if it doesn't exist
      // if (!previousAdditionalCosts.find((post) => post.type === "Settlement Fee")) {
      //   // addSettlementFee();
      //   let tmpSettlementFee = {};
      //   async function getAdditionalFees() {
      //     const docSnap = await getDoc(doc(db, "GeneralSettings", "AdditionalFees"));
      //     if (docSnap.exists()) {
      //       console.log("AdditionalFees:", docSnap.data());
      //       tmpSettlementFee = docSnap.data().settlementFee;
      //       setSettlementFee(tmpSettlementFee);
      //     }
      //   }
      //   await getAdditionalFees();
      //   // previousAdditionalCosts.push({ type: "Settlement Fee", quantity: 1, cost: 4, vat: "25%", sum: 4 });
      //   previousAdditionalCosts.push({
      //     type: "Settlement Fee",
      //     quantity: 1,
      //     cost: tmpSettlementFee.sum / (1 + removePercentage(tmpSettlementFee.vat) / 100),
      //     vat: tmpSettlementFee.vat,
      //     sum: tmpSettlementFee.sum,
      //   });
      //   console.log("previousAdditionalCosts", previousAdditionalCosts, "settlementFee", tmpSettlementFee);
      // }
      // docSnap.data().summary && setSummaryData(docSnap.data().summary);
      if (previousAdditionalCosts) {
        setSummaryData(previousAdditionalCosts);
      }

      //if deductFromTickets...
      setDeductFromTickets(docSnap.data().deductFromTickets);
      let tmpTickets = docSnap.data().tickets && docSnap.data().tickets;
      docSnap.data().tickets && setTicketData({ ...tmpTickets });
      setTimeout(() => {
        docSnap.data().ticketSummary ? setTicketsTotal(docSnap.data().ticketSummary.ticketsTotal) : setTicketsTotal(0);
      }, 1000);
      // console.log("TicketsTotal", docSnap.data().ticketSummary.ticketsTotal);
      await getPaymentPost(docSnap.data().bookingid);

      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    fetchTransactions(OCR);
    getFeesFromPaymentPosts();
  }, [OCR]);

  useEffect(() => {
    console.log("ticketsTotal changed..!", ticketsTotal);
  }, [ticketsTotal]);

  useEffect(() => {
    if (!Object.values(bookingFeatures).length) return;

    // setBookingFeaturesSummaryIncVat(calculateBookingPrice(bookingFeatures, "totalIncVat") + administrativeFees);
    setBookingFeaturesSummaryIncVat(calculateBookingPrice(bookingFeatures, "totalIncVat"));
    setBookingFeaturesSummaryExclVat(calculateBookingPrice(bookingFeatures, "totalSum"));
    setBookingFeatureSummaryVat(calculateBookingPrice(bookingFeatures, "totalVat"));
    // HHERE
    // alert("administrative");
    console.log("bookingFeatures for bookingfeaturesummary", bookingFeatures);
  }, [bookingFeatures, administrativeFees]);

  useEffect(() => {
    console.log("bookingFeaturesSummaryIncVat", bookingFeaturesSummaryIncVat);
    // alert("bookingFeaturesSummaryIncVat" + bookingFeaturesSummaryIncVat);
  }, [bookingFeaturesSummaryIncVat]);

  // useEffect(() => {
  //   console.log("administrativeFees", administrativeFees);
  // }, [administrativeFees]);

  // // calculate the sum field of all ticketposts if either 'quantity' or 'cost' fields are changed.
  // useEffect(() => {
  //   // setTicketData((prevTicketData) => [...prevTicketData, { ticketsSold: tmpTicketsSold, ticketSum: tmpTicketSum }]);

  //   console.log("ticketData", ticketData, ticketSummary);

  //   const newTicketData =
  //     ticketData &&
  //     Object.values(ticketData).map((post) => {
  //       const vat = post.vat ? parseFloat(post.vat.replace("%", "")) / 100 + 1 : 1.06; // remove the % in order to calculate
  //       const sum = parseFloat((post.quantity * post.cost * vat).toFixed(2));
  //       return { ...post, sum: sum };
  //     });

  //   // summarize total sold tickets and total sum from ticketsales.
  //   let tmpTicketsSold = newTicketData.reduce((acc, post) => acc + Number(post.quantity), 0);
  //   setTicketsSold(tmpTicketsSold);
  //   let tmpTicketsTotal = newTicketData.reduce((acc, post) => acc + post.sum, 0);
  //   // setTicketsTotal(tmpTicketsTotal); //HERE!

  //   // set ticketData and ticketSummary
  //   if (booking.ticketEventId) {
  //     return;
  //   }
  //   setTicketSummary({ ticketsSold: tmpTicketsSold, ticketsTotal: tmpTicketsTotal });

  //   setTicketData([...newTicketData]);
  // }, [
  //   ticketData &&
  //     Object.values(ticketData)
  //       .map((post) => `${post.quantity}-${post.cost}-${post.vat}`)
  //       .join("-"),
  // ]);

  // calculate the sum field of all posts if either 'quantity' or 'cost' fields are changed.
  useEffect(() => {
    const newSummaryData =
      summaryData &&
      summaryData.map((post) => {
        const vat = post.vat && parseFloat(post.vat.replace("%", "")) / 100 + 1; // remove the % in order to calculate
        const vatSum = post.vat && post.cost * post.quantity * (parseFloat(post.vat.replace("%", "")) / 100);
        // const sum = parseFloat((post.quantity * post.cost * vat).toFixed(2));
        const sum = parseFloat((post.quantity * post.cost * vat).toFixed(2));
        return { ...post, sum: sum, vatSum: vatSum };
      });
    setSummaryData(newSummaryData);
  }, [summaryData && summaryData.map((post) => `${post.quantity}-${post.cost}-${post.vat}`).join("-")]);

  // useEffect(() => {
  //   console.log("SUMMARYDATA", summaryData);
  // }, [summaryData]);

  // Calculate CO2e footprint
  useEffect(() => {
    // first for venue rent
    setCO2eVenue(Math.round(makeNumber(booking.price) * (1 + Number(venueVat.slice(0, -1)) / 100) * 0.035));
    // ...then for summary posts
    const postsSum =
      summaryData &&
      summaryData.map((post) => {
        const vat = post.vat && parseFloat(post.vat.replace("%", "")) / 100 + 1; // remove the % in order to calculate
        const vatSum = post.vat && post.cost * post.quantity * (parseFloat(post.vat.replace("%", "")) / 100);
        // const sum = parseFloat((post.quantity * post.cost * vat).toFixed(2));
        const sum = parseFloat((post.quantity * post.cost * vat).toFixed(2));
        return { ...post, sum: sum, vatSum: vatSum };
      });
    setCO2ePosts(postsSum * 0.007);
    // ...then for tickets
    setCO2eTickets(Math.round(ticketsTotal * 0.035));
    //...lastly sum all parts
    setCO2eSum(CO2ePosts + CO2eVenue + CO2eTickets);
  }, [summaryData]);

  // const bookingFee = (
  //   <tr>
  //     <td>Booking fee</td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //   </tr>
  // );

  // const transactionFee = (
  //   <tr>
  //     <td>Transaction fee</td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //     <td></td>
  //   </tr>
  // );

  // // in order to get OCR if it's not already set
  async function getAgreement() {
    if (!booking) return;
    if (!booking.agreementId) return;
    let tmp = {};
    const docSnap = await getDoc(doc(db, "Agreements", booking.agreementId));
    tmp = docSnap.data();
    console.log("agreement", tmp);
    setAgreement(tmp);
  }

  // // in order to get OCR if it's not already set
  // useEffect(() => {
  //   if (!OCR) getAgreement();
  // }, [OCR]);

  async function getPaymentPost(bookingid) {
    const querySnapshot = await getDocs(query(collection(db, "PaymentPosts"), where("bookingids", "array-contains", bookingid)));
    // const querySnapshot = await db.collection("PaymentPosts").where("bookingIds", "array-contains", bookingid).get();

    querySnapshot.forEach((doc) => {
      console.log("paymentpost", doc.data());
      doc.data().type === "BALANCE" && console.log("Balance", doc.data());
      doc.data().type === "BALANCE" && setTotalAmountToBePaidByNow(doc.data().totalAmountToBePaidByNow);
      doc.data().type === "BALANCE" && setOCR(doc.data().OCR);
    });
  }

  async function fetchTransactions(OCR) {
    try {
      // alert(agreement.OCR);
      const response = await axios.get(`https://europe-west3-venuehub-64e72.cloudfunctions.net/sumOCRTransactions?OCR=${OCR}`);
      // const response = await axios.get(`http://localhost:5001/venuehub-64e72/us-central1/sumOCRTransactions?OCR=${agreement.OCR}`);  // For testing purposes
      console.log("response", response.data);
      console.log("Total amount", response.data.totalAmount);
      setAmountAlreadyPaid(response.data.totalAmount);
      return response.data.totalAmount;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    setTotalPrepaidConsidered(totalAmountToBePaidByNow + additionalCostsIncVat - amountAlreadyPaid - totalTickets.sum);
    // setMissingInBalance(totalCosts - totalTickets.sum - amountAlreadyPaid);
    setMissingInBalance(totalAmountToBePaidByNow - totalTickets.sum - amountAlreadyPaid);

    console.log("totalAmountToBePaidByNow", totalAmountToBePaidByNow);
    console.log("additionalCosts", additionalCosts);
    console.log("amountAlreadyPaid", -amountAlreadyPaid);
    console.log("totalTickets.sum", -totalTickets.sum);
    console.log("totalCosts", totalCosts);
    // setAmountToBePaid(totalAmountToBePaidByNow + additionalCosts - amountAlreadyPaid - totalTickets.sum);
    setAmountToBePaid(totalAmountToBePaidByNow + additionalCostsIncVat - amountAlreadyPaid - totalTickets.sum);
    // if (totalAmountToBePaidByNow === 0) setAmountToBePaid(totalCosts - amountAlreadyPaid - totalTickets.sum);
    if (totalAmountToBePaidByNow === 0) setAmountToBePaid(totalCosts - amountAlreadyPaid - totalTickets.sum);

    // alert(`additionalcosts = ${additionalCosts}`);
    // setTotalPrepaidConsidered(totalCosts - totalTickets.sum - amountAlreadyPaid);
  }, [totalCosts, amountAlreadyPaid, totalTickets.sum, totalAmountToBePaidByNow, additionalCosts]);

  useEffect(() => {
    console.log("totalPrepaidConsidered", totalPrepaidConsidered);
  }, [totalPrepaidConsidered]);

  // useEffect(() => {
  //   booking.price * (1 + Number(venueVat.slice(0, -1)) / 100) - amountAlreadyPaid
  //     ? setRemainingToPay(booking.price * (1 + Number(venueVat.slice(0, -1)) / 100) - amountAlreadyPaid)
  //     : setRemainingToPay(0);
  // }, [booking.price, venueVat, amountAlreadyPaid]);

  useEffect(() => {
    console.log("amountAlreadyPaid", amountAlreadyPaid);
    bookingFeaturesSummaryIncVat - amountAlreadyPaid
      ? setRemainingToPay(bookingFeaturesSummaryIncVat - amountAlreadyPaid - totalTickets.sum)
      : setRemainingToPay(0);
    // setRemainingToPay(bookingFeaturesSummaryIncVat - amountAlreadyPaid - totalTickets.sum);
    console.log("totalAmountToBePaidByNow", totalAmountToBePaidByNow);
    console.log("amountAlreadyPaid", amountAlreadyPaid);
    console.log("totalTickets.sum", totalTickets.sum);
    console.log("remaining", totalAmountToBePaidByNow - amountAlreadyPaid - totalTickets.sum);
  }, [bookingFeaturesSummaryIncVat, booking.price, venueVat, amountAlreadyPaid, administrativeFees]);

  return (
    <div style={{ position: "relative", marginLeft: "50px" }}>
      <Grid
        container
        p={4}
        xs={11}
        flexDirection="column"
        gap={2}
        mx={2}
        sx={{ background: "#1a1a1a", borderRadius: "15px", border: "1px solid #00ffd4" }}
        style={{ position: "relative", minWidth: "1000px" }}
      >
        {!loaded && (
          <CircularProgress style={{ right: "10px", top: "10px", width: "15px", height: "15px", zIndex: "2", position: "absolute" }} />
        )}
        <h1>Summary</h1>

        {/* <BookingPayments booking={booking} /> */}

        <table className="summaryTable">
          <tr>
            <td>Artist</td>
            <td>{booking.artist && booking.artist.artist}</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{booking.date}</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{numberFormatted(booking.price)}</td>
          </tr>
          <tr>
            <td>OCR</td>
            <td>{OCR}</td>
          </tr>
          {/* <tr>
            <td>Tickets{deductFromTickets}</td>
            <td>
              <Switch checked={deductFromTickets} onChange={handleTickets}>
                Tickets
              </Switch>
            </td>
            <td>Provided by Tixly</td>
          </tr> */}

          <tr>
            <th></th>
            <th>Type</th>
            <th>Quantity</th>
            <th>Cost</th>
            <th>Vat</th>
            <th>Sum</th>
            <th style={{ opacity: "0.5" }} colSpan="1"></th>
            <th style={{ opacity: "0.5" }}>🌿 CO2e</th>
          </tr>
          {deductFromTickets && <tr>Ticket summary</tr>}
          {console.log("ticketData", ticketData)}
          {/* Dynamic deduct from tickets */}
          {deductFromTickets &&
            Object.values(ticketData).map((post, index) => (
              <tr key="index">
                <td>{index + 1}</td>
                <td>
                  <Autocomplete
                    disabled={ticketService}
                    value={post.type}
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        handleTicketData("type", newValue.label, index);
                      }
                    }}
                    size="small"
                    id="ticketPostType"
                    options={ticketTypes}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} onChange={(e) => handleTicketData("type", e.target.value, index)} />}
                  />
                </td>
                <td>
                  <TextField
                    disabled={ticketService}
                    size="small"
                    type="number"
                    value={post.quantity}
                    onChange={(e) => handleTicketData("quantity", e.target.value, index)}
                    onBlur={(e) => handleTicketData("quantity", e.target.value, index, "number")}
                  />
                </td>
                <td>
                  <TextField
                    disabled={ticketService}
                    size="small"
                    type="number"
                    value={post.cost}
                    onChange={(e) => handleTicketData("cost", e.target.value, index)}
                  />
                </td>
                <td>
                  <Autocomplete
                    disabled={ticketService}
                    value={post.vat ? post.vat : "6%"}
                    disablePortal
                    onChange={(e, newValue) => {
                      handleTicketData("vat", newValue.label, index);
                      if (newValue !== null) {
                        handleTicketData("vat", newValue.label, index);
                      }
                    }}
                    size="small"
                    id="ticketPostVat"
                    options={postVat}
                    sx={{ width: "200" }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "150" }} onChange={(e) => handleTicketData("vat", e.target.value, index)} />
                    )}
                  />
                </td>
                <td>
                  <TextField size="small" disabled={true} value={numberFormatted(post.sum)} />
                </td>
                {!ticketService && (
                  <td style={{ width: "200px" }}>
                    <IconButton onClick={() => addTicketPost(index)} size="small">
                      <AddIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteTicketPost(index)} size="small">
                      <RemoveIcon />
                    </IconButton>
                  </td>
                )}
              </tr>
            ))}

          {/* If venue deducts rent fee and other from ticket sales */}
          {deductFromTickets && booking.tickets && (
            <tr style={{ borderBottom: "20px solid #fa5a2600" }}>
              <td></td>
              <td
              // colSpan="3" align="right"
              >
                {/* <TextField size="small" value={"Ticket summary"} sx={{ background: "#22ccff44", borderRadius: "10px" }} disabled /> */}
              </td>
              <td>
                <TextField size="small" value={totalTickets.count} type="number" disabled />
              </td>
              <td></td>
              <td></td>
              <td>
                <TextField
                  size="small"
                  defaultValue={0}
                  value={totalTickets && numberFormatted(totalTickets.sum)}
                  sx={{ background: "#22ccff44", borderRadius: "10px" }}
                  disabled
                />
              </td>
              <td></td>
              <td className="CO2e">{Math.round(totalTickets.count * 0.035)} kg</td>
            </tr>
          )}
          {/* {booking.deductFromTickets && (
            <tr>
              <td></td>
              <td>
                <TextField size="small" disabled={true} value={"Free tickets"} sx={{ borderRadius: "10px" }} />
              </td>
              <td>
                <TextField
                  size="small"
                  // defaultValue={booking.tickets && booking.tickets.free ? booking.tickets.free : 0}
                  value={ticketsFree}
                  sx={{ width: "150" }}
                  onChange={(e) => setTicketsFree(e.target.value)}
                />
              </td>
              <td></td>
              <td></td>
            </tr>
          )} */}
          <tr>
            <td colSpan="3">Initial agreement total</td>

            <td>
              <TextField size="small" disabled={true} value={bookingFeaturesSummaryExclVat} />
            </td>
            <td>
              <TextField size="small" disabled={true} value={bookingFeatureSummaryVat} />
            </td>
            <td>
              <TextField
                size="small"
                disabled={true}
                // value={numberFormatted(makeNumber(booking.price) * (1 + Number(venueVat.slice(0, -1)) / 100))}
                // value={numberFormatted(bookingFeaturesSummary + administrativeFees)}
                value={numberFormatted(bookingFeaturesSummaryIncVat)}
              />
            </td>
            <td></td>
            <td className="CO2e">{Math.round(makeNumber(booking.price) * (1 + Number(venueVat.slice(0, -1)) / 100) * 0.035)} kg</td>
          </tr>
          {/* <tr>
            <td></td>
            <td>
              <Tooltip title="Transaction fees, reminders, etc...">
                <TextField size="small" value="Administrative" disabled />
              </Tooltip>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <TextField size="small" value={administrativeFees} disabled />
            </td>
          </tr> */}
          {/* {remainingToPay && (
            <tr style={{ borderTop: "5px solid transparent" }}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <TextField
                  size="small"
                  label="Remaining to pay"
                  value={numberFormatted(remainingToPay)}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
            </tr>
          )} */}
          <tr>
            <td style={{ opacity: "0.5" }}>{/* Included in venue rent: */}</td>
            <td colspan="5">{/* <BookingFeaturesSummary booking={booking} /> */}</td>
          </tr>
          <tr>
            <td>Additional Costs</td>
          </tr>
          {summaryData &&
            summaryData.map((post, index) => (
              <>
                {post.type !== "Settlement Fee OLD" && ( // Don't show settlement fee in summary
                  <tr key="index">
                    <td>{index + 1}</td>
                    <td>
                      <Autocomplete
                        value={post.type}
                        onChange={(e, newValue) => {
                          if (newValue !== null) {
                            handleChange("type", newValue.label, index);
                          }
                        }}
                        size="small"
                        id="summaryPostType"
                        options={postTypes}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} onChange={(e) => handleChange("type", e.target.value, index)} />}
                      />
                    </td>
                    <td>
                      <TextField
                        size="small"
                        type="number"
                        value={post.quantity}
                        onChange={(e) => handleChange("quantity", e.target.value, index)}
                      />
                    </td>
                    <td>
                      <TextField
                        size="small"
                        type="number"
                        value={post.cost}
                        onChange={(e) => handleChange("cost", e.target.value, index, "number")}
                        style={{ minWidth: "100px" }}
                      />
                    </td>
                    <td>
                      <Autocomplete
                        value={post.vat}
                        disablePortal
                        onChange={(e, newValue) => {
                          if (newValue !== null) {
                            handleChange("vat", newValue.label, index);
                          }
                        }}
                        size="small"
                        id="summaryPostVat"
                        options={postVat}
                        sx={{ width: "200" }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "150" }} onChange={(e) => handleChange("vat", e.target.value, index)} />
                        )}
                      />
                    </td>
                    <td>
                      <TextField size="small" disabled={true} value={post.sum && numberFormatted(post.sum)} style={{ minWidth: "120px" }} />
                    </td>
                    <td style={{ width: "200px" }}>
                      <IconButton onClick={() => addPost(index)} size="small">
                        <AddIcon />
                      </IconButton>
                      <IconButton onClick={() => deletePost(index)} size="small">
                        <RemoveIcon />
                      </IconButton>
                    </td>
                    <td className="CO2e">{Math.round(post.sum * 0.007)} kg</td>
                  </tr>
                )}
              </>
            ))}
          <tr style={{ borderTop: "20px solid #22222200" }}>
            <td colSpan="3" align="right">
              Summary Costs:
            </td>
            <td>
              <TextField size="small" disabled={true} value={numberFormatted((totalCosts - totalVat).toFixed(2))} label="Summary" />
              {/* <TextField
                size="small"
                disabled={true}
                value={numberFormatted(bookingFeaturesSummaryExclVat + additionalCostsExclVat)}
                label="Summary"
              /> */}
            </td>
            <td>
              <TextField size="small" disabled={true} value={numberFormatted(totalVat.toFixed(2))} label="VAT" />
              {/* <TextField size="small" disabled={true} value={numberFormatted(bookingFeatureSummaryVat + additionalCostsVat)} label="VAT" /> */}
            </td>
            <td>
              <TextField
                size="small"
                disabled={true}
                // value={totalCosts ? totalCosts : 0}
                value={bookingFeaturesSummaryIncVat + additionalCostsIncVat}
                label="Costs Total"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ "& .MuiInputBase-input.Mui-disabled": { color: "yellow" } }}
              />
            </td>
            <td style={{ opacity: "0.1" }}>
              <Tooltip title="🌿 Activate climate compensation">
                <FormControl>
                  <Switch label=""></Switch>
                </FormControl>
              </Tooltip>
            </td>
            {/* <td>4 384 kg</td> */}
          </tr>
          {/* <tr>
            <td>Amount paid</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <TextField value={amountAlreadyPaid} size="small" />
            </td>
          </tr>
          <tr>
            <td>Ticket sales within scope of agreement</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <TextField value={numberFormatted(allTicketsWithinAgreement)} size="small" disabled />
            </td>
          </tr>
          <tr>
            <td>Amount to be paid by now</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <TextField value={numberFormatted(totalAmountToBePaidByNow)} size="small" />
            </td>
          </tr> */}
          {/* Trailing costs (checking agreement balance) */}
          {missingInBalance - totalCosts > 0 && (
            <tr>
              <td>Overdue payment</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <TextField value={numberFormatted(missingInBalance - totalCosts)} size="small" />
              </td>
            </tr>
          )}

          <tr style={{ borderTop: "20px solid #22222200" }}>
            <td colSpan="3" align="right">
              {amountToBePaid > 0 ? "Amount to recieve" : "Amount to pay"}
            </td>
            <td></td>
            <td></td>
            <td>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                disabled={true}
                value={amountToBePaid > 0 ? numberFormatted(amountToBePaid) : numberFormatted(-amountToBePaid)}
                label={amountToBePaid > 0 ? "Amount to recieve" : "Amount to pay"}
                sx={{ background: "#ffc10711", borderRadius: "10px", fontWeight: "900", color: "white" }}
              />
            </td>
            <td></td>
            <td></td>
          </tr>
        </table>
        <Grid container gap={2}>
          {/* {booking.summary && ( */}
          {booking && (
            <Button
              width={200}
              variant="outlined"
              onClick={() => saveForLater()}
              endIcon={saving ? <CircularProgress style={{ width: "15px", height: "15px" }} /> : <ViewCompactIcon fontSize="small" />}
            >
              Save for later
            </Button>
          )}
          {booking.summary && (
            <Tooltip
              title={
                !currentVenue.IBAN ||
                !currentVenue.BIC ||
                currentVenue.IBAN === "" ||
                currentVenue.BIC === "" ||
                currentVenue.IBAN.length < 22 ||
                currentVenue.IBAN.length > 34 ? (
                  <>Enter you IBAN and BIC in venue settings</>
                ) : (
                  <>Send settlement and payment link to customer</>
                )
              }
            >
              {/* <Button
                mx={3}
                color="warning"
                variant="outlined"
                onClick={() => finalize()}
                endIcon={<SendIcon fontSize="small" />}
                disabled={!currentVenue.IBAN || !currentVenue.BIC || currentVenue.IBAN === "" || currentVenue.BIC === "" ? true : false}
              >
                Finalize
              </Button> */}
              <Button
                // disabled // Disable button for now until the payment system is up and running
                mx={3}
                color="warning"
                variant="outlined"
                onClick={() => finalize()}
                endIcon={<SendIcon fontSize="small" />}
                // disabled={!currentVenue.IBAN || !currentVenue.BIC || currentVenue.IBAN === "" || currentVenue.BIC === "" ? true : false}
              >
                Finalize
              </Button>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12} gap={2}></Grid>
      </Grid>
      {/* <Grid container xs={12} gap={2} p={4}>
        <Modal
          open={showInvoice}
          onClose={handleCloseInvoice}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ background: "#00000099" }}
        >
          <Box style={boxStyle}>
            <div>
              <VenueInvoice
                bookingid={bookingid}
                paymentButton={!booking.deductFromTickets && true}
                payoutButton={booking.deductFromTickets && true}
                sendButton={true}
                handleSend={handleSend}
                close={handleCloseInvoice}
              />
            </div>
          </Box>
        </Modal>
      </Grid> */}
      <Grid container xs={12} gap={2} p={4}>
        {OCR}
        <Modal
          open={showInvoice}
          onClose={handleCloseInvoice}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ background: "#00000099" }}
        >
          <Box style={boxStyle}>
            <div>
              <Settlement
                bookingid={bookingid}
                additionalCosts={additionalCostsIncVat}
                remainingToPay={remainingToPay}
                OCR={OCR}
                amountAlreadyPaid={amountAlreadyPaid}
                // booking={booking}
                // paymentButton={!booking.deductFromTickets && true}
                // payoutButton={booking.deductFromTickets && true}
                // sendButton={true}
                // handleSend={handleSend}
                close={handleCloseInvoice}
              />
              {/* <VenueInvoice
                bookingid={bookingid}
                paymentButton={!booking.deductFromTickets && true}
                payoutButton={booking.deductFromTickets && true}
                sendButton={true}
                handleSend={handleSend}
                close={handleCloseInvoice}
              /> */}
            </div>
          </Box>
        </Modal>
      </Grid>
      {console.log("booking", booking)}
    </div>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};
