/** @format */

import React from "react";

const IncomingPing = () => {
  return (
    <audio controls autoplay volume="0.5" style={{ zIndex: "50", display: "none" }}>
      <source
        src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/sounds%2Fping.mp3?alt=media&token=48157db6-eafc-42f0-a4db-8e1fd0c09ff1"
        type="audio/mp3"
      />
    </audio>
  );
};

export default IncomingPing;
