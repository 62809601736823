/** @format */

import { Checkbox, FormControlLabel, Slider } from "@mui/material";
import React from "react";
import DisplayObject from "../../helpers/displayObject";
import { numberFormatted } from "../../helpers/Numbers";
import PieChartCenteredLabel from "../UI helpers/PieChartCenteredLabel";

const Water = ({ handleChange, formData }) => {
  return (
    <div className="visualData">
      <h7>Water</h7>
      <div className="dataInput">
        <Slider
          name={"waterUse"}
          label="Quantity of water"
          value={formData.waterUse ? formData.waterUse : 1000}
          min={1000}
          max={200000}
          step={1000}
          marks
          valueLabelDisplay={formData.waterUse === "not available" ? "off" : "on"}
          valueLabelFormat={(v) => `${v.toLocaleString()} litres`}
          onChange={(e) => handleChange("waterUse", e.target.value)}
          style={{ width: "300px", marginTop: "30px" }}
        />
        {formData["waterUseCO2e"] && <div className="CO2eDisplay">CO2e: {numberFormatted(formData["waterUseCO2e"])} kg</div>}

        {/* <FormControlLabel
          control={<Checkbox checked={formData.waterUse === "not available"} onChange={(e) => handleChange("waterUse", "not available")} />}
          label="I don't know"
        /> */}
      </div>
    </div>
  );
};

export default Water;
