/** @format */

import { Grid } from "@mui/material";
import React from "react";
import DataSheetPremises from "../components/VisualData/DataSheetPremises";

const DataSheet = () => {
  return (
    <Grid mt={8} p={4}>
      <DataSheetPremises />
    </Grid>
  );
};

export default DataSheet;
