/** @format */

//Dark Blue
// export const primaryColor = "#02ffd4";
// export const primaryColorLight = "#6effff";
// export const primaryColorDark = "#222222";
// export const secondaryColor = "#184c5c";
// export const secondaryColorLight = "#487889";
// export const secondaryColorDark = "#002432";
// export const tertiaryColor = "#ffd402";
// export const waterColor = "#00373c";

//Grey/Brown
export const primaryColor = "#fa5a26";
export const primaryColorLight = "#555555";
export const primaryColorDark = "#03bd98";
export const secondaryColor = "#fa5a26";
export const secondaryColorLight = "#fa5a26";
export const secondaryColorDark = "#333333";
export const tertiaryColor = "#222222";
export const waterColor = "#666666";

//Light
// export const primaryColor = "#999999";
// export const primaryColorLight = "#666666";.
// export const primaryColorDark = "#bbbbbb";
// export const secondaryColor = "#fff";
// export const secondaryColorLight = "#f0dcca";
// export const secondaryColorDark = "#f0dcca";
// export const tertiaryColor = "#f0dcca";
// export const waterColor = "#bbbbbb";

//Black
// export const primaryColor = "#999999";
// export const primaryColorLight = "#00373c";
// export const primaryColorDark = "#00373c";
// export const secondaryColor = "#ffb900";
// export const secondaryColorLight = "#ffb900";
// export const secondaryColorDark = "#444444";
// export const tertiaryColor = "yellow";
// export const waterColor = "#222222";
// export const waterColorLight = "#eeeeee";
// export const waterColorDark = "#222222";

//Experimental
// export const primaryColor = "#999999";
// export const primaryColorLight = "#00373c";
// export const primaryColorDark = "#00373c";
// export const secondaryColor = "#ffb900";
// export const secondaryColorLight = "#ffb900";
// export const secondaryColorDark = "#111";
// export const tertiaryColor = "yellow";
// export const waterColor = "#111";
// export const waterColorLight = "#eeeeee";
// export const waterColorDark = "#222";
