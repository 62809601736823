/** @format */

import { Button, Grid } from "@mui/material";
import React from "react";
import VenueInvoice from "../finshark/VenueInvoice";

const Invoice = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const response = urlParams.get("bookingid");

  return (
    <Grid container xs={12} p={4}>
      Invoice
      <Grid item xs={3}>
        <VenueInvoice />
        {/* <Button fullWidth variant="outlined" color="primary" onClick={() => createPayment({ bodyData, setPaymentId, setClientId })}>
          Pay direct
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default Invoice;
