/** @format */

import React, { useEffect, useState } from "react";
import { List, ListItem, IconButton, ListItemText, Grid, ListItemAvatar, Avatar, Button } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

function DayInCalendarQue() {
  const selectedDate = useSelector((state) => state.counter.selectedDate);
  //   const bookings = useSelector((state) => state.counter.bookings);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const currentBooking = useSelector((state) => state.counter.currentBooking);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const [bookingsSelectedDate, setBookingsSelectedDate] = useState([]);

  // const GreenBlob = () => {
  //   return <div className="greenBlob"></div>;
  // };
  // const RedBlob = () => {
  //   return <div className="redBlob"></div>;
  // };
  // const OrangeBlob = () => {
  //   return <div className="orangeBlob"></div>;
  // };
  // const GreyBlob = () => {
  //   return <div className="greyBlob"></div>;
  // };

  const statusOrder = {
    Confirmed: 1,
    "Awaiting Artist": 2,
    "Pending Request": 3,
    Killed: 4,
  };

  async function handleDelete(bookingid) {
    // await deleteDoc(doc(db, "Bookings", currentBookingId));
    await updateDoc(doc(db, "Bookings", bookingid), {
      status: "Killed",
    });
    // handleClose();
  }

  useEffect(() => {
    let bookingsThisDate = currentBookings.filter(
      (booking) => new Date(booking.date).toLocaleString() === new Date(selectedDate).toLocaleString()
    );
    setItems(bookingsThisDate);
    console.log("this", bookingsThisDate);
    console.log("items", items);
  }, [currentBookings]);

  const [items, setItems] = useState([]);

  const moveItemUp = (index) => {
    if (index === 0) return;
    const newItems = [...items];
    const temp = newItems[index];
    newItems[index] = newItems[index - 1];
    newItems[index - 1] = temp;
    setItems(newItems);
  };

  const moveItemDown = (index) => {
    if (index === items.length - 1) return;
    const newItems = [...items];
    const temp = newItems[index];
    newItems[index] = newItems[index + 1];
    newItems[index + 1] = temp;
    setItems(newItems);
  };

  //   function sortItems() {
  //     items.sort((a, b) => {
  //       return statusOrder[a.status] - statusOrder[b.status];
  //     });
  //   }
  useEffect(() => {
    items.sort((a, b) => {
      return statusOrder[a.status] - statusOrder[b.status];
    });
    console.log("ITEMS:", items);
  }, [items]);

  const confirmed = <div style={{ width: "15px", height: "15px", background: "green", borderRadius: "10px" }}></div>;
  const awaiting = <div style={{ width: "15px", height: "15px", background: "orange", borderRadius: "10px" }}></div>;
  const pending = <div style={{ width: "15px", height: "15px", background: "red", borderRadius: "10px" }}></div>;
  const killed = <div style={{ width: "15px", height: "15px", background: "grey", borderRadius: "10px" }}></div>;
  const blocked = <div style={{ width: "15px", height: "15px", background: "pink", borderRadius: "10px" }}></div>;

  return (
    <>
      {items.length > 0 ? (
        <Grid xs={12} sm={12} overflow="scroll" mt={4}>
          <h6>Waiting List</h6>
          <table className="selectedDateItem">
            <thead style={{ background: "#333" }}>
              <th>Up</th>
              <th>Event</th>
              <th></th>
              <th>Created</th>
              <th>Status</th>
              <th>Time slot</th>
              <th>Get in</th>
              <th></th>
              <th></th>
              <th>Down</th>
            </thead>

            {items.map((item, index) => (
              <>
                {item.status !== "Killed" && (
                  <tr>
                    <td>
                      <IconButton onClick={() => moveItemUp(index)} size="small" disabled={index < 1}>
                        <KeyboardArrowUpIcon />
                      </IconButton>
                    </td>

                    <td>
                      <itemImage>
                        <img src={item.artist.image} />
                      </itemImage>
                    </td>
                    <td>{item.artist.artist}</td>
                    <td>{item.created}</td>
                    <td>
                      {item.status === "Confirmed" ? (
                        <>{confirmed}</>
                      ) : item.status === "Pending Request" ? (
                        <>{pending}</>
                      ) : item.status === "Awaiting Artist" ? (
                        <>{awaiting}</>
                      ) : item.status === "Killed" ? (
                        <>{killed}</>
                      ) : item.status === "Blocked" ? (
                        <>{blocked}</>
                      ) : null}
                    </td>
                    <td style={{ width: "100px" }}>
                      {item.loadIn}-{item.loadOut}
                    </td>
                    <td style={{ width: "80px" }}>{item.getIn}</td>
                    <td>{/* {item.status === "Pending Request" && <Button>Accept</Button>} */}</td>
                    <td>
                      {/* {item.status === "Pending Request" && (
                        <ConfirmDialog onConfirm={() => handleDelete(item.bookingid)} size={"small"} variant={""} color={""}>
                          <DeleteIcon size="small" /> Decline
                        </ConfirmDialog>
                      )}
                      {item.status === "Awaiting Artist" && (
                        <ConfirmDialog onConfirm={() => handleDelete(item.bookingid)} size={"small"} variant={""} color={""}>
                          <DeleteIcon size="small" /> Cancel
                        </ConfirmDialog>
                      )} */}
                    </td>
                    <td>
                      <IconButton onClick={() => moveItemDown(index)} size="small" disabled={index === items.length - 1}>
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </table>
        </Grid>
      ) : (
        <Grid item mt={4}>
          <h6>No bookings yet</h6>
        </Grid>
      )}
    </>
  );
}

export default DayInCalendarQue;
