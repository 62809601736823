/** @format */

import React from "react";

const WhatDayIsIt = (props) => {
  var numberOfLetters = 3;
  if (props.numberOfLetters) numberOfLetters = props.numberOfLetters;
  let d = new Date(props.date);
  let day = d.getDay();
  const dayOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const result = dayOfTheWeek[day];

  // Added extra space " " after result...
  if (result !== undefined) return result.slice(0, numberOfLetters) + " ";
  // else return "day";
  else {
    // return "☠";
  }
};

export default WhatDayIsIt;
