/** @format */

export function updateAccessibleVenuesWhenCurrentVenue(accessibleVenues, currentVenue) {
  // turn formdata.products into a nested object with productid as key
  const accessiblesObject = accessibleVenues.reduce((acc, obj) => {
    acc[obj.venueid] = obj;
    return acc;
  }, {});

  accessiblesObject[currentVenue.venueid] = currentVenue;
  console.log("accessiblesObject", accessiblesObject);
  //   turn the nested and updated object back into an array
  const arrayAgain = Object.values(accessiblesObject);

  // and return it to be stored in accessibleVenues
  return arrayAgain;
}
