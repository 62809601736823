/**
 * Old PreferredDays
 *
 * @format
 */

import { FormControlLabel, FormGroup, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { storeCurrentVenue, storePrefDays } from "../../features/venueFilter/venueFilterSlice";

var numberOfLetters = 3;

const PreferredDays = (props) => {
  const dispatch = useDispatch();
  const prefDays = useSelector((state) => state.counter.prefDays);

  if (props.numberOfLetters) numberOfLetters = props.numberOfLetters;

  const [days, setDays] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);

  const { specificDays } = props;

  // If specificDays are applied dispatch all days to prefDays (otherwise old prefDays may run along)
  useEffect(() => {
    specificDays && dispatch(storePrefDays(specificDays));
  }, [specificDays]);

  const handleChange = (event, newDays) => {
    dispatch(storePrefDays(newDays));
  };

  return (
    <div id="preferreddays">
      {/* {currentVenue.preferredDays} */}
      <ToggleButtonGroup color="primary" size="small" value={prefDays} onChange={handleChange} aria-label="day">
        {days.map((day) => (
          <ToggleButton value={day} sx={{ fontSize: 11 }} aria-label={day} disabled={specificDays && !specificDays.includes(day)}>
            {day.slice(0, numberOfLetters)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default PreferredDays;
