/** @format */

import { Badge, Button, CircularProgress, Grid, Tooltip } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import DisplayObject from "../../helpers/displayObject";
import shortText from "../../helpers/shortText";
import { wait } from "../MiscUIComponents/Wait";
import BatchEditBookings from "./BatchEditBookings";
import SelectBookings from "./SelectBookings";
import GetBookings from "../../helpers/GetBookings";

const IncomingRequests = ({ closeRequests, incomingAgreementId }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const [loading, setLoading] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [currentAgreementId, setCurrentAgreementId] = useState([]);
  const [bookingsForAgreementId, setBookingsForAgreementId] = useState([]);
  const currentDate = new Date();

  useEffect(() => {
    console.log("currentVenue", currentVenue);
    console.log("currentVenueBookings", currentVenueBookings);
    const filteredBookings =
      // currentVenue.bookings && currentVenue.bookings.filter((booking) => booking.status === "Pending Request" && booking.agreementId);
      currentVenueBookings && currentVenueBookings.filter((booking) => booking.status === "Pending Request" && booking.agreementId);
    setBookings(filteredBookings);
    // }, [currentVenue]);
  }, [currentVenueBookings]);

  useEffect(() => {
    console.log(bookings);
    if (bookings !== null) {
      const uniqueAgreementIds = bookings && Array.from(new Set(bookings.map((booking) => booking.agreementId)));

      const result = uniqueAgreementIds.map((uniqueId) => {
        const matchingBookings = bookings.filter((booking) => booking.agreementId === uniqueId);
        return {
          agreementId: uniqueId !== undefined ? uniqueId : "none",
          artist: matchingBookings[0].artist, // Assuming all artists for the same agreementId are the same
          count: matchingBookings.length,
        };
      });
      console.log("REZULT", result);
      setAgreements(result);
    } else {
      setBookings(currentVenueBookings);
    }
  }, [bookings]);

  // Automatically "click" previous agreementId, incomingAgreementId or use the first agreementId
  useEffect(() => {
    if (currentAgreementId.length) return; // an agreementId is already set
    function autoClickFirst() {
      if (agreements.length > 0) {
        handleClickedAgreement(incomingAgreementId || agreements[0].agreementId);
      }
    }
    autoClickFirst();
  }, [agreements]);

  useEffect(() => {
    //Also when something is edited this is needed to get the rows updated in the datagrid
    handleClickedAgreement(currentAgreementId);
  }, [bookings]);

  // When a request is clicked
  function handleClickedAgreement(agreementId) {
    if (bookings === null) return;
    console.log("currentVenueBookings", currentVenueBookings);
    const filteredBookings = bookings.filter((booking) => booking.agreementId === agreementId);
    console.log("Filtered Bookings", filteredBookings);
    setBookingsForAgreementId(filteredBookings);
    setCurrentAgreementId(agreementId);
  }

  return (
    <>
      {currentVenueBookings === null && <GetBookings />}
      <Grid item xs={12} p={1} marginTop={{ xs: 6, sm: 0 }}>
        {loading ? (
          <CircularProgress style={{ zoom: "0.4" }} />
        ) : (
          <div style={{ display: "flex", gap: "20px", textAlign: "center", fontSize: "14px", flexWrap: "wrap" }}>
            {agreements.map((item, index) => (
              <Tooltip title={`Edit ${item.count} dates`} followCursor>
                <div onClick={() => handleClickedAgreement(item.agreementId)}>
                  <div
                    className="inComingRequests"
                    style={{
                      // transform: `rotate(${index * -20}deg)`,
                      width: item.agreementId === currentAgreementId ? "80px" : "60px",
                      height: item.agreementId === currentAgreementId ? "80px" : "60px",
                      borderRadius: "60px",
                      overflow: "hidden",
                      backgroundImage: `url(${item.artist.image})`,
                      backgroundSize: "cover",
                      backgroundColor: item.agreementId === currentAgreementId ? "#ffffff44" : "#00000044",
                      backgroundBlendMode: "multiply",
                      // filter: item.agreementId === currentAgreementId ? "drop-shadow(0px 0px 5px #ffffff99)" : "blur(0px)",
                      // filter: "drop-shadow(0px 0px 3px #ffffff99)",
                      opacity: item.agreementId === currentAgreementId ? "1" : "0.8",
                      // animationDelay: `${index * 3}s`,
                      animation: `spinIn ${index / 5}s`,
                    }}
                  >
                    {item.agreementId === currentAgreementId ? (
                      ""
                    ) : (
                      <div style={{ fontSize: "40px", fontWeight: "900", opacity: "1" }}>{item.count}</div>
                    )}
                  </div>

                  <span>{shortText(item.artist.artist, 9)}</span>
                </div>
                {/* </Badge> */}
              </Tooltip>
            ))}
          </div>
        )}
        <div style={{ marginTop: "10px" }}>{<BatchEditBookings bookings={bookingsForAgreementId} />}</div>
        <Button onClick={() => closeRequests()}>Close</Button>
      </Grid>
    </>
  );
};

export default IncomingRequests;
