/** @format */

import React from "react";
import { useSelector } from "react-redux";
import CircleGraph from "../../components/UI helpers/CircleGraph";

const ShowOccupancyData = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentMonth = useSelector((state) => state.counter.currentMonth);
  const currentYear = useSelector((state) => state.counter.currentYear);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const confirmedBookings = currentBookings.filter((item) => item.status === "Confirmed");
  const occupancyRate = Math.round((confirmedBookings.length / daysInMonth) * 100);

  console.log(daysInMonth, currentBookings, confirmedBookings);
  return (
    <div>
      <div style={{ float: "left", padding: "20px", borderRadius: "10px" }}>
        <p>Occupancy</p>
        <CircleGraph size="40" value={occupancyRate} stroke="5" />
      </div>
      {/* <div style={{ float: "left", padding: "20px", borderRadius: "10px" }}>
        <p>Overall Sales</p>
        <CircleGraph size="40" value="random" stroke="5" />
      </div> */}
    </div>
  );
};

export default ShowOccupancyData;
