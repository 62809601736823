/** @format */

import React, { useState, useEffect } from "react";

const BookingPayments = ({ booking }) => {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (booking && booking.payments) {
      setPayments(booking.payments);
    }
  }, [booking]);

  return (
    <div className="paymentStatus">
      <h6>Payment status for Booking</h6>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>ID</th>
            <th>Status</th>
            {/* Add more headers if needed */}
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index}>
              <td>{payment.date}</td>
              <td>{payment.type}</td>
              <td>{payment.amount}</td>
              <td>{payment.paymentId}</td>
              <td>{payment.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BookingPayments;
