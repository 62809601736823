/** @format */

import React from "react";

const CopyText = ({ children }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(children)
      .then(() => {
        alert("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <span onClick={handleCopy} style={{ cursor: "context-menu" }}>
      {children}
    </span>
  );
};

export default CopyText;
