/** @format */

import { getAuth, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { database } from "../../firebase";

export const resetPassword = async (userEmail) => {
  const auth = getAuth();
  const email = userEmail;
  const actionCodeSettings = {
    url: "https://venuehub.live",
  };

  if (typeof email === "string") {
    // The email variable is a string
    console.log("Email is correctly a string:", email);
  } else {
    // The email variable is not a string
    console.log("Email is not a string:", email);
  }
  //   sendPasswordResetEmail();
  try {
    const link = await sendPasswordResetEmail(auth, email, undefined);
    return link;
  } catch (error) {
    console.log("error:", error);
  }
};
