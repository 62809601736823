import * as React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import { updateDocument } from '../../firebase';

const style = {
	wrapper: {
		position: 'absolute',
		width: '300px',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		// bgcolor: 'rgba(88, 84, 84, 1)',
		// border: '2px solid #FFF',
		boxShadow: 24,
		p: 4,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		maxHeight: '80vh',
		overflow: 'auto',
		borderRadius: '5px',
		backdropFilter: 'blur(60px)',
	},
	title: {
		display: 'flex',
		flex: 12,
		fontSize: '20px',
	},
	buttonStyle: {
		color: '#FFF',
	},
};

export const ConfirmationModal = (props) => {
	const handleClose = () => {
		props.setShowConfirmationModal(false);
	};

	return (
		<div>
			<Modal
				open={props.open}
				// open={true}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style.wrapper}>
					<h1 style={style.title}>Decline booking?</h1>
					<p style={style.text}>This will remove the booking.</p>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Button
							style={style.buttonStyle}
							onClick={() => {
								updateDocument(
									'Bookings',
									{ status: 'declined' },
									props.selectedEvent.bookingid,
								);
								handleClose();
								props.closeArtistInfo();
							}}
						>
							Ok
						</Button>
						<Button style={style.buttonStyle} onClick={handleClose}>
							Go back
						</Button>
					</div>
				</Box>
			</Modal>
		</div>
	);
};
