/** @format */

export const getDistanceTo = (latA, lngA, latB, lngB, index) => {
  // Calculate distance between A and B
  var lat1 = Number(latA);
  var lng1 = Number(lngA);
  var lat2 = Number(latB);
  var lng2 = Number(lngB);
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lng2 - lng1) * Math.PI) / 180;
  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = (Math.round(R * c) / 1).toFixed(0); // in 1 m (to get in km divide by 1000 instead)
  return distance;
};
