/** @format */

import React, { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  storeCurrentAgreements,
  storeCurrentUser,
  storeCurrentUserId,
  storeUserRole,
  storeVenueAccess,
} from "../../features/venueFilter/venueFilterSlice";
import GetBookings from "../../helpers/GetBookings";

const CheckRole = () => {
  console.time("CheckRoleTime");
  const auth = getAuth();
  const userRole = useSelector((state) => state.counter.userRole);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);

  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // console.group("THIS USER ROLE", userRole);
    currentUser.userRole === "Sustainability" && navigate("/SustainabilityForm");
    // THIS!
    // if (userRole === "owner" && userRole !== "admin") navigate("/Owner/Home");
  }, [userRole]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch(storeCurrentUserId(user.uid));
        const docRef = doc(db, "Users", user.uid);

        // Get the user document data
        getDoc(docRef)
          .then((doc) => {
            if (doc.exists()) {
              var userRoleToGo = doc.data().role;
              dispatch(storeUserRole(userRoleToGo));
              setUserData(doc.data());
              dispatch(storeCurrentUser(doc.data()));
              var tmpVenueAccess = doc.data().venueAccess;
              dispatch(storeVenueAccess(tmpVenueAccess));
              console.timeEnd("CheckRoleTime");
              // console.log("Document finally arrived");
            } else {
              // console.log("Document does not exist");
            }
          })
          .catch((error) => {
            // console.log("Error getting document:", error);
          });
      } else {
        // User is signed out
        // console.log("User not logged in");
        return;
      }
    });
  }, [onAuthStateChanged]);

  useEffect(() => {
    if (venueAccess && venueAccess[0] === "none") return;
    getAllAgreements();
  }, [venueAccess]);

  async function getAllAgreements() {
    try {
      const agreements = [];
      const q = query(collection(db, "Agreements"), where("venueid", "in", venueAccess));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        agreements.push(doc.data());
      });

      console.log("venueAccess", venueAccess);
      dispatch(storeCurrentAgreements(agreements));
    } catch (error) {
      console.error("Failed to fetch agreements:", error);
    }
  }

  useEffect(() => {
    if (currentAgreements.length === 0) return;
    if (currentVenueBookings.length === 0) return;
    console.log("currentVenueBookings:", currentVenueBookings);
    const filteredAgreements = currentAgreements.filter((agreement) =>
      currentVenueBookings.some((booking) => booking.agreementId === agreement.agreementId)
    );
    dispatch(storeCurrentAgreements(filteredAgreements));
  }, [currentVenueBookings]);

  return <div>{/* <GetBookings /> */}</div>;
};

export default CheckRole;
