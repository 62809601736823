/** @format */

import { Button, FormControlLabel, Grid, MenuItem, Select, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

const BatchChangeBookingParameterName = ({ bookings }) => {
  if (!bookings) return;
  const [oldParameterName, setOldParameterName] = useState("");
  const [newParameterName, setNewParameterName] = useState("");
  const [numberOfBookings, setNumberOfBookings] = useState(bookings.length);
  const [bookingKeys, setBookingKeys] = useState([]);
  const [deleteOldParameter, setDeleteOldParameter] = useState(false);

  useEffect(() => {
    setNumberOfBookings(bookings.length);
    const bookingKeys = Object.keys(bookings[bookings.length - 1]);
    const bookingKeysSorted = bookingKeys.sort();
    setBookingKeys(bookingKeysSorted);
  }, [bookings]);

  useEffect(() => {
    console.log("bookingKeys", bookingKeys);
  }, [bookingKeys]);

  async function changeAllBookingsParameterName() {
    for (const booking of bookings) {
      await setDoc(doc(db, "Bookings", booking.bookingid), { [newParameterName]: booking[oldParameterName] }, { merge: true });
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h7>Change Booking Parameter Name ({numberOfBookings} bookings)</h7>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select fullWidth variant="outlined" size="small" value={oldParameterName} onChange={(e) => setOldParameterName(e.target.value)}>
          {bookingKeys.map((key) => (
            <MenuItem value={key}>{key}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          onChange={(e) => {
            setNewParameterName(e.target.value);
          }}
          label="NEW Booking Parameter Name"
          variant="outlined"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControlLabel
          control={
            <Switch
              value={deleteOldParameter}
              onChange={() => {
                setDeleteOldParameter(!deleteOldParameter);
              }}
            />
          }
          label=" Delete old parameter?"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <ConfirmDialog
          header="Careful now!"
          message={`Are you sure you want to change the booking parameter name of all bookings?`}
          noButton
          onConfirm={() => changeAllBookingsParameterName()}
        >
          <Button fullWidth variant="outlined">
            Change
          </Button>
        </ConfirmDialog>
      </Grid>
    </Grid>
  );
};

export default BatchChangeBookingParameterName;
