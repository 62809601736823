/** @format */

import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customerAccountObj } from "../dbObjects.js/dbCustomerAccount";
// import { ownerAccountObj } from "../dbObjects.js/dbOwnerAccount";
// import { userObj } from "../dbObjects.js/dbUser";
import DisplayObject from "../helpers/displayObject";
import GeneralForm from "../helpers/GeneralForm";
import SelectVenue from "../helpers/SelectVenue";

const CustomerAccount = () => {
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  // const currentUser = useSelector((state) => state.counter.currentUser);
  //   const handleClose = () => {
  //     navigate(-1);
  //   };
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid mt={10} p={4} sm={11} md={8}>
      <h2>Account</h2>
      <GeneralForm
        form={customerAccountObj}
        formName="userForm"
        priority={1}
        setUserData={setUserData}
        navigateAfter={-1}
        // fields={["firstName", "lastName", "phone", "streetAddress", "zipCode", "city", "country", "socialId"]}
      />
    </Grid>
  );
};

export default CustomerAccount;
