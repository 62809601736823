/** @format */

import { number } from "prop-types";

// export function numberFormatted(numberToFormat) {
//   if (numberToFormat) {
//     // Med parsefloat tillåt ej decimaler. Liveläge(?)
//     // var y = parseFloat(numberToFormat.toString().replace(/[^\d.]/g, ""));
//     // const result = y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

//     // Utan parsefloat tillåts decimaler. För testning enbart.
//     var y = numberToFormat.toString().replace(/[^\d.]/g, "");
//     const result = y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
//     return result;
//   } else return;
// }

// export function numberFormatted(numberToFormat) {
//   if (numberToFormat) {
//     const y = parseFloat(numberToFormat.toString().replace(/[^\d.]/g, ""));
//     const result = y.toLocaleString(undefined, {
//       maximumFractionDigits: 2,
//       useGrouping: true,
//       minimumFractionDigits: 2,
//       minimumIntegerDigits: 1,
//       style: "decimal",
//     });

//     // Replace the default decimal separator (usually a period ".") with a comma ","
//     return result.replace(/\./, ",");
//   } else {
//     return "";
//   }
// }

export function numberFormatted(numberToFormat) {
  if (numberToFormat) {
    // console.log(numberToFormat);
    // Med parsefloat tillåt ej decimaler. Liveläge(?)
    // var y = parseFloat(numberToFormat.toString().replace(/[^\d.]/g, ""));
    // const result = y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const number = parseFloat(numberToFormat);

    // console.log(number);
    const resultWithTwoDecimals = (number) => {
      if (!isNaN(number)) {
        if (Number.isInteger(number)) {
          return number.toString();
        } else {
          // Fix the number to two decimal places if it's not an integer
          return number.toFixed(2);
        }
      } else {
        return "Invalid number";
      }
    };

    // var y = resultWithTwoDecimals.toString().replace(/[^\d.]/g, "");
    const result = resultWithTwoDecimals(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // const result = resultWithTwoDecimals(number);
    // console.log(result);
    // THIS
    // Utan parsefloat tillåts decimaler. För testning enbart.
    // var y = resultWithTwoDecimals.toString().replace(/[^\d.]/g, "");
    // const result = y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // return result;
    return result;
  } else return;
}

export const makeNumber = (formattedNumberString) => {
  if (formattedNumberString) {
    const result = parseFloat(formattedNumberString.toString().replace(/[^\d.]/g, ""));
    return result;
  } else return;
};

//  a function that removes % from a string (like "12%") and returns a number
export const removePercentage = (percentageString) => {
  if (percentageString) {
    const result = parseFloat(percentageString.toString().replace("%", ""));
    return result;
  } else return;
};
