/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import GetArtists from "../../helpers/GetArtists";
import { useDispatch, useSelector } from "react-redux";
import { storeCurrentBookingId, storeIsNewBooking, storeSelectedDate } from "../../features/venueFilter/venueFilterSlice";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import { bookingsObj } from "../../dbObjects.js/dbBookings";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

let tempBooking = [];
var newID = uuid();

export default function NewBookingStepper(props) {
  // const [bookingData, setBookingData] = useState({ artist: "unset" });
  const [inputData, setInputData] = useState();
  const artists = useSelector((state) => state.counter.artists);
  const selectedDate = useSelector((state) => state.counter.selectedDate);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentBookingId = useSelector((state) => state.counter.currentBookingId);
  const searchableArtists = artists.map(({ artist: label, artistid: id, image: image }) => ({ id, label, image }));
  const [who, setWho] = useState("Set Artist...");
  const [done, setDone] = useState(false);
  const dispatch = useDispatch();

  let bookingFormArray = bookingsObj;
  // tempBooking.bookingid = newID;
  // tempBooking.status = "Pending Request";

  const handleChange = (id, value) => {
    if (value === undefined) value = "none";
    if (id === "image") {
      tempBooking["artistImage"] = value;
      console.log(tempBooking);
    }
    if (id === "artist") {
      tempBooking["artist"] = value;
      console.log(tempBooking);
    }
    if (id === "artistid") {
      tempBooking["artistid"] = value;
      console.log(tempBooking);
    } else {
      tempBooking[id] = value;
      console.log(tempBooking);
    }
  };

  function isWeekend(d) {
    var date = new Date(d);
    return date.getDay() === 5 || date.getDay() === 6;
  }

  function createNewBooking() {
    // dispatch(storeCurrentBookingId(newID));
    tempBooking.bookingid = newID;
    tempBooking.status = "Pending Request";
    dispatch(storeCurrentBookingId(tempBooking["bookingid"]));

    if (currentVenue) {
      bookingFormArray.map((formfield) => {
        if (formfield.source === "Venues") {
          console.log(currentVenue[formfield.id]);
          tempBooking[formfield.id] = currentVenue[formfield.id];
        }
        if (formfield.id === "price" && isWeekend(selectedDate)) tempBooking["price"] = currentVenue.priceWknd;
        if (formfield.id === "price" && !isWeekend(selectedDate)) tempBooking["price"] = currentVenue.priceWkday;
      });
      console.log(tempBooking);
    }
    tempBooking["date"] = selectedDate;
    tempBooking["product"] = "Concert";

    // setDoc(doc(db, "Bookings", tempBooking["bookingid"]), {
    //   ...tempBooking,
    // });
    storeBook();
  }

  function storeBook() {
    setDoc(doc(db, "Bookings", tempBooking["bookingid"]), {
      ...tempBooking,
    });

    setTimeout(() => {
      setDone(true);
    }, 3000);
  }

  // useEffect(() => {
  //   setInputData((prev) => {
  //     return { date: selectedDate };
  //   });
  // }, []);

  const steps = [
    {
      label: <h4>{selectedDate}</h4>,
      formField: (
        <>
          <Autocomplete
            freeSolo
            defaultValue={who}
            onChange={(event, newValue) => {
              handleChange("artistid", newValue.id);
              handleChange("artist", newValue.label);
              handleChange("image", newValue.image);
            }}
            id="controllable-states-demo"
            getOptionId={(option) => option.id}
            getOptionLabel={(option) => option.label}
            options={searchableArtists}
            placeholder="he hej"
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} size={"small"} value={who} />}
          />
        </>
      ),
      description: `Add name of artist or name of the event.`,
    },
    {
      label: "Contact information",
      formField: (
        <div>
          <TextField
            label="Contact Name"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              console.log(newValue);
              handleChange("agent", event.target.value);
            }}
          />
          <TextField
            label="Phone"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              handleChange("agentPhone", event.target.value);
            }}
          />
          <TextField
            label="Email"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              handleChange("agentEmail", event.target.value);
            }}
          />
          <TextField
            label="Corporate ID"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              handleChange("corporateId", event.target.value);
            }}
          />
          <TextField
            label="Street Address"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              handleChange("agentStreetAddress", event.target.value);
            }}
          />
          <TextField
            label="Zip Code"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              handleChange("agentZipCode", event.target.value);
            }}
          />
          <TextField
            label="City"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              handleChange("agentCity", event.target.value);
            }}
          />
          <TextField
            label="Country"
            sx={{ margin: "5px", width: 300 }}
            onChange={(event, newValue) => {
              handleChange("agentCountry", event.target.value);
            }}
          />
        </div>
      ),
      description: "Add add name, phone, email and invoice information.",
    },
    {
      label: "Notes",
      formField: (
        <>
          {/* {console.log(inputData)} */}
          <TextField
            sx={{ width: "300px", height: "300px" }}
            id="standard-multiline-flexible"
            label="Notes"
            multiline
            maxRows={10}
            // value={value}
            // onChange={handleChange}
            variant="outlined"
            onChange={(event, newValue) => {
              handleChange("bookingNotes", event.target.value);
            }}
          />
        </>
      ),
      description: `Add notes.`,
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  // const isNewBooking = useSelector((state) => state.counter.isNewBooking);

  const handleSubmit = async () => {
    await createNewBooking();
    // await storeBooking();
    // await alert("i steppern", currentBookingId);
    // console.log(inputData);
    // await dispatch(storeCurrentBookingId(newID));
    // console.log(currentBookingId);
    // dispatch(storeCurrentBookingId(tempBooking.bookingid));
    // dispatch(storeIsNewBooking(false));
    // console.log(currentBookingId);
    // await setDoc(doc(db, "Testing", tempBooking["bookingid"]), {
    //   ...tempBooking,
    // });

    // alert("stored");
    // setTimeout(() => {
    //   dispatch(storeIsNewBooking(false));
    // }, 4000);
    // props.modal();
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <GetArtists />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}>{step.label}</StepLabel>
            <StepContent>
              {step.formField}
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {index === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                  {/* <Button
                    disabled={index === 0}
                    onClick={() => {
                      createNewBooking();
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Set VenueData
                  </Button> */}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === 3 && !done && (
        <div>
          <CircularProgress />
        </div>
      )}
      {
        // activeStep === steps.length && handleSubmit()

        (activeStep === 3 && !done && handleSubmit(), activeStep === 3 && done && dispatch(storeIsNewBooking(false)))

        // activeStep === 3 && !done && console.log("DONE")

        // <Paper square elevation={0} sx={{ p: 3 }}>
        //   <Typography>All steps completed - you&apos;re finished</Typography>
        //   <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
        //     Reset
        //   </Button>
        // </Paper>
      }
    </Box>
  );
}
