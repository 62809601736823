/** @format */

// KNOWN ERRORS
// Needs som cleaningn up
// To open previously opened product in dropdown, you have to select another option end then choose the on you intended. Kinda bad. Not urgent, but bad.
// Possible memory leekage with bad loop "Still waiting..." in deleteProduct, but could also be fine.

/** @format */

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  Popover,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productObj } from "../../dbObjects.js/dbProducts";
import { storeCurrentProduct, storeCurrentVenue } from "../../features/venueFilter/venueFilterSlice";
import { createId, db } from "../../firebase";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { doc, updateDoc } from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import AreYouSureModal from "../UI helpers/AreYouSureModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SettingsAccessibilitySharp } from "@mui/icons-material";
import { set } from "date-fns";
import { numberFormatted } from "../../helpers/Numbers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const Products = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const [productData, setProductData] = useState(["product1", "productid1"]);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const dispatch = useDispatch();
  const [typeOfEvent, setTypeOfEvent] = React.useState(() => ["Concert"]);
  const [preferredDays, setPreferredDays] = React.useState(() => ["Friday, Saturday"]);
  const [showSection, setShowSection] = useState({
    Product: true,
    "Type of Event": true,
    "Standard Running Times": true,
    Pricing: true,
    "Preferred Days": true,
  });
  const [AYS, setAYS] = useState(null);
  const [showAYS, setShowAYS] = useState(false);

  function changeProduct(e) {
    const productid = e.target.value;
    if (productid === "newProduct") return createProduct();
    let tmpData = [];
    tmpData = currentVenue.products.find((product) => product.productid === productid);

    setProductData({ ...tmpData });
    setShowSection(tmpData.sectionsToShow);
    dispatch(storeCurrentProduct(tmpData));
    setOpen(true);
  }

  async function createProduct() {
    setProductData([]);
    const newID = await createId();
    setProductData({
      product: "",
      productid: newID,
      sectionsToShow: {
        Product: true,
        "Standard Running Times": true,
        "Type of Event": true,
        Pricing: true,
        "Preferred Days": true,
        Audience: true,
      },
      privatePublic: "Private Event",
    });
    setOpen(true);
  }

  function handleChange(key, value, type) {
    if (type === "number") {
      setProductData((prev) => ({ ...prev, [key]: numberFormatted(value) }));
    } else {
      setProductData((prev) => ({ ...prev, [key]: value }));
    }
  }

  const handleTypeOfEvent = (event, newTypes) => {
    setTypeOfEvent(newTypes);
    setProductData((prev) => ({ ...prev, typeOfEvent: newTypes }));
  };

  const handlePreferredDays = (event, newDays) => {
    setPreferredDays(newDays);
    setProductData((prev) => ({ ...prev, preferredDays: newDays }));
  };

  const handleSection = (section, show) => {
    let tmpShowSection = { ...productData.sectionsToShow, [section]: show };
    setShowSection((prev) => ({ ...prev, [section]: show }));
    setProductData((prev) => ({ ...prev, sectionsToShow: tmpShowSection }));
  };

  async function submitProduct() {
    alert("Product submitted");
    console.log(currentVenue.products);
    let tmpProducts = [];
    {
      currentVenue.products ? (tmpProducts = [...currentVenue.products]) : (tmpProducts = []);
    }
    console.log("💚", tmpProducts);
    console.log("💚", productData);

    // make sure that the product contains preferredDays() and typeOfEvent() as arrays.
    productData.preferredDays = preferredDays;
    productData.typeOfEvent = typeOfEvent;

    //Check if productid already exists, in that case first remove that post and then store the current.
    await tmpProducts.map((prod) => {
      if (prod.productid === productData.productid) {
        const index = tmpProducts.findIndex((product) => product.productid === prod.productid);
        tmpProducts.splice(index, 1);
      }
    });
    //If currentVenue doesn't hold any products yet...
    if (!currentVenue.products) {
      tmpProducts = [productData];
    }
    // ...and if currentVenue already has some products, then bring them along
    else {
      tmpProducts = [...tmpProducts, productData];
    }
    // update venue with new product in db
    console.log(tmpProducts);

    await updateDoc(doc(db, "Venues", currentVenue.venueid), {
      products: tmpProducts,
    });

    // and in order to show up in products menu we store it to redux instead of reloading the venue.
    let tmpVenue = { ...currentVenue };
    tmpVenue["products"] = tmpProducts;
    dispatch(storeCurrentVenue(tmpVenue));
    handleClose();
  }

  async function deleteProduct(id) {
    // setShowAYS(true);

    function wait() {
      setTimeout(() => {
        // console.log("still waiting...");
      }, 500);
    }

    if (AYS === false) {
      // console.log(AYS);
      setShowAYS(false);
      setAYS(null);
    }

    if (AYS === true) {
      // setAYS(null);

      let tmpProducts = [...currentVenue.products];
      const index = tmpProducts.findIndex((product) => product.productid === id);
      // console.log(productData.productid, id);
      tmpProducts.map((tmp) => {
        // console.log(tmp.productid);
      });

      // Check that the productid exists to avoid otherwise the last in the list is removed.
      let result = tmpProducts.find((object) => object.productid === id);
      result !== undefined && tmpProducts.splice(index, 1);

      // update venue in db Venues without the removed product
      // console.log(tmpProducts);
      await updateDoc(doc(db, "Venues", currentVenue.venueid), {
        products: tmpProducts,
      });

      // and in order to show up in products menu we store it to redux instead of reloading the venue.
      let tmpVenue = { ...currentVenue };
      tmpVenue["products"] = tmpProducts;
      dispatch(storeCurrentVenue(tmpVenue));
      setShowAYS(false);
      setAYS(null);
    }
    if (AYS === null) {
      wait();
    }
  }
  const nom = 2000;
  const optionalSection = (section) => {
    const optionals = ["Stage Hands", "Security", "Sound Engineer", "Pricing"];
    if (optionals.includes(section)) {
      return true;
    } else {
      return false;
    }
  };
  const productOrProducts = currentVenue.products && currentVenue.products.length > 1 ? " products" : " product";

  useEffect(() => {
    // setTimeout(() => {
    setProductsLoaded(true);
    // }, 2000);
  }, [currentVenue]);

  useEffect(() => {}, [productData, showSection, typeOfEvent]);

  useEffect(
    () => {
      deleteProduct(productData.productid);
    },
    AYS,
    [showAYS === true]
  );

  return (
    <Grid container sx={12} spacing={4}>
      {/* {console.log(AYS)} */}
      <Grid item sx={6}>
        {/* {console.log(currentVenue.products)} */}
        <table>
          <Select
            size="small"
            xs={12}
            sm={4}
            onChange={(e) => changeProduct(e)}
            style={{ width: "500px" }}
            defaultValue=""
            displayEmpty
            inputProps={{ "aria-label": currentVenue.products && currentVenue.products.length }}
          >
            {/* <thead>
              <th>Product</th>
              <th>Price</th>
              <th>Cap</th>
            </thead> */}
            {currentVenue.products &&
              currentVenue.products.map((product) => (
                <MenuItem key={product.productid} value={product.productid} style={{ width: "auto", display: "flex", gap: "10px" }}>
                  <tr>
                    <td>
                      <EditIcon fontSize="inherit" className="editProduct" onClick={() => setOpen(true)} />
                    </td>
                    <td>
                      {product.privatePublic === "Private Event" ? "Private" : "Public"}
                      {product.product}
                    </td>
                    <td style={{ opacity: "0.3" }}>Price: {product.price}</td>
                    <td style={{ opacity: "0.3" }}>Cap: {product.capacity}</td>
                  </tr>
                </MenuItem>
              ))}
            <MenuItem value="" disabled style={{ textTransform: "lowercase" }}>
              {currentVenue.products ? currentVenue.products.length + productOrProducts : "No products yet..."}
            </MenuItem>
            <MenuItem key={"newProduct"} value={"newProduct"}>
              <b>+ Create new Product</b>
            </MenuItem>
          </Select>
        </table>
      </Grid>
      {/* {!currentVenue.products && <div>No products yet...</div>}
      {currentVenue.products && <div>{currentVenue.products.length} products availbale</div>} */}
      {/* <Grid item sx={12}>
        {
          <div>
            <div>Product: {productData.product}</div>
            <div>Type of Event: {productData.typeOfEvent}</div>
            <div>Preferred Days: {productData.prederredDays}</div>
            <div>Includes: {productData.showSection && productData.showSection.map((section) => <div>{section}</div>)}</div>
            <div>AYS: {AYS}</div>
            <div>ShowAYS: {showAYS}</div>
          </div>
        }
      </Grid> */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h2>{productData.product}</h2>
          </Typography>

          <Grid container xs={12} style={{ display: "flex" }} spacing={4}>
            {productObj.map((formfield) => (
              <>
                {/* {console.log("id", formfield.id)} */}
                {formfield.type === "Section" && (
                  <Grid item style={{ width: "100%" }} className="formSectionLabel">
                    {/* {!formfield.switch ? (
                      <>{formfield.section}</>
                    ) : (
                      <>
                        <FormControlLabel
                          style={{ marginRight: "10px" }}
                          control={
                            <Switch
                              size="small"
                              value={formfield.section}
                              onChange={(e) => handleSection(e.target.value, e.target.checked)}
                              checked={showSection[formfield.section]}
                              className="appear"
                              style={{ display: "none" }}
                            />
                          }
                          label={formfield.section}
                        />
                      </>
                    )} */}
                    <description>{formfield.description}</description>
                  </Grid>
                )}

                {formfield.type !== "Section" &&
                  formfield.type === "number" &&
                  // showSection[formfield.section] &&
                  formfield.priority < 10 && (
                    <Grid item style={{ flexWrap: "wrap" }}>
                      <TextField
                        {...formfield}
                        type="text"
                        // defaultValue={numberFormatted(productData[formfield.id])}
                        // value={productData[formfield.id] ? productData[formfield.id] : formfield.defaultValue}
                        value={productData[formfield.id] ? productData[formfield.id] : formfield.defaultValue}
                        onChange={(e) => handleChange(formfield.id, e.target.value, "number")}
                        // onBlur={(e) => StoreAsFormattedNumber(formfield.id, e.target.value)}
                        size="small"
                        style={{ width: "150px" }}
                      />
                      {/* {!productData[formfield.id] && handleChange(formfield.id, formfield.defaultValue)} */}
                    </Grid>
                  )}
                {formfield.type !== "Section" &&
                  (formfield.type === "text" || formfield.type === "time") &&
                  showSection[formfield.section] &&
                  formfield.priority < 10 && (
                    <Grid item style={{ flexWrap: "wrap" }}>
                      <TextField
                        {...formfield}
                        value={productData[formfield.id] ? productData[formfield.id] : formfield.defaultValue}
                        onChange={(e) => handleChange(formfield.id, e.target.value)}
                        size="small"
                        style={{
                          width: "150px",
                          background: (formfield.id === "loadIn" || formfield.id === "loadOut") && "#00ffd422",
                          borderRadius: "10px",
                        }}
                      />
                      {!productData[formfield.id] && handleChange(formfield.id, formfield.defaultValue)}
                    </Grid>
                  )}
                {formfield.type !== "Section" &&
                  formfield.type === "textarea" &&
                  showSection[formfield.section] &&
                  formfield.priority < 10 && (
                    <Grid item style={{ flexWrap: "wrap" }}>
                      <TextField
                        {...formfield}
                        // value={productData[formfield.id] ? productData[formfield.id] : formfield.defaultValue}
                        value={productData[formfield.id]}
                        onChange={(e) => handleChange(formfield.id, e.target.value)}
                        size="small"
                        style={{ width: "300px" }}
                      />
                      {/* {!productData[formfield.id] && handleChange(formfield.id, formfield.defaultValue)} */}
                    </Grid>
                  )}
                {formfield.type !== "Section" &&
                  formfield.id === "typeOfEvent" &&
                  showSection[formfield.section] &&
                  formfield.priority < 10 && (
                    <Grid px={2} style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                      <ToggleButtonGroup
                        // value={productData.typeOfEvent}
                        value={productData.typeOfEvent ? productData.typeOfEvent : formfield.defaultValue}
                        defaultValue={formfield.defaultValue}
                        onChange={handleTypeOfEvent}
                        aria-label="type"
                      >
                        {formfield["options"].map((option) => (
                          <ToggleButton value={option} aria-label="option" size="small" className="eventType">
                            {option}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                  )}
                {formfield.type !== "Section" &&
                  // formfield.type === "checkbox" &&
                  formfield.id === "preferredDays" &&
                  formfield.priority < 10 && (
                    <Grid px={2} style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                      <ToggleButtonGroup
                        value={productData.preferredDays ? productData.preferredDays : formfield.defaultValue}
                        onChange={handlePreferredDays}
                        aria-label="days"
                      >
                        {formfield["options"].map((option) => (
                          <ToggleButton
                            value={option}
                            defaultValue={formfield.defaultValue}
                            aria-label="option"
                            size="small"
                            className="eventType"
                          >
                            {option}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                  )}
                {/* </Grid> */}
              </>
            ))}
          </Grid>
          <Grid item my={4} style={{ display: "flex", gap: "10px" }}>
            <Button variant="contained" onClick={() => submitProduct()}>
              <SaveIcon style={{ fontSize: "14px", marginRight: "10px" }} /> Save product
            </Button>
            <Button variant="outlined" onClick={() => handleClose()}>
              <ArrowBackIosIcon style={{ fontSize: "14px", marginRight: "10px" }} /> Close
            </Button>
            <Button
              // color="warning"
              onClick={() => {
                // deleteProduct(productData.productid);
                setShowAYS(true);
              }}
            >
              <DeleteIcon fontSize="inherit" className="deleteProduct" /> Remove
            </Button>
            {/* {console.log("showAYS", showAYS)} */}
            {showAYS && (
              <AreYouSureModal setAYS={setAYS} showAYS={true} msg={`You are about to delete the product ${productData.product}.`} />
            )}
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};
export default Products;
