/** @format */

import React, { useState } from "react";

function DevTodoList() {
  const [todos, setTodos] = useState([]);
  const [isEditing, setIsEditing] = useState(null);

  const handleAddTodo = (event) => {
    event.preventDefault();
    const newTodo = {
      text: event.target.todo.value,
      createdAt: new Date().toISOString(),
      completedAt: null,
    };
    setTodos([...todos, newTodo]);
    event.target.reset();
  };

  const handleCompleteTodo = (index) => {
    const newTodos = [...todos];
    newTodos[index].completedAt = new Date().toISOString();
    setTodos(newTodos);
  };

  const handleEditTodo = (index) => {
    const newTodos = [...todos];
    newTodos[index].completedAt = null; // Återställ till ej färdigställd
    setIsEditing(index);
  };

  const handleSaveEdit = (index, newText) => {
    const newTodos = [...todos];
    newTodos[index].text = newText;
    setTodos(newTodos);
    setIsEditing(null);
  };

  return (
    <div>
      <h1>Todo-lista</h1>
      <form onSubmit={handleAddTodo}>
        <input type="text" name="todo" placeholder="Lägg till en uppgift" />
        <button type="submit">Lägg till</button>
      </form>
      <table className="devTodoList">
        <thead>
          <tr>
            <th>Uppgift</th>
            <th>Skapad</th>
            <th>Färdigställd</th>
          </tr>
        </thead>
        <tbody>
          {todos
            .sort((a, b) => (a.completedAt || new Date()) - (b.completedAt || new Date()))
            .map((todo, index) => (
              <tr key={index}>
                <td>
                  {isEditing === index ? (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSaveEdit(index, e.target.editTodo.value);
                      }}
                    >
                      <input type="text" name="editTodo" defaultValue={todo.text} />
                      <button type="submit">Spara</button>
                    </form>
                  ) : (
                    <>
                      {todo.datum}
                      <button onClick={() => handleEditTodo(index)}>Redigera</button>
                      {todo.completedAt === null && <button onClick={() => handleCompleteTodo(index)}>Klar</button>}
                    </>
                  )}
                </td>
                <td>{todo.createdAt}</td>
                <td>{todo.completedAt || "-"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default DevTodoList;
