/** @format */

export function updateProductsWithNewModuleData(formData, additionals) {
  console.log("nowFormData", formData);
  console.log("nowAdditionals", additionals);

  // turn formdata.products into a nested object with productid as key
  const productsObject = formData.products.reduce((acc, obj) => {
    acc[obj.productid] = obj;
    return acc;
  }, {});

  // deep clone the productsObject in order to be able to change the values on the fly
  let tmpProductsObject = JSON.parse(JSON.stringify(productsObject));

  console.log("productsObject", productsObject);

  Object.values(productsObject).map((product) => {
    if (product.features && Object.values(product.features).length) {
      Object.values(product.features).map((feature) => {
        if (
          tmpProductsObject[product.productid] &&
          tmpProductsObject[product.productid].features &&
          tmpProductsObject[product.productid].features[feature.additionalId]
        ) {
          // change the name of the module
          tmpProductsObject[product.productid].features[feature.additionalId].additional =
            (additionals[feature.additionalId] && additionals[feature.additionalId].additional) || "";

          if (feature.options && Object.values(feature.options).length) {
            Object.values(feature.options).map((option) => {
              console.log("option", option);

              if (
                tmpProductsObject[product.productid] &&
                tmpProductsObject[product.productid].features &&
                tmpProductsObject[product.productid].features[feature.additionalId] &&
                tmpProductsObject[product.productid].features[feature.additionalId].options &&
                tmpProductsObject[product.productid].features[feature.additionalId].options[option.optionId]
              ) {
                const targetOption = tmpProductsObject[product.productid].features[feature.additionalId].options[option.optionId];
                const additionalOption =
                  additionals[feature.additionalId] &&
                  additionals[feature.additionalId].options &&
                  additionals[feature.additionalId].options[option.optionId];

                if (targetOption) {
                  // change the name of the option
                  if (targetOption.option) {
                    targetOption.option = (additionalOption && additionalOption.option) || "";
                  }
                  // change the description of the option
                  if (targetOption.info) {
                    targetOption.info = (additionalOption && additionalOption.info) || "";
                  }
                  // change the price of the option
                  if (targetOption.price) {
                    targetOption.price = (additionalOption && additionalOption.price) || 0;
                  }
                  // change the vat of the option
                  if (targetOption.vat) {
                    targetOption.vat = (additionalOption && additionalOption.vat) || "0%";
                  }
                  // change the each status of the option
                  if (targetOption.each) {
                    targetOption.each = (additionalOption && additionalOption.each) || 0;
                  }
                  // change the internalCost of the option
                  if (targetOption.internalCost) {
                    targetOption.internalCost = (additionalOption && additionalOption.internalCost) || 0;
                  }
                }
              }
            });
          }
        }
      });
    }
  });

  // turn the nested and updated object back into an array
  const arrayAgain = Object.values(tmpProductsObject);

  // return the updated array to be saved in the database
  console.log("arrayAgain", arrayAgain);
  return arrayAgain;
}
