/** @format */

import React, { useEffect, useState } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { Circle, GoogleMap, LoadScript, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { Grid, Switch, Tooltip } from "@mui/material";
import { darkMapStyles, darkMapStylesOrange } from "../components/UI helpers/mapStyles";
import { set } from "date-fns";
import ObjectSize from "../helpers/ObjectSize";
import { getDistanceTo } from "../helpers/GetDistanceTo";
import { numberFormatted } from "../helpers/Numbers";
import { calculateEmissionsBasedOnDistance } from "../components/VisualData/TravelEmissionCalculator";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { wait } from "../components/MiscUIComponents/Wait";
import UpCount from "../components/UI helpers/UpCount";
import { MdOutlineSignalWifiStatusbarConnectedNoInternet4 } from "react-icons/md";

const BenchmarkTravelData = ({ booking }) => {
  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(4);
  const [coordinates, setCoordinates] = useState([]);
  const [locations, setLocations] = useState([]);
  const bookingid = booking.bookingid;
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  const [showTicketCount, setShowTicketCount] = useState(true);
  const ticketEventId = booking.ticketEventId;
  const ticketVenueId = booking.ticketVenueId;
  const [travelData, setTravelData] = useState([null]);
  const [visitors, setVisitors] = useState([null]);
  const [tooltip, setTooltip] = useState(null);
  const [travelImpact, setTravelImpact] = useState({
    visitors,
    distance: 240,
    climateImpact: 360,
    travel: 480,
    carDistance: 0,
    bicycleDistance: 0,
    busDistance: 0,
    trainDistance: 0,
    planeDistance: 0,
  });

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const googleCX = process.env.REACT_APP_GOOGLE_CX;

  // The center of the map (arbitrary, can be any coordinates)
  const CENTER = {
    lat: 59.3293, // latitude of Stockholm
    lng: 18.0686, // longitude of Stockholm
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleApiKey,
  });

  useEffect(() => {
    if (travelData.length > 1) setCoordsLoaded(true);
  }, [travelData]);

  useEffect(() => {
    if (travelData.length < 2) return;
    const visitors = travelData.length;
    // const totalDistance = travelData.reduce((acc, item) => {
    //   return acc + getDistanceTo(CENTER.lat, CENTER.lng, item.lat, item.lng);
    // });

    const totalDistance = travelData.reduce((acc, item) => {
      console.log("item", item);
      return acc + Number(getDistanceTo(CENTER.lat, CENTER.lng, item.lat, item.lng)) / 1000;
    }, 0); // Initialize acc to 0
    const averageDistance = totalDistance / visitors;

    let carDistance = 0;
    let bikeDistance = 0;
    let busDistance = 0;
    let trainDistance = 0;
    let planeDistance = 0;

    travelData.forEach((item) => {
      const distance = getDistanceTo(CENTER.lat, CENTER.lng, item.lat, item.lng);
      carDistance += (distance * (item.Car / 100)) / 1000;
      bikeDistance += (distance * (item.Bike / 100)) / 1000;
      busDistance += (distance * (item.Bus / 100)) / 1000;
      trainDistance += (distance * (item.Train / 100)) / 1000;
      planeDistance += (distance * (item.Plane / 100)) / 1000;
    });

    setTravelImpact((prev) => ({
      ...prev,
      visitors: visitors,
      averageDistance: averageDistance,
      carDistance: carDistance,
      bicycleDistance: bikeDistance,
      busDistance: busDistance,
      trainDistance: trainDistance,
      planeDistance: planeDistance,
    }));
  }, [travelData]);

  //   set listener to BenchmarkTravelData
  useEffect(() => {
    if (!bookingid) return;

    // Setting up the listener to get instantaneous updates
    const unsubscribe = onSnapshot(
      doc(db, "BenchmarkTravelData", bookingid),
      (doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            setTravelData(Object.values(data));
          } else {
            console.log("Document has no data!");
            setTravelData([]); // Or set some default value
          }
        } else {
          console.log("No such document!");
          setTravelData([]); // Or set some default value
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    );

    // const unsubscribe = onSnapshot(
    //   doc(db, "BenchmarkTravelData", bookingid),
    //   (doc) => {
    //     if (doc.exists) {
    //       setTravelData(Object.values(doc.data()));
    //     } else {
    //       console.log("No such document!");
    //     }
    //     setLoading(false);
    //   },
    //   (error) => {
    //     console.error("Error fetching document: ", error);
    //     setLoading(false);
    //   }
    // );

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [bookingid]);

  return (
    <Grid container p={0} xs={12} gap={4} className="eventTravelData">
      {/* {travelData.length > 1 &&
        travelData.map((item) => (
          <div>
            {item.lat && item.lat} {item.lng && item.lng}
          </div>
        ))} */}
      {/* {console.log(travelImpact, "travelImpact")} */}
      {console.log("travel data", travelData)}
      {coordsLoaded && console.log("travelData B", travelData)}
      {coordsLoaded && isLoaded && (
        <Grid xs={12}>
          <div style={{ position: "relative" }}>
            <div className="eventTravelDataMap">
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "45vh" }}
                center={CENTER}
                zoom={zoom}
                options={{ styles: darkMapStylesOrange, disableDefaultUI: true }}
                className="travelDataMap"
              >
                {travelData.map((coord, i) => (
                  <React.Fragment key={coord + 1}>
                    <div key={i}>
                      {console.log("coord", coord)}
                      <OverlayView
                        position={{
                          lat: coord.lat,
                          lng: coord.lng,
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      >
                        <div
                          className="travelDataPoint"
                          style={{
                            width: "20px",
                            height: "20px",
                            background: "#00ffd455",
                            borderRadius: "50%",
                            textAlign: "center",
                            padding: "3px",
                          }}
                        >
                          {showTicketCount && coord.count}
                        </div>
                      </OverlayView>
                    </div>
                  </React.Fragment>
                ))}
                <Circle
                  center={CENTER}
                  radius={10000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                  }}
                />
                <Circle
                  center={CENTER}
                  radius={20000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                  }}
                />
                <Circle
                  center={CENTER}
                  radius={80000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                  }}
                />
                <Circle
                  center={CENTER}
                  radius={500000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                    strokeDashArray: "5 2",
                  }}
                />
              </GoogleMap>
            </div>
            <div className="travelReport">
              <table className="sustainabilityReport">
                <tr>
                  <td>Travel data</td>
                  <td>
                    <thead>
                      <th>Visitors</th>
                      <th>Average Distance</th>
                      <th>CO2e</th>
                      <th>Average Impact</th>
                    </thead>
                    <tr>
                      <td>
                        {/* <span>{travelImpact.visitors}</span> */}
                        <UpCount target={travelImpact.visitors} type="" />
                      </td>
                      <td>
                        <span>{numberFormatted(travelImpact.averageDistance)} km</span>
                      </td>
                      <td>
                        <span>{travelImpact.climateImpact} kg</span>
                      </td>
                      <td>
                        <span>{travelImpact.averageImpact} kg</span>
                      </td>
                    </tr>
                    <tr className="headerIcons">
                      <th>
                        <DirectionsCarIcon />
                      </th>
                      <th>
                        <DirectionsBikeIcon />
                      </th>
                      <th>
                        <DirectionsBusIcon />
                      </th>
                      <th>
                        <DirectionsRailwayIcon />
                      </th>
                      <th>
                        <AirplanemodeActiveIcon />
                      </th>
                    </tr>
                    <tr className="travelImpact">
                      <td>
                        <UpCount target={travelImpact.carDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.bicycleDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.busDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.trainDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.planeDistance} type="km" />
                      </td>

                      {/* <td>{travelImpact.carDistance || 0} km</td>
                      <td>{travelImpact.bicycleDistance || 0} km</td>
                      <td>{travelImpact.busDistance || 0} km</td>
                      <td>{travelImpact.trainDistance || 0} km</td>
                      <td>{travelImpact.planeDistance || 0} km</td> */}
                    </tr>
                  </td>
                  <td>
                    <Switch checked={showTicketCount} onChange={() => setShowTicketCount(!showTicketCount)} />
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {/* <ObjectSize dataObject={travelData} name="Coords size" /> */}
        </Grid>
      )}
    </Grid>
  );
};

export default BenchmarkTravelData;
