/** @format */

import { fabClasses } from "@mui/material";
import React, { useEffect } from "react";
import { Months } from "./Months";
import { throttle } from "lodash";
import { wait } from "../MiscUIComponents/Wait";

const MonthRangePicker = ({ setCurrentMonth, setCurrentYear, setOpenMonthPicker, openMonthPicker }) => {
  const currentDate = new Date().toLocaleString().substring(0, 10);
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear + 1, currentYear + 2, currentYear + 3, currentYear + 4];

  const handleChange = throttle((year, month) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  }, 100);

  return (
    <>
      {openMonthPicker && (
        <div className="monthPicker">
          {/* <div onClick={() => setOpenMonthPicker(false)} style={{ color: "#00ffd4", padding: "10px" }}>
            ⓧ close
          </div> */}
          {years.map((year) => {
            return (
              <div className="year">
                <div className="yearsColumn">{year}</div>
                <div className="months">
                  {Months.map((month, index) => (
                    <div
                      className="monthsToPick"
                      onMouseOver={() => handleChange(year, index)}
                      onClick={() => setOpenMonthPicker(false)}
                      // style={{ animation: `spinIn ${index / 25}s` }}
                    >
                      {month.slice(0, 3)}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MonthRangePicker;
