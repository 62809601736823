/** @format */

import { Grid } from "@mui/material";
import React from "react";
import { bookingsObj } from "../../dbObjects.js/dbBookings";

const CreateBookings = () => {
  return <Grid>Create Bookings</Grid>;
};

export default CreateBookings;
