/** @format */

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Grid, TextField, Typography } from "@mui/material";
import { time } from "../../features/avails/time";
import { set } from "date-fns";

const StandardTimesSlider = ({ productData, setProductData, TextFields, disabledSlider, simple, formlock }) => {
  console.log("productData", productData);
  const eventOrder = ["getIn", "loadIn", "soundcheck", "doors", "show", "loadOut", "curfew"];
  const loadInIndex = 1; // Adjust based on your actual data structure
  const loadOutIndex = 5; // Adjust based on your actual data structure

  // Set default values for missing event times
  if (productData.getIn === undefined) setProductData({ ...productData, getIn: "15:00" });
  if (productData.loadIn === undefined) setProductData({ ...productData, loadIn: "16:00" });
  if (productData.soundcheck === undefined) setProductData({ ...productData, soundcheck: "17:00" });
  if (productData.doors === undefined) setProductData({ ...productData, doors: "18:30" });
  if (productData.show === undefined) setProductData({ ...productData, show: "19:00" });
  if (productData.loadOut === undefined) setProductData({ ...productData, loadOut: "23:00" });
  if (productData.curfew === undefined) setProductData({ ...productData, curfew: "00:00" });

  const timeToSliderValue = (time) => {
    if (time === undefined) return;
    let [hours, minutes] = time.split(":").map(Number);
    // Convert "HH:MM" format into a value from 0 to 47
    hours = hours === 4 && minutes === 30 ? 24 : hours; // Treat 04:30 as the end of the slider
    if (hours < 5) {
      hours += 24; // Times from 00:00 to 04:00 are considered late night/early morning next day
    }
    let value = (hours - 5) * 2 + (minutes === 30 ? 1 : 0); // Adjust starting point to 05:00
    return value >= 0 ? value : 47; // Ensure 04:30 maps to the last step
  };

  const sliderValueToTime = (value) => {
    let hours = Math.floor(value / 2) + 5; // Start from 05:00
    const minutes = value % 2 === 0 ? "00" : "30";
    if (hours >= 24) hours -= 24; // Adjust hours to wrap after midnight
    // if (value === 47) return "04:30"; // Special case for the end of the range
    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  };

  // Convert event times from productData to slider values
  // const sliderValues = eventOrder.map((eventName) => timeToSliderValue(productData[eventName]));
  const [sliderValues, setSliderValues] = useState(eventOrder.map((eventName) => timeToSliderValue(productData[eventName])));

  const handleSliderChange = (event, newValue) => {
    const newEventTimes = {};
    newValue.forEach((value, index) => {
      const eventName = eventOrder[index];
      newEventTimes[eventName] = sliderValueToTime(value);
    });
    setProductData({ ...productData, ...newEventTimes });
  };

  const totalSliderSteps = 48; // Total steps in the slider for your time range

  const calculatePositionPercentage = (sliderValue) => {
    return (sliderValue / totalSliderSteps) * 100;
  };

  const loadInSliderValue = timeToSliderValue(productData.loadIn);
  const loadOutSliderValue = timeToSliderValue(productData.loadOut);

  const loadInPosition = calculatePositionPercentage(loadInSliderValue);
  const loadOutPosition = calculatePositionPercentage(loadOutSliderValue);

  const calculateHighlightPosition = () => {
    const loadInSliderValue = timeToSliderValue(productData.loadIn);
    const loadOutSliderValue = timeToSliderValue(productData.loadOut);

    const loadInPosition = calculatePositionPercentage(loadInSliderValue);
    const loadOutPosition = calculatePositionPercentage(loadOutSliderValue);
    const widthOfHighlight = loadOutPosition - loadInPosition;

    // Adjust these positions based on your slider's CSS and layout
    return {
      left: `${loadInPosition}%`,
      right: `${100 - loadOutPosition}%`, // Use 100 - position for the right value
      width: `${widthOfHighlight}%`,
    };
  };

  const [timeSlotLeft, setTimeSlotLeft] = useState(calculatePositionPercentage(loadInSliderValue) + 1);
  const [timeSlotRight, setTimeSlotRight] = useState(100 - calculatePositionPercentage(loadOutSliderValue) - 1);

  useEffect(() => {
    setTimeSlotRight(100 - calculatePositionPercentage(loadOutSliderValue) - loadOutPosition / 40);
    setTimeSlotLeft(calculatePositionPercentage(loadInSliderValue) + loadInPosition / 40);
  }, [, loadOutSliderValue, loadInSliderValue]);

  useEffect(() => {
    console.log("productData THIS!", productData);
    setSliderValues(eventOrder.map((eventName) => timeToSliderValue(productData[eventName])));
  }, [productData]);

  return (
    <Grid container sx={{ margin: "0 auto", float: "left" }} xs={12} sm={11} className="standardTimeSlider">
      {console.log("❤️", calculateHighlightPosition())}

      <div style={{ position: "relative", width: "100%" }}>
        <Slider
          // disabled={disabledSlider ? true : false}
          // components={{ Thumb: ThumbComponent }}
          disabled={formlock}
          marks={[
            { value: 0, label: "05:00" },
            { value: 47, label: "04:30" },
          ]}
          getAriaLabel={() => "Event timeline"}
          value={sliderValues}
          onChange={TextFields ? handleSliderChange : null}
          valueLabelDisplay="auto"
          valueLabelFormat={(value, index) => `${eventOrder[index]}: ${sliderValueToTime(value)}`}
          step={1}
          min={0}
          max={47} // 27 hours * 2 steps per hour
          componentsProps={{
            thumb: {
              // You could try to apply styles here, but it's limited for targeting specific thumbs
            },
          }}
          sx={{
            "& .MuiSlider-thumb": {
              className: "eventThumb",
              // backgroundColor: "#184c5c", // Default thumb color
              // zIndex: "2",
              "&:nth-of-type(4)": {
                // Assuming loadIn is the second thumb
                backgroundColor: "#00ffd4", // Highlight loadIn
                className: "loadInThumb",
                // height: "20px",
                borderRadius: "10px 0px 0px 10px",
                // width: "20px",
                zIndex: "3",
              },
              "&:nth-of-type(8)": {
                // Assuming loadOut is the sixth thumb
                // backgroundColor: "#00ffd4", // Highlight loadOut
                backgroundColor: "linear-gradient(0, #111, #333)", // Highlight loadOut
                className: "loadOutThumb",
                // height: "20px",
                borderRadius: "0px 10px 10px 0px",
                // width: "20px",
                zIndex: "3",
              },
            },

            // Highlight the track dynamically based on loadIn and loadOut positions
            "& .MuiSlider-track": {
              // Custom track styles here
              // Note: Directly targeting only the segment between loadIn and loadOut is complex
              // and might require additional JavaScript logic or pseudo-elements
              // This example will style the entire track, adjust as needed
              //   backgroundColor: "rgba(0, 255, 212, 0.5)", // Example style, adjust as needed
              backgroundColor: "#000", // Example style, adjust as needed
            },
          }}
        />
        {/* TIME SLOT BAR */}
        <div
          className="timeSlotHighlight"
          style={{
            height: "8px",

            top: "11px",
            left: timeSlotLeft + "%",
            // width: {calculatePositionPercentage(loadOutSliderValue) - calculatePositionPercentage(loadInSliderValue)},
            right: timeSlotRight + "%",
            position: "absolute",
            zIndex: "1",
          }}
        ></div>
      </div>
      {TextFields && !simple && (
        <Grid item mt={2} style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <TextField
            disabled={formlock}
            size="small"
            label="Get In"
            type="time"
            value={productData.getIn}
            onChange={(e) => setProductData({ ...productData, getIn: e.target.value })}
          />
          <TextField
            disabled={formlock}
            size="small"
            label="Load In"
            type="time"
            value={productData.loadIn}
            style={{ backgroundColor: "#00ffd422", borderRadius: "10px" }}
            onChange={(e) => setProductData({ ...productData, loadIn: e.target.value })}
          />
          <TextField
            disabled={formlock}
            size="small"
            label="Soundcheck"
            type="time"
            value={productData.soundcheck}
            onChange={(e) => setProductData({ ...productData, soundcheck: e.target.value })}
          />
          <TextField
            disabled={formlock}
            size="small"
            label="Doors"
            type="time"
            value={productData.doors}
            onChange={(e) => setProductData({ ...productData, doors: e.target.value })}
          />
          <TextField
            disabled={formlock}
            size="small"
            label="Show"
            type="time"
            value={productData.show}
            onChange={(e) => setProductData({ ...productData, show: e.target.value })}
          />
          <TextField
            disabled={formlock}
            size="small"
            label="Load Out"
            type="time"
            value={productData.loadOut}
            style={{ backgroundColor: "#00ffd422", borderRadius: "10px" }}
            onChange={(e) => setProductData({ ...productData, loadOut: e.target.value })}
          />
          <TextField
            disabled={formlock}
            size="small"
            label="Curfew"
            type="time"
            value={productData.curfew}
            onChange={(e) => setProductData({ ...productData, curfew: e.target.value })}
          />
        </Grid>
      )}
      {TextFields && simple && (
        <Grid item mt={0} style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <div className="simpleTimeField">
            <label>Get In</label>
            <input
              disabled={formlock}
              size="small"
              label="Get In"
              // type="time"
              value={productData.getIn}
              onChange={(e) => setProductData({ ...productData, getIn: e.target.value })}
            />
          </div>
          <div className="simpleTimeField">
            <label>Load In</label>
            <input
              disabled={formlock}
              size="small"
              label="Load In"
              // type="time"
              value={productData.loadIn}
              style={{ backgroundColor: "#00ffd422", borderRadius: "10px" }}
              onChange={(e) => setProductData({ ...productData, loadIn: e.target.value })}
            />
          </div>
          <div className="simpleTimeField">
            <label>Soundcheck</label>
            <input
              disabled={formlock}
              size="small"
              label="Soundcheck"
              // type="time"
              value={productData.soundcheck}
              onChange={(e) => setProductData({ ...productData, soundcheck: e.target.value })}
            />
          </div>
          <div className="simpleTimeField">
            <label>Doors</label>
            <input
              disabled={formlock}
              size="small"
              label="Doors"
              // type="time"
              value={productData.doors}
              onChange={(e) => setProductData({ ...productData, doors: e.target.value })}
            />
          </div>
          <div className="simpleTimeField">
            <label>Show</label>
            <input
              disabled={formlock}
              size="small"
              label="Show"
              // type="time"
              value={productData.show}
              onChange={(e) => setProductData({ ...productData, show: e.target.value })}
            />
          </div>
          <div className="simpleTimeField">
            <label>Load Out</label>
            <input
              disabled={formlock}
              size="small"
              label="Load Out"
              // type="time"
              value={productData.loadOut}
              style={{ backgroundColor: "#00ffd422", borderRadius: "10px" }}
              onChange={(e) => setProductData({ ...productData, loadOut: e.target.value })}
            />
          </div>
          <div className="simpleTimeField">
            <label>Curfew</label>
            <input
              disabled={formlock}
              size="small"
              label="Curfew"
              // type="time"
              value={productData.curfew}
              onChange={(e) => setProductData({ ...productData, curfew: e.target.value })}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default StandardTimesSlider;
