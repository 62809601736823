/** @format */

import { Grid } from "@mui/material";
import React from "react";
import BookingsListed from "../features/owner/BookingsListed";

const Bookings = () => {
  return (
    <Grid container p={4}>
      <h1>Bookings</h1>
      <BookingsListed />
    </Grid>
  );
};

export default Bookings;
