/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField, Typography, Grid, Select, MenuItem, Switch, FormControlLabel } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import CreateEditor from "./CreateEditor";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import axios from "axios";
import CreateUser from "./CreateUser";

const HandleUsers = ({}) => {
  const [open, setOpen] = useState(false);
  const [accessibleVenues, setAccessibleVenues] = useState([{}]);
  console.log("accessibleVenues", accessibleVenues);
  const [userData, setUserData] = useState();
  const roles = ["Owner", "Editor", "Viewer"];
  const venueAccess = useSelector((state) => state.counter.venueAccess);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = async (key, value) => {
    if (key === "socialId") {
      console.log(key, value);
      console.log("encrypted:");
      const encryptedString = await encryptString(value);

      console.log("encrypted:", encryptedString);
      setUserData((prev) => ({ ...prev, socialId: encryptedString }));
    }
  };

  async function encryptString(value) {
    console.log("value:", value);
    const body = { string: value };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/encryptString`, body);
    console.log("response:", response.data);
    return response.data.encryptedString;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to Firebase
    console.log("User Data Submitted:", userData);
    handleClose();
  };

  useEffect(() => {
    if (!venueAccess || venueAccess.length === 0) return;
    async function getData() {
      try {
        const venuePromises = venueAccess.map((venueid) => getDoc(doc(db, "Venues", venueid)));
        const venueDocs = await Promise.all(venuePromises);
        const tmp = venueDocs.map((docSnap) => docSnap.data()).filter(Boolean);
        setAccessibleVenues(tmp);
      } catch (error) {
        console.error("Error fetching venue data: ", error);
      }
    }
    getData();

    async function getUsersWithAccess() {
      let tmp = [];
      for (const venueid of venueAccess) {
        const querySnapshot = await getDocs(query(collection(db, "Users"), where("venueAccess", "array-contains", venueid)));
        querySnapshot.forEach((doc) => tmp.push(doc.data()));
        console.log(
          "Users with access:",
          querySnapshot.docs.map((doc) => doc.data())
        );
      }
      setUserData(tmp);
    }
    getUsersWithAccess();
  }, [venueAccess]);

  return (
    <div>
      <PeopleIcon />
      <Button onClick={handleOpen}>Manage Users</Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "10vh",
            left: "5vw",
            width: "90vw",
            height: "80vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          style={{ overflowY: "scroll" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <h2>Manage users</h2>
              <h7>Current users</h7>
              {userData &&
                userData.map((user) => (
                  <Grid item xs={12} mt={6} className="handleUsers-user">
                    <div style={{ display: "flex", gap: "15px" }}>
                      <TextField
                        size="small"
                        id="email"
                        label="Email"
                        type="email"
                        value={user.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        inputProps={{
                          style: {
                            width: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                      <TextField
                        size="small"
                        id="socialId"
                        label="Social ID"
                        type="encrypted"
                        value={user.socialId}
                        onBlur={(e) => handleChange("socialId", e.target.value)}
                        inputProps={{
                          style: {
                            width: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                      {/* {user.socialId} */}
                      <Select size="small" defaultValue="Viewer">
                        {roles.map((role) => (
                          <MenuItem value={role}>{role}</MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Grid item xs={12} mt={4}>
                      <table className="smallTable">
                        <thead>
                          <tr>
                            <th>Venue</th>
                            <th>Edit products/modules</th>
                            <th>Edit bookings</th>
                            <th>Sign agreements</th>
                            <th>Handle Settlements</th>
                          </tr>
                        </thead>
                        <tbody style={{ borderTop: "20px solid transparent" }}>
                          {accessibleVenues.map((venue, index) => (
                            <tr>
                              <td key={index}>{venue.venue}</td>
                              <td>
                                <FormControlLabel control={<Switch defaultChecked />} />
                              </td>
                              <td>
                                <FormControlLabel control={<Switch defaultChecked />} />
                              </td>
                              <td>
                                <FormControlLabel control={<Switch defaultChecked />} />
                              </td>
                              <td>
                                <FormControlLabel control={<Switch defaultChecked />} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
              {/* <CreateEditor /> */}
              <CreateUser />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default HandleUsers;
