/** @format */

import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { BarChart } from "@mui/x-charts";
import ObjectSize from "../../helpers/ObjectSize";

const TicketSalesGraph = ({ booking }) => {
  const [data, setData] = useState([]);

  // Calculate ticket counts per week
  const getWeekNumber = (date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  useEffect(() => {
    async function getData() {
      const docSnap = await getDoc(doc(db, "TicketsTest", booking.tixlyId));
      const tmp = docSnap.data() && Object.values(docSnap.data());
      setData(tmp || []);
    }
    getData();
  }, [booking.tixlyId]);

  useEffect(() => {
    console.log("💪 data", data);
  }, [data]);

  const summarizedData = data.reduce((summary, item) => {
    const week = getWeekNumber(new Date(item.Created));
    if (!summary[week]) {
      summary[week] = 0;
    }
    summary[week] += item.TicketCount;
    return summary;
  }, {});

  // Convert summarized data into an array of objects
  const chartData = Object.keys(summarizedData).map((week) => ({
    week: `Week ${week}`,
    ticketCount: summarizedData[week],
  }));

  return (
    <div>
      <h1>Ticket Sales Bar Chart</h1>
      {data.length > 0 ? <BarChart data={chartData} width={600} height={400}></BarChart> : <p>Loading data...</p>}
    </div>
  );
};

export default TicketSalesGraph;
