/** @format */

import React, { useRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, Grid, Paper } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { db, login, useAuth } from "../../firebase";
import { Prev } from "react-bootstrap/esm/PageItem";
import { doc, getDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { resetPassword } from "./resetPassword";
import { getAuth } from "firebase/auth";
import CheckIfEmailExists from "../../utils/CheckIfEmailExists";

const PasswordReset = ({ setOpenReset, openReset, currentEmailField }) => {
  //   const currentUserId = useSelector((state) => state.counter.currentUserId);
  //   const [credentials, setCredentials] = useState({});
  const [email, setEmail] = useState(currentEmailField);
  const [loading, setLoading] = useState(false);
  const [validEmail, setValidEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);
  //   const [hidePassword, setHidePassword] = useState(false);
  //   const USR = useAuth();

  function handleClose() {
    setOpenReset(false);
  }

  const sendResetPasswordLink = async () => {
    setEmailSent(false);
    setLoading(true);
    await resetPassword(email);

    setLoading(false);
    setEmailSent(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  // Check if email is valid. (not needed since we have a function "testEmail" that checks if email exists)
  // useEffect(() => {
  //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //   if (emailRegex.test(email)) {
  //     // This block will run if 'email' is a valid email address
  //     setValidEmail(true);
  //   } else {
  //     // This block will run if 'email' is NOT a valid email address
  //     setValidEmail(false);
  //   }
  // }, [email]);

  useEffect(() => {
    testEmail();
  }, [email]);

  async function testEmail() {
    if ((await CheckIfEmailExists(email)) !== "Exists") {
      setValidEmail(false);
    } else {
      setValidEmail(true);
      return;
    }
  }

  return (
    <div>
      <Dialog
        open={openReset}
        // open={true}
        onClose={handleClose}
        PaperProps={{
          style: { backgroundColor: "#ffffff11", boxShadow: "2px 2px 10px black" },
        }}
      >
        <Paper
          className="loginPaper"
          style={{
            backgroundImage: "none",
            background: "transparent",
            backdropFilter: "blur(20px)",
            padding: "30px",
          }}
        >
          <Grid container gap={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <DialogTitle sx={{ background: "" }}>
                <h2>Reset Password</h2>
              </DialogTitle>
            </Grid>
            <DialogContent>
              <Grid item sx={{ textAlign: "center" }}>
                {loading && (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                    <div>Sending reset link to {email}...</div>
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                {emailSent ? (
                  <>
                    Check your inbox at <span style={{ color: "#00ffd4" }}>{email}</span> and click the link to reset password!
                  </>
                ) : (
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => setEmail(e.target.value)}
                    margin="normal"
                    id="name"
                    label="Email Address"
                    type="email"
                    defaultValue={currentEmailField}
                    fullWidth
                    autoFocus
                  />
                )}
              </Grid>
            </DialogContent>
          </Grid>
          <DialogActions sx={{ background: "transparent" }}>
            <Button onClick={sendResetPasswordLink} disabled={!validEmail} variant="contained">
              {validEmail ? <>Send link to reset Password</> : <>Enter valid email address</>}
            </Button>
            <Button onClick={handleClose} variant="outlined">
              ❌ Cancel
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};

export default PasswordReset;
