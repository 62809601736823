/** @format */

import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Slider, TextField } from "@mui/material";
import React from "react";
import DisplayObject from "../../helpers/displayObject";
import { numberFormatted } from "../../helpers/Numbers";

const Refrigerants = ({ handleChange, formData }) => {
  return (
    <div className="visualData">
      <h7>Refrigerants</h7>

      <div style={{ display: "flex", gap: "15px" }} className="dataInput">
        <FormControl variant="outlined">
          <InputLabel shrink>Type</InputLabel>
          <Select
            size="small"
            value={formData.refrigerantType ? formData.refrigerantType : 0}
            onChange={(e) => handleChange("refrigerantType", e.target.value)}
            style={{ minWidth: "100px" }}
          >
            <MenuItem value="dont-know">I don't know</MenuItem>
            <MenuItem value="not-applicable">Not applicable</MenuItem>
            <MenuItem value="hfc-23">HFC-23</MenuItem>
            <MenuItem value="hfc-32">HFC-32</MenuItem>
            <MenuItem value="hfc-41">HFC-41</MenuItem>
            <MenuItem value="hfc-125">HFC-125</MenuItem>
          </Select>
        </FormControl>
        <TextField
          size="small"
          label="Quantity"
          value={formData.refrigerantQuantity ? formData.refrigerantQuantity : 0}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChange("refrigerantQuantity", e.target.value)}
          style={{ width: "140px" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        />
        {formData["refrigerantQuantityCO2e"] && (
          <div className="CO2eDisplay">CO2e: {numberFormatted(formData["refrigerantQuantityCO2e"])} kg</div>
        )}
      </div>
    </div>
  );
};

export default Refrigerants;
