/** @format */

import React, { useState, useCallback } from "react";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import ReactDOM from "react-dom";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import { withStyles } from "@mui/styles";
// import { getOrientation } from "get-orientation/browser";
import ImgDialog from "./ImgDialog";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
const storage = getStorage();
const storageRef = ref(storage);
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

export const ImageUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    console.log("imagecropped12321313", imageSrc, croppedImage);
  }, [imageSrc, croppedImage]);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      setCroppedImage(croppedImage);
      setImageSrc(croppedImage.toDataURL());
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      // setLoading(false);
    }
  };
  const urlToBlob = (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return blob;
      });
  };

  const cropAndSave = async () => {
    setLoading(true);
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
    console.log("Cropped90909090", croppedImage);
    urlToBlob(croppedImage)
      .then((blob) => {
        return blobToBase64(blob);
      })
      .then((base64) => {
        setImageSrc(base64);
        const uuid = uuidv4();
        console.log(uuid, "imageSrc", base64);
        const imagesRef = ref(storageRef, `${props.storageFolder}/${uuid}`);
        uploadString(imagesRef, base64, "data_url").then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            props.setFormValues((prev) => ({
              ...props.formValues,
              [props.formImageKey]: downloadURL,
            }));
          });
          setLoading(false);
        });
      });
    const uuid = uuidv4();
    console.log(uuid, "imageSrc", imageSrc);
    // const imagesRef = ref(storageRef, `${props.storageFolder}/${uuid}`);
    // uploadString(imagesRef, imageSrc, "data_url").then((snapshot) => {
    //   getDownloadURL(snapshot.ref).then((downloadURL) => {
    //     props.setFormValues((prev) => ({
    //       ...props.formValues,
    //       [props.formImageKey]: downloadURL,
    //     }));
    //   });
    // });
  };

  return (
    <div>
      {props.formValues[props.formImageKey] ? (
        <div>
          <img
            src={props.formValues[props.formImageKey]}
            alt="image"
            style={{
              width: "100%",
              height: "300px",
              objectFit: "cover",
            }}
          />
        </div>
      ) : imageSrc ? (
        <React.Fragment>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 200,
              background: "#333",
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1",
                alignItems: "center",
              }}
            >
              <Typography variant="overline">Zoom</Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                style={{ padding: "22px 0px", marginLeft: 16 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flex: "1",
                alignItems: "center",
              }}
            >
              <Typography variant="overline">Rotation</Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation)}
                style={{ padding: "22px 0px", marginLeft: 16 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flex: "1",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Grid container gap={2}>
                {/* <Button onClick={showCroppedImage} variant="contained" color="primary">
                  Show Result
                </Button> */}
                {/* <Button
                  onClick={async () => {
                    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
                    console.log("Cropped90909090", croppedImage);
                    urlToBlob(croppedImage)
                      .then((blob) => {
                        return blobToBase64(blob);
                      })
                      .then((base64) => {
                        setImageSrc(base64);
                      });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Crop
                </Button> */}
                <Button
                  onClick={() => {
                    cropAndSave();
                    //     console.log("imageSrc", imageSrc);
                    //     const uuid = uuidv4();
                    //     const imagesRef = ref(storageRef, `${props.storageFolder}/${uuid}`);
                    //     uploadString(imagesRef, imageSrc, "data_url").then((snapshot) => {
                    //       getDownloadURL(snapshot.ref).then((downloadURL) => {
                    //         props.setFormValues((prev) => ({
                    //           ...props.formValues,
                    //           [props.formImageKey]: downloadURL,
                    //         }));
                    //       });
                    //     });
                  }}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  style={{ marginTop: "15px" }}
                >
                  {console.log(props.formValues)}
                  {loading ? <CircularProgress size="15px" style={{ marginRight: "10px" }} /> : <></>}
                  Set
                </Button>
                <Button style={{ marginTop: "15px" }} onClick={() => setImageSrc(false)}>
                  Another Image
                </Button>
              </Grid>
            </div>
          </div>
          <ImgDialog img={croppedImage} onClose={onClose} />
        </React.Fragment>
      ) : (
        <div>
          <input type="file" onChange={(e) => onFileChange(e)} accept="image/*" />
        </div>
      )}
    </div>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
