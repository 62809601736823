/** @format */

import React, { useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

import { Button, Grid, MenuItem, Select } from "@mui/material";
import { isEqual } from "lodash";
import { db } from "../../firebase";

const CompareDocs = () => {
  const [doc1, setDoc1] = useState("1366e87d-7786-4acb-98a7-c8febe4a2dc6");
  const [doc2, setDoc2] = useState("1366e87d-7786-4acb-98a7-c8febe4a2dc6");
  const [keys, setKeys] = useState([]);

  const handleCompare = async () => {
    if (!doc1 || !doc2) {
      return;
    }

    const bookingsCollection = collection(db, "Bookings");
    // const doc1Data = (await getDoc(bookingsCollection)).doc.data();
    // const doc2Data = (await getDoc(bookingsCollection)).doc.data();

    const doc1Data = await getDoc(doc(bookingsCollection, doc1));
    const doc2Data = await getDoc(doc(bookingsCollection, doc2));

    const allKeys = [...new Set([...Object.keys(doc1Data), ...Object.keys(doc2Data)])];
    const differentKeys = allKeys.filter((key) => !isEqual(doc1Data[key], doc2Data[key]));

    const result = allKeys.map((key) => {
      const isDifferent = differentKeys.includes(key);
      return {
        key,
        doc1: doc1Data[key],
        doc2: doc2Data[key],
        isDifferent,
      };
    });

    setKeys(result);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Select value="1366e87d-7786-4acb-98a7-c8febe4a2dc6" onChange={(e) => setDoc1(e.target.value)} fullWidth>
          <MenuItem value="">Select document 1</MenuItem>
          <MenuItem value="1366e87d-7786-4acb-98a7-c8febe4a2dc6">Document 1</MenuItem>
          <MenuItem value="1366e87d-7786-4acb-98a7-c8febe4a2dc6">Document 2</MenuItem>
        </Select>
      </Grid>
      <Grid item>
        <Select value="1366e87d-7786-4acb-98a7-c8febe4a2dc6" onChange={(e) => setDoc2(e.target.value)} fullWidth>
          <MenuItem value="">Select document 2</MenuItem>
          <MenuItem value="1366e87d-7786-4acb-98a7-c8febe4a2dc6">Document 1</MenuItem>
          <MenuItem value="1366e87d-7786-4acb-98a7-c8febe4a2dc6">Document 2</MenuItem>
        </Select>
      </Grid>
      <Grid item>
        <Button onClick={handleCompare} variant="contained" color="primary">
          Compare
        </Button>
      </Grid>
      {keys.length > 0 && (
        <Grid item xs={12}>
          <table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Document 1</th>
                <th>Document 2</th>
              </tr>
            </thead>
            <tbody>
              {keys.map((key) => (
                <tr key={key.key}>
                  <td style={{ color: key.isDifferent ? "red" : "green" }}>{key.key}</td>
                  <td>{JSON.stringify(key.doc1)}</td>
                  <td>{JSON.stringify(key.doc2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      )}
    </Grid>
  );
};

export default CompareDocs;
