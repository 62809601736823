/** @format */

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { doc, updateDoc } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { db } from "../../firebase";
import { storeCurrentVenue } from "../../features/venueFilter/venueFilterSlice";
import { CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const MultiImagesUpload = ({ bookingid }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const venueid = currentVenue && currentVenue.venueid;
  const [selectedFiles, setSelectedFiles] = useState([]); // Changed to support multiple files
  const [uploadProgresses, setUploadProgresses] = useState({}); // Object to track progress of each file
  const [downloadURLs, setDownloadURLs] = useState([...currentVenue.venueImages]); // Array to store URLs of uploaded files
  const [done, setDone] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [currentSize, setCurrentSize] = useState(0);
  const dispatch = useDispatch();

  //   const handleFileChange = async (event) => {
  //     const files = Array.from(event.target.files);
  //     const resizedFilesPromises = files.map((file) => {
  //       if (file.type.startsWith("image/") && file.size > 200 * 1024) {
  //         // Attempt to resize the image to fit within 1MB by adjusting dimensions
  //         return resizeImageToFit1MB(file, 500); // Adjust '500' as needed
  //       }
  //       return Promise.resolve(file); // Return the file as-is if not resizing
  //     });

  //     Promise.all(resizedFilesPromises)
  //       .then((resizedFiles) => {
  //         setSelectedFiles(resizedFiles);
  //       })
  //       .catch((error) => {
  //         console.error("Error resizing files:", error);
  //       });
  //   };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const resizedFilesPromises = files.map((file) => {
      setResizing(true); // Indicate that resizing is starting
      if (file.type.startsWith("image/") && file.size > 200 * 1024) {
        // Resize image if larger than 1MB
        return resizeImageToFit1MB(file, 500); // Adjust as needed
      }
      return Promise.resolve(file); // Return the file as-is if not resizing
      setResizing(false); // Resizing complete
    });

    Promise.all(resizedFilesPromises)
      .then((resizedFiles) => {
        setSelectedFiles(resizedFiles);
      })
      .catch((error) => {
        console.error("Error resizing files:", error);
        setResizing(false); // Ensure resizing is marked as complete even on error
      });
  };

  const handleUpload = async () => {
    setDone(false);
    const storage = getStorage();
    const promises = selectedFiles.map((file) => {
      const storageRef = ref(storage, `venueImages/${venueid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgresses((prevProgresses) => ({
              ...prevProgresses,
              [file.name]: progress,
            }));
          },
          reject,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    });

    Promise.all(promises)
      .then((urls) => {
        setDownloadURLs((previous) => [...previous, ...urls]);
        console.log("All files uploaded successfully", urls);
        hideUploadStatus();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  function hideUploadStatus() {
    setTimeout(() => {
      setDone(true);
    }, [2000]);
  }

  useEffect(() => {
    // Ensure there are URLs to update and a valid venueid is present
    if (downloadURLs.length > 0 && venueid) {
      const updateVenueWithImages = async () => {
        try {
          // Reference to the venue document in Firestore
          const venueRef = doc(db, "Venues", venueid);

          // Update the document with the new images array
          // This could be an append operation or a replacement, depending on your needs
          await updateDoc(venueRef, {
            venueImages: downloadURLs, // This replaces the entire array; adjust logic if appending is needed
          });

          // Optionally: Dispatch Redux action to update local state
          // This step depends on your application's architecture
          //   dispatch(storeCurrentVenue({ ...currentVenue, venueImages: downloadURLs }));

          console.log("Venue images updated successfully.");
        } catch (error) {
          console.error("Failed to update venue images:", error);
        }
      };

      updateVenueWithImages();
    }
    //   }, [downloadURLs, venueid, currentVenue, dispatch]); // Dependency array to trigger effect when these values change
  }, [downloadURLs]); // Dependency array to trigger effect when these values change

  const handleRemoveImage = (url) => {
    setDownloadURLs(downloadURLs.filter((downloadURL) => downloadURL !== url));
    deleteImage(url);
  };

  function resizeImageToFit1MB(file, maxDimension, step = 500, quality = 1) {
    return new Promise((resolve, reject) => {
      setResizing(true);

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        let resizeRatio = 1;

        // Function to attempt resizing and check size
        const attemptResize = () => {
          // Calculate new dimensions
          const newWidth = width * resizeRatio;
          const newHeight = height * resizeRatio;

          // Create a canvas, draw the image at the new dimensions
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          // Convert to blob and check file size
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Canvas to Blob conversion failed"));
                return;
              }

              if (blob.size <= 1024 * 1024) {
                // If size is under 1MB, resolve with new file
                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(resizedFile);
                setResizing(false);
              } else if (newWidth > maxDimension && newHeight > maxDimension) {
                // Reduce dimensions for next attempt
                setCurrentSize(`${((newWidth * newHeight) / 1000000).toFixed(2)} MB`);
                resizeRatio -= step / Math.max(width, height);
                attemptResize();
              } else {
                // If we can't resize it smaller than 1MB at the min dimensions, reject
                reject(new Error("Unable to reduce image size under 1MB"));
              }
            },
            file.type,
            quality
          );
        };

        // Start the resize attempts
        attemptResize();
      };
      img.onerror = (error) => reject(error);
    });
  }

  const deleteImage = async (imagePath) => {
    const storage = getStorage();
    const imageRef = ref(storage, imagePath);

    try {
      await deleteObject(imageRef);
      console.log("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  return (
    <div>
      <input type="file" multiple accept=".png,.jpeg,.jpg" onChange={handleFileChange} className="uploadInput" />
      {resizing && (
        <div style={{ color: "#00ffd499", fontSize: "10px", padding: "15px" }}>
          <CircularProgress style={{ zoom: "0.2", marginRight: "15px" }} />
          {currentSize} ...resizing images
        </div>
      )}
      <Button variant="outlined" size="small" onClick={handleUpload} disabled={resizing}>
        {loading ? (
          <>
            <CircularProgress style={{ zoom: "0.4", marginRight: "15px" }} /> Hang on
          </>
        ) : (
          "Upload"
        )}
      </Button>

      {!done &&
        Object.entries(uploadProgresses).map(([fileName, progress]) => (
          <div key={fileName} style={{ marginTop: "10px" }}>
            <LinearProgress variant="determinate" value={progress} />
            <p>
              {fileName}: {progress}%
            </p>
          </div>
        ))}
      <div className="venueImages">
        {console.log("DownloadURLs", downloadURLs)}
        {downloadURLs &&
          downloadURLs.map((url) => (
            <div key={url} style={{ margin: "10px 0" }} className="imageUploaded">
              <a href={url} target="_blank">
                <img src={url} alt="Uploaded" style={{ width: "100px" }} />
              </a>
              <div onClick={() => handleRemoveImage(url)} className="removeImage">
                <DeleteIcon />
              </div>
            </div>
          ))}
      </div>
      {/* {downloadURLs.length > 0 && <Button onClick={() => setDownloadURLs([])}>Clear All</Button>} */}
    </div>
  );
};

export default MultiImagesUpload;
