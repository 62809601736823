/** @format */

import { BookmarkAddTwoTone } from "@mui/icons-material";
import { current } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { time } from "./time";
// import { useSelector } from "react-redux";

export default async function checkAvails(currentVenue, chosenDateRange, prefDays, currentProduct, setAvails, setAwaiting) {
  let booked = [];
  let tmpAvails = [];
  let tmpAwaiting = [];

  // CHAIN
  await findBookings();
  await findAvails();
  await findAwaiting();
  setAvails(tmpAvails);
  setAwaiting(tmpAwaiting);

  // CHAIN End

  function findBookings() {
    currentVenue.bookings &&
      currentVenue.bookings.map((booking) => {
        const dayOfWeek = new Date(booking.date).toLocaleDateString("en-US", { weekday: "long" }); // using "en-US" to get the day of the week in english
        console.log("chosenDateRange", chosenDateRange, "booking.date", booking.date, "prefDays", prefDays);
        if (
          chosenDateRange &&
          chosenDateRange.includes(booking.date) === true &&
          (booking.status === "Confirmed" || booking.status === "Awaiting Artist" || booking.status === "Blocked") &&
          time(booking.loadIn) <= time(currentProduct.loadOut) &&
          time(booking.loadOut) >= time(currentProduct.loadIn) &&
          prefDays.includes(dayOfWeek)
        ) {
          booked.push(booking);
        }
      });
  }

  function findAvails() {
    // get all DAYS except "confirmed"
    tmpAvails =
      chosenDateRange &&
      chosenDateRange.filter((date) => {
        const dayOfWeek = new Date(date).toLocaleDateString("en-US", { weekday: "long" }); // using "en-US" to get the day of the week in english
        // console.log("dayOfWeek", dayOfWeek, "prefDays", prefDays);
        let tmpPrefDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        if (prefDays !== undefined) tmpPrefDays = prefDays;
        return !booked.some((booking) => booking.date === date) && tmpPrefDays.includes(dayOfWeek);
      });
  }
  // }

  function findAwaiting() {
    // get all awaiting
    booked.map((booking) => {
      const dayOfWeek = new Date(booking.date).toLocaleDateString("en-US", { weekday: "long" }); // using "en-US" to get the day of the week in english
      if (booking.status === "Awaiting Artist" && chosenDateRange.includes(booking.date) && prefDays.includes(dayOfWeek))
        tmpAwaiting.push(booking.date);
    });
  }
}
