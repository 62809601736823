/** @format */

import { Slider } from "@mui/material";
import React, { useEffect } from "react";

const PaymentGraph = ({ bookingData, setBookingData, upfrontMax, preChargeMax, balanceMax, formlock, steps }) => {
  console.log("bookingData precharge", bookingData.preCharge);
  const x = 1.2;
  const width = "20px";
  const q = 1.5;

  const upfront = bookingData.upfront;
  const upfrontAt = 365 - bookingData.upfrontAt;
  const deposit = bookingData.preCharge;
  const depositAt = 365 - bookingData.preChargeAt;
  const balance = bookingData.balance;
  const balanceAt = 365 - bookingData.balanceAt;

  //   let upfrontLeft = upfrontAt;
  let upfrontLeft = 0;

  if (upfrontAt < 0) {
    upfrontLeft = 0;
  }

  // useEffect(() => {
  //   console.log("upfront", upfront, "deposit", deposit, "balance", balance);
  // }, [bookingData]);

  return (
    <div className="paymentGraph" style={{ position: "relative", width: `27.3%`, height: "100px" }}>
      {/* bookingData preCharge: {bookingData.preCharge} */}
      <div
        className="upfront"
        style={{
          position: "absolute",
          left: `${upfrontLeft}%`,
          width: width,
        }}
      >
        <label
          style={{
            position: "absolute",
            backdropFilter: "blur(10px)",

            // bottom: `${upfront + 5}% `
          }}
        >
          <span>Upfront</span> <span>{upfront}%</span>
        </label>
        <Slider
          disabled={formlock}
          min={0}
          max={100}
          step={steps}
          value={upfront}
          valueLabelDisplay="off"
          orientation="vertical"
          onChange={(e) => setBookingData((prev) => ({ ...prev, upfront: Math.min(e.target.value, upfrontMax) }))} // Math.min prevents the value from exceeding the max
        />
      </div>
      {/* {deposit && ( */}
      <div className="deposit" style={{ position: "absolute", left: `${depositAt}%`, width: width, height: "100px" }}>
        <label
          style={{
            position: "absolute",
            backdropFilter: "blur(10px)",
            //   bottom: `${deposit && deposit + 5}%`,
          }}
        >
          <span>Deposit</span> <span>{deposit}%</span>
        </label>
        <Slider
          disabled={formlock}
          min={0}
          max={100}
          step={steps}
          value={deposit}
          valueLabelDisplay="off"
          orientation="vertical"
          onChange={(e) => setBookingData((prev) => ({ ...prev, preCharge: Math.min(e.target.value, preChargeMax) }))} // Math.min prevents the value from exceeding the max
        />
      </div>
      {/* )} */}
      <div className="balance" style={{ position: "absolute", left: `${balanceAt}%`, width: width, height: "100px" }}>
        <label
          style={{
            position: "absolute",
            backdropFilter: "blur(10px)",

            // bottom: `${balance && balance + 5}% !important`
          }}
        >
          <span>Balance</span> <span>{balance}%</span>
        </label>
        <Slider disabled={formlock} min={0} max={100} step={5} value={balance} valueLabelDisplay="off" orientation="vertical" />
      </div>
    </div>
  );
};

export default PaymentGraph;
