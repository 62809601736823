/** @format */

import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Icon } from "@mui/material";

const LikeButton = (props) => {
  const [isLiked, setIsLiked] = React.useState(false);

  const handleClick = () => {
    setIsLiked(!isLiked);
  };

  return (
    <button
      onClick={handleClick}
      style={{ zIndex: "10", background: "transparent", border: "none" }}
      // className="LikeButton"
    >
      {isLiked ? (
        <div>
          <FavoriteIcon size={15} />
        </div>
      ) : (
        <div>
          <FavoriteBorderIcon size={15} />
        </div>
        // <span role="img" aria-label="heart">
        //   ❤️
        // </span>
        // <span role="img" aria-label="unliked">
        //   ♡
        // </span>
      )}
    </button>
  );
};

export default LikeButton;
