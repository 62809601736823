/** @format */

import { doc, updateDoc } from "firebase/firestore";
import { createId, db } from "../firebase";
import { SendRequest } from "./SendRequest";

const SendBatchRequest = async (currentArtist, currentAgent, wantedList, agreementId) => {
  let bookingids = [];
  // const agreementId = wantedList.agreementId;
  console.log("🎃", agreementId);
  console.log("wantedList", wantedList);
  await goSendBatch();
  async function goSendBatch() {
    // console.log(currentArtist);
    // console.log(currentAgent);

    await wantedList.map((availData) => {
      const bookingid = createId();
      bookingids.push(bookingid);
      SendRequest(currentArtist, currentAgent, availData, availData.proposedDate, agreementId, bookingid);
    });

    // const agreementPackage = {
    //   bookingids: bookingids,
    //   status: "Pending Request",
    //   email: email,
    //   agent: { email: "", phone: "" },
    //   venue: currentVenue.venue,
    //   venueid: currentVenue.venueid,
    //   documentid: agreementId,
    //   agreementId: agreementId,
    //   venueLogo: currentVenue.venueLogo,
    // };

    // set the bookingids in Agreements
    await updateDoc(doc(db, "Agreements", agreementId), {
      bookingids: bookingids,
      // earlyCancelation: 50,
      // earlyCancelationAt: 180,
      // lateCancelation: 100,
      // lateCancelationAt: 60,
    });
  }
  return "done";
};

export default SendBatchRequest;
