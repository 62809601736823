/** @format */

import React from "react";

const DayOfTheWeekMini = (props) => {
  const days = ["S", "M", "T", "W", "T", "F", "S"];

  return (
    <div className="">
      {days.map(
        (day, index) => (
          //   <div>{days[3]}</div>
          <div>
            {index === props.dayIndex ? (
              <div className="DayOfTheWeekMini TheActualDayOfTheWeek">{days[props.dayIndex]}</div>
            ) : (
              <div className="DayOfTheWeekMini">{days[index]}</div>
            )}
          </div>
        )
        //   {
        //     index == props.dayIndex ? (
        //       <div className="TheActualDayOfTheWeek">{days[index]}</div>
        //     ) : (
        //       <div className="DayOfTheWeekMini">{days[index]}</div>
        //     );
        //   }
      )}
    </div>
  );
};

export default DayOfTheWeekMini;
