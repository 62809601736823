/** @format */

// Sends an email with short message and a button to log in and request avails.

import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { site } from "../../utils/site";

export function quickSend(exists, name, email, venue, avails, awaiting, availsid, currentProduct, EmailSent) {
  // alert(availsid);
  // const currentVenue = useSelector((state) => state.counter.currentVenue);
  // const venue = currentVenue.venue;
  // console.log(email, venue.venue, avails, awaiting);
  // var availsInText = () => <div>{avails.awaiting.map((dateToChallenge) => dateToChallenge)}</div>;
  // console.log("availsInText", avails);
  const newUserId = "this is id token";
  const currentYear = new Date().getFullYear();
  // let availCount = avails.nightAvails.length;
  let availCount = avails.length;
  let availsMessage = "";
  console.log("currentProduct", currentProduct);
  if (availCount === 0) {
    availsMessage = `There are no available dates`;
  } else if (availCount === 1) {
    availsMessage = `There is <span style='color:#00ffd4;font-weight:900;'>1</span> available date`;
  } else {
    availsMessage = `There are <span style='color:#00ffd4;font-weight:900;'>${availCount}</span> available dates.`;
  }

  // let preliminaryCount = avails.nightAwaiting.length;
  let preliminaryCount = awaiting.length;
  let preliminaryMessage = "";

  if (preliminaryCount === 0) {
    preliminaryMessage = `no preliminary booked dates.`;
  } else if (preliminaryCount === 1) {
    preliminaryMessage = `<span style="color:#00ffd4;font-weight:900;">1</span> preliminary booked date.`;
  } else {
    preliminaryMessage = `<span style='color:#00ffd4;font-weight:900;'>${preliminaryCount}</span> preliminary dates booked.`;
  }

  const msg = {
    to: [email],
    message: {
      subject: `Available dates at ${venue.venue}`,
      text: "This is the plaintext section of the email body.",
      html: `<body style="background:white">
      <table cellpadding="0" cellspacing="0" border="0" width="100%" >
        <tr>
          <td align="left" style="padding: 10px">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" >
              <tr>
                <td align="center" style="padding: 15px">
                  <table cellpadding="0" cellspacing="0" border="0" width="100%" >
                    
                    <tr style="background: linear-gradient(0deg, #111, #333);width:100%;">
                      <td align="center" style="margin-top:10px;padding: 15px; font-size: 18px; font-family: avenir;border-radius:10px;box-shadow:2px 2px 6px black">
                        <span style="font-size: 28px; font-weight: bold;color:#fff">Available dates at ${venue.venue}</span>
                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr>
                            <td align="center" style="color: #aaa; padding-top: 20px;;font-size:18px;">
                             </br>Thank you for requesting available dates at ${venue.venue}! (${currentProduct.product})
                            </td>
                          </tr>
                          <tr>
                            <td align="center" style="color: #aaa; padding-bottom: 0px;font-size:18px;">
                             </br>${availsMessage} and ${preliminaryMessage}.
                            </td>
                          </tr>
                          <tr>
                          <td align="center" style="color: #aaa; padding-bottom: 0px;font-size:18px;">
                          Click the link below to see open dates and make your request!
                          </td>
                          </tr>
                          <tr>
                            <td align="center" style="padding-top: 30px;">
                              <a
                                href="${site}/RequestAvails?user=${newUserId}&exists=${exists}&venue=${venue.venue}&password=123456&availsid=${availsid}"
                                style="text-decoration: none"
                              >
                                <table cellpadding="0" cellspacing="0" border="0" bgcolor="#333333" style="border-radius: 2mm; margin: 20px">
                                  <tr>
                                    <td
                                      align="center"
                                      style="padding: 15px 30px; font-size: 18px; font-weight: bold; color: white; border: 1px solid #00ffd4;border-radius:5px;"
                                    >
                                      Choose dates
                                    </td>
                                  </tr>
                                </table>
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td
            align="center"
            style="
              font-family: avenir;
              color: #777;
              padding: 20px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            "
          >
          <a href="https://venuehub.live" style="text-decoration:none;color:#000;">
            © ${currentYear} Powered by 
            <img
            alt="Venuehub AB"
              src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&amp;token=517af342-186c-4317-9204-47580e024ce4"
              style="margin-left:20px;width: 24px; height: 24px; vertical-align: middle; margin-right: 5px"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&amp;token=afb33ecb-24d6-4158-8206-5254c01618a3"
              style="width: 100px; height: 17px; vertical-align: middle"
            /></br> </a>
            <br />
            
          </td>
        </tr>
      </table>
    </body>`,
    },
  };
  //   setDoc(doc(db, "mail", bookingData["bookingid"]), { ...bookingData });
  var mailid;
  setDoc(doc(db, "mail", availsid), { ...msg });
  // .then((docRef) => {
  //   // mailid = docRef.id;
  //   EmailSent("Email Sent!");
  //   // console.log(`Document has been added successfully to mail! ${mailid}`);
  // })
  // .catch((error) => {
  //   // console.log(error);
  // });
}

export async function createAvailsPost(name, email, venue, avails, awaiting, chosenDateRange, currentProduct) {
  var availsid = "";
  var data = {
    availsid: "place for id",
    name,
    email,
    venueid: venue.venueid,
    venue: venue.venue,
    avails,
    awaiting,
    chosenDateRange,
    currentProduct,
    // upfront: venue.upfront,
    // upfrontAt: venue.upfrontAt,
    // preCharge: venue.preCharge,
    // preChargeAt: venue.preChargeAt,
    // balance: venue.balance,
    // balanceAt: venue.balanceAt,
    // earlyCancelation: venue.earlyCancelation,
    // earlyCancelationAt: venue.earlyCancelationAt,
    // lateCancelation: venue.lateCancelation,
    // lateCancelationAt: venue.lateCancelationAt,
  };

  await addDoc(collection(db, "nuAvails"), data)
    .then((docRef) => {
      availsid = docRef.id;
      console.log(`Document has been added successfully to nuAvails! ${availsid}`);
      updateDoc(docRef, { availsid: availsid })
        .then((docRef) => {
          console.log("AvailsID updated!");
        })
        .catch((error) => {
          console.log("Something went wrong creating the availsPost", error);
        });
    })
    .catch((error) => {
      // console.log(error);
    });

  return availsid;
}
