/** @format */

import { Circle, GoogleMap, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect } from "react";
import { useState } from "react";
import { db } from "../firebase";
import { darkMapStylesOrange } from "../components/UI helpers/mapStyles";
import { doc, onSnapshot } from "firebase/firestore";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { wait } from "../components/MiscUIComponents/Wait";
import CreateBookingQrCode from "../helpers/CreateBookingQrCode";
import { set } from "date-fns";

const EventPollVisual = () => {
  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(8);
  const [coordinates, setCoordinates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  const [showTicketCount, setShowTicketCount] = useState(true);
  const [msg, setMsg] = useState("");
  //   const ticketEventId = booking.ticketEventId;
  //   const ticketVenueId = booking.ticketVenueId;
  const [travelData, setTravelData] = useState([null]);
  const [visitors, setVisitors] = useState([null]);
  const [tooltip, setTooltip] = useState(null);
  const [travelImpact, setTravelImpact] = useState({
    visitors,
    distance: 240,
    climateImpact: 360,
    travel: 480,
    carDistance: 0,
    bicycleDistance: 0,
    busDistance: 0,
    trainDistance: 0,
    planeDistance: 0,
  });
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const currentVenue = useSelector((state) => state.counter.currentVenue);

  const [selectOpen, setSelectOpen] = useState(true);
  const [booking, setBooking] = useState({});
  const [bookingid, setBookingId] = useState(null);
  const handleSelectClose = () => {
    setSelectOpen(false);
  };
  const handleBookingChange = (e) => {
    setBookingId(e.target.value);
  };

  const [CENTER, setCENTER] = useState({ lat: 59.3293, lng: 18.0686 });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleApiKey,
  });

  const [map, setMap] = useState(null); // Add this state

  const onLoad = (mapInstance) => {
    setMap(mapInstance); // Store the map instance in the state
  };

  // Function to toggle fullscreen mode for the div with id 'eventpollvisual'
  function toggleFullScreen() {
    const elem = document.getElementById("evp");

    if (!document.fullscreenElement) {
      // Request fullscreen for the specific div
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        // Safari compatibility
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        // IE11 compatibility
        elem.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Safari compatibility
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE11 compatibility
        document.msExitFullscreen();
      }
    }
  }

  useEffect(() => {
    if (travelData.length > 1) setCoordsLoaded(true);
  }, [travelData]);

  useEffect(() => {}, [CENTER]);

  useEffect(() => {
    if (!bookingid) return;
    // Setting up the listener to get instantaneous updates
    const unsubscribe = onSnapshot(
      doc(db, "BenchmarkTravelData", bookingid),
      (doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            setTravelData(Object.values(data));
          } else {
            console.log("Document has no data!");
            setTravelData([]); // Or set some default value
          }
        } else {
          console.log("No such document!");
          setTravelData([]); // Or set some default value
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [bookingid]);

  useEffect(() => {
    setBookingId(booking.bookingid);
  }, [booking]);

  useEffect(() => {
    if (!bookingid) return;
    // toggleFullScreen();
    return <></>;
  }, [bookingid]);

  useEffect(() => {
    if (map && travelData && travelData.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      const lastThreeCoords = travelData.slice(-2);

      // Loop through travelData to extend bounds to include each coordinate
      lastThreeCoords.forEach((coord) => {
        if (coord.lat && coord.lng) {
          bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
        }
      });
      map.fitBounds(bounds);
      setMsg("❤️ Tack!");

      setTimeout(() => {
        travelData.forEach((coord) => {
          if (coord.lat && coord.lng) {
            bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
          }
        });
        setMsg("Vi mäter vår miljöpåverkan. 🌿 Hjälp oss genom att scanna QR-koden och ange hur du kom hit idag.");
        map.fitBounds(bounds);
      }, 5000);
    }
  }, [map, travelData]);

  // useEffect(() => {
  //   if (map && travelData && travelData.length > 0) {
  //     const lastCoord = travelData[travelData.length - 1];
  //     // setMsg("Last zip code: " + lastCoord.zipCode && lastCoord.zipCode);
  //     setMsg("❤️ Tack för ditt deltagande!");
  //   }
  // }, [map, travelData]);

  useEffect(() => {
    if (!bookingid) return;
    document.documentElement.requestFullscreen();
  }, [bookingid]);

  return (
    <div>
      {console.log("currentVenueBookings", currentVenueBookings)}
      {console.log("booking", bookingid)}
      {console.log("travelData", travelData)}
      <Dialog open={selectOpen} onClose={handleSelectClose}>
        <DialogTitle>Select a booking</DialogTitle>
        <DialogContent>
          <DialogContentText>Select a booking to view the travel data for that event.</DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="booking-select">Booking</InputLabel>
            <Select
              labelId="booking-select"
              id="booking-select"
              value={booking || "a"}
              onChange={(e) => setBooking(e.target.value)}
              style={{ width: "100%" }}
            >
              <MenuItem value={"a"}>Choose booking</MenuItem>
              {currentVenueBookings.map((booking) => (
                <MenuItem key={booking.bookingid} value={booking} startIcon={<Avatar src={booking.artist.image} fullWidth />}>
                  <Avatar src={booking.artist.image} style={{ marginRight: "15px" }} /> {booking.artist.artist} - {booking.date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectClose}>Cancel</Button>
          <Button onClick={handleSelectClose}>Done</Button>
        </DialogActions>
      </Dialog>
      {/* <Button
        onClick={() => {
          setSelectOpen(!selectOpen);
        }}
      >
        EventPollVisual
      </Button> */}
      {bookingid && travelData && travelData.length > 1 && (
        <div id="evp" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100vh", zIndex: "100000" }}>
          <div className="evp-artist-image-blur">
            <img src={booking.artist.image} alt={booking.artist} />
          </div>
          <div className="evp-artist-image" onClick={() => document.documentElement.requestFullscreen()}>
            <img src={booking.artist.image} alt={booking.artist} />
          </div>
          <div className="evp-artist-info">
            <h2>
              {booking.artist.artist} {booking.date}
            </h2>
          </div>
          <div className="evp-venue-logo">
            <img src={currentVenue.venueLogo} />
          </div>
          <div className="evp-msg">{msg}</div>
          {bookingid && (
            <div className="evp-QR-image">
              <h7>Poll QR code</h7>
              <CreateBookingQrCode message={`https://venuehub.live/TravelForm?id=${bookingid}`} filnamn={`travel${bookingid}`} />
            </div>
          )}

          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100vh" }}
            center={CENTER}
            zoom={zoom}
            options={{ styles: darkMapStylesOrange, disableDefaultUI: true }}
            className="travelDataMap"
            onLoad={onLoad} // Add this onLoad handler
          >
            {travelData.map((coord, i) => (
              <React.Fragment key={coord + 1}>
                <div key={i}>
                  {console.log("coord", coord)}
                  <OverlayView
                    position={{
                      lat: coord.lat,
                      lng: coord.lng,
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div
                      className={i === travelData.length - 1 ? "lastTravelDataPoint" : "pollTravelDataPoint"}
                      style={{
                        width: "20px",
                        height: "20px",
                        background: "#00ffd455",
                        borderRadius: "50%",
                        textAlign: "center",
                        padding: "3px",
                      }}
                    >
                      {showTicketCount && coord.count}
                    </div>
                  </OverlayView>
                </div>
              </React.Fragment>
            ))}
            <Circle
              className="circle"
              center={CENTER}
              radius={10000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              className="circle"
              center={CENTER}
              radius={20000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              className="circle"
              center={CENTER}
              radius={80000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              className="circle"
              center={CENTER}
              radius={500000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
                strokeDashArray: "5 2",
              }}
            />
          </GoogleMap>
        </div>
      )}
    </div>
  );
};

export default EventPollVisual;
