/** @format */

import React, { useState, useEffect } from "react";

function RythmLogin() {
  const [clickCount, setClickCount] = useState(0);
  const [clickTimestamps, setClickTimestamps] = useState([]);
  const [superOwner, setSuperOwner] = useState(false);
  const [rhythmPattern, setRhythmPattern] = useState([]);
  const [rhythmPatternInput, setRhythmPatternInput] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const rhythmTolerance = 100; // Adjust tolerance in milliseconds

  const handleClick = () => {
    if (isRecording && clickCount < 7) {
      setClickCount(clickCount + 1);
      setClickTimestamps([...clickTimestamps, Date.now()]);

      if (clickCount > 3) {
        const timeDiffs = clickTimestamps.slice(1).map((t, i) => t - clickTimestamps[i]);
        setIsRecording(false);

        setRhythmPatternInput(timeDiffs.join(","));
        setRhythmPattern(timeDiffs);
      }
    } else {
      setClickCount(clickCount + 1);
      if (clickCount === 5) {
        checkRhythm();
        setClickCount(0);
      }
    }
  };

  useEffect(() => {
    console.log("clickCount", clickCount);
    console.log("clickTimeStamps", clickTimestamps);
    console.log("rhythmPattern", rhythmPattern);
    console.log("rhythmPatternInput", rhythmPatternInput);
  }, [clickTimestamps, rhythmPattern, rhythmPatternInput]);

  const handleStartRecording = () => {
    setClickCount(0);
    setClickTimestamps([]);
    setIsRecording(true);
  };

  const checkRhythm = () => {
    const timeDiffs = clickTimestamps.slice(1).map((t, i) => t - clickTimestamps[i]);

    if (timeDiffs.length !== rhythmPattern.length) {
      // Reset click count and timestamps
      setClickCount(0);
      setClickTimestamps([]);
      return;
    }

    const isRhythmCorrect = timeDiffs.every((diff, i) => Math.abs(diff - rhythmPattern[i]) < rhythmTolerance);

    if (isRhythmCorrect) {
      setSuperOwner(true);
    } else {
      // Reset click count and timestamps
      setClickCount(0);
      setClickTimestamps([]);
    }
  };

  return (
    <div>
      {/* {console.log("rhythmPattern", rhythmPattern)}
      {console.log("rhythmPatternInput", rhythmPatternInput)} */}
      <button onClick={handleClick}>Click me</button>
      <button onClick={handleStartRecording}>{isRecording ? "Stop Recording" : "Start Recording"}</button>
      <input type="text" value={rhythmPatternInput} readOnly />
    </div>
  );
}

export default RythmLogin;
