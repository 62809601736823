/** @format */

// Next: in batchagreementmodal fetch the relevant bookings. They should now be fitted with bookingFeatures. Make sure that's the case.

import { AlignVerticalCenterTwoTone, AltRouteTwoTone } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { storeCurrentBookings } from "../../features/venueFilter/venueFilterSlice";
import { db } from "../../firebase";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import DisplayObject from "../../helpers/displayObject";
import { makeNumber, numberFormatted } from "../../helpers/Numbers";
import shortText from "../../helpers/shortText";
import AgreementModal from "../Documents/AgreementModal";
import BatchAgreementModal from "../Documents/BatchAgreementModal";
import WhatDayIsIt from "../MiscUIComponents/WhatDayIsIt";
import LoadingDots from "./LoadingDots";

const OfferDatagrid = ({ agreementId, thisBooking, bookingStatus }) => {
  // const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [climateCompensation, setClimateCompensation] = useState(false);
  const timeColor = "#00ffd499";
  const [ticketPrice, setTicketPrice] = useState();
  const bookings = useSelector((state) => state.counter.bookings);
  const [rows, setRows] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const relevantBookings = bookings.filter((booking) => booking.agreementId === agreementId && booking.status !== "Killed");
  const [numberSelected, setNumberSelected] = useState(0);
  const [ticketService, setTicketService] = useState("Nortic");
  const [showAgreement, setShowAgreement] = useState(false);
  const [bookingFeatures, setBookingFeatures] = useState();
  const [optionalFeatures, setOptionalFeatures] = useState([]);
  const [optionals, setOptionals] = useState([]);
  const [customerOpted, setCustomerOpted] = useState();
  // const [relevantBookings, setRelevantBookings] = useState([]);

  // const currentVenue = relevantBookings && relevantBookings[0].venue;
  // console.log(currentVenue);

  const dispatch = useDispatch();
  //   const products = relevantBookings[0].venue.products;

  const goSignAgreement = async () => {
    console.log(relevantBookings);
    // await storeRelevantBookings(); //THIS!!!
    await storeBookingFeatures(rows); // adding bookingFeatures
    console.log("Relevant bookings (bookingfeatures?)", relevantBookings);
    setShowAgreement(true);
  };

  const climateCompensate = () => {
    setClimateCompensation(true);
    goSignAgreement();
  };

  function handleSelectedRow(newSelection) {
    setSelectedRows(newSelection);
  }

  useEffect(() => {
    // let tmp = [];
    const data = relevantBookings.map((booking, index) => {
      console.log("THIS relevant bookings", relevantBookings);
      // console.log("currentVenue", currentVenue);
      setReady(true);

      // return features of the specific product of the booking
      const features = (booking.venue.products.find((productObj) => productObj.product === booking.product) || {}).features || []; //THISS!!!

      return {
        id: booking.bookingid, // Unique ID for each row (you can customize this)
        name: shortText(booking.artist.artist, 20),
        day: booking.date,
        date: booking.date,
        product: booking.product ? booking.product : "",
        price: booking.price ? booking.price : "",
        capacity: booking.capacity ? booking.capacity : "",
        getIn: booking.getIn ? booking.getIn : "",
        loadIn: booking.loadIn ? booking.loadIn : "",
        doors: booking.doors ? booking.doors : "",
        show: booking.show ? booking.show : "",
        loadOut: booking.loadOut ? booking.loadOut : "",
        soundcheck: booking.soundcheck ? booking.soundcheck : "",
        curfew: booking.curfew ? booking.curfew : "",
        status: booking.status ? booking.status : "",
        features: { ...features },
      };
    });

    console.log(relevantBookings);
    setRows(data);
    setReady(true);
  }, []);

  const makeOptionalsArray = () => {
    let tmp = [];
    console.log("ROWS", rows);
    //THIS!!

    rows &&
      rows.map(
        (row) =>
          row.features !== undefined ||
          (row.features !== null &&
            Object.values(row.features).map((feature) =>
              Object.values(feature.options).map((option) => {
                selectedRows.includes(row.id) &&
                  option.selected &&
                  option.optional === true &&
                  tmp.push({ ...option, bookingid: row.id, product: row.product });
                console.log(tmp);
              })
            ))
      );
    rows &&
      rows.map((row) =>
        Object.values(row.features).map(
          (feature) =>
            feature.options &&
            Object.values(feature.options).map(
              (option) =>
                selectedRows.includes(row.id) &&
                option.selected &&
                option.optional === true &&
                tmp.push({ ...option, bookingid: row.id, product: row.product })
            )
        )
      );

    let uniqueArray = tmp.reduce((acc, current) => {
      const x = acc.find((item) => item.optionId === current.optionId && item.price === current.price);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    console.log("NOW THIS:", tmp);
    console.log("uniqueArray:", uniqueArray);
    setOptionals(uniqueArray);
  };

  const getProductsFromOptionId = (optionId) => {
    let tmp = [];
    rows.map((row) => {
      // console.log("Row", row);
      Object.values(row.features).map((feature) => {
        if (feature.options.hasOwnProperty(optionId)) tmp.push(row.product);
        // console.log("TmpProducts", tmp, optionId);
      });
    });
    tmp = new Set(tmp);
    // console.log("PRODUCTS PER...", tmp);
    let result = tmp.length && tmp.map((obj) => <span>{obj}, </span>);

    return result;
  };

  const checkFeaturesForSelectedAndOptionals = () => {
    // console.log("selected rows", selectedRows);
    // let allFeatures = [];
    // rows &&
    //   rows.map((row) => {
    //     const tmp = row.features;
    //     console.log("row features", tmp);
    //     let tmpSelectedOptions = [];
    //     Object.values(tmp).map((feature) => {
    //       Object.values(feature.options).map((option) => {
    //         if (option.selected && option.optional)
    //           tmpSelectedOptions.push({ ...option, additionlId: feature.additionalId, product: row.product, bookingid: row.id });
    //       });
    //     });
    //     {
    //       tmpSelectedOptions.length && allFeatures.push({ ...tmpSelectedOptions });
    //     }
    //   });
    // console.log("allFeatures", allFeatures);
    // setOptionals(allFeatures);
  };

  // By default select all rows (unless previously killed)
  useEffect(() => {
    if (ready) {
      const allRowIds = rows.map((row) => row.id);
      setSelectedRows(allRowIds);
      console.log(allRowIds);
      // checkFeaturesForSelectedAndOptionals(); //THIS
      // storeBookingFeatures(rows);
    }
  }, [rows]);

  useEffect(() => {
    setNumberSelected(selectedRows.length);
    makeOptionalsArray();
  }, [selectedRows]);

  const calculateTotalPrice = () => {
    return selectedRows.reduce((total, currentRowId) => {
      const row = rows.find((row) => row.id === currentRowId);
      return total + (row ? makeNumber(row.price) : 0);
    }, 0);
  };

  const calculateTotalCap = () => {
    return selectedRows.reduce((total, currentRowId) => {
      const row = rows.find((row) => row.id === currentRowId);
      return total + (row ? makeNumber(row.capacity) : 0);
    }, 0);
  };

  // const updateCustomerOptedRows = (checked, additionalId, option) => {
  //   let tmp = { ...optionals };
  //   // const newId = option.optionid + index;

  //   if (checked) tmp[option.optionId] = { ...option, customerOpted: true };
  //   if (!checked) {
  //     delete tmp[option.optionid];
  //   }
  //   console.log("updated optional", tmp);

  //   setCustomerOpted(tmp);
  // };

  const updateCustomerOptedRows = (checked, additionalId, option) => {
    let tmpAllRows = [];
    let bookingid = "";
    // mappa genom alla rows och ändra customerOpted i den option som kryssats
    rows.map((row, index) => {
      bookingid = index;
      let tmpRow = JSON.parse(JSON.stringify(row));
      Object.values(row.features).map((feature) => {
        Object.values(feature.options).map((oldOption) => {
          // If option checked
          if (oldOption.optionId === option.optionId && checked) {
            option.customerOpted = true;
            console.log("option", row.features[feature.additionalId].options[option.optionId]);
            tmpRow.features[feature.additionalId].options[option.optionId] = option;
            tmpRow.features[feature.additionalId].options[option.optionId].bookingid = "";
          }
          // If option unchecked
          if (oldOption.optionId === option.optionId && !checked) {
            option.customerOpted = false;
            console.log("option", row.features[feature.additionalId].options[option.optionId]);
            tmpRow.features[feature.additionalId].options[option.optionId] = option;
            tmpRow.features[feature.additionalId].options[option.optionId].bookingid = "";
          }
        });
      });
      tmpAllRows.push(tmpRow);
    });
    setRows(tmpAllRows);
  };

  // function storeBookingFeatures(data) {
  //   data.map(async (row) => {
  //     const feats = row.features;
  //     console.log("rowFeatures", feats, row.id);
  //     alert(row.id);
  //     await updateDoc(doc(db, "Bookings", row.id), {
  //       bookingFeatures: feats,
  //     });

  //     console.log("rowFeaturesB", row.features);
  //   });
  // }

  async function storeBookingFeatures(data) {
    const updatePromises = data.map(async (row) => {
      try {
        const feats = row.features;
        console.log("Updating features for booking ID:", row.id);

        await setDoc(
          doc(db, "Bookings", row.id),
          {
            bookingFeatures: feats,
          },
          { merge: true }
        );

        console.log("Updated features for booking ID:", row.id);
      } catch (error) {
        console.error("Error updating booking ID:", row.id, error);
      }
    });

    try {
      // Wait for all updates to complete
      await Promise.all(updatePromises);
      console.log("All booking features updated successfully.");
    } catch (error) {
      console.error("An error occurred during the updates:", error);
    }
  }

  const VenueSignedAgreement = () => {
    console.log("hej=== = = ======");
    alert("kollar om venue signed");
    return true;
  };

  const deleteEvents = () => {
    selectedRows.map(async (bookingid) => {
      await deleteDoc(doc(db, "Bookings", bookingid));
    });
  };

  useEffect(() => {
    console.log("optionalFeatures", optionalFeatures);
  }, [optionalFeatures]);

  function storeRelevantBookings() {
    // If anything changes in bookingFeatures it's gotta be saved to the relevant bookings.
    let relevantBookingsWithBookingFeatures = [];
    console.log("relevant...", relevantBookings);
    relevantBookings.map((booking, index) => {
      const features = (booking.venue.products.find((productObj) => productObj.product === booking.product) || {}).features || [];
      console.log("Features for relevantBookings", features);
      relevantBookingsWithBookingFeatures.push({ ...booking, bookingFeatures: features });
    });
    let tmp = JSON.parse(JSON.stringify(relevantBookingsWithBookingFeatures));
    //now let's update the options in tmp[index].bookingFeatures that is present in optionalFeatures
    tmp.map((booking, index) => {
      console.log("optionalFeatures", optionalFeatures, booking.bookingid);
      Object.values(optionalFeatures).map((option) => {
        console.log(booking.product, booking.bookingFeatures);
        // if (tmp[index].bookingFeatures[option.additionalId]) {
        if (booking.bookingFeatures[option.additionalId].options[option.optionId].optional) {
          tmp[index].bookingFeatures[option.additionalId].options[option.optionId] = option;
          //..and set the price for this product
          tmp[index].bookingFeatures[option.additionalId].options[option.optionId].price =
            booking.bookingFeatures[option.additionalId].options[option.optionId].price;
        }
        console.log("tmp", tmp);
      });
    });
    //finally map through tmp and store the bookings with new value.
    tmp.map(async (event) => {
      console.log(event);
      await setDoc(doc(db, "Bookings", event.bookingid), { ...event }, { merge: true }); // THIS!!!
    });
    console.log("currentBookings", tmp);
    dispatch(storeCurrentBookings(tmp));
  }

  const products = ["morning", "day", "night"];
  const columns = [
    {
      field: "day",
      headerName: "Day",
      width: 60,
      editable: false,
      renderCell: (params) => (
        <span>
          <WhatDayIsIt date={params.row.day} />
        </span>
      ),
    },

    { field: "date", headerName: "Date", width: 100, editable: false },
    { field: "product", headerName: "Product", width: 100, editable: false, type: "singleSelect", valueOptions: [...products] },
    { field: "price", headerName: "Price", width: 100, editable: false, renderCell: (params) => <>{numberFormatted(params.row.price)}</> },
    {
      field: "show",
      headerName: "Details",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Tooltip
          title={`Get in: ${params.row.getIn}, Load in: ${params.row.loadIn}, Soundcheck: ${params.row.soundcheck}, Doors: ${params.row.doors}, Show: ${params.row.show}, Load out: ${params.row.loadOut}, Curfew: ${params.row.curfew}`}
        >
          {params.row.show}
        </Tooltip>
      ),
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 100,
      editable: false,
      renderCell: (params) => <>{numberFormatted(params.row.capacity)}</>,
    },
  ];
  return (
    <Grid item xs={12}>
      {console.log("THIS", selectedRows[0])}
      {ready && (
        <div className="offerDataGrid" style={{ height: `${120 + rows.length * 50}px`, maxHeight: "800px", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection={true}
            selectionModel={selectedRows}
            onSelectionModelChange={(newSelection) => handleSelectedRow(newSelection)}
            experimentalFeatures={{ newEditingApi: true }}
            // onSelectionModelChange={(newSelection) => {
            //   setSelectedRows(newSelection.selectionModel);
            // }}
            // onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            sortModel={[
              {
                field: "date", // column field name
                sort: "asc", // 'asc' or 'desc'
              },
            ]}
            localeText={{
              footerRowSelected: (count) => {
                setNumberSelected(selectedRows.length);
                return `${count} event${count > 1 ? "s" : ""} selected`;
              },
            }}
            // onSelectionModelChange={(newSelection) => setSelectedRows(newSelection.selectionModel)}
            // getRowClassName={(params) => {
            //   return "unselectedRow";
            // }}
          />
        </div>
      )}
      <Grid container xs={12} style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
        {/* NOTHING SELECTED */}
        {numberSelected > 0 ? "" : <span style={{ marginRight: "20px" }}>Select bookings</span>}

        {/* ACCEPT BUTTON */}
        {/* <div style={{ height: "500px", width: "600px", background: "#000" }}> */}

        {/* <Grid item xs={12} sm={12} className="offerOptionals">
          {bookingFeatures !== undefined && <h6>Optional features</h6>}
          {bookingFeatures &&
            Object.values(bookingFeatures).map((feature) => (
              <div>
                {feature.options &&
                  Object.values(feature.options).map((option) => (
                    <div>
                      {option.selected && (
                        <div>
                          {option.selected && option.optional && (
                            <div>
                              <span>
                                <Checkbox onClick={(e) => updateCustomerOptedRows(e.target.checked, feature.additionalId, option)} />
                              </span>
                              <span>
                                <span style={{ fontWeight: "900" }}>{option.option}</span>, {feature.additional}
                              </span>
                              {option.price !== "" && (
                                <span>
                                  {" "}
                                  (Additional cost: {option.price} {currentVenue.currency}){" "}
                                </span>
                              )}
                              <span style={{ opacity: "0.5" }}>Applicable on: {getProductsFromOptionId(option.optionId)}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </Grid> */}

        <Grid item xs={12} sm={12} className="offerOptionals">
          {optionals.length && <h6>OptionalFeatures</h6>}
          <>
            {optionals.map((option) => (
              <div>
                Opted:{option.customerOpted}
                <Checkbox
                  value={option.customerOpted}
                  onClick={(e) => updateCustomerOptedRows(e.target.checked, option.additionalId, option)}
                />
                <b style={{}}>{option.option}</b>
                <span style={{}}>{option.info && `, ${option.info}.`}</span>
                <span style={{ opacity: "0.4" }}>{option.price && ` Additional ${numberFormatted(option.price)} kr`}</span>
                <span style={{ opacity: "0.4" }}>{option.product && `, per ${getProductsFromOptionId(option.optionId)}`}</span>
                {/* {console.log("option", option)} */}
                {/* {option.info && `, ${option.info}`} */}
              </div>
            ))}
          </>
        </Grid>

        {/* <FormControl>
          <InputLabel id="tickets-label">Ticket Service</InputLabel>
          <Select
            value={ticketService}
            labelId="tickets-label"
            size="small"
            style={{ width: "130px" }}
            onChange={(e) => setTicketService(e.target.value)}
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="Nortic" style={{ background: "blue" }}>
              Nortic
            </MenuItem>
            <MenuItem value="Tixly" style={{ background: "rgb(250, 69, 23)" }}>
              Tixly
            </MenuItem>
          </Select>
        </FormControl> */}
        {/* <Button variant="outlined" onClick={() => goSignAgreement()} size="medium" disabled={numberSelected === 0}>
          Accept
        </Button> */}
        <div>
          {/* {rows.map((row) => (
            <div>{row.features && console.log(row.features.additional)}</div>
          ))} */}
          {console.log(rows)}
        </div>
        <div style={{ color: "#00ffd499" }}>Total cost: {numberFormatted(calculateTotalPrice())} plus vat(25%)</div>
        <div style={{ color: "#00ffd499" }}>CO2e: {numberFormatted(Math.round(calculateTotalPrice() * 0.035))} kg</div>

        {bookingStatus === "Awaiting Artist" && selectedRows.length > 0 && (
          <ConfirmDialog
            onConfirm={() => climateCompensate()}
            onCancel={() => goSignAgreement()}
            header="🌿 Climate Compensate"
            message="Wanna climate compensate this booking?"
            optionB="Yes, please!"
            optionA="No"
            variant="outlined"
          >
            Continue with {selectedRows.length} date{selectedRows.length > 1 ? "s" : ""}
          </ConfirmDialog>
        )}
        {bookingStatus === "Pending Request" && (
          <div style={{ float: "right", color: "orange" }}>
            Waiting for venue to respond
            <LoadingDots speed="1500" />
          </div>
        )}
        {/* <Button onClick={() => storeBookingFeatures(rows)}>Store</Button> */}
        <ConfirmDialog
          onConfirm={() => deleteEvents()}
          onCancel={() => console.log("canceled")}
          header="Delete selected?"
          message={`Delete ${selectedRows.length} date${selectedRows.length > 1 ? "s" : ""}`}
          optionB="Go Ahead"
          optionA="No"
          variant="outlined"
        >
          Delete {selectedRows.length} date{selectedRows.length > 1 ? "s" : ""}
        </ConfirmDialog>
      </Grid>
      {showAgreement && (
        <div>
          {console.log("booking Features", bookingFeatures)}
          <BatchAgreementModal
            agreementId={agreementId}
            bookingids={selectedRows}
            sign="agentSignature"
            open={true}
            setShowAgreement={setShowAgreement}
            climateCompensation={climateCompensation}
            bookingFeatures={bookingFeatures}
            // relevantBookings={relevantBookings}
          />
          {console.log("SELECTED ROWS", selectedRows)}
        </div>
      )}
    </Grid>
  );
};

export default OfferDatagrid;
