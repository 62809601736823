/** @format */

export function generateColor(value) {
  // Ensure the value is within the range 0-100
  const normalizedValue = Math.min(Math.max(value, 0), 100);

  // Calculate the hue based on the normalized value
  const hue = (normalizedValue * 120) / 100; // 120 corresponds to the range from red to green in HSL

  // Create the HSL color string
  const color = `hsl(${hue}, 100%, 50%)`; // 100% saturation, 50% lightness

  return color;
}

// Test the function
const value = 50; // Replace with any value from 0 to 100
const color = generateColor(value);
// console.log(color);
