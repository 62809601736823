/** @format */

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { doc, updateDoc } from "firebase/firestore";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { createSmallId, db } from "../../firebase";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import { storeCurrentVenue } from "../../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { create } from "@mui/material/styles/createTransitions";

const Logoupload = ({ bookingid }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadURL, setDownloadURL] = useState(currentVenue.venueLogo);
  const venueid = currentVenue && currentVenue.venueid;
  const imageId = createSmallId();
  const filename = `logo-${imageId}`;
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      const storage = getStorage();
      //   const storageRef = ref(storage, "riders/" + selectedFile.name);
      const customFileName = `${Date.now()}-${selectedFile.name}`; // Adding timestamp to avoid duplicate names
      const storageRef = ref(storage, `venueImages/${customFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(progress);
      });

      await new Promise((resolve, reject) => {
        uploadTask.on("state_changed", null, reject, resolve);
      });

      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      setDownloadURL(downloadURL);
      console.log("File uploaded successfully", downloadURL);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    async function updateVenueWithLogo() {
      if (downloadURL === undefined || !downloadURL) return;
      await updateDoc(doc(db, "Venues", venueid), {
        venueLogo: downloadURL,
      });
      dispatch(storeCurrentVenue({ ...currentVenue, venueLogo: downloadURL }));
    }
    downloadURL && updateVenueWithLogo();
  }, [downloadURL]);

  return (
    <div>
      {currentVenue.venueLogo && (
        <div
          style={{
            width: "390px",
            height: "auto",
            background: "#fff",
            padding: "20px",
            borderRadius: "10px",
            overflow: "hidden",
            display: "grid",
            marginBottom: "20px",
          }}
        >
          <img src={currentVenue.venueLogo} alt="venue logo" width="250px" height="auto" />
        </div>
      )}
      {!downloadURL && (
        <>
          {/* <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} className="uploadInput" /> */}
          <input type="file" accept=".png,.jpeg,.jpg" onChange={handleFileChange} className="uploadInput" />
          <Button variant="outlined" size="small" onClick={handleUpload}>
            Upload
          </Button>
        </>
      )}
      {uploadProgress > 0 && !downloadURL && (
        <div style={{ marginTop: "10px" }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </div>
      )}
      {downloadURL && <Button onClick={() => setDownloadURL(null)}>Change</Button>}
      {/* {downloadURL && (
        <p>
          <a href={downloadURL} target="_blank" rel="noopener noreferrer">
            <DownloadIcon />
          </a>
          <ConfirmDialog onConfirm={() => setDownloadURL(null)}>Change</ConfirmDialog>
        </p>
      )} */}
    </div>
  );
};

export default Logoupload;
