/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { storeBookings } from "../../features/venueFilter/venueFilterSlice";
import { db } from "../../firebase";
import DisplayObject from "../../helpers/displayObject";
import shortText from "../../helpers/shortText";
import BatchEditBookings from "./BatchEditBookings";
import SelectBookings from "./SelectBookings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";
import OfferDatagrid from "./OfferDatagrid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BatchAgreementModal from "../Documents/BatchAgreementModal";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import GradingIcon from "@mui/icons-material/Grading";

const IncomingOffers = () => {
  //   const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const bookings = useSelector((state) => state.counter.bookings);
  const [loading, setLoading] = useState(false);
  const [agreements, setAgreements] = useState([]);
  //   const [bookings, setBookings] = useState([]);
  const [currentAgreementId, setCurrentAgreementId] = useState([]);
  const [bookingsForAgreementId, setBookingsForAgreementId] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0];
  const [rows, setRows] = React.useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const rowsInitialized = bookings.map((booking, index) => ({
    // id: index + 1, // Unique ID for each row (you can customize this)
    id: booking.bookingid, // Unique ID for each row (you can customize this)
    name: shortText(booking.artist.artist, 20),
    day: booking.date,
    date: booking.date,
    product: booking.product ? booking.product : "",
    price: booking.price ? booking.price : "",
    capacity: booking.capacity ? booking.capacity : "",
    getIn: booking.getIn ? booking.getIn : "",
    loadIn: booking.loadIn ? booking.loadIn : "",
    doors: booking.doors ? booking.doors : "",
    show: booking.show ? booking.show : "",
    loadOut: booking.loadOut ? booking.loadOut : "",
    soundcheck: booking.soundcheck ? booking.soundcheck : "",
    curfew: booking.curfew ? booking.curfew : "",
  }));

  const statusColor = (state) =>
    ({
      Confirmed: "green",
      "Awaiting Artist": "red",
      "Pending Request": "orange",
    }[state] || "grey");

  const handleChange = (panel) => (event, newExpanded) => {
    setIsExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const uniqueAgreementIds = bookings && Array.from(new Set(bookings.map((booking) => booking.agreementId)));

    const result = uniqueAgreementIds.map((uniqueId) => {
      const matchingBookings = bookings.filter((booking) => booking.agreementId === uniqueId);
      const numberOf = matchingBookings.filter((booking) => booking.status !== "Killed");
      return {
        agreementId: uniqueId !== undefined ? uniqueId : "none",
        artist: matchingBookings[0].artist, // Assuming all artists for the same agreementId are the same
        venue: matchingBookings[0].venue,
        count: numberOf.length,
        status: matchingBookings[0].status,
      };
    });
    setAgreements(result);
  }, [bookings]);

  useEffect(() => {}, [agreements]);

  // When a request is clicked
  function handleClickedAgreement(agreementId) {
    const filteredBookings = bookings.filter((booking) => booking.agreementId === agreementId);
    // console.log("Filtered Bookings", filteredBookings);
    setBookingsForAgreementId(filteredBookings);
    setCurrentAgreementId(agreementId);
  }

  return (
    <>
      <Grid item xs={12} sm={8} md={6}>
        {loading ? (
          <CircularProgress style={{ zoom: "0.4" }} />
        ) : (
          <div style={{ display: "flex", gap: "20px", textAlign: "center", fontSize: "14px", flexDirection: "column" }}>
            {agreements.map((item, index) => (
              <>
                {item.count > 0 && item.status !== "Killed" && (
                  <Accordion>
                    {/* {console.log("here", item, index)} */}
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <div onClick={() => handleClickedAgreement(item.agreementId)}>
                        {/* {console.log("Agreements", agreements)} */}
                        <div
                          className="inComingRequests"
                          style={{
                            width: item.agreementId === currentAgreementId ? "80px" : "60px",
                            height: item.agreementId === currentAgreementId ? "80px" : "60px",
                            borderRadius: "60px",
                            overflow: "hidden",
                            backgroundImage: `url(${item.venue.venueImage})`,
                            backgroundSize: "cover",
                            backgroundColor: item.agreementId === currentAgreementId ? "#ffffff44" : "#00000044",
                            backgroundBlendMode: "multiply",
                            opacity: item.agreementId === currentAgreementId ? "1" : "0.8",
                            animation: `spinIn ${index / 5}s`,
                          }}
                        >
                          {item.agreementId === currentAgreementId ? (
                            ""
                          ) : (
                            <div style={{ fontSize: "40px", fontWeight: "900", opacity: "1" }}>{item.count}</div>
                          )}
                        </div>

                        {/* <span>{shortText(item.venue.venue, 20)}</span> */}
                      </div>
                      <table className="offerAccordionTable">
                        <td className="venue">{item.venue.venue}</td>
                        {/* <td className="agreementId">{item.agreementId}</td> */}
                        {/* <td className="agreementId" style={{ color: statusColor(item.status) }}>
                          {item.status}
                        </td> */}
                        {item.status === "Confirmed" && (
                          <td className="readyToSign">
                            <GradingIcon />
                            {/* <BatchAgreementModal agreementId={item.agreementId} /> */}
                          </td>
                        )}
                        {item.status === "Awaiting Artist" && (
                          <td className="readyToSign">
                            <BorderColorIcon /> Sign agreement
                          </td>
                        )}
                        <tr>
                          <td className="artist">{item.artist.artist}</td>
                        </tr>
                      </table>
                    </AccordionSummary>
                    <AccordionDetails>
                      <OfferDatagrid agreementId={item.agreementId} bookingStatus={item.status} />
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            ))}
          </div>
        )}
      </Grid>
    </>
  );
};

export default IncomingOffers;
