/** @format */

import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { storeStartDate, storeEndDate, storeChosenDateRange } from "../../features/venueFilter/venueFilterSlice";
import { eachDayOfInterval } from "date-fns";
import GetBookings from "../../helpers/GetBookings";
import { useEffect } from "react";
import { monthsFrom, monthsFromNow } from "../../helpers/DateManagement";
import { parseDate } from "../../utils/valueParser";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Icon } from "@mui/material";

export default function CalendarsDateRangePicker() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState([null, null]);
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const startDate = useSelector((state) => state.counter.startDate);
  const endDate = useSelector((state) => state.counter.endDate);
  const dispatch = useDispatch();
  function setDefaultDateRange() {
    const tempChosenDateRange = eachDayOfInterval({
      start: new Date(parseDate(startDate)),
      end: new Date(parseDate(endDate)),
    });
    const chosenDateRange = [];
    tempChosenDateRange.map((date) => chosenDateRange.push(new Date(date).toLocaleDateString("sv-SE")));
    dispatch(storeChosenDateRange(chosenDateRange));
  }

  // INITIALIZE LOADING THE VENUES BY SETTING A DEFAULT DATE RANGE
  useEffect(() => {
    setTimeout(() => {
      // const newValue = [new Date("2023-01-01"), new Date("2023-02-02")];
      const newValue = [new Date(monthsFromNow(1)), new Date(monthsFromNow(2))];
      setValue(newValue);
      console.log("newValue", newValue);
      const startDate = newValue[0] > 0 ? newValue[0].toLocaleDateString("sv-SE") : monthsFrom(new Date().toLocaleDateString("sv-SE"), 1);
      const endDate = newValue[1] > 0 ? newValue[1].toLocaleDateString("sv-SE") : monthsFrom(newValue[0].toLocaleDateString("sv-SE"), 2);
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      const tempChosenDateRange = eachDayOfInterval({
        start: new Date(startDate),
        end: new Date(endDate),
      });

      const chosenDateRange = [];
      tempChosenDateRange.map((date) => chosenDateRange.push(new Date(date).toLocaleDateString("sv-SE")));

      dispatch(storeStartDate(startDate));
      dispatch(storeEndDate(endDate));
      dispatch(storeChosenDateRange(chosenDateRange));
    }, 1500);
  }, []);
  //   useEffect(() => {
  //     const tempChosenDateRange = eachDayOfInterval({
  //       start: new Date(startDate),
  //       end: new Date(endDate),
  //     });
  //     dispatch(storeChosenDateRange(chosenDateRange));
  //   });

  // useEffect(() => {
  //   // setDefaultDateRange();
  // }, []);

  function setDefaultDateRange() {
    const tempChosenDateRange = eachDayOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    });
    const chosenDateRange = [];
    tempChosenDateRange.map((date) => chosenDateRange.push(new Date(date).toLocaleDateString("sv-SE")));
    dispatch(storeChosenDateRange(chosenDateRange));
  }

  const chosenDateRange = [];
  // tempChosenDateRange.map((date) =>
  // 	chosenDateRange.push(new Date(date).toLocaleDateString("sv-SE")),
  // );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <DateRangePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          calendars={Math.round(vw / 350)} // antal månader att visa på sidan
          value={value}
          // defaultValue={(startDate, endDate)}
          onChange={(newValue) => {
            setValue(newValue);
            const startDate =
              newValue[0] > 0 ? newValue[0].toLocaleDateString("sv-SE") : monthsFrom(new Date().toLocaleDateString("sv-SE"), 1);
            const endDate =
              newValue[1] > 0 ? newValue[1].toLocaleDateString("sv-SE") : monthsFrom(newValue[0].toLocaleDateString("sv-SE"), 2);
            const tempChosenDateRange = eachDayOfInterval({
              start: new Date(startDate),
              end: new Date(endDate),
            });

            const chosenDateRange = [];
            tempChosenDateRange.map((date) => chosenDateRange.push(new Date(date).toLocaleDateString("sv-SE")));

            dispatch(storeStartDate(startDate));
            dispatch(storeEndDate(endDate));
            dispatch(storeChosenDateRange(chosenDateRange));
          }}
          renderInput={(startProps, endProps) => (
            <div
              className="calendarTrigger"
              onClick={() => {
                setOpen(true);
              }}
            >
              <div style={{ fontSize: "12px", textAlign: "center", marginTop: "-0px" }}>
                <b>{startDate}</b> <CalendarMonthIcon color="primary" style={{ marginTop: "-5px", opacity: "0.7" }} /> <b>{endDate}</b>
              </div>
            </div>
            // <React.Fragment>
            //   {console.log(startProps, endProps)}
            //   <div onClick={() => {}}>
            //     <CalendarMonthIcon
            //       onClick={() => {
            //         setOpen(true);
            //       }}
            //     />
            //   </div>
            //   <TextField defaultValue={startDate} {...startProps} size={"small"} className="dateRange" />
            //   <Box sx={{ py: 3, mx: 1 }}> to </Box>
            //   <TextField defaultValue={endDate} {...endProps} size={"small"} className="dateRange" />
            // </React.Fragment>
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
