/** @format */

import React, { useEffect, useState } from "react";
import { Modal, TextField, Button, Grid, Backdrop, CircularProgress, formLabelClasses } from "@mui/material";
import { db, login, signup } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import PhoneInput from "../components/UI helpers/PhoneInput";
import CheckIfEmailExists from "../utils/CheckIfEmailExists";
import { useSelector } from "react-redux";

const SimpleSignup = ({ openSignup, setOpenSignup, mailAddress, role }) => {
  const userRole = useSelector((state) => state.counter.userRole);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(mailAddress);
  const [emailOk, setEmailOk] = useState("false");
  const [telephone, setTelephone] = useState("");
  const [orgNumber, setOrgNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usr, setUsr] = useState(getAuth().currentUser);
  const [ready, setReady] = useState(false);

  const handleOpen = () => {
    setOpenSignup(true);
  };

  const handleClose = () => {
    setOpenSignup(false);
  };

  setTimeout(() => {
    setReady(true);
  }, 1000);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError(true);
    } else {
      // Submit the form
      console.log({ email, telephone, password, confirmPassword });
      try {
        setLoading(true);
        // Signup new user...
        await signup(email, password, telephone);
        var user = getAuth().currentUser;
        // ...and create corresponding document in User collection with same ID
        await setDoc(doc(db, "Users", user.uid), {
          email: email,
          //   password: password,
          role: role,
          phone: telephone,
          firstName: firstName,
          lastName: lastName,
          userid: user.uid,
          documentid: user.uid,
          socialId: "U2FsdGVkX19XdcEuSwjX+vK2UApA6gcteIUSR9Rf1lA=",
        });
        window.location.reload();
      } catch {
        console.log("Failed to sign up new user");
      }
      setLoading(false);
      setError(false);
      handleClose();
    }
  };

  async function handleLogin() {
    console.log("logging in...");
    try {
      setLoading(true);
      await login(email, password);
      window.location.reload();

      // await console.log("USRID", currentUserId);
    } catch {
      alert("Failed to sign in");
    }
    setLoading(false);
    handleClose();
  }

  //   const style = {
  //     position: "absolute",
  //     top: "50%",
  //     left: "50%",
  //     transform: "translate(-50%, -50%)",
  //     maxWidth: 500,
  //     width: "100%",
  //     background: "linear-gradient(0deg, #222, #44444422)",
  //     // border: "2px solid #000",
  //     boxShadow: 24,
  //     borderRadius: "10px",
  //     p: 4,
  //   };

  // useEffect(() => {
  //   console.log("testing email", email);
  // }, [email]);

  async function testEmail() {
    if ((await CheckIfEmailExists(email)) === "Exists") {
      // console.log("Exists");
      setEmailOk("Nope");
    } else {
      // console.log("Don't exist");
      setEmailOk(true);
    }
  }

  // useEffect(() => {
  //   console.log("USR", usr);
  // }, [getAuth()]);

  return (
    <>
      <br></br>
      <br></br>
      <Modal
        open={ready && openSignup && userRole === "none"}
        // open={true}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{ style: { backdropFilter: "blur(0px)", position: "absolute", top: "0", left: "0" } }}
        style={{ display: "grid", justifyContent: "center", alignContent: "center" }}
      >
        <Grid
          className="standardModal"
          container
          // style={{ padding: "20px", background: "linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5))", borderRadius: "10px" }}
          spacing={4}
          xs={12}
        >
          {emailOk !== "Nope" ? <h2>Enter details in order to sign up</h2> : <h2>Log in</h2>}
          <form onSubmit={handleSubmit} style={{ display: "grid", gap: "10px", width: "100%", marginTop: "20px", fontFamily: "Avenir" }}>
            {emailOk !== "Nope" && (
              <TextField
                required
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                // size="small"
              />
            )}
            {emailOk !== "Nope" && (
              <TextField required label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} fullWidth size="small" />
            )}
            <TextField
              required
              label="Email"
              // error={emailOk === "Nope"}
              helperText={emailOk === "Nope" ? "Enter password to log in or change email address to sign up new user." : ""}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={testEmail()}
              fullWidth
              // size="small"
            />
            {/* <TextField required label="Telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} fullWidth size="small" /> */}
            {emailOk !== "Nope" && <PhoneInput setPhoneNumber={setTelephone} />}
            <TextField
              required
              label="Password"
              type="password"
              value={password}
              error={password.length < 6 ? true : false}
              helperText={password.length < 6 ? "Create your Password. It needs to be at least 6 characters" : ""}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              // size="small"
              sx={{ marginTop: "20px" }}
            />
            {emailOk !== "Nope" && (
              <TextField
                required
                // error={error}
                error={confirmPassword !== password}
                helperText={confirmPassword !== password ? "Passwords do not match" : ""}
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                // size="small"
              />
            )}
            {emailOk === "Nope" && (
              <Button variant="contained" color="primary" type="submit" sx={{ marginTop: "20px" }} onClick={() => handleLogin()}>
                {loading && <CircularProgress style={{ zoom: "0.4", marginRight: "10px" }} />}
                Login
              </Button>
            )}
            {emailOk !== "Nope" && (
              <Button variant="contained" color="primary" type="submit" sx={{ marginTop: "20px" }}>
                Submit
              </Button>
            )}
          </form>
        </Grid>
      </Modal>
    </>
  );
};

export default SimpleSignup;
