/** @format */

import { Create } from "@mui/icons-material";
import React from "react";
import CreateTicketCategoriesNortic from "./CreateTicketCategoriesNortic";
import { useSelector } from "react-redux";

const TicketsBookings = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);

  return (
    <div>
      Ticket Categories for booking:
      <CreateTicketCategoriesNortic />
    </div>
  );
};

export default TicketsBookings;
