/** @format */

import Products from "../components/Forms/Products";

/** @format */

export const agentObj = [
  { section: "Document ID", type: "Section" },
  {
    section: "Document",
    placeholder: "Document ID",
    label: "Document ID",
    id: "documentid",
    type: "text",
    disabled: true,
  },
  {
    section: "User Role",
    label: "Role",
    id: "userRole",
    type: "text",
    value: "agent",
  },
  {
    section: "Account",
    type: "Section",
  },
  {
    section: "Account",
    label: "Name",
    id: "agent",
    type: "text",
  },
  {
    section: "Account",
    label: "City",
    id: "agentCity",
    type: "text",
  },
  {
    section: "Account",
    label: "Country",
    id: "agentCountry",
    type: "text",
  },
  {
    section: "Account",
    label: "Email",
    label: "Email",
    id: "agent",
    type: "text",
  },
  {
    section: "Account",
    label: "Phone",
    id: "agentPhone",
    type: "number",
  },
  {
    section: "Account",
    label: "StreetAddress",
    id: "agentStreetAddress",
    type: "text",
  },
  {
    section: "Account",
    label: "agentid",
    id: "agentid",
    type: "text",
  },
  {
    section: "Account",
    label: "ZipCode",
    id: "agentZipCode",
    type: "number",
  },
  {
    section: "Company",
    type: "Section",
  },
  {
    section: "Company",
    label: "Company",
    id: "agentCompany",
    type: "text",
  },
  {
    section: "Company",
    label: "Vat number",
    id: "agentVAT",
    type: "text",
  },
  {
    section: "Representing",
    type: "Section",
  },
  {
    section: "Location",
    type: "Section",
  },
  {
    section: "Location",
    name: "streetAddress",
    placeholder: "Street Address",
    label: "Street Address",
    id: "venueStreetAddress",
    type: "text",
  },
  {
    section: "Location",
    name: "zipCode",
    placeholder: "Zip Code",
    label: "Zip Code",
    id: "venueZipCode",
    type: "text",
  },
  {
    section: "Location",
    name: "city",
    placeholder: "City",
    label: "City",
    id: "venueCity",

    type: "text",
    defaultValue: "Stockholm",
  },
  // Gereric Location Data Fields
  {
    section: "Location",
    name: "country",
    placeholder: "Country",
    label: "Country",
    id: "venueCountry",

    type: "text",
    defaultValue: "Sweden",
  },
  {
    section: "Location",
    name: "county",
    placeholder: "County",
    label: "County",
    id: "venueCounty",

    type: "text",
    defaultValue: "Sweden",
  },
  {
    section: "Location",
    name: "countyB",
    placeholder: "CountyB",
    label: "CountyB",
    id: "venueCountyB",

    type: "text",
    defaultValue: "Sweden",
  },
  {
    section: "Location",
    name: "lat",
    placeholder: "Latitude",
    label: "Latitude",
    id: "venueLat",

    type: "number",
    defaultValue: 59,
  },
  {
    section: "Location",
    name: "lng",
    placeholder: "Longitude",
    label: "Longitude",
    id: "venueLng",

    type: "number",
    defaultValue: 18,
  },
  {
    section: "Location",
    type: "addLocationComponent",
    fieldPrepend: "agent",
  },

  {
    // Array of artistids agent represents
    section: "Representing",
    label: "Representing",
    id: "representing",
    type: "list",
  },
  {
    section: "History",
    type: "Section",
  },
  {
    // Array of venueids previously booked
    section: "History",
    label: "Venue Log",
    id: "agentVenueLog",
    type: "list",
  },
  {
    section: "Discounts",
    type: "Section",
  },
  {
    //Array of venueids: and discount:
    section: "Discounts",
    label: "Discounts",
    id: "agentDiscounts",
    type: "list",
  },
  {
    section: "Special Access",
    type: "Section",
  },
  {
    //Array of venueids witgh granted auto confirmation
    section: "Special Access",
    label: "Auto Confirmed Bookings at",
    id: "autoConfirmAccess",
    type: "list",
  },
  {
    section: "Artists",
    type: "Section",
  },
  {
    section: "Artists",
    type: "GeneralFormsComponent",
  },
];
