/** @format */

import { Button, Grid } from "@mui/material";
import React from "react";

const Transactions = () => {
  function checkTransactions() {
    fetch(`https://europe-west3-venuehub-64e72.cloudfunctions.net/checkTransactions`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data from Finshark: ", data);
      })
      .catch((error) => console.error(error));
  }

  return (
    <div>
      <Grid p={8} mt={10}>
        <Button onClick={() => checkTransactions()} variant="outlined" size="small">
          Transactions
        </Button>
      </Grid>
    </div>
  );
};

export default Transactions;
