/** @format */

import { Button, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { primaryColorDark, secondaryColor } from "../assets/theme/Theme";
import { bookingsObj } from "../dbObjects.js/dbBookings";
import { sampleArtist } from "../dbObjects.js/dbSampleArtist";
import { storeWantedList } from "../features/venueFilter/venueFilterSlice";
import { SendRequest } from "./SendRequest";

const SendMultiRequest = () => {
  const [loading, setLoading] = useState();
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const currentAgent = useSelector((state) => state.counter.currentAgent);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);
  const wantedList = useSelector((state) => state.counter.wantedList);
  const dispatch = useDispatch();
  // const currentRequestDate = useSelector((state) => state.counter.currentRequestDate);
  // const venueid = props.venueid;
  // const date = props.date;

  async function handleClick() {
    setLoading(true);
    console.log(currentArtist);
    console.log(currentAgent);
    // console.log("availableVenuesAndDates", availableVenuesAndDates);
    // alert(wantedList.length);

    // availableVenuesAndDates.map((availData) => {
    await wantedList.map((availData) => {
      SendRequest(currentArtist, currentAgent, availData, availData.proposedDate);
    });
    //remove sent requests from CreateTimeline
    setLoading(false);
    dispatch(storeWantedList([]));
  }

  return (
    <Grid container mt={2} justifyContent="center" alignItems="center">
      {/* {currentArtist && <div>{currentArtist}</div>}
      {currentAgent && <div>{currentAgent}</div>} */}
      {loading && (
        <span style={{ zoom: "0.4", padding: "10px" }}>
          <CircularProgress />
        </span>
      )}
      <Grid item xs={12}>
        <Button
          className="w-100"
          variant="contained"
          // color='secondary'
          onClick={handleClick}
          sx={{ backgroundColor: primaryColorDark }}
        >
          Request dates
        </Button>
      </Grid>
      {/* <Grid item xs={12} mt={2}>
        <Button variant="outlined" color="primary" className="w-100">
          Save for later
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default SendMultiRequest;
