/** @format */

import React, { useState } from "react";
import axios from "axios";

function SyncNortic({ ticketOrganizerId, ticketEventId }) {
  const [responseData, setResponseData] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = "https://europe-west3-venuehub-64e72.cloudfunctions.net/getNorticTicketsAndStore";
    const data = {
      organizerId: "3236",
      eventId: "52269",
      organizerKey: "452be945-03e5-4356-82f5-e93f0ebcebbe",
      //   organizerId: "2915",
      //   eventId: "51819",
      //   organizerKey: "68a50a13-b26b-4fe7-8506-5c1a285afe55",
    };

    try {
      const response = await axios.post(url, data);
      setResponseData(JSON.stringify(response.data));
      console.log(response);
    } catch (err) {
      setError("Error occurred: " + err.message);
    }
  };

  return (
    <div>
      {/* <b>orgId:</b> {ticketOrganizerId} <b>eventId:</b> {ticketEventId} */}
      Click to load Nortic tickets and store them in Firebase:
      <form onSubmit={handleSubmit}>
        <button type="submit">Send POST Request</button>
      </form>
      {responseData && <p>Response: {responseData}</p>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default SyncNortic;
