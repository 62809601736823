/** @format */

import React, { useEffect, useState } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Circle, GoogleMap, LoadScript, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

const TicketsMap = () => {
  //   const [coordinates, setCoordinates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [coordinates, setCoordinates] = useState([null]);
  const [markersLoaded, setMarkersLoaded] = useState(false);
  const currentTickets = useSelector((state) => state.counter.currentTickets);

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const googleCX = process.env.REACT_APP_GOOGLE_CX;

  const coords = [];

  // The center of the map (arbitrary, can be any coordinates)
  const CENTER = {
    lat: 59.3293, // latitude of Stockholm
    lng: 18.0686, // longitude of Stockholm
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleApiKey,
  });

  async function handleConvert() {
    currentTickets.map(async (obj) => {
      const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: `${obj.city} ${obj.zipCode}`,
          key: googleApiKey,
        },
      });
      if (response.data.results[0]) {
        console.log(response.data.results[0].geometry.location.lat, response.data.results[0].geometry.location.lng);
        await coords.push({
          ticketId: obj.ticketId,
          zipCode: obj.zipCode,
          city: obj.city,
          lat: response.data.results[0].geometry.location.lat,
          lng: response.data.results[0].geometry.location.lng,
        });
      }
    });
  }

  useEffect(() => {
    async function getData() {
      await handleConvert();
      console.log(coords);
      await setCoordinates(coords);
      setMarkersLoaded(true);
    }
    getData();
  }, []);

  useEffect(() => {
    console.log(coordinates);
  }, [coordinates]);

  if (markersLoaded)
    return (
      <Grid container p={4} xs={12} gap={4} style={{ index: "10000000000" }}>
        {isLoaded && coordinates && markersLoaded && (
          <Grid xs={12}>
            {console.log("💪💪💪", "Coordinates:", coordinates)}
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "300px", opacity: "0.2" }}
              center={CENTER}
              zoom={10}
              options={{ styles: darkMapStyles, disableDefaultUI: true, backgroundColor: "transparent" }}
            >
              <OverlayView>
                <h6>Ticket sales</h6>
              </OverlayView>
              {coordinates.map((data, index) => (
                <OverlayView
                  style={{ index: "20000000000" }}
                  position={{
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    //   className="travelDataPoint"
                    style={{ width: "20px", height: "20px", background: "#fa5a2699", borderRadius: "20px" }}
                  ></div>
                </OverlayView>
              ))}
              {/* <Circle
              center={CENTER}
              radius={10000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              center={CENTER}
              radius={20000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              center={CENTER}
              radius={70000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              center={CENTER}
              radius={500000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
                strokeDashArray: "5 2",
              }}
            /> */}
              {/* {travelData.map((data, index) => (
            <Marker key={index} position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }} />
          ))} */}
            </GoogleMap>
          </Grid>
        )}
      </Grid>
    );
};

export default TicketsMap;

const darkMapStyles = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [
      {
        // visibility: "simplified",
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
        // visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffeb3b",
      },
      {
        visibility: "on",
      },
      {
        weight: 8,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#46bcec",
      },
      {
        visibility: "off",
      },
    ],
  },
];

// const darkMapStyles = [
//   {
//     elementType: "geometry",
//     stylers: [
//       {
//         // color: "#242f3e",
//         color: "transparent",
//       },
//     ],
//   },
//   {
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#746855",
//       },
//     ],
//   },
//   {
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         color: "#242f3e",
//       },
//     ],
//   },
//   {
//     featureType: "administrative",
//     elementType: "geometry",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.locality",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#d59563",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.neighborhood",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.text",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#d59563",
//       },
//     ],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#263c3f",
//       },
//     ],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#6b9a76",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#38414e",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "geometry.stroke",
//     stylers: [
//       {
//         color: "#212a37",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "labels",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "labels.icon",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#9ca5b3",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#746855",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry.stroke",
//     stylers: [
//       {
//         color: "#1f2835",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#f3d19c",
//       },
//     ],
//   },
//   {
//     featureType: "transit",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "transit",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#2f3948",
//       },
//     ],
//   },
//   {
//     featureType: "transit.station",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#d59563",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "geometry",
//     stylers: [
//       {
//         // color: "#17263c",
//         color: "transparent",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "geometry.stroke",
//     stylers: [
//       {
//         color: "#ffeb3b",
//       },
//       {
//         visibility: "on",
//       },
//       {
//         weight: 8,
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "labels.text",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#515c6d",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         color: "#17263c",
//       },
//     ],
//   },
// ];
