import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useSelector, useDispatch } from 'react-redux';
import { storeVenues } from '../features/venueFilter/venueFilterSlice';
import { CircularProgress } from "@mui/material";


const GetVenues = () => {
    const [ venues, setVenues ] = useState([]);
    const venueCollectionRef = collection(db, "Venues");
    const count = useSelector((state) => state.counter.count);
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
    const getData = async () => {
        const data = await getDocs(venueCollectionRef);
     setVenues(data.docs.map((doc) => (doc.data())));
     setLoading(false);
        };
        getData();
    }, [])

    setTimeout(() => {
        dispatch(storeVenues(venues))
                
    }, 100);


    return(
        <div>
            {/* {loading ? <div class="d-flex justify-content-center mt-5"> <CircularProgress /> </div> : null } */}
        </div>
    );
}
 
export default GetVenues;




// import { collection, onSnapshot } from 'firebase/firestore';
// import React, { useEffect, useState } from 'react';
// import db from '../firebase';
// import { useSelector, useDispatch } from 'react-redux';
// import { storeVenues } from '../features/venueFilter/venueFilterSlice';


// const GetVenues = () => {
//     const [ loaded, setLoaded ] = useState()

// // get all venues
//     const [ venues, setVenues] = useState(() =>
//         onSnapshot(collection(db, "Venues"),(snapshot) =>
//         setVenues(snapshot.docs.map((doc) => ({...doc.data(), id:doc.id})))
//     ));

// // to dispatch when venues loaded
//     useEffect(
//         () => 
//         handleChange,
//         [venues],
//     );

//     const count = useSelector((state) => state.counter.count);
//     const dispatch = useDispatch()
//     const handleChange = () => {
//         setVenues('loading'); //Dåligt och ska inte behövas men gör att det funkar....
//         dispatch(storeVenues(venues));
//         console.log('dispatched! ', venues)
//     };

//     return (
//         <>
//         </>
//     )
// }

// export default GetVenues
