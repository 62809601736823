/** @format */

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  Grid,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MyBookings from "../features/bookings/MyBookings";
import MyArtists from "../helpers/MyArtists";
import { listenAllBookings } from "../helpers/Listeners";
import GetArtists from "../helpers/GetArtists";
import GetBookings from "../helpers/GetBookings";

import { useSelector } from "react-redux";
import { useState } from "react";
import GetMyBookings from "../helpers/GetMyBookings";
import UserForm from "../components/Forms/UserForm";
import GeneralForm from "../helpers/GeneralForm";
import { userObj } from "../dbObjects.js/dbUser";
import { venueObj } from "../dbObjects.js/dbVenue";
import IncomingOffers from "../components/UI helpers/IncomingOffers";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useDispatch } from "react-redux";
import { storeBookings, storeCurrentBookings } from "../features/venueFilter/venueFilterSlice";
import LoadingDots from "../components/UI helpers/LoadingDots";

const Requests = () => {
  const currentUser = useSelector((state) => state.counter.currentUser);

  const bookings = useSelector((state) => state.counter.bookings);
  const artists = useSelector((state) => state.counter.artists);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [agentid, setAgentid] = useState(currentUser.userid);
  const [venueData, setVenueData] = useState();
  const dispatch = useDispatch();
  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    setAgentid(currentUser.userid);
  }, [currentUser]);

  useEffect(() => {
    async function getData() {
      setReady(false);
      let tmp = [];
      const querySnapshot = await getDocs(
        query(
          collection(db, "Bookings"),
          where("agentid", "==", currentUser.userid)
          // where("date", ">", currentDate)
        )
      );
      querySnapshot.forEach((doc) => {
        tmp.push(doc.data());
      });

      const usersBookings = tmp.filter((booking) => booking.agentid === currentUser.userid);
      //   const usersBookings = tmp.filter((booking) => booking.artist.artist === "Rusiak");
      await dispatch(storeBookings(usersBookings));
      await dispatch(storeCurrentBookings(usersBookings));
      setReady(true);
      // setBookings(usersBookings);
    }
    getData();
  }, [currentUser]);

  //   listenAllBookings();
  return (
    <Grid container xs={12} sm={12} sx={{ px: { xs: 2, sm: 4 } }} mt={10}>
      {ready ? (
        <IncomingOffers />
      ) : (
        <div>
          <CircularProgress style={{ zoom: "0.4", marginRight: "30px" }} />
          <span style={{ opacity: "0.5" }}>
            fetching requests
            <LoadingDots speed="300" />
          </span>
        </div>
      )}
    </Grid>
  );
};

export default Requests;
