/** @format */

import React, { useEffect, useState } from "react";
import { LinearProgress, Box, CircularProgress } from "@mui/material";
import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";

const EmailProgressBar = ({ docId, close, sentArray, setSentArray }) => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [dontClose, setDontClose] = useState("false");
  const [endTime, setEndTime] = useState(0);
  const [dateSent, setDateSent] = useState();

  // useEffect(() => {
  //   const timestampInMilliseconds = endTime;
  //   setDateSent(new Date(timestampInMilliseconds).toLocaleDateString());
  // }, [endTime]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "mail", docId), (docSnapshot) => {
      const data = docSnapshot.data();
      let deliveryState = "NONE"; // default value

      if (data && data.delivery && data.delivery.state) {
        deliveryState = data.delivery.state;
      }

      // if (data && data.delivery.endTime) {
      //   setEndTime(data.delivery.endTime.seconds * 1000);
      // }

      switch (deliveryState) {
        case "NONE":
          setProgress(0);
          setMessage(`Please wait`);
          break;
        case "PENDING":
          setDontClose("false");
          setProgress(5);
          setMessage(`Connecting`);
          break;
        case "PROCESSING":
          setProgress(50);
          setMessage(`Sending`);
          break;
        case "SUCCESS":
          setProgress(100);
          setMessage(`Email sent`);
          setSentArray((prev) => [...prev, docId]);
          setTimeout(() => {
            // dontClose === "false" && close();
          }, 2000);

          break;
        default:
          setProgress(0);
          setMessage("");
          break;
      }
    });

    return () => {
      unsub();
    };
  }, [docId]);

  return (
    <Box sx={{ width: "100%" }}>
      {message}
      {/* {dateSent} */}
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default EmailProgressBar;
