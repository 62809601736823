/** @format */

import { Box, Button, Divider, Drawer, Fab, Grid, Icon, ListItemButton, ListItemIcon, MenuItem, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  MdOutlineCalendarToday,
  MdAttachEmail,
  MdOutlineDriveFileRenameOutline,
  MdOutlineMenu,
  MdOutlineManageAccounts,
} from "react-icons/md";
import VenueCalendar from "./VenueCalendar";
import Avails from "../../pages/Avails";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import SelectVenue from "../../helpers/SelectVenue";
import HomeIcon from "@mui/icons-material/Home";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendAvails from "../../features/avails/SendAvails";
import ForestIcon from "@mui/icons-material/Forest";
import VerifiedIcon from "@mui/icons-material/Verified";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import MiniMonth from "../../features/MiniMonth";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSelector } from "react-redux";
import ViewListIcon from "@mui/icons-material/ViewList";
import IncomingRequests from "../UI helpers/IncomingRequests";
import VenueInfo from "./VenueInfo";
import SyncTickets from "../../helpers/SyncTickets";
import TicketsMap from "../../helpers/TicketsMap";
import AddVenue from "../Forms/AddVenue";
import EditIcon from "@mui/icons-material/Edit";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import RythmLogin from "../UI helpers/RythmLogin";

const SidebarOwner = () => {
  const superOwner = useSelector((state) => state.counter.superOwner);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  // console.log("currentVenue bea", currentVenue);
  // console.log("currentVenueBookings bea", currentVenueBookings);
  const bookings = useSelector((state) => state.counter.bookings);
  const navigate = useNavigate();

  const drawerWidth = 250;
  const [show, setShow] = useState(true);
  const [openAvails, setOpenAvails] = useState(false);
  const closeAvails = () => {
    setOpenAvails(false);
  };
  const [openRequests, setOpenRequests] = useState(false);
  const closeRequests = () => {
    setOpenRequests(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const addVenue = () => {};
  const userRole = useSelector((state) => state.counter.userRole);

  // const [expanded, setExpanded] = React.useState(false);

  // const handleAccordionChange = () => {
  //   setExpanded(!expanded);
  // };

  // Sync tickets with ticketService and put into redux ticketsSummary

  return (
    <>
      <SyncTickets
        ticketService={currentVenue.ticketService && currentVenue.ticketService}
        ticketApiKey={currentVenue.ticketApiKey && currentVenue.ticketApiKey}
        organizer={currentVenue.ticketOrganizer && currentVenue.ticketOrganizer}
        eventId={currentVenue.ticketEventId && currentVenue.ticketEventId}
      />
      <Drawer
        variant="permanent"
        sx={{
          minWidth: drawerWidth,
          maxWidth: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
            border: "none",
            // background: "none",
            borderRight: "1px solid #333",
          },
          display: { xs: "none", md: "block" },
        }}
      >
        {/* <Grid container className="sidebar" direction="column" spacing={2} style={{ background: "#222" }} mt={10} sx={{ height: "92vh" }}> */}
        <Grid container direction="column" spacing={2} sx={{ height: "92vh" }}>
          <Grid item py={5} mx={1} mt={5}>
            <SelectVenue />
          </Grid>
          {currentVenue.venue !== "" && (
            <Grid item mt={-5}>
              <Link to="/Owner/Venue">
                <ListItemButton id="VenueSettingsBtn">
                  <ListItemIcon>
                    <EditIcon className="mx-3 addEditButton" size={25} />
                  </ListItemIcon>
                  <span className="addEditButton">Venue Settings</span>
                  {/* {console.log(currentVenue)} */}
                </ListItemButton>
              </Link>
            </Grid>
          )}
          <Divider />
          <Grid item>
            <Link to="/Owner/Home">
              <ListItemButton id="HomeBtn">
                <ListItemIcon>
                  <HomeIcon className="mx-3 " size={25} />
                </ListItemIcon>
                {/* <itemTitle className="d-none d-sm-inline menuOption">Home</itemTitle> */}
                Home
              </ListItemButton>
            </Link>
          </Grid>

          {/* <Grid item>
          <Link to="/Owner/Editors">
            <ListItemButton>
              <icon className="text-md-left">
                <MdOutlineManageAccounts className="mx-3" size={25} style={{ color: "primary" }} />
              </icon>
              <itemTitle className="d-none d-sm-inline menuOption">Editors</itemTitle>
            </ListItemButton>
          </Link>
        </Grid> */}
          {currentVenue && (
            <Grid item>
              <Link to="/Owner/Bookings">
                <ListItemButton id="BookingsBtn">
                  <icon className="text-md-left">
                    <LocalActivityIcon className="mx-3" size={25} style={{ color: "primary" }} />
                  </icon>
                  Bookings
                </ListItemButton>
              </Link>
            </Grid>
          )}
          {userRole === "admin" && (
            <Grid item>
              <Link to="/Testing">
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon className="mx-3" size={25} />
                  </ListItemIcon>
                  {/* <itemTitle className="d-none d-sm-inline menuOption">Home</itemTitle> */}
                  [[[Testing]]]
                </ListItemButton>
              </Link>
            </Grid>
          )}
          {/* <Grid item>
            <Link to="/Owner/VenueCalendar">
              <ListItemButton>
                <icon className="text-md-left">
                  <MdOutlineCalendarToday className="mx-3" size={25} style={{ color: "primary" }} />
                </icon>
                Calendar
              </ListItemButton>
            </Link>
          </Grid> */}
          {/* <Grid item>
            <Link to="/Owner/Customers">
              <ListItemButton>
                <icon className="text-md-left">
                  <MdAttachEmail className="mx-3" size={25} style={{ color: "primary" }} />
                </icon>
                Customers
              </ListItemButton>
            </Link>
          </Grid> */}

          {currentVenue && (
            <Grid item>
              <ListItemButton onClick={() => setOpenRequests(true)} id="RequestsBtn">
                <icon className="text-md-left">
                  <ViewListIcon className="mx-3" size={25} style={{ color: "primary" }} />
                </icon>
                Requests
              </ListItemButton>
              <Modal open={openRequests} onClose={closeRequests}>
                <Grid container xs={11} sm={10} md={10} className="standardModal">
                  <IncomingRequests closeRequests={closeRequests} />
                </Grid>
              </Modal>
            </Grid>
          )}
          <Grid item>
            <ListItemButton onClick={() => setOpenAvails(true)} id="SendAvailsBtn">
              <icon className="text-md-left">
                <MdAttachEmail className="mx-3" size={25} style={{ color: "primary" }} />
              </icon>
              Send Avails
            </ListItemButton>
            <Modal open={openAvails} onClose={closeAvails}>
              <Grid container xs={12} sm={8} md={6} className="standardModal">
                {/* <Box style={{ width: "50%", height: "50%", margin: "20%" }} className="standardModal"> */}
                <h2>Send Avails</h2>
                {openAvails && <SendAvails closeAvails={closeAvails} />}
                {/* </Box> */}
              </Grid>
            </Modal>
          </Grid>
          <Grid item>
            <Link to="/Sustainability">
              <ListItemButton id="SustainabilityBtn">
                <icon className="text-md-left">
                  <ForestIcon className="mx-3" size={25} style={{ color: "primary" }} />
                </icon>
                Sustainability
              </ListItemButton>
            </Link>
          </Grid>
          {superOwner && (
            <div className="adminTools">
              <Grid item mx={2} mt={2}>
                {/* <Divider /> */}
                ==> Admin tools
              </Grid>
              <Grid item>
                <Link to="/Owner/BackupManager">
                  <ListItemButton>
                    <icon className="text-md-left">
                      {/* <ForestIcon className="mx-3" size={25} style={{ color: "primary" }} /> */}
                      <SettingsBackupRestoreIcon className="mx-3" size={25} style={{ color: "primary" }} />
                    </icon>
                    Backup Manager
                  </ListItemButton>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/datasheet">
                  <ListItemButton>
                    <icon className="text-md-left">
                      {/* <ForestIcon className="mx-3" size={25} style={{ color: "primary" }} /> */}
                      <ForestIcon className="mx-3" size={25} style={{ color: "primary" }} />
                    </icon>
                    Emission datasheet
                  </ListItemButton>
                </Link>
              </Grid>
            </div>
          )}
          <Grid item>{/* <RythmLogin /> */}</Grid>
          {/* <Grid item mx={0} mt={0} px={0} sx={{ marginLeft: "-2px" }}>
            <Accordion
              sx={{ color: "none", background: "transparent" }}
              // onChange={handleOpenAvails}
              onClick={() => setOpenAvails(true)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <icon className="text-md-left">
                  <MdAttachEmail className="mx-3" size={25} style={{ color: "primary" }} />
                </icon>
                Send Avails
              </AccordionSummary>
              <AccordionDetails sx={{ background: "#1a1a1a" }}><SendAvails /></AccordionDetails>
            </Accordion>
          </Grid> */}
          {/* <Grid item>
          <Link to="/Owner/Avails">
            <Button>
              <icon className="text-md-left">
                <MdAttachEmail className="mx-3" size={25} style={{ color: "primary" }} />
              </icon>
              <itemTitle className="d-none d-sm-inline menuOption">Send Avails</itemTitle>
            </Button>
          </Link>
        </Grid> */}
          {/* <Grid item>
          <Link to="/Owner/Reports">
            <Button>
              <icon className="text-md-left">
                <MdOutlineDriveFileRenameOutline className="mx-3" size={25} style={{ color: "primary" }} />
              </icon>
              <itemTitle className="d-none d-sm-inline menuOption">Reports</itemTitle>
            </Button>
          </Link>
        </Grid> */}
          {/* <Grid item>
            <Link to="/Owner/Venue">
              <ListItemButton>
                <icon className="text-md-left">
                  <ModeEditIcon className="mx-3" size={25} style={{ color: "primary" }} />
                </icon>
                Edit Venue
              </ListItemButton>
            </Link>
          </Grid> */}
          {/* <Grid item>
          <MiniMonth />
        </Grid> */}
        </Grid>
        <Divider />
        <Grid item py={5} mx={3} mt={0}>
          <VenueInfo />
        </Grid>
      </Drawer>
    </>
  );
};

export default SidebarOwner;
