import React from 'react';
import { doc, getDoc } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../../firebase'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeUserRole } from '../../features/venueFilter/venueFilterSlice';


var userRole = 'hej';


const CheckUserRole = async () => {
    
    const auth = getAuth();
    // const userRole = useSelector((state) => state.counter.userRole);
    // console.log(userRole)
    
    // const dispatch = useDispatch();

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log('found user', user.uid)
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        console.log('Role: ', docSnap.data().role)

      var userRole = docSnap.data().role;
      // dispatch(storeUserRole(userRoleToGo))
        // User is signed in, see docs for a list of available properties
        // console.log('Role: ', userRoleToGo)
        // console.log('Email: ', docSnap.data().email)

        // const uid = user.uid;

        // return

        // ...¨
      }
       else {
        // User is signed out
        console.log('User not logged in')
        return
      }

      // return
    });

return (
  <div>
    Hej
  </div>
)

    // const navigate = useNavigate();
        // console.log('PROPS: ',props.uid, docSnap.data())

    // await docSnap.data().role == "owner" ? navigate("/Owner") : console.log('no role')
    //  docSnap.data().role == "agent" ? alert("/Agent") : console.log('not agent')
    //  docSnap.data().role == "editor" ? alert("/Venue") : console.log('not editor')
//   function redirect(userRole){
    

  // console.log("Inloggad som", userRole);

//   //  userRole == 'agent' ? navigate("/Agent") : console.log('not agent')
    
//   //  userRole == "agent" ? redirectTo("/Agent") : console.log('not agent')
//   //  userRole == "editor" ? redirectTo("/Venue") : console.log('not editor')
// //   userRole == "editor" ? alert("/Venue") : console.log('not editor')
//   }


}

export default CheckUserRole