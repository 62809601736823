/** @format */

import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CloseIcon from "@mui/icons-material/Close";
import { spotifyIcon } from "../../assets/icons";

import { FastAverageColor } from "fast-average-color";
import { updateDocument } from "../../firebase";
import { ConfirmationModal } from "./ConfirmationModal";
import BookingForm from "../Forms/BookingForm";
import { GeneralModal } from "./GeneralModal";
import { ReviewBookingModal } from "./ReviewBookingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  storeBookingModalOpen,
  storeCurrentBooking,
  storeCurrentBookingId,
  storeIsNewBooking,
} from "../../features/venueFilter/venueFilterSlice";
import WhatDayIsIt from "../MiscUIComponents/WhatDayIsIt";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import BookingFormGeneral from "../Forms/BookingFormGeneral";

const style = {
  wrapper: {
    position: "absolute",
    width: "400px",
    height: "700px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "linear-gradient(to bottom, rgba(201, 0, 104, 0), rgba(156, 1, 81, 1))",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    borderRadius: "20px",
    alignItems: "center",
    padding: "30px 20px 0 20px",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "250px",
    height: "300px",
    borderRadius: "20px",
    background: "hsla(0, 0%, 100%, .2)",
    backdropFilter: "blur(50px)",
    border: "1px solid rgba(255, 255, 255, 0.5)",
    paddingTop: "20px",
    overflow: "hidden",
  },
  iconWrapper: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // width: "50px",
    // height: "50px",
    // borderRadius: "10px",
    // background: "hsla(0, 0%, 100%, .2)",
    // backdropFilter: "blur(50px)",
    // border: "1px solid rgba(255, 255, 255, 0.5)",
    // marginRight: "5px",
  },
  imageContainer: {
    width: "200px",
    height: "200px",
    borderRadius: "20px",
    objectFit: "contain",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "cover",
    objectPosition: "center",
  },
  artistName: {
    fontSize: "20px",
    marginTop: "20px",
  },
  artistTitle: {
    fontSize: "15px",
  },
  closeModalButtonContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
};

export const ArtistInfoModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [bgColor, setBgColor] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showReviewBooking, setShowReviewBooking] = useState(false);
  const currentBooking = useSelector((state) => state.counter.currentBooking);
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(false);
  const handleClose = () => setOpen(false);

  console.log("ARTISTINMODAL", props.selectedArtist);
  const { artist, artistType, image, about, facebook, instagram, twitter, youtube, spotify, tiktok } = props.selectedArtist;
  async function getDescription(artist) {
    const response = await fetch(`https://en.wikipedia.org/api/rest_v1/page/summary/${artist}`).then((response) => {
      console.log("RESPONSE", response);
    });
    // const data = await response.json();
    // return data.extract;
  }

  const handleEditClick = () => {
    console.log(currentBooking);
    dispatch(storeCurrentBookingId(currentBooking.bookingid));
    dispatch(storeBookingModalOpen(true));
    dispatch(storeIsNewBooking(false));
  };

  console.log(props);
  useEffect(() => {
    dispatch(storeCurrentBooking(props.selectedEvent));
  }, [props.selectedEvent]);

  useEffect(() => {
    console.log("DESCRIPTION", getDescription(props.selectedArtist.artist));
    const fac = new FastAverageColor();
    fac.crossOrigin = "anonymous";
    fac
      .getColorAsync(props.selectedArtist.image)
      .then((color) => {
        setBgColor(color.rgba);
        console.log("color", color.rgba);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.selectedArtist]);
  const refs = {
    spotify: useRef(null),
    youtube: useRef(null),
    instagram: useRef(null),
    facebook: useRef(null),
    twitter: useRef(null),
  };

  const handleClick = (event, type) => {
    event.preventDefault();
    window.open(refs[type].current.href);
  };
  return (
    <div style={{ border: "none" }} className="artistInfoModal">
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(5px)", border: "none" }}
      >
        <Box
          style={{
            ...style.wrapper,
            background: `linear-gradient(to bottom, rgba(201, 0, 104, 0), ${bgColor})`,
          }}
        >
          {showConfirmationModal && (
            <ConfirmationModal
              open={showConfirmationModal}
              setShowConfirmationModal={setShowConfirmationModal}
              selectedEvent={props.selectedEvent}
              closeArtistInfo={() => props.onClose()}
            />
          )}
          {showReviewBooking && (
            <ReviewBookingModal
              open={showReviewBooking}
              setShowReviewBooking={setShowReviewBooking}
              closeArtistInfo={() => props.onClose()}
              selectedEvent={props.selectedEvent}
            />
          )}
          <div style={style.closeModalButtonContainer}>
            <IconButton
              style={{ alignSelf: "flex-end" }}
              onClick={() => {
                props.onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {currentBooking && (
            <div className="rtistInfoModalDate">
              <WhatDayIsIt date={currentBooking.date} /> {currentBooking.date}
            </div>
          )}
          <div style={style.imageWrapper}>
            <div style={style.imageContainer}>
              <img src={image} style={style.image} alt="Artist Image" />
            </div>
            <h1 style={style.artistName}>{artist}</h1>
            {artistType && <p style={style.artistTitle}>{artistType}</p>}
          </div>
          <div style={{ marginTop: "20px", display: "flex" }}>
            {twitter && (
              <div style={style.iconWrapper}>
                <IconButton href={twitter} ref={refs.twitter} onClick={(event) => handleClick(event, "twitter")}>
                  <TwitterIcon sx={{ fontSize: 30, margin: "auto" }} />
                </IconButton>
              </div>
            )}
            {facebook && (
              <div style={style.iconWrapper}>
                <IconButton href={facebook} ref={refs.facebook} onClick={(event) => handleClick(event, "facebook")}>
                  <FacebookIcon sx={{ fontSize: 30, margin: "auto" }} />
                </IconButton>
              </div>
            )}
            {instagram && (
              <div style={style.iconWrapper}>
                <IconButton href={instagram} ref={refs.instagram} onClick={(event) => handleClick(event, "instagram")}>
                  <InstagramIcon sx={{ fontSize: 30, margin: "auto" }} />
                </IconButton>
              </div>
            )}
            {youtube && (
              <div style={style.iconWrapper}>
                <IconButton href={youtube} ref={refs.youtube} onClick={(event) => handleClick(event, "youtube")}>
                  <YouTubeIcon sx={{ fontSize: 30, margin: "auto" }} />
                </IconButton>
              </div>
            )}
            {spotify && (
              <div style={style.iconWrapper}>
                <IconButton href={spotify} ref={refs.spotify} onClick={(event) => handleClick(event, "spotify")}>
                  <img
                    src={spotifyIcon}
                    style={{
                      color: "#FFFFFF",
                      fontSize: 30,
                      marginTop: "3px",
                    }}
                  />
                </IconButton>
              </div>
            )}
          </div>
          <div style={{ marginTop: "15px", maxHeight: "125px" }}>
            <h1>Artist Info</h1>
            {about && (
              <p
                style={{
                  maxHeight: "125px",
                  overflow: "scroll",
                }}
              >
                {about}
              </p>
            )}
          </div>
          <div className="editBookingIcon">
            {/* <Link to="/Owner/Booking"> */}
            <IconButton
              onClick={() => {
                handleEditClick();
              }}
            >
              <EditIcon />
            </IconButton>
            {/* </Link> */}
            {/* <BookingFormGeneral /> */}
          </div>

          {props.selectedEvent.status !== "Confirmed" && (
            <div style={{ position: "absolute", bottom: "10px" }}>
              <Button
                size="small"
                variant="contained"
                // color="primary"
                onClick={() => {
                  console.log(props.selectedEvent);
                  setShowReviewBooking(true);
                }}
                sx={{ background: "black", color: "white" }}
              >
                Review Booking
              </Button>
              <Button
                size="small"
                style={{ marginLeft: "10px" }}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  console.log("SelectedEvent", props.selectedEvent);
                  setShowConfirmationModal(true);
                }}
              >
                Decline
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};
