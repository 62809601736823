/** @format */

import React from "react";
import { useRef, useState } from "react";
import { signup, login, logout, useAuth } from "../../firebase";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { CircularProgress } from "@mui/material";
import { getAuth } from "firebase/auth";
import CheckUserRole from "./CheckUserRole";
import redirectUser from "./redirectUser";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [loading, setLoading] = useState(false);
  const currentUser = useAuth();
  const navigate = useNavigate();

  async function handleLogin() {
    try {
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
    } catch {
      alert("Failed to sign in");
    }
    // var user = getAuth().currentUser;
    setLoading(false);
    setModal(false);

    // console.log('User Role----->  : ', await CheckUserRole());
    // await CheckUserRole() === "owner" ? console.log(await CheckUserRole()) : console.log('no Owner')
    // await CheckUserRole() === "agent" ? console.log(await CheckUserRole()) : console.log('no Agent')
    // await CheckUserRole() === "editor" ? console.log(await CheckUserRole()) : console.log('no Venue')
  }

  async function handleLogout() {
    setLoading(true);
    try {
      await logout();
    } catch {
      alert("Failed to logout");
    }
    setLoading(false);
    setModal(false);
  }

  return (
    <div>
      <div onClick={() => Toggle()}>
        <div>Log In</div>
      </div>
      {modal ? (
        <div>
          <Modal show={modal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Title id="contained-modal-title-vcenter" className="text-center py-3">
              <h3>
                <strong>Log In</strong>
              </h3>
              {loading ? (
                <div class="d-flex justify-content-center mt-5">
                  <div class="spinner-border" role="status" />
                  <CircularProgress />
                </div>
              ) : null}
            </Modal.Title>
            <Modal.Body className="justify-content-center align-items-center">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control ref={emailRef} type="email" placeholder="Enter Email" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control ref={passwordRef} type="password" placeholder="Enter Password" />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button disabled={loading || currentUser} onClick={handleLogin}>
                Submit
              </Button>
              <Button onClick={() => Toggle()} variant="secondary">
                Close
              </Button>
              {currentUser ? (
                <Button variant="" disabled={loading || !currentUser} onClick={handleLogout}>
                  Log Out
                </Button>
              ) : (
                <div></div>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default Login;
