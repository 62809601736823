/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Switch } from "@mui/material";
import React from "react";

const StageModule = ({ featureColor, feature, handleSelect, handleChangeValue, getIcon }) => {
  return (
    <>
      {/* <Accordion> */}
      {/* <AccordionSummary> */}
      <h6 style={{ textAlign: "left", color: featureColor }}>{getIcon(feature.additional)} Stage</h6>
      {/* </AccordionSummary> */}
      {/* <AccordionDetails> */}
      <table>
        <thead>
          <th>Select</th>
          <th>Option</th>
          <th>Width</th>
          <th>Depth</th>
          <th>Total Height</th>
          <th>Clearance Rig</th>
          <th>Additional Cost</th>
          <th>CO2e</th>
        </thead>
        {feature.options &&
          Object.values(feature.options).map((option) => (
            <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
              <td>
                <Switch
                  checked={option.selected ? true : false}
                  onChange={(event) =>
                    handleSelect(event.target.checked, feature.additionalId, option.optionId, feature.unique ? "unique" : null)
                  }
                />
              </td>
              <td>{option.stageLabel}</td>
              <td>{option.stageWidth}</td>
              <td>{option.stageDepth}</td>
              <td>{option.stageClearanceTotal}</td>
              <td>{option.stageClearanceToRig}</td>
              <td>
                <input
                  disabled={!option.selected}
                  type="number"
                  value={option.price}
                  onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value)}
                />
              </td>
              <td>{option.climate}</td>
            </tr>
          ))}
      </table>{" "}
      {/* </AccordionDetails> */}
      {/* </Accordion> */}
    </>
  );
};

export default StageModule;
