/** @format */

import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useState } from "react";
import GoogleCalendar from "../components/MiscUIComponents/GoogleCalendar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Products from "../components/Forms/Products";
import GoogleIcon from "@mui/icons-material/Google";
import { createPayment } from "../finshark/createPayment";
import { useEffect } from "react";
import { createPayout } from "../finshark/createPayout";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { ConstructionTwoTone, TroubleshootOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { storeCurrentVenue } from "../features/venueFilter/venueFilterSlice";
import DisplayObject from "../helpers/displayObject";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { site } from "../utils/site";

const SettingsOwner = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [iframeURL, setIframeURL] = useState();
  // const paymentId = "abcde12345",
  const regionId = "SE";
  const region = "SE";
  // const redirectUri = "http://localhost:3000/Payment";
  const redirectUri = `${site}/Payment`;
  //   const client_id = process.env.REACT_APP_CLIENT_ID_FINSHARK;
  //   const client_secret = process.env.REACT_APP_CLIENT_SECRET_FINSHARK;
  const [paymentId, setPaymentId] = useState();
  const [clientId, setClientId] = useState();

  const dispatch = useDispatch();
  const currentVenue = useSelector((state) => state.counter.currentVenue);

  const [deductFromTickets, setDeductFromTickets] = useState(false);

  // Set parameters when currentVenue is loaded
  useEffect(() => {
    setDeductFromTickets(currentVenue.deductFromTickets);
  }, [currentVenue]);

  // handle deduct from tickets
  const handleDeductFromTickets = async (event) => {
    setDeductFromTickets(event.target.checked);
    let tmp = {};
    tmp = { ...currentVenue };
    tmp.deductFromTickets = event.target.checked ? true : false;

    // update in Redux
    dispatch(storeCurrentVenue(tmp));

    // update in Venues
    await updateDoc(doc(db, "Venues", currentVenue.documentid), {
      deductFromTickets: event.target.checked ? true : false,
    });
  };

  async function changeSettingsInVenue() {}

  //   const bodyData = {
  //     id: "7fa05928-79af-4a24-b59c-4499885d146f",
  //     clientId: process.env.REACT_APP_CLIENT_ID_FINSHARK,
  //     ClientAccess: "SANDBOX",
  //     status: "CREATED",
  //     amount: {
  //       currency: "SEK",
  //       value: 4000,
  //     },
  //     chargeBearer: "SHAR",
  //     creditor: {
  //       account: {
  //         accountNumber: "SE2550000000054400047903",
  //         accountType: "IBAN",
  //         bic: "ESSESESS",
  //         currencyCode: "SEK",
  //       },
  //       address: {
  //         city: "Stockholm",
  //         street: "Landsvagen 39",
  //         country: "Sweden",
  //       },
  //       message: "Tjena biffen",
  //       name: "Peter Olof",
  //     },
  //     debtor: {
  //       account: {
  //         accountNumber: "string",
  //         accountType: "IBAN",
  //         bic: "string",
  //         currencyCode: "string",
  //       },
  //       email: "string",
  //       message: "172800000",
  //       name: "string",
  //       phoneNumber: "string",
  //     },
  //     externalReference: "string",
  //     remittanceInformation: {
  //       value: "Darfor betalar du...",
  //       type: "UNSTRUCTURED",
  //     },
  //     region: "SE",
  //     paymentProductId: "se-domestic-credit-transfer",
  //     paymentTemplateId: "string",
  //     settlementCreditors: [
  //       {
  //         accountNumber: "string",
  //         accountType: "IBAN",
  //         bic: "string",
  //         amount: 4000,
  //         currencyCode: "string",
  //         name: "string",
  //       },
  //     ],
  //   };

  // const client_id = "";
  // const client_secret = "";

  // function getToken(client_id, client_secret, bodyData) {
  // 	console.log(bodyData);
  // 	const authUrl = 'https://auth.finshark.io/connect/token';
  // 	// const apiUrl = "https://api.finshark.io/";
  // 	const apiUrl = 'https://api.finshark.io/v1/payments';
  // 	const authData = new URLSearchParams({
  // 		client_id: process.env.REACT_APP_CLIENT_ID_FINSHARK,
  // 		client_secret: process.env.REACT_APP_CLIENT_SECRET_FINSHARK,
  // 		grant_type: 'client_credentials',
  // 	});

  // 	return fetch(authUrl, {
  // 		method: 'POST',
  // 		headers: {
  // 			'Content-Type': 'application/x-www-form-urlencoded',
  // 		},
  // 		body: authData,
  // 	})
  // 		.then((response) => response.json())
  // 		.then((data) => {
  // 			const access_token = data.access_token;
  // 			console.log(access_token);
  // 			return fetch(apiUrl, {
  // 				method: 'POST',
  // 				headers: {
  // 					Authorization: `Bearer ${access_token}`,
  // 					'Content-Type': 'application/json',
  // 				},
  // 				mode: 'no-cors',
  // 				body: bodyData,
  // 			});
  // 		})
  // 		.then((response) => response)
  // 		.then((data) => {
  // 			console.log(data);
  // 		})
  // 		.catch((snap) => {
  // 			console.error('Snap! ', snap);
  // 		});
  // }

  // function betala() {
  //   const clientid = "";

  //   const amount = 200;
  //   console.log("trying...");
  //   setIframeURL(
  //     `https://flows.finshark.io?type=payments&clientId=${clientid}&redirectUri=${redirectUri}&region=${region}&paymentId=${paymentId}`
  //   );
  //   fetch(`https://europe-west3-venuehub-64e72.cloudfunctions.net/finshark?amount=${amount}`)
  //     .then((response) => {
  //       console.log(response);
  //       console.log(response.data.json());
  //       // return response.json();
  //     })
  //     .then((data) => {
  //       // use the accessToken in your app
  //       // const accessToken = data.accessToken;
  //       // console.log("accessToken:", accessToken);
  //       console.log("data");
  //       console.log(data);

  // setIframeURL(
  //   `https://flows.finshark.io?type=payments&clientId=${clientId}&redirectUri=${redirectUri}&region=${region}&paymentId=${paymentId}`
  // );
  //     })
  //     .catch((error) => {
  //       // handle error
  //       console.error(error);
  //     });
  // }

  useEffect(() => {
    {
      paymentId &&
        setIframeURL(
          `https://flows.finshark.io?type=payments&clientId=${clientId}&redirectUri=${redirectUri}&region=${region}&paymentId=${paymentId}`
        );
    }
  }, [paymentId && clientId]);

  return (
    <Grid container xs={12} p={4}>
      <Grid item xs={12}>
        <h1>Settings</h1>
        <Grid item xs={12} sm={4}>
          <Accordion expanded={expanded === "google"} onChange={handleChange("google")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography sx={{ width: "33%", flexShrink: 0 }}>Sync</Typography>
              <Typography sx={{ color: "text.secondary" }}>Sync bookings with Google Calendar</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GoogleCalendar />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Accordion expanded={expanded === "products"} onChange={handleChange("products")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography sx={{ width: "33%", flexShrink: 0 }}>Products</Typography>
              <Typography sx={{ color: "text.secondary" }}> Set up your Products</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Products />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Accordion expanded={expanded === "payment"} onChange={handleChange("payment")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography sx={{ width: "33%", flexShrink: 0 }}>Payment (Finshark)</Typography>
              <Typography sx={{ color: "text.secondary" }}> Make a payment</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ height: "700px", width: "400px" }}>
              {/* <Button onClick={() => getToken(client_id, client_secret, bodyData)}>Pay up</Button> */}
              {/* <Button
                onClick={() =>
                  createPayment({
                    bodyData,
                    setPaymentId,
                    setClientId,
                  })
                }
              >
                Pay up
              </Button> */}
              {/* <Button
                onClick={() =>
                  createPayout({
                    bodyData,
                    setPaymentId,
                    setClientId,
                  })
                }
              >
                Get Paid
              </Button> */}
              {/* <Button onClick={() => sendPaymentLink({ bodyData })}>Send Payment Link</Button> */}
              <iframe src={iframeURL} height="640px" width="360px"></iframe>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {currentVenue.IBAN && (
          <Grid item item xs={12} sm={4}>
            <Accordion expanded={expanded === "invoice"} onChange={handleChange("invoice")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Invoice settings</AccordionSummary>
              <AccordionDetails>
                <LocalActivityIcon sx={{ marginRight: "15px" }} />
                <FormControlLabel
                  control={<Switch checked={deductFromTickets} onChange={(e) => handleDeductFromTickets(e)} />}
                  label="Deduct From Tickets"
                />
                <DisplayObject data={currentVenue} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SettingsOwner;
