/** @format */

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import shortText from "../../helpers/shortText";

const ShowTicketCategoriesNortic = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const settings = { size: "small", width: 3, margin: "normal" };
  const [loading, setLoading] = useState(false);
  // const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: currentVenue.ticketVenueId });
  const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: 3305 });
  const [ticketCategories, setTicketCategories] = useState([]);
  async function getTicketCategories() {
    setLoading(true);
    const data = { organizerId: newTicketCategory.organizerId };
    console.log("Data", data);
    try {
      // console.log("Body", body);

      const result = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicketCategoriesNortic", data);
      console.log("Ticket Categories", result.data);
      setTicketCategories(result.data.values);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!newTicketCategory.organizerId) return;
    getTicketCategories();
  }, [newTicketCategory.organizerId]);

  const handleChange = (id, name) => {
    console.log(id, name);
  };

  return (
    <div style={{ display: "flex" }}>
      ShowTicketCategoriesNortic
      {ticketCategories.length &&
        ticketCategories.map((category) => (
          <div key={category.id}>
            <FormControlLabel
              control={<Radio label={shortText(category.name, 10)} onChange={(e) => handleChange(category.id, category.name)} />}
            />
            {category.name}
          </div>
        ))}
    </div>
  );
};

export default ShowTicketCategoriesNortic;
