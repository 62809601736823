import React from "react";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { storeCapMin, storeCapMax, storeNumberOfEvents } from "../../features/venueFilter/venueFilterSlice";

const NumberOfEventsSlider = () => {

    const numberOfEvents = useSelector((state) => state.counter.numberOfEvents);
    const dispatch = useDispatch();

    function handleChangeCommitted(event, newValue){
        dispatch(storeNumberOfEvents(newValue))
    }

  return (
    <div>
        <Slider 
            defaultValue={10} 
            aria-label="Default"    
            valueLabelDisplay="auto"
            min={0}
            step={1}
            max={40}
            marks={[100,200,300,400,500]}
            // style={{ maxWidth: 200 }}
            onChangeCommitted={handleChangeCommitted}
        />
    </div>
  )
}

export default NumberOfEventsSlider
