/** @format */

import axios from "axios";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
export const createFinsharkSubscription = async (body, currentVenue, plan) => {
  const venueid = currentVenue.venueid;
  try {
    // const body = {
    // 	amount: {
    // 		currency: 'SEK',
    // 		value: 2.2,
    // 	},
    // 	chargeBearer: 'SHAR',
    // 	creditor: {
    // 		account: {
    // 			accountNumber: 'SE1060000000000515034762',
    // 			accountType: 'IBAN',
    // 			bic: 'HANDSESS',
    // 			currencyCode: 'SEK',
    // 		},
    // 		address: {
    // 			city: 'Stockholm',
    // 			street: 'Rörstrandsgatan 18',
    // 			country: 'Sweden',
    // 		},
    // 		message: 'string',
    // 		name: 'string',
    // 	},
    // 	frequency: 'MONTHLY',
    // 	paymentProductId: 'se-domestic-credit-transfer',
    // 	region: 'SE',
    // 	remittanceInformation: {
    // 		value: 'string',
    // 		type: 'UNSTRUCTURED',
    // 	},
    // 	startDate: 1703688054087,
    // };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/finsharkCreateSubscription`, body);
    console.log("response:", response.data);
    // setDoc(
    //   doc(db, "Venues", venueid),
    //   { subscription: { id: response.data.responseData.id, plan: plan, service: "Finshark" } },
    //   { merge: true }
    // );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
