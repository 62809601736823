/** @format */

import React from "react";
import axios from "axios";
import { Button } from "@mui/material";

const CreateEventNortic = () => {
  // React Component to send payload
  const sendEvent = async () => {
    const payload = {
      organizerId: 3302,
      name: "Metallica",
      status: "DRAFT",
      eventCategory: "THEATER",
      shows: [
        {
          name: "Shrek",
          startDate: "2025-01-21T14:42:30+01:00",
          maxAvailable: 1000,
          ticketCategories: [
            {
              name: "Ordinarie",
              price: 100,
              maxAvailable: 1000,
            },
          ],
        },
      ],
    };

    try {
      const response = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/createEventNortic", payload);
      console.log("Event created successfully:", response.data);
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  return (
    <Button onClick={sendEvent} variant="contained" size="small">
      Create Event
    </Button>
  );
};

// Example JSON payload to be sent in the request:
// {
//   "organizerId": 1,
//   "name": "Metallica",
//   "status": "DRAFT",
//   "eventCategory": "THEATER",
//   "shows": [
//     {
//       "name": "string",
//       "startDate": "2025-01-21T14:42:30+01:00",
//       "maxAvailable": 1000,
//       "ticketCategories": [
//         {
//           "name": "string",
//           "price": 100,
//           "maxAvailable": 1000
//         }
//       ]
//     }
//   ]
// }

export default CreateEventNortic;
