import React from 'react';
import { Tooltip, Badge, IconButton } from '@mui/material';
import { AvTimer, Done } from '@mui/icons-material';

type Props = {};

export const AwaitingArtistResponseBooking = (props: Props) => {
	const onClickHandler = () => {
		props.setShowBookingStatusType(
			props.showBookingStatusType === 'Awaiting Artist'
				? ''
				: 'Awaiting Artist',
		);
	};
	return (
		<IconButton className='badge' onClick={onClickHandler}>
			<Tooltip
				title={props.noAwaitingResponse + ' requests awaiting artist'}
			>
				<Badge
					badgeContent={props.noAwaitingResponse}
					sx={{
						'& .MuiBadge-badge': {
							color: 'white',
							border: '1px solid #509a6c',
							backgroundColor: 'orange',
							// backgroundColor: "orange",
						},
					}}
				>
					<AvTimer color='action' />
				</Badge>
			</Tooltip>
		</IconButton>
	);
};
