/** @format */

import { CircularProgress } from "@mui/material";
import { set } from "date-fns";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgreementModal from "../../components/Documents/AgreementModal";
import AgreementSign from "../../components/Documents/AgreementSign";
import { listenAllBookings } from "../../helpers/Listeners";
import {
  storeAwaitingBookings,
  storeConfirmedBookings,
  storeCurrentArtistBookings,
  storeCurrentBookings,
  storePendingBookings,
} from "../venueFilter/venueFilterSlice";

// var tempAwaiting = [];
// var tempConfirmed = [];
let sortedBookings;
const ArtistEventList = () => {
  const dispatch = useDispatch();

  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);
  const bookings = useSelector((state) => state.counter.bookings);
  const [tempPending, setTempPending] = useState();
  const [tempAwaiting, setTempAwaiting] = useState();
  const [tempConfirmed, setTempConfirmed] = useState();
  const [sortingDone, setSortingDone] = useState(false);
  let tempCurrentArtistBookings = [];

  function wait() {
    setTimeout(() => {}, 300);
  }

  // Put bookings in date order
  async function sortBookings() {
    sortedBookings = await bookings.slice().filter(function (booking) {
      return booking.artistid === currentArtist.artistid;
    });
    await sortedBookings.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    setTimeout(() => {
      setSortingDone(true);
    }, 1000);
  }

  if (sortingDone === false) wait();

  useEffect(() => {
    async function getArtistBookings() {
      await sortBookings();
      await sortedBookings.map((booking) => {
        if (booking.artistid === currentArtist.artistid && new Date(booking.date) > today) tempCurrentArtistBookings.push(booking);
      });
      await dispatch(storeCurrentBookings(tempCurrentArtistBookings));
      await dispatch(storeCurrentArtistBookings(tempCurrentArtistBookings));
      setSortingDone(false);
    }
    getArtistBookings();
  }, [currentArtist, availableVenuesAndDates]);

  function checkAgreementAndSign(booking) {
    console.log(booking);
  }

  const today = new Date();
  const pendingRequests = (booking, noPe) => {
    if (booking.artistid === currentArtist.artistid && booking.status === "Pending Request" && new Date(booking.date) > today) {
      return (
        <div>
          <div className="blobPending"></div>
          {/* <div className="blobLine"></div> */}
          {booking.date} {booking.venue}
        </div>
      );
    }
  };
  const awaitingArtist = (booking) => {
    if (booking.artistid === currentArtist.artistid && booking.status === "Awaiting Artist" && new Date(booking.date) > today) {
      return (
        <div>
          <div className="blobAwaiting"></div>
          {/* <div className="blobLine"></div> */}
          {console.log(booking.bookingid)}
          {booking.date} {booking.venue}
          <span style={{ float: "right", zoom: "0.7" }}>
            {booking.bookingid && booking.status === "Awaiting Artist" && (
              <AgreementModal bookingid={booking.bookingid} sign={"agentSignature"} />
            )}
          </span>
        </div>
      );
    }
  };
  const confirmed = (booking) => {
    if (booking.artistid === currentArtist.artistid && booking.status === "Confirmed" && new Date(booking.date) > today) {
      return (
        <div>
          <div className="blobConfirmed"></div>
          <div className="blobLine"></div>
          <b>{booking.date}</b> {booking.venue}
          <span style={{ float: "right", zoom: "0.7" }}>
            {booking.bookingid && booking.status === "Confirmed" && <AgreementModal bookingid={booking.bookingid} />}
          </span>
        </div>
      );
    }
  };

  {
    if (sortingDone)
      return (
        <div>
          {/* ArtistEventList */}
          <div style={{ height: "350px", overflow: "scroll", fontSize: "14px" }}>
            <div>
              <b>Pending Requests</b>
              {sortedBookings && sortedBookings.map((booking) => <div>{pendingRequests(booking)}</div>)}
            </div>
            <div>
              <b>Awaiting your signature</b>
              {sortedBookings && sortedBookings.map((booking) => <div>{awaitingArtist(booking)}</div>)}
            </div>
            <div>
              <b>Confirmed</b>
              {sortedBookings && sortedBookings.map((booking) => <div>{confirmed(booking)}</div>)}
            </div>
          </div>
        </div>
      );
    else
      return (
        <div style={{ padding: "50px" }}>
          <CircularProgress size={20} />
          <p>...</p>
        </div>
      );
  }
};

export default ArtistEventList;
