/** @format */

import React, { useEffect, useState } from "react";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import {
  Grid,
  Switch,
  Button,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  InputLabel,
  FormControl,
  FormGroup,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { set } from "date-fns";

const CreateUser = () => {
  let venueAccess = [];
  const ownedVenues = useSelector((state) => state.counter.ownedVenues);
  const accessibleVenues = useSelector((state) => state.counter.accessibleVenues);
  console.log("accessibleVenues", accessibleVenues);
  const [open, setOpen] = React.useState(false);
  const [newUser, setNewUser] = useState({ name: "", phone: "", email: "", venueAccess: "", userid: "", password: "" });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //   const editor = { name: "", phone: "", email: "", venueAccess: "", userid: "" };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // border: '1px solid'
  };

  const handleChange = (type, id, value, checked) => {
    if (id === "venueAccess") {
      venueAccess = [...newUser.venueAccess];
      if (checked == true) {
        venueAccess.push(value);
        setNewUser({ ...newUser, [id]: venueAccess });
      }
      if (checked == false) {
        venueAccess.splice(venueAccess.indexOf(value), 1);
        setNewUser({ ...newUser, [id]: venueAccess });
      }
    } else {
      //   setNewUser({ ...newUser, [id]: value });
    }
    // id === "venueAccess" ? (value = venueAccess) : (value = value);
    // if (checked == true) {
    //   venueAccess.push(value);
    //   value = venueAccess;
    // }
    // if (checked == false) {
    //   venueAccess.splice(venueAccess.indexOf(value), 1);
    //   value = venueAccess;
    // }
    //
  };

  const handleSubmit = () => {
    // alert("sending invite!");
    handleClose();
  };

  useEffect(() => {
    console.log("venueAccess", venueAccess);
    console.log("newUser", newUser);
  }, [newUser]);

  return (
    <div>
      {open ? (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            {/* <EditorForm modal={handleClose} userToEdit={editor} /> */}
            <FormControl>
              <Grid container xs={12} spacing={2}>
                {console.log(ownedVenues)}
                <div className="formTitle">
                  Add user
                  {/* {userToEdit && console.log(userToEdit)} */}
                </div>
                {accessibleVenues &&
                  accessibleVenues.map((venue) => (
                    <Grid item xs={12}>
                      <Switch
                        key={venue.venueid}
                        checked={newUser.venueAccess.includes(venue.venueid)}
                        id="venueAccess"
                        value={venue.venueid}
                        size="small"
                        onChange={(e) => handleChange(e.target.type, e.target.id, e.target.value, e.target.checked)}
                      />
                      <stats>{venue.venue}</stats>
                    </Grid>
                  ))}

                {/* create a mui select with the options: Viewer, Editor and Owner */}
                <Grid item xs={12}>
                  <Select size="small" defaultValue="Viewer" onChange={(e) => setNewUser({ ...newUser, userRole: e.target.value })}>
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Viewer">Viewer</MenuItem>
                    <MenuItem value="Editor">Editor</MenuItem>
                    <MenuItem value="Owner">Owner</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label="Name"
                    id="name"
                    // defaultValue={defaultName}
                    size="small"
                    onChange={(e) => handleChange(e.target.type, e.target.id, e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="email"
                    label="Email"
                    id="email"
                    // defaultValue={defaultEmail}
                    size="small"
                    onChange={(e) => handleChange(e.target.type, e.target.id, e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="phone"
                    label="Phone"
                    id="phone"
                    // defaultValue={defaultPhone}
                    size="small"
                    onChange={(e) => handleChange(e.target.type, e.target.id, e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="password"
                    label="Password"
                    id="password"
                    // defaultValue={defaultPassword}
                    size="small"
                    onChange={(e) => handleChange(e.target.type, e.target.id, e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    disabled
                    type="userid"
                    label="User ID"
                    id="userid"
                    // defaultValue={defaultUserId}
                    size="small"
                    onChange={(e) => handleChange(e.target.type, e.target.id, e.target.value)}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <Grid container spacing={2} mt={1}>
              <Grid item>
                <Button color="primary" variant="contained" onClick={handleSubmit}>
                  Save new user
                </Button>
              </Grid>
              <Grid item>
                <Button color="info" variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : (
        <Tooltip title={"Add new Editor"}>
          {/* <Button> */}
          <icon>
            {ownedVenues !== "none" ? (
              <PersonAddAlt1Icon
                onClick={() => {
                  handleOpen();
                }}
                color="primary"
              />
            ) : (
              <div>
                <CircularProgress sx={{ zoom: "0.5" }} />
              </div>
            )}
          </icon>
          {/* </Button> */}
        </Tooltip>
      )}
    </div>
  );
};

export default CreateUser;
