/** @format */

import { Slider } from "@mui/material";
import React from "react";
import { numberFormatted } from "../../helpers/Numbers";
import PieChartCenteredLabel from "../UI helpers/PieChartCenteredLabel";

const ElectricityUse = ({ handleChange, formData }) => {
  return (
    <div className="visualData">
      <h7>Electricity Use</h7>

      <div className="dataInput">
        <Slider
          name={"electricityUse"}
          value={formData.electricityUse ? formData.electricityUse : 0}
          label="Purchased electrcitiy during the reporting year"
          min={0}
          max={1000000}
          step={1000}
          marks
          valueLabelDisplay={formData.electricityUse === "not available" ? "off" : "on"}
          valueLabelFormat={(v) => `${v.toLocaleString()} kWh`}
          onChange={(e, newValue) => handleChange("electricityUse", newValue)}
          style={{ width: "200px", marginTop: "40px", float: "right" }}
        />
        {formData["electricityUseCO2e"] && <div className="CO2eDisplay">CO2e: {numberFormatted(formData["electricityUseCO2e"])} kg</div>}
      </div>
    </div>
  );
};

export default ElectricityUse;
