/** @format */

import { Box, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelationGraphs from "./CancelationGraphs";
import { calculateBookingPrice } from "../components/MiscUIComponents/calculateBookingPrice";
import InfoBox from "../components/UI helpers/InfoBox";

const CancelationSlider = ({ bookingData, setBookingData, formlock }) => {
  const [value, setValue] = useState([bookingData.earlyCancelationAt, bookingData.lateCancelationAt]); // Initial range values
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let tmp = JSON.parse(JSON.stringify(bookingData));
    tmp.earlyCancelationAt = 365 - newValue[0];
    tmp.lateCancelationAt = 365 - newValue[1];
    setBookingData(tmp);
  };

  const marks = [
    { value: 365, label: "Event Day" },
    { value: 0, label: "1 year" },
  ];

  useEffect(() => {
    setValue([365 - bookingData.earlyCancelationAt, 365 - bookingData.lateCancelationAt].reverse());
    // setValues([365 - bookingData.upfrontAt, 365 - bookingData.preChargeAt, 365 - bookingData.chargeAt].reverse());
  }, [bookingData]);

  return (
    <Box className="cancelationSlider">
      <CancelationGraphs bookingData={bookingData} setBookingData={setBookingData} formlock={formlock} />
      <Slider
        disabled={formlock}
        getAriaLabel={() => "Early to late range"}
        value={value}
        marks={marks}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => (value === 0 ? `Direct` : `${365 - value} days ahead`)}
        aria-labelledby="range-slider"
        getAriaValueText={(value) => `${365 - value}%`}
        min={0}
        max={365} // Adjust max value as needed
        step={5}
        sx={{
          "& .MuiSlider-track": { display: "none" },
        }}
        size="small"
      />
      <div className="formDescription">
        {/* <InfoBox
          text={`Early cancelation: {bookingData.earlyCancelation}% of total cost for canceled date, less than {bookingData.earlyCancelationAt} days
        ahead of event.
        <br></br>
        Late cancelation: {bookingData.lateCancelation}% of total cost for canceled date, if less than {bookingData.lateCancelationAt} days
        ahead of event.`}
        /> */}
        Early cancelation: {bookingData.earlyCancelation}% of total cost for canceled date, less than {bookingData.earlyCancelationAt} days
        ahead of event.
        <br></br>
        Late cancelation: {bookingData.lateCancelation}% of total cost for canceled date, if less than {bookingData.lateCancelationAt} days
        ahead of event.
      </div>
    </Box>
  );
};

export default CancelationSlider;
