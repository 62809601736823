/**
 * Old CreateTour
 *
 * @format
 */

/** @format */

import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAvailableVenuesAndDates,
  storeAverageTicketPrice,
  storeMapIsOpen,
  storeTimelineIsOpen,
  storeWantedList,
} from "../venueFilter/venueFilterSlice";

const CreateTour = (props) => {
  const dispatch = useDispatch();
  const chosenDateRange = useSelector((state) => state.counter.chosenDateRange);
  const averageTicketPrice = useSelector((state) => state.counter.averageTicketPrice);
  const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);
  const wantedList = useSelector((state) => state.counter.wantedList);
  const startDate = useSelector((state) => state.counter.startDate);
  const endDate = useSelector((state) => state.counter.endDate);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const bookings = useSelector((state) => state.counter.bookings);
  let tempVenue = [];
  let tempVenuesToRemove = [];
  var i = 0;
  var j = 0;
  const [nuTemp, setNuTemp] = useState();
  let venuesToRemove = [];
  let temp = [];
  let venuesWithProposedDate = [];
  // var bookingsForArtist = bookings.filter((bookings)=> bookings.artistid == currentArtist.artistid)

  console.log(currentArtist);
  console.log("Bookings", bookings);

  console.log("The DATES", startDate, endDate);
  isReadyToCreateTour();

  async function makeTour() {
    //CHAIN
    //First create a list to sort the venues in suitable order for customer
    await calculateProfit();

    // Check for Venues in same town
    await checkVenuesWithinSameArea();

    // Make the unique Set of venues to remove into an array
    temp = Array.from(await venuesToRemove);

    //...remove unusable venues
    // console.log("Before", availableVenuesAndDates);
    // console.log("Temp", temp);
    await removeAllUnusableVenue(temp);

    await suitableDates(tempVenue);
    // console.log("Proposed", venuesWithProposedDate);
    // console.log("DONE", await tempVenue);
    dispatch(storeTimelineIsOpen(true));
    // dispatch(storeMapIsOpen(true));
  }
  function isReadyToCreateTour() {}

  async function calculateProfit() {
    availableVenuesAndDates.map((venue) => {
      var revenue = averageTicketPrice * venue.capacity - venue.priceWknd;
      tempVenue.push({ ...venue, revenue: revenue });
    });
    await tempVenue.sort(function (a, b) {
      return b.revenue - a.revenue;
    });
    console.log("orderByProfit", await tempVenue);
    dispatch(storeAvailableVenuesAndDates(await tempVenue));
  }

  function checkVenuesWithinSameArea() {
    availableVenuesAndDates.map(async (A, indexA) => {
      // console.log(indexA)
      await availableVenuesAndDates.map(async (B, indexB) => {
        // console.log(indexB)
        var m = await getDistanceTo(A.venueLat, A.venueLng, B.venueLat, B.venueLng);
        if (m > 0 && m < 10000) {
          compareIndex(A.venueid, B.venueid);
        }
      });
    });
  }

  const getDistanceTo = (latA, lngA, latB, lngB, index) => {
    // Calculate distance between A and B. This is to
    var lat1 = parseFloat(latA);
    var lng1 = parseFloat(lngA);
    var lat2 = parseFloat(latB);
    var lng2 = parseFloat(lngB);
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lng2 - lng1) * Math.PI) / 180;
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = (Math.round(R * c) / 1).toFixed(0); // in 1 m (to get in km divide by 1000 instead)
    return distance;
  };

  function compareIndex(A, B) {
    var indexA = availableVenuesAndDates.map((object) => object.venueid).indexOf(A);
    var indexB = availableVenuesAndDates.map((object) => object.venueid).indexOf(B);
    let v = [];
    if (indexA < indexB) {
      //Basically if venue A is higher ranked (1 instead of 2), remove it from "availableVenuesAndDates"
      tempVenuesToRemove.push(B);
      v = new Set(tempVenuesToRemove);
      console.log(v);
      venuesToRemove = v;
    }
    // removeUnusableVenue(v);
  }

  function checkIfHasAvails() {
    //map over all venues and check length if has avails...
    availableVenuesAndDates.map((venue) => {
      // if(venue.avails.length === 1) removeUnusableVenue(venue.venueid);
      if (venue.avails.length === 1) {
        console.log("Missing AVAILS", venue.venue);
        tempVenuesToRemove.push(venue.venueid);
        var v = new Set(tempVenuesToRemove);
        console.log(v);
        venuesToRemove = v;
      }
    });
  }

  // function removeUnusableVenue(venueid){
  //     var a = availableVenuesAndDates;

  //     //remove venue with venueid
  //     a = a.reduce((p,c) => (c.venueid !== venueid && p.push(c),p),[]);

  //     //Then dispatch
  //     dispatch(storeAvailableVenuesAndDates(a))
  //     console.log('📪 🧡 🎯 Believer !!', a, i);
  //     console.log('Removed: =>', a.venue)
  //     return
  // }

  async function removeAllUnusableVenue(venues) {
    var a = availableVenuesAndDates;
    console.log(venues);

    await venues.map((venue) => {
      //remove venue with venueid
      a = a.reduce((p, c) => (c.venueid !== venue && p.push(c), p), []);
    });

    //Then dispatch
    console.log("dispatch");
    dispatch(storeAvailableVenuesAndDates(a));
    tempVenue = a;
    console.log(availableVenuesAndDates);
    return;
  }

  async function suitableDates(venues) {
    let ve = [];
    let allAvailableDates = [];
    let uniqueAvailableDates = [];

    // Sort venues by number of avails.
    await venues.map((venue) => ve.push({ ...venue, numberOfAvails: venue.numberOfAvails }));
    await ve.sort(function (a, b) {
      return a.numberOfAvails - b.numberOfAvails;
    });
    console.log("Venues sorted by number of avails", ve);
    tempVenue = ve;
    console.log("NEW venues sorted by number of avails", tempVenue);
    // dispatch(storeAvailableVenuesAndDates(await ve))

    // Then create list of all unique dates available
    await tempVenue.map((v) => {
      v.avails.map((av) => {
        allAvailableDates.push(av);
        // console.log(av)
      });
      uniqueAvailableDates = new Set(allAvailableDates);
      console.log(uniqueAvailableDates);
    });

    var bookingsForArtist = bookings.filter((bookings) => bookings.artistid == currentArtist.artistid);
    bookings.map((booking) => {
      // console.log(booking);
    });
    // console.log("Artistens bokningar:", bookingsForArtist);
    var busyDates = bookingsForArtist.map((booking) => booking.date);
    // console.log(busyDates);

    // Now pick the dates for each venue (from uniqueAvailableDates)
    let preBook = [];
    // console.log(tempVenue.length);
    // console.log(preBook);
    await tempVenue.map((venue) => {
      for (i = 1; i <= venue.avails.length; i++) {
        if (!preBook.includes(venue.avails[i]) && !busyDates.includes(venue.avails[i])) {
          // console.log(venue.venue, venue.avails[i]);
          // console.log(busyDates);
          preBook.push(venue.avails[i]);
          venuesWithProposedDate.push({
            ...venue,
            proposedDate: venue.avails[i],
          });
          break;
        }
      }
    });

    // Then sort venuesWithProposedDate in date order
    let soVe = venuesWithProposedDate;
    await venuesWithProposedDate.sort(function (a, b) {
      return new Date(a.proposedDate) - new Date(b.proposedDate);
    });
    console.log("soVe", venuesWithProposedDate);
    // dispatch(storeAvailableVenuesAndDates(venuesWithProposedDate));
    dispatch(storeWantedList(venuesWithProposedDate));
  }

  return (
    <div>
      {/* <GetBookings /> */}
      <Button className="w-100" variant="outlined" color="primary" onClick={makeTour}>
        Create Tour
      </Button>
    </div>
  );
};

export default CreateTour;
