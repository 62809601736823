/** @format */

// /** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { CircularProgress, Tooltip } from "@mui/material";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import CircleGraph from "../components/UI helpers/CircleGraph";
import { useSelector } from "react-redux";
import ObjectSize from "./ObjectSize";
import LoadingDots from "../components/UI helpers/LoadingDots";

const EventTicketSummary = ({ ticketEventId, salesPie, weeklyChart, detailedSeats, pieSize, pieStroke, noNumber, showSales }) => {
  const currentTicketSummary = useSelector((state) => state.counter.currentTicketSummary);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [ticketSummary, setTicketSummary] = useState(null);

  const [shareSold, setShareSold] = useState(0);
  const [error, setError] = useState(null);
  // const capSize = currentVenue.capacity;
  const capSize = 450;
  console.log("ticketEventId", ticketEventId);
  useEffect(() => {
    console.log("currentTicketSummary", currentTicketSummary);
    const obj = Object.values(currentTicketSummary).find((x) => x.ticketEventId === ticketEventId);
    console.log("obj", obj);
    obj && obj.summary && console.log("obj", obj.summary.count);
    if (obj && obj.summary) {
      setTicketSummary(obj.summary);
      setShareSold(Math.round((obj.summary.count / capSize) * 100));
    }
  }, [currentTicketSummary]);

  useEffect(() => {
    console.log("Share sold", shareSold);
  }, [shareSold]);

  const TicketSearch = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000); // 3 seconds
      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }, []);

    // return <div style={{ marginRight: "5px", opacity: "0.5" }}>{loading ? <CircularProgress style={{ zoom: "0.3" }} /> : "No sales"}</div>;
    return <div style={{ marginRight: "5px", opacity: "0.7" }}>{loading ? <LoadingDots speed={300} /> : "No sales"}</div>;
  };

  // if (currentTicketSummary.length > 0)
  return (
    <div>
      {/* <LocalActivityIcon /> */}
      {ticketSummary && ticketSummary.count ? (
        <Tooltip title={`${ticketSummary.count} tickets sold.`}>
          <div className="singleBookingTickets" style={{ width: `${pieSize * 2}px`, height: `${pieSize * 2}px` }}>
            {/* {shareSold && salesPie && ( */}
            <span className="ticketPie">
              <CircleGraph size={pieSize ? pieSize : "10"} value={shareSold} stroke={pieStroke ? pieStroke : "5"} noNumber={noNumber} />
            </span>
            {/* )} */}
            {showSales === "smallOnTheSide" && <span className="ticketSaleNumber">{ticketSummary.count}</span>}
            {showSales === "inside" && (
              <div>
                <div className="showSalesInside">{ticketSummary.count}</div>
              </div>
            )}
          </div>
        </Tooltip>
      ) : (
        // <CircularProgress style={{ zoom: "0.3" }} />
        <TicketSearch />
      )}
    </div>
  );
};

export default EventTicketSummary;
