/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Switch } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { countries } from "../../dbObjects.js/dbCountries";
import VatSelector from "../MiscUIComponents/VatSelector";

const StaffModule = ({ featureColor, feature, handleSelect, handleChangeValue, getIcon }) => {
  const [calculatedPrice, setCalculatedPrice] = useState(0);

  function calculatePrice(featureId, optionId, field, value) {
    const each = feature.options[optionId].each;
    const quantity = feature.options[optionId].quantity;

    setTimeout(() => {
      handleChangeValue(featureId, optionId, "price", each * quantity);
    }, 100);
  }

  return (
    <>
      <h6 style={{ textAlign: "left", color: featureColor }}>{getIcon(feature.additional)} Staff</h6>
      <table>
        <thead>
          <th>Select</th>
          <th>Name</th>
          <th>Area of responsibility</th>
          <th>Phone</th>
          <th>Email</th>
          <th>CO2e</th>
        </thead>
        {feature.options &&
          Object.values(feature.options).map((option) => (
            <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
              <td>
                <Switch
                  onChange={(event) => handleSelect(event.target.checked, feature.additionalId, option.optionId)}
                  checked={option.selected ? true : false}
                />
              </td>
              <td>{option.name}</td>
              <td>{option.responsibility}</td>
              <td>{option.phone}</td>
              <td>{option.email}</td>

              <td>{option.climate}</td>
            </tr>
          ))}
      </table>
    </>
  );
};

export default StaffModule;
