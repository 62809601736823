/** @format */

// Create array of form fields.
export let productObj = [
  { section: "Document ID", type: "Section" },
  {
    section: "Document",
    placeholder: "Document ID",
    label: "Document ID",
    id: "documentid",
    type: "text",
    disabled: true,
  },
  //   {
  //     sectionsToShow: { Product: true, Stage: true, Security: false, Audience: true },
  //   },
  {
    section: "Product",
    type: "Section",
    switch: true,
    description: "Name and description of this product",
  },
  {
    section: "Product",
    label: "Product",
    placeholder: "Name Product...",
    id: "product",
    defaultValue: "Concert Evening",
    type: "text",
    priority: 1,
  },
  {
    section: "Product",
    label: "ProductID",
    placeholder: "ProductID",
    id: "productid",
    defaultValue: "Standard Product",
    type: "text",
    disabled: true,
    priority: 10,
  },
  {
    section: "Product",
    label: "Description",
    placeholder: "Description",
    maxLength: 150,
    id: "description",
    defaultValue: "Describe this product...",
    type: "textarea",
    multiline: true,
    rows: 4,
    style: { width: "500px" },
    priority: 1,
  },
  // {
  //   section: "Pricing",
  //   type: "Section",
  //   switch: true,
  //   description: "Set the price of this product",
  // },
  // {
  //   section: "Pricing",
  //   placeholder: "Total Price",
  //   label: "Total Price",
  //   id: "price",
  //   type: "text",
  //   defaultValue: "0",
  //   priority: 1,
  // },
  {
    section: "Private or Public",
    type: "Section",
    switch: true,
    description: "Set the visibility and contractual variables of this product.",
  },
  {
    section: "Private or Public",
    label: "Public or Private",
    id: "privatePublic",
    options: ["Private Event", "Public Event"],
    // defaultValue: ["Public Event"],
    type: "toggleButton",
    style: { fontSize: "5px" },
    priority: 1,
  },

  // {
  //   section: "Type of Event",
  //   type: "Section",
  //   switch: true,
  //   description: "Suitable type of events for this product",
  // },
  // {
  //   section: "Type of Event",
  //   label: "Type of Event",
  //   id: "typeOfEvent",
  //   options: ["Band", "Concert", "DJ", "Standup", "Orchestra", "Big Band", "Theatrical", "Private Event", "Public Event", "Conference"],
  //   defaultValue: [
  //     "Band",
  //     "Concert",
  //     "DJ",
  //     "Standup",
  //     "Orchestra",
  //     "Big Band",
  //     "Theatrical",
  //     "Private Event",
  //     "Public Event",
  //     "Conference",
  //   ],
  //   type: "toggleButton",
  //   style: { fontSize: "5px" },
  //   priority: 1,
  // },

  {
    section: "Preferred Days",
    type: "Section",
    switch: true,
    description: "Set days of availability for this product",
  },
  {
    section: "Preferred Days",
    id: "preferredDays",
    label: "Preferred Days",
    options: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    defaultValue: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    type: "toggleButton",
    priority: 1,
  },

  {
    section: "Standard Running Times",
    type: "Section",
    switch: true,
    description:
      "Set standard running times for this product. ⚠️ NOTE Load in and Load out are the start and end of the event block in your scheduler.",
  },
  {
    section: "Standard Running Times",
    disabled: true,
    type: "StandardTimesSlider",
    id: "StandardTimesSlider",
    switch: true,
    description: "Set standard running times for this product",
  },

  // {
  //   section: "Standard Running Times",
  //   placeholder: "Get In",
  //   label: "Get In",
  //   id: "getIn",
  //   type: "time",
  //   defaultValue: "14:00",
  //   priority: 1,
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Load In",
  //   label: "Load In",
  //   id: "loadIn",
  //   info: "This is the start of the event block in your scheduler.",
  //   type: "time",
  //   defaultValue: "15:00",
  //   priority: 1,
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Soundcheck",
  //   label: "Soundcheck",
  //   id: "soundcheck",
  //   type: "time",
  //   defaultValue: "16:00",
  //   priority: 1,
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Doors",
  //   label: "Doors",
  //   id: "doors",
  //   type: "time",
  //   defaultValue: "18:00",
  //   priority: 1,
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Show",
  //   label: "Show",
  //   id: "show",
  //   type: "time",
  //   defaultValue: "19:00",
  //   priority: 1,
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Load Out",
  //   label: "Load Out",
  //   id: "loadOut",
  //   info: "This is the end of the event block in your scheduler.",
  //   type: "time",
  //   defaultValue: "23:00",
  //   priority: 1,
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Curfew",
  //   label: "Curfew",
  //   id: "curfew",
  //   type: "time",
  //   defaultValue: "01:00",
  //   priority: 1,
  // },

  // {
  //   section: "Pricing",
  //   placeholder: "Price Weekdays",
  //   label: "Price Weekdays",
  //   id: "priceWeekdays",
  //   type: "number",
  //   defaultValue: "0",
  //   priority: 1,
  // },
  // {
  //   section: "Pricing",
  //   placeholder: "Price Weekends",
  //   label: "Price Weekends",
  //   id: "priceWeekends",
  //   type: "number",
  //   defaultValue: "0",
  //   priority: 1,
  // },
  // {
  //   section: "Included Areas",
  //   type: "Section",
  //   switch: true,
  //   description: "Describe accessible areas included in this product",
  // },
  // {
  //   section: "Included Areas",
  //   label: "Specific areas included in this product",
  //   // placeholder: "Description",
  //   id: "includedAreas",
  //   type: "text",
  //   multiline: true,
  //   rows: 4,
  //   style: { minWidth: "500px" },
  //   priority: 1,
  // },
  //   {
  //     section: "Type",
  //     type: "Section",
  //     show: "true",
  //   },
  //   {
  //     section: "Type",
  //     id: "type",
  //     label: "Type",
  //     placeholder: "Type",
  //     options: ["Public", "Conference"],
  //     defaultValue: "Public",
  //     // type: "toggleButton",
  //   },

  //   {
  //     section: "Time Slot",
  //     type: "Section",
  //     show: "true",
  //   },
  //   {
  //     section: "Time Slot",
  //     id: "timeSlot",
  //     label: "Time Slot",
  //     type: "text",
  //     defaultValue: [{ endTime: 18, startTime: 21 }],
  //   },
  // {
  //   section: "Audience",
  //   type: "Section",
  //   show: "true",
  //   description: "Set capacity for this product. If several combinations are available, create several products.",
  //   priority: 1,
  // },
  // {
  //   section: "Audience",
  //   name: "",
  //   placeholder: "Capacity Seated",
  //   label: "Capacity Seated",
  //   id: "capSeated",
  //   type: "number",
  //   defaultValue: "",
  //   priority: 1,
  // },
  // {
  //   section: "Audience",
  //   name: "",
  //   placeholder: "Capacity Standing",
  //   label: "Capacity Standing",
  //   id: "capStanding",
  //   type: "number",
  //   defaultValue: "",
  //   priority: 1,
  // },
  // {
  //   section: "Audience",
  //   name: "",
  //   placeholder: "Capacity Combined",
  //   label: "Capacity Combined",
  //   id: "capacity",
  //   type: "number",
  //   defaultValue: "",
  //   priority: 1,
  // },

  //   {
  //     section: "Stage",
  //     type: "Section",
  //   },
  //   {
  //     section: "Stage",
  //     name: "Stage Width",
  //     placeholder: "Stage Width",
  //     label: "Stage Width",
  //     id: "stageWidth",
  //     type: "number",
  //   },
  //   {
  //     section: "Stage",
  //     name: "Stage Height",
  //     placeholder: "Stage Height",
  //     label: "Stage Height",
  //     id: "stageHeight",
  //     type: "number",
  //   },
  //   {
  //     section: "Stage",
  //     name: "Stage Depth",
  //     placeholder: "Stage Depth",
  //     label: "Stage Depth",
  //     id: "stageDepth",
  //     type: "number",
  //   },
  //   {
  //     section: "Stage",
  //     name: "Clearance Total",
  //     placeholder: "Clearance Total",
  //     label: "Clearance Total",
  //     id: "clearanceTotal",
  //     type: "number",
  //   },
  //   {
  //     section: "Stage",
  //     name: "Clearance to house rig",
  //     placeholder: "Clearance to house rig",
  //     label: "Clearance to house rig",
  //     id: "clearanceRig",
  //     type: "number",
  //   },
  // {
  //   section: "Lights",
  //   type: "Section",
  //   show: "true",
  // },
  // {
  //   section: "Lights",
  //   name: "",
  //   placeholder: "Lights",
  //   label: "Lights",
  //   id: "lights",
  //   type: "textarea",
  // },
  // {
  //   section: "Lights",
  //   name: "addCost",
  //   placeholder: "Lights",
  //   label: "Lights",
  //   id: "lights",
  //   type: "number",
  // },
  // {
  //   section: "Sound Equipment",
  //   type: "Section",
  //   show: "true",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Mixerposition",
  //   label: "Mixerposition",
  //   id: "mixerposition",
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "House PA",
  //   label: "House PA",
  //   id: "housepa",
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Power",
  //   label: "Power",
  //   id: "power",
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Power Points",
  //   label: "Power Points",
  //   id: "powerpoints",
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Main speaker system",
  //   label: "Main speaker system",
  //   id: "mainSpeakerSyst",
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Front Fill",
  //   label: "Front Fill",
  //   id: "frontFill",
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Amplification",
  //   label: "Amplification",
  //   id: "amplification",
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Delay system",
  //   label: "Delay system",
  //   id: "delaySystem",

  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Amp delay system",
  //   label: "Amp delay system",
  //   id: "ampDelaySystem",

  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "FOH mixer/driverack",
  //   label: "FOH mixer/driverack",
  //   id: "FOHmixerDriverack",

  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Monitor mixer",
  //   label: "Monitor mixer",
  //   id: "monitorMixer",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Stagerack/Splitter",
  //   label: "Stagerack/Splitter",
  //   id: "stagerackSplitter",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Signal Stage-FOH",
  //   label: "Signal Stage-FOH",
  //   id: "signalStageFOH",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Analog mixer",
  //   label: "Analog mixer",
  //   id: "analogMixer",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Dynamics",
  //   label: "Dynamics",
  //   id: "dynamics",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Wedges",
  //   label: "Wedges",
  //   id: "wedges",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Sidefills",
  //   label: "Sidefills",
  //   id: "sidefills",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Drumfill subs",
  //   label: "Drumfill subs",
  //   id: "drumfillSubs",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Microphones / DIs",
  //   label: "Microphones / DIs",
  //   id: "microphonesDIs",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Stands",
  //   label: "Stands",
  //   id: "stands",
  //   type: "text",
  // },
  // {
  //   section: "Sound Equipment",
  //   placeholder: "Cables",
  //   label: "Cables",
  //   id: "cables",
  //   type: "text",
  // },
  // {
  //   section: "Rooms",
  //   type: "Section",
  //   show: "true",
  // },
  // {
  //   section: "Rooms",
  //   placeholder: "Dressing Rooms",
  //   label: "Dressing Rooms",
  //   id: "dressingrooms",
  //   type: "number",
  // },
  // {
  //   section: "Rooms",
  //   placeholder: "Production Office",
  //   label: "Production Office",
  //   id: "productionoffice",
  //   type: "number",
  // },
  // {
  //   section: "Rooms",
  //   placeholder: "Catering Area",
  //   label: "Catering Area",
  //   id: "cateringarea",
  //   type: "number",
  // },
  // {
  //   section: "Security",
  //   type: "Section",
  //   show: "true",
  // },
  // {
  //   section: "Security",
  //   placeholder: "Security",
  //   label: "Security",
  //   id: "security",
  //   type: "number",
  //   defaultValue: "0",
  // },
  // {
  //   section: "Security",
  //   placeholder: "Additional Cost",
  //   label: "Additional Cost (each)",
  //   id: "securityCost",
  //   type: "number",
  //   defaultValue: "2000",
  // },
  // {
  //   section: "Security",
  //   placeholder: "Comments",
  //   label: "Comments",
  //   id: "securityComments",
  //   type: "text",
  //   multiline: true,
  //   rows: 4,
  //   style: { minWidth: "300px" },
  // },
  // {
  //   section: "Stage Hands",
  //   type: "Section",
  //   show: "true",
  // },
  // {
  //   section: "Stage Hands",
  //   placeholder: "Stage Hands",
  //   label: "Stage Hands",
  //   id: "stageHands",
  //   type: "number",
  //   defaultValue: "0",
  // },
  // {
  //   section: "Stage Hands",
  //   placeholder: "Additional Cost",
  //   label: "Additional Cost (each)",
  //   id: "stageHandsCost",
  //   type: "number",
  //   defaultValue: "2000",
  // },
  // {
  //   section: "Stage Hands",
  //   placeholder: "Comments",
  //   label: "Comments",
  //   id: "stageHandsComments",
  //   type: "text",
  //   multiline: true,
  //   rows: 4,
  //   style: { minWidth: "300px" },
  // },
  // {
  //   section: "Sound Engineer",
  //   type: "Section",
  //   show: "true",
  // },
  // {
  //   section: "Sound Engineer",
  //   placeholder: "Sound Engineer",
  //   label: "Sound Engineer",
  //   id: "soundEngineer",
  //   type: "number",
  //   defaultValue: "0",
  // },
  // {
  //   section: "Sound Engineer",
  //   placeholder: "Additional Cost (each)",
  //   label: "Additional Cost",
  //   id: "soundEngineerCost",
  //   type: "number",
  //   defaultValue: "2000",
  // },
  // {
  //   section: "Sound Engineer",
  //   placeholder: "Comments",
  //   label: "Comments",
  //   id: "soundEngineerComments",
  //   type: "text",
  //   multiline: true,
  //   rows: 4,
  //   style: { minWidth: "300px" },
  // },

  // {
  // 	section:'Genre',
  // 	placeholder:'Genre',
  //    label: "Genre",
  // 	id:'genre',

  // 	options:['Concert', 'Theater', 'Stand Up', 'Private Event', 'DJ/Club'],
  // 	type:'select'
  // },
];
