/** @format */

//  IMPORTANT TO DO
// Add quantity (at leats 1) if custom features are selected (such as Security, Speakers, etc)

/** @format */

import React, { useEffect, useRef, useState } from "react";
import DisplayObject from "../../helpers/displayObject";
import { db, getBookingById } from "../../firebase";
import { makeNumber, numberFormatted, removePercentage } from "../../helpers/Numbers";
import { useSelector } from "react-redux";
import { set } from "date-fns";
import { Button, TextField } from "@mui/material";
import PdfHandler from "./PdfHandler";
import { createPaymentPostForSettlement } from "../Banking/createPaymentPostForSettlement";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import axios from "axios";

export default function Settlement({ bookingid, remainingToPay, additionalCosts, amountAlreadyPaid }) {
  let test = 0;
  let test2 = [];
  const [bookingData, setBookingData] = useState({});
  //   const [bookingFeatures, setBookingFeatures] = useState({});
  const numberOfColumns = 5;
  const specificationsRef = useRef(null);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [currency, setCurrency] = useState();
  const [sum, setSum] = useState(0);
  const [vatAmounts, setVatAmounts] = useState();
  const [sumVat, setSumVat] = useState(0);
  const [sumOfCostsIncVat, setSumOfCostsIncVat] = useState(0);
  const [sumOfCostsExVat, setSumOfCostsExVat] = useState(0);
  const [amountToBePaid, setAmountToBePaid] = useState(0);
  const [recipient, setRecipient] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [pdfDoc, setPdfDoc] = useState();
  const [OCR, setOCR] = useState();
  const [fees, setFees] = useState();
  const [administrativeFees, setAdministrativeFees] = useState();
  const [settlementFee, setSettlementFee] = useState();
  const [additionalCostsAndFee, setAdditionalCostsAndFee] = useState(0);
  const [ticketsTotal, setTicketsTotal] = useState(0);
  const [agreement, setAgreement] = useState({});

  const [pdfUrl, setPdfUrl] = useState("");

  // 1. fetchBooking and setBookingData
  // 2. Calculate sums and vatAmounts for costs

  //   ========== 1. fetchBooking and setBookingData + OCR ==========
  useEffect(() => {
    const fetchBooking = async () => {
      getBookingById(bookingid).then((data) => {
        setBookingData(data);
      });
    };
    fetchBooking();
  }, [bookingid]);

  useEffect(() => {
    // in order to get OCR
    getAgreement();
    async function getAgreement() {
      if (Object.keys(bookingData).length === 0) return;
      console.log("BOOKINGDATA???", bookingData);
      if (!bookingData.agreementId) return;
      let tmp = {};
      const docSnap = await getDoc(doc(db, "Agreements", bookingData.agreementId));
      tmp = docSnap.data();
      // console.log("agreement", agreement);
      setAgreement(tmp);
      setOCR(tmp.OCR);
      // return docSnap.data();
    }
  }, [bookingData]);

  useEffect(() => {
    getFeesFromPaymentPosts();
  }, [OCR]);

  useEffect(() => {
    async function getStandardFees() {
      // get standard fees from dbAdditionalFees
      const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getAdditionalFees`);
      setSettlementFee(response.data.settlementFee);
      console.log("response", response.data);
    }
    getStandardFees();
  }, []);
  //   ========== 2. Calculate sums and vatAmounts for costs ==========
  useEffect(() => {
    // if (Object.keys(bookingData).length === 0) return;
    console.log("bookingData", bookingData);
    console.log("settlementFee", settlementFee);
    const postRecord = calculateSumsOfCosts();
    if (!postRecord) return;
    console.log("postRecord", postRecord);
    const vatRecord = summarizeByVat(postRecord);
    if (!postRecord) return;
    console.log(vatRecord);

    setCurrency(bookingData.venue.currency);
  }, [Object.keys(bookingData).length !== 0 && settlementFee && administrativeFees]);

  function calculateSumsOfCosts() {
    if (!bookingData || !settlementFee) return; // return if bookingData or settlementFee is not set
    // alert("calculateSumsOfCosts");
    let postRecord = [];
    // alert("calculateSumsOfCosts");
    let sumOfPosts = 0;
    settlementFee && setAdditionalCostsAndFee(additionalCosts + settlementFee.sum);
    if (!bookingData.bookingFeatures) return;
    let bookingFeatures = bookingData.bookingFeatures;

    // First selected bookingFeatures
    Object.values(bookingFeatures).map((feature) => {
      let options = feature.options;
      let sumOfOption = 0;
      console.log("feature", feature);
      Object.values(options).map((option) => {
        console.log("option", option);
        console.log("option.quantity", option.quantity);

        if (option.selected) {
          console.log("option.selected", option.selected, option);
          // sumOfOption = Number(option.price || option.each) * Number(option.quantity);
          sumOfOption = Number(option.each) * Number(option.quantity);
          postRecord.push({
            option: option.option,
            sum: sumOfOption,
            quantity: Number(option.quantity),
            each: Number(option.each),
            climate: option.climate,
            vat: option.vat,
          });
          console.log("💚 sumOfOption A", sumOfOption);
          sumOfPosts += Number(sumOfOption);
          console.log("💛 sumOfPosts A", sumOfPosts);
        }
        // alert(`sumOfOption${option.option} sum: ${sumOfOption} count: ${option.quantity} price: ${option.each} sumOfPost: ${sumOfPosts}`);
      });
    });

    // Add (previous) administrative fees (transactionFee, reminders, etc)
    postRecord.push({
      option: "Administrative Fees",
      sum: administrativeFees * 0.8,
      quantity: 1,
      each: administrativeFees * 0.8,
      climate: "option.climate",
      vat: "25%",
    });
    sumOfPosts += Number(administrativeFees * 0.8); //*0.8 to get the sum without VAT

    // Adding additional costs from bookingSummary to sumOfPosts
    bookingData.summary.forEach((item) => {
      let sumOfOption = 0;
      sumOfOption = Number(item.cost) * Number(item.quantity);
      console.log("summary item", item);
      const cost = Number(item.cost);
      postRecord.push({
        option: item.type,
        sum: sumOfOption,
        quantity: Number(item.quantity) || 1,
        each: cost,
        climate: item.climate,
        vat: item.vat,
      });
      console.log("sumOfOption", sumOfOption);
      sumOfPosts += Number(sumOfOption);
      console.log("🫁 sumOfPosts add", sumOfPosts);
    });

    console.log("bookingData", bookingData);

    setSumOfCostsExVat(sumOfPosts);
    console.log("sumOfPosts", sumOfPosts);
    console.log("postRecord", postRecord);
    // alert(`sumOfPosts: ${sumOfPosts}`);
    return postRecord;
  }

  function summarizeByVat(data) {
    let tmpSum = 0;
    const vatSummary = data.reduce((acc, item) => {
      const vat = Number(item.vat.replace("%", ""));
      const vatAmount = item.sum * (vat / 100);
      tmpSum += vatAmount;
      if (!acc[vat]) {
        acc[vat] = { sum: 0, vat: vat };
      }
      acc[vat].sum += vatAmount;
      return acc;
    }, {});

    console.log("vatSummary", vatSummary);
    setVatAmounts(Object.values(vatSummary));
    setSumVat(tmpSum);
    return Object.values(vatSummary); //return it as an array
  }

  useEffect(() => {
    setPdfDoc(document.querySelector("#settlement") && document.querySelector("#settlement").innerHTML);
  }, [amountToBePaid]);

  useEffect(() => {
    if (!bookingData.ticketSummary) return;
    let ticketTotal = 0;
    if (!bookingData.ticketSummary.sum) ticketTotal = 0;
    if (bookingData.ticketSummary.sum) ticketTotal = bookingData.ticketSummary.sum;
    // setAmountToBePaid(sumOfCostsExVat + sumVat - ticketTotal);
    // setAmountToBePaid(remainingToPay + additionalCosts - ticketTotal);
    setAmountToBePaid(sumOfCostsExVat + sumVat - ticketTotal - amountAlreadyPaid);
    setTicketsTotal(ticketTotal);
    // setSumOfCostsIncVat(sumOfCostsExVat + sumVat);
  }, [sumOfCostsExVat, sumVat]);

  //   ========== Send Settlement ==========

  const handleEmailChange = (email) => {
    // Validate emailaddress before setting it
    function isValidEmail(email) {
      //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return emailRegex.test(email.trim());
    }
    isValidEmail(email) ? setValidEmail(true) : setValidEmail(false);
    setRecipient(email);
  };

  async function SendSettlement() {
    console.log("Send Settlement");
    console.log("recipient", recipient);
    createPaymentPostForSettlement("SETTLEMENT", amountToBePaid, bookingData.agreementId, recipient);
  }

  async function getFeesFromPaymentPosts() {
    if (!OCR) return;
    let tmp = [];
    const q = query(collection(db, "PaymentPosts"), where("OCR", "==", OCR));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data().fees !== undefined && tmp.push({ [doc.data().type]: doc.data().fees });
      doc.data().fees !== undefined &&
        doc.data().fees.map((fee) => {
          tmp.push(fee);
        });
    });
    setFees(...tmp);
    const totalAdministrativeFees = tmp.reduce((acc, fee) => {
      return acc + fee.sum;
    }, 0);
    setAdministrativeFees(totalAdministrativeFees);
    console.log("totalAdministrativeFees", totalAdministrativeFees);
    console.log("tmp fees", tmp);
  }

  // ========== SPECIFICATIONS ==========

  if (bookingData)
    return (
      <div style={{ width: "80%" }}>
        <div
          id="settlement"
          style={{
            color: "black",
            background: "white",
            padding: "50px",
            fontSize: "12px",
            borderCollapse: "collapse",
          }}
        >
          <h2>
            <b>Settlement</b>
          </h2>
          <div ref={specificationsRef} style={{ borderRadius: "10px", background: "#00ffd444", padding: "10px" }}>
            <table style={{ fontSize: "12px", lineHeight: "15px", width: "100%" }}>
              {/* <thead> */}
              {/* <tr>
                <i>{bookingData.product}</i>
              </tr>
              <tr>
                <b>{bookingData.date}</b>
              </tr> */}
              <th>Product</th>
              <th>Quantity</th>

              <th>Amount</th>
              <th>CO2e</th>
              <th style={{ width: "100px" }}>Amount (inc. vat)</th>
              {/* </thead> */}
              <>
                <tr>
                  <td></td>
                </tr>
                {/* <tr style={{ background: "#00000022", padding: "5px" }}>
                  <td></td>
                  <td></td>

                  <td>{bookingData.capacity}</td>
                  <td>{bookingData.co2e}</td>
                  <td></td>
                </tr> */}
                {/* <DisplayObject data={bookingData} /> */}
                <tr style={{ borderTop: "0.1pt solid #000", lineHeight: "15px" }}>
                  <td></td>
                </tr>
                <tr className="categoryHeader" style={{ textTransform: "uppercase", lineHeight: "15px" }}>
                  <td>Ticket Sales</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                {bookingData.tickets &&
                  Object.values(bookingData.tickets).map((item) => (
                    <tr>
                      <td>{item.type}</td>
                      <td>{item.quantity}</td>
                      <td>{item.cost}</td>
                      <td></td>
                      <td>{numberFormatted(item.sum)}</td>
                    </tr>
                  ))}
                <tr style={{ borderTop: "1px solid #ccc", lineHeight: "15px" }}>
                  <td colSpan={numberOfColumns - 1}>Sum Tickets</td>
                  <td>
                    <b>{bookingData.ticketSummary && bookingData.ticketSummary.sum ? numberFormatted(bookingData.ticketSummary.sum) : 0}</b>{" "}
                    {currency}
                  </td>
                </tr>
                <tr style={{ borderTop: "0.1pt solid #000", lineHeight: "15px" }}>
                  <td></td>
                </tr>
                <tr className="categoryHeader" style={{ textTransform: "uppercase", lineHeight: "15px" }}>
                  <td>Costs</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {bookingData.bookingFeatures &&
                  Object.values(bookingData.bookingFeatures).map((feature) => (
                    <>
                      {/* Show rental fee first */}
                      {Object.values(feature.options).map(
                        (option) =>
                          !option.optional &&
                          option.selected &&
                          feature.additional === "Rental Fee" && (
                            <tr style={{ lineHeight: "15px" }}>
                              <td>{feature.additional}</td>
                              <td>{1}</td>
                              <td>{option.price}</td>
                              <td>{option.climate ? option.climate : "..."}</td>
                              <td>{numberFormatted(option.price * (1 + removePercentage(option.vat) / 100))}</td>
                            </tr>
                          )
                      )}
                    </>
                  ))}

                {bookingData.bookingFeatures &&
                  Object.values(bookingData.bookingFeatures).map((feature) => (
                    <>
                      {Object.values(feature.options).map((option) => (
                        <>
                          {/* customer opted features */}
                          {option.customerOpted && option.price && (
                            <tr style={{ lineHeight: "15px" }}>
                              <td style={{ opacity: "1" }}>{feature.additional}</td>
                              <td colspan={numberOfColumns - 3}>{option.option}</td>
                              <td>{option.climate ? option.climate : "-"}</td>
                              <td>{numberFormatted(option.price * (1 + removePercentage(option.vat) / 100))}</td>
                            </tr>
                          )}
                          {/* Included features with a cost */}
                          {!option.optional &&
                            option.selected &&
                            feature.additional !== "Staff" &&
                            feature.additional !== "Capacity" &&
                            feature.additional !== "Rental Fee" &&
                            feature.additional !== "Stage" && (
                              <tr style={{ lineHeight: "15px" }}>
                                {console.log("this option", option)}
                                <td style={{ opacity: "1" }}>
                                  {option.option}
                                  {option.info ? `, ${option.info}` : ""}
                                  {option.specs ? `, ${option.specs}` : ""}
                                </td>
                                <td>{option.quantity}</td>
                                <td>{!option.each ? option.price : option.each}</td>
                                <td>{option.climate ? option.climate : "..."}</td>
                                {/* <td>{numberFormatted(option.price * (1 + removePercentage(option.vat) / 100))}</td> */}
                                <td>{numberFormatted(option.quantity * option.each * (1 + removePercentage(option.vat) / 100))}</td>
                              </tr>
                            )}
                          {/* indluded features */}
                          {/* <DisplayObject data={option} /> */}
                          {option.selected && option.optional && feature.additional !== "Staff" && (
                            <tr style={{ lineHeight: "15px" }}>
                              <td colspan={numberOfColumns - 1}>
                                <span style={{ opacity: "0.4" }}>{feature.additional}: </span>
                                <span>{option.option}</span>
                                <span>{option.price === 0 || (!option.price && !option.optional && " (Included)")}</span>
                              </td>
                              <td>{option.optional ? <span style={{ opacity: "0.4" }}>(optional)</span> : option.price}</td>
                            </tr>
                          )}
                        </>
                      ))}
                    </>
                  ))}
              </>
              {/* AdministrativeFees */}

              <tr>
                <td>Administrative Fees</td>
                <td></td>
                <td>{administrativeFees * 0.8}</td>
                <td></td>
                <td>{administrativeFees}</td>
              </tr>

              {/* <tr style={{ borderTop: "1px solid #ccc", lineHeight: "15px" }}>
                <td>Remaining to pay</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <b>{remainingToPay === 0 ? 0 : numberFormatted(remainingToPay)}</b> {currency}
                </td>
              </tr> */}

              {/* {props.climateCompensation && (
            <>
              <tr style={{ borderTop: "0.1pt solid #ccc" }}>
                                <th>Additional</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr>
              <tr style={{ borderTop: "1pt solid transparent" }}>
                                <td colspan="10">CO2e</td>
                                <td>{numberFormatted(Math.round(sumIncVat * 0.035))} kg</td>
                              </tr>
            </>
          )} */}
              {additionalCosts > 1 && (
                <>
                  <tr style={{ borderTop: "0.1pt solid #000", lineHeight: "15px" }}>
                    <td></td>
                  </tr>
                  <tr className="categoryHeader" style={{ textTransform: "uppercase", lineHeight: "15px" }}>
                    <td>Additional Costs</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {bookingData.summary &&
                    bookingData.summary.map((item) => (
                      <tr>
                        <td>{item.type}</td>
                        <td>{item.quantity}</td>
                        <td>{item.cost}</td>
                        <td></td>
                        <td>{item.sum}</td>
                      </tr>
                    ))}
                  {/* <tr>
                    <td>Settlement Fee</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{settlementFee && settlementFee.sum}</td>
                  </tr> */}
                  {/* <tr style={{ borderTop: "1px solid #ccc", lineHeight: "15px" }}>
                    <td>Sum Additional Costs</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>{numberFormatted(additionalCosts)}</b> {currency}
                    </td>
                  </tr> */}
                </>
              )}
              <tr style={{ borderTop: "0.1pt solid #000", lineHeight: "15px" }}>
                <td></td>
              </tr>
              <tr className="categoryHeader" style={{ textTransform: "uppercase", lineHeight: "15px" }}>
                <td>Total Sum Costs</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ borderTop: "1px solid #ccc", lineHeight: "15px" }}>
                <td>Sum Costs</td>
                <td></td>
                <td>
                  {numberFormatted(sumOfCostsExVat)} {currency}
                </td>
                <td></td>
                <td>
                  {numberFormatted(sumOfCostsExVat + sumVat)} {currency}
                </td>
              </tr>
              {/* {Object.keys(vatAmounts).map(
                //Map through the vat amounts
                (obj) => (
                  <tr style={{ lineHeight: "15px" }}>
                    <td colSpan={numberOfColumns - 1}>vat({obj}%)</td>
                    <td>{numberFormatted(vatAmounts[obj])}</td>
                  </tr>
                )
              )} */}
              {vatAmounts &&
                vatAmounts.map((vat) => (
                  <tr>
                    <td>Sum Vat ({vat.vat}%)</td>
                    <td></td>
                    <td>{numberFormatted(vat.sum)}</td>
                    {/* <td>{numberFormatted(vatAmounts[vat.vat].sum)}</td> */}
                  </tr>
                ))}
              <tr>
                <td></td>
              </tr>
              <tr style={{ borderTop: "0.1pt solid #000", lineHeight: "15px" }}>
                <td></td>
              </tr>
              {amountAlreadyPaid && (
                <tr>
                  <td>Amount paid</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{amountAlreadyPaid}</td>
                </tr>
              )}
              <tr>
                <td>Tickets total</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{ticketsTotal}</td>
              </tr>
              <tr style={{ lineHeight: "15px", background: "#ffffff99" }}>
                <td style={{ textTransform: "uppercase" }}>
                  <b>{amountToBePaid < 0 ? "Amount to recieve" : "Amount to pay"}</b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ width: "50pt" }}>
                  <b>{amountToBePaid < 0 ? numberFormatted(-amountToBePaid) : numberFormatted(amountToBePaid)}</b> {currency}
                </td>
              </tr>
            </table>
          </div>
          {amountToBePaid > 0 && (
            <table style={{ padding: "15px", marginTop: "50px" }}>
              <tr>
                <b>Make Payment to</b>
              </tr>
              <tr>
                <td>Bg 363-0274</td>
              </tr>
              <tr>
                <td>OCR: {OCR}</td>
              </tr>
              <tr>
                <td>
                  Amount: {numberFormatted(amountToBePaid)} {currency}
                </td>
              </tr>
            </table>
          )}
        </div>
        {amountToBePaid && (
          <div style={{ display: "flex", gap: "15px", padding: "15px" }}>
            <TextField
              defaultValue={bookingData.agent ? bookingData.agent.email : ""}
              size="small"
              label="Recipient"
              placeholder="Enter Email"
              onChange={(e) => handleEmailChange(e.target.value)}
            />
            <Button onClick={() => SendSettlement} disabled={!validEmail}>
              Send Settlement
            </Button>
            {numberFormatted(amountToBePaid) !== null && (
              <PdfHandler
                pdfDoc={document.querySelector("#settlement") && document.querySelector("#settlement").innerHTML}
                setPdfUrl={setPdfUrl}
                includedAttachments={[]}
                agreementId={bookingData.agreementId}
                agreement={agreement}
              />
            )}
            {amountToBePaid > 0 && <Button onClick={() => SendSettlement("venuePay")}>Send Invoice</Button>}
            {amountToBePaid < 0 && <Button onClick={() => SendSettlement("arrangerPay")}>Make Payment</Button>}

            {/* <div
              id="pdfContent"
              dangerouslySetInnerHTML={{
                __html: pdfDoc,
              }}
            /> */}
          </div>
        )}

        {/* <DisplayObject data={bookingData} /> */}
      </div>
    );
}
