/** @format */

import { useEffect, useRef, useState } from "react";
import React from "react";
import { signup, login, logout, useAuth } from "./firebase";
import "./App.css";
import Header from "./components/Header";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const darkTheme = createTheme({
    root: {
      background: "transparent",
    },
    select: {
      background: "transparent",
      width: "100%",
    },
    customBadge: {
      backgroundColor: (props) => props.color,
      color: "white",
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#02ffd4",
        dark: "#03bd98",
        light: "#6effff",
        contrastText: "#000000",
        // main: "#ffd800",
        // dark: "#eb6414",
        // light: "#00ffd4",
        // contrastText: "#000000",
      },
      secondary: {
        main: "#184c5c",
        dark: "#487889",
        light: "#002432",
        contrastText: "#000000",
      },
    },
  });

  const lightTheme = createTheme({
    customBadge: {
      backgroundColor: (props) => props.color,
      color: "#fodcca",
    },
    palette: {
      mode: "light",
      primary: {
        main: "#ffd800",
        dark: "#eb6414",
        light: "#00ffd4",
        contrastText: "#000000",
      },
      secondary: {
        main: "#00ffd4",
        dark: "#004343",
        contrastText: "#000000",
      },
    },
  });
  const [tema, setTema] = useState(darkTheme);
  const themeMode = useSelector((state) => state.counter.themeMode);

  useEffect(() => {
    themeMode == "darkTheme" ? setTema(darkTheme) : setTema(lightTheme);
  }, [themeMode]);

  return (
    <div>
      {/* <div style={{ width: "100vw", height: "100vh", border: "20px dashed yellow", position: "absolute", zIndex: "1000000000000000000" }}>
        Beta version for testing purposes
      </div> */}
      <ToastContainer />
      <ThemeProvider theme={tema}>
        <CssBaseline />

        <div>
          <div className="workInProgress">
            {/* <img
              src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2FUC-bottom.png?alt=media&token=e00a22c4-532a-47df-b049-588f89ac601a"
              width="100%"
              height="auto"
            /> */}
          </div>
          <div className="workInProgressLabel">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2FUC.png?alt=media&token=b96093e5-c660-4971-bc2f-4eb48d44dbf8"
              width="100%"
              height="auto"
            />
          </div>
          <Grid container>
            <Grid item>
              <Header />
            </Grid>
          </Grid>
          <Grid container></Grid>
        </div>
      </ThemeProvider>
    </div>
  );
}
