/** @format */

import React from "react";
import { numberFormatted } from "./Numbers";

function ObjectSize({ dataObject, name }) {
  // Function to calculate the size of the object
  const calculateSize = (obj) => {
    const jsonString = JSON.stringify(obj);
    return new Blob([jsonString]).size;
  };
  // Calculate the size
  const size = calculateSize(dataObject);

  return (
    <div>
      <span>{name}: </span>
      <span style={{ color: size > 10000 ? (size > 50000 ? "red" : "orange") : "green" }}> {numberFormatted(size / 1000)} kb</span>
    </div>
  );
}

export default ObjectSize;
