/** @format */

import axios from "axios";
export const cancelFinsharkSubscription = async (subscriptionId) => {
  try {
    const response = await axios.get(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/finsharkCancelSubscription?paymentId=${subscriptionId}`
    );
    console.log("canceled", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
