/** @format */

export function downloadDataForBackup(data) {
  // Step 1: Convert the data to a JSON string
  const jsonString = JSON.stringify(data, null, 2); // Pretty print with 2 spaces

  // Step 2: Create a Blob from the JSON string
  const blob = new Blob([jsonString], { type: "application/json" });

  // Step 3: Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Step 4: Create an anchor tag to download the blob
  const downloadElement = document.createElement("a");
  downloadElement.href = url;
  downloadElement.download = "data.json"; // Name of the file to be downloaded
  document.body.appendChild(downloadElement); // Append to body

  // Step 5: Trigger the download
  downloadElement.click();

  // Step 6: Clean up by removing the anchor tag and revoking the blob URL
  document.body.removeChild(downloadElement);
  URL.revokeObjectURL(url);
}

// Usage: Assuming `data` is your data object or array
// downloadDataForBackup(data);
