/** @format */

export function calculateEmissionsBasedOnDistance(distanceKm) {
  const emissionFactors = {
    car: 220, // g CO2e/passenger-km
    bus: 60, // g CO2e/passenger-km
    train: 50, // g CO2e/passenger-km
    bicycle: 0, // g CO2e/passenger-km
    plane: 150, // g CO2e/passenger-km
  };

  let emissionsKg = 0;
  let carDistance = 0;
  let bicycleDistance = 0;
  let busDistance = 0;
  let trainDistance = 0;
  let planeDistance = 0;

  if (distanceKm < 10) {
    // Calculate emissions based on distribution
    carDistance = distanceKm * 0.05;
    bicycleDistance = distanceKm * 0.15;
    busDistance = distanceKm * 0.2;
    trainDistance = distanceKm * 0.6;
    planeDistance = distanceKm * 0.0;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  }
  if (distanceKm < 80 && distanceKm >= 10) {
    // Calculate emissions based on distribution
    carDistance = distanceKm * 0.2;
    bicycleDistance = distanceKm * 0.0;
    busDistance = distanceKm * 0.2;
    trainDistance = distanceKm * 0.5;
    planeDistance = distanceKm * 0.0;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  }
  if (distanceKm >= 80 && distanceKm < 700) {
    // Calculate emissions based on distribution
    carDistance = distanceKm * 0.3;
    bicycleDistance = distanceKm * 0.0;
    busDistance = distanceKm * 0.1;
    trainDistance = distanceKm * 0.6;
    planeDistance = distanceKm * 0.0;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  }
  if (distanceKm >= 700) {
    // Calculate emissions based on distribution
    carDistance = distanceKm * 0.1;
    bicycleDistance = distanceKm * 0.0;
    busDistance = distanceKm * 0.1;
    trainDistance = distanceKm * 0.3;
    planeDistance = distanceKm * 0.5;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  } else {
    // Calculate emissions based on entire distance by car
    emissionsKg = (distanceKm * emissionFactors.car) / 1000;
  }

  return { impact: emissionsKg, carDistance, bicycleDistance, busDistance, trainDistance, planeDistance };
}

// Example usage
// const distance = 8; // km
// const emissions = calculateEmissions(distance);
// console.log(`Emissions for traveling ${distance} km: ${emissions.toFixed(2)} kg CO2e`);
