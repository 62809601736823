/** @format */

/** @format */

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  Popover,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productObj } from "../../dbObjects.js/dbProducts";
import { storeCurrentProduct, storeCurrentVenue } from "../../features/venueFilter/venueFilterSlice";
import { createId, db } from "../../firebase";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import AreYouSureModal from "../UI helpers/AreYouSureModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SettingsAccessibilitySharp } from "@mui/icons-material";
import { set } from "date-fns";
import { makeNumber, numberFormatted } from "../../helpers/Numbers";
import ProductFeatures from "./ProductFeatures";
import ProductSummary from "./ProductSummary";
import StandardTimesSlider from "../MiscUIComponents/StandardTimesSlider";
import VenueAdditionals from "../MiscUIComponents/VenueAdditionals";
import ObjectSize from "../../helpers/ObjectSize";
import { privateIcon, publicIcon } from "../../helpers/customIcons";
import DayOfTheWeekMini from "../UI helpers/DayOfTheWeekMini";
import shortText from "../../helpers/shortText";
import DayOfTheWeekMicro from "../UI helpers/DayOfTheWeekMicro";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: "80%",
  width: { xs: "95%", sm: "90%" },
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: { xs: 1, sm: 4 },
};

const ProductsWithFeatures = ({ setValue, formData, setFormData }) => {
  const superOwner = useSelector((state) => state.counter.superOwner);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const [productData, setProductData] = useState(["product1", "productid1"]);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const dispatch = useDispatch();
  const [typeOfEvent, setTypeOfEvent] = React.useState(() => ["Concert"]);
  const [privatePublic, setPrivatePublic] = React.useState(() => ["Public"]);
  const [preferredDays, setPreferredDays] = React.useState(() => ["Friday", "Saturday"]);
  const [showSection, setShowSection] = useState({
    Product: true,
    "Type of Event": true,
    "Standard Running Times": true,
    Pricing: true,
    "Preferred Days": true,
  });
  const [AYS, setAYS] = useState(null);
  const [showAYS, setShowAYS] = useState(false);
  // const sortedProducts = Object.values(currentVenue.products).sort((b, a) => {
  //   // Sort by privatePublic first
  //   if (a.privatePublic !== b.privatePublic) {
  //     return a.privatePublic.localeCompare(b.privatePublic);
  //   }
  //   // If privatePublic is equal, sort by loadIn
  //   return a.loadIn - b.loadIn;
  // });
  const sortedProducts = Object.values(currentVenue.products).sort((b, a) => {
    // First, sort by privatePublic (assuming "Public" should come after "Private")
    if (a.privatePublic < b.privatePublic) return -1;
    if (a.privatePublic > b.privatePublic) return 1;
    // If privatePublic is the same, sort by loadIn (date string)
    if (a.loadIn < b.loadIn) return -1;
    if (a.loadIn > b.loadIn) return 1;
    return 0; // If all are equal
  });
  const dayIndexMap = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };

  // const sortedProducts = Object.values(currentVenue.products).sort((b, a) => {
  //   return a.privatePublic.localeCompare(b.privatePublic);
  // });

  function changeProduct(productid) {
    // console.log("E", e);
    // const productid = e.target.value;
    if (productid === "newProduct") return createProduct();
    let tmpData = [];
    tmpData = currentVenue.products.find((product) => product.productid === productid);

    setProductData({ ...tmpData });
    setShowSection(tmpData.sectionsToShow);
    dispatch(storeCurrentProduct(tmpData));
    setOpen(true);
  }

  async function createProduct() {
    // alert("saving");
    setProductData([]);
    const newID = await createId();
    setProductData({
      preferredDays: preferredDays,
      product: "",
      productid: newID,
      sectionsToShow: {
        Product: true,
        "Standard Running Times": true,
        "Type of Event": true,
        Pricing: true,
        "Preferred Days": true,
        Audience: true,
      },
      privatePublic: "Public Event",
    });
    setOpen(true);
  }

  function handleChange(key, value, type) {
    if (type === "number") {
      setProductData((prev) => ({ ...prev, [key]: numberFormatted(value) }));
    } else {
      setProductData((prev) => ({ ...prev, [key]: value }));
    }
  }

  const handleTypeOfEvent = (event, newTypes) => {
    setTypeOfEvent(newTypes);
    setProductData((prev) => ({ ...prev, typeOfEvent: newTypes }));
  };

  const handlePrivatePublic = (event, newTypes) => {
    setPrivatePublic(newTypes);
    setProductData((prev) => ({ ...prev, privatePublic: newTypes }));
  };

  const handlePreferredDays = (event, newDays) => {
    setPreferredDays(newDays);
    setProductData((prev) => ({ ...prev, preferredDays: newDays }));
  };

  async function submitProduct() {
    console.log("Current Venue Products", currentVenue.products);
    let tmpProducts = [];
    {
      currentVenue.products ? (tmpProducts = [...currentVenue.products]) : (tmpProducts = []);
    }
    console.log(tmpProducts);
    console.log(productData);

    //Check if productid already exists, in that case first remove that post and then store the current.
    await tmpProducts.map((prod) => {
      if (prod.productid === productData.productid) {
        const index = tmpProducts.findIndex((product) => product.productid === prod.productid);
        tmpProducts.splice(index, 1);
      }
    });
    //If currentVenue doesn't hold any products yet...
    if (!currentVenue.products) {
      tmpProducts = [productData];
    }
    // ...and if currentVenue already has some products, then bring them along
    else {
      tmpProducts = [...tmpProducts, productData];
    }
    // update venue with new product in db
    console.log("product data to be stored", tmpProducts);
    await updateDoc(doc(db, "Venues", currentVenue.venueid), {
      products: tmpProducts,
    });

    // and in order to show up in products menu we store it to redux instead of reloading the venue.
    let tmpVenue = { ...currentVenue };
    tmpVenue["products"] = tmpProducts;
    dispatch(storeCurrentVenue(tmpVenue));
    handleClose();
  }

  async function deleteProduct(id) {
    function wait() {
      setTimeout(() => {}, 500);
    }

    if (AYS === false) {
      setShowAYS(false);
      setAYS(null);
    }

    if (AYS === true) {
      let tmpProducts = [...currentVenue.products];
      const index = tmpProducts.findIndex((product) => product.productid === id);
      tmpProducts.map((tmp) => {});

      // Check that the productid exists to avoid otherwise the last in the list is removed.
      let result = tmpProducts.find((object) => object.productid === id);
      result !== undefined && tmpProducts.splice(index, 1);

      // update venue in db Venues without the removed product
      await updateDoc(doc(db, "Venues", currentVenue.venueid), {
        products: tmpProducts,
      });

      // and in order to show up in products menu we store it to redux instead of reloading the venue.
      let tmpVenue = { ...currentVenue };
      tmpVenue["products"] = tmpProducts;
      dispatch(storeCurrentVenue(tmpVenue));
      setShowAYS(false);
      setAYS(null);
    }
    if (AYS === null) {
      wait();
    }
  }
  // const nom = 2000;
  // const optionalSection = (section) => {
  //   const optionals = ["Stage Hands", "Security", "Sound Engineer", "Pricing"];
  //   if (optionals.includes(section)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const productOrProducts = currentVenue.products && currentVenue.products.length > 1 ? " products" : " product";

  // HandleOnFocus (select all)
  function handleOnFocus(event) {
    event.target && event.target.select();
  }

  // handleOnFocus (select all) END

  // ========== Back up Sets ==
  useEffect(() => {
    if (!currentVenue.venueid) return;
    console.log("getting sets for", currentVenue.venue);
    getSets();
  }, [currentVenue]);

  const [backedUpSets, setBackedUpSets] = useState([]);
  const [setIndex, setSetIndex] = useState(0);

  const getSets = async () => {
    console.log("currentVenue.venueid", currentVenue.venueid);
    // const docSnap = await getDoc(doc(db, "ProductsAndModules", currentVenue.venueid));
    // const docSnap = await getDoc(doc(db, "ProductsAndModules", "wjrbwst2ttgcxv3s6ptyqphqqr2k7kk5"));
    const docSnap = await getDoc(doc(db, "ProductsAndModules", currentVenue.venueid));

    if (docSnap.exists()) {
      console.log("Set data:", docSnap.data());
      Object.values(docSnap.data()).map((set, index) => {
        console.log(set);
      });
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    docSnap.data() && setBackedUpSets(Object.values(docSnap.data()));
  };

  const restoreSet = async () => {
    console.log("setIndex", setIndex);
    console.log("backedUpSets", backedUpSets[setIndex].products);
    setProductData(backedUpSets[setIndex].products);
    dispatch(storeCurrentProduct(backedUpSets[setIndex].products));
    // Set the currentVenue.products to the backed up set
    let tmp = JSON.parse(JSON.stringify(currentVenue));
    tmp.products = backedUpSets[setIndex].products;
    tmp.features = backedUpSets[setIndex].features;
    dispatch(storeCurrentVenue(tmp));
  };

  const storeSet = async () => {
    console.log(currentVenue.venueid);
    console.log("backedUpSets", Object.values(backedUpSets));
    const setToStore = [...Object.values(backedUpSets)];
    setToStore.push({ features: currentVenue.features, products: currentVenue.products });

    await setDoc(doc(db, "ProductsAndModules", currentVenue.venueid), {
      ...setToStore,
    });
  };
  // ========== Back up Sets == END

  // private/public icons
  // const publicIcon = (
  //   <div
  //     style={{
  //       color: "#00ffd499",
  //       fontSize: "10px",
  //       border: "1px solid #00ffd499",
  //       background: "transparent",
  //       borderRadius: "5px",
  //       padding: "0px 5px",
  //       marginRight: "5px",
  //     }}
  //   >
  //     Public
  //   </div>
  // );
  // const privateIcon = (
  //   <div style={{ color: "black", fontSize: "10px", background: "#00ffd499", borderRadius: "5px", padding: "0px 5px", marginRight: "5px" }}>
  //     Private
  //   </div>
  // );

  // private/public icons END

  useEffect(() => {
    setProductsLoaded(true);
  }, [currentVenue]);

  useEffect(() => {}, [productData, showSection, typeOfEvent]);

  useEffect(
    () => {
      deleteProduct(productData.productid);
    },
    AYS,
    [showAYS === true]
  );

  const [showDetail, setShowDetail] = useState(["privatePublic", "price", "preferredDays", "timeSlot"]);
  const handleShowDetail = (event, newDetail) => {
    console.log("newDetail", newDetail);
    setShowDetail(newDetail);
  };
  return (
    <Grid container sx={12} spacing={4}>
      {console.log("currentVenue.products", currentVenue.products)}
      <Grid item xs={12}>
        {console.log("ShowDetail", showDetail)}
        <ToggleButtonGroup value={showDetail} onChange={handleShowDetail} size="small">
          <ToggleButton value="privatePublic">
            {publicIcon}
            {privateIcon}
          </ToggleButton>
          <ToggleButton value="price">Price</ToggleButton>
          <ToggleButton value="capacity">Capacity</ToggleButton>
          <ToggleButton value="preferredDays">Day of the Week</ToggleButton>
          <ToggleButton value="timeSlot">Time Slot</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item sx={6}>
        <table>
          {/* <Select
            size="small"
            xs={12}
            sm={4}
            onChange={(e) => changeProduct(e)}
            style={{ width: "500px" }}
            defaultValue=""
            displayEmpty
            inputProps={{ "aria-label": currentVenue.products && currentVenue.products.length }}
          > */}
          {currentVenue.products &&
            // currentVenue.products.map((product) => (
            sortedProducts.map((product) => (
              <MenuItem
                key={product.productid}
                value={product.productid}
                style={{ width: "auto", display: "flex", gap: "10px" }}
                onClick={() => changeProduct(product.productid)}
              >
                <tr>
                  {/* <td>
                    <EditIcon fontSize="inherit" className="editProduct" onClick={() => setOpen(true)} />
                  </td> */}
                  {showDetail.includes("privatePublic") && (
                    <td style={{ width: "80px" }}>{product.privatePublic === "Public Event" ? publicIcon : privateIcon}</td>
                  )}
                  <td style={{ width: "150px" }}>{product.product}</td>
                  {showDetail.includes("price") && (
                    <td style={{ opacity: "0.3", padding: "5px", fontSize: "12px", width: "80px" }}>
                      Price: {product.price} {currentVenue.currency}
                    </td>
                  )}
                  {showDetail.includes("capacity") && (
                    <td style={{ opacity: "0.3", padding: "5px", fontSize: "12px", width: "80px" }}>Cap: {product.capacity}</td>
                  )}
                  {showDetail.includes("preferredDays") && (
                    <td>{product.preferredDays && <DayOfTheWeekMicro preferredDays={product.preferredDays} />}</td>
                  )}
                  {showDetail.includes("timeSlot") && (
                    <td style={{ opacity: "0.3", padding: "5px", fontSize: "12px", width: "80px", color: "#00ffd4" }}>
                      {product.loadIn} - {product.loadOut}
                    </td>
                  )}
                </tr>
              </MenuItem>
            ))}

          <MenuItem value="" disabled style={{ textTransform: "lowercase" }}>
            {currentVenue.products ? currentVenue.products.length + productOrProducts : "No products yet..."}
          </MenuItem>
          <MenuItem key={"newProduct"} value={"newProduct"} onClick={() => changeProduct("newProduct")}>
            <b>+ Create new Product</b>
          </MenuItem>
          {/* </Select> */}
        </table>
        {superOwner && (
          <Grid item>
            <Button onClick={() => storeSet()}>
              Store current set <ObjectSize dataObject={{ features: currentVenue.features, products: currentVenue.products }} />
            </Button>
            <Select size="small" value={setIndex} onChange={(e) => setSetIndex(e.target.value)}>
              {backedUpSets &&
                backedUpSets.map((item, index) => (
                  <MenuItem key={index} value={index}>
                    {index}
                  </MenuItem>
                ))}
            </Select>
            <Button onClick={() => restoreSet()}>Restore set</Button>
          </Grid>
        )}
      </Grid>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid container xs={12} overflow="hidden" className="productModal">
            <Grid item xs={12} sm={12}>
              {/* <h2>{productData.product}</h2> */}
              <h2>Product Builder</h2>
            </Grid>
            <Grid item xs={12} sm={9} style={{ height: "71vh" }} className="productsWithFeatures">
              <Grid item xs={12}>
                <Grid container xs={12} style={{ display: "flex", gap: "15px" }}>
                  {productObj.map((formfield) => (
                    <>
                      {formfield.type === "Section" && (
                        <div style={{ width: "100%" }}>
                          <description>{formfield.description}</description>
                        </div>
                      )}

                      {formfield.type !== "Section" && formfield.type === "number" && formfield.priority < 10 && (
                        <Grid item style={{ flexWrap: "wrap" }}>
                          <TextField
                            {...formfield}
                            type="text"
                            value={productData[formfield.id] ? productData[formfield.id] : formfield.defaultValue}
                            onChange={(e) => handleChange(formfield.id, e.target.value, "number")}
                            size="small"
                            style={{ width: "150px" }}
                            onFocus={handleOnFocus}
                          />
                        </Grid>
                      )}
                      {formfield.type !== "Section" &&
                        formfield.type === "text" &&
                        showSection[formfield.section] &&
                        formfield.priority < 10 && (
                          <Grid item style={{ flexWrap: "wrap" }}>
                            <TextField
                              {...formfield}
                              value={productData[formfield.id] ? productData[formfield.id] : formfield.defaultValue}
                              onChange={(e) => handleChange(formfield.id, e.target.value)}
                              size="small"
                              style={{
                                width: "150px",
                                background: (formfield.id === "loadIn" || formfield.id === "loadOut") && "#00ffd422",
                                borderRadius: "10px",
                              }}
                            />
                            {!productData[formfield.id] && handleChange(formfield.id, formfield.defaultValue)}
                          </Grid>
                        )}
                      {formfield.type !== "Section" &&
                        formfield.type === "time" &&
                        showSection[formfield.section] &&
                        formfield.priority < 10 && (
                          <Grid item style={{ flexWrap: "wrap" }}>
                            <TextField
                              {...formfield}
                              value={productData[formfield.id] ? productData[formfield.id] : formfield.defaultValue}
                              onChange={(e) => handleChange(formfield.id, e.target.value)}
                              size="small"
                              style={{
                                width: "150px",
                                background: (formfield.id === "loadIn" || formfield.id === "loadOut") && "#00ffd422",
                                borderRadius: "10px",
                              }}
                            />
                            {!productData[formfield.id] && handleChange(formfield.id, formfield.defaultValue)}
                          </Grid>
                        )}
                      {formfield.type !== "Section" &&
                        formfield.type === "textarea" &&
                        showSection[formfield.section] &&
                        formfield.priority < 10 && (
                          <Grid item style={{ flexWrap: "wrap" }}>
                            <TextField
                              {...formfield}
                              inputProps={{
                                maxLength: formfield.maxLength, // Set the maximum length to 10 characters
                              }}
                              value={productData[formfield.id]}
                              onChange={(e) => handleChange(formfield.id, e.target.value)}
                              size="small"
                              style={{ width: "300px" }}
                            />
                          </Grid>
                        )}
                      {formfield.type !== "Section" && formfield.id === "StandardTimesSlider" && (
                        <Grid item xs={12} style={{ flexWrap: "wrap" }}>
                          <StandardTimesSlider productData={productData} setProductData={setProductData} TextFields />
                        </Grid>
                      )}
                      {/* {formfield.type !== "Section" &&
                        formfield.id === "typeOfEvent" &&
                        showSection[formfield.section] &&
                        formfield.priority < 10 && (
                          <Grid px={2} style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                            <ToggleButtonGroup
                              value={productData.typeOfEvent ? productData.typeOfEvent : formfield.defaultValue}
                              defaultValue={formfield.defaultValue}
                              onChange={handleTypeOfEvent}
                              aria-label="type"
                            >
                              {formfield["options"].map((option) => (
                                <ToggleButton value={option} aria-label="option" size="small" className="eventType">
                                  {option}
                                </ToggleButton>
                              ))}
                            </ToggleButtonGroup>
                          </Grid>
                        )} */}
                      {
                        // formfield.type !== "Section" &&
                        formfield.id === "privatePublic" && formfield.priority < 10 && (
                          <Grid style={{ display: "flex", gap: "10px" }}>
                            <ToggleButtonGroup
                              exclusive
                              value={productData.privatePublic ? productData.privatePublic : formfield.defaultValue}
                              defaultValue={formfield.defaultValue}
                              onChange={handlePrivatePublic}
                              aria-label="type"
                            >
                              {formfield["options"].map((option) => (
                                <ToggleButton value={option} aria-label="option" size="small" className="eventType">
                                  {option}
                                </ToggleButton>
                              ))}
                            </ToggleButtonGroup>
                          </Grid>
                        )
                      }
                      {formfield.type !== "Section" && formfield.id === "preferredDays" && formfield.priority < 10 && (
                        <Grid style={{ display: "flex", gap: "10px" }}>
                          <ToggleButtonGroup
                            value={productData.preferredDays ? productData.preferredDays : formfield.defaultValue}
                            onChange={handlePreferredDays}
                            aria-label="days"
                          >
                            {formfield["options"].map((option) => (
                              <ToggleButton
                                value={option}
                                defaultValue={formfield.defaultValue}
                                aria-label="option"
                                size="small"
                                className="eventType"
                              >
                                {option}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                overflow="scroll"
                mt={8}
                // style={{
                //   padding: "10px",
                //   borderRadius: "5px",
                //   border: "1px solid #00ffd433",
                //   width: "720px",
                // }}
              >
                <h7>Modules</h7>
                <ProductFeatures productData={productData} setProductData={setProductData} setValue={setValue} />
                {/* <VenueAdditionals formData={formData} setFormData={setFormData} /> */}
              </Grid>
              <Grid item my={4} style={{ display: "flex", gap: "10px" }}>
                <Button variant="contained" onClick={() => submitProduct()}>
                  <SaveIcon style={{ fontSize: "14px", marginRight: "10px" }} /> Save product
                </Button>
                <Button variant="outlined" onClick={() => handleClose()}>
                  <ArrowBackIosIcon style={{ fontSize: "14px", marginRight: "10px" }} /> Close
                </Button>
                <Button
                  onClick={() => {
                    setShowAYS(true);
                  }}
                >
                  <DeleteIcon fontSize="inherit" className="deleteProduct" /> Remove
                </Button>
                {showAYS && (
                  <AreYouSureModal setAYS={setAYS} showAYS={true} msg={`You are about to delete the product ${productData.product}.`} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} p={1} style={{}} className="productSummary" style={{ overflow: "scroll" }}>
              <ProductSummary productData={productData} currentVenue={currentVenue} setProductData={setProductData} />
              {/* <div style={{ border: "1px solid #00ffd422", borderRadius: "5px", padding: "10px" }}>
                <h7>Modules</h7>
                <VenueAdditionals formData={formData} setFormData={setFormData} />
              </div> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};
export default ProductsWithFeatures;
