import React from 'react'
import Login from '../components/Authentication/Login';
import ArtistForm from '../components/Forms/ArtistForm';
import GetArtists from '../helpers/GetArtists';


const About = () => {


  return (
    <div>
      <h3>About Page</h3>
      <ArtistForm />
     


    </div>
  )
}

export default About
