/** @format */

import React, { useState } from "react";

function OCRGenerator() {
  const [invoiceNumber, setInvoiceNumber] = useState(generateRandomNumberString());
  const [ocrNumber, setOcrNumber] = useState("");

  // Funktion för att generera OCR-nummer
  const generateOcrNumber = () => {
    setInvoiceNumber(generateRandomNumberString());
    const today = new Date();
    const dateString = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, "0")}${today
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const uniqueOcrNumber = `${dateString}${invoiceNumber.toString().padStart(6, "0")}`;
    setOcrNumber(uniqueOcrNumber);
  };

  function generateRandomNumberString() {
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += Math.floor(Math.random() * 10); // Generate a random integer between 0 and 9
    }
    return result;
  }

  // const generateRandomNumber = () => {
  //   const randomNumber = Math.floor(Math.random() * 10000000000);
  //   setInvoiceNumber(randomNumber.toString());
  // };

  return (
    <div style={{ display: "flex", gap: "15px", height: "50px" }}>
      <input type="text" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} placeholder="Fakturanummer" />
      <button onClick={generateOcrNumber}>Generera OCR-nummer</button>
      {ocrNumber && <p>Genererat OCR-nummer: {ocrNumber}</p>}
    </div>
  );
}

export default OCRGenerator;
