/** @format */

import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { db } from "../firebase";
import axios from "axios";
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const FinsharkTransactions = () => {
  const [transactions, setTransactions] = useState();

  useEffect(() => {
    loadTrans();
  }, []);

  async function loadTrans() {
    const trans = await (await getDoc(doc(db, "Transactions", "transactions"))).data();
    console.log(Object.values(trans));
    setTransactions(Object.values(trans));
  }

  async function getTransactions() {
    console.log("getting transactions...");
    const body = { email: "marcus.dernulf@venuehub.live", account: 2 }; // account:1=Venuehub, 2=Klientmedel
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/finsharkGetAllTransactions`, body);
    console.log(response.data);
  }

  //   const makeDate = (milliseconds) => {
  //     new Date(milliseconds);
  //   };

  return (
    <div>
      <h1>transactions</h1>
      <Button onClick={() => getTransactions()} variant="outlined">
        Get transactions
      </Button>
      <div style={{ marginTop: "20px" }}>
        <table style={{ width: "100%" }} className="transactionTable">
          <thead>
            <th>Date</th>
            <th>Status</th>
            <th>Notes</th>
            <th>Amount</th>
            <th>Reference</th>
          </thead>

          {transactions &&
            transactions.map((transaction) => (
              <tr>
                <td>{new Date(transaction.date).toLocaleDateString()}</td>
                <td>{transaction.status}</td>
                <td>{transaction.notes}</td>
                <td>
                  {transaction.amount.value} {transaction.amount.currency}
                </td>
                <td>{transaction.description}</td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  );
};

export default FinsharkTransactions;
