/** @format */

// /** @format */

// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const InsTranslate = ({ phrase, from, to }) => {
//   const [inputText, setInputText] = useState("");
//   const [translatedText, setTranslatedText] = useState("");
//   const [loading, setLoading] = useState(false);
//   const apiKey = "AIzaSyDamfHQJ-BsgHnfvFrJpuZ2s0opV4Qppts"; // Replace with your actual API key

//   const handleTranslate = async () => {
//     setLoading(true);

//     try {
//       const response = await axios.post(`https://translation.googleapis.com/language/translate/v2?key=${apiKey}`, {
//         q: phrase,
//         target: to, // Target language code (Russian in this case)
//         source: from, // Source language code (English)
//       });

//       const translated = response.data.data.translations[0].translatedText;
//       setTranslatedText(translated);
//     } catch (error) {
//       console.error("Error translating text:", error);
//       setTranslatedText("Translation failed");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (phrase) handleTranslate();
//   }, [phrase]);

//   return <span className="text">{translatedText}</span>;
// };

// export default InsTranslate;

// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const InsTranslate = ({ phrase, from, to }) => {
//   const [translatedText, setTranslatedText] = useState("");
//   const [loading, setLoading] = useState(false);
//   const apiKey = "AIzaSyDamfHQJ-BsgHnfvFrJpuZ2s0opV4Qppts"; // Replace with your actual API key

//   const handleTranslate = async () => {
//     setLoading(true);

//     try {
//       // Encode line breaks as HTML entities
//       const encodedPhrase = phrase.replace(/\n/g, "&#10;");
//       const response = await axios.post(
//         `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
//         {
//           q: encodedPhrase,
//           target: to,
//           source: from,
//         },
//         {
//           headers: { "Content-Type": "application/json" },
//         }
//       );

//       // Decode HTML entities back to line breaks

//       // const translated = response.data.data.translations[0].translatedText.replace(/&#10;/g, "\n");
//       const translated = response.data.data.translations[0].translatedText;
//       setTranslatedText(translated);
//     } catch (error) {
//       console.error("Error translating text:", error);
//       setTranslatedText("Translation failed");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (phrase) handleTranslate();
//   }, [phrase]);

//   return (
//     <span className="text" style={{ whiteSpace: "pre-wrap" }}>
//       {loading ? "Translating..." : translatedText}
//     </span>
//   );
// };

// export default InsTranslate;

import React, { useEffect, useState } from "react";
import axios from "axios";

const InsTranslate = ({ phrase, from, to }) => {
  const [translatedText, setTranslatedText] = useState("");
  const [loading, setLoading] = useState(false);
  const apiKey = "AIzaSyDamfHQJ-BsgHnfvFrJpuZ2s0opV4Qppts"; // Replace with your actual API key

  const handleTranslate = async () => {
    if (!phrase) return; // Safety check to make sure there is a phrase to translate

    setLoading(true);

    try {
      // Make the request to the Google Translate API
      const response = await axios.post(`https://translation.googleapis.com/language/translate/v2?key=${apiKey}`, {
        q: phrase,
        target: to, // Target language code
        source: from, // Source language code
        format: "text", // Use 'text' to preserve line breaks
      });

      const translated = response.data.data.translations[0].translatedText;
      setTranslatedText(translated);
    } catch (error) {
      console.error("Error translating text:", error);
      setTranslatedText("Translation failed");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTranslate();
  }, [phrase, from, to]);

  return <span className="text">{loading ? "Translating..." : translatedText}</span>;
};

export default InsTranslate;
