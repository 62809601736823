/** @format */

import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

export function addNewVenueToUsersVenueAccess(formData, currentUser) {
  // Check if the venue already exists - otherwise add to users venueAccess
  currentUser.venueAccess.includes(formData.venueid) ? venueAlreadyExists() : addToUsersVenueAccess();

  async function addToUsersVenueAccess() {
    let tmpUserVenueAccess = [...currentUser.venueAccess];
    console.log("currentUser.documentid", currentUser.documentid);
    tmpUserVenueAccess.push(formData.venueid);
    console.log(tmpUserVenueAccess);
    setDoc(doc(db, "Users", currentUser.documentid), { venueAccess: tmpUserVenueAccess }, { merge: true });
  }
}

function venueAlreadyExists() {
  return;
}
