/** @format */

import { Autocomplete, Box, Button, Grid, Modal, Stack, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { setWeekYearWithOptions } from "date-fns/fp";
import { db, addDocumentToCollection, updateDocument, deleteDocument } from "../../firebase";
import { addDoc, collection, deleteDoc, doc, FieldValue, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
// import { storeBookingModalOpen } from "../features/venueFilter/venueFilterSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  storeBookingModalOpen,
  storeCurrentBookingId,
  storeCurrentEventlist,
  storeIsNewBooking,
  storeReloadBookings,
} from "../../features/venueFilter/venueFilterSlice";
import SearchArtist from "../../helpers/SearchArtist";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import VenueCalendar from "../Sidebar/VenueCalendar";
import id from "date-fns/locale/id";
import { v4 as uuid } from "uuid";
import GetBookings from "../../helpers/GetBookings";
import { Navigate, useNavigate } from "react-router-dom";
import GetBookingsForCurrentVenue from "../../helpers/GetBookingsForCurrentVenue";
import GetArtists from "../../helpers/GetArtists";
import ReloadBookings from "../../helpers/ReloadBookings";
import { artistObj } from "../../dbObjects.js/dbArtists";
import { generateUUID } from "../../utils/uuidGenerator";
import { ImageUploader } from "../UI helpers/ImageUpload/ImageUploader";
import { DaysToggler } from "../Toggles/DaysToggler";
import PreferredDays from "../Sidebar/PreferredDays";
import { agentObj } from "../../dbObjects.js/dbAgent";

const AgentForm = (props) => {
  const [formValues, setFormValues] = useState({});
  let agentFormArray = agentObj;

  const style = {
    position: "absolute",
    top: "15%",
    left: "15%",
    right: "15%",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid #333",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  useEffect(() => {
    console.log("SelectedArtistPopup", props.currentAgent);
    if (props.currentAgent) {
      const keyValuePairs = Object.entries(props.currentAgent);
      const updatedFormValues = keyValuePairs.reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, formValues);
      setFormValues(updatedFormValues);
    }
  }, [props.currentAgent]);

  const handleClose = [];

  // const navigate = useNavigate();

  // const dispatch = useDispatch();
  // const bookingModalOpen = useSelector((state) => state.counter.bookingModalOpen);
  // const handleNewSection = (newSection) =>{
  // 	currentSection = newSection;
  // 	return(<Grid xs={12} md={4} spacing={3}><br/>{newSection}</Grid> )
  // }
  var artists = [];
  const searchableArtists = artists.map(({ artist: label, artistid: id, image: image }) => ({
    id,
    label,
    image,
  }));
  const [agentData, setAgentData] = useState({});
  var agentKeys = Object.keys(agentData);

  const handleChange = (id, value) => {
    // console.log(id, value)
    // console.log(currentBooking[id])
    // // currentBooking[id] = value
    // setCurrentFieldValue({key:id, value:value})
    // console.log(currentBooking)
    // setBookingData((prev) => {
    // 	return{...prev, [id]:value}
    // })
    // id === 'artist' ? setArtist(value) : setArtist(null)
  };

  const saveBooking = async () => {
    // 	bookingKeys.map((key) => {
    // 		if (bookingData[key] == null){
    // 			bookingData[key] = '-'
    // 		}
    // 		console.log(key, bookingData[key] )
    // 	})
    // 	console.log(bookingData)
  };

  async function handleDelete() {
    // 	await deleteDoc(doc(db, "Artist", currentBookingId));
    // 	// alert('deleted '+currentBookingId)
    // 	handleClose();
    // console.log(currentVenue)
  }
  const sections = [];

  for (const item of agentObj) {
    if (item.type === "Section") {
      sections.push(item.section);
    }
  }
  const [selectedDays, setSelectedDays] = useState([]);
  const handleToggle = (event, value) => {
    console.log(event, value);
    // const currentIndex = selectedDays.indexOf(value);
    // const newSelectedDays = [...selectedDays];

    // if (currentIndex === -1) {
    // 	newSelectedDays.push(value);
    // } else {
    // 	newSelectedDays.splice(currentIndex, 1);
    // }
    // console.log(newSelectedDays);
    // setSelectedDays(newSelectedDays);
  };

  // useEffect(() => {
  // 	const keyValuePairs = Object.entries(props.currentArtist);
  // 	const updatedFormValues = keyValuePairs.reduce((acc, [key, value]) => {
  // 		return { ...acc, [key]: value };
  // 	}, formValues);
  // 	setFormValues(updatedFormValues);
  // }, []);

  return (
    <Grid container>
      <Grid container>
        <Grid xs={12}>
          <h3 id="parent-modal-title">Account</h3>
        </Grid>
      </Grid>
      {/* <Grid item direction="column" xs={4}>
        <ImageUploader storageFolder="artistImages" formImageKey="image" setFormValues={setFormValues} formValues={formValues} />
        {formValues.image && (
          <Button
            onClick={() => {
              setFormValues({
                ...formValues,
                image: "",
              });
            }}
          >
            Ta bort bild
          </Button>
        )}
      </Grid> */}
      <Grid container xs={12} sm={12}>
        {agentFormArray.map((formfield) => (
          <>
            <Grid item>
              {formfield.type == "Section" && <div className="formSectionLabel">{formfield.section}</div>}
              {sections.map((section) => {
                if (formfield.section === section && formfield.type !== "Section" && formfield.type !== "list") {
                  return (
                    <TextField
                      {...formfield}
                      value={formValues[formfield.id]}
                      onChange={(event) => {
                        setFormValues({
                          ...formValues,
                          [formfield.id]: event.target.value,
                        });
                      }}
                      size="small"
                      sx={{ margin: "10px" }}
                    />
                  );
                } else if (formfield.section === section && formfield.section === "Preferred Days" && formfield.type !== "Section") {
                  return <DaysToggler formValues={formValues} setFormValues={setFormValues} />;
                } else if (formfield.section === section && formfield.section === "Representing") console.log(formValues[formfield.id]);
              })}

              {/* {formfield.type == 'search' &&
							formfield.priority < 1000 ? (
								<Autocomplete
									value={artistData[formfield.id]}
									freeSolo
									onInputChange={(event, newInputValue) => {
										// bookingData[formfield.id] = newInputValue;
										console.log(newInputValue);
									}}
									onChange={(event, newValue) => {
										handleChange('artistid', newValue.id);
										handleChange('artist', newValue.label);
										handleChange('image', newValue.image);
									}}
									id='controllable-states-demo'
									getOptionId={(option) => option.id}
									options={searchableArtists}
									sx={{ width: 300 }}
									renderInput={(params) => (
										<TextField
											{...params}
											label={formfield.placeholder}
											size={'small'}
											value={artistData[formfield.id]}
										/>
									)}
								/>
							) : (
								<div></div>
							)} */}
            </Grid>
          </>
        ))}
        <Stack direction="row" spacing={2} height={35} mt={20}>
          <Button
            onClick={
              formValues.id
                ? () => {
                    updateDocument("Agents", formValues, formValues.id);
                  }
                : () => {
                    const newUUID = generateUUID();
                    setFormValues({
                      ...formValues,
                      agentid: newUUID,
                      userRole: "agent",
                    });
                    addDocumentToCollection("Agents", formValues, newUUID);
                  }
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              props.setShowAgentForm();
            }}
            startIcon={<CloseIcon />}
            size={"small"}
          >
            Close
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              deleteDocument("Agents", formValues.id);
            }}
            startIcon={<DeleteIcon />}
            size={"small"}
          >
            Remove
          </Button>
          {/* <Button
						variant='contained'
						onClick={saveBooking}
						endIcon={<SendIcon />}
						size={'small'}
					>
						Save
					</Button>
					<Button
						variant='outlined'
						onClick={handleClose}
						startIcon={<CloseIcon />}
						size={'small'}
					>
						Close
					</Button>
					<Button
						variant='outlined'
						onClick={handleDelete}
						startIcon={<DeleteIcon />}
						size={'small'}
					>
						Kill Artist
					</Button> */}
        </Stack>
      </Grid>
      {/* </Modal> */}
      <Grid container xs={12} style={{ fontSize: 10 }}></Grid>
      {/* {artistModalOpen != true ?  : '' } */}
    </Grid>
  );
};

export default AgentForm;
