import { Grid } from '@mui/material';
import React from 'react';
import SendAvails from '../features/avails/SendAvails';
import {
	Calendar,
	dateFnsLocalizer,
	Views,
	DateLocalizer,
	momentLocalizer,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

const Avails = () => {
	return (
		<div>
			<Grid item container xs={12}>
				<Grid item xs={12} sm={12} md={8}>
					<Grid item className='dateDisplay'></Grid>
					{/* <UpComingEvents /> */}
					<SendAvails />
				</Grid>

				<Grid
					item
					sm={12}
					md={4}
					sx={{ display: { xs: 'none', sm: 'block' } }}
				>
					{/* <DnDCalendar
            defaultDate={new Date(monthDisplayed)}
            defaultView={Views.MONTH}
            events={myEventsList}
            localizer={localizer}
            onEventDrop={onEventDrop}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onEventResize={onEventResize}
            // resizable
            style={{ height: "50vh" }}
            selectable
            eventPropGetter={eventPropGetter}
          /> */}
				</Grid>
				<Grid item xs={12}>
					{/* <h2>{currentVenue.venue}</h2> */}
					{/* Check for bookings in currentVenue and push into myEventsList to be shown in the calendar */}
					{/* {bokat
            ? bokat.map((booked) => {
                myEventsList.push({
                  id: booked.bookingid,
                  title: booked.artist,
                  start: new Date(booked.date),
                  end: new Date(booked.date),
                  status: booked.status,
                });
              })
            : null} */}
				</Grid>

				<Grid item xs={5}>
					{/* <BookingForm /> */}
				</Grid>
			</Grid>
		</div>
	);
};

export default Avails;
