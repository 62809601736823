/** @format */

import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import StandardTimesSlider from "../components/MiscUIComponents/StandardTimesSlider";
import axios from "axios";

const Itinerary = () => {
  var urlParams = new URLSearchParams(window.location.search);
  const bookingid = urlParams.get("bookingid");
  const usr = urlParams.get("usr");
  const [features, setFeatures] = useState();
  const [userSpecificFeatures, setUserSpecificFeatures] = useState();
  const [bookingData, setBookingData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!bookingid) return;
    getBookingData();
  }, []);

  async function getBookingData() {
    setLoading(true);
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getBookingData?bookingid=${bookingid}`); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getBookingData?bookingid=${bookingid}`); // for testing
    console.log(response.data);
    setBookingData(response.data);
    setLoading(false);
  }

  if (bookingData)
    return (
      <>
        <Grid item xs={12} style={{ position: "fixed", zIndex: "-1", filter: "blur(10px)", opacity: "0.5" }}>
          <img src={bookingData.artist.image} width="auto" height="100%" />
        </Grid>
        <Grid container p={2} mt={8} xs={12} sm={6}>
          <Grid item xs={12}>
            <h1>Itinerary</h1>

            <h2>{bookingData && bookingData.artist.artist}</h2>

            <h6>
              {bookingData.venue.venue}, {bookingData && bookingData.date}
            </h6>
          </Grid>
          <Grid container item xs={12}>
            {loading && <CircularProgress />}
            <Grid item xs={12}>
              <h7>Standard Times</h7>
              <StandardTimesSlider
                productData={bookingData}
                setProductData={setBookingData}
                TextFields={true}
                simple={true}
                formlock={true}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              {bookingData &&
                bookingData.bookingFeatures &&
                Object.values(bookingData.bookingFeatures).map((feature) => (
                  <Grid item>
                    <h7>{feature.additional}</h7>
                    {feature.additional !== "Staff" &&
                      Object.values(feature.options).map(
                        (option) =>
                          option.selected && (
                            <p>
                              {option.option}
                              {option.info && <>, {option.info}</>}
                              {option.specs && <>, {option.specs}</>}
                            </p>
                          )
                      )}
                    {feature.additional === "Staff" && (
                      <table>
                        {Object.values(feature.options).map(
                          (option) =>
                            option.selected && (
                              <>
                                <tr>
                                  <td>
                                    <b>{option.responsibility}:</b>
                                  </td>

                                  <td style={{ color: option.optionId === usr ? "orange" : "white" }}>{option.name}</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>{option.email}</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>{option.phone}</td>
                                </tr>
                              </>
                            )
                        )}
                      </table>
                    )}
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
              <h7>Notes</h7>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
};

export default Itinerary;
