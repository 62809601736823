/** @format */

// export const site = "http://localhost:3000";
// export const site = "https://venuehub.live";

// Instead of using 'export'
// export const site = "https://venuehub.live";

// Use 'module.exports' for CommonJS syntax
module.exports = {
  site: "https://venuehub.live",
};
