/** @format */

import { Button, CircularProgress, FormControlLabel, InputAdornment, Switch, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { Edit, Refresh } from "@mui/icons-material";
import { set } from "date-fns";

const AgreementAddendum = ({ agreement, type }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentAgreement = useSelector((state) => state.counter.currentAgreement);
  if (!agreement.addendum) return;
  const [addendum, setAddendum] = useState({
    text:
      agreement.addendum && agreement.addendum.text && agreement.addendum.text.length
        ? agreement.addendum.text
        : currentVenue.addendum || "",
    useAddendum:
      agreement.addendum && "useAddendum" in agreement.addendum ? agreement.addendum.useAddendum : currentVenue.useAddendum || false,
  });
  const [useAddendum, setUseAddendum] = useState(addendum.useAddendum);
  const [editAddendum, setEditAddendum] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddendumChange = (event) => {
    // setUseAddendum(!useAddendum);
    storeAddendumInAgreement();
  };

  const handleEditAddendum = () => {
    setEditAddendum(!editAddendum);
  };

  async function storeAddendumInAgreement() {
    setLoading(true);
    try {
      await updateDoc(doc(db, "Agreements", agreement.agreementId), {
        addendum: { text: addendum.text, useAddendum: useAddendum },
      });
      setEditAddendum(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  async function storeAddendumInProduct() {
    alert("storeAddendumInProduct");
    // try {
    //   await updateDoc(doc(db, "Agreements", agreement.agreementId), {
    //     addendum: { text: addendum.text, useAddendum: addendum.useAddendum },
    //   });
    // } catch (error) {
    //   console.error("Error updating document:", error);
    // }
  }

  useEffect(() => {
    handleAddendumChange();
  }, [useAddendum]);

  if (type === "sign" || type === "venue" || type === "product")
    return (
      <div style={{ padding: "10px" }}>
        <div>
          {currentVenue.addendum.text}
          <h7>Addendum</h7>
        </div>
        <FormControlLabel
          control={<Switch checked={useAddendum} onChange={() => setUseAddendum(!useAddendum)} defaultChecked />}
          label={"Add text"}
        />
        <FormControlLabel
          control={<Edit style={{ margin: "0px 10px", color: editAddendum ? "#00ffd4" : "white" }} />}
          label={"Edit Addendum"}
          onClick={() => handleEditAddendum()}
        />
        {loading && <CircularProgress style={{ zoom: "0.4", marginRight: "20px" }} />}

        {editAddendum && (
          <div>
            <TextField
              label="Addendum"
              value={addendum.text}
              onChange={(e) => setAddendum((prev) => ({ ...prev, text: e.target.value }))}
              multiline
              rows={4}
              sx={{ width: "100%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      variant="caption"
                      sx={{
                        position: "absolute",
                        top: 4,
                        right: 8,
                        color: "text.secondary",
                      }}
                    >
                      {addendum.text.length} letters
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        position: "absolute",
                        bottom: 4,
                        right: 8,
                        color: "text.secondary",
                      }}
                    >
                      Reset to default{" "}
                      <Refresh
                        onClick={() => setAddendum((prev) => ({ ...prev, text: currentVenue.addendum || "" }))}
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />

            {type === "sign" && (
              <Tooltip title="Store the addendum in the agreement.">
                <Button onClick={() => storeAddendumInAgreement()} variant="outlined" style={{ margin: "15px" }} size="small">
                  Update agreement
                </Button>
              </Tooltip>
            )}
            {type === "product" && (
              <Tooltip title="Store the addendum in the product.">
                <Button onClick={() => storeAddendumInProduct()} variant="outlined" style={{ margin: "15px" }} size="small">
                  Store addendum
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
};

export default AgreementAddendum;
