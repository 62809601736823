/** @format */

import { set } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import TollIcon from "@mui/icons-material/Toll";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PeopleIcon from "@mui/icons-material/People";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import { Icon } from "@mui/material";
import { getDistanceTo } from "../../helpers/GetDistanceTo";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import DirectionsIcon from "@mui/icons-material/Directions";

const TourCalculation = () => {
  // const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);
  const wantedList = useSelector((state) => state.counter.wantedList);
  const averageTicketPrice = useSelector((state) => state.counter.averageTicketPrice);
  const standardLocation = { lat: "59", lng: "18" };

  //   setTimeout(() => {
  //     console.log(availableVenuesAndDates);
  //   }, 2000);

  const totalCost = useMemo(() => {
    return wantedList.reduce((carry, item) => {
      if (item.proposedDate) return carry + parseInt(item.priceWknd);
      return carry;
    }, 0);
  }, [wantedList]);

  const totalReach = useMemo(() => {
    return wantedList.reduce((carry, item) => {
      if (item.proposedDate) return carry + parseInt(item.capacity);
      return carry;
    }, 0);
  }, [wantedList]);

  const costPerSeat = Math.round(totalCost / totalReach);

  const totalRevenue = useMemo(() => {
    return totalReach * averageTicketPrice;
  }, [averageTicketPrice]);

  const totalDistance = useMemo(() => {
    var temp = 0;
    return wantedList.reduce((carry, item) => {
      console.log(standardLocation.lat, standardLocation.lng, item.lat, item.lng);
      console.log(getDistanceTo(standardLocation.lat, standardLocation.lng, item.lat, item.lng));
      temp = carry + (Number(getDistanceTo(standardLocation.lat, standardLocation.lng, item.lat, item.lng)) * 2) / 1000;
      if (item.proposedDate) return Math.round(temp);
      return carry;
    }, 0);
  }, [wantedList]);

  const totalCo2e = useMemo(() => {
    return Math.round(totalDistance * 0.181);
  }, [wantedList]);

  //   useEffect(() => {
  //     wantedList.map((item) => {
  //       setTimeout(() => {
  //         console.log(standardLocation.lat, standardLocation.lng, item.lat, item.lng);
  //       }, 2000);
  //     });
  //   }, [wantedList]);

  //   setTimeout(() => {
  //     console.log(wantedList);
  //   }, 1000);

  // if (totalCost)
  return (
    <div className="tourCalc">
      {/* <h4>Tour Calculation</h4> */}
      <div className="">
        <div className="tc">
          <div className="tcCost">
            <div className="tcIcon">
              <PointOfSaleIcon />
            </div>
            <span>Cost: {totalCost.toLocaleString("sv-SE", { style: "decimal", useGrouping: true })} kr</span>
          </div>
        </div>
        <div className="tc">
          <div className="tcCostPerSeat">
            <div className="tcIcon">
              <TollIcon />
            </div>

            <span>Average cost/seat: {costPerSeat} kr</span>
          </div>
        </div>
        <div className="tc">
          <div className="tcRevenue">
            <div className="tcIcon">
              <ConfirmationNumberIcon />
            </div>
            <span>Sale: {totalRevenue.toLocaleString("sv-SE", { style: "decimal", useGrouping: true })} kr</span>
          </div>
        </div>
        <div className="tc">
          <div className="tcReach">
            <div className="tcIcon">
              <PeopleIcon />
            </div>
            <span>Reach: {totalReach.toLocaleString("sv-SE", { style: "decimal", useGrouping: true })}</span>
          </div>
        </div>

        <div className="tc">
          <div className="tcDistance">
            <div className="tcIcon">
              <DirectionsIcon />
            </div>
            <span>Traveled Distance: {totalDistance.toLocaleString("sv-SE", { style: "decimal", useGrouping: true })} km</span>
          </div>
        </div>
        <div className="tc">
          <div className="tcCo2e">
            <div className="tcIcon">
              <AirportShuttleIcon />
            </div>
            <span>CO2e: {totalCo2e} kg</span>
          </div>
        </div>
      </div>
      {/* <div>Test:{totalTest}</div>
      <div>Cost:{totalCost}</div>
      <div>Reach:{totalReach}</div>
      <div>Revenue:{totalRevenue}</div> */}
    </div>
  );
};

export default TourCalculation;
