/** @format */

import React from "react";

const DayOfTheWeekMicro = ({ preferredDays }) => {
  const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  console.log("preferredDays", preferredDays);
  function sortDaysOfWeek(days) {
    const dayOrder = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6,
    };
    // Create a copy of the days array
    const daysCopy = [...days];
    return daysCopy.sort((a, b) => dayOrder[a] - dayOrder[b]);
  }
  const sortedDays = sortDaysOfWeek(preferredDays && preferredDays);
  console.log("sortedDays", sortedDays);

  if (preferredDays.length)
    return (
      <div style={{ display: "flex" }}>
        {daysOfTheWeek.map((day) => (
          <div
            style={{
              opacity: sortedDays.includes(day) ? "1" : "0.3",
              fontSize: "10px",
              //   borderRadius: "50%",
              margin: "0px 2px",
              padding: "0px 3px",
              //   border: "1px solid #00ffd4",
              height: "15px",
              width: "15px",
              textAlign: "center",
              background: "#777",
            }}
          >
            {day.substring(0, 1)}
          </div>
        ))}
      </div>
    );
};

export default DayOfTheWeekMicro;
