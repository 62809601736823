/** @format */

import { Avatar, Box, Button, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db, getBookingsByVenueid } from "../firebase";

import { CircularProgress } from "@mui/material";
// import { listenToOwnedVenuesBookings, reloadCalendarBookings } from "./Listeners";
import DisplayObject from "./displayObject";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoadingDots from "../components/UI helpers/LoadingDots";
import AddVenue from "../components/Forms/AddVenue";
import EditIcon from "@mui/icons-material/Edit";
import shortText from "./shortText";
import {
  storeAccessibleVenues,
  storeCurrentBookings,
  storeCurrentLoadingBookingsProgress,
  storeCurrentSettings,
  storeCurrentTicketSummary,
  storeCurrentVenue,
  storeCurrentVenueBookings,
} from "../features/venueFilter/venueFilterSlice";
import ObjectSize from "./ObjectSize";
import { set } from "date-fns";
import { updateAccessibleVenuesWhenCurrentVenue } from "../components/MiscUIComponents/updateAccessibleVenuesWhenCurrentVenue";

const SelectVenue = () => {
  const cs = useSelector((state) => state.counter.currentSettings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentTicketSummary = useSelector((state) => state.counter.currentTicketSummary);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  // const [currentVenue, setCurrentVenue] = useState({});
  // const [currentVenue, setCurrentVenue] = useState({});
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [loading, setLoading] = useState(false);
  const [loadingBookings, setLoadingBookings] = useState(0);
  const [value, setValue] = useState(cs.svVenueid ? cs.svVenueid : null);
  const [accessibleVenues, setAccessibleVenues] = useState([{}]);
  const [currentVenueid, setcurrentVenueid] = useState();
  const [groupedData, setGroupedData] = useState({});

  // THIS HERE IS A PROBLEM (but it still works)
  // currentVenue && listenToOwnedVenuesBookings();
  // currentVenue && reloadCalendarBookings(currentVenue);

  async function handleChange(venueid) {
    // first empty currentBookings
    // dispatch(storeCurrentBookings([]));
    dispatch(storeCurrentVenueBookings([]));
    dispatch(storeCurrentVenue(null));

    if (venueid === undefined || !venueid) return;
    // alert("Now A");
    setValue(venueid);
    const venue = accessibleVenues.find((venue) => venue.venueid === venueid);
    // if (venue.venueid === currentVenue.venueid) return;
    // alert("1");
    dispatch(storeCurrentVenue(venue));
    // listenToVenueBookings(venue);

    // dispatch(storeCurrentSettings({ ...cs, svVenueid: venueid }));
  }

  useEffect(() => {
    // alert("currentVenue: " + currentVenue.venueid);
    // alert("now D");
    // listenToVenueBookings(currentVenue);
    listenToVenueBookings();
  }, [currentVenue.venueid]);

  // function listenToVenueBookings(currentVenue) {
  function listenToVenueBookings() {
    if (currentVenue.venueid === "none" || currentVenue.venueid === undefined) return;
    setListener();

    // const bookingCollectionRef = collection(db, "Bookings");
    // const dispatch = useDispatch();
    let tempCurrentVenue = [];
    // const currentVenue = useSelector((state) => state.counter.currentVenue);
    // alert("reloading");
    async function setListener() {
      // alert("now");
      if (currentVenue.venueid === undefined) return;
      if (currentVenue && currentVenue.venueid) {
        // alert("now");
        console.time("bookings time");
        const q = query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
        const unsubscribe = await onSnapshot(q, async (querySnapshot) => {
          let bookings = [];
          const totalDocs = querySnapshot.size;
          let currentIndex = 0;
          // querySnapshot.forEach((doc) => {
          //   bookings.push(doc.data());
          //   currentIndex++;
          //   const progressValue = (currentIndex / totalDocs) * 100;
          //   console.log("querySnapshot", currentIndex, totalDocs);
          //   console.log("progressValue", progressValue);
          //   setLoadingBookings(progressValue);
          // });
          for (const doc of querySnapshot.docs) {
            bookings.push(doc.data());
            currentIndex++;

            // Update progress based on the current index
            const progressValue = (currentIndex / totalDocs) * 100;
            setLoadingBookings(progressValue);

            // Add a small delay to allow rendering of progress bar
            await new Promise((resolve) => setTimeout(resolve, 1));
          }
          bookings.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          });
          // alert(Object.values(currentVenue.products).length);
          tempCurrentVenue = { ...currentVenue, bookings };
          // alert("now B");
          await dispatchToStore(tempCurrentVenue);
          // await dispatch(storeCurrentVenueBookings(bookings));
          console.timeEnd("bookings time");
          return;
        });
      }
    }
    // alert("reloading " + currentVenue.venueid);
  }

  async function dispatchToStore(tempCurrentVenue) {
    // alert("dispatched");
    const tmpFiltered = tempCurrentVenue.bookings.filter((item) => item.status !== "Killed");
    // alert("now C");
    dispatch(storeCurrentVenueBookings(tempCurrentVenue.bookings));
    // alert("2");
    dispatch(storeCurrentVenue(tempCurrentVenue)); // With all bookings inside currentVenue.bookings // <=== HERE
    // dispatch(storeCurrentVenue(currentVenue)); // With no bookings inside currentVenue.bookings
  }

  // useEffect(() => {
  //   // if currentVenue.features changes, change the same instance in accessibleVenues
  //   const updatedAccessibleVenues = accessibleVenues.map((venue) => {
  //     if (venue.venueid === currentVenue.venueid) {
  //       return { ...currentVenue };
  //     }
  //     return venue;
  //   });
  //   setAccessibleVenues(updatedAccessibleVenues);
  // }, [currentVenue.features]);

  // useEffect(() => {
  //   // if currentVenue.products changes, change the same instance in accessibleVenues
  //   const updatedAccessibleVenues = accessibleVenues.map((venue) => {
  //     if (venue.venueid === currentVenue.venueid) {
  //       // alert("venue.venueid: " + venue.venueid);
  //       return { ...currentVenue };
  //     }
  //     return venue;
  //   });
  //   console.log("currentVenueProducts", currentVenue.products);
  //   console.log("accessibleVenuesProducts", accessibleVenues);
  //   console.log("updatedAccessibleVenues", updatedAccessibleVenues);
  //   setAccessibleVenues(updatedAccessibleVenues);
  // }, [currentVenue.products]);

  useEffect(() => {
    console.log("accessibleVenues", accessibleVenues);
  }, [accessibleVenues]);

  useEffect(() => {
    if (venueAccess[0] === "none") return;
    console.time("getVenues");
    console.log("getting venues");
    async function loadVenues(venueAccess) {
      let tmpAccessibleVenues = [];
      try {
        // Loop through each venueid in the venueAccess array
        for (const venueid of venueAccess) {
          // Fetch each document from the "Venues" collection based on the venueid
          const venueDoc = await getDoc(doc(db, "Venues", venueid));
          // Check if the document exists
          if (venueDoc.exists()) {
            // Push the venue data into the accessibleVenues array
            tmpAccessibleVenues.push(venueDoc.data());
          } else {
            console.error(`No venue found for venueid: ${venueid}`);
          }
        }
        // Return or set accessibleVenues as needed
        // console.log("Accessible Venues:", accessibleVenues);
        setAccessibleVenues(tmpAccessibleVenues);
        console.timeEnd("getVenues");
        return tmpAccessibleVenues; // Return or use accessibleVenues accordingly
      } catch (error) {
        console.error("Error loading venues:", error);
      }
    }
    // Call the loadVenues function with the venueAccess array
    loadVenues(venueAccess);
  }, [venueAccess]);

  // When accessible Venues are loaded, set the currentVenue to the last venue in the array
  useEffect(() => {
    if (Object.keys(accessibleVenues[0]).length === 0 && accessibleVenues[0].constructor === Object) return; // If accessibleVenues is empty, return
    console.log("accessibleVenues", accessibleVenues);
    handleChange(accessibleVenues[0] && accessibleVenues[accessibleVenues.length - 1].venueid);
    // dispatch(storeCurrentVenue(accessibleVenues[0] && accessibleVenues[accessibleVenues.length - 1]));
    dispatch(storeAccessibleVenues(accessibleVenues));
  }, [accessibleVenues]);

  // useEffect(() => {
  //   console.log("loadingBookings");
  // }, [loadingBookings]);

  useEffect(() => {
    if (currentVenue && currentVenue.venueid === "none") return;
    // alert("currentVenue: " + currentVenue.venueid);

    const updatedAccessibleVenues = updateAccessibleVenuesWhenCurrentVenue(accessibleVenues, currentVenue);
    // setAccessibleVenues(updatedAccessibleVenues);

    if (Object.keys(currentVenue).length === 0 && currentVenue.constructor === Object) return;
    console.log("currentVenuetickets", currentVenue);
    if (!currentVenue) {
      alert("No currentVenue");
      return;
    }

    const ticketVenueId = currentVenue.ticketVenueId && currentVenue.ticketVenueId;
    if (!ticketVenueId) return;
    // alert("ticketVenueId: " + ticketVenueId);
    console.log("fetching ticket summary for", ticketVenueId);
    fetchTicketSummary(ticketVenueId);

    // async function fetchTicketSummary(ticketVenueId) {
    //   console.time("fetchTickets");

    //   const ticketSummaryRef = doc(db, "TicketSummaries", ticketVenueId);
    //   const ticketSummarySnap = await getDoc(ticketSummaryRef);
    //   // if (!ticketSummarySnap.exists()) alert("No ticket summary found for this venue");
    //   if (!ticketSummarySnap.exists()) return;
    //   if (ticketSummarySnap.exists()) {
    //     console.log("Ticket Document data:", ticketSummarySnap.data());
    //     const ticketSummaryData = ticketSummarySnap.data();
    //     dispatch(storeCurrentTicketSummary(ticketSummaryData));
    //     // alert("Ticket Summary fetched");
    //     console.timeEnd("fetchTickets");
    //   }
    // }
    async function fetchTicketSummary(ticketVenueId) {
      console.time("fetchTickets");

      try {
        const ticketSummaryRef = doc(db, "TicketSummaries", ticketVenueId);
        const ticketSummarySnap = await getDoc(ticketSummaryRef);

        if (!ticketSummarySnap.exists()) {
          console.warn("No ticket summary found for this venue");
          return;
        }

        console.log("Ticket Document data:", ticketSummarySnap.data());
        const ticketSummaryData = ticketSummarySnap.data();
        dispatch(storeCurrentTicketSummary(ticketSummaryData));
      } catch (error) {
        console.error("Error fetching ticket summary:", error);
      } finally {
        console.timeEnd("fetchTickets");
      }
    }
  }, [currentVenue]);

  // REMOVE?
  useEffect(() => {
    if (Object.keys(currentTicketSummary[0]).length === 0 && currentTicketSummary[0].constructor === Object) return;
    currentTicketSummary.length > 0 && console.log("currentTicketSummary:", currentTicketSummary);
  }, [currentTicketSummary]);

  return (
    <div>
      {/* <DisplayObject data={currentVenue.products} /> */}
      {loadingBookings > 0 && loadingBookings < 100 && (
        <LinearProgress variant="determinate" value={loadingBookings} style={{ opacity: "0.3" }} />
      )}

      <Box sx={{ minWidth: 100, maxWidth: 200, height: 20 }} mt={10}>
        {/* <div className="bookingSize">
          {groupedData && Object.values(groupedData).map((data) => <ObjectSize dataObject={data} name=" bytes" />)}
        </div> */}
        {/* {accessibleVenues.map((venue) => (
          <ObjectSize dataObject={venue} name=" kb" />
        ))} */}
        {/* <ObjectSize dataObject={currentVenue} name=" kb" /> */}
        {/* <ObjectSize dataObject={currentTicketSummary} name=" kb" />
        {console.log("real currentVenue", currentVenue)} */}

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" style={{ marginTop: "-25px" }} shrink={true}>
            {!loading ? (
              <>Select Venue</>
            ) : (
              <>
                {/* <CircularProgress size={15} />  */}
                Loading venues
                <LoadingDots speed="300" />
              </>
            )}
          </InputLabel>
          {console.log("VALUE:", currentVenue)}
          <Select
            value={value}
            // renderValue={shortText(value, 10)}
            className="selectVenue"
            labelId="select-venue"
            id="select-venue"
            onChange={(e) => handleChange(e.target.value)}
            fullWidth={true}
            sx={{ height: "30px", color: "transparent" }}
          >
            {loading && (
              <MenuItem value={null} name="Loading venues">
                {" "}
                <CircularProgress size={15} />{" "}
              </MenuItem>
            )}
            {accessibleVenues.map((venue) => (
              <MenuItem value={venue.venueid} name={venue.venue}>
                <Avatar
                  size="small"
                  alt={venue.venue}
                  src={venue.venueImage}
                  sx={{
                    marginRight: "10px",
                    float: "left",
                    border: "1px solid",
                  }}
                />
                <p style={{ color: "white", display: "flex", marginTop: "15px" }}>{venue.venue && shortText(venue.venue, 16)}</p>
              </MenuItem>
            ))}

            <Divider />
            <MenuItem p={2}>
              <AddVenue location="selectVenue" />
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default SelectVenue;
