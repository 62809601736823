/** @format */

import React from "react";
import axios from "axios";

const CheckIfEmailExists = async (email) => {
  let msg;
  try {
    const response = await axios.post(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/checkIfThisEmailExists?email=${encodeURIComponent(email)}`
    );
    // console.log(response.data);
    // console.log(response.status);
    if (response.status === 201) msg = "Exists";
    if (response.status === 202) msg = "OK";
  } catch (error) {
    console.error("Error checking email:", error);
    // Retry the request after a short delay
    // setTimeout(handleBlur, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
  }

  //   return <div>CheckIfEmailExists</div>;
  console.log(msg);
  return msg;
};

export default CheckIfEmailExists;
