/** @format */

import React from "react";
import IncomingRequests from "../components/UI helpers/IncomingRequests";

const IncomingRequestPage = () => {
  return <IncomingRequests />;
};

export default IncomingRequestPage;
