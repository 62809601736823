/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DisplayObject = ({ data, name, type }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [expandedStrings, setExpandedStrings] = useState([]);

  const toggleExpand = (key) => {
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter((k) => k !== key));
    } else {
      setExpandedKeys([...expandedKeys, key]);
    }
  };

  const toggleExpandString = (key) => {
    if (expandedStrings.includes(key)) {
      setExpandedStrings(expandedStrings.filter((k) => k !== key));
    } else {
      setExpandedStrings([...expandedStrings, key]);
    }
  };

  const renderValue = (value, key) => {
    if (typeof value === "object") {
      const isExpanded = expandedKeys.includes(key);
      return (
        <>
          <IconButton onClick={() => toggleExpand(key)}>
            {isExpanded ? <KeyboardArrowDownIcon color="primary" /> : <KeyboardArrowRightIcon color="primary" />}
          </IconButton>
          {isExpanded && renderObject(value)}
        </>
      );
    } else if (typeof value === "string" && value.length > 40) {
      const isExpanded = expandedStrings.includes(key);
      return (
        <>
          <IconButton onClick={() => toggleExpandString(key)}>
            {isExpanded ? <KeyboardArrowDownIcon color="primary" /> : <KeyboardArrowRightIcon color="primary" />}
          </IconButton>
          {isExpanded ? value : value.substring(0, 40) + "..."}
        </>
      );
    } else {
      return value;
    }
  };

  const renderObject = (obj, depth = 0) => {
    // return (
    //   <Accordion>
    //     <AccordionSummary>Display: {name && name}</AccordionSummary>
    //     <AccordionDetails>
    //       <table>
    //         {Object.entries(obj).map(([key, value]) => (
    //           <tr key={key}>
    //             <td style={{ width: "200px" }}>
    //               <strong>{key}</strong>
    //             </td>
    //             <td>{renderValue(value, key)}</td>
    //           </tr>
    //         ))}
    //       </table>
    //     </AccordionDetails>
    //   </Accordion>
    // );
    return (
      <table style={{ border: "1pt dashed #444", padding: "5pt" }}>
        {Object.entries(obj).map(([key, value]) => (
          <tr key={key}>
            <td style={{ width: "200px" }}>
              <strong>{key}</strong>
            </td>
            <td>{renderValue(value, key)}</td>
          </tr>
        ))}
      </table>
    );
  };

  return <div>{data && renderObject(data)}</div>;
};

export default DisplayObject;
