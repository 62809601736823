/** @format */

import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import GetBookingsForCurrentVenue from "../../helpers/GetBookingsForCurrentVenue";
import { createId, db } from "../../firebase";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useDispatch } from "react-redux";
import ProductSelect from "../MiscUIComponents/ProductSelect";
import checkAvails from "../../features/avails/CheckAvails";
import { all } from "axios";
import { SendRequest } from "../../helpers/SendRequest";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WhatDayIsIt from "./WhatDayIsIt";

const VhDatePicker = ({ currentVenue, bookingData, setBookingData }) => {
  const divRef = useRef();
  const currentUser = useSelector((state) => state.counter.currentUser);
  const month = useSelector((state) => state.counter.currentMonth);
  const year = useSelector((state) => state.counter.currentYear);
  const thisYear = new Date().getFullYear();
  //   const bookings = useSelector((state) => state.counter.bookingsForCurrentVenue);
  const [bookings, setBookings] = useState([]);
  const selectedDate = useSelector((state) => state.counter.selectedDate);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [datesDone, setDatesDone] = useState(false);
  const [renderedDates, setRenderedDates] = useState(false);
  const [allStatuses, setAllStatuses] = useState();
  const [chosenDateRange, setChosenDateRange] = useState();
  const [avails, setAvails] = useState();
  const [awaiting, setAwaiting] = useState();
  const [pickedDates, setPickedDates] = useState([]);
  const [dayData, setDayData] = useState([]);

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedDivs, setSelectedDivs] = useState([]);

  const [hoveredDay, setHoveredDay] = useState(null);

  const statusColor = (status) => {
    switch (status) {
      case "Confirmed":
        return "green";
      case "Awaiting Artist":
        return "orange";
      case "Pending Request":
        return "red";
      case "Blocked":
        return "pink";
      default:
        return "black";
    }
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "none",
    background: "linear-gradient(180deg, rgba(255,255,255,0.1), rgba(0,0,0,0.5))",
    backdropFilter: "blur(30px)",
    boxShadow: 24,
    p: 4,
    height: "80vh",
    overflow: "hidden",
    borderRadius: "10px",
  };

  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // const years = [year, year + 1, year + 2, year + 3];
  const years = [thisYear, thisYear + 1, thisYear + 2, thisYear + 3, thisYear + 4, thisYear + 5];

  //Get all bookings for venue
  const [done, setDone] = useState(false);
  useEffect(() => {
    if (!open) return;
    setPickedDates([]);
    async function getData() {
      // alert("getData");
      //   const data = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", "ppt4ilbghuvbzeawiy6vxqsjlmq5gage")));
      //     const data = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid)));
      //     const bookingData = data.docs.map((doc) => doc.data());
      //     setBookings(bookingData);
      //   setBookings(currentVenueBookings);
      // alert("bookingData");
      // Set all dates for the next 4 years with status
      let tmpStatuses = [];
      let allDates = [];
      await years.map((year) =>
        months.map((month, monthIndex) => {
          const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
          const dates = Array.from({ length: daysInMonth }, (_, index) =>
            new Date(year, monthIndex, index + 1).toLocaleDateString("sv-SE")
          ); // Generate dates for each day of the month

          dates.forEach((date) => {
            // bookingData.find(
            //   (booking) =>
            //     booking.date === date &&
            //     booking.status !== "Pending Request" &&
            //     booking.status !== "Killed" &&
            //     booking.status !== "Declined" &&
            //     // booking.status !== "Blocked" &&
            //     tmpStatuses.push({
            //       date: date,
            //       artist: bookingData.find((booking) => booking.date === date).artist,
            //       status: booking.status,
            //       loadIn: booking.loadIn,
            //       loadOut: booking.loadOut,
            //     })
            // );
            allDates.push(date);
          });
        })
      );
      setChosenDateRange(allDates);
      setAllStatuses(tmpStatuses);
    }
    getData();
  }, [open]);

  useEffect(() => {
    if (!chosenDateRange) return;
    function chain() {
      console.log("✅ currentProduct set");
      console.log("bookings", bookings);
      console.log("chosenDateRange", chosenDateRange);
      setAwaiting([]); // Clear awaiting
      setAvails([]); // Clear avails
      checkAvailabilities();
      setPickedDates([]); // Clear picked dates
    }
    chain();
  }, [chosenDateRange, currentProduct]);

  useEffect(() => {
    console.log("allStatuses", allStatuses);
  }, [allStatuses]);

  useEffect(() => {
    // bookings.length && setDone(true);
  }, [bookings]);

  const prefDays = currentProduct
    ? currentProduct.preferredDays
    : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const checkAvailabilities = async () => {
    // console.log("🌿Checking Avails");
    // console.log("currentVenue", currentVenue);
    // console.log("chosenDateRange", chosenDateRange);
    // console.log("prefDays", prefDays);
    // console.log("currentProduct", currentProduct);
    checkAvails(currentVenue, chosenDateRange, prefDays, currentProduct, setAvails, setAwaiting);
  };

  useEffect(() => {
    // console.log("avails", avails);
    // console.log("awaiting", awaiting);
    getAvailColor();
  }, [avails, awaiting]);

  const [availColor, setAvailColor] = useState({});
  const getAvailColor = () => {
    chosenDateRange &&
      chosenDateRange.map((date) => {
        const avail = avails && avails.find((avail) => avail === date);
        const awaitingArtist = awaiting && awaiting.find((tmpAwaiting) => tmpAwaiting === date);
        // const picked = pickedDates && pickedDates.find((pick) => pick === date);

        // Old Date
        if (date === bookingData.date) {
          setAvailColor((prev) => ({
            ...prev,
            [date]: {
              background: "orange",
              opacity: "1",
              border: "1px solid orange",
              animation: "radialPulse 3s infinite ease-in-out",
            },
          }));
          return;
        }
        if (avail) {
          setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd433", opacity: "1" } }));
        } else if (awaitingArtist) {
          setAvailColor((prev) => ({
            ...prev,
            [date]: {
              background: "#FFA50099",
              opacity: "0.6",
              color: "#ffffff33",
            },
          }));
        } else {
          setAvailColor((prev) => ({
            ...prev,
            [date]: {
              background: "#FF000044",
              opacity: "0.6",
              color: "#ffffff33",
            },
          }));
        }
      });
  };

  useEffect(() => {
    console.log("availColor", availColor);
  }, [availColor]);

  const changeDate = () => {
    if (pickedDates[0] === bookingData.date || pickedDates[0] === undefined) {
      console.log("⊘ No date picked");
      return;
    }
    console.log("✅ Date changed to", pickedDates[0]);
    setBookingData({ ...bookingData, date: pickedDates[0] });
    setOpen(false);
  };

  //Show month in calendar
  const showMonth = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstWeekday = new Date(year, month, "01").getDay();
    const numberOfPaddingDays = firstWeekday - 1; //Change this number to change startday of the week -2 gives monday, -1 gives sunday
    const paddingDays = [];
    for (let i = 0; i <= numberOfPaddingDays; i++) {
      paddingDays.push(i);
    }

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    const [loading, setLoading] = useState(false);
    // if (currentProduct)
    return (
      <div style={{ width: "300px", height: "350px", float: "left" }}>
        {months[month]}
        <div style={{ width: "100%", float: "left", display: "block" }} onMouseUp={handleMouseUp}>
          {weekdays.map((day) => (
            <div
              // className="day"
              style={{
                userSelect: "none",
                WebkitUserSelect: "none",
                float: "left",
                background: "transparent",
                width: "12%",
                height: "20px",
                borderRadius: "10px",
                marginBottom: "10px",
                margin: "2px",
              }}
            >
              <span className="weekday" style={{ opacity: "0.3" }}>
                {day}
              </span>
            </div>
          ))}
          {paddingDays.map((day) => (
            <div
              className="day"
              style={{
                float: "left",
                background: "transparent",
                width: "12%",
                height: "40px",
                borderRadius: "10px",
                margin: "2px",
                // border: `1px solid ${statusColor}`,
              }}
            >
              <span className="day-number" style={{ opacity: "0.3" }}></span>
            </div>
          ))}
          {days.map((day) => (
            <>
              <div
                // data-value={formatDate(year, month, day)}

                className="day"
                style={{
                  cursor: dayData.length > 0 ? "pointer" : "varning",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  float: "left",
                  width: "12%",
                  height: "40px",
                  borderRadius: "5px",
                  margin: "2px",
                  color: "white",
                  ...(!availColor || availColor[new Date(year, month, day).toLocaleDateString("sv-SE")]),
                }}
                onMouseOver={() => handleMouseOver(formatDate(year, month, day))}
                onMouseOut={() => setHoveredDay(null)}
                onClick={() => toggleSelection(formatDate(year, month, day))}
              >
                <span className="day-number" style={{ pointerEvents: "none" }}>
                  {day}
                </span>
              </div>
              {/* {hoveredDay === formatDate(year, month, day) && (
                <div className="dayData">
                  {dayData.map((booking) => (
                    <>
                      {booking.status !== "Killed" && (
                        <div>
                          <span style={{ color: statusColor(booking.status), marginRight: "10px" }}>█</span>
                          {booking.artist.artist}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )} */}
            </>
          ))}
        </div>
      </div>
    );
  };

  function formatDate(year, month, day) {
    // Pad the month and day with a leading zero if necessary
    const paddedMonth = String(month + 1).padStart(2, "0");
    const paddedDay = String(day).padStart(2, "0");
    const dateString = `${year}-${paddedMonth}-${paddedDay}`;

    // Create a Date object and convert to the desired locale string
    const date = new Date(dateString);
    return date.toLocaleDateString("sv-SE");
  }

  const handleMouseDown = (date) => {
    if (!avails) {
      console.log("⊘ avails not set");
      return;
    }
    setIsSelecting(true);

    if (pickedDates.includes(date)) {
      // If already selected, remove from selection
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd433", opacity: "1" } }));
      setPickedDates([bookingData.date]);
      //   setPickedDates((previous) => [...pickedDates.filter((i) => i !== date)]);
    } else if (avails.includes(date)) {
      setPickedDates([date]);
      //   setPickedDates((previous) => [...previous, date]);
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd499", opacity: "1" } }));
    }
  };

  const handleMouseOver = (date) => {
    setHoveredDay(date);
  };

  useEffect(() => {
    console.log(hoveredDay, "hoveredDay");
    const filteredBookings = currentVenueBookings.filter((booking) => booking.date === hoveredDay);
    setDayData(filteredBookings);
  }, [hoveredDay]);

  const toggleSelection = (date) => {
    console.log("toggleSelection", date);

    if (pickedDates.includes(date)) {
      // If already selected, remove from selection and set old date
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd433", opacity: "1" } }));
      setPickedDates([bookingData.date]);
      console.log(bookingData.date);
      //   setPickedDates((previous) => [...pickedDates.filter((i) => i !== date)]);
    } else if (avails.includes(date)) {
      // If not selected, add to selection
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd499", opacity: "1" } }));
      // and reset color to previously picked dateunless it's the old booking date.
      pickedDates[0] !== bookingData.date &&
        setAvailColor((prev) => ({ ...prev, [pickedDates[0]]: { background: "#00ffd433", opacity: "1" } }));
      setPickedDates([date]);
      //   setPickedDates((previous) => [...previous, date]);
    }
  };

  const handleMouseUp = (date) => {
    setIsSelecting(false);
  };

  useEffect(() => {
    console.log("pickedDates", pickedDates);
  }, [pickedDates]);

  const [artist, setArtist] = useState([]);
  const [email, setEmail] = useState("Email");
  const [name, setName] = useState("");

  return (
    <Grid item>
      {currentVenue && currentVenue.products && (
        <h4 style={{ display: "flex", cursor: "pointer", color: "#ffffff" }} onClick={() => handleOpen()}>
          <WhatDayIsIt date={bookingData.date} />
          {bookingData.date}
          <div style={{ marginTop: "-2px", marginLeft: "10px" }}>
            <CalendarMonthIcon />
          </div>
        </h4>
      )}
      <Modal open={open} onClose={handleClose}>
        <Grid sx={style}>
          <Grid container className="pickDates" spacing={2}>
            <Grid item mb={4}>
              {chosenDateRange && <ProductSelect products={currentVenue.products} currentProductId={bookingData.productid} />}
            </Grid>
            <Grid item flexDirection="column">
              {currentProduct && ` ${currentProduct.product}: ${currentProduct.loadIn}-${currentProduct.loadOut}`}
              <Grid item style={{ opacity: "0.5" }}>
                {pickedDates &&
                  `${pickedDates[0] === bookingData.date || pickedDates[0] === undefined ? "Old Date:" : "New Date:"} ${
                    pickedDates[0] ? pickedDates[0] : bookingData.date
                  }`}
              </Grid>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                onClick={() => changeDate()}
                disabled={pickedDates[0] === bookingData.date || pickedDates[0] === undefined}
              >
                Set new Date
              </Button>
              <Button variant="contained" onClick={() => setOpen(false)} variant="outlined" style={{ marginLeft: "15px" }}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              {dayData.length > 0 && dayData.some((item) => item.status !== "Killed") && (
                <>
                  <p>Other on {hoveredDay}</p>
                  <div className="dayData">
                    {dayData.map((booking) => (
                      <p>
                        {booking.status && booking.status !== "Killed" && (
                          <div>
                            <span style={{ color: statusColor(booking.status), marginRight: "10px" }}>█</span>
                            {booking.artist.artist} {booking.loadIn} - {booking.loadOut}
                          </div>
                        )}
                      </p>
                    ))}
                  </div>
                </>
              )}
            </Grid>
            <div style={{ width: "100%", overflow: "auto", maxHeight: "70vh", marginTop: "30px" }}>
              {years.map((year, yearIndex) => (
                <div key={yearIndex}>
                  <span className="addBookingsYear">
                    <h2>{year}</h2>
                  </span>
                  {months.map((month, monthIndex) => (
                    <div key={monthIndex} style={{ userSelect: "none", WebkitUserSelect: "none" }}>
                      {showMonth(year, monthIndex)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default VhDatePicker;
