/** @format */

import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { storeCurrentProduct } from "../../features/venueFilter/venueFilterSlice";
import { privateIcon, publicIcon } from "../../helpers/customIcons";
import ProductFilter from "../../helpers/ProductFilter";
import { numberFormatted } from "../../helpers/Numbers";

const ProductSelect = ({ products, currentProductId }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const dispatch = useDispatch();
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  // const currentBooking = useSelector((state) => state.counter.currentBooking);

  const handleChange = (value) => {
    dispatch(storeCurrentProduct(value));
    // console.log("produtti", value);

    // If bookingData is passed, set the product in bookingData
  };

  // If a currentProductId is passed, set the currentProduct to that product
  useEffect(() => {
    if (currentProductId) {
      const tmpProduct = products.filter((product) => product.productid === currentProductId);
      // console.log("product", tmpProduct);
      // console.log("productId", currentProductId);
      dispatch(storeCurrentProduct(tmpProduct[0]));
      handleChange(tmpProduct[0]);
    }
  }, [currentProductId]);

  return (
    <>
      <FormControl>
        <InputLabel shrink={currentProduct !== ""} id="product-select"></InputLabel>
        <Select
          placeholder="Set product"
          style={{ width: "140px" }}
          id="product-select"
          size="small"
          className="selectFullwidth"
          onChange={(e) => handleChange(e.target.value)}
          label="Product"
          InputLabelProps={{
            shrink: currentProduct !== "",
          }}
        >
          {products &&
            products.map((product) => (
              <MenuItem defaultValue="Set product.." value={product} style={{ width: "100%" }}>
                {product.privatePublic === "Public Event" ? publicIcon : privateIcon} {product.product}{" "}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ProductSelect;
