/** @format */

import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { useEffect } from "react";

import ApiCalendar from "react-google-calendar-api";
import { useSelector } from "react-redux";
import { bookingsObj } from "../../dbObjects.js/dbBookings";
import { createId } from "../../firebase";
import ReplayIcon from "@mui/icons-material/Replay";
import GoogleIcon from "@mui/icons-material/Google";

const config = {
  clientId: "432523093238-16et6j29sbf06r22chi1fgkurkseak86.apps.googleusercontent.com",
  apiKey: "AIzaSyBkG4yC057TYQia4k6pi2sfnYladgPViaU",
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
};

const apiCalendar = new ApiCalendar(config);

const GoogleCalendar = () => {
  const uuid = require("uuid");

  // let eventId = uuid.v4();
  // console.log("eventid:", eventId);

  const newID = async () => {
    let eventId = await createId();
    console.log(eventId);
    return eventId;
  };

  let BookingsSynced = [];

  const currentBookings = useSelector((state) => state.counter.currentBookings);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [chosenCal, setChosenCal] = useState();
  const handleItemClick = async (event: SyntheticEvent<any>, name: string): void => {
    if (name === "sign-in") {
      // console.log(event);
      await apiCalendar.handleAuthClick(setIsLoggedIn);
      loopUntilSignedIn();
      // setEvents(result.items);

      // setTimeout(() => {
      //   setIsLoggedIn(true);
      // }, 5000);
      checkIfLoggedIn();
    } else if (name === "sign-out") {
      apiCalendar.handleSignoutClick();
      setIsLoggedIn(false);
    }
  };

  // function updateSigninStatus() {
  //   if (isSignedIn) {
  //     console.log("You are authorized");
  //     // listUpcomingEvents();
  //   } else {
  //     console.log("You are not authorized");
  //   }
  // }
  function checkIfLoggedIn() {
    if (ApiCalendar.listenSign === true) setIsLoggedIn(true);

    // apiCalendar.listenSign(function (user) {
    //   if (user && user.isLoggedIn) {
    //     // User is logged in
    //     // Do something here
    //     setIsLoggedIn(true);
    //   }
    // });
  }

  const getGoogleCalendarDateTime = (dateString) => {
    const dateObj = new Date(dateString);
    const dateTime = dateObj.toISOString();
    return dateTime;
  };

  function isWeekend(d) {
    var date = new Date(d);
    return date.getDay() === 5 || date.getDay() === 6 || date.getDay() === 0;
  }

  const starting = (date, time) => getGoogleCalendarDateTime(`${date}T${time}:00`);
  const ending = (date, time) => getGoogleCalendarDateTime(`${date}T${time}:00`);
  const loadIn = (booking) => {
    if (isWeekend(booking.date) === true) {
      return booking.loadInWeekends;
    } else {
      return booking.loadInWeekdays;
    }
  };
  const soundcheck = (booking) => {
    if (isWeekend(booking.date) === true) {
      return booking.soundcheckWeekends;
    } else {
      return booking.soundcheckWeekdays;
    }
  };
  const curfew = (booking) => {
    if (isWeekend(booking.date) === true) {
      return booking.curfewWeekends;
    } else {
      return booking.curfewWeekdays;
    }
  };

  let randomnumber = Math.floor(Math.random() * (200 - 100 + 1) + 100);

  let newEvent = (booking) => [
    {
      // id: `${booking.bookingid}`,
      id: createId(),
      eventType: "default",
      kind: "calendar#event",
      location: `${booking.venue}, ${booking.venueStreetAddress}, ${booking.venueZipCode}, ${booking.venueCountry}`,
      description: `${booking.artist}.
      Load in: ${loadIn(booking)}
      Soundcheck:${soundcheck(booking)}
      Doors:${booking.doors}
      Show:${booking.show}
      Curfew:${curfew(booking)}

      Arranger: ${booking.agent}
      Email: ${booking.agentEmail}
      Phone: ${booking.agentPhone}
      `,
      organizer: { email: "venhudev@gmail.com", self: true },
      reminders: { useDefault: true },
      sequence: 0,
      start: {
        // dateTime: `${booking.date}T${"21:00"}:00`,
        dateTime: starting(booking.date, loadIn(booking)),
        timeZone: "Europe/Berlin",
      },
      end: {
        // dateTime: `${booking.date}T${"18:00"}:00`,
        dateTime: ending(booking.date, booking.show),
        timeZone: "Europe/Berlin",
      },
      status: "confirmed",
      summary: booking.artist,
    },
  ];

  function checkAllValuesExist(booking) {
    var start = starting(booking.date, loadIn(booking));
    var end = ending(booking.date, booking.show);

    console.log(start);
    console.log(end);
  }

  function syncBookings() {
    BookingsSynced.forEach(async (booking) => {
      // await checkAllValuesExist(booking);

      console.log(...newEvent(booking));
      await apiCalendar
        .createEvent(...newEvent(booking))
        .then((result: object) => {
          console.log(result);
        })
        .catch((error: any) => {
          console.log(error);
        });
    });
  }

  const handleChangeCalendar = (e) => {
    console.log(e.target.value);
    setChosenCal(e.target.value);
    // setIsLoggedIn(true);
  };

  //Change state of isLogged in when signed in/out
  function loopUntilSignedIn() {
    // console.log(apiCalendar.checkSignedIn(setIsLoggedIn));
    if (apiCalendar.checkSignedIn(setIsLoggedIn) === true) wait();
    function wait() {
      setTimeout(() => {
        apiCalendar.checkSignedIn(setIsLoggedIn);
        if (apiCalendar.checkSignedIn(setIsLoggedIn) === false) return;
        else {
          loopUntilSignedIn();
        }
      }, 100);
    }
  }

  function fetchFromGoogleCalendar() {}

  useEffect(() => {
    if (isLoggedIn === true) {
      apiCalendar.listUpcomingEvents(10).then(({ result }: any) => {
        console.log(result);
        setEvents(result.items);
        apiCalendar.listCalendars().then(({ result }: any) => {
          console.log(result.items);
          setCalendars(result.items);
        });
      });
    }
    if (isLoggedIn === false) {
      setEvents([]);
    }
  }, [isLoggedIn]);

  return (
    <Grid xs={12}>
      {starting}
      {ending}
      {console.log(currentBookings)}
      {/* <Button
          onClick={async (e) => {
            // setIsLoggedIn(apiCalendar.checkSignedIn());
            apiCalendar.checkSignedIn(setIsLoggedIn);
          }}
          variant="outlined"
        >
          Check auth
        </Button> */}
      {!isLoggedIn && (
        <Button onClick={(e) => handleItemClick(e, "sign-in")} variant="outlined">
          <GoogleIcon style={{ marginRight: "10px", fontSize: "19px" }} /> sign in
        </Button>
      )}
      {isLoggedIn && (
        <div>
          <Button onClick={(e) => handleItemClick(e, "sign-out")} variant="outlined">
            sign-out
          </Button>

          {currentBookings.length !== 0 ? <p>Bookings to sync</p> : <p>No bookings to sync</p>}

          {currentBookings.length !== 0 &&
            currentBookings.map((booking) => {
              if (booking.status === "Confirmed") {
                return (
                  <div>
                    <span>{booking.date}</span> <span>{booking.artist}</span> <span>{booking.status}</span> <span>{booking.bookingid}</span>
                    {BookingsSynced.push(booking)}
                  </div>
                );
              }
            })}
          <p>Calendars</p>
          {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small"> */}
          {calendars.length === 0 && <p>No calendars to show</p>}

          <Select
            defaultValue="Chose a calendar"
            value={chosenCal}
            label="Age"
            size="small"
            onChange={handleChangeCalendar}
            style={{ width: "100%" }}
          >
            {calendars && calendars.map((calendar) => <MenuItem value={calendar.id}>{calendar.summary}</MenuItem>)}
          </Select>
          {/* </FormControl> */}

          {chosenCal && currentBookings.length !== 0 && (
            <Button onClick={() => syncBookings()} variant="contained">
              Push to Google Calendar
            </Button>
          )}

          {/* <Button
          onClick={async (e) => {
            const eventFromNow: object = {
              summary: "Poc Dev From Now",
              time: 480,
            };

            apiCalendar
              .createEventFromNow(eventFromNow)
              .then((result: object) => {
                console.log(result);
              })
              .catch((error: any) => {
                console.log(error);
              });
          }}
        >
          Sync the following Bookings
        </Button> */}

          <div style={{ padding: "0.5em" }}>
            <Button
              onClick={(e) => {
                apiCalendar.listUpcomingEvents(10).then(({ result }: any) => {
                  console.log(result);
                  setEvents(result.items);
                });
              }}
            >
              <ReplayIcon />
            </Button>
            <Grid item style={{ overflow: "scroll" }}>
              <p>Events</p>
              {events.length === 0 && <p>No events to show</p>}
              {events.map((event) => (
                // <p key={event.id}>{JSON.stringify(event)}</p>
                <p key={event.id}>
                  <span style={{ background: "#444", color: "white", padding: "0px 10px", borderRadius: "5px" }}>{event.summary}</span>
                  {event.start.dateTime} - {event.end.dateTime} - {event.id}
                </p>
              ))}
              {isLoggedIn && events.length !== 0 && (
                <Button onClick={() => fetchFromGoogleCalendar()} variant="contained">
                  Fetch from Google Calendar
                </Button>
              )}
            </Grid>
          </div>
          <div style={{ padding: "0.5em" }}>{console.log("bookingsToSync", BookingsSynced)}</div>
          {/* <button
          onClick={(e) => {
            apiCalendar.createCalendar("myCalendar2").then(({ result }: any) => {
              console.log(result);
            });
          }}
        >
          Create calendar
        </button> */}
        </div>
      )}
    </Grid>
  );
};

export default GoogleCalendar;
