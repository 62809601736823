/** @format */

import { Box, Slider } from "@mui/material";
import React, { useEffect } from "react";
// import Slider from "@mui/material/Slider";

const CancelationGraphs = ({ bookingData, setBookingData, formlock }) => {
  const x = 1.2;
  const width = "100px";
  const q = 1.5;

  useEffect(() => {
    console.log("earlyLeft", bookingData.earlyCancelationAt, "lateLeft", bookingData.lateCancelationAt);
  }, [bookingData]);

  return (
    <div className="cancelationGraphs" style={{ position: "relative", width: `27.3%`, height: "100px" }}>
      <div
        height="100px"
        style={{
          position: "absolute",
          left: `${365 - bookingData.earlyCancelationAt}%`,
          width: width,
          bottom: "-10px",
        }}
      >
        <label style={{ position: "absolute", bottom: `${bookingData.earlyCancelation + 5}%` }}>
          <span>Early</span> <span>{bookingData.earlyCancelation}%</span>
        </label>
        <Slider
          disabled={formlock}
          min={0}
          max={100}
          step={5}
          value={bookingData.earlyCancelation}
          valueLabelDisplay="off"
          orientation="vertical"
          onChange={(e) => setBookingData((prev) => ({ ...prev, earlyCancelation: e.target.value }))}
        />
      </div>
      <div
        height="100px"
        style={{
          position: "absolute",
          left: `${365 - bookingData.lateCancelationAt}%`,
          width: width,
          bottom: "-10px",
        }}
      >
        <label style={{ position: "absolute", bottom: `${bookingData.lateCancelation + 5}%` }}>
          <span>Late</span> <span>{bookingData.lateCancelation}%</span>
        </label>
        <Slider
          disabled={formlock}
          min={0}
          max={100}
          step={5}
          value={bookingData.lateCancelation}
          valueLabelDisplay="off"
          orientation="vertical"
          onChange={(e) => setBookingData((prev) => ({ ...prev, lateCancelation: e.target.value }))}
        />
      </div>
    </div>
  );
};

export default CancelationGraphs;
