/** @format */

import { Button, CircularProgress, Grid, Popper, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { set } from "date-fns";
import React, { useEffect, useState } from "react";
import SyncNortic from "../helpers/SyncNortic";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db, getBookingsByVenueid } from "../firebase";
import VenueInvoice from "../finshark/VenueInvoice";
import Tmp_VenueInvoice from "../finshark/Tmp_VenueInvoice";
import SubscriptionInput from "../helpers/SubscriptionInput";
import Settler from "../components/Banking/Settler";
import axios from "axios";
import FinsharkTransactions from "../finshark/FinsharkTransactions";
import LedgerView, { createCreditPost, createDebitPost } from "./LedgerView";
import PreChargeStatement from "../finshark/PreChargeStatement";
import BatchAgreementModal from "../components/Documents/BatchAgreementModal";
import DataSheetPremises from "../components/VisualData/DataSheetPremises";
import { log } from "../helpers/log";
import GetTixlyEvents from "../features/tixly/GetTixlyEvents";
import { CreateAdditionalPaymentPost } from "../components/Banking/CreateAdditionalPaymentPost";
import { numberFormatted } from "../helpers/Numbers";
import SimpleSignup from "../helpers/simpleSignup";
import { MonthNavigator } from "../components/MiscUIComponents/MonthNavigator";
import MonthRangePicker from "../components/UI helpers/MonthRangePicker";
import PdfMaker from "../components/UI helpers/PdfMaker";
import PdfDownloader from "../components/Documents/PdfDownloader";
import ColdplayPopularityInSweden from "../helpers/SpotifyArtistPopularity";
import PaymentSlider from "../helpers/PaymentSlider";
import CancelationSlider from "../helpers/CancelationSlider";
// import PdfMaker from "../components/UI helpers/PdfMaker";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import OCRGenerator from "../helpers/OCRGenerator";
import { useSelector } from "react-redux";
import EPCQRCode from "../components/Banking/EPCQRCode";
import { generateOCR } from "../helpers/generateOCR";
import TicksterTest from "../components/MiscUIComponents/Testing/TicksterTest";
import SkapaQrKod from "../helpers/SkapaQrKod";
import Settlement from "../components/Documents/Settlement";
import EventTravelForm from "./EventTravelForm";
import ObjectSize from "../helpers/ObjectSize";
import LoadingDots from "../components/UI helpers/LoadingDots";
import getTicketEventIdFromBookingId from "../components/MiscUIComponents/getTicketEventIdFromBookingId";
import getAllTicketSalesFromAgreementId from "../components/MiscUIComponents/getAllTicketSalesFromAgreementId";
import DevTodoList from "../helpers/DevTodoList";
import { NumericFormat } from "react-number-format";
import InsTranslate from "../helpers/InsTranslate";
import PdfAgreementArchiver from "../components/Documents/PdfAgreementArchiver";
import CheckArchivedAgreements from "../helpers/CheckArchivedAgreements";
import TicketFinderTickster from "../features/tickster/TicketFinderTickster";
import ModulesBackup from "./ModulesBackup";
import { calculateDistances } from "./calculateDistances";
import AgreementAddendum from "../components/Documents/AgreementAddendum";
import CreateSubOrganizerNortic from "../features/nortic/CreateSubOrganizerNortic";
import CreateEventNortic from "../features/nortic/CreateEventNortic";
import CreateTicketCategoriesNortic from "../features/nortic/CreateTicketCategoriesNortic";
import NewAgreement from "../components/Documents/newAgreement";
import ShowTicketCategoriesNortic from "../features/nortic/ShowTicketCategoriesNortic";
// import calculateDistances from "../helpers/calculateDistances";

const TestSite = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const userRole = useSelector((state) => state.counter.userRole);
  // const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  const [userData, setUserData] = useState(false);
  const [formDB, setFormDB] = useState([]);
  const [string, setString] = useState([]);
  const [venue, setVenue] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // console.log("DATA", calculateDistances());
    let categories = categorizeDistances(calculateDistances());
    console.log("Categories", categories);
  }, []);

  function categorizeDistances(data) {
    const categories = {
      "15km or less": 0,
      "15-50km": 0,
      "50-500km": 0,
      "More than 500km": 0,
    };

    data.forEach((item) => {
      const distance = item.distance;
      const visitors = item.visitors;

      if (distance <= 15) {
        categories["15km or less"] += visitors;
      } else if (distance > 15 && distance <= 50) {
        categories["15-50km"] += visitors;
      } else if (distance > 50 && distance <= 500) {
        categories["50-500km"] += visitors;
      } else {
        categories["More than 500km"] += visitors;
      }
    });

    return categories;
  }

  // useEffect(() => {
  //   // load venue from "Venues" collection where venueid = currentVenue.venueid.
  //   async function getData() {
  //     const venueSnap = await getDoc(doc(db, "Venues", "wjrbwst2ttgcxv3s6ptyqphqqr2k7kk5"));
  //     console.log("Document data:", venueSnap.data());
  //     setVenue(venueSnap.data());
  //   }
  //   getData();
  // }, []);

  // useEffect(() => {
  //   if (userRole === "none") return;
  //   // load venue from "Venues" collection where venueid = currentVenue.venueid.
  //   async function getData() {
  //     setLoading(true);
  //     // const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", "wjrbwst2ttgcxv3s6ptyqphqqr2k7kk5")));
  //     // const querySnapshot = await getDocs(collection(db, "Venues"));
  //     const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", "yshmiexkxg5bipm5xh7uuyd3ot7pehjh")));
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       console.log(doc.id, " => ", doc.data());
  //     });
  //     console.log(
  //       "Booking data:",
  //       querySnapshot.docs.map((doc) => doc.data())
  //     );
  //     setLoading(false);
  //   }
  //   if (userRole === "owner") getData();
  // }, [userRole]);

  // async function fetchTickets() {
  //   try {
  //     const querySnapshot = await getDocs(collection(db, "temporary/eventid1234/tickets"));
  //     const tickets = [];
  //     querySnapshot.forEach((doc) => {
  //       tickets.push({ id: doc.id, ...doc.data() });
  //     });
  //     return tickets;
  //   } catch (error) {
  //     console.error("Error fetching tickets:", error);
  //     throw error;
  //   }
  // }

  // fetchTickets().then((tickets) => {
  //   console.log("Tickets:", tickets);
  // });

  // const d = new Date().toISOString().split("T")[0];
  // const d = new Date().toLocaleDateString("sv-SE");

  // CreateAdditionalPaymentPost({
  //   email: "marcus.dernulf@venuehub.live",
  //   amount: 10,
  //   due: "2024-01-01",
  //   nextDateToCheck: "2024-01-15",
  //   bookingids: "abc123",
  //   venue: "Maxime",
  //   referenceId: "Test123",
  // });
  // CreateAdditionalPaymentPost("marcus.dernulf@venuehub.live", 10, "2024-01-01", "2024-01-15", "abc123", "Maxime", "Test123");
  // CreateAdditionalPaymentPost(email, amount, due, nextDateToCheck, bookingids, venue, referenceId);

  // THIIS!!
  // userRole != "owner" ? navigate("/Home") : console.log("Access granted");
  // if (userRole == "none") navigate("/Home");

  // async function getTransactions() {
  //   console.log("getting transactions...");
  //   const body = { email: "marcus.dernulf@venuehub.live" };
  //   const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/finsharkGetAllTransactions`, body);
  //   console.log(response.data);
  // }

  async function trigger() {
    console.log("Starting...");
    const body = { email: "marcus.dernulf@venuehub.live" };
    // const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/checker`, body);  //for live
    const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/checker`, body); // for testing

    console.log("💛 💛 💛", response.data);
  }

  async function triggerTickster() {
    console.log("testing events by venue...");
    const body = {
      venue: currentVenue.venue,
      organizer: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
      venueid: currentVenue.venueid,
    };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicksterEventsByVenue`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicksterEventsByVenue`, body); // for testing
    setEvents(response.data);
    console.log("💚 💚 💚", response.data);
  }

  async function getTicksterTicketsByEvents() {
    console.log("getting tickets... (by event)");
    const body = {
      organizer: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
    };
    // const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicksterTicketsByEvent`, body); //for live
    const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicksterTicketsByEvent`, body); // for testing Tickets
    console.log("💚 ❤️ 💚", response.data);
  }

  async function triggerTicksterTickets() {
    console.log("testing tickets by venue...");
    const body = {
      // ticketVenueId: "jadwwwhevyyr058", // debaser = jadwwwhevyyr058
      // venueApiKey: "62dbc821f0c4d5181a49f4f00cb257f2", // debaseer = 62dbc821f0c4d5181a49f4f00cb257f2
      ticketVenueId: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
    };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicksterTicketsByVenue`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicksterTicketsByVenue`, body); // for testing Tickets
    console.log("💚 ❤️ 💚", response.data);
  }

  async function summarizeTickets() {
    console.log("Summarizing tickets...");
    const body = {
      ticketVenueId: "DCHMLTF6FCWGXMP", // maxim = DCHMLTF6FCWGXMP
      venueApiKey: "8fd52faea08489dce1a58a07040b22fa", // maxim = 8fd52faea08489dce1a58a07040b22fa
    };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/summarizeTickster`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/summarizeTickster`, body); // for testing Tickets
    console.log("SuMmariZed 💚 ❤️ 💚", response.data);
  }

  async function triggerEncrypt() {
    const body = { string: string };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/encryptString`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/encryptString`, body); // for testing
    console.log("❤️ ❤️ ❤️", response.data);
    setString(response.data.encryptedString);
  }

  async function triggerDecrypt() {
    const body = { string: string };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/decryptString`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/decryptString`, body); // for testing
    console.log("❤️ ❤️ ❤️", response.data);
    setString(response.data.decryptedString);
  }

  async function generateQR() {
    console.log("Generating QR code...");
    const response = await axios.post(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/generateAndUploadQRCode?ocr=8fd52faea08489dce1a58a07040b22fa&&amount=100&&referenceId=upfront2342334jk432`
    );
    // const response = await axios.post(
    //   `http://127.0.0.1:5001/venuehub-64e72/europe-west3/generateAndUploadQRCode?ocr=8fd52faea08489dce1a58a07040b22fa&&amount=100&&referenceId=upfront2342334jk432`
    // ); //for testing
    console.log("QR code URL:", response.data);
  }

  async function getFees() {
    console.log("getting Fees...");
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getAdditionalFees`);
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getAdditionalFees`); //for testing
    console.log("getFees:", response.data);
  }

  async function archivePDF() {
    console.log("Archiving...");
    const pdfDoc = `<h1>Test</h1>`;

    const htmlData = `<table>
    <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
    </tr>
    <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
    </tr>
    <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
    </tr>
    <tr>
    
    </table>`;
    const body = {
      htmlData,
      filename: "test",
      agreementId: "1234-567",
      venueId: "7654-321",
      venueEmail: "agent@venuehub.live",
      agentEmail: "marcus.dernulf@venuehub.live",
    };
    // const response = await axios.post(
    //   "http://127.0.0.1:5001/venuehub-64e72/europe-west3/generatePdfForArchive",
    //   body // Important: Set the response type to 'blob' for PDF download
    // );
    const response = await axios.post("https://europe-west3-venuehub-64e72.cloudfunctions.net/generatePdfForArchive", body);
    console.log("done...", response.data);
  }

  // async function caller() {
  //   const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/callAnotherFunction`);
  //   console.log("Caller", response.data);
  // }

  // async function fetchData(eventId) {
  //   try {
  //     log("test", eventId);
  //     // URL of the API
  //     const url = `https://europe-west3-venuehub-64e72.cloudfunctions.net/getTixlyEventTicketSummary?eventId=${eventId}`;

  //     // Fetch the data
  //     const response = await fetch(url);
  //     console.log(response);
  //     // Check if the response is okay (status 200-299)
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     // Parse the JSON response
  //     const data = await response.json();

  //     // Here, 'data' contains the response object
  //     console.log("data", data);
  //     return data;
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  // Example usage
  // const eventId = 72138;
  // fetchData(eventId).then((data) => {
  //   if (data) {
  //     console.log("Fetched data:", data);
  //     // You can now use 'data' as needed
  //   }
  // });

  const [OCR, setOCR] = useState(generateOCR());
  const [newVal, setNewVal] = useState(0);

  return (
    <Grid container p={8} spacing={4} flexDirection="row" style={{ display: "flexWrap", gap: "10px", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          padding: "15px",
          background: "#00ffd411",
          dixplay: "flex",
          gap: "30px",
          flexDirection: "column",
          margin: "10px",
        }}
      >
        {/* <NewAgreement agreementId={"iq3wv25sypdrysyhbuwlsvhdnu545ynb"} language={"svenska"} /> */}
      </div>
      {/* <AgreementAddendum agreementId={"z6rh3sgt4ilf5dnuyuq7k6twqsweehmy"} type="sign" /> */}
      <div style={{ width: "100%", padding: "15px", background: "#00ffd411", dixplay: "flex", gap: "30px", flexDirection: "column" }}>
        <h4>Nortic stuff</h4>
        <div>
          <ShowTicketCategoriesNortic />
          <CreateTicketCategoriesNortic />
        </div>
        <div>
          <CreateSubOrganizerNortic />
        </div>
        <div>
          <CreateEventNortic />
        </div>

        <div>
          <SyncNortic ticketOrganizerId={2915} ticketEventId={51819} />
        </div>
      </div>

      <BatchAgreementModal agreementId={"lspxl45rcdydyaehuhynm7kxsfqpb37u"} sign="venueSignature" />
      {/* <CheckArchivedAgreements /> */}
      {/* <InsTranslate phrase="Hello" from="en" to="sv" /> */}
      {/* <BatchAgreementModal agreementId="52qun3q5hvnr2y2eno5fiqnfyqd3sv63" open="true" /> */}
      {superOwner && (
        <>
          {/* <TextField label="test" value={NumericFormat} value={12345} thousandSeparator /> */}
          <NumericFormat
            value={0}
            suffix={currentVenue.currency}
            thousandSeparator=" "
            customInput={TextField}
            onValueChange={(event) => {
              const { formattedValue, value } = event;
              setNewVal(value); // Use the unformatted 'value' property
            }}
          />
          {/* <TicketFinderTickster /> */}
          {/* <TextField label="test" value={newVal} /> */}
          {/* <Popper open={true}>
            <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
              <CircularProgress />
            </Box>
          </Popper> */}
          <DevTodoList />
          <ObjectSize dataObject={venue} />
          {/* <div style={{ width: "100%", background: "red", height: "700px", padding: "20px", overflow: "scroll" }}>
            <ModulesBackup />
          </div> */}
          User role: {userRole}
          {loading && <h1>Loading{<LoadingDots speed={300} />}</h1>}
          {/* <EventTravelForm /> */}
          {/* <Settlement bookingid="ityhjkzdw5g2fqkvi6qj2vvtkvwjtbke" /> */}
          {/* <SkapaQrKod /> */}
          {/* <CancelationSlider /> */}
          {/* <PaymentSlider /> */}
          {/* {OCR}
      <OCRGenerator /> */}
          {/* <TicksterTest /> */}
          {/* <h1>{d}</h1> */}
          {/* <EPCQRCode bg={"363-0274"} ocr={"1234 5678 2345 6789"} amount={123.45} /> */}
          {/* <ColdplayPopularityInSweden clientId="6cd07c087f234c9a9cc75a0d401352e6" /> */}
          {/* <PdfDownloader /> */}
          {/* <PdfMaker /> */}
          {/* <DataSheetPremises /> */}
          {/* <div style={{ background: "red", height: "100px", width: "200px" }}>
        <BatchAgreementModal agreementId={"qzgl2sorhurne35lzyrct3q3iga2evtj"} sign="venueSignature" />
      </div> */}
          {/* <GetTixlyEvents /> */}
          {/* <PreChargeStatement /> */}
          <Grid item xs={12}>
            {/* <MonthRangePicker /> */}
            {/* <LedgerView /> */}
          </Grid>
          <Grid item>
            <Button onClick={() => archivePDF()} color="secondary">
              Go archive som pdf
            </Button>
          </Grid>
          <Grid item>
            <Grid item style={{ display: "flex", gap: "15px" }}>
              <Button onClick={() => trigger()} variant="outlined">
                Trigger Checker
              </Button>
              <Button onClick={() => triggerTickster()} variant="outlined">
                Tickster events
              </Button>
              <Button onClick={() => triggerTicksterTickets()} variant="outlined">
                Tickster tickets
              </Button>
              <Button onClick={() => getTicksterTicketsByEvents()} variant="outlined">
                Tickster tickets (by event)
              </Button>
              <Button onClick={() => summarizeTickets()} variant="outlined">
                Summarize
              </Button>
              <Button onClick={() => triggerEncrypt()} variant="outlined">
                Encrypt
              </Button>
              <Button onClick={() => triggerDecrypt()} variant="outlined">
                Decrypt
              </Button>
              <Button onClick={() => generateQR()} variant="outlined">
                Server side QR code
              </Button>
              <Button onClick={() => getFees()} variant="outlined">
                Get Fees
              </Button>
              {/* <Button onClick={() => getBookings()} variant="outlined">
            getBookings
          </Button> */}
              <input type="text" value={string} onChange={(e) => setString(e.target.value)} />
              {/* <Grid item>
            <Button onClick={() => caller()} variant="outlined">
              Caller test
            </Button>
          </Grid> */}
            </Grid>
          </Grid>
          <Grid item mt={4}>
            <FinsharkTransactions />
          </Grid>
          <Grid item>
            {/* <Settler agreementId="6mekavlyn2eiinyyekidyvnoiwi7gqcu" type="signedAgreement" span={currentVenue.span} />{" "} */}
          </Grid>
          {/* THIS */}
          {/* <Settler agreementId="rvqpfnrmpemr65vammuplaz7azbaalts" type="signedAgreement" span="Monthly" /> */}
          {/* <Settler agreementId="eh6xfcqxnh6mpsourv3u3223fggoppig" type="signedAgreement" span="Monthly" /> */}
          {/* <Settler agreementId="gfs5fgig77whiqx6wwehuylaj5zazz6z" type="preCharge" /> */}
          {/* <SubscriptionInput /> */}
          {/* <Tmp_VenueInvoice
        bookingid={"bvi4pzz4zsqpjkbfowdksd57wnsov5r6"}
        // paymentButton={!booking.deductFromTickets && true}
        paymentButton={true}
        payoutButton={false}
        sendButton={true}
        // handleSend={handleSend}
        // close={handleCloseInvoice}
      /> */}
          {/* <SyncNortic ticketOrganizerId={2915} ticketEventId={51819} /> */}
        </>
      )}
    </Grid>
  );
};

export default TestSite;
