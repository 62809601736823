/** @format */

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";

const UnsavedChanges = ({ New, Old, trigger }) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  console.log("New", New);
  console.log("Old", Old);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>UnsavedChanges</DialogTitle>
      <DialogContent>Hej hej</DialogContent>
      <DialogActions>
        <Button onClick={trigger}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChanges;
