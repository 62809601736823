/** @format */

import React from "react";
import BookingForm from "../components/Forms/BookingForm";

const Booking = () => {
  return (
    <div>
      <BookingForm />
    </div>
  );
};

export default Booking;
