/** @format */

import React, { useEffect, useState } from "react";
import { collection, getDocs, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";
import { useSelector, useDispatch } from "react-redux";
import { storeBookings } from "../features/venueFilter/venueFilterSlice";
import GetBookingsForCurrentVenue from "./GetBookingsForCurrentVenue";

const GetBookings = () => {
  const [bookings, setBookings] = useState([]);
  const bookingCollectionRef = collection(db, "Bookings");
  const count = useSelector((state) => state.counter.count);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getDocs(bookingCollectionRef);
      setBookings(data.docs.map((doc) => doc.data()));
    };
    getData();
  }, []);

  useEffect(() => {
    dispatch(storeBookings(bookings));
  }, [bookings]);

  return <div>{/* <GetBookingsForCurrentVenue /> */}</div>;
};

export default GetBookings;
