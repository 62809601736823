/** @format */

import React, { useEffect, useRef, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import { Badge, Button, Card, Icon, ListItemButton } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LikeButton from "../../components/MiscUIComponents/LikeButton";
import VenueRating from "../../components/MiscUIComponents/VenueRating";
import { VenueInfoModal } from "../../components/Modals/VenueInfoModal";
import SimpleSelectAvailDates from "../../components/UI helpers/SimpleSelectAvailDates";
import { storeSortVenuesBy } from "../venueFilter/venueFilterSlice";
import ProductSelect from "../../components/MiscUIComponents/ProductSelect";

const StandardVenueCard = (props) => {
  const sortVenuesBy = useSelector((state) => state.counter.sortVenuesBy);
  // const [venues, setVenues] = useState([]);
  // const [filteredVenues, setFilteredVenues] = useState([]);
  var [dateIsSet, setDateIsSet] = useState(0);
  //   const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);
  const [showVenueInfo, setShowVenueInfo] = useState(false);
  const [liked, setLiked] = useState();
  const [sortedVenues, setSortedVenues] = useState();
  const dispatch = useDispatch();
  var previousVenueCity = "none";

  const venue = props.venue;

  return (
    <div>
      <div>
        <Card className="VenueCardBooking">
          {/* <div style={{ background: "linear-gradient(#333333,#111)", padding: "15px", height: "100%" }}> */}
          <Badge color="info" badgeContent={venue.numberOfAvails - 1} className="vcBadge"></Badge>
          <div className="venueCardBookingImage">
            <img src={venue.venueImage} width="auto" height="130px" />
            {/* <div className="venueImgOverlay"></div> */}
            {/* <div className="vcRating"><VenueRating /></div> */}
          </div>

          <div className="vcVenue">
            <p>{venue.venue}</p>

            <div className="vcInfo">
              <PlaceIcon />
              {venue.venueCity}
              <PeopleIcon />
              {venue.capacity}
              <PointOfSaleIcon />
              {venue.price} kr
            </div>
            {venue.products && (
              <div className="vcProductSelect">
                <ProductSelect products={venue.products} />
              </div>
            )}
          </div>
          <div className="vcLikeBtn">
            <LikeButton liked={setLiked} />
          </div>
          {/* </div> */}
        </Card>
      </div>
    </div>
  );
};

export default StandardVenueCard;
