/** @format */

import { FormControl, InputLabel, MenuItem, Select, Slider } from "@mui/material";
import React from "react";
import PieChartCenteredLabel from "../UI helpers/PieChartCenteredLabel";

const ElectricitySource = ({ handleChange, formData }) => {
  return (
    <div className="visualData">
      <h7>Electricity Source</h7>
      <div className="dataInput">
        <FormControl variant="outlined">
          <InputLabel shrink>Type of electricity</InputLabel>
          <Select
            value={formData.electricitySource ? formData.electricitySource : 0}
            onChange={(e) => handleChange("electricitySource", e.target.value)}
            style={{ minWidth: "200px" }}
            size="small"
          >
            <MenuItem value="Renewable">Renewable electricity</MenuItem>
            <MenuItem value="Non renewable">Non renewable electricity</MenuItem>
            <MenuItem value="Dont know">Dont know</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default ElectricitySource;
