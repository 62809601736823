/** @format */

export function generateOCR() {
  let uniqueOcrNumber = "";
  generateOcrNumber();

  // Funktion för att generera OCR-nummer // todays date + 8 numbers
  function generateOcrNumber() {
    let invoiceNumber = generateRandomNumberString();
    const today = new Date();
    const dateString = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, "0")}${today
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    uniqueOcrNumber = `${dateString}${invoiceNumber.toString().padStart(6, "0")}`;
    console.log("uniqueOcrNumber", uniqueOcrNumber);
  }

  function generateRandomNumberString() {
    // 8 numbers randomly generated
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += Math.floor(Math.random() * 10); // Generate a random integer between 0 and 9
    }
    return result;
  }

  return uniqueOcrNumber;
}
