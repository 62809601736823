/** @format */

import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import AgreementModal from "./AgreementModal";
import { jsPDF } from "jspdf";
import { Button, CircularProgress, InputAdornment, Modal, Switch, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { AES, enc } from "crypto-js";
import GeneralForm from "../../helpers/GeneralForm";
import { userObj } from "../../dbObjects.js/dbUser";
import DisplayObject from "../../helpers/displayObject";
import SimpleSendEmail from "../UI helpers/SimpleSendEmail";
import EmailConfirmationVenueSignature from "../../helpers/EmailConfirmationVenueSignature";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { storeCurrentBookingId, storeCurrentUser } from "../../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { db } from "../../firebase";

const AgreementSign = (props) => {
  const agreementId = props.agreementId;
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);
  const [bookingId, setBookingId] = useState(props.bookingid);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentBookingId = useSelector((state) => state.counter.currentBookingId);
  const secretKey = process.env.REACT_APP_FORM_KEY;
  const encryptedSocialId = props.socialId;
  const initialSocialId = AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8);
  const dispatch = useDispatch();

  // console.log(props.socialId);
  // const socialId = AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8);
  const [socialId, setSocialId] = useState(AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8));
  const [decryptedSocialId, setDecryptedSocialId] = useState(AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8));
  // const phoneRef = +46707402114;
  const phoneRef = props.phone;
  const type = props.sign;
  const [company, setCompany] = useState(currentUser.company);
  const [orgNumber, setOrgNumber] = useState(currentUser.orgNumber);
  const [streetAddress, setStreetAddress] = useState(currentUser.streetAddress);
  const [zipCode, setZipCode] = useState(currentUser.zipCode);
  const [city, setCity] = useState(currentUser.city);
  const [country, setCountry] = useState(currentUser.country);
  const [formData, setFormData] = useState(currentUser);
  // console.log("SHOW", props.bookingData.agent);
  console.log("SHOW", currentUser);
  // const userId = "mdernulf@gmail.com";
  const userId = currentUser.email;
  const returnAddress = window.location.href;
  console.log("socialId", socialId);
  console.log("userId", userId);
  function signAgreement() {
    setLoading(true);
    fetch(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/verifyId?socialId=${socialId}&phoneNumber=${phoneRef}&type=${type}&userId=${userId}&bookingId=${bookingId}&returnAddress=${returnAddress}&`
    )
      .then((response) => response.json())
      .then((data) => {
        window.open(data.url, "_blank");
        // window.open(data.url);
        // window.location.replace(data.url);
        console.log("data", data);
        setLoading(false);
        // dispatch(storeCurrentUser(formData)); // *THIS !!!*
      })
      .catch((error) => console.error(error));
  }
  console.log("return to: ", returnAddress);
  // alert(AES.decrypt(props.socialId, secretKey).toString(enc.Utf8));

  function handleChange(key, value) {
    setFormData((prev) => ({ ...prev, [key]: value }));
    // dispatch(storeCurrentUser((prev) => ({ ...prev, [key]: value })));
  }

  useEffect(() => {
    //THIS!!! Den här gör att hela sidan laddas om.
    // dispatch(storeCurrentUser(formData));
  }, [formData]);

  useEffect(() => {
    // Put bookingId in Redux
    bookingId && dispatch(storeCurrentBookingId(bookingId));
    // If returning from another page (missing bookingId) set it from Redux
    if (!bookingId) setBookingId(currentBookingId);

    if (initialSocialId !== "") setChecked(true);
  }, []);

  function handleSocialId(value) {
    const encryptedValue = AES.encrypt(value, secretKey).toString();
    setDecryptedSocialId(value);
    // setSocialId(value);
    handleChange("socialId", encryptedValue);
  }

  // TO DO: store user data (inc or ex social id)
  async function handleDone() {
    let tmpFormData = { ...formData };
    if (!checked) tmpFormData.socialId = "";
    await updateDoc(doc(db, "Users", currentUser.userid), {
      ...tmpFormData,
    });

    setDone(true);
  }

  // Storing or not storing socialId
  const [checked, setChecked] = useState(false);
  const handleSwitch = (event) => {
    setChecked(event.target.checked);
    props.sign === "venueSignature" && handleDone();
  };

  if (props.sign)
    return (
      <>
        {props.sign === "agentSignature" && (
          <>
            {/* <h1>{props.sign}</h1> */}
            <h6>Make sure your details below are correct</h6>
            {!currentUser.company ||
            !currentUser.orgNumber ||
            !currentUser.streetAddress ||
            !currentUser.zipCode ||
            !currentUser.city ||
            !currentUser.country ||
            decryptedSocialId.length !== 12 ? (
              <div>Please enter missing information to sign agreement.</div>
            ) : (
              ""
            )}

            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", marginTop: "10px" }}>
              <TextField
                disabled={done}
                type="number"
                error={decryptedSocialId.length !== 12}
                defaultValue={decryptedSocialId}
                label="Social ID"
                placeholder="YYYYMMDDXXXX"
                size="small"
                onChange={(e) => handleSocialId(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={checked ? "Storing Social ID to your profile" : "Not storing Social ID to your profle"}>
                        <Switch checked={checked} onChange={handleSwitch} color="primary" />
                      </Tooltip>
                      {/* {decryptedSocialId.length === 12 && <CheckCircleIcon style={{ color: "#0080006b" }} />} */}
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="Encrypted">
                        <EnhancedEncryptionIcon style={{ color: "#555", zIndex: "100000" }} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              {/* {!AES.decrypt(props.socialId, secretKey).toString(enc.Utf8) && (
            <div>
              Store my Social ID <Switch checked={checked} onChange={handleSwitch} color="primary" />
            </div>
          )} */}
              <TextField
                size="small"
                label="Company Name"
                error={!currentUser.company}
                defaultValue={company}
                // onChange={(e) => setCompany(e.target.value)}
                onChange={(e) => handleChange("company", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Organization Number"
                error={!currentUser.orgNumber}
                defaultValue={orgNumber}
                // onChange={(e) => setOrgNumber(e.target.value)}
                onChange={(e) => handleChange("orgNumber", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Street Address"
                error={!currentUser.streetAddress}
                defaultValue={streetAddress}
                // onChange={(e) => setStreetAddress(e.target.value)}
                onChange={(e) => handleChange("streetAddress", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Zip Code"
                error={!currentUser.zipCode}
                defaultValue={zipCode}
                // onChange={(e) => setZipCode(e.target.value)}
                onChange={(e) => handleChange("zipCode", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="City"
                error={!currentUser.city}
                defaultValue={city}
                // onChange={(e) => setCity(e.target.value)}
                onChange={(e) => handleChange("city", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Country"
                error={!currentUser.country}
                defaultValue={country}
                // onChange={(e) => setCountry(e.target.value)}
                onChange={(e) => handleChange("country", e.target.value)}
                disabled={done}
              />
              <Button
                onClick={() => handleDone()}
                variant="outlined"
                disabled={
                  decryptedSocialId.length !== 12 ||
                  !currentUser.company ||
                  !currentUser.orgNumber ||
                  !currentUser.streetAddress ||
                  !currentUser.zipCode ||
                  !currentUser.city ||
                  !currentUser.country ||
                  done
                }
              >
                Ready to Sign
              </Button>
            </div>
          </>
        )}
        {props.sign === "venueSignature" && (
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", marginTop: "10px" }}>
            <TextField
              disabled={done}
              type="number"
              error={decryptedSocialId.length !== 12}
              defaultValue={decryptedSocialId}
              label="Social ID"
              placeholder="YYYYMMDDXXXX"
              size="small"
              onChange={(e) => handleSocialId(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={checked ? "Storing Social ID to your profile" : "Not storing Social ID to your profle"}>
                      <Switch checked={checked} onChange={handleSwitch} color="primary" />
                    </Tooltip>
                    {/* {decryptedSocialId.length === 12 && <CheckCircleIcon style={{ color: "#0080006b" }} />} */}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Encrypted">
                      <EnhancedEncryptionIcon style={{ color: "#555", zIndex: "100000" }} />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        <Button
          disabled={
            (props.sign === "agentSignature" &&
              (decryptedSocialId.length !== 12 ||
                !currentUser.company ||
                !currentUser.orgNumber ||
                !currentUser.streetAddress ||
                !currentUser.zipCode ||
                !currentUser.city ||
                !currentUser.country ||
                !done)) ||
            (props.sign === "venueSignature" && decryptedSocialId.length !== 12)
          }
          style={{ marginTop: "20px" }}
          variant="contained"
          size="large"
          color="success"
          onClick={() => signAgreement()}
        >
          {loading && <CircularProgress sx={{ zoom: "0.4" }} style={{ marginRight: "15px" }} />}
          Sign Agreement
        </Button>
      </>
    );
};

export default AgreementSign;
