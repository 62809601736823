/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
let Data = [];
const DelayedTimeline = () => {
  const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);

  useEffect(() => {
    async function timer() {
      for (let i = 0; i < 100; i++) {
        await new Promise((resolve) => {
          setTimeout(() => {
            Data.push(i);
            resolve(true);
          }, 100);
        });
      }
    }
    timer();
  }, [availableVenuesAndDates]);

  // timer();
  //   return timer;
  return (
    <div>
      <h5>hej</h5>
    </div>
  );
};

export default DelayedTimeline;
