/**
 * *******************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 * ********************************************************************
 *
 * @format
 */

import React, { useEffect, useState } from 'react';
// import Ellipse15Image from 'src/assets/images/nuVenueCard_Ellipse_15.png';
// import Vector1Image from 'src/assets/images/nuVenueCard_Vector_1.png';
// import Star1Image from 'src/assets/images/nuVenueCard_Star_1.png';
// import Star2Image from 'src/assets/images/nuVenueCard_Star_2.png';
// import Star3Image from 'src/assets/images/nuVenueCard_Star_3.png';
// import Star4Image from 'src/assets/images/nuVenueCard_Star_4.png';
// import Vector2Image from 'src/assets/images/nuVenueCard_Vector_2.png';
// import Ellipse16Image from 'src/assets/images/nuVenueCard_Ellipse_16.png';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
	storeCurrentVenue,
	storeUIDrawerOpen,
} from '../features/venueFilter/venueFilterSlice';
import useChangeVenue from './useChangeVenue';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { reloadCalendarBookings } from './Listeners';

const NuVenueCard = styled('div')({
	backgroundColor: `rgba(255, 255, 255, 1)`,
	display: `flex`,
	position: `relative`,
	isolation: `isolate`,
	flexDirection: `row`,
	width: '100%',
	height: `258px`,
	justifyContent: `flex-start`,
	alignItems: `flex-start`,
	padding: `0px`,
	boxSizing: `border-box`,
	overflow: `hidden`,
});

const Title = styled('div')({
	textAlign: `left`,
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `700`,
	fontSize: `20px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
	width: `200px`,
	height: `28px`,
	position: `absolute`,
	left: `8px`,
	top: `146px`,
});

const UpcomingEventsSpan1 = styled('span')({
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `700`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
});

const UpcomingEventsSpan2 = styled('span')({
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `400`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
});

const UpcomingEvents = styled('div')({
	textAlign: `left`,
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `400`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
	width: `200px`,
	height: `12px`,
	position: `absolute`,
	left: `8px`,
	top: `213px`,
});

const RequestsSpan1 = styled('span')({
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `700`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
});

const RequestsSpan2 = styled('span')({
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `400`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
});

const Requests = styled('div')({
	textAlign: `left`,
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontFamily: 'unset',
	fontWeight: `400`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
	width: `200px`,
	height: `13px`,
	position: `absolute`,
	left: `8px`,
	top: `225px`,
});

const UnbookedSpan1 = styled('span')({
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `700`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
});

const UnbookedSpan2 = styled('span')({
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontFamily: `Droid Sans`,
	fontWeight: `400`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
});

const Unbooked = styled('div')({
	textAlign: `left`,
	whiteSpace: `pre-wrap`,
	fontStyle: `normal`,
	fontWeight: `400`,
	fontSize: `10px`,
	letterSpacing: `0px`,
	textDecoration: `none`,
	lineHeight: `16px`,
	textTransform: `none`,
	width: `200px`,
	height: `13px`,
	position: `absolute`,
	left: `8px`,
	top: `238px`,
});

const Image = styled('div')({
	backgroundColor: `rgba(217, 217, 217, 1)`,
	width: `242px`,
	height: `141px`,
	position: `absolute`,
	left: `0px`,
	top: `0px`,
});

const GreenIcon = styled('div')({
	display: `flex`,
	position: `absolute`,
	isolation: `isolate`,
	flexDirection: `row`,
	justifyContent: `flex-start`,
	alignItems: `flex-start`,
	padding: `0px`,
	boxSizing: `border-box`,
	width: `18px`,
	height: `18px`,
	left: `142px`,
	top: `233px`,
});

const Ellipse15 = styled('img')({
	height: `18px`,
	width: `18px`,
	position: `absolute`,
	left: `0px`,
	top: `0px`,
});

const Vector1 = styled('img')({
	height: `13px`,
	width: `10px`,
	position: `absolute`,
	left: `4px`,
	top: `4px`,
});

const Rating = styled('div')({
	display: `flex`,
	position: `absolute`,
	isolation: `isolate`,
	flexDirection: `row`,
	justifyContent: `flex-start`,
	alignItems: `flex-start`,
	padding: `0px`,
	boxSizing: `border-box`,
	width: `59px`,
	height: `13px`,
	left: `169px`,
	top: `236px`,
});

const Star1 = styled('img')({
	height: `13px`,
	width: `13px`,
	position: `absolute`,
	left: `0px`,
	top: `0px`,
});

const Star2 = styled('img')({
	height: `13px`,
	width: `13px`,
	position: `absolute`,
	left: `15px`,
	top: `0px`,
});

const Star3 = styled('img')({
	height: `13px`,
	width: `13px`,
	position: `absolute`,
	left: `31px`,
	top: `0px`,
});

const Star4 = styled('img')({
	height: `13px`,
	width: `13px`,
	position: `absolute`,
	left: `46px`,
	top: `0px`,
});

const Edit = styled('div')({
	display: `flex`,
	position: `absolute`,
	isolation: `isolate`,
	flexDirection: `row`,
	justifyContent: `flex-start`,
	alignItems: `flex-start`,
	padding: `0px`,
	boxSizing: `border-box`,
	width: `19px`,
	height: `19px`,
	left: `212px`,
	top: `8px`,
});

const Vector2 = styled('img')({
	height: `12px`,
	width: `12px`,
	position: `absolute`,
	left: `4px`,
	top: `3px`,
});

const Ellipse16 = styled('img')({
	height: `19px`,
	width: `19px`,
	position: `absolute`,
	left: `0px`,
	top: `0px`,
});

const Frame1 = (props) => {
	const [venue, setVenue] = useState([]);
	const ownedVenues = useSelector((state) => state.counter.ownedVenues);
	const currentVenue = useSelector((state) => state.counter.currentVenue);
	const image = venue.venueImage;
	const name = venue.venue;
	const lat = venue.lat;
	const lng = venue.lng;
	const venueid = venue.venueid;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (ownedVenues !== 'none') {
			setVenue(ownedVenues.find((v) => v.venueid === props.venueid));
		}
	}, [ownedVenues]);
	// THIIS!!
	// navigate("/Owner/Venue");
	useEffect(() => {
		// reloadCalendarBookings(currentVenue);
	}, [currentVenue]);

	async function handleClick() {
		// dispatch(storeCurrentVenue(venueid))
		// dispatch(storeUIDrawerOpen(false));
		// await dispatch(storeCurrentVenue(await useChangeVenue(venueid)));

		await dispatch(storeCurrentVenue(venue));
		// reloadCalendarBookings(currentVenue);
		setTimeout(() => {}, 100);
	}

	function handleEdit() {
		navigate('/Owner/Venue');
	}

	// if (ownedVenues && image)
	return (
		<NuVenueCard
			className={(props.className, 'venueCard')}
			style={{
				width: '200px',
				background: 'none',
				float: 'left',
				marginRight: '10px',
				color: 'white',
			}}
			// onClick={() => {
			//   handleClick();
			// }}
		>
			<Title style={{ color: 'white' }}>{name}</Title>
			<UpcomingEvents>
				<UpcomingEventsSpan1>{`112 `}</UpcomingEventsSpan1>
				<UpcomingEventsSpan2>{`upcoming events`}</UpcomingEventsSpan2>
			</UpcomingEvents>
			<Requests>
				{venue.bookings ? (
					<RequestsSpan1>{venue.bookings.length}</RequestsSpan1>
				) : (
					<RequestsSpan1>?</RequestsSpan1>
				)}
				<RequestsSpan2>{` requests`}</RequestsSpan2>
			</Requests>
			<Unbooked>
				<UnbookedSpan1>{`102 `}</UnbookedSpan1>
				<UnbookedSpan2>{`unbooked`}</UnbookedSpan2>
			</Unbooked>
			<Image
				style={{
					height: '100px',
					width: '100%',
					overflow: 'hidden',
					borderRadius: '10px',
				}}
			>
				<img src={image} style={{ width: '100%', height: 'auto' }} />
			</Image>
			<GreenIcon>
				<SettingsIcon
					onClick={handleEdit}
					style={{ zIndex: '1000' }}
					className='icon'
				/>
			</GreenIcon>
		</NuVenueCard>
	);
};

export default Frame1;
