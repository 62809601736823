/** @format */

// TODO

/** @format */

import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BookingForm from "../components/Forms/BookingForm";
import { GeneralModal } from "../components/Modals/GeneralModal";
import { storeBookingModalOpen, storeCurrentBooking, storeIsNewBooking, storeSelectedDate } from "./venueFilter/venueFilterSlice";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { useState } from "react";
import DayInCalendar from "./bookings/DayInCalendar";
import DayInCalendarQue from "./bookings/DayInCalendarQue";
import WhatDayIsIt from "../components/MiscUIComponents/WhatDayIsIt";
import DayOfTheWeekMini from "../components/UI helpers/DayOfTheWeekMini";
import BookingFormGeneral from "../components/Forms/BookingFormGeneral";
import { SendRequest } from "../helpers/SendRequest";
import ProductSelect from "../components/MiscUIComponents/ProductSelect";
import ProductsGraph from "../components/Forms/ProductsGraph";
import BookingFormLite from "./BookingFormLite";
import { format } from "date-fns";

const MiniMonth = () => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const month = useSelector((state) => state.counter.currentMonth);
  const year = useSelector((state) => state.counter.currentYear);
  const bookings = useSelector((state) => state.counter.currentBookings);
  const selectedDate = useSelector((state) => state.counter.selectedDate);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstWeekday = new Date(year, month, "01").getDay();
  const numberOfPaddingDays = firstWeekday - 1; //Change this number to change startday of the week -2 gives monday, -1 gives sunday

  const handleDisplayDateOpen = () => setDisplayDateOpen(true);
  const handleDisplayDateClose = () => {
    setDisplayDateOpen(false);
    setShowBookingFormLite(false);
  };
  const [displayDateOpen, setDisplayDateOpen] = useState(false);

  const [bookingsToShow, setBookingsToShow] = useState(false);

  function formatDate(year, month, day) {
    // Pad the month and day with a leading zero if necessary
    const paddedMonth = String(month + 1).padStart(2, "0");
    const paddedDay = String(day).padStart(2, "0");
    const dateString = `${year}-${paddedMonth}-${paddedDay}`;

    // Create a Date object and convert to the desired locale string
    const date = new Date(dateString);
    return date.toLocaleDateString("sv-SE");
  }

  var statusColor = "";
  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  const paddingDays = [];
  for (let i = 0; i <= numberOfPaddingDays; i++) {
    paddingDays.push(i);
  }

  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  //Show markers in calendar
  const status = (day) => {
    statusColor = "transparent";
    // var msg = "";
    const d = new Date(year, month, day).toLocaleDateString("sv-SE");

    bookings.map((b) => {
      // console.log("Checkup", b);
      // if (b.date === d) statusColor = "#444";
      if (b.date === d && b.status === "Confirmed") statusColor = "green";
      if (b.date === d && b.status === "Awaiting Artist") statusColor = "orange";
      if (b.date === d && b.status === "Pending Request") statusColor = "red";
      if (b.date === d && b.status === "Blocked") statusColor = "pink";
    });
    return (
      <div style={{ background: statusColor, width: "8px", height: "8px", borderRadius: "10px", margin: "3px", opacity: "0.6" }}></div>
    );
  };

  const showDay = (day) => {
    // const d = new Date(year, month, day).toLocaleDateString();
    const d = new Date(year, month, day).toLocaleDateString("sv-SE");
    console.log("Checkup", d);
    bookings.map((b) => {
      if (b.date === d) {
        // setOpen(true);
        // setHoveredDayEvents()
      }
    });
    return (
      <div>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <div>date</div>
          <div>artist</div>
          <div>status</div>
        </Modal>
        ;
      </div>
    );
  };

  const [showBookingFormLite, setShowBookingFormLite] = useState(false);

  const addEvent = () => {
    setShowBookingFormLite(true);
  };

  const blockDate = () => {
    // setShowBookingFormLite(true);
    alert("blocking date");
  };

  const [artist, setArtist] = useState("");
  const [agentEmail, setAgentEmail] = useState("");

  const createBooking = (day) => {
    setShowBookingFormLite(false);
    let venue = { ...currentVenue };
    venue.currentProduct = currentProduct;
    SendRequest({ artistid: "-", artist: artist }, { agentid: "-", agent: agentEmail }, venue, selectedDate);
  };

  function handleEmail(emailAddress) {
    // checkIfUserExist(emailAddress);
    checkUserExists(emailAddress);
    setAgentEmail(emailAddress);
  }

  function checkUserExists(emailAddress) {
    const agent = agents.find((agent) => agent.agentEmail === emailAddress);
    agent && setName(agent.agent);
    return agent ? agent : null;
  }

  const [name, setName] = useState();
  function handleName(customerName) {
    setName(customerName);
  }

  const [agents, setAgents] = useState();

  //Get previous customers
  useEffect(() => {
    let temp = [];
    let agentsListed = [...new Map(bookings.map((e) => [e.agentid, e])).values()];

    agentsListed.map((b, id) => {
      let sum = 0;
      bookings.forEach((booking) => booking.agentid === b.agentid && sum++);
      console.log(sum);
      temp.push({
        id: id,
        artist: b.artist,
        agent: b.agent,
        date: b.date,
        agentEmail: b.agentEmail,
        agentid: b.agentid,
        artistImage: b.artistImage,
        price: b.price,
        numberofevents: sum,
      });
    });
    setAgents(temp);
    console.log(temp);
  }, [bookings]);

  return (
    //First print the weekdays, then the paddingDays... then the days of the month}
    <div className="miniMonth" style={{ textAlign: "center", fontSize: "12px", width: "100%" }}>
      <Modal
        open={displayDateOpen}
        onClose={handleDisplayDateClose}
        className="selectedDateModal"
        PaperProps={{
          style: { backgroundColor: "transparent", boxShadow: "none" },
        }}
      >
        <Box
          className="DayInCalendarModal"
          sx={{
            width: "80%",
            height: "80%",
            margin: "auto",
            marginTop: "10vh",
            borderRadius: "10px",
            backdropFilter: "blur(20px)",
            background: "#ffffff11",
            boxShadow: "2px 2px 10px black",
            overflowY: "scroll",
          }}
        >
          <Grid container xs={12} sm={12}>
            <Grid container p={4} xs={12} sm={10} style={{ background: "#fa5a2600" }}>
              <Grid xs={12} sm={8}>
                <h4>
                  <span>
                    {console.log("selectedDate", selectedDate)}
                    <WhatDayIsIt date={selectedDate} />
                    {selectedDate}
                    <DayOfTheWeekMini dayIndex={new Date(selectedDate).getDay()} />
                  </span>
                </h4>
              </Grid>
              <Grid item xs={10} sm={2}>
                {/* <Button fullWidth onClick={() => addEvent()}>
                  + Add Event
                </Button> */}
                <BookingFormLite currentVenue={currentVenue} selectedDate={selectedDate} />
              </Grid>
              {/* <Grid item xs={10} sm={2}>
                <Button fullWidth onClick={() => blockDate()}>
                  ⊘ Block date
                </Button>
              </Grid> */}
              {showBookingFormLite && (
                <Dialog open={true} onClose={() => setShowBookingFormLite(false)}>
                  <Grid container xs={12} sm={10} mt={4} spacing={1} flexDirection="Row">
                    <Grid item xs={12} sm={3}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Email"
                        size="small"
                        placeholder="Enter customer email"
                        onChange={(e) => handleEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Name"
                        size="small"
                        value={name}
                        placeholder="Enter customer name"
                        onChange={(e) => handleName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Artist/Event"
                        size="small"
                        placeholder="Enter artist or event name"
                        onChange={(e) => setArtist(e.target.value)}
                      />
                    </Grid>

                    <Grid item sx={12} xs={2} style={{ width: "100%" }}>
                      <ProductSelect products={currentVenue.products} date={selectedDate} />
                    </Grid>
                    <Grid item xs={12} sm={1} style={{ width: "100%" }}>
                      <Button onClick={() => createBooking()} variant="contained" size="small">
                        Go
                      </Button>
                    </Grid>
                  </Grid>
                </Dialog>
              )}
              <Grid item xs={12} sm={12}>
                {selectedDate && <DayInCalendarQue />}
              </Grid>
            </Grid>
            <Grid container sm={2} style={{ background: "#fa5a2600" }} p={4}>
              <Grid item>
                <ProductsGraph products={currentVenue.products} selectedDate={selectedDate} venue={currentVenue} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* <Modal
        className="bookingFormModal"
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="bookingFormModal"
        sx={{ borderRadius: "20px" }}
      >
        {booking && <BookingFormGeneral currentBooking={} handleClose={handleClose} />}
      </Modal> */}
      {weekdays.map((day) => (
        <div
          className="day"
          style={{
            float: "left",
            background: "transparent",
            width: "12%",
            height: "20px",
            borderRadius: "10px",
            marginBottom: "10px",
            margin: "2px",
          }}
        >
          <span className="weekday" style={{ opacity: "0.3" }}>
            {day}
          </span>
        </div>
      ))}
      {paddingDays.map((day) => (
        <div
          className="day"
          style={{ float: "left", background: "transparent", width: "12%", height: "40px", borderRadius: "10px", margin: "2px" }}
        >
          <span className="day-number" style={{ opacity: "0.3" }}>
            {}
          </span>
        </div>
      ))}
      {days.map((day) => (
        <div
          className="day"
          style={{ float: "left", width: "12%", height: "40px", borderRadius: "5px", margin: "2px" }}
          onMouseOver={() => showDay(day)}
          onClick={() => {
            dispatch(storeBookingModalOpen(true));
            dispatch(storeIsNewBooking(true));
            // dispatch(storeSelectedDate(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE")));
            dispatch(storeSelectedDate(formatDate(year, month, day)));
            // dispatch(storeSelectedDate(new Date(year + "-" + (month + 1) + "-" + day)));
            handleDisplayDateOpen(true);
          }}
        >
          <span className="day-number" style={{}}>
            {day}
            {status(day)}
          </span>
        </div>
      ))}
      {/* <BookingForm /> */}
    </div>
  );
};

export default MiniMonth;
