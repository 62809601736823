/** @format */

import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AgentForm from "../components/Forms/AgentForm";
import { GeneralModal } from "../components/Modals/GeneralModal";
import CustomersListed from "../features/owner/CustomersListed";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonGroup } from "react-bootstrap";

const Customers = () => {
  const [showAgentForm, setShowAgentForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const currentAgent = useSelector((state) => state.counter.currentAgent);

  const handleClose = () => {
    setShowAgentForm(false);
  };
  return (
    <Grid container p={4}>
      <h1>Customers</h1>
      <CustomersListed />
      <Grid item>
        {/* <AgentForm /> */}
        Edit/Add AGENT
        <GeneralModal
          setOpen={() => {
            setShowAgentForm(true);
          }}
          open={showAgentForm}
          onClose={handleClose}
        >
          <AgentForm editMode={editMode} currentAgent={editMode ? currentAgent : {}} setShowAgentForm={() => setShowAgentForm(false)} />
        </GeneralModal>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <IconButton
            onClick={() => {
              setShowAgentForm(true);
              setEditMode(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowAgentForm(true);
              setEditMode(false);
            }}
          >
            <AddCircleIcon />
          </IconButton>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default Customers;

/*TODO 
Fixa så att ny användare som skapas också hamnar i Users
Lägg till visning av artister
Lägg till visning av Special Access


*/
