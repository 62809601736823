/** @format */

import { Send } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { storeCurrentVenue } from "../venueFilter/venueFilterSlice";

const CreateSubOrganizerNortic = ({ handleChange }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [name, setName] = useState(currentVenue.venue);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(currentVenue.venue);
  }, [currentVenue]);

  async function handleSubmit() {
    try {
      setLoading(true);
      const result = await axios.post(
        "http://127.0.0.1:5001/venuehub-64e72/europe-west3/createSubOrganizerNortic",
        { name },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setResponse(result.data.values[0]);
      console.log("Response from Nortic:", result.status, result.data.values[0]);
      handleChange(result.data.values[0].id, "ticketVenueId", "value");
      setLoading(false);
    } catch (error) {
      setResponse(error.message);
    }
  }

  return (
    <div>
      {currentVenue.venue}
      {currentVenue.ticketVenueId}
      {/* <input type="text" disabled value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter name" /> */}
      <Button variant="outlined" onClick={() => handleSubmit()} startIcon={loading ? <CircularProgress style={{ zoom: "0.4" }} /> : null}>
        Create Sub Organizer: {name}
      </Button>
      <div>
        {/* <h7>Response:</h7>
        <p>{response}</p> */}
      </div>
    </div>
  );
};

export default CreateSubOrganizerNortic;
