/** @format */

import React from "react";

function GridNet({ rows, columns }) {
  const gridStyle = {
    gridTemplateColumns: `repeat(${columns}, 3vh)`,
    gridTemplateRows: `repeat(${rows}, 3vh)`,
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="grid-net" style={gridStyle}>
        {Array.from({ length: rows * columns }).map((_, index) => (
          <div key={index} className="grid-cell"></div>
        ))}
      </div>
    </div>
  );
}

export default GridNet;
