/** @format */

import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { storeCapMin, storeCapMax } from "../../features/venueFilter/venueFilterSlice";

const range_steps = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000, 5000, 7500, 10000, 15000, 20000, 30000, 40000, 100000, 500000,
];

const marks = [
  { value: 0, label: "100" },
  { value: 5, label: "500" },
  { value: 12, label: "3K" },
  { value: 17, label: "20K" },
  { value: 21, label: "500K" },
];

export default function NonLinearSlider() {
  const [value, setValue] = React.useState([3, 17]);

  const count = useSelector((state) => state.counter.count);
  const dispatch = useDispatch();

  const handleChange = (event, newValue, capMax, capMin) => {
    setValue(newValue);
    capMin = range_steps[newValue[0]];
    capMax = range_steps[newValue[1]];
    // dispatch(storeCapMax(capMax));
    // dispatch(storeCapMin(capMin));
  };

  //INTIALIZE A VALUE TO GET VENUES LOADED
  // useEffect(() => {
  //   setTimeout(() => {
  //     setValue([4, 18]);
  //     handleChangeCommitted(null, value, 4, 18);
  //   }, 1000);
  // }, []);

  const handleChangeCommitted = (event, newValue, capMax, capMin) => {
    // setValue(newValue);
    capMin = range_steps[newValue[0]];
    capMax = range_steps[newValue[1]];
    dispatch(storeCapMax(capMax));
    dispatch(storeCapMin(capMin));
  };

  return (
    <div className="rangeSlider">
      <Slider
        // style={{ maxWidth: 200 }}
        defaultValue={[5, 8]}
        value={value}
        min={0}
        step={1}
        max={21}
        marks={marks}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="off"
        size="small"
      />
      <p className="text-center" className="RangeSliderValues">
        {range_steps[value[0]]} - {range_steps[value[1]]}
      </p>
    </div>
  );
}
