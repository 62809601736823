/** @format */

import React, { useState, useEffect } from "react";

function LoadingDots({ speed }) {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length < 3) {
          return prevDots + ".";
        }
        return "";
      });
    }, speed);

    return () => clearInterval(interval);
  }, []);

  return <span>{dots}</span>;
}

export default LoadingDots;
