/** @format */

export default function shortText(text, maxLength) {
  if (text && text.length <= maxLength) {
    return text;
  } else {
    return text && text.substring(0, maxLength - 3) + "...";
  }
}

const originalText = "This is a long text that needs to be shortened.";
const shortenedText = shortText(originalText, 20);

// console.log(shortenedText); // Output: "This is a long text..."
