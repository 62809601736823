/** @format */

import { CircularProgress, Grid } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
// import { set } from "immer/dist/internal";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoginMUI from "../components/Authentication/LoginMUI";
import AgreementModal from "../components/Documents/AgreementModal";
import AgreementSign from "../components/Documents/AgreementSign";
import { storeCurrentBookings } from "../features/venueFilter/venueFilterSlice";
import { db } from "../firebase";
import BatchAgreementModal from "../components/Documents/BatchAgreementModal";
import { checkIfUserExist } from "../helpers/CheckIfUserExist";
import CheckIfEmailExists from "../utils/CheckIfEmailExists";
import SimpleSignup from "../helpers/simpleSignup";

const SignAgreement = () => {
  const currentUser = useSelector((state) => state.counter.currentUser);
  const userRole = useSelector((state) => state.counter.userRole);
  const bookings = useSelector((state) => state.counter.bookings);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingids = queryParams.get("bookingids").split(",");
  const sign = queryParams.get("sign");
  const agreementId = queryParams.get("agreementId");
  const userid = queryParams.get("userid");
  const agreementPDF = queryParams.get("agreement");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [myBookings, setMyBookings] = useState();
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [docPDF, setDocPDF] = useState("");
  const [bookingData, setBookingData] = useState({});
  const [newUser, setNewUser] = useState(false);

  // useEffect(() => {
  //   // currentUser.email === userid ? setOpenLogin(false) : handleOpenLogin();
  //   !currentUser.userid ? setOpenLogin(true) : setOpenLogin(false);
  //   !currentUser.userid ? console.log("CHECK", "no user") : setOpenLogin(false);
  //   console.log("THIS", currentUser);
  // }, [currentUser.email]);

  // useEffect(() => {
  //   async function checkForRegisteredUser() {
  //     console.log("Email?", userid);
  //     const result = await CheckIfEmailExists(userid);
  //     result === "OK" ? setOpenSignup(true) : setOpenSignup(false);
  //     console.log(result);
  //   }
  //   checkForRegisteredUser();
  // }, []);

  // useEffect(() => {
  //   const storage = getStorage();
  //   const storageRef = ref(storage, `agreements/${bookingid}.pdf`);
  //   console.log(storageRef);
  //   getDownloadURL(storageRef).then((url) => {
  //     setDocPDF(url);
  //   });
  // }, [bookingid]);

  // useEffect(() => {
  //   console.log("docPDF", docPDF);
  // }, [docPDF]);

  // Get the booking to sign. (AgreementModal picks it up from currentBookings, so we fetch it and put it there)
  useEffect(() => {
    setLoading(true);
    const tmp = bookings
      .filter(function (booking) {
        return booking.agentid === currentUser.userid;
      })
      .map(function (booking) {
        return booking;
      });
    tmp.sort((a, b) => new Date(a.date) - new Date(b.date));
    setMyBookings(tmp);
    dispatch(storeCurrentBookings(tmp));
    setLoading(false);
  }, [bookings]);

  // useEffect(() => {
  //   const fetchBookingData = async () => {
  //     const docRef = doc(db, "Bookings", bookingid);
  //     const docSnap = await getDoc(docRef);
  //     setBookingData(docSnap.data());
  //     console.log("docsnap", docSnap.data());
  //     dispatch(storeCurrentBookings([docSnap.data()]));
  //   };
  //   //when logged in get bookingData to show agreement
  //   openLogin === false && fetchBookingData();
  // }, [bookingid, openLogin]);

  // useEffect(() => {
  //   userid === currentUser.email && setOpenLogin(false);
  // }, [openLogin]);

  return (
    <div>
      {/* {newUser ? `${userid} is a new user` : `${userid} is a recurring user`} */}
      {/* {currentUser && userid && !openSignup && <LoginMUI openLogin={openLogin} setOpenLogin={setOpenLogin} userName={userid} />} */}
      <SimpleSignup openSignup={true} setOpenSignup={setOpenSignup} mailAddress={userid} role={"agent"} />
      <div>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid mt={12} xs={12} p={8}>
            {!bookingData.agentSignature && <h2>Sign Document...</h2>}
            {bookingData.agentSignature && bookingData.venueSignature && <h2>Document signed...</h2>}
            {!openLogin && bookingData && <span>Click to open</span>}
            {/* {bookingids} */}
            {/* <AgreementModal bookingid={bookingid} sign={"agentSignature"} open={!openLogin} /> */}
            {sign === "venueSignature" && <BatchAgreementModal sign={sign} agreementId={agreementId} open={!openLogin} />}
            {/* {sign === "agentSignature" && <BatchAgreementModal sign={sign} bookingids={bookingids} open={!openLogin} />} */}
            {/* {sign === "agentSignature" && console.log("CHECKUP", sign, agreementId, bookingids)} */}
            {sign === "agentSignature" && userRole !== "none" && (
              // <BatchAgreementModal sign={sign} agreementId={agreementId} bookingids={bookingids} open={!openLogin} />
              <BatchAgreementModal
                sign={sign}
                agreementId={agreementId}
                // bookingids={bookingids}
                open="true"
              />

              // <BatchAgreementModal
              //   agreementId={agreementId}
              //   bookingids={bookingids}
              //   sign= "agentSignature"
              //   // sign="venueSignature"
              //   open={true}
              //   setShowAgreement={true}
              //   // climateCompensation={climateCompensation}
              //   // bookingFeatures={bookingFeatures}
              //   // relevantBookings={relevantBookings}
              // />
            )}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default SignAgreement;
