/** @format */

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Grid } from "@mui/material";
import { SendRequest } from "../helpers/SendRequest";
import ProductSelect from "../components/MiscUIComponents/ProductSelect";
import { createId, db } from "../firebase";
import { useSelector } from "react-redux";
import { doc, setDoc } from "firebase/firestore";
import { generateOCR } from "../helpers/generateOCR";

const BookingFormLite = ({ selectedDate }) => {
  //   const selectedDate = useSelector((state) => state.counter.selectedDate);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [artist, setArtist] = useState();
  const [open, setOpen] = useState();
  const agreementId = createId();
  const bookingid = createId();
  const OCR = generateOCR();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log("currentProduct", currentProduct);
  console.log(currentVenue);

  const agreementPackage = {
    OCR: OCR,
    bookingids: [bookingid],
    status: "Pending Request",
    email: email,
    agent: { email: "", phone: "" },
    venue: currentVenue.venue,
    venueid: currentVenue.venueid,
    documentid: agreementId,
    agreementId: agreementId,
    venueLogo: currentVenue.venueLogo || "",
    upfront: currentVenue.upfront,
    upfrontAt: currentVenue.upfrontAt,
    preCharge: currentVenue.preCharge,
    preChargeAt: currentVenue.preChargeAt,
    balance: currentVenue.balance,
    balanceAt: currentVenue.balanceAt,
    earlyCancelation: currentVenue.earlyCancelation,
    earlyCancelationAt: currentVenue.earlyCancelationAt,
    lateCancelation: currentVenue.lateCancelation,
    lateCancelationAt: currentVenue.lateCancelationAt,
    addendum: { text: currentVenue.addendum || "", useAddendum: currentVenue.useAddendum || false },
  };

  const createBooking = async (day) => {
    // Create Agreement
    await setDoc(doc(db, "Agreements", agreementId), {
      ...agreementPackage,
    });

    console.log("agreementPackage", agreementPackage);
    // setShowBookingFormLite(false);
    let venue = { ...currentVenue };
    // venue.currentProduct = currentProduct;
    await SendRequest(
      { artistid: "-", artist: artist }, //currentArtist
      { agentid: "-", email: email, firstName: firstName || "", lastName: lastName || "" }, //currentAgent
      {
        venue: currentVenue && { ...currentVenue },
        venueid: currentVenue.venueid,
        product: currentProduct.product,
        productid: currentProduct.productid,
        getIn: currentProduct.getIn,
        loadIn: currentProduct.loadIn,
        soundcheck: currentProduct.soundcheck,
        doors: currentProduct.doors,
        show: currentProduct.show,
        loadOut: currentProduct.loadOut,
        curfew: currentProduct.curfew,
        price: currentProduct.price,
        priceIncVat: currentProduct.priceIncVat,
        upfront: currentVenue.upfront,
        upfrontAt: currentVenue.upfrontAt,
        preCharge: currentVenue.preCharge,
        preChargeAt: currentVenue.preChargeAt,
        balance: currentVenue.balance,
        balanceAt: currentVenue.balanceAt,
        earlyCancelation: currentVenue.earlyCancelation,
        earlyCancelationAt: currentVenue.earlyCancelationAt,
        lateCancelation: currentVenue.lateCancelation,
        lateCancelationAt: currentVenue.lateCancelationAt,
      }, //availData
      selectedDate, // proposedDate
      agreementId, // agreementId
      bookingid // bookingid
    );
    // SendRequest = (currentArtist, currentAgent, availData, proposedDate, agreementId, bookingid)
    setOpen(false);
  };

  const gradientStyle = {
    background: "linear-gradient(to right, #ff7e5f, #feb47b)", // Define your gradient here
    color: "white",
    padding: "20px",
    borderRadius: "8px",
  };

  return (
    <div>
      <Button onClick={() => handleClickOpen()}>+ Add New Event</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { zIndex: "2", background: "linear-gradient(to top, #111111, #555555)" } }}
      >
        <DialogTitle>
          <h4>Add Event ({selectedDate})</h4>
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12} mt={4} flexDirection="Row" spacing={4}>
            <Grid item sx={12} xs={12} style={{ width: "100%" }}>
              <ProductSelect products={currentVenue.products} date={selectedDate} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Artist/Event"
                size="small"
                placeholder="Enter artist or event name"
                onChange={(e) => setArtist(e.target.value)}
                // onChange={(e) => setArtist(e.target.value)}
              />
            </Grid>
            {console.log("agreementPackage", agreementPackage)}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Email"
                size="small"
                placeholder="Enter customer email"
                onChange={(e) => setEmail(e.target.value)}
                // onChange={(e) => handleEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="First Name"
                size="small"
                value={name}
                placeholder="Enter first name"
                onChange={(e) => setFirstName(e.target.value)}
                // onChange={(e) => handleName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Last Name"
                size="small"
                value={name}
                placeholder="Enter last name"
                onChange={(e) => setLastName(e.target.value)}
                // onChange={(e) => handleName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={1} style={{ width: "100%" }}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => createBooking()}
            variant="outlined"
            disabled={!currentProduct.product || !currentVenue.venue || !selectedDate || !email || !artist}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BookingFormLite;
