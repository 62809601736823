/** @format */

import { Badge, Button, Divider, Grid } from "@mui/material";
import { BarPlot, ChartsXAxis, LinePlot, ResponsiveChartContainer, SparkLineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";

import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import PieChartCenteredLabel from "../components/UI helpers/PieChartCenteredLabel";
import AreaSparkLine from "../components/UI helpers/AreaSparkLine";
import ElectricityUse from "../components/VisualData/ElectricityUse";
import ElectricitySource from "../components/VisualData/ElectrictySource";
import OnSiteProducedEnergy from "../components/VisualData/OnSiteProducedEnergy";
import Refrigerants from "../components/VisualData/Refrigerants";
import Water from "../components/VisualData/Water";
import HeatingAndFuel from "../components/VisualData/HeatingAndFuel";
import Waste from "../components/VisualData/Waste";
import BasicInfo from "../components/VisualData/BasicInfo";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SelectVenue from "../helpers/SelectVenue";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useSelector } from "react-redux";
import DisplayObject from "../helpers/displayObject";
import LoadingDots from "../components/UI helpers/LoadingDots";
import PowerIcon from "@mui/icons-material/Power";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { MdRecycling } from "react-icons/md";
import { WaterRounded } from "@mui/icons-material";
import TicketsMap from "../helpers/TicketsMap";
import GridNet from "../components/UI helpers/GridNet";
import VisualDataSummary from "../components/VisualData/VisualDataSummary";
import CheckMissing from "../components/VisualData/CheckMissing";
import CO2eConversion from "../components/VisualData/CO2eConversion";
import { numberFormatted } from "../helpers/Numbers";
import TravelTmp from "../components/VisualData/TravelTmp";

// import { CO2eConversion } from "../components/VisualData/CO2eConversion";

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = ["A", "B", "C", "D", "E", "F", "G"];

const Sustainability = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [formData, setFormData] = useState({});
  const [CO2eValue, setCO2eValue] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [premisesTabValue, setPremisesTabValue] = React.useState(formData.length > 2 ? 5 : 5);
  const [emissionFactors, setEmissionFactors] = useState();

  const handleChange = (key, value) => {
    let tmpKey = key;
    if (key === "year") {
      // If key === "year"
      setFormData((prev) => ({ ...prev, [key]: value }));
      getData(value);
    } else {
      const year = formData.year;
      const CO2e = CO2eConversion(key, value, year, emissionFactors, formData);
      if (key === "electricitySource") tmpKey = "electricityUse";
      setFormData((prev) => ({ ...prev, [key]: value, [`${tmpKey}CO2e`]: CO2e ? CO2e : "-" }));
      setCO2eValue((prev) => ({ ...prev, [key]: CO2e }));
    }
  };

  useEffect(() => {
    console.log(formData.electricityUseCO2e);
  }, [formData]);

  const switchTabPremises = (event, newValue) => {
    setPremisesTabValue(newValue);
  };

  async function getData(year) {
    // Check if venueid and year are valid before proceeding
    if (currentVenue.venueid && formData.year) {
      // Create a valid Firestore document path by concatenating venueid and year

      const documentPath = `venues/${currentVenue.venueid}/${year ? year : formData.year}`;
      const docSnap = await getDoc(doc(db, "ClimateData", documentPath));
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        setFormData({ ...docSnap.data(), venueid: currentVenue.venueid });
        setLoaded(true);
      } else {
        setFormData((prev) => ({ venueid: currentVenue.venueid, year: year }));
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  }

  useEffect(() => {
    console.log("FormData changed");
  }, [formData]);

  // Get venue climate data
  useEffect(() => {
    setFormData((prevFormData) => ({
      year: prevFormData.year,
    }));
    getData(); // Call the function immediately
  }, [currentVenue]); // Add dependencies to the useEffect hook

  // Get PREMISES emission factor data for CO2e conversion
  useEffect(() => {
    async function getData() {
      const year = formData.year;
      const path = `datasheet/premises/${year}`;
      const docSnap = await getDoc(doc(db, "ClimateData", path));
      if (docSnap.exists()) {
        setEmissionFactors(docSnap.data());
      }
    }
    getData();
  }, [formData.year]);

  // useEffect(() => {
  //   console.log("EMISSIONFACTORS:", emissionFactors);
  // }, [emissionFactors]);

  async function handleStore() {
    console.log("storing", currentVenue.venueid, formData.year);
    // Check if venueid and year are valid before proceeding
    if (currentVenue.venueid && formData.year) {
      console.log("storing");
      // Create a valid Firestore document path by concatenating venueid and year
      const documentPath = `venues/${currentVenue.venueid}/${formData.year}`;
      // const documentPath = `${currentVenue.venueid}`;

      try {
        // Use setDoc to store the data in the Firestore document
        await setDoc(doc(db, "ClimateData", documentPath), { ...formData }, { merge: true });
        console.log("Document data stored successfully!", documentPath);
      } catch (error) {
        console.error("Error storing document data:", error);
      }
    }
  }

  const checkMissing = (params) => {
    const [numberMissing, setNumberMissing] = useState(0);
    // Filter out parameters with undefined values
    let missingParams = params.filter((param) => formData[param] === undefined);
    useEffect(() => {
      setNumberMissing(missingParams.length);
    }, [formData]);
    return numberMissing;
  };

  useEffect(() => {
    // This code runs after `yourState` has been updated
    console.log("State updated:", formData);
    CO2eConversion();
  }, [formData]); // Dependency array

  return (
    <Grid container spacing={3} p={4} className="sustainability">
      {/* <DisplayObject data={formData} /> */}
      <Grid container xs={12} md={12} mt={1}>
        <Grid item xs={2}>
          <SelectVenue />
        </Grid>
        <Grid item xs={8} mt={8}>
          <BasicInfo formData={formData} handleChange={handleChange} />
        </Grid>
      </Grid>
      <Grid item mt={1} xs={12} md={6}>
        <Grid item className="blockContainer" style={{ padding: "20px", height: "60vh" }}>
          <h4>
            Premises <span style={{ float: "right", opacity: "0.4" }}>{formData.year}</span>
          </h4>

          <Tabs
            className="tabstyle"
            value={premisesTabValue}
            onChange={switchTabPremises}
            aria-label="basic tabs example"
            style={{ marginBottom: "20px" }}
            size="small"
          >
            <Tab
              label={
                <Badge
                  badgeContent={checkMissing([
                    "electricityUse",
                    "electricitySource",
                    // , "onSiteProduced"
                  ])}
                  color="primary"
                >
                  Electricity
                </Badge>
              }
            />
            <Tab
              label={
                <Badge badgeContent={checkMissing(["heatingUsed", "heatingQuantity", "heatingUnit"])} color="primary">
                  Heating
                </Badge>
              }
            />
            <Tab
              label={
                <Badge badgeContent={checkMissing(["refrigerantType", "refrigerantQuantity"])} color="primary">
                  Refrigerants
                </Badge>
              }
            />
            <Tab
              label={
                <Badge badgeContent={checkMissing(["waterUse"])} color="primary">
                  Water
                </Badge>
              }
            />
            <Tab
              label={
                <Badge
                  badgeContent={checkMissing([
                    "recycledMetal",
                    "recycledElectric",
                    "landfilledWaste",
                    "mixedHousehold",
                    "recycledPaper",
                    "recycledPlastic",
                  ])}
                  color="primary"
                >
                  Waste
                </Badge>
              }
            />
            <Tab label={<span style={{ color: "orange" }}>Summary</span>} />
          </Tabs>

          {premisesTabValue === 0 && (
            <>
              <Grid item>
                <PowerIcon style={{ fontSize: "4rem", opacity: "0.2" }} />
                <div className="vd-info">
                  Purchased electrcitiy during the reporting year. If you purchase electricity from renewable sources such as wind, hydro or
                  solar, provide the answer in the field below. Statements should be backed up by evidence from the energy supplier, with a
                  so called GO (garantuee of origin) or REC (Renewable energy certificate)
                </div>
              </Grid>
              <Grid item xs={12} md={12} overflow="hidden">
                <ElectricityUse formData={formData} handleChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={21} overflow="hidden">
                <ElectricitySource formData={formData} handleChange={handleChange} />
              </Grid>
              {/* <Grid item xs={12} md={12} overflow="hidden">
                <OnSiteProducedEnergy formData={formData} handleChange={handleChange} />
              </Grid> */}
            </>
          )}
          {premisesTabValue === 1 && (
            <>
              <Grid item>
                <LocalFireDepartmentIcon style={{ fontSize: "4rem", opacity: "0.2" }} />
                <div className="vd-info">
                  Heating and on-site fuel use from the facility during the reporting year. Specifify the type of heating used for the
                  facility and the quantity used for the reporting year
                </div>
              </Grid>
              <Grid item xs={12} md={12} overflow="hidden">
                <HeatingAndFuel formData={formData} handleChange={handleChange} />
              </Grid>
            </>
          )}
          {premisesTabValue === 2 && (
            <>
              <Grid item>
                <AcUnitIcon style={{ fontSize: "4rem", opacity: "0.2" }} />
                <div className="vd-info">
                  Refrigerants are used in air conditioning systems and heat pumps. If known, specifiy the type if refrigerants used and the
                  refilled amount during the reporting year.
                </div>
              </Grid>
              <Grid item xs={12} md={12} overflow="hidden">
                <Refrigerants formData={formData} handleChange={handleChange} />
              </Grid>
            </>
          )}
          {premisesTabValue === 3 && (
            <>
              <Grid item>
                <WaterRounded style={{ fontSize: "4rem", opacity: "0.2" }} />
                <div className="vd-info">Water used on the premises during the reporting year.</div>
              </Grid>
              <Grid item xs={12} md={12} overflow="hidden">
                <Water formData={formData} handleChange={handleChange} />
              </Grid>
            </>
          )}
          {premisesTabValue === 4 && (
            <>
              <Grid item>
                <MdRecycling style={{ fontSize: "4rem", opacity: "0.2" }} />
                <div className="vd-info">Generated waste during the reporting year from the premises.</div>
              </Grid>
              <Grid item xs={12} md={12} overflow="hidden">
                <Waste formData={formData} handleChange={handleChange} />
              </Grid>
            </>
          )}
          {premisesTabValue === 5 && (
            <>
              <Grid item>
                {/* <MdRecycling style={{ fontSize: "4rem", opacity: "0.2" }} /> */}
                {/* <div className="vd-info">Listed summary of premises sutainability data.</div> */}
              </Grid>
              <Grid item xs={12} md={12} overflow="hidden">
                <VisualDataSummary formData={formData} />
              </Grid>
            </>
          )}
          {/* <Grid item xs={12} md={6} overflow="hidden">
            <h7>Electricity</h7>
            <PieChartCenteredLabel
              width="200px"
              height="100px"
              data={[
                { value: 5, label: "Plane", color: "#00ffd499" },
                { value: 10, label: "Car", color: "#00ffd433" },
                { value: 15, label: "Bus", color: "#ffa50044" },
                { value: 20, label: "Train", color: "#ffa50099" },
              ]}
              label="Travel"
            />
          </Grid>
          <Grid item xs={12} md={6} overflow="hidden">
            <h7>Electricity</h7>
            <PieChartCenteredLabel
              width="200px"
              height="100px"
              data={[
                { value: 5, label: "Plane", color: "#00ffd499" },
                { value: 10, label: "Car", color: "#00ffd433" },
                { value: 15, label: "Bus", color: "#ffa50044" },
                { value: 20, label: "Train", color: "#ffa50099" },
              ]}
              label="Travel"
            />
          </Grid>
          <Grid item xs={12} md={6} overflow="hidden">
            <BarChart
              width={500}
              height={300}
              series={[
                {
                  data: pData,
                  label: "pv",
                  id: "pvId",
                  yAxisKey: "leftAxisId",
                },
                {
                  data: uData,
                  label: "uv",
                  id: "uvId",
                  yAxisKey: "rightAxisId",
                },
              ]}
              xAxis={[{ data: xLabels, scaleType: "band" }]}
              yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
              rightAxis="rightAxisId"
            />
          </Grid> */}
          <Divider />
          <Grid item sx={{ display: "flex", justifyContent: "flex-end" }} mt={4}>
            <Button onClick={() => handleStore()} variant="outlined" size="large">
              Store
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={1} xs={12} md={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item className="blockContainer" p={2} style={{ height: "40vh" }}>
              <Grid container>
                {/* <Grid item xs={6}>
                  
                </Grid> */}
                {/* First Column (Left on Desktop, Bottom on Mobile) */}
                <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }} style={{ height: "32vh" }}>
                  <h4>Transportation</h4>
                  <PieChartCenteredLabel
                    width="200px"
                    height="100px"
                    data={[
                      { value: 5, label: "✈️ Plane", color: "#00ffd422" },
                      { value: 10, label: "🚗 Car", color: "#00ffd433" },
                      { value: 15, label: "🚌 Bus", color: "#00ffd455" },
                      { value: 20, label: "🚆 Train", color: "#00ffd411" },
                    ]}
                    label="Travel"
                  />
                  {/* <Typography variant="h4">First Column</Typography> */}
                  {/* Content for the first column */}
                </Grid>
                {/* Second Column (Right on Desktop, Top on Mobile) */}
                <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }} style={{ height: "30vh" }}>
                  <div className="vd-map">
                    {/* <h7 style={{ padding: "20px" }}>Map</h7> */}
                    <div>
                      <GridNet rows="20" columns="20" />

                      {/* <TicketsMap /> */}
                      <TravelTmp />
                    </div>
                  </div>
                  {/* <Typography variant="h4">Second Column</Typography> */}
                  {/* Content for the second column */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} flexDirection="row">
            <Grid container className="blockContainer" p={2} style={{ height: "35vh" }} flexDirection="row">
              <h4 style={{ width: "100%" }}>Foods & Goods</h4>
              <Grid item xs={4} overflow="hidden">
                <h7>Foods</h7>
                <PieChartCenteredLabel
                  width="200px"
                  height="200px"
                  data={[
                    { value: 5, label: "Beef", color: "#00ffd455" },
                    { value: 10, label: "Seafood", color: "#00ffd433" },
                    { value: 9, label: "Vegetables", color: "#00ffd422" },
                  ]}
                  label="Foods"
                />
              </Grid>
              <Grid item xs={4} overflow="hidden">
                <h7>Drinks</h7>
                <PieChartCenteredLabel
                  width="200px"
                  height="200px"
                  data={[
                    { value: 5, label: "Liquors", color: "#00ffd499" },
                    { value: 10, label: "Beer", color: "#00ffd433" },
                    { value: 7, label: "Locally Produced", color: "#ffa50044" },
                    { value: 20, label: "Coffee", color: "#ffa50099" },
                  ]}
                  label="Drinks"
                />
              </Grid>
              <Grid item xs={4} overflow="hidden">
                <h7>Other</h7>
                <PieChartCenteredLabel
                  width="200px"
                  height="200px"
                  data={[
                    { value: 5, label: "Merchandise", color: "#00ffd499" },
                    { value: 10, label: "Tobacco", color: "#00ffd433" },
                    { value: 15, label: "Snacks", color: "#ffa50044" },
                    { value: 20, label: "Wardrobe", color: "#ffa50099" },
                  ]}
                  label="Other"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Sustainability;

// <Grid item xs={12} sm={6} className="blockContainer premises">
// <h4>Premises</h4>
// <AreaSparkLine />
// </Grid>
// <Grid item xs={12} sm={6} className="blockContainer goods">
// <h4>Goods</h4>
// <Grid item>
//   <BarChart
//     width={500}
//     height={300}
//     series={[
//       {
//         data: pData,
//         label: "Food & Catering",
//         id: "pvId",
//         yAxisKey: "leftAxisId",
//       },
//     ]}
//     xAxis={[{ data: xLabels, scaleType: "band" }]}
//     yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
//     rightAxis="rightAxisId"
//   />
// </Grid>
// </Grid>
// <Grid item xs={12} sm={6} className="blockContainer transportation">
// <h4>Transportation</h4>
// <Grid item>
//   <BarChart
//     width={500}
//     height={300}
//     series={[
//       {
//         data: pData,
//         label: "pv",
//         id: "pvId",
//         yAxisKey: "leftAxisId",
//       },
//       {
//         data: uData,
//         label: "uv",
//         id: "uvId",
//         yAxisKey: "rightAxisId",
//       },
//     ]}
//     xAxis={[{ data: xLabels, scaleType: "band" }]}
//     yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
//     rightAxis="rightAxisId"
//   />
// </Grid>
// </Grid>
// <Grid item xs={12} sm={6} className="blockContainer">
// <Grid item>
//   <PieChartCenteredLabel
//     width="200px"
//     height="100px"
//     data={[
//       { value: 5, label: "Plane", color: "#00ffd499" },
//       { value: 10, label: "Car", color: "#00ffd433" },
//       { value: 15, label: "Bus", color: "#ffa50044" },
//       { value: 20, label: "Train", color: "#ffa50099" },
//     ]}
//     label="Travel"
//   />
// </Grid>
// <Grid item>
//   <PieChartCenteredLabel
//     width="250px"
//     height="150px"
//     data={[
//       { value: 25, label: "Dairy", color: "#00ffd499" },
//       { value: 15, label: "Eggs and mayonnaise", color: "#00ffd433" },
//       { value: 30, label: "Fruits", color: "#ffa50044" },
//       { value: 40, label: "Grains, bread and pasta", color: "#ffa50099" },
//       { value: 50, label: "Meat", color: "#00ffd499" },
//       { value: 20, label: "Plant-based meat alternatives", color: "#00ffd433" },
//       { value: 10, label: "Cooking oils", color: "#ffa50044" },
//       { value: 15, label: "Sauces, vinegars and condiments", color: "#ffa50099" },
//       { value: 35, label: "Seafood", color: "#00ffd499" },
//       { value: 25, label: "Snacks", color: "#00ffd433" },
//       { value: 30, label: "Vegetables", color: "#ffa50044" },
//       { value: 45, label: "Meat dish", color: "#ffa50099" },
//       { value: 20, label: "Fish dish", color: "#00ffd499" },
//       { value: 15, label: "Vegetarian dish", color: "#00ffd433" },
//       { value: 10, label: "Nuts", color: "#ffa50044" },
//       { value: 35, label: "Potato chips and other snacks", color: "#ffa50099" },
//     ]}
//     label="Foods"
//   />
// </Grid>
// <Grid item mt={10}>
//   <PieChartCenteredLabel
//     width="250px"
//     height="150px"
//     data={[
//       { value: 60, label: "Beer", color: "#00ffd499" },
//       { value: 25, label: "Ale and others", color: "#00ffd433" },
//       { value: 40, label: "Coffee", color: "#ffa50044" },
//       { value: 50, label: "Bottled water", color: "#ffa50099" },
//       { value: 30, label: "Cider, ginger ale and other non-mixed drinks", color: "#00ffd499" },
//       { value: 35, label: "Fruit juice", color: "#00ffd433" },
//       { value: 20, label: "Pre-mixed alcoholic drinks", color: "#ffa50044" },
//       { value: 45, label: "Soft drinks and energy drinks", color: "#ffa50099" },
//       { value: 55, label: "Spirits", color: "#00ffd499" },
//       { value: 30, label: "Tea, leaves", color: "#00ffd433" },
//       { value: 65, label: "Wine", color: "#ffa50044" },
//     ]}
//     label="Drinks"
//   />
// </Grid>
// <Grid item mt={10}>
//   <PieChartCenteredLabel
//     width="250px"
//     height="150px"
//     data={[
//       { value: 10, label: "Plastic and rubber products", color: "#00ffd499" },
//       { value: 15, label: "Paper products", color: "#00ffd433" },
//       { value: 20, label: "Textiles", color: "#ffa50044" },
//       { value: 30, label: "Fridges and freezers", color: "#ffa50099" },
//       { value: 25, label: "Electrical items", color: "#00ffd499" },
//       { value: 12, label: "IT", color: "#00ffd433" },
//       { value: 22, label: "Cleaning products and soap", color: "#ffa50099" },
//       { value: 8, label: "Chemicals for smoke machines and pyrotechnics", color: "#00ffd499" },
//       { value: 40, label: "Construction materials", color: "#00ffd433" },
//     ]}
//     label="Other"
//   />
// </Grid>
