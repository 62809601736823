/** @format */

import React from "react";
import QRCode from "qrcode";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

const CreateBookingQrCode = ({ message, filnamn }) => {
  const [url, setUrl] = React.useState("");

  message && filnamn && generateAndUploadQRCode({ message, filnamn });

  function generateAndUploadQRCode({ message, filnamn }) {
    return QRCode.toDataURL(message, { errorCorrectionLevel: "H" })
      .then((url) => fetch(url))
      .then((res) => res.blob())

      .then((blob) => {
        const storage = getStorage();
        const qrCodeRef = storageRef(storage, `QR-codes-random/${filnamn}.png`);
        return uploadBytes(qrCodeRef, blob).then(() => qrCodeRef);
      })
      .then((qrCodeRef) => getDownloadURL(qrCodeRef))
      .then((url) => {
        //   console.log("QR code uploaded to Firestore Storage and available at", url);
        setUrl(url);
        return url; // This returns the URL of the uploaded QR code
      })
      .catch((error) => {
        console.error("Error generating or uploading QR code:", error);
        throw error; // Rethrow error to handle it in the calling code
      });
  }

  return (
    <div>
      {url && (
        <a href={url} style={{ background: "grey", width: "100px", height: "100px" }}>
          {url && <img src={url} alt="QR code" />}
        </a>
      )}
    </div>
  );
};

export default CreateBookingQrCode;
