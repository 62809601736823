/** @format */

import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";

// const size = {
//   width: 400,
//   height: 200,
// };

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 30,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieChartCenteredLabel({ data, label, width, height }) {
  return (
    <div style={{ width: width, height: height }}>
      <PieChart
        series={[{ data, innerRadius: 100, outerRadius: 115, paddingAngle: 2, cornerRadius: 5 }]}
        width="400"
        height="300"
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: 20,
              fill: "black",
            },
          },
        }}
      >
        <PieCenterLabel>{label}</PieCenterLabel>
      </PieChart>
    </div>
  );
}
