/** @format */

import { Grid } from "@mui/material";
import React from "react";
import SetImage from "./SetImage";

const CreateArtists = () => {
  return (
    <Grid container>
      <SetImage />
    </Grid>
  );
};

export default CreateArtists;
