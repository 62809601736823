/** @format */

import React, { useEffect, useState } from "react";
import { Box, Slider, Typography, Tooltip } from "@mui/material";
import { set } from "date-fns";
import DisplayObject from "./displayObject";
import PaymentGraph from "../components/UI helpers/PaymentGraph";
import InfoBox from "../components/UI helpers/InfoBox";

// Tooltip component for custom markers
function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

function PaymentSlider({ currentVenue, bookingData, setBookingData, formlock }) {
  const [steps, setSteps] = useState(5);
  console.log("💪 bookingData", bookingData);
  if (isNaN(bookingData.upfrontAt)) setBookingData((prev) => ({ ...prev, upfrontAt: 10000 }));
  if (isNaN(bookingData.upfront)) setBookingData((prev) => ({ ...prev, upfront: 0 }));
  if (isNaN(bookingData.preChargeAt)) setBookingData((prev) => ({ ...prev, preChargeAt: 180 }));
  if (isNaN(bookingData.preCharge)) setBookingData((prev) => ({ ...prev, preCharge: 30 }));
  if (isNaN(bookingData.balanceAt)) setBookingData((prev) => ({ ...prev, balanceAt: 60 }));

  const initialValues = [365 - bookingData.upfrontAt, 365 - bookingData.preChargeAt, 365 - bookingData.balanceAt].reverse(); // Reverse initial values to match the slider's reversed logic

  //   const [values, setValues] = useState([365 - bookingData.upfrontAt, 365 - bookingData.preChargeAt, 365 - bookingData.chargeAt].reverse());
  const [values, setValues] = useState([10000, 365 - bookingData.preChargeAt, 365 - bookingData.balanceAt].reverse());
  console.log("values", values);

  const handleSliderChange = (event, newValues) => {
    if (newValues[0] !== 0) return;
    setValues(newValues);
    let tmp = JSON.parse(JSON.stringify(bookingData));
    tmp.preChargeAt = 365 - newValues[1];
    tmp.balanceAt = 365 - newValues[2];
    tmp.upfrontAt = 365 - newValues[0];
    if (tmp.upfrontAt === 365) tmp.upfrontAt = 10000;
    console.log("tmp.upfrontAt", tmp.upfrontAt);

    setBookingData(tmp);
  };

  const handleChangeSteps = (event, newValue) => {
    console.log("steps", newValue); // Use this value for further processing
    setSteps(newValue);
  };

  // Convert the slider's value to the display value
  const displayValue = (sliderValue) => 365 - sliderValue;

  // Generate marks for the slider based on the current values
  const marks = [
    { value: displayValue(0), label: "365 Days" },
    { value: displayValue(values[0]), label: `Upfront ${bookingData.upfront}%` },
    { value: displayValue(values[1]), label: `Deposition ${bookingData.preCharge}%` },
    { value: displayValue(values[2]), label: `Balance ${bookingData.balance}%` },
    { value: displayValue(365), label: "Event Day" },
    // { value: displayValue(values[1]), label: "Early" },
    // { value: displayValue(values[0]), label: "Late" },
  ].map((mark) => ({ ...mark, value: 365 - mark.value })); // Adjust mark positions to match reversed logic

  const dateDaysPriorTo = (date, days) => {
    if (days === undefined || days === "-") days = 0;

    console.log(date, days);
    return new Date(new Date(date).setDate(new Date(date).getDate() - days)).toLocaleDateString("sv-SE");
  };

  const [upfrontMax, setUpfrontMax] = useState(100);
  const [preChargeMax, setPreChargeMax] = useState(100 - bookingData.upfront || 0);
  const [balanceMax, setBalanceMax] = useState(100 - bookingData.preCharge - bookingData.upfront || 0);

  useEffect(() => {
    setPreChargeMax(100 - bookingData.upfront);
    setBalanceMax(100 - bookingData.preCharge - bookingData.upfront);
  }, [bookingData.upfront]);

  useEffect(() => {
    setUpfrontMax(100 - bookingData.preCharge);
    setBalanceMax(100 - bookingData.preCharge - bookingData.upfront);
  }, [bookingData.preCharge]);

  //   Prevent error with NaN values
  useEffect(() => {
    // Checking each variable and assigning it a new value if it's NaN
    if (Number.isNaN(balanceMax)) setBalanceMax(bookingData.balance);
    if (Number.isNaN(upfrontMax)) setUpfrontMax(bookingData.upfront);
    if (Number.isNaN(preChargeMax)) setPreChargeMax(bookingData.preCharge);
  }, [balanceMax, upfrontMax, preChargeMax]);

  // Prevent further errors with NaN values
  useEffect(() => {
    if (Number.isNaN(bookingData.upfront)) setBookingData((prev) => ({ ...prev, upfront: 0 }));
    if (Number.isNaN(bookingData.preCharge)) setBookingData((prev) => ({ ...prev, preCharge: 0 }));
    if (Number.isNaN(bookingData.balance)) setBookingData((prev) => ({ ...prev, balance: 0 }));
  }, [bookingData.upfront, bookingData.preCharge, bookingData.balance]);

  //   Calculate the balance payment based on the upfront and deposit size
  useEffect(() => {
    setBookingData((prev) => ({ ...prev, balance: 100 - bookingData.preCharge - bookingData.upfront }));
  }, [bookingData.upfront, bookingData.preCharge]);

  //   Make sure values are updated when bookingData changes (changing currentVenue and stuff...)
  useEffect(() => {
    setValues([0, 365 - bookingData.preChargeAt, 365 - bookingData.balanceAt].reverse()); // first value is set to "0" since upfront is to be payed directly
  }, [bookingData]);

  return (
    <>
      <div className="stepsSlider">
        <Slider
          defaultValue={5}
          step={null} // Disable continuous sliding by setting step to null
          marks={[
            { value: 1, label: "1" },
            { value: 5, label: "5" },
            { value: 10, label: "10" },
          ]}
          min={1}
          max={10}
          onChange={handleChangeSteps}
          valueLabelDisplay="off" // Automatically display the value label
        />
      </div>
      <InfoBox
        text="Schedule payment stages for the booking. The payment stages are calculated based on the total booking price. Settings apply to all
        bookings within booking agreement."
      />
      <div className="paymentSlider">
        <div className="paymentGraphSlider">
          <PaymentGraph
            bookingData={bookingData}
            setBookingData={setBookingData}
            upfrontMax={upfrontMax}
            preChargeMax={preChargeMax}
            balanceMax={balanceMax}
            formlock={formlock}
            steps={steps}
          />
          <Slider
            disabled={formlock}
            className="paymentSlider"
            //   components={{ ValueLabel: ValueLabelComponent }}
            getAriaLabel={() => "Financial stages"}
            value={values} // use the state for slider values
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => (value === 0 ? `Direct` : `${365 - value} days ahead`)}
            marks={marks}
            min={0}
            max={365}
            step={5}
            track="inverted"
            size="small"
          />
        </div>
      </div>
    </>
  );
}

export default PaymentSlider;
