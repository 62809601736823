/** @format */

import React, { useState, useEffect } from "react";
import { makeNumber, numberFormatted } from "../../helpers/Numbers";

const UpCount = ({ target, type }) => {
  const [count, setCount] = useState(0);
  // Function to generate a random number between 90 and 100
  const getRandomNumber = () => {
    return Math.floor(Math.random() * (100 - 50 + 1)) + 50;
  };
  const increaseBy = makeNumber(target) / getRandomNumber();
  let index = 1;

  useEffect(() => {
    if (count < makeNumber(target)) {
      index += 2;
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + increaseBy * index);
      }, 5);

      return () => clearInterval(interval); // Cleanup interval on component unmount or when target changes
    }
    if (count > makeNumber(target)) setCount(makeNumber(target));
  }, [count, target]);

  return (
    <div>
      {numberFormatted(count)} {type}
    </div>
  );
};

export default UpCount;
