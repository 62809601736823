/** @format */

import axios from "axios";
export const cancelStripeSubscription = async (subscriptionId) => {
  // const body = {
  // 	checkoutSessionId:
  // 		'cs_live_a1AMNQBnCc8xEHN5pmGdz0esoar7Np5WXjIQgxOm44k3kB3C2rIlWyrafD',
  // };
  const body = {
    checkoutSessionId: subscriptionId,
  };
  console.log("canceling", subscriptionId);
  try {
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/cancelStripeSubscription`, body);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
