/** @format */

import { MultiSelectUnstyled } from "@mui/base";
import { Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useState } from "react";
import { productObj } from "../../dbObjects.js/dbProducts";

const TypeOfEventSelect = () => {
  const [typeOfEventSelected, setTypeOfEventSelected] = useState();
  let typeOfEvents = productObj.find((event) => event.id === "typeOfEvent");
  //   let options = typeOfEvents.options;

  //   const options = typeOfEvents.options.map((type) => ({ value: type, label: type }));

  //   const handleChange = (e, selected) => {
  //     console.log();
  //     setTypeOfEventSelected((prev) => ({ ...prev, selected }));
  //   };

  const options = ["Monday", "Tuesday", "Wednesday"].map((day) => ({
    value: day,
    label: day,
  }));

  setTimeout(() => {
    console.log(options);
  }, 2000);

  return (
    <div>
      {/* <ToggleButtonGroup value={typeOfEventSelected} onChange={handleChange}>
        {options.map((type) => (
          <ToggleButton size="small" style={{ fontSize: "10px" }}>
            {type}
          </ToggleButton>
        ))}
      </ToggleButtonGroup> */}

      {/* <Select
        multiple
        options={options}
        value={typeOfEventSelected}
        onChange={(e, newValue) => setTypeOfEventSelected(newValue)}
        size="small"
      /> */}

      <Select
        multiple
        value={typeOfEventSelected}
        onChange={setTypeOfEventSelected}
        options={options}
        size="small"
        style={{ fontSize: "12px", width: "100px" }}
      />
    </div>
  );
};
export default TypeOfEventSelect;
