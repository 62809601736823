/** @format */

export function updateModulesWithNewModuleData(oldFeatures, newFeatures) {
  console.log("oldFeatures", oldFeatures);
  console.log("newFeatures", newFeatures);

  // deep clone the productsObject in order to be able to change the values on the fly
  let targetFeaturesObject = JSON.parse(JSON.stringify(oldFeatures));

  //   console.log("productsObject", productsObject);

  if (oldFeatures && Object.values(oldFeatures).length) {
    Object.values(oldFeatures).map((feature) => {
      if (targetFeaturesObject[feature.additionalId]) {
        // change the name of the module
        console.log("Gammalt namn", targetFeaturesObject[feature.additionalId].additional);

        targetFeaturesObject[feature.additionalId].additional =
          (newFeatures[feature.additionalId] && newFeatures[feature.additionalId].additional) || "";
        console.log("Nytt namn", targetFeaturesObject[feature.additionalId].additional);

        if (feature.options && Object.values(feature.options).length) {
          Object.values(feature.options).map((option) => {
            console.log("option", option);

            if (
              targetFeaturesObject[feature.additionalId] &&
              targetFeaturesObject[feature.additionalId].options &&
              targetFeaturesObject[feature.additionalId].options[option.optionId]
            ) {
              const targetOption = targetFeaturesObject[feature.additionalId].options[option.optionId];
              const newOption =
                newFeatures[feature.additionalId] &&
                newFeatures[feature.additionalId].options &&
                newFeatures[feature.additionalId].options[option.optionId];
              console.log("newOption", newOption);

              // Skip to the next iteration if newOption is undefined
              if (!newOption) {
                return;
              }

              if (targetOption) {
                // change the name of the option
                if (targetOption.option) {
                  targetOption.option = (newOption && newOption.option) || "";
                }
                // change the description of the option
                if (targetOption.info) {
                  //   alert("info: " + newOption.option + " : " + newOption.info);
                  targetOption.info = (newOption && newOption.info) || "";
                }
                // change the price of the option
                if (targetOption.price) {
                  targetOption.price = (newOption && newOption.price) || 0;
                }
                // change the vat of the option
                if (targetOption.vat) {
                  targetOption.vat = (newOption && newOption.vat) || "0%";
                }
                // change the each status of the option
                if (targetOption.each) {
                  targetOption.each = (newOption && newOption.each) || 0;
                }
                // change the internalCost of the option
                if (targetOption.internalCost) {
                  targetOption.internalCost = (newOption && newOption.internalCost) || 0;
                }
                // Leave(!) the quantity of the option
                // if (targetOption.quantity) {
                // targetOption.quantity = (newOption && newOption.quantity) || 0;
                targetOption.quantity = targetOption.quantity || 0;
                // }
              }
            }
          });
        }
      }
    });
  }

  // turn the nested and updated object back into an array
  //   const arrayAgain = Object.values(targetFeaturesObject);

  // return the updated featureObject to be saved in the database
  //   console.log("featureObject", targetFeaturesObject);
  return targetFeaturesObject;
}
