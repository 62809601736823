/** @format */

import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { db } from "../firebase";
import { cancelStripeSubscription } from "../services/stripe/cancelSubscription";

const Subscribed = () => {
  const [newSub, setNewSub] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const oldSubscriptionId = searchParams.get("oldSubscriptionId");
  const plan = searchParams.get("plan");
  const venueid = searchParams.get("venueid");
  const service = searchParams.get("service");
  const id = searchParams.get("id");

  // Subscription är startad
  // Ta bort gammal subscription om den finns (currentVenue.subscription.id)
  // - kolla om finshark eller stripe?

  // Spara data på currentVenue med ny subscription.id, rätt plan och rätt service.

  // Terminate old subscription
  //   oldSubscriptionId !== "" && cancelStripeSubscription(oldSubscriptionId);

  // Store new subscription to Venue
  service === "Stripe" && setDoc(doc(db, "Venues", venueid), { subscription: { id: id, plan: plan, service: service } }, { merge: true });

  return (
    <div>
      <h3>Subscribed!</h3>
      <div>oldId: {oldSubscriptionId}</div>
      <div>Service: {service}</div>
      <div>Plan: {plan}</div>
      <div>Id: {id}</div>
    </div>
  );
};

export default Subscribed;
