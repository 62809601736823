/** @format */

import { Button, CircularProgress, FormControlLabel, Grid, MenuItem, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectVenue from "../helpers/SelectVenue";
import { useSelector } from "react-redux";
import ObjectSize from "../helpers/ObjectSize";
import { set } from "date-fns";
import { db } from "../firebase";
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { Form } from "react-bootstrap";
import ConfirmDialog from "../helpers/ConfirmDialog";
import BatchChangeBookingParameterName from "../helpers/BatchChangeBookingParameterName";

const BackupManager = () => {
  const [loading, setLoading] = useState(false);
  const [waitPrompt, setWaitPrompt] = useState("");
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const products = currentVenue && currentVenue.products;
  const modules = currentVenue && currentVenue.features;
  const bookings = currentVenue && currentVenue.bookings;
  const agreements = useSelector((state) =>
    state.counter.currentAgreements.filter((agreement) => agreement.venueid === currentVenue.venueid)
  );
  const cleanVenue = { ...currentVenue, bookings: [], features: [], products: [] }; // without bookings, products or modules
  const [backups, setBackups] = useState([]); // [ { id: "timestamp", data: { venue: {}, bookings: {}, products: {}, modules: {} } }
  const [chosenBackup, setChosenBackup] = useState("");
  const [includeVenue, setIncludeVenue] = useState(true);
  const [includeProducts, setIncludeProducts] = useState(true);
  const [includeModules, setIncludeModules] = useState(true);
  const [includeBookings, setIncludeBookings] = useState(true);
  const [includeAgreements, setIncludeAgreements] = useState(true);
  const [venueid, setVenueid] = useState(currentVenue.venueid);
  const [unrelatedAgreements, setUnrelatedAgreements] = useState([]);
  console.log("agreements", agreements);

  useEffect(() => {
    setVenueid(currentVenue.venueid);
  }, [currentVenue]);

  useEffect(() => {
    checkForBackups();
  }, [venueid]);

  useEffect(() => {
    getChosenBackup();
  }, [chosenBackup]);

  useEffect(() => {
    console.log("backups", backups);
  }, [backups]);

  useEffect(() => {
    findUnrelatedAgrements();
  }, [agreements.length]);

  useEffect(() => {
    console.log("unrelatedAgreements", unrelatedAgreements);
  }, [unrelatedAgreements]);

  async function checkForBackups() {
    setBackups(["waiting..."]); // clear backups
    try {
      console.log("check for backups in venue:", currentVenue.venueid);
      const backupsCollectionRef = collection(db, "BackupManager", venueid, "Backups");

      const querySnapshot = await getDocs(backupsCollectionRef);
      const backupDocuments = [];
      querySnapshot.forEach((doc) => {
        backupDocuments.push({ id: doc.id, data: doc.data() });
      });

      console.log("Backup Documents:", backupDocuments);
      setBackups(backupDocuments);
      return backupDocuments;
    } catch (error) {
      console.error("Error finding backup documents:", error);
    }
  }

  async function getChosenBackup() {
    try {
      // Step 1: Reference the specific document in the subcollection
      const backupDocRef = doc(db, "BackupManager", venueid, "Backups", chosenBackup);

      // Step 2: Fetch the document
      const docSnap = await getDoc(backupDocRef);

      if (docSnap.exists()) {
        console.log("Backup", docSnap.data());

        // Step 3: Reference the Bookings subcollection within the chosen backup document
        const bookingsCollectionRef = collection(backupDocRef, "Bookings");

        // Step 4: Fetch all documents in the Bookings subcollection
        const bookingsSnapshot = await getDocs(bookingsCollectionRef);

        const bookings = bookingsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        console.log("Bookings", bookings);

        return {
          backupData: docSnap.data(),
          bookings: bookings,
        };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching chosen backup and bookings:", error);
      return null;
    }
  }

  function getFormattedDate() {
    const date = new Date();
    // Extract parts of the date
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, pad with 0
    const day = date.getDate().toString().padStart(2, "0"); // Pad with 0
    const hours = date.getHours().toString().padStart(2, "0"); // Pad with 0
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Pad with 0
    // Combine into the desired format
    const formattedDate = `${year}-${month}-${day}(${hours}:${minutes})`;
    return formattedDate;
  }

  const createBackup = async () => {
    setLoading(true);
    const timestamp = getFormattedDate();

    console.log("bookings", bookings);
    try {
      //store bookings
      //   for (const booking of bookings, const index = 0; index < bookings.length; index++) {
      for (let index = 0; index < bookings.length; index++) {
        const booking = bookings[index];
        setWaitPrompt(`backing up bookings... ${bookings.length - index} left`);
        console.log("Booking:", booking);
        const docRef = doc(collection(db, "BackupManager"), currentVenue.venueid, "Backups", timestamp, "Bookings", booking.bookingid);
        await setDoc(docRef, { booking, timestamp });
        console.log(`Backup created for booking ID: ${booking.bookingid}`);
      }
      // store venue
      setWaitPrompt("backing up venue... ");
      const venueRef = doc(collection(db, "BackupManager"), currentVenue.venueid, "Backups", timestamp);
      await setDoc(venueRef, { venue: cleanVenue }, { merge: true });
      // store products
      setWaitPrompt("backing up products... ");
      const productsRef = doc(collection(db, "BackupManager"), currentVenue.venueid, "Backups", timestamp);
      await setDoc(productsRef, { products }, { merge: true });
      // store modules
      setWaitPrompt("backing up modules... ");
      const modulesRef = doc(collection(db, "BackupManager"), currentVenue.venueid, "Backups", timestamp);
      await setDoc(modulesRef, { modules }, { merge: true });
      // store agreements
      setWaitPrompt("backing up agreements... ");
      const agreementsRef = doc(collection(db, "BackupManager"), currentVenue.venueid, "Backups", timestamp);
      await setDoc(agreementsRef, { agreements }, { merge: true });
      setWaitPrompt("");

      console.log("All backups created successfully");
    } catch (error) {
      console.error("Error creating backups:", error);
    }
    setLoading(false);
  };

  async function deleteEntire() {
    // detele all bookings
    for (const booking of bookings) {
      // await deleteDoc(doc(db, "Bookings", booking.bookingid)); // Commented for safety reasons
      console.log(`Backup deleted for booking ID: ${booking.bookingid}`);
    }
    // detele all agreements
    for (const agreement of agreements) {
      await deleteDoc(doc(db, "Agreements", agreement));
      console.log(`Backup deleted for agreement ID: ${agreement.agreementId}`);
    }
  }

  async function findUnrelatedAgrements() {
    console.log("findUnrelatedAgrements", agreements);
    const unrelated = [];
    for (const agreement of agreements) {
      const booking = bookings.find((booking) => booking.agreementId === agreement.agreementId);
      if (!booking) {
        unrelated.push(agreement);
      }
    }
    console.log("🌞 unrelatedAgreements", unrelated);
    setUnrelatedAgreements(unrelated);
  }

  async function deleteUnrelatedAgreements() {
    console.log("deleteUnrelatedAgreements", unrelatedAgreements);
    for (const agreement of unrelatedAgreements) {
      await deleteDoc(doc(db, "Agreements", agreement.agreementId));
      console.log(`Original deleted for agreement ID: ${agreement.agreementId}`);
    }
  }

  return (
    <Grid container p={4} flexDirection="column">
      <Grid container>
        <Grid item>
          <h1>Backup Manager</h1>
        </Grid>

        <Grid item>{/* <SelectVenue /> */}</Grid>

        <Grid container flexDirection="row" spacing={4}>
          <Grid item>
            <h7>Make Backup</h7>
          </Grid>
          <Grid item>
            {/* <FormControlLabel
              control={<Switch checked={includeVenue} onChange={() => setIncludeVenue(!includeVenue)} />}
              label="Include Venue"
            /> */}
            venue: {cleanVenue && cleanVenue.venue} <ObjectSize dataObject={cleanVenue} />
          </Grid>
          <Grid item>
            {/* <FormControlLabel
              control={<Switch checked={includeProducts} onChange={() => setIncludeProducts(!includeProducts)} />}
              label="Include Products"
            /> */}
            products: {products && products.length} <ObjectSize dataObject={products} />
          </Grid>
          <Grid item>
            {/* <FormControlLabel
              control={<Switch checked={includeModules} onChange={() => setIncludeModules(!includeModules)} />}
              label="Include Modules"
            /> */}
            modules: {modules && Object.keys(modules).length} <ObjectSize dataObject={modules} />
          </Grid>
          <Grid item>
            {/* <FormControlLabel
              control={<Switch checked={includeBookings} onChange={() => setIncludeBookings(!includeBookings)} />}
              label="Include Bookings"
            /> */}
            bookings: {bookings && bookings.length} <ObjectSize dataObject={bookings} />
          </Grid>
          <Grid item>
            {/* <FormControlLabel
              control={<Switch checked={includeAgreements} onChange={() => setIncludeAgreements(!includeAgreements)} />}
              label="Include Agreements"
            /> */}
            agreements: {agreements && agreements.length} <ObjectSize dataObject={agreements} />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => createBackup()} mt={4}>
              {loading ? <>{waitPrompt}</> : "Backup everything"}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={4} mt={4}>
          <Grid item>
            <h7>Restore Backup</h7>
          </Grid>

          <Grid item>
            <TextField
              placeHolder="Enter VenueId"
              label="venueId"
              size="small"
              value={venueid}
              onChange={(e) => {
                setVenueid(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            {console.log()}
            {backups && backups.length > 0 ? (
              backups.map((backup) => (
                <MenuItem onClick={() => setChosenBackup(backup.id)}>
                  {backup.id} <ObjectSize dataObject={backup} />
                </MenuItem>
              ))
            ) : (
              <div>No backups found</div>
            )}
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                alert("To restore backups, you need to implement a restore function.");
              }}
            >
              Restore
            </Button>
          </Grid>
          <Grid item>
            <ConfirmDialog
              onConfirm={() => deleteUnrelatedAgreements()}
              noButton
              header="Delete agreements?"
              message={`You're about to delete unrelated agreements that have no corresponding booking. Should be totally safe. Continue?`}
            >
              {unrelatedAgreements.length > 0 ? (
                <Button onClick={() => alert("Wow..!")}>Found {unrelatedAgreements.length} unrelated agreements</Button>
              ) : (
                <>No unrelated Agreements found.</>
              )}
            </ConfirmDialog>
          </Grid>
          <Grid item>
            <ConfirmDialog
              noButton
              onConfirm={() => deleteEntire()}
              header="Really..?"
              message="⚠️ This deletes related agreements and bookings permanently."
            >
              <Button>Delete Bookings and Agreements</Button>
            </ConfirmDialog>
          </Grid>
        </Grid>
        <Grid container spacing={4} mt={4}>
          <Grid item>
            <BatchChangeBookingParameterName bookings={bookings} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BackupManager;
