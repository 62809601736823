/** @format */

import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, MenuItem, Select } from "@mui/material";
// import { Phone } from "@material-ui/icons";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DisplayObject from "../../helpers/displayObject";

export default function PhoneInput({ formValue, setPhoneNumber }) {
  const [number, setNumber] = useState(formValue && formValue);
  const [countryCode, setCountryCode] = useState("+46"); // Default country code
  const [placeholder, setPlaceholder] = useState("Enter number");

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  //If if number is already stored (in formValues) and just need to be shown after reloading.
  useEffect(() => {
    //show stored number as the placeholder if it exists.
    formValue === "undefined" ? setPlaceholder("Enter Number") : setPlaceholder(formValue);
    setNumber(formValue);
  }, [formValue]);

  //If number starts with "0" remove leading zero
  function removeLeadingZero(value) {
    if (typeof value !== "string") {
      return value;
    }
    if (value.charAt(0) === "0") {
      return value.substring(1);
    }
    return value;
  }

  //Handle countryCode (remove it in order to add it lastly to avoid multiple countryCodes)
  const removeCountryCode = (phoneNumber) => {
    let updatedPhoneNumber = phoneNumber;
    phoneNumber = phoneNumber || "";
    countryCodes.forEach((country) => {
      if (phoneNumber && phoneNumber.startsWith(country.value)) {
        updatedPhoneNumber = phoneNumber.substring(country.value.length);
      }
    });
    return updatedPhoneNumber;
  };

  const formatNumber = async (value) => {
    //remove if leading zero
    let tmp = await removeLeadingZero(value);
    //remove if leadaing countryCode
    tmp = await removeCountryCode(tmp);
    //lastly add countryCode
    tmp = (await countryCode) + tmp;
    if (!value) tmp = "";
    setPhoneNumber(tmp);

    return tmp;
  };

  useEffect(() => {
    formatNumber(number);
  }, [countryCode]);

  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      label="Phone Number"
      type="number"
      placeholder={number ? placeholder : "070 xxx xx xx"}
      InputProps={{
        startIcon: <PhoneIphoneIcon />,
        startAdornment: (
          <InputAdornment position="start">
            <Select
              className="phoneInput"
              value={countryCode}
              onChange={handleCountryCodeChange}
              size="small"
              style={{ color: "transparent" }}
            >
              {countryCodes.map((country) => (
                <MenuItem key={country.value} value={country.value} disabled={country.disabled}>
                  <span>{country.flag}</span>
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
      defaultValue={formValue}
      value={number}
      onChange={(e) => setNumber(e.target.value)}
      // onChange={(e) => setNumber(e.target.value)}
      onBlur={(e) => formatNumber(e.target.value)}
    />
  );
}

const countryCodes = [
  //Scandinavia
  {
    label: "Denmark",
    flag: "🇩🇰",
    value: "+45",
  },
  {
    label: "Finland",
    flag: "🇫🇮",
    value: "+358",
  },
  {
    label: "Iceland",
    flag: "🇮🇸",
    value: "+354",
  },
  {
    label: "Norway",
    flag: "🇳🇴",
    value: "+47",
  },
  {
    label: "Sweden",
    flag: "🇸🇪",
    value: "+46",
  },
];
