/** @format */
import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { countries } from "../dbObjects.js/dbCountries";

const StandardVatSelect = ({ formData, handleChange }) => {
  // const [vatOptions, setVatOptions] = useState([]);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  // If country not set for venue
  // If country is set för venue
  // setVatOptions(countries.find((country) => country.country === currentVenue.country).vatOptions);
  const vatOptions = countries.find((country) => country.country === currentVenue.country).vatOptions || "";

  return (
    <div>
      Country:{currentVenue.country}
      <FormControl>
        <InputLabel>Vat (standard rate)</InputLabel>
        <Select
          size="small"
          style={{ width: "200px" }}
          defaultValue="Standard Rate"
          value={formData["standardVat"]}
          onChange={(event) => handleChange(event, "standardVat", "text")}
        >
          {vatOptions.map((option, index) => (
            <MenuItem key={index} value={option.vatRate}>
              {option.vatRate}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default StandardVatSelect;
