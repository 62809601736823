/** @format */

import React, { useEffect } from 'react';
import {
	GoogleMap,
	useJsApiLoader,
	MarkerF,
	OverlayViewF,
	OverlayView,
	Marker,
	LoadScript,
	Autocomplete,
	StandaloneSearchBox,
} from '@react-google-maps/api';
import { useState } from 'react';
import { useCallback } from 'react';
import { Box, Button, Modal } from '@mui/material';

const containerStyle = {
	width: '400px',
	height: '400px',
};

const center = {
	lat: -3.745,
	lng: -38.523,
};

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: '40vh',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	overflow: 'scroll',
};

const AddLocation = ({ setFormValues, formValues, fieldPrepend }) => {
	const key = process.env.REACT_APP_GOOGLE_API_KEY;
	const [open, setOpen] = React.useState(false);
	//   const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [map, setMap] = React.useState(null);
	const [markers, setMarkers] = useState([]);
	const [coordinates, setCoordinates] = useState(undefined);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	});

	console.log(fieldPrepend);

	const onMapClick = (e) => {
		//We only want to set one marker at this point
		setMarkers(() => [
			{
				lat: e.latLng.lat(),
				lng: e.latLng.lng(),
			},
		]);
		setFormValues({
			...formValues,
			venueLat: e.latLng.lat(),
			venueLng: e.latLng.lng(),
		});
		setCoordinates({
			latitude: e.latLng.lat(),
			longitude: e.latLng.lng(),
		});
		setTimeout(() => {
			handleClose();
		}, 1000);
	};

	React.useEffect(() => {
		if (map) map.setZoom(6);
		// if (map) setZoomValue(map.getZoom());
	}, [map]);

	const center = {
		lat: 59,
		lng: 18,
	};

	const getAddressFromCoordinates = async () => {
		const response = await fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.latitude},${coordinates.longitude}&key=${key}`,
		);
		const data = await response.json();
		const addressComponents = data.results[0].address_components;
		const formattedAddress = data.results[0].formatted_address;
		let address = '';
		let postalCode = '';
		let city = '';
		let country = '';
		let County = '';
		let CountyB = '';
		// let venueCounty = "";
		// let venueCountyB = "";

		addressComponents.forEach((component) => {
			if (component.types.includes('street_number')) {
				address += component.long_name + ' ';
			} else if (component.types.includes('route')) {
				address += component.long_name;
			} else if (component.types.includes('postal_code')) {
				postalCode = component.long_name;
			} else if (component.types.includes('postal_town')) {
				city = component.long_name;
			} else if (component.types.includes('country')) {
				country = component.long_name;
			} else if (component.types.includes('sublocality_level_1')) {
				County = component.long_name;
			} else if (
				component.types.includes('administrative_area_level_1')
			) {
				CountyB = component.long_name;
			}
		});
		fieldPrepend === 'venue' &&
			setFormValues({
				...formValues,
				venueStreetAddress: address,
				venueZipCode: postalCode,
				venueCity: city,
				venueCountry: country,
				venueCounty: County,
				venueCountyB: CountyB,
			});

		fieldPrepend === 'agent' &&
			setFormValues({
				...formValues,
				agentStreetAddress: address,
				agentZipCode: postalCode,
				agentCity: city,
				agentCountry: country,
				agentCounty: County,
				agentCountyB: CountyB,
			});

		fieldPrepend === 'artist' &&
			setFormValues({
				...formValues,
				artistStreetAddress: address,
				artistZipCode: postalCode,
				artistCity: city,
				artistCountry: country,
				artistCounty: County,
				artistCountyB: CountyB,
			});
	};

	useEffect(() => {
		if (coordinates) {
			getAddressFromCoordinates();
		}
	}, [coordinates]);

	//   const onLoad = useCallback(function callback(loadedMap) {
	//     setMap(loadedMap);
	//     const bounds = new window.google.maps.LatLngBounds(center);
	//   }, []);

	//   const onLoad = (ref) => (this.searchBox = ref);

	//   const onPlacesChanged = () => console.log(this.searchBox.getPlaces());

	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
			<Button onClick={() => setOpen(true)}>
				Get Address for {fieldPrepend}
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={center}
						zoom={10}
						onClick={onMapClick}
						mapContainerStyle={{
							width: '100%',
							height: '35vh',
						}}
					>
						{markers.map((marker) => (
							<MarkerF
								position={{
									lat: marker.lat,
									lng: marker.lng,
								}}
							/>
						))}
						{/* Child components, such as markers, info windows, etc. */}
						{/* <StandaloneSearchBox onLoad={onload} onPlacesChanged={onPlacesChanged}>
          <input
            type="text"
            placeholder="Customized your placeholder"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox> */}
					</GoogleMap>
				</Box>
			</Modal>
		</LoadScript>
	);
};

export default AddLocation;
