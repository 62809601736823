/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  MdOutlineCalendarToday,
  MdAttachEmail,
  MdOutlineDriveFileRenameOutline,
  MdOutlineMenu,
  MdPeopleOutline,
  MdOutlineSearch,
  MdOutlineToday,
  MdOutlineMap,
} from "react-icons/md";
import ReactSlider from "react-slider";
import Form from "react-bootstrap/Form";
import PreferredDays from "./PreferredDays";
import Slider from "@mui/material/Slider";
import CapSlider from "./CapSlider";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import RangeSlider from "./RangeSlider";
import DateRange from "./DateRange";
import Drawer from "@mui/material/Drawer";
import CreateTour from "../../features/create tour/CreateTour";
import { useDispatch, useSelector } from "react-redux";
import FilterVenues from "../../features/venueFilter/FilterVenues";
import { storeAverageTicketPrice } from "../../features/venueFilter/venueFilterSlice";
import CreateTimeline from "../../features/create tour/CreateTimeline";
import NumberOfEventsSlider from "./NumberOfEventsSlider";
import SendRequest from "../../helpers/SendRequest";
import SendMultiRequest from "../../helpers/SendMultiRequest";
import SeatedStandingSelect from "./SeatedStandingSelect";
import TypeOfEventSelect from "./TypeOfEventSelect";

// const drawerWidth = 240;

const BottomAgentControls = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ticketPrice, setTicketPrice] = useState(0);
  const searchVenueRef = useRef();
  const ticketPriceRef = useRef(0);
  const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storeAverageTicketPrice(ticketPrice));
  }, [ticketPrice]);

  return (
    <>
      <Grid item xs={12} sm={2} mt={-1}>
        <div className="filterLabel">Date Range</div>
        <DateRange />
      </Grid>

      <Grid item container xs={12} p={0} mt={2}>
        <Grid item xs={11} sm={2} mx={2} my={1}>
          <div className="filterLabel">Capacity</div>
          <RangeSlider />
        </Grid>

        <Grid item xs={12} sm={2} mx={3} mt={1}>
          <div className="filterLabel">Preferred Days</div>
          {/* {currentArtist.prefDays} */}
          <PreferredDays numberOfLetters={2} prefDays={currentArtist.prefDays} />
        </Grid>

        {/* <Grid item xs={8} sm={1} my={1} ml={2} my={1}>
          <NumberOfEventsSlider />
        </Grid> */}

        <Grid item xs={12} sm={2} mx={3} mt={1}>
          <div className="filterLabel">Type of Event</div>
          {/* {currentArtist.prefDays} */}
          <TypeOfEventSelect />
        </Grid>

        <Grid item xs={12} sm={1} mt={1} ml={3} mr={2}>
          <div className="filterLabel">Ticket Price</div>
          <TextField
            type="number"
            defaultValue={ticketPrice}
            onChange={(e) => setTicketPrice(e.target.value)}
            size="small"
            // fullWidth={true}
          />
        </Grid>

        <Grid item xs={12} sm={2} mx={2} mt={3}>
          <CreateTour showMap={props.setMapsIsOpen} />
          {/* <Box>
            <SendMultiRequest />
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
};

export default BottomAgentControls;
