/** @format */

import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

function ChooseBetween({ min, max }) {
  const [value, setValue] = useState(min);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const generateOptions = () => {
    const numbers = [];
    for (let i = min; i <= max; i++) {
      numbers.push(i);
    }
    return numbers;
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="number-select-outlined-label">Dates wanted</InputLabel>
      <Select value={value} onChange={handleChange} label="Dates wanted" size="small" sx={{ width: "80px" }}>
        {generateOptions().map((num) => (
          <MenuItem key={num} value={num}>
            {num}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ChooseBetween;

// Usage:
// <NumberSelect min={5} max={15} />
