import React, { useState, useEffect, useRef } from 'react';
import {
	Box,
	Button,
	Typography,
	Modal,
	IconButton,
	Grid,
	TextField,
	Divider,
} from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { updateDocument } from '../../firebase';
const style = {
	wrapper: {
		position: 'absolute',
		width: '90%',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '80vh',
		overflow: 'auto',
	},
	headerContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	headerTitle: {
		flex: 9,
		fontSize: '20px',
		textAlign: 'center',
	},
	headerCloseIcon: {
		flex: 1,
	},
	bodyContainer: {
		display: 'flex',
		flex: 10,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	artistDetailsContainer: {
		display: 'flex',
		flex: 5,
		width: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	venueDetailsContainer: {
		display: 'flex',
		flex: 5,
		width: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	bookingDetailsTitle: {
		fontWeight: 'bold',
		fontSize: '16px',
		marginBottom: '-3px',
	},
	bookingDetailsValue: {
		fontWeight: 'bold',
		fontSize: '16px',
		marginBottom: '-3px',
	},
	buttonContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
};

export const ReviewBookingModal = (props) => {
	const [formValues, setFormValues] = useState({});
	const handleClose = () => {
		props.setShowReviewBooking(false);
	};
	const handleOnChange = (event, valueKey) => {
		setFormValues({ ...formValues, [valueKey]: event.target.value });
	};
	const handleOnSave = () => {
		updateDocument('Bookings', formValues, formValues.bookingid);
	};
	const acceptBooking = () => {
		updateDocument(
			'Bookings',
			{ status: 'Awaiting Artist' },
			props.selectedEvent.bookingid,
		);
		handleClose();
		props.closeArtistInfo();
	};
	useEffect(() => {
		setFormValues(props.selectedEvent);
	}, [props.selectedEvent]);

	return (
		<Grid container>
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style.wrapper}>
					<div style={style.headerContainer}>
						<h1 style={style.headerTitle}>Review Booking</h1>
						<IconButton onClick={handleClose}>
							<Close />
						</IconButton>
					</div>
					<div style={style.bodyContainer}>
						<div style={style.artistDetailsContainer}>
							<h4>Artist info</h4>
							<Divider
								variant='middle'
								sx={{
									backgroundColor: '#FFFFFF',
									height: '2px',
									marginBottom: '2px',
								}}
							/>
							<Grid container spacing={1}>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										Artist:
									</p>
									<p>{formValues.artist}</p>
								</Grid>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										Agent:
									</p>
									<p>{formValues.agent}</p>
								</Grid>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										Contact:
									</p>
									<p>{formValues.agentEmail}</p>
								</Grid>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										Phone number:
									</p>
									<p>{formValues.agentPhone}</p>
								</Grid>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										Booking date:
									</p>
									<p>{formValues.date}</p>
								</Grid>
							</Grid>
						</div>
						<div style={style.venueDetailsContainer}>
							<h4>Venue info</h4>
							<Divider
								variant='middle'
								sx={{
									backgroundColor: '#FFFFFF',
									height: '2px',
									marginBottom: '2px',
								}}
							/>
							<Grid container spacing={1}>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										Venue:
									</p>
									<EditText
										name='textbox3'
										value={formValues.venue}
										onChange={(event) => {
											handleOnChange(event, 'venue');
										}}
										onBlur={handleOnSave}
										editButtonContent={<Edit />}
										editButtonProps={{
											style: {
												marginLeft: '5px',
												width: 16,
												backgroundColor: 'transparent',
												color: 'white',
											},
										}}
										showEditButton
									/>
								</Grid>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										City:
									</p>
									<EditText
										name='textbox3'
										value={formValues.venueCity}
										onBlur={handleOnSave}
										onChange={(event) => {
											handleOnChange(event, 'venueCity');
										}}
										editButtonContent={<Edit />}
										editButtonProps={{
											style: {
												marginLeft: '5px',
												width: 16,
												backgroundColor: 'transparent',
												color: 'white',
											},
										}}
										showEditButton
									/>
								</Grid>
								<Grid item xs={12} sm>
									<p style={style.bookingDetailsTitle}>
										Address:
									</p>
									<EditText
										name='textbox3'
										value={formValues.venueStreetAddress}
										onBlur={handleOnSave}
										onChange={(event) => {
											handleOnChange(
												event,
												'venueStreetAddress',
											);
										}}
										editButtonContent={<Edit />}
										editButtonProps={{
											style: {
												marginLeft: '5px',
												width: 16,
												backgroundColor: 'transparent',
												color: 'white',
											},
										}}
										showEditButton
									/>
								</Grid>
							</Grid>
							<div style={style.buttonContainer}>
								<Button onClick={acceptBooking}>
									Accept Booking
								</Button>
								<Button onClick={handleClose}>Go back</Button>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</Grid>
	);
};
