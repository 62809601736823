/** @format */

// 2024-11-21T19:00:00+01:00

// 2024-11-21T20:30:00+01:00

// Find tickeEventId for a booking that doesn't have one

import React from "react";
import CopyText from "../../components/UI helpers/CopyText";
import { Select } from "@mui/material";

const FindTicketEventId = ({ eventTitle, start, end, date }) => {
  const data = summarizedData;
  const bookingStart = date + "T" + start + ":00+01:00";
  const bookingEnd = date + "T" + end + ":00+01:00";

  findEventWithinBooking(data, bookingStart, bookingEnd);

  //   function findEventWithinBooking(events, bookingStartStr, bookingEndStr) {
  //     const bookingStart = new Date(bookingStartStr);
  //     const bookingEnd = new Date(bookingEndStr);
  //     return events
  //       .filter((event) => {
  //         const eventStart = new Date(event.eventStart);
  //         const eventEnd = new Date(event.eventEnd);

  //         // Check if event period overlaps with booking period
  //         return (
  //           (eventStart >= bookingStart && eventStart <= bookingEnd) ||
  //           (eventEnd >= bookingStart && eventEnd <= bookingEnd) ||
  //           (eventStart <= bookingStart && eventEnd >= bookingEnd) // Event encompasses the entire booking period
  //         );
  //       })
  //       .map((event) => ({ ticketEventId: event.ticketEventId, eventName: event.eventName }));
  //   }

  function findEventWithinBooking(events, bookingStartStr, bookingEndStr) {
    const bookingStart = new Date(bookingStartStr);
    const bookingEnd = new Date(bookingEndStr);

    return events
      .filter((event) => {
        const eventStart = new Date(event.eventStart);
        const eventEnd = new Date(event.eventEnd);

        // Check if event period overlaps with booking period and if the date matches eventStart date
        return (
          eventStart.toDateString() === bookingStart.toDateString() &&
          ((eventStart >= bookingStart && eventStart <= bookingEnd) ||
            (eventEnd >= bookingStart && eventEnd <= bookingEnd) ||
            (eventStart <= bookingStart && eventEnd >= bookingEnd)) // Event encompasses the entire booking period
        );
      })
      .map((event) => ({ ticketEventId: event.ticketEventId, eventName: event.eventName }));
  }

  return (
    <div>
      <Select>
        <table className="smallTable">
          <th>Name</th>
          <th>Id</th>
          <th>Start</th>
          <th>End</th>
          {findEventWithinBooking(data, bookingStart, bookingEnd).map((event) => {
            return (
              <tr>
                <td> {event.eventName}</td>
                <td style={{ color: "#00ffd4" }}>
                  <CopyText>{event.ticketEventId}</CopyText>
                </td>
                <td>{event.eventStart}</td>
                <td>{event.eventEnd}</td>
              </tr>
            );
          })}
        </table>
      </Select>
    </div>
  );
};

export default FindTicketEventId;

// Sample Data

const summarizedData = [
  {
    ticketEventId: "z4aw0arweb4ut4z",
    ticketProductionId: "4JH3XGXZ4BYMC2Z",
    eventName: "Ahmed Berhan - Inget driv",
    eventStart: "2024-11-02T20:00:00+01:00",
    eventEnd: "2024-11-02T21:15:00+01:00",
    currency: "SEK",
    tickets: [],
    summary: {
      sum: 0,
      count: 0,
    },
    bookingId: "binn7eearscfdrgiingwvku2vklobozo",
  },
  {
    ticketEventId: "5ldxt8t9dplntd5",
    ticketProductionId: "FH9M23DBGPPR9UJ",
    eventName: "PetrINA",
    eventStart: "2024-11-21T19:00:00+01:00",
    eventEnd: "2024-11-21T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie",
        cost: 395,
        sum: 104675,
        count: 265,
        vat: "6%",
      },
    ],
    summary: {
      sum: 104675,
      count: 265,
    },
    bookingId: "afdo4ykapx3qostrckeyqbbytxujiuwi",
  },
  {
    ticketEventId: "n68jez90xexyk96",
    ticketProductionId: "FH9M23DBGPPR9UJ",
    eventName: "PetrINA",
    eventStart: "2024-11-22T20:00:00+01:00",
    eventEnd: "2024-11-22T21:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie",
        cost: 395,
        sum: 130745,
        count: 331,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 395,
        sum: 20935,
        count: 53,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd, balkong",
        cost: 395,
        sum: 7110,
        count: 18,
        vat: "6%",
      },
    ],
    summary: {
      sum: 158790,
      count: 402,
    },
    bookingId: "63rxm22weatusoobiqlwmmfo6oxrjgbp",
  },
  {
    ticketEventId: "ud44wf0a2pt1v22",
    ticketProductionId: "XVG9A4YREXRWNDA",
    eventName: "Stockholm Jazz festival på Maxim",
    eventStart: "2024-10-19T20:00:00+02:00",
    eventEnd: "2024-10-19T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 450,
        sum: 177750,
        count: 395,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 450,
        sum: 9000,
        count: 20,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, dold sikt",
        cost: 450,
        sum: 2250,
        count: 5,
        vat: "6%",
      },
    ],
    summary: {
      sum: 189000,
      count: 420,
    },
    bookingId: "5y52327la47zlnmsw5pr",
  },
  {
    ticketEventId: "le2r4ykkn8915l7",
    ticketProductionId: "XVG9A4YREXRWNDA",
    eventName: "Stockholm Jazz festival på Maxim",
    eventStart: "2024-10-20T20:00:00+02:00",
    eventEnd: "2024-10-20T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 117600.00000000006,
        count: 359,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 350,
        sum: 26600,
        count: 79,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 350,
        sum: 7000,
        count: 20,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, dold sikt",
        cost: 350,
        sum: 1750,
        count: 5,
        vat: "6%",
      },
    ],
    summary: {
      sum: 152949.99999999997,
      count: 463,
    },
    bookingId: "2vjgydpku5hgcorupnlk2rmf6xv5gq6d",
  },
  {
    ticketEventId: "ecy1vggyt5h5lj6",
    ticketProductionId: "XVG9A4YREXRWNDA",
    eventName: "Stockholm Jazz festival på Maxim",
    eventStart: "2024-10-17T20:00:00+02:00",
    eventEnd: "2024-10-17T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 450,
        sum: 105300,
        count: 234,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 450,
        sum: 9450,
        count: 21,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, dold sikt",
        cost: 450,
        sum: 1800,
        count: 4,
        vat: "6%",
      },
      {
        type: "Rullstolsplats",
        cost: 450,
        sum: 450,
        count: 1,
        vat: "6%",
      },
      {
        type: "Ordinarie,barstol med ryggstöd",
        cost: 450,
        sum: 1800,
        count: 4,
        vat: "6%",
      },
    ],
    summary: {
      sum: 118800,
      count: 264,
    },
    bookingId: "7ia3kj77dci7azp5i74v34ye7lialjhi",
  },
  {
    ticketEventId: "1kzdjde3g02d7h0",
    ticketProductionId: "XVG9A4YREXRWNDA",
    eventName: "Stockholm Jazz festival på Maxim",
    eventStart: "2024-10-18T20:00:00+02:00",
    eventEnd: "2024-10-18T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 450,
        sum: 77400,
        count: 179,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, dold sikt",
        cost: 450,
        sum: 900,
        count: 2,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 450,
        sum: 16200,
        count: 38,
        vat: "6%",
      },
      {
        type: "Ordinarie barstol med ryggstöd",
        cost: 225,
        sum: 2250,
        count: 6,
        vat: "6%",
      },
    ],
    summary: {
      sum: 96750,
      count: 225,
    },
    bookingId: "4pn52x2nqfzovsqrgx6vqe7nts2qfay4",
  },
  {
    ticketEventId: "dw92zkv2da51zuu",
    ticketProductionId: "",
    eventName: "Peter Apelgren - Livets efterrätt",
    eventStart: "",
    eventEnd: "",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 146965,
        count: 323,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 21840,
        count: 48,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 12740,
        count: 20,
        vat: "6%",
      },
    ],
    summary: {
      sum: 181545,
      count: 391,
    },
    bookingId: "534kucmkv4g2utmb3afksivrllakilpz",
  },
  {
    ticketEventId: "cpb72l4dxzlkb6f",
    ticketProductionId: "XVG9A4YREXRWNDA",
    eventName: "Stockholm Jazz festival på Maxim",
    eventStart: "2024-10-21T20:00:00+02:00",
    eventEnd: "2024-10-21T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 111650,
        count: 319,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 466.6666666666667,
        sum: 26250.000000000004,
        count: 73,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 350,
        sum: 8400,
        count: 24,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, skymd sikt",
        cost: 350,
        sum: 1750,
        count: 5,
        vat: "6%",
      },
    ],
    summary: {
      sum: 148049.99999999997,
      count: 421,
    },
    bookingId: "3jwxac6frtadyg3h2wsrojq3hsipdn6p",
  },
  {
    ticketEventId: "a7xtkwrfeg7wv4e",
    ticketProductionId: "P7470VTG6YK0XLD",
    eventName: "Peter Apelgren - Livets efterrätt",
    eventStart: "2024-12-21T16:00:00+01:00",
    eventEnd: "2024-12-21T17:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 155610,
        count: 342,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 19110,
        count: 42,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 5915,
        count: 13,
        vat: "6%",
      },
    ],
    summary: {
      sum: 180635,
      count: 397,
    },
    bookingId: "5me2iu24dklfgtul7g56epuopisirvka",
  },
  {
    ticketEventId: "2wc3hb6k0bhfxrj",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-05T14:00:00+01:00",
    eventEnd: "2025-01-05T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 42750,
        count: 102,
        vat: "6%",
      },
      {
        type: "Parkett barn",
        cost: 350,
        sum: 7700,
        count: 22,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 4200,
        count: 15,
        vat: "6%",
      },
    ],
    summary: {
      sum: 54650,
      count: 139,
    },
    bookingId: "662rrq7wqgcoavo5o2ltb36k5phpe3fn",
  },
  {
    ticketEventId: "bzul4zg0ye0gyty",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-05T19:00:00+01:00",
    eventEnd: "2025-01-05T22:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 21150,
        count: 47,
        vat: "6%",
      },
      {
        type: "Parkett barn",
        cost: 350,
        sum: 2450,
        count: 7,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 1050,
        count: 3,
        vat: "6%",
      },
    ],
    summary: {
      sum: 24650,
      count: 57,
    },
    bookingId: "5q2zd6m42kvss53kfb5kgryat2hobt7o",
  },
  {
    ticketEventId: "9evjc0u5478fd5m",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-02-01T14:00:00+01:00",
    eventEnd: "2025-02-01T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 19350,
        count: 43,
        vat: "6%",
      },
      {
        type: "Parkett barn",
        cost: 350,
        sum: 1050,
        count: 3,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
    ],
    summary: {
      sum: 21100,
      count: 48,
    },
    bookingId: "adjt76jgmourfuefbk3nbjlpnj64d2u4",
  },
  {
    ticketEventId: "eu8mfmjz4k64t8n",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-12T14:00:00+01:00",
    eventEnd: "2025-01-12T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 5250,
        count: 15,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 13500,
        count: 30,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 5950,
        count: 17,
        vat: "6%",
      },
    ],
    summary: {
      sum: 24700,
      count: 62,
    },
    bookingId: "fmbgbgjhsfvcv3h54pqb3rvb2hwx53cy",
  },
  {
    ticketEventId: "dfcbu7v3uma1ygd",
    ticketProductionId: "J6PEJDYYLXPLAMT",
    eventName: "Sissela Kyle - Min föreställning om mamma",
    eventStart: "2024-11-28T19:00:00+01:00",
    eventEnd: "2024-11-28T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 145145,
        count: 319,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 21840,
        count: 48,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 11375,
        count: 23,
        vat: "6%",
      },
    ],
    summary: {
      sum: 178360,
      count: 390,
    },
    bookingId: "645yrcvhbmqcyr2vo4r7avijlxmmtfdj",
  },
  {
    ticketEventId: "2ebl19j6mlzekuy",
    ticketProductionId: "J6PEJDYYLXPLAMT",
    eventName: "Sissela Kyle - Min föreställning om mamma",
    eventStart: "2024-11-30T19:00:00+01:00",
    eventEnd: "2024-11-30T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 20020,
        count: 44,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 150150,
        count: 330,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 4095,
        count: 9,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, skymd sikt",
        cost: 455,
        sum: 910,
        count: 2,
        vat: "6%",
      },
    ],
    summary: {
      sum: 175175,
      count: 385,
    },
    bookingId: "7n5eh7d5lukzxd4z62mf",
  },
  {
    ticketEventId: "gnndrvr6kfc2w2h",
    ticketProductionId: "J6PEJDYYLXPLAMT",
    eventName: "Sissela Kyle - Min föreställning om mamma",
    eventStart: "2024-11-29T19:00:00+01:00",
    eventEnd: "2024-11-29T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 21840,
        count: 48,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 148785,
        count: 327,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 5005,
        count: 11,
        vat: "6%",
      },
    ],
    summary: {
      sum: 175630,
      count: 386,
    },
    bookingId: "5267takucm72lpqxpsmsslaax6e6ubzt",
  },
  {
    ticketEventId: "ucj84a44nlvbk0n",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-11T14:00:00+01:00",
    eventEnd: "2025-01-11T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 29250,
        count: 65,
        vat: "6%",
      },
      {
        type: "Parkett barn",
        cost: 350,
        sum: 3150,
        count: 9,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 15050,
        count: 43,
        vat: "6%",
      },
    ],
    summary: {
      sum: 47450,
      count: 117,
    },
    bookingId: "37ajhbtj4r2ffceuv2kbhsa4yqfc6e4z",
  },
  {
    ticketEventId: "tw7avvlx0dnf9ta",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-24T19:00:00+01:00",
    eventEnd: "2025-01-24T22:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 4500,
        count: 10,
        vat: "6%",
      },
      {
        type: "Parkett barn",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
    ],
    summary: {
      sum: 5900,
      count: 14,
    },
    bookingId: "3b3pcmtvxnwm6ydby2sgyxh4eeqbdrvx",
  },
  {
    ticketEventId: "vhcuyxhp2yxtdr0",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-10T19:00:00+01:00",
    eventEnd: "2025-01-10T22:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 9450,
        count: 21,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
    ],
    summary: {
      sum: 10850,
      count: 25,
    },
    bookingId: "35cjfs6uvk2hcyb2uuf5k2ddyz4wwnib",
  },
  {
    ticketEventId: "cf5waz3cbnhec3n",
    ticketProductionId: "",
    eventName: "Stol på Maxim",
    eventStart: "",
    eventEnd: "",
    currency: "SEK",
    tickets: [],
    summary: {
      sum: 0,
      count: 0,
    },
    bookingId: "7stvuthqnfdfvnrloq24sqji5wwwgy7s",
  },
  {
    ticketEventId: "k69wh3mk3tlhjev",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-09-01T18:00:00+02:00",
    eventEnd: "2025-09-01T18:00:00+02:00",
    currency: "SEK",
    tickets: [],
    summary: {
      sum: 0,
      count: 0,
    },
  },
  {
    ticketEventId: "x7lbder12udp25t",
    ticketProductionId: "X8KWH76UP0VN0J8",
    eventName: "Stol på Maxim",
    eventStart: "2024-09-01T20:00:00+02:00",
    eventEnd: "2029-09-01T21:30:00+02:00",
    currency: "SEK",
    tickets: [],
    summary: {
      sum: 0,
      count: 0,
    },
    bookingId: "26vh7vq4szio6jmsghq4kilevyo4gyzb",
  },
  {
    ticketEventId: "2p9dv56t2jurrng",
    ticketProductionId: "BHDFN1AZ2HTLTJ2",
    eventName: "Felipe Esparza:    At My Leisure World Tour",
    eventStart: "2024-12-05T19:30:00+01:00",
    eventEnd: "2024-12-05T21:00:00+01:00",
    currency: "SEK",
    tickets: [],
    summary: {
      sum: 0,
      count: 0,
    },
    bookingId: "2e7fife5tqiu2budojwmmbdai4l47h7h",
  },
  {
    ticketEventId: "yxdfmttu5phwnx6",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-09-26T18:00:00+02:00",
    eventEnd: "2024-09-26T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie",
        cost: 395,
        sum: 112101,
        count: 286,
        vat: "6%",
      },
    ],
    summary: {
      sum: 112101,
      count: 286,
    },
    bookingId: "2pc5xgkzmxg6ahs6wfib",
  },
  {
    ticketEventId: "vdjy8x4a7u09xzw",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-17T19:00:00+01:00",
    eventEnd: "2025-01-17T22:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 9900,
        count: 22,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 1750,
        count: 5,
        vat: "6%",
      },
    ],
    summary: {
      sum: 11650,
      count: 27,
    },
    bookingId: "2tfxq33hasbv4oifrkkc6qj5vfz74omj",
  },
  {
    ticketEventId: "l82xfc4tadux9gl",
    ticketProductionId: "5DVZD9WDGWJVJX5",
    eventName: "Steve Hofstetter: Kill the Butterflies.",
    eventStart: "2024-10-07T20:00:00+02:00",
    eventEnd: "2024-10-07T22:00:00+02:00",
    currency: "SEK",
    tickets: [],
    summary: {
      sum: 0,
      count: 0,
    },
    bookingId: "bwju7cfwxhfrnhfzclnnqozumj4d54ku",
  },
  {
    ticketEventId: "uakmncx7huzangu",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-25T14:00:00+01:00",
    eventEnd: "2025-01-25T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 15300,
        count: 34,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 10150,
        count: 29,
        vat: "6%",
      },
    ],
    summary: {
      sum: 25450,
      count: 63,
    },
    bookingId: "7mjndjcygycdtjfq63sf",
  },
  {
    ticketEventId: "1jldny91rlphkc1",
    ticketProductionId: "64HZYFC8YM6D1B3",
    eventName: "Sarah Klang.",
    eventStart: "2024-09-20T19:00:00+02:00",
    eventEnd: "2024-09-20T21:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 695,
        sum: 207805,
        count: 299,
        vat: "6%",
      },
      {
        type: "Balkongen",
        cost: 0,
        sum: 0,
        count: 53,
        vat: "6%",
      },
    ],
    summary: {
      sum: 207805,
      count: 352,
    },
    bookingId: "55pwdt6hbhdl27v6pscy",
  },
  {
    ticketEventId: "z6kpl4z1wfy77gd",
    ticketProductionId: "J6PEJDYYLXPLAMT",
    eventName: "Sissela Kyle - Min föreställning om mamma",
    eventStart: "2024-11-30T16:00:00+01:00",
    eventEnd: "2024-11-30T17:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 143325,
        count: 315,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 21840,
        count: 48,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 8645,
        count: 19,
        vat: "6%",
      },
    ],
    summary: {
      sum: 173810,
      count: 382,
    },
    bookingId: "5d3lmfabsu77a2o4k6c7tdsgola37tws",
  },
  {
    ticketEventId: "hbrve4lec0jnm80",
    ticketProductionId: "65NU26WFY3EEVAH",
    eventName: "Dokumentären",
    eventStart: "2024-11-15T19:00:00+01:00",
    eventEnd: "2024-11-15T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 395,
        sum: 36735,
        count: 93,
        vat: "6%",
      },
      {
        type: "Rullstolsplats",
        cost: 395,
        sum: 395,
        count: 1,
        vat: "6%",
      },
    ],
    summary: {
      sum: 37130,
      count: 94,
    },
    bookingId: "crcur7rzkaldzcwtszbf4fsf5m4wu4lh",
  },
  {
    ticketEventId: "kh7bxm0w8agwfzv",
    ticketProductionId: "J6PEJDYYLXPLAMT",
    eventName: "Sissela Kyle - Min föreställning om mamma",
    eventStart: "2024-12-12T17:00:00+01:00",
    eventEnd: "2024-12-12T18:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 165165,
        count: 363,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 21840,
        count: 48,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 9100,
        count: 20,
        vat: "6%",
      },
    ],
    summary: {
      sum: 196105,
      count: 431,
    },
    bookingId: "aoyp4f2t5pqad5v2uxyuerqrl433pdlx",
  },
  {
    ticketEventId: "pvv75mhrn61xy9x",
    ticketProductionId: "RD2HXB12KE2UJGG",
    eventName: "Carminho",
    eventStart: "2024-11-07T19:00:00+01:00",
    eventEnd: "2024-11-07T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 65975,
        count: 145,
        vat: "6%",
      },
    ],
    summary: {
      sum: 65975,
      count: 145,
    },
    bookingId: "3do7ec43keu2cazil37oraa5qkc6pym2",
  },
  {
    ticketEventId: "5d8k3fvrve6tj68",
    ticketProductionId: "J6PEJDYYLXPLAMT",
    eventName: "Sissela Kyle - Min föreställning om mamma",
    eventStart: "2024-12-08T16:00:00+01:00",
    eventEnd: "2024-12-08T17:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 455,
        sum: 147420,
        count: 324,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 455,
        sum: 22750,
        count: 50,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd",
        cost: 455,
        sum: 9100,
        count: 20,
        vat: "6%",
      },
    ],
    summary: {
      sum: 179270,
      count: 394,
    },
    bookingId: "2hctv72w2jmllbq2h36b2qtnwcwiavma",
  },
  {
    ticketEventId: "k4kj33rxwezwcek",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-18T14:00:00+01:00",
    eventEnd: "2025-01-18T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 20700,
        count: 46,
        vat: "6%",
      },
      {
        type: "Parkett barn",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 12250,
        count: 35,
        vat: "6%",
      },
    ],
    summary: {
      sum: 33650,
      count: 83,
    },
    bookingId: "4d5kk5cz2flc4jqnqearh2lbhixzbhvv",
  },
  {
    ticketEventId: "8xbv5368zdz5pv5",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-06T14:00:00+01:00",
    eventEnd: "2025-01-06T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 3850,
        count: 11,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 23850,
        count: 53,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 15050,
        count: 43,
        vat: "6%",
      },
    ],
    summary: {
      sum: 42750,
      count: 107,
    },
    bookingId: "4a6ko5ffg6m3hj756hmnvav22fqvr67n",
  },
  {
    ticketEventId: "e0m5a7jc7l767ej",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-19T14:00:00+01:00",
    eventEnd: "2025-01-19T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 1050,
        count: 3,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 2250,
        count: 5,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 1050,
        count: 3,
        vat: "6%",
      },
    ],
    summary: {
      sum: 4350,
      count: 11,
    },
    bookingId: "3rqgc3zqedyvwqmumrwtmgas7j2scyab",
  },
  {
    ticketEventId: "jhm6ylp6u57dcd8",
    ticketProductionId: "3VHT94KFV1EA75P",
    eventName: "Little Jinder",
    eventStart: "2024-11-09T20:00:00+01:00",
    eventEnd: "2024-11-09T21:15:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett, sittande",
        cost: 375,
        sum: 61875,
        count: 165,
        vat: "6%",
      },
      {
        type: "Parkett, stående",
        cost: 375,
        sum: 59625,
        count: 159,
        vat: "6%",
      },
      {
        type: "Rullstolsplats",
        cost: 375,
        sum: 375,
        count: 1,
        vat: "6%",
      },
    ],
    summary: {
      sum: 121875,
      count: 325,
    },
    bookingId: "fodhml6cms7gygztwnie75yohvtsgc5h",
  },
  {
    ticketEventId: "7n5mb002fuzzud5",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-31T19:00:00+01:00",
    eventEnd: "2025-01-31T22:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 1800,
        count: 4,
        vat: "6%",
      },
    ],
    summary: {
      sum: 1800,
      count: 4,
    },
    bookingId: "5cbmqy6nobtosy6scpsw",
  },
  {
    ticketEventId: "3xx64d7e14uye95",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-01-26T14:00:00+01:00",
    eventEnd: "2025-01-26T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 350,
        count: 1,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 3150,
        count: 7,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 2450,
        count: 7,
        vat: "6%",
      },
    ],
    summary: {
      sum: 5950,
      count: 15,
    },
    bookingId: "6ggywviqxsmf3yu7qppao5f7l5isadg5",
  },
  {
    ticketEventId: "xgmffvc9fvce6rv",
    ticketProductionId: "AWNDRJ0K4KKG7XM",
    eventName: "Sogand",
    eventStart: "2024-11-01T20:00:00+01:00",
    eventEnd: "2024-11-01T23:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett",
        cost: 1499,
        sum: 251430,
        count: 270,
        vat: "6%",
      },
      {
        type: "Balkong",
        cost: 599,
        sum: 27164,
        count: 36,
        vat: "6%",
      },
      {
        type: "Rullstolsplats",
        cost: 599,
        sum: 599,
        count: 1,
        vat: "6%",
      },
    ],
    summary: {
      sum: 279193,
      count: 307,
    },
    bookingId: "mh7forl7x3yrcb4pazgkbiiqjmnmvmbn",
  },
  {
    ticketEventId: "2101mvkx256k7vb",
    ticketProductionId: "65NU26WFY3EEVAH",
    eventName: "Dokumentären",
    eventStart: "2024-11-13T19:00:00+01:00",
    eventEnd: "2024-11-13T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 395,
        sum: 9875,
        count: 25,
        vat: "6%",
      },
    ],
    summary: {
      sum: 9875,
      count: 25,
    },
    bookingId: "45yb3iwd2ds67jq2n5vcvunsdhtnv6bx",
  },
  {
    ticketEventId: "9p7d1jgyykja9ly",
    ticketProductionId: "65NU26WFY3EEVAH",
    eventName: "Dokumentären",
    eventStart: "2024-11-14T19:00:00+01:00",
    eventEnd: "2024-11-14T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 395,
        sum: 24490,
        count: 60,
        vat: "6%",
      },
      {
        type: "Rullstolsplats",
        cost: 395,
        sum: 395,
        count: 1,
        vat: "6%",
      },
    ],
    summary: {
      sum: 24885,
      count: 61,
    },
    bookingId: "cx7hcv3nrasmppis6jo7b2q4r7n6hpql",
  },
  {
    ticketEventId: "bceja81bx45ag74",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-02-02T14:00:00+01:00",
    eventEnd: "2025-02-02T17:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 5850,
        count: 13,
        vat: "6%",
      },
      {
        type: "Parkett barn (6-18 år)",
        cost: 350,
        sum: 1050,
        count: 3,
        vat: "6%",
      },
    ],
    summary: {
      sum: 6900,
      count: 16,
    },
    bookingId: "27ntl7hqw7xynt6ywhharkmjzsvfz5hs",
  },
  {
    ticketEventId: "7fc2z58v2hp3nay",
    ticketProductionId: "YRNJKJB7TRM0Y4G",
    eventName: "Helen Sjöholm och Anna Stadling - Sånger i juletid",
    eventStart: "2024-12-01T16:00:00+01:00",
    eventEnd: "2024-12-01T17:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 760,
        sum: 215080,
        count: 283,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 790,
        sum: 22910,
        count: 29,
        vat: "6%",
      },
    ],
    summary: {
      sum: 237990,
      count: 312,
    },
    bookingId: "cemxcch3gsum7a4jdrhlw5dpwcfxzegt",
  },
  {
    ticketEventId: "jrn8pcfjg369c2k",
    ticketProductionId: "WNHBPT84GXHHF78",
    eventName: "Opera från The Met - Hoffmans äventyr",
    eventStart: "2024-10-06T15:00:00+02:00",
    eventEnd: "2024-10-06T19:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 11550,
        count: 33,
        vat: "6%",
      },
      {
        type: "Under 25 år, parkett",
        cost: 250,
        sum: 1000,
        count: 4,
        vat: "6%",
      },
    ],
    summary: {
      sum: 12550,
      count: 37,
    },
  },
  {
    ticketEventId: "tue1yzrta7jd5wx",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-09-29T17:00:00+02:00",
    eventEnd: "2024-09-29T18:29:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 210,
        sum: 11760,
        count: 56,
        vat: "6%",
      },
    ],
    summary: {
      sum: 11760,
      count: 56,
    },
    bookingId: "n3e5q33tx7mcr6vqshodksngrwgoiben",
  },
  {
    ticketEventId: "yyxh0k1fjdxmvcn",
    ticketProductionId: "3G3Y763V26ZHBF8",
    eventName: "Opera från The Met - Tosca",
    eventStart: "2024-11-23T19:00:00+01:00",
    eventEnd: "2024-11-23T22:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 15050,
        count: 43,
        vat: "6%",
      },
    ],
    summary: {
      sum: 15050,
      count: 43,
    },
  },
  {
    ticketEventId: "xc0mm9d87bj2tad",
    ticketProductionId: "MKU591A45NKV3NR",
    eventName: "Opera från The Met  - Grounded",
    eventStart: "2024-10-22T18:00:00+02:00",
    eventEnd: "2024-10-22T22:00:00+02:00",
    currency: "SEK",
    tickets: [],
    summary: {
      sum: 0,
      count: 0,
    },
  },
  {
    ticketEventId: "47zyf6hh82p7m5z",
    ticketProductionId: "ZAG5BCP1UYHRT11",
    eventName: "Opera från The Met - Aida",
    eventStart: "2025-01-28T18:00:00+01:00",
    eventEnd: "2025-01-28T21:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 10850,
        count: 31,
        vat: "6%",
      },
    ],
    summary: {
      sum: 10850,
      count: 31,
    },
  },
  {
    ticketEventId: "macgtc356xgvdpg",
    ticketProductionId: "KZ7WYYHDRH54VUR",
    eventName: "Opera från The Met - Figaros bröllop",
    eventStart: "2025-04-27T15:00:00+02:00",
    eventEnd: "2025-04-27T19:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 2800,
        count: 8,
        vat: "6%",
      },
    ],
    summary: {
      sum: 2800,
      count: 8,
    },
  },
  {
    ticketEventId: "eldeb581nfu523w",
    ticketProductionId: "YMB6DANH8U5PR70",
    eventName: "Opera från The Met - Salome",
    eventStart: "2025-05-17T19:00:00+02:00",
    eventEnd: "2025-05-17T21:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 350,
        count: 1,
        vat: "6%",
      },
    ],
    summary: {
      sum: 350,
      count: 1,
    },
  },
  {
    ticketEventId: "bh6n1ufa2fbytdl",
    ticketProductionId: "GHD0ZHKBRR21GA1",
    eventName: "Jordan Jensen",
    eventStart: "2025-03-14T19:30:00+01:00",
    eventEnd: "2025-03-14T21:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie balkong",
        cost: 365,
        sum: 2190,
        count: 6,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett",
        cost: 365,
        sum: 30295,
        count: 83,
        vat: "6%",
      },
    ],
    summary: {
      sum: 32485,
      count: 89,
    },
  },
  {
    ticketEventId: "1g8yg426yu572ax",
    ticketProductionId: "UAZF2J071G4G06E",
    eventName: "Amanda Bergman",
    eventStart: "2024-12-14T19:00:00+01:00",
    eventEnd: "2024-12-14T21:15:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie balkong",
        cost: 395,
        sum: 21725,
        count: 55,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett",
        cost: 395,
        sum: 73470,
        count: 186,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd, balkong",
        cost: 395,
        sum: 5925,
        count: 15,
        vat: "6%",
      },
      {
        type: "Stående parkett",
        cost: 395,
        sum: 90850,
        count: 230,
        vat: "6%",
      },
      {
        type: "Dold sikt, parkett",
        cost: 395,
        sum: 1975,
        count: 5,
        vat: "6%",
      },
      {
        type: "Rullstolsplats",
        cost: 395,
        sum: 395,
        count: 1,
        vat: "6%",
      },
    ],
    summary: {
      sum: 194340,
      count: 492,
    },
    bookingId: "3mrnmcvcyto2fgxskcgiffc73hgjqswd",
  },
  {
    ticketEventId: "ahp82um6vk22fwh",
    ticketProductionId: "1J11UJHNMTA0GRN",
    eventName: "TENSTA GOSPEL CHOIR – THE CHRISTMAS GOSPEL",
    eventStart: "2024-12-07T19:30:00+01:00",
    eventEnd: "2024-12-07T21:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 550,
        sum: 116050,
        count: 211,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 550,
        sum: 28600,
        count: 52,
        vat: "6%",
      },
      {
        type: "Parkett, stående",
        cost: 495,
        sum: 18315,
        count: 37,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, dold sikt",
        cost: 550,
        sum: 2200,
        count: 4,
        vat: "6%",
      },
      {
        type: "Ordinarie barstol med ryggstöd",
        cost: 550,
        sum: 10450,
        count: 19,
        vat: "6%",
      },
    ],
    summary: {
      sum: 175615,
      count: 323,
    },
    bookingId: "45wj3eghugn5hiige7gjlp2esorqvdcm",
  },
  {
    ticketEventId: "nllh0m451ltl3xk",
    ticketProductionId: "UAZF2J071G4G06E",
    eventName: "Amanda Bergman",
    eventStart: "2024-12-13T19:00:00+01:00",
    eventEnd: "2024-12-13T20:40:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie balkong",
        cost: 395,
        sum: 17380,
        count: 44,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett",
        cost: 395,
        sum: 76235,
        count: 193,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd, balkong",
        cost: 395,
        sum: 7900,
        count: 20,
        vat: "6%",
      },
      {
        type: "Stående parkett",
        cost: 395,
        sum: 16195,
        count: 41,
        vat: "6%",
      },
    ],
    summary: {
      sum: 117710,
      count: 298,
    },
    bookingId: "77hsjow2khqb32s6vvi7tt2uc35cdxe2",
  },
  {
    ticketEventId: "6hn1x7z10m2r9mj",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-10-10T20:00:00+02:00",
    eventEnd: "2024-10-10T23:59:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Biljett",
        cost: 0,
        sum: 0,
        count: 12,
        vat: "6%",
      },
    ],
    summary: {
      sum: 0,
      count: 12,
    },
  },
  {
    ticketEventId: "1r3k0h6dl0n82v0",
    ticketProductionId: "N03V4JNRT39BRHT",
    eventName: "Nils Frahm",
    eventStart: "2024-10-11T20:00:00+02:00",
    eventEnd: "2024-10-11T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 495,
        sum: 177210,
        count: 358,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong",
        cost: 495,
        sum: 27720,
        count: 56,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd, balkong",
        cost: 495,
        sum: 9900,
        count: 20,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, skymd sikt",
        cost: 495,
        sum: 2475,
        count: 5,
        vat: "6%",
      },
      {
        type: "Ordinarie balkong, skymd sikt",
        cost: 495,
        sum: 8910,
        count: 18,
        vat: "6%",
      },
    ],
    summary: {
      sum: 226215,
      count: 457,
    },
    bookingId: "2wmws2bl4xsxkcwpf7c5c4woyelc72ur",
  },
  {
    ticketEventId: "pfaht82f81gw3d7",
    ticketProductionId: "AC0680HX8WZ17EB",
    eventName: "Sunnan",
    eventStart: "2025-02-22T20:00:00+01:00",
    eventEnd: "2025-02-22T21:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie balkong",
        cost: 295,
        sum: 14455,
        count: 49,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett",
        cost: 295,
        sum: 46905,
        count: 159,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, skymd sikt",
        cost: 295,
        sum: 295,
        count: 1,
        vat: "6%",
      },
      {
        type: "Ståplats parkett",
        cost: 295,
        sum: 6490,
        count: 22,
        vat: "6%",
      },
      {
        type: "Barstol med ryggstöd, balkong",
        cost: 295,
        sum: 4720,
        count: 16,
        vat: "6%",
      },
    ],
    summary: {
      sum: 72865,
      count: 247,
    },
    bookingId: "4gtxgbw3yi3q5p7efyvdpjw55btnkkzj",
  },
  {
    ticketEventId: "gr3f468neuh968u",
    ticketProductionId: "7MHMNRMEHP456JV",
    eventName: "Opera från The Met - Barberaren i Sevilla",
    eventStart: "2025-06-03T18:00:00+02:00",
    eventEnd: "2025-06-03T21:30:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
    ],
    summary: {
      sum: 700,
      count: 2,
    },
  },
  {
    ticketEventId: "8eptheu07cwgfdh",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-02-16T14:00:00+01:00",
    eventEnd: "2025-04-11T23:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 4550,
        count: 13,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 8100,
        count: 18,
        vat: "6%",
      },
    ],
    summary: {
      sum: 12650,
      count: 31,
    },
    bookingId: "7xvbszqrwuamjeo6ngcrbum2vcrmjkb4",
  },
  {
    ticketEventId: "4mk10hnul2695xb",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-11-05T19:00:00+01:00",
    eventEnd: "2024-11-06T00:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett",
        cost: 150,
        sum: 50440,
        count: 281,
        vat: "6%",
      },
      {
        type: "Balkong",
        cost: 120,
        sum: 8360,
        count: 48,
        vat: "6%",
      },
    ],
    summary: {
      sum: 58800,
      count: 329,
    },
  },
  {
    ticketEventId: "ewwg2bfwgmy6a5w",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-10-25T18:00:00+02:00",
    eventEnd: "2024-10-25T21:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Cinemafricas filmfestival",
        cost: 200,
        sum: 17400,
        count: 87,
        vat: "6%",
      },
      {
        type: "Rullstolsplats",
        cost: 200,
        sum: 200,
        count: 1,
        vat: "6%",
      },
      {
        type: "Pensionär/student",
        cost: 125,
        sum: 3250,
        count: 26,
        vat: "6%",
      },
    ],
    summary: {
      sum: 20850,
      count: 114,
    },
  },
  {
    ticketEventId: "zytx4zuzyxyjpxt",
    ticketProductionId: "7TJ6BDCH78HCU07",
    eventName: "Extas",
    eventStart: "2024-12-03T19:00:00+01:00",
    eventEnd: "2024-12-03T21:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 395,
        sum: 9480,
        count: 24,
        vat: "6%",
      },
    ],
    summary: {
      sum: 9480,
      count: 24,
    },
    bookingId: "ofkmgcgeuencoihs4nsk37kxtgo3qpmz",
  },
  {
    ticketEventId: "mnfj2fb674cm7mt",
    ticketProductionId: "XYB5EL5B80TE8MK",
    eventName: "TMRW/TDAY @ MAXIM, ADAM TAAL LIVE",
    eventStart: "2024-12-11T19:00:00+01:00",
    eventEnd: "2024-12-11T23:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett, sittande",
        cost: 300,
        sum: 8400,
        count: 28,
        vat: "6%",
      },
      {
        type: "Parkett, stående",
        cost: 300,
        sum: 1800,
        count: 6,
        vat: "6%",
      },
    ],
    summary: {
      sum: 10200,
      count: 34,
    },
  },
  {
    ticketEventId: "k1gvty1xtl884km",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-02-07T19:00:00+01:00",
    eventEnd: "2025-04-03T04:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 700,
        count: 2,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 1800,
        count: 4,
        vat: "6%",
      },
    ],
    summary: {
      sum: 2500,
      count: 6,
    },
    bookingId: "vw5vpi6gb2g4myrtk7bonk44jvlteihr",
  },
  {
    ticketEventId: "7gj7rtf3f3d5rza",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-10-30T16:00:00+01:00",
    eventEnd: "2024-10-30T18:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Spökdisco & film på Maxim",
        cost: 150,
        sum: 8550,
        count: 57,
        vat: "6%",
      },
    ],
    summary: {
      sum: 8550,
      count: 57,
    },
  },
  {
    ticketEventId: "3x6tfac8894u0a2",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-11-16T20:00:00+01:00",
    eventEnd: "2024-11-16T21:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 480,
        sum: 1440,
        count: 3,
        vat: "6%",
      },
    ],
    summary: {
      sum: 1440,
      count: 3,
    },
  },
  {
    ticketEventId: "a6hr4txagcyb523",
    ticketProductionId: "7TJ6BDCH78HCU07",
    eventName: "Extas",
    eventStart: "2024-12-02T19:00:00+01:00",
    eventEnd: "2024-12-02T21:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 395,
        sum: 4345,
        count: 11,
        vat: "6%",
      },
    ],
    summary: {
      sum: 4345,
      count: 11,
    },
    bookingId: "cakcidxvlrzeetrutq5rmufjkmcbjrcb",
  },
  {
    ticketEventId: "8g43erp0kdw2cpe",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-11-06T19:00:00+01:00",
    eventEnd: "2024-11-06T21:00:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett",
        cost: 150,
        sum: 8850,
        count: 59,
        vat: "6%",
      },
      {
        type: "Balkong",
        cost: 150,
        sum: 2850,
        count: 19,
        vat: "6%",
      },
    ],
    summary: {
      sum: 11700,
      count: 78,
    },
  },
  {
    ticketEventId: "kx69rfjg2pvr1vd",
    ticketProductionId: "",
    eventName: "",
    eventStart: "2024-12-20T19:00:00+01:00",
    eventEnd: "2024-12-20T21:50:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Pensionär",
        cost: 250,
        sum: 500,
        count: 2,
        vat: "6%",
      },
      {
        type: "Upp till 25 år",
        cost: 250,
        sum: 1000,
        count: 4,
        vat: "6%",
      },
      {
        type: "Parkett ordinarie",
        cost: 350,
        sum: 8750,
        count: 25,
        vat: "6%",
      },
    ],
    summary: {
      sum: 10250,
      count: 31,
    },
  },
  {
    ticketEventId: "gv6nuvf0wke5m93",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-02-08T14:00:00+01:00",
    eventEnd: "2025-04-03T23:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 1400,
        count: 4,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 1800,
        count: 4,
        vat: "6%",
      },
    ],
    summary: {
      sum: 3200,
      count: 8,
    },
    bookingId: "2ew7qgf6obsamhek4qe6b6t5y2eaugci",
  },
  {
    ticketEventId: "fw9dhy4ennvttck",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-02-15T14:00:00+01:00",
    eventEnd: "2025-04-10T23:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett barn",
        cost: 350,
        sum: 350,
        count: 1,
        vat: "6%",
      },
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 1350,
        count: 3,
        vat: "6%",
      },
    ],
    summary: {
      sum: 1700,
      count: 4,
    },
    bookingId: "rrwavabnlm6ekwd4tl7uwbykriu3dqqc",
  },
  {
    ticketEventId: "wdhhucf6p22pt4d",
    ticketProductionId: "21PAKW1DKEXV9H6",
    eventName: "Club Killers",
    eventStart: "2024-12-27T20:30:00+01:00",
    eventEnd: "2024-12-27T22:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett, stående",
        cost: 270,
        sum: 1080,
        count: 4,
        vat: "6%",
      },
      {
        type: "Ordinarie parkett, sittande",
        cost: 270,
        sum: 1620,
        count: 6,
        vat: "6%",
      },
    ],
    summary: {
      sum: 2700,
      count: 10,
    },
  },
  {
    ticketEventId: "mc9cwmc24yeuayp",
    ticketProductionId: "6B882RX3EVMB6ZN",
    eventName: "Vad Blir Det För Mord? - Live!",
    eventStart: "2025-03-07T19:30:00+01:00",
    eventEnd: "2025-03-07T22:20:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett ordinarie",
        cost: 295,
        sum: 106900,
        count: 332,
        vat: "6%",
      },
      {
        type: "Balkong ordinarie",
        cost: 365,
        sum: 34310,
        count: 94,
        vat: "6%",
      },
    ],
    summary: {
      sum: 141210,
      count: 426,
    },
  },
  {
    ticketEventId: "jdbh6kdrlvk1wa1",
    ticketProductionId: "2CGX3ZDDE6YD42N",
    eventName: "Shrek The Musical",
    eventStart: "2025-02-09T14:00:00+01:00",
    eventEnd: "2025-04-04T23:00:00+02:00",
    currency: "SEK",
    tickets: [
      {
        type: "Parkett vuxen",
        cost: 450,
        sum: 2700,
        count: 6,
        vat: "6%",
      },
      {
        type: "Parkett barn",
        cost: 350,
        sum: 1750,
        count: 5,
        vat: "6%",
      },
    ],
    summary: {
      sum: 4450,
      count: 11,
    },
    bookingId: "kt575bb3gwc6f4y2btskgcgcf3jl7w4v",
  },
  {
    ticketEventId: "9pf2b4at76le39r",
    ticketProductionId: "YRNJKJB7TRM0Y4G",
    eventName: "Helen Sjöholm och Anna Stadling - Sånger i juletid",
    eventStart: "2024-12-01T19:00:00+01:00",
    eventEnd: "2024-12-01T20:30:00+01:00",
    currency: "SEK",
    tickets: [
      {
        type: "Ordinarie parkett",
        cost: 760,
        sum: 3040,
        count: 4,
        vat: "6%",
      },
    ],
    summary: {
      sum: 3040,
      count: 4,
    },
    bookingId: "7sx7h2a2t3emukrzlat7uopecjv76tea",
  },
];

// const testData = [
//     {
//       purchase: {
//         crmId: 9307742,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "3JX50MZ2A0",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-29T21:45:15.773Z",
//         lastupdatedUtc: "2024-10-29T21:50:37.713Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UVARKTNW",
//         firstname: "Ali",
//         lastname: "Behroozi",
//         postalAddressLineOne: "Tranebergsgatan 24",
//         postalAddressLineTwo: "",
//         zipcode: "16744",
//         city: "Stockholm",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46766085395",
//         email: "behrooziali28@gmail.com",
//         acceptInfo: true,
//         idNo: "990907-8512",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "xgmffvc9fvce6rv",
//             name: "Sogand",
//             start: "2024-11-01T20:00:00+01:00",
//             end: "2024-11-01T23:00:00+01:00",
//             lastUpdated: "2024-10-08T16:45:25.347+02:00",
//             productionId: "AWNDRJ0K4KKG7XM",
//             productionName: "Sogand",
//             hasTableReservation: false,
//             tags: ["sogand"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-22Z-0-81132887",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-22Z",
//             priceIncVatAfterDiscount: "799",
//             vatPortion: "45.2264",
//             vatPercent: "0.06",
//             eventId: "xgmffvc9fvce6rv",
//             section: "Parkett",
//             seat: "259",
//             row: "14",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-INSUR-0",
//             name: "Avbeställningsförsäkring via M",
//             receipttext: "Avbeställningsförsäkring via Mysafety",
//             type: "EventInsurance",
//             artno: "YBK1-INSUR",
//             priceIncVatAfterDiscount: "92",
//             vatPortion: "0",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9308196,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "YB3GEFNYB9",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T06:25:22.763Z",
//         lastupdatedUtc: "2024-10-30T06:27:32.36Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UNEBU75CR",
//         firstname: "Mia",
//         lastname: "Hussner",
//         postalAddressLineOne: "Svartviksslingan 83",
//         postalAddressLineTwo: "",
//         zipcode: "16738",
//         city: "Bromma",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46736199890",
//         email: "mia.hussner@hotmail.com",
//         acceptInfo: true,
//         idNo: "650510-7042",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "nllh0m451ltl3xk",
//             name: "Amanda Bergman",
//             start: "2024-12-13T19:00:00+01:00",
//             end: "2024-12-13T20:40:00+01:00",
//             lastUpdated: "2024-10-03T13:20:11.89+02:00",
//             productionId: "UAZF2J071G4G06E",
//             productionName: "Amanda Bergman",
//             hasTableReservation: false,
//             tags: ["Amanda Bergman"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-25L-0-81134445",
//             name: "Ordinarie parkett",
//             receipttext: "Ordinarie parkett",
//             type: "Ticket",
//             artno: "YBK1-25L",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "nllh0m451ltl3xk",
//             section: "Parkett",
//             seat: "167",
//             row: "9",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".sitt"],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-INSUR-0",
//             name: "Avbeställningsförsäkring via M",
//             receipttext: "Avbeställningsförsäkring via Mysafety",
//             type: "EventInsurance",
//             priceIncVatAfterDiscount: "65",
//             vatPortion: "0",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9308268,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "C2LPXP4XWH",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T06:25:48.54Z",
//         lastupdatedUtc: "2024-10-30T06:50:14.747Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UHVJHY0RX",
//         firstname: "Andreas",
//         lastname: "Kandell",
//         postalAddressLineOne: "Källvägen 14",
//         postalAddressLineTwo: "",
//         zipcode: "83751",
//         city: "Åre",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46768025666",
//         email: "a.kandell90@gmail.com",
//         acceptInfo: false,
//         idNo: "900616-0593",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "nllh0m451ltl3xk",
//             name: "Amanda Bergman",
//             start: "2024-12-13T19:00:00+01:00",
//             end: "2024-12-13T20:40:00+01:00",
//             lastUpdated: "2024-10-03T13:20:11.89+02:00",
//             productionId: "UAZF2J071G4G06E",
//             productionName: "Amanda Bergman",
//             hasTableReservation: false,
//             tags: ["Amanda Bergman"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-INSUR-0",
//             name: "Avbeställningsförsäkring via M",
//             receipttext: "Avbeställningsförsäkring via Mysafety",
//             type: "EventInsurance",
//             artno: "YBK1-INSUR",
//             priceIncVatAfterDiscount: "117",
//             vatPortion: "0",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-25M-0-81134734",
//             name: "Stående parkett",
//             receipttext: "Stående parkett",
//             type: "Ticket",
//             artno: "YBK1-25M",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "nllh0m451ltl3xk",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".stå"],
//           },
//           {
//             goodsid: "YBK1-25M-1-81134735",
//             name: "Stående parkett",
//             receipttext: "Stående parkett",
//             type: "Ticket",
//             artno: "YBK1-25M",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "nllh0m451ltl3xk",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".stå"],
//           },
//           {
//             goodsid: "YBK1-25M-2-81134736",
//             name: "Stående parkett",
//             receipttext: "Stående parkett",
//             type: "Ticket",
//             artno: "YBK1-25M",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "nllh0m451ltl3xk",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".stå"],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-1",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-2",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9308528,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "7DNT1HRR63",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T07:26:53.253Z",
//         lastupdatedUtc: "2024-10-30T07:29:24.963Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UD8M5U52",
//         firstname: "Sandra",
//         lastname: "Sondell",
//         postalAddressLineOne: "Stövargatan 67",
//         postalAddressLineTwo: "",
//         zipcode: "12461",
//         city: "Bandhagen",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46700920978",
//         email: "sandrasondell@hotmail.com",
//         acceptInfo: false,
//         idNo: "930307",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "5ldxt8t9dplntd5",
//             name: "PetrINA",
//             start: "2024-11-21T19:00:00+01:00",
//             end: "2024-11-21T20:30:00+01:00",
//             lastUpdated: "2024-04-03T11:52:44.377+02:00",
//             productionId: "FH9M23DBGPPR9UJ",
//             productionName: "PetrINA",
//             hasTableReservation: false,
//             tags: ["Maxim"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-1YX-0-81135906",
//             name: "Ordinarie",
//             receipttext: "Ordinarie",
//             type: "Ticket",
//             artno: "YBK1-1YX",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "5ldxt8t9dplntd5",
//             section: "Parkett",
//             seat: "285",
//             row: "16",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: ["Maxim", "Petrina Solange", "Ina Lundström", "petrina"],
//           },
//           {
//             goodsid: "YBK1-1YX-1-81135907",
//             name: "Ordinarie",
//             receipttext: "Ordinarie",
//             type: "Ticket",
//             artno: "YBK1-1YX",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "5ldxt8t9dplntd5",
//             section: "Parkett",
//             seat: "286",
//             row: "16",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: ["Maxim", "Petrina Solange", "Ina Lundström", "petrina"],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-1",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9308937,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "LNZ3HPYWKV",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T07:42:51.2Z",
//         lastupdatedUtc: "2024-10-30T08:16:42.863Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UGK9L4PA",
//         firstname: "Jessica",
//         lastname: "Blomgren ",
//         postalAddressLineOne: "Inedalsgatan 9",
//         postalAddressLineTwo: "",
//         zipcode: "11233",
//         city: "Stockholm",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46736854655",
//         email: "jessicablomgrenw@gmail.com",
//         acceptInfo: true,
//         idNo: "820304",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "7gj7rtf3f3d5rza",
//             name: "Spökdisco & film på Maxim",
//             start: "2024-10-30T16:00:00+01:00",
//             end: "2024-10-30T18:30:00+01:00",
//             lastUpdated: "2024-10-24T15:46:09.267+02:00",
//             productionId: "",
//             productionName: "",
//             hasTableReservation: false,
//             tags: [],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-288-0-81137437",
//             name: "Spökdisco & film på Maxim",
//             receipttext: "Spökdisco & film på Maxim",
//             type: "Ticket",
//             artno: "YBK1-288",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "8.4906",
//             vatPercent: "0.06",
//             eventId: "7gj7rtf3f3d5rza",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9309064,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "MDC7RPY5AY",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T08:26:52.363Z",
//         lastupdatedUtc: "2024-10-30T08:29:51.233Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UDLPBNX2J",
//         firstname: "Sofia ",
//         lastname: "Bellucci Feijen ",
//         postalAddressLineOne: "Furusundsgatan 10 ",
//         postalAddressLineTwo: "",
//         zipcode: "11537",
//         city: "Stockholm",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46735987637",
//         email: "bellucci.sofia@gmail.com",
//         acceptInfo: false,
//         idNo: "830806-0303",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "8g43erp0kdw2cpe",
//             name: "Filmpremiär: Det omätbara",
//             start: "2024-11-06T19:00:00+01:00",
//             end: "2024-11-06T21:00:00+01:00",
//             lastUpdated: "2024-10-24T10:29:25.86+02:00",
//             productionId: "",
//             productionName: "",
//             hasTableReservation: false,
//             tags: ["bio"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-28D-0-81137885",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-28D",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "30",
//             vatPercent: "0.25",
//             eventId: "8g43erp0kdw2cpe",
//             section: "Parkett",
//             seat: "101",
//             row: "6",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-28D-1-81137886",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-28D",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "30",
//             vatPercent: "0.25",
//             eventId: "8g43erp0kdw2cpe",
//             section: "Parkett",
//             seat: "105",
//             row: "6",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-28D-2-81137887",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-28D",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "30",
//             vatPercent: "0.25",
//             eventId: "8g43erp0kdw2cpe",
//             section: "Parkett",
//             seat: "104",
//             row: "6",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-28D-3-81137888",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-28D",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "30",
//             vatPercent: "0.25",
//             eventId: "8g43erp0kdw2cpe",
//             section: "Parkett",
//             seat: "103",
//             row: "6",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-28D-4-81137889",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-28D",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "30",
//             vatPercent: "0.25",
//             eventId: "8g43erp0kdw2cpe",
//             section: "Parkett",
//             seat: "102",
//             row: "6",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-1",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-2",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-3",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-4",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9309141,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "HW75X64ZHD",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T08:32:25.31Z",
//         lastupdatedUtc: "2024-10-30T08:36:44.603Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "U34LV91DT",
//         firstname: "Monika",
//         lastname: "Brannäs",
//         postalAddressLineOne: "",
//         postalAddressLineTwo: "",
//         zipcode: "11765",
//         city: "",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46704220112",
//         email: "monika.brannas@gmail.com",
//         acceptInfo: false,
//         idNo: null,
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "8g43erp0kdw2cpe",
//             name: "Filmpremiär: Det omätbara",
//             start: "2024-11-06T19:00:00+01:00",
//             end: "2024-11-06T21:00:00+01:00",
//             lastUpdated: "2024-10-24T10:29:25.86+02:00",
//             productionId: "",
//             productionName: "",
//             hasTableReservation: false,
//             tags: ["bio"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-28D-0-81138142",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-28D",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "30",
//             vatPercent: "0.25",
//             eventId: "8g43erp0kdw2cpe",
//             section: "Parkett",
//             seat: "41",
//             row: "3",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-28D-1-81138143",
//             name: "Parkett",
//             receipttext: "Parkett",
//             type: "Ticket",
//             artno: "YBK1-28D",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "30",
//             vatPercent: "0.25",
//             eventId: "8g43erp0kdw2cpe",
//             section: "Parkett",
//             seat: "42",
//             row: "3",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-1",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9309886,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "T584PF8Y7U",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T09:38:56.183Z",
//         lastupdatedUtc: "2024-10-30T09:39:55.32Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UT05X01L",
//         firstname: "Smulan",
//         lastname: "Härenstam",
//         postalAddressLineOne: "",
//         postalAddressLineTwo: "",
//         zipcode: null,
//         city: "",
//         countryCode: "XX",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46704808359",
//         email: "smulan@maximstockholm.se",
//         acceptInfo: false,
//         idNo: null,
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "37vhbpptgefhuvm",
//             name: "Frukostvisning",
//             start: "2024-11-19T08:00:00+01:00",
//             end: "2024-11-19T09:00:00+01:00",
//             lastUpdated: "2024-10-30T10:37:20.227+01:00",
//             productionId: "",
//             productionName: "",
//             hasTableReservation: false,
//             tags: [],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-296-0-81140886",
//             name: "Biljett",
//             receipttext: "Biljett",
//             type: "Ticket",
//             artno: "YBK1-296",
//             priceIncVatAfterDiscount: "0",
//             vatPortion: "0",
//             vatPercent: "0",
//             eventId: "37vhbpptgefhuvm",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Ingen avgift",
//             receipttext: "Ingen avgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "0",
//             vatPortion: "0",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9309888,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "WYAW74W0CJ",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T09:28:48.177Z",
//         lastupdatedUtc: "2024-10-30T09:39:58.623Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "U6HLA0XW",
//         firstname: "Sabina",
//         lastname: "Bernhardsson",
//         postalAddressLineOne: "Kungsholms strand 111",
//         postalAddressLineTwo: "",
//         zipcode: "11233",
//         city: "Stockholm",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46730341463",
//         email: "sabinabernhardsson@hotmail.com",
//         acceptInfo: false,
//         idNo: "900211-1764",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "7gj7rtf3f3d5rza",
//             name: "Spökdisco & film på Maxim",
//             start: "2024-10-30T16:00:00+01:00",
//             end: "2024-10-30T18:30:00+01:00",
//             lastUpdated: "2024-10-24T15:46:09.267+02:00",
//             productionId: "",
//             productionName: "",
//             hasTableReservation: false,
//             tags: [],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-288-0-81140890",
//             name: "Spökdisco & film på Maxim",
//             receipttext: "Spökdisco & film på Maxim",
//             type: "Ticket",
//             artno: "YBK1-288",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "8.4906",
//             vatPercent: "0.06",
//             eventId: "7gj7rtf3f3d5rza",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-INSUR-0",
//             name: "Avbeställningsförsäkring via M",
//             receipttext: "Avbeställningsförsäkring via Mysafety",
//             type: "EventInsurance",
//             artno: "YBK1-INSUR",
//             priceIncVatAfterDiscount: "28",
//             vatPortion: "0",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9310097,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "GPB4LV1ANB",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T09:46:04.253Z",
//         lastupdatedUtc: "2024-10-30T09:58:57.693Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UHFZDKE0",
//         firstname: "Anna",
//         lastname: "Marklund",
//         postalAddressLineOne: "slåttervägen 8",
//         postalAddressLineTwo: "",
//         zipcode: "12133",
//         city: "enskededalen ",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46709261804",
//         email: "annam_marklund@hotmail.com",
//         acceptInfo: false,
//         idNo: "780302-0283",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "1g8yg426yu572ax",
//             name: "Amanda Bergman",
//             start: "2024-12-14T19:00:00+01:00",
//             end: "2024-12-14T21:15:00+01:00",
//             lastUpdated: "2024-10-03T13:20:38.457+02:00",
//             productionId: "UAZF2J071G4G06E",
//             productionName: "Amanda Bergman",
//             hasTableReservation: false,
//             tags: ["Amanda Bergman"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-251-0-81141503",
//             name: "Stående parkett",
//             receipttext: "Stående parkett",
//             type: "Ticket",
//             artno: "YBK1-251",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "1g8yg426yu572ax",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".stå"],
//           },
//           {
//             goodsid: "YBK1-251-1-81141504",
//             name: "Stående parkett",
//             receipttext: "Stående parkett",
//             type: "Ticket",
//             artno: "YBK1-251",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "1g8yg426yu572ax",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".stå"],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-1",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9310135,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "TXDT0BLYBW",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T10:00:32.613Z",
//         lastupdatedUtc: "2024-10-30T11:12:05.2Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "U6HLA0XW",
//         firstname: "Sabina",
//         lastname: "Bernhardsson",
//         postalAddressLineOne: "Kungsholms strand 111",
//         postalAddressLineTwo: "",
//         zipcode: "11233",
//         city: "Stockholm",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46730341463",
//         email: "sabinabernhardsson@hotmail.com",
//         acceptInfo: false,
//         idNo: "900211-1764",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "7gj7rtf3f3d5rza",
//             name: "Spökdisco & film på Maxim",
//             start: "2024-10-30T16:00:00+01:00",
//             end: "2024-10-30T18:30:00+01:00",
//             lastUpdated: "2024-10-24T15:46:09.267+02:00",
//             productionId: "",
//             productionName: "",
//             hasTableReservation: false,
//             tags: [],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-288-0-81141613",
//             name: "Spökdisco & film på Maxim",
//             receipttext: "Spökdisco & film på Maxim",
//             type: "Ticket",
//             artno: "YBK1-288",
//             priceIncVatAfterDiscount: "150",
//             vatPortion: "8.4906",
//             vatPercent: "0.06",
//             eventId: "7gj7rtf3f3d5rza",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-INSUR-0",
//             name: "Avbeställningsförsäkring via M",
//             receipttext: "Avbeställningsförsäkring via Mysafety",
//             type: "EventInsurance",
//             artno: "YBK1-INSUR",
//             priceIncVatAfterDiscount: "28",
//             vatPortion: "0",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9310784,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "B9BU8U0WN1",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T10:53:47.46Z",
//         lastupdatedUtc: "2024-10-30T10:57:39.383Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UB48DA62",
//         firstname: "Christina",
//         lastname: "Preisler",
//         postalAddressLineOne: "Gyllenstiensgatan 5",
//         postalAddressLineTwo: "",
//         zipcode: "11526",
//         city: "Stockholm",
//         countryCode: "SE",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+46761231868",
//         email: "cpreislerschedin@gmail.com",
//         acceptInfo: false,
//         idNo: "700410",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "1g8yg426yu572ax",
//             name: "Amanda Bergman",
//             start: "2024-12-14T19:00:00+01:00",
//             end: "2024-12-14T21:15:00+01:00",
//             lastUpdated: "2024-10-03T13:20:38.457+02:00",
//             productionId: "UAZF2J071G4G06E",
//             productionName: "Amanda Bergman",
//             hasTableReservation: false,
//             tags: ["Amanda Bergman"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-24Z-0-81144018",
//             name: "Ordinarie balkong",
//             receipttext: "Ordinarie balkong",
//             type: "Ticket",
//             artno: "YBK1-24Z",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "1g8yg426yu572ax",
//             section: "Balkong",
//             seat: "79",
//             row: "7",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".sitt"],
//           },
//           {
//             goodsid: "YBK1-24Z-1-81144019",
//             name: "Ordinarie balkong",
//             receipttext: "Ordinarie balkong",
//             type: "Ticket",
//             artno: "YBK1-24Z",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "1g8yg426yu572ax",
//             section: "Balkong",
//             seat: "80",
//             row: "7",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".sitt"],
//           },
//           {
//             goodsid: "YBK1-24Z-2-81144020",
//             name: "Ordinarie balkong",
//             receipttext: "Ordinarie balkong",
//             type: "Ticket",
//             artno: "YBK1-24Z",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "1g8yg426yu572ax",
//             section: "Balkong",
//             seat: "81",
//             row: "7",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".sitt"],
//           },
//           {
//             goodsid: "YBK1-24Z-3-81144021",
//             name: "Ordinarie balkong",
//             receipttext: "Ordinarie balkong",
//             type: "Ticket",
//             artno: "YBK1-24Z",
//             priceIncVatAfterDiscount: "395",
//             vatPortion: "22.3585",
//             vatPercent: "0.06",
//             eventId: "1g8yg426yu572ax",
//             section: "Balkong",
//             seat: "82",
//             row: "7",
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [".sitt"],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-1",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-2",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-3",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//     {
//       purchase: {
//         crmId: 9310996,
//         eogRequestCode: "DCHMLTF6FCWGXMP",
//         status: "Completed",
//         bookingPaymentUrl: null,
//         purchaseRefno: "HJ9MVBUCKF",
//         parentPurchaseRefno: "",
//         createdUtc: "2024-10-30T11:07:45.643Z",
//         lastupdatedUtc: "2024-10-30T11:12:34.733Z",
//         currency: "SEK",
//         privacyrefno: "ATL77",
//         termsrefno: "ZHCDW",
//         channel: "Webshop",
//         userrefno: "UJG2CH7LZ",
//         firstname: "Thora",
//         lastname: "Bragstad",
//         postalAddressLineOne: "Fagerheimgata 9E",
//         postalAddressLineTwo: "",
//         zipcode: "0567",
//         city: "Oslo",
//         countryCode: "NO",
//         companyName: null,
//         isCompany: false,
//         mobilePhoneNo: "+4792899615",
//         email: "thorabragstad@gmail.com",
//         acceptInfo: true,
//         idNo: "920929",
//         toBePaidInRestaurantSystem: false,
//         discountCodeName: "",
//         discountCode: "",
//         campaigns: [],
//         events: [
//           {
//             id: "jhm6ylp6u57dcd8",
//             name: "Little Jinder",
//             start: "2024-11-09T20:00:00+01:00",
//             end: "2024-11-09T21:15:00+01:00",
//             lastUpdated: "2024-10-09T14:46:24.693+02:00",
//             productionId: "3VHT94KFV1EA75P",
//             productionName: "Little Jinder",
//             hasTableReservation: false,
//             tags: ["Little Jinder"],
//             venue: {
//               id: "rjpy6cvweatmx7n",
//               name: "Maxim",
//               address: "Karlaplan 4",
//               zipcode: "114 60",
//               city: "Stockholm",
//               country: "SE",
//             },
//             restaurants: [],
//           },
//         ],
//         goods: [
//           {
//             goodsid: "YBK1-229-0-81144807",
//             name: "Parkett, stående",
//             receipttext: "Parkett, stående",
//             type: "Ticket",
//             artno: "YBK1-229",
//             priceIncVatAfterDiscount: "375",
//             vatPortion: "21.2264",
//             vatPercent: "0.06",
//             eventId: "jhm6ylp6u57dcd8",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: ["Little Jinder"],
//           },
//           {
//             goodsid: "YBK1-229-1-81144808",
//             name: "Parkett, stående",
//             receipttext: "Parkett, stående",
//             type: "Ticket",
//             artno: "YBK1-229",
//             priceIncVatAfterDiscount: "375",
//             vatPortion: "21.2264",
//             vatPercent: "0.06",
//             eventId: "jhm6ylp6u57dcd8",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: ["Little Jinder"],
//           },
//           {
//             goodsid: "YBK1-SVC-0",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//           {
//             goodsid: "YBK1-SVC-1",
//             name: "Serviceavgift",
//             receipttext: "Serviceavgift",
//             type: "AdminGoods",
//             artno: "YBK1-SVC",
//             priceIncVatAfterDiscount: "35",
//             vatPortion: "7",
//             vatPercent: "0",
//             eventId: "",
//             section: null,
//             seat: null,
//             row: null,
//             packageId: null,
//             partOfSeasonToken: false,
//             partOfSeasonTokenGoodsId: null,
//             partOfTableReservation: false,
//             canBePlacedAtTable: false,
//             restaurantId: "",
//             tags: [],
//           },
//         ],
//         additionalinputfields: [],
//       },
//     },
//   ];
