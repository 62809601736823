import React from 'react';
import { Tooltip, Badge, IconButton } from '@mui/material';
import { Mail } from '@mui/icons-material';

export const AwaitingResponseBooking = (props) => {
	const onClickHandler = () => {
		props.setShowBookingStatusType(
			props.showBookingStatusType === 'Pending Request'
				? ''
				: 'Pending Request',
		);
	};
	return (
		<IconButton className='badge' onClick={onClickHandler}>
			<Tooltip title={props.noPendingRequest + ' pending requests'}>
				<Badge
					badgeContent={props.noPendingRequest}
					sx={{
						'& .MuiBadge-badge': {
							color: 'white',
							border: '1px solid #509a6c',
							backgroundColor: 'red',
							// backgroundColor: "red",
						},
					}}
				>
					<Mail color='action' />
				</Badge>
			</Tooltip>
		</IconButton>
	);
};
