/** @format */

import { Button, CircularProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { createId, createMidId, db } from "../../firebase";
import { SendRequest } from "../../helpers/SendRequest";
import { timeAdjust } from "../../components/UI helpers/timeAdjust";
import { generateOCR } from "../../helpers/generateOCR";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import { create } from "qrcode";
import { set } from "date-fns";
import { testData } from "./testData";

const SyncTicksterEvents = () => {
  const [loading, setLoading] = useState(false);
  const [ticksterEvents, setTicksterEvents] = useState([]);
  const [existingBookings, setExistingBookings] = useState([]);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const venueid = currentVenue && currentVenue.venueid;
  console.log("currentVenue", currentVenue);

  const [countNew, setCountNew] = useState(0);
  const [countExisting, setCountExisting] = useState(0);
  const [newEvents, setNewEvents] = useState([]);
  const [existingEvents, setExistingEvents] = useState([]);
  const [newEventsMap, setNewEventsMap] = useState([]);
  const [waitPrompt, setWaitPrompt] = useState("");
  const [ticketSummaries, setTicketSummaries] = useState([]);

  async function getExistingBookings() {
    // setLoading(true);
    try {
      setWaitPrompt("Checking existing bookings...");
      // console.log("Getting existing bookings");

      // Ensure currentVenue and venueid are defined
      if (!currentVenue || !currentVenue.ticketVenueId || !venueid) {
        console.error("Required IDs are not properly defined.");
        return []; // Early return if the required ids are not defined
      }

      // Fetch documents from Firestore where 'ticketVenueId' matches
      const bookingsRef = collection(db, "Bookings");
      const bookingsQuery = query(bookingsRef, where("ticketVenueId", "==", currentVenue.ticketVenueId));
      const querySnapshot = await getDocs(bookingsQuery);

      // Filter documents where 'venueid' matches and collect them in an array
      const filteredBookings = querySnapshot.docs.filter((doc) => doc.data().venueid === venueid).map((doc) => doc.data());

      // Update state or perform further actions
      setCountExisting(filteredBookings.length);

      // Optionally, you can update the state with the bookings
      setExistingBookings(filteredBookings);
      // setLoading(false);
      setWaitPrompt("done!");
      return filteredBookings;
    } catch (error) {
      console.error("Error fetching existing bookings:", error);
      return []; // Return an empty array in case of an error
    }
  }

  useEffect(() => {
    console.log("waitPrompt", waitPrompt);
  }, [waitPrompt]);

  async function checkTickster() {
    setLoading(true);
    getTicketData();

    // === >>> === >>> For testing purposes to be REMOVED
    if (testData && testData.length > 0) {
      // setExistingBookings(await getExistingBookings());
      getExistingBookings();
      setTicksterEvents(testData);
      setLoading(false);
      return;
    }

    // TEMP, uncomment later
    getExistingBookings(); // first get the existing bookings
    // // then get the tickster events
    const body = {
      venue: currentVenue.venue,
      organizer: currentVenue.ticketVenueId,
      venueApiKey: currentVenue.ticketApiKey,
      venueid: currentVenue.venueid,
    };
    console.log("currentVenue", currentVenue);
    setWaitPrompt("Checking Tickster...");
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicksterEventsByVenue`, body); // for testing
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicksterEventsByVenue`, body); //for live
    console.log("💚 💚 💚", response.data); //for live
    setTicksterEvents(response.data); //for live
    setLoading(false);
  }

  // Create the newEvents array.
  useEffect(() => {
    if (ticksterEvents.length < 1) return;

    for (const newEvent of ticksterEvents) {
      // check if the event already exists in "Bookings" collection.
      let existingEvent = existingBookings.find((booking) => booking.ticketEventId === newEvent.id);
      // ...otherwise add it to newEvents
      if (!existingEvent) {
        setNewEvents((previous) => [...previous, newEvent]);
        setCountNew((previous) => previous + 1);
        console.log("new event");
      } else {
        console.log("existing event");
      }
    }
  }, [ticksterEvents]);

  // Now check in ticketSummaries for (child)events that didn't show up by checking getTicksterEventsByVenue
  useEffect(() => {
    let tmp = [];
    let newCreatedEvents = [...newEvents];
    for (const newEvent of newEvents) {
      if (newEvent.eventHierarchyType === "production") {
        const childEvents = ticketSummaries.filter((item) => item.ticketProductionId.toLowerCase() === newEvent.id.toLowerCase());

        childEvents.map((item) => {
          // check if the child event is already in newEvents
          if (newEvents.some((obj) => obj.id.toLowerCase() === item.ticketEventId.toLowerCase())) return;
          // now keep the ones that are not in newEvents
          // ta newEvent och skapa en kopia i newEvents med id från item.ticketEventId ovan.

          tmp.push(item);
          let tmpEvent = JSON.parse(JSON.stringify(newEvent));
          tmpEvent.id = item.ticketEventId.toLowerCase();
          tmpEvent.parentEventId = item.ticketProductionId.toLowerCase();
          tmpEvent.eventHierarchyType = "production-child";
          newCreatedEvents.push(tmpEvent);
        });
      }
    }
    console.log("child-productions B", tmp);
    console.log("newCreatedEvents", newCreatedEvents);
    setNewEvents(newCreatedEvents);
  }, [ticketSummaries]);

  // useEffect(() => {}, [newEvents]);

  // useEffect(() => {
  //   console.log("newEventsMap", newEventsMap);
  // }, [newEventsMap]);

  async function deleteExisting() {
    for (const existingEvent of existingBookings) {
      console.log("deleting...", existingEvent.documentid);
      deleteDoc(doc(db, "Bookings", existingEvent.documentid));
    }
    setCountExisting(0);
  }

  async function matchWithVenueProducts(newEvent, doors, show, loadOut, curfew) {
    let tmp = JSON.parse(JSON.stringify(currentVenue.products[0]));
    tmp.getIn = doors && timeAdjust(doors, "-03:30");
    tmp.loadIn = doors && timeAdjust(doors, "-03:00");
    tmp.soundcheck = doors && timeAdjust(doors, "-02:00");
    tmp.doors = doors;
    tmp.show = show;
    tmp.loadOut = loadOut;
    tmp.curfew = curfew;
    console.log("TMP", tmp);
    return tmp;
  }

  async function importNewEvents() {
    setLoading(true);
    let i = 0;
    for (const newEvent of newEvents) {
      i++;
      await createBooking(newEvent);
      setCountNew((previous) => previous - 1);
      setCountExisting((previous) => previous + 1);
      console.log("i", i);
    }
    console.log("getting existing again");
    // setLoading(true);
    // setExistingBookings(await getExistingBookings());
    await getExistingBookings();

    console.log("mapping parent child events");
    mapParentChildEvents();
  }

  useEffect(() => {
    console.log("existing bookings", existingBookings);
  }, [existingBookings]);

  // async function importNewEvents() {}

  async function createBooking(newEvent) {
    const doors = newEvent.doorsOpenUtc && newEvent.doorsOpenUtc.split("T")[1].substring(0, 5);
    const show = newEvent.startUtc && newEvent.startUtc.split("T")[1].substring(0, 5);
    const loadOut = newEvent.endUtc && newEvent.endUtc.split("T")[1].substring(0, 5);
    const curfew = newEvent.curfewUtc && newEvent.curfewUtc.split("T")[1].substring(0, 5);
    const selectedDate = new Date(newEvent.startUtc).toLocaleDateString("sv-SE");
    const bookingid = createId();

    const tmp = await matchWithVenueProducts(newEvent, doors, show, loadOut, curfew);
    const currentProduct = currentVenue.products[0];

    // Create Agreement
    // const agreementId = newEventsMap.find((event) => event.id === newEvent.id).targetAgreementId;
    const agreementId = createId();
    const OCR = await generateOCR();
    const agreementPackage = {
      OCR: OCR,
      userId: currentUser,
      bookingids: [bookingid],
      status: "Pending Request",
      email: currentVenue.email || "",
      agent: { email: "", phone: "" },
      venue: currentVenue.venue,
      venueid: currentVenue.venueid,
      documentid: agreementId,
      agreementId: agreementId,
      venueLogo: currentVenue.venueLogo || "",
      artist: { artist: newEvent.name, image: newEvent.imageUrl || "" },
      upfront: currentVenue.upfront,
      upfrontAt: currentVenue.upfrontAt,
      preCharge: currentVenue.preCharge,
      preChargeAt: currentVenue.preChargeAt,
      balance: currentVenue.balance || 0,
      balanceAt: currentVenue.balanceAt || 0,
      earlyCancelation: currentVenue.earlyCancelation || 50,
      earlyCancelationAt: currentVenue.earlyCancelationAt || 180,
      lateCancelation: currentVenue.lateCancelation || 100,
      lateCancelationAt: currentVenue.lateCancelationAt || 60,
      addendum: { text: currentVenue.addendum || "", useAddendum: currentVenue.useAddendum || false },
    };

    // Create agreement if eventHierarchyType is either "production" or "event". (Later connect production-children to the correct agreement)
    if (newEvent.eventHierarchyType === "production" || newEvent.eventHierarchyType === "event") {
      setWaitPrompt("Creating agreement...");
      console.log("Creating agreement for...", newEvent.name);
      await setDoc(doc(db, "Agreements", agreementId), {
        ...agreementPackage,
      });
    }

    // TEMPLATE====> SendRequest = (currentArtist, currentAgent, availData, proposedDate, agreementId, bookingid)
    setWaitPrompt("Creating booking...");
    // if (newEvent.parentEventId !== null) {
    // only non-parents
    await SendRequest(
      { artistid: "-", artist: newEvent.name, image: newEvent.imageUrl || "" },
      { agentid: "-", agent: "", firstName: "", lastName: "" },
      {
        venue: currentVenue && { ...currentVenue },
        venueid: currentVenue.venueid,
        product: currentProduct.product,
        productid: currentProduct.productid,
        getIn: tmp.getIn,
        loadIn: tmp.loadIn,
        soundcheck: tmp.soundcheck,
        doors: tmp.doors,
        show: tmp.show,
        loadOut: tmp.loadOut,
        curfew: tmp.curfew,
        price: tmp.price,
        priceIncVat: tmp.priceIncVat,
        status: "Pending Request",
        ticketEventId: newEvent.id,
        documentid: bookingid,
      },
      selectedDate,
      agreementId,
      bookingid
    );
    // }
    // await mapParentChildEvents();
  }

  async function getTicketData() {
    // Get all ticket sales from ticketEventIds
    const ticketVenueId = currentVenue.ticketVenueId;
    const docSnapTickets = await getDoc(doc(db, "TicketSummaries", ticketVenueId));
    setTicketSummaries(docSnapTickets.data() && Object.values(docSnapTickets.data()));
  }

  async function mapParentChildEvents() {
    alert("Mapping parent child events");
    setWaitPrompt("Connecting bookings to agreements...");

    const existing = await getExistingBookings();
    let targetAgreementId = null;

    let eventsMap = [];
    for (const item of newEvents) {
      if (item.eventHierarchyType === "event") {
        // alert("event");
        continue;
      }
      if (item.eventHierarchyType === "production-child") {
        console.log("item", item);
        const foundBooking = existing.find((booking) => booking.ticketEventId === item.parentEventId);
        if (foundBooking === undefined) continue;
        const targetAgreementId = foundBooking.agreementId;
        // targetAgreementId = existing.find((booking) => booking.ticketEventId === item.parentEventId).agreementId;

        const bookingid = existing.find((booking) => booking.ticketEventId === item.id).bookingid;
        eventsMap.push({ id: item.id, type: "child", targetAgreementId, bookingid, eventHierarchyType: item.eventHierarchyType });
      }
      // if (!item.parentEventId) {
      if (item.eventHierarchyType === "production") {
        // console.log("parent", item.id);
        console.log("item", item);
        const foundBooking = existing.find((booking) => booking.ticketEventId === item.id);
        if (foundBooking === undefined) {
          console.log("krage", item);
          continue;
        }
        targetAgreementId = foundBooking.agreementId;
        const bookingid = existing.find((booking) => booking.ticketEventId === item.id).bookingid;
        eventsMap.push({ id: item.id, type: "parent", targetAgreementId, bookingid, eventHierarchyType: item.eventHierarchyType });
      }
    }
    console.log("eventsMap", eventsMap);
    groupByTargetAgreementId(eventsMap);

    async function groupByTargetAgreementId(data) {
      setWaitPrompt("Grouping by targetAgreementId...");
      const result = data.reduce((acc, item) => {
        // Ensure the key exists
        if (!acc[item.targetAgreementId]) {
          acc[item.targetAgreementId] = {
            targetAgreementId: item.targetAgreementId,
            bookingids: [],
          };
        }

        // Add bookingid for both parent and child types if they have a bookingid
        if (item.bookingid) {
          setWaitPrompt("Adding bookingid...");
          acc[item.targetAgreementId].bookingids.push(item.bookingid);
        }
        return acc;
      }, {});

      // Store bookingids in agreement, and agreementId to booking
      console.log(result);
      setWaitPrompt("Storing bookingids in agreement...");
      for (const item of Object.values(result)) {
        await setDoc(
          doc(db, "Agreements", item.targetAgreementId),
          {
            bookingids: item.bookingids,
          },
          { merge: true }
        );
        for (const obj of item.bookingids) {
          await setDoc(
            doc(db, "Bookings", obj),
            {
              agreementId: item.targetAgreementId,
            },
            { merge: true }
          );
        }
      }
      await deleteNotNeeded(data);
    }
    setWaitPrompt("Done!");
    setLoading(false);
  }

  async function deleteNotNeeded(data) {
    setWaitPrompt("Deleting not needed");
    let notNeeded = [];
    data.map((item) => {
      console.log("item", item);
      if (item.eventHierarchyType === "event" || item.eventHierarchyType === "production-child") console.log("Keeper:", item.bookingid);
      if (item.eventHierarchyType === "production") {
        console.log("Loose this bookingid:", item.bookingid);
        // Remove from bookingids in agreement
        // await setDoc(
        //   doc(db, "Agreements", item.targetAgreementId),
        //   {
        //     bookingids: item.bookingids,
        //   },
        //   { merge: true }
        // );
        // ...and detlete the booking
        deleteDoc(doc(db, "Bookings", item.bookingid));
      }
    });
    setWaitPrompt("Done deleting not needed!");
  }

  useEffect(() => {
    console.log("waitPrompt", waitPrompt);
  }, [waitPrompt]);

  return (
    <>
      {console.log("Ticket sales", ticketSummaries)}
      {newEvents.length && (
        <div style={{ display: "flex", gap: "15px", flexDirection: "column", padding: "15px" }}>
          {console.log("newEvents", newEvents)}
          <table>
            <th>TEID</th>
            <th>TPID</th>
            <th>Sales</th>
            {ticketSummaries &&
              ticketSummaries.map((item) => (
                // <tr style={{ opacity: newEvents.some((item) => item.id === item.ticketEventId) ? 1 : 0.3 }}>
                <tr style={{ opacity: newEvents.some((obj) => obj.id === item.ticketEventId) ? 0.3 : 1 }}>
                  <td>{item.ticketEventId}</td>
                  <td>{item.ticketProductionId}</td>
                  <td>{item.summary.count}</td>
                </tr>
              ))}
          </table>
          New Events Found:
          <table style={{ width: "100%" }}>
            <th>Name</th>
            <th>Date</th>
            <th>eventId</th>
            <th>parentId</th>
            <th>Type</th>
            <th>Image</th>
            <th>Products</th>
            <th>Sales</th>
            {newEvents.map((newEvent, index) => (
              <tr key={index}>
                <td>{newEvent.name}</td>
                {/* 
                <td>{newEvent.endUtc}</td>
                <td>{newEvent.doorsOpenUtc}</td>
                <td>{newEvent.curfewUtc}</td> */}
                <td>{new Date(newEvent.startUtc).toLocaleDateString()}</td>
                <td>{newEvent.id}</td>
                <td>{newEvent.parentEventId}</td>
                <td>{newEvent.eventHierarchyType}</td>
                <td style={{ maxHeight: "20px", maxWidth: "20px", overflow: "hidden" }}>
                  {newEvent.imageUrl && <img src={newEvent.imageUrl} width="auto" height="20" />}
                </td>
                <td>
                  <Tooltip title={newEvent.products && newEvent.products.map((item) => `${item.name}  -  `)}>
                    {newEvent.products && newEvent.products.length}
                  </Tooltip>
                </td>
                <td style={{ background: "#00ffd4", color: "black", textAlign: "center" }}>
                  {ticketSummaries && ticketSummaries.filter((item) => item.ticketEventId === newEvent.id)[0]
                    ? ticketSummaries.filter((item) => item.ticketEventId === newEvent.id)[0].summary.count
                    : "none"}
                </td>
                {/* name, productType, description, mainImageUrl, price, variants */}
              </tr>
            ))}
          </table>
        </div>
      )}
      <div style={{ display: "flex", gap: "15px", flexDirection: "row" }}>
        <div>New: {countNew}</div>
        <div>Existing: {countExisting}</div>
        {loading && <CircularProgress style={{ zoom: "0.4", marginRight: "15px" }} />}

        {waitPrompt && <div style={{ color: "#00ffd499" }}>{waitPrompt}</div>}
      </div>
      <div style={{ padding: "15px", display: "flex", gap: "15px", flexDirection: "row" }}>
        <Button onClick={() => checkTickster()} variant="outlined">
          Check Tickster
        </Button>
        {existingBookings.length > 0 && (
          <ConfirmDialog
            onConfirm={deleteExisting}
            title="Delete Existing"
            description="Are you sure you want to delete existing bookings?"
          >
            <Button variant="outlined" color="warning">
              Remove Existing
            </Button>
          </ConfirmDialog>
        )}
        {newEvents.length > 0 && (
          <Button onClick={() => importNewEvents()} variant="outlined">
            Import New
          </Button>
        )}
        {existingBookings.length > 0 && (
          <ConfirmDialog
            onConfirm={deleteNotNeeded}
            title="Delete not needed"
            description="Are you sure you want to delete not needed bookings?"
          >
            <Button variant="outlined" color="warning">
              Delete not needed
            </Button>
          </ConfirmDialog>
        )}
        <Button variant="outlined" color="warning" onClick={() => mapParentChildEvents()}>
          Map parentchild
        </Button>
      </div>
    </>
  );
};

export default SyncTicksterEvents;
