/** @format */

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { countries } from "../../dbObjects.js/dbCountries";

const CountryInput = ({ formData, handleChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="country-select-label">Country</InputLabel>
      <Select
        sx={{ minWidth: "200px" }}
        size="small"
        labelId="country-select-label"
        id="country-select"
        value={formData["country"]}
        label="Country"
        onChange={(event) => handleChange(event, "country", "text")}
      >
        {countries.map((country, index) => (
          <MenuItem key={index} value={country.country}>
            {/* <CountryFlag countryCode={country.flagCode} svg style={{ marginRight: '10px' }} /> */}
            {country.country}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountryInput;
