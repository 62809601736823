/** @format */

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { countries } from "../../dbObjects.js/dbCountries";

const VatSelector = ({ handleChangeOption, value, item, optionId }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  console.log("currentVenue", currentVenue);

  const country =
    currentVenue.country !== undefined
      ? countries.filter((item) => currentVenue.country === item.country)
      : countries.filter((item) => "Sweden" === item.country);
  console.log("📊", value, countries, country, currentVenue, item);
  const vatOptions = country[0].vatOptions;

  const guessedRate = item.additional === "Catering" ? vatOptions[1].vatRate : vatOptions[0].vatRate;
  // (!value || value === "") && handleChangeOption(item.additionalId, optionId, "vat", guessedRate); // If no vatRate is set set the guessed one.
  console.log(" 📊 📊 📊", "value", value, "guessedrate", guessedRate);

  useEffect(() => {
    // if no value set guessedRate
    if (value === " " || value === "") handleChangeOption(item.additionalId, optionId, "vat", guessedRate);
  }, []);

  return (
    // handleChangeOption(item, option.optionId, field.id, e.target.value)
    <FormControl>
      <InputLabel id="vat-selector" className="vatLabel">
        Vat
      </InputLabel>
      <Select
        className="vatSelector"
        id="vat-selector"
        defaultValue={guessedRate}
        value={value}
        size="small"
        onChange={(e) => handleChangeOption(item.additionalId, optionId, "vat", e.target.value)}
        sx={{
          // color: "transparent",
          minWidth: "100px",
        }}
      >
        {vatOptions.map((vatOption) => (
          <MenuItem value={vatOption.vatRate} label="Vat">
            {/* <span style={{ color: "#fff" }}> */}
            {vatOption.vatRate}
            {/* </span> */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VatSelector;
