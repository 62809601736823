/** @format */

//TODO
// IBAN kan eventuellt ha andra format än som nu inställt, med de två första stora bokstäver, resten siffror och mellan 22 och 34 i längd.

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { InputAdornment, Tooltip } from "@mui/material";
import { EnhancedEncryption } from "@mui/icons-material";
import { AES, enc } from "crypto-js";
import { useEffect } from "react";

const IBANTextField = ({ formValue, setIBAN }) => {
  const [iban, setIban] = useState("");
  const [decryptedValue, setDecryptedValue] = useState("");
  const [error, setError] = useState(false);
  const secretKey = process.env.REACT_APP_FORM_KEY; // Replace with your secret key

  const handleChange = (event) => {
    const newIBAN = event.target.value;
    // Automatically convert the first two characters to uppercase
    const formattedIBAN = newIBAN.slice(0, 2).toUpperCase() + newIBAN.slice(2).replace(/[^0-9]/g, "");

    //Encrypt before return
    const encryptedIBAN = formattedIBAN && AES.encrypt(formattedIBAN, secretKey).toString();
    setIBAN(encryptedIBAN);
  };

  useEffect(() => {
    setDecryptedValue(formValue && AES.decrypt(formValue, secretKey).toString(enc.Utf8));
  }, [formValue]);

  useEffect(() => {
    // Check if the first two characters are not numbers and the total length is not more than 22 characters
    if (decryptedValue && !/^\d{2}/.test(decryptedValue) && decryptedValue.length > 21 && decryptedValue.length < 35) {
      setError(false);
    } else {
      setError(true);
    }
  }, [decryptedValue]);

  return (
    <div>
      <TextField
        size="small"
        style={{ width: "350px" }}
        label="IBAN Number"
        variant="outlined"
        // value={formValue}
        value={decryptedValue}
        // value={AES.decrypt(formValue, secretKey).toString(enc.Utf8)}
        onChange={handleChange}
        error={error}
        helperText={error ? "Insert valid IBAN" : ""}
        InputProps={{
          endAdornment: <InputAdornment position="end">{!error && <CheckCircle style={{ color: "#0080006b" }} />}</InputAdornment>,
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Encrypted">
                <EnhancedEncryption style={{ color: "#555", zIndex: "100000" }} />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default IBANTextField;
