/** @format */

import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhatDayIsIt from "../../components/MiscUIComponents/WhatDayIsIt";
import venueFilterSlice, { storeWantedList } from "../venueFilter/venueFilterSlice";

const ShowAvails = ({ venue }) => {
  let tmp = [];
  // let filteredAvails = [];
  const currentArtistBookings = useSelector((state) => state.counter.currentArtistBookings);
  const wantedList = useSelector((state) => state.counter.wantedList);
  const bookingsAndWanted = currentArtistBookings.concat(wantedList);
  var tempWantedList = [...wantedList];
  let avails = venue.avails.slice();
  let availsWithoutBookingsAndWanted = [];
  const dispatch = useDispatch();
  const venueid = venue.venueid;

  const handleClick = (avail, index) => {
    //Add proposedDate to wantedList
    const newObject = { ...venue };
    newObject["proposedDate"] = avail;
    tempWantedList.push({ ...newObject }); //...the new way
    //and dispatch
    dispatch(storeWantedList(tempWantedList));
  };

  return (
    <div style={{ width: "flex" }} className="availsPicker">
      <div>Available dates</div>
      {console.log("takenDates", tmp)}
      {console.log("bookingsAndWanted", bookingsAndWanted)}
      {avails &&
        tmp &&
        avails.map((avail, index) => {
          if (index > 0 && !avails.includes({ avail }))
            // if (index > 0 && !avails.includes({ avail }))
            return (
              <>
                <span onClick={() => handleClick(avail, index)} className="availPick">
                  <span className="goo">
                    <WhatDayIsIt date={avail} numberOfLetters={"3"} />
                  </span>
                  {avail}
                </span>
              </>
            );
        })}
    </div>
  );
};

export default ShowAvails;
