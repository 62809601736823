/** @format */

import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { MdOutlineCalendarToday, MdAttachEmail, MdOutlineDriveFileRenameOutline, MdOutlineMenu } from "react-icons/md";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SendAvails from "../../features/avails/SendAvails";
import SelectVenue from "../../helpers/SelectVenue";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";

const SidebarVenue = () => {
  const [show, setShow] = useState(true);
  // const currentVenue = useSelector((state) => state.counter.currentVenue);

  return (
    <>
      <Grid container className="sidebar" direction="column" spacing={2} style={{ background: "#ffffff11" }} mt={0} sx={{ height: "92vh" }}>
        <Grid item py={5} mx={3} mt={5}>
          <SelectVenue />
        </Grid>

        <Grid item>
          <Link to="/Venue/VenueCalendar">
            <Button>
              <icon className="text-md-left">
                <MdOutlineCalendarToday className="mx-3" size={25} color={"white"} />
              </icon>
              <itemTitle className="d-none d-sm-inline">Calendar</itemTitle>
            </Button>
          </Link>
        </Grid>
        <Grid item mx={0} mt={0} px={0} sx={{ marginLeft: "-2px" }}>
          <Accordion defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <icon className="text-md-left">
                <MdAttachEmail className="mx-3" size={25} color={"white"} />
              </icon>
              <Typography>SEND AVAILS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SendAvails />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* <Grid item>
          <Link to="/Venue/Avails">
            <Button>
              <icon className="text-md-left">
                <MdAttachEmail className="mx-3" size={25} color={"white"} />
              </icon>
              <itemTitle className="d-none d-sm-inline">Send Avails</itemTitle>
            </Button>
          </Link>
        </Grid> */}
        <Grid item>
          <Link to="/Owner/Certificates">
            <Button>
              <icon className="text-md-left">
                {/* <ForestIcon className="mx-3" size={25} style={{ color: "primary" }} /> */}
                <VerifiedIcon className="mx-3" size={25} style={{ color: "primary" }} />
              </icon>
              <itemTitle className="d-none d-sm-inline menuOption">Certificates</itemTitle>
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/Venue/Reports">
            <Button>
              <icon className="text-md-left">
                <MdOutlineDriveFileRenameOutline className="mx-3" size={25} color={"white"} />
              </icon>
              <itemTitle className="d-none d-sm-inline">Reports</itemTitle>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default SidebarVenue;
