/** @format */

import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { makeNumber } from "../../helpers/Numbers";

const CO2eConversion = (key, value, year, emissionFactors, formData) => {
  if (key === "electricityUse" || key === "electricitySource") {
    let tmpValue = value;
    const emissionFactor =
      formData.electricitySource === "Renewable"
        ? parseFloat(emissionFactors[4].TotalValue.replace(",", ".")) // If renewable...
        : parseFloat(emissionFactors[0].TotalValue.replace(",", ".")); // ...otherwise
    console.log("emissionFactor", emissionFactor);
    if (key === "electricitySource") tmpValue = formData.electricityUse; // get tmpValue from formData instead of prop (value)
    const result = (emissionFactor * tmpValue) / 1000; // (/1000 means we're returning "kg" instead of "g")
    return result;
  }

  if (key === "onSiteProduced") {
    const emissionFactor = parseFloat(emissionFactors[4].TotalValue.replace(",", "."));
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "heatingQuantity") {
    const emissionFactor =
      formData.heatingUsed === "natural gas" && formData.heatingUnit === "tonnes" //if
        ? parseFloat(emissionFactors[7].TotalValue.replace(",", ".")) //...then get emissionfactor from row 7
        : formData.heatingUsed === "natural gas" && formData.heatingUnit === "kWh"
        ? parseFloat(emissionFactors[8].TotalValue.replace(",", "."))
        : null;
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "refrigerantQuantity") {
    const emissionFactor =
      formData.refrigerantType === "hfc-23" //if
        ? parseFloat(emissionFactors[28].TotalValue.replace(",", ".")) //...then get emissionfactor from row 28
        : formData.refrigerantType === "hfc-32"
        ? parseFloat(emissionFactors[29].TotalValue.replace(",", "."))
        : formData.refrigerantType === "hfc-41"
        ? parseFloat(emissionFactors[30].TotalValue.replace(",", "."))
        : formData.refrigerantType === "hfc-125"
        ? parseFloat(emissionFactors[31].TotalValue.replace(",", "."))
        : null;
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "waterUse") {
    const emissionFactor = parseFloat(emissionFactors[142].TotalValue.replace(",", ".")); //...then get emissionfactor from row 142
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "recycledMetal") {
    const emissionFactor = parseFloat(emissionFactors[143].TotalValue.replace(",", ".")); //...then get emissionfactor from row 143
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "recycledPlastic") {
    const emissionFactor = parseFloat(emissionFactors[145].TotalValue.replace(",", ".")); //...then get emissionfactor from row 143
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "recycledPaper") {
    const emissionFactor = parseFloat(emissionFactors[147].TotalValue.replace(",", ".")); //...then get emissionfactor from row 143
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "recycledElectric") {
    const emissionFactor = parseFloat(emissionFactors[149].TotalValue.replace(",", ".")); //...then get emissionfactor from row 143
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "mixedHousehold") {
    const emissionFactor = parseFloat(emissionFactors[150].TotalValue.replace(",", ".")); //...then get emissionfactor from row 143
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }

  if (key === "landfilledWaste") {
    const emissionFactor = parseFloat(emissionFactors[148].TotalValue.replace(",", ".")); //...then get emissionfactor from row 143
    const result = (emissionFactor * value) / 1000; // (/1000 means we're returning "kg" instead of "g")
    console.log(emissionFactor, value, result);
    return result;
  }
};

export default CO2eConversion;
