/** @format */

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import DisplayObject from "../../helpers/displayObject";
import { numberFormatted } from "../../helpers/Numbers";
import { calculateBookingPrice } from "../MiscUIComponents/calculateBookingPrice";
import { privateIcon, publicIcon } from "../../helpers/customIcons";

const ProductSummary = ({ productData, currentVenue, setProductData }) => {
  const [productTotal, setProductTotal] = useState();
  const [priceIncVat, setPriceIncVat] = useState();
  console.log("productData", productData);
  useEffect(() => {
    productSum();
  }, [productData.features]);

  // calculate product total sum
  const productSum = () => {
    let sumArray = [];
    productData.features &&
      Object.values(productData.features).map((feature) => {
        Object.values(feature.options).map((option) => {
          option.selected === true && option.price && !option.optional && sumArray.push(Number(option.price));
          console.log(sumArray);
        });
      });
    let result = sumArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    console.log("RESULT", result);
    setProductTotal(result);
    setPriceIncVat(calculateBookingPrice(productData.features, "totalIncVat"));

    return result;
  };

  // set the price (unless in bookingFormGeneral)
  setProductData &&
    useEffect(() => {
      // alert(priceIncVat);
      // alert(productTotal);
      // setProductPrice(productSumValue);
      setProductData((prev) => ({ ...prev, price: productTotal, priceIncVat: priceIncVat }));
    }, [productTotal, priceIncVat]);

  // useEffect(()=>{
  //   setProductData((prev) => ({ ...prev, capacity: productTotal }));
  // },[])

  // useEffect(() => {
  //   productSum();
  // }, [productSum]);
  // useEffect(() => {
  //   // setProductPrice(productSum);
  //   if (productSum !== productTotal) setProductData((prev) => ({ ...prev, price: productSum() }));
  // }, [productSum]);

  console.log(productData);
  return (
    <div className="productSummaryDiv">
      <h6 style={{ lineHeight: "30px", borderBottom: "1px solid #ffffff33" }}>
        Product summary | {productData.privatePublic === "Public Event" ? publicIcon : privateIcon} {productData.product}{" "}
      </h6>
      {productData.loadIn} - {productData.loadOut}
      {productData.features &&
        Object.values(productData.features).map((feature) => (
          <div>
            <h6
              style={{
                opacity: "0.4",
                fontSize: "13px",
                marginTop: "5px",
                marginBottom: "0px",
                textTransform: "uppercase",
                // color: "#00ffd4",
              }}
            >
              {feature.additional}
            </h6>
            {Object.values(feature.options).map((option) => (
              <>
                {/* {console.log("OPTION", option)} */}
                {option.selected && (
                  <div style={{ fontSize: "12px", opacity: "0.9" }}>
                    <b>{option.option}</b>
                    {option.info ? `, ${option.info}` : ""}
                    {option.specs ? <span> {option.specs}</span> : ""}
                    {option.price ? (
                      <span>
                        , {option.price} {currentVenue.currency && currentVenue.currency}
                      </span>
                    ) : (
                      ""
                    )}{" "}
                    {option.optional && <span style={{ color: "orange" }}>(optional)</span>}
                  </div>
                )}
              </>
            ))}
          </div>
        ))}
      <h6 style={{ lineHeight: "30px", marginTop: "15px", borderTop: "1px solid #ffffff33" }}>
        {/* old(Total: {numberFormatted(productSum())} {currentVenue.currency}) */}
        Total: {numberFormatted(productData.price)} {currentVenue.currency}{" "}
        <span style={{ opacity: "0.5" }}>
          (incl. VAT: {numberFormatted(productData.priceIncVat)} {currentVenue.currency}){" "}
        </span>
      </h6>
      {/* {productSum()} */}
    </div>
  );
};

export default ProductSummary;
