/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import {
	GoogleMap,
	useJsApiLoader,
	MarkerF,
	OverlayViewF,
	OverlayView,
} from '@react-google-maps/api';
import { useWindowSize } from '@react-hook/window-size';
import {
	primaryColor,
	secondaryColor,
	primaryColorDark,
	secondaryColorDark,
	primaryColorLight,
	secondaryColorLight,
	tertiaryColor,
	waterColor,
} from '../../assets/theme/Theme';
import { getVenuesByIds } from '../../firebase';
import { useSelector } from 'react-redux';
import { Badge, Tooltip } from '@mui/material';
import FlatVenueCard from '../../features/create tour/FlatVenueCard';
import ShowAvails from '../../features/create tour/ShowAvails';
import MiniMonth from '../../features/MiniMonth';
import WhatDayIsIt from '../MiscUIComponents/WhatDayIsIt';
import { storeWantedList } from '../../features/venueFilter/venueFilterSlice';

export const MainGoogleMapComponent = (props) => {
	const availableVenuesAndDates = useSelector(
		(state) => state.counter.availableVenuesAndDates,
	);
	const wantedList = useSelector((state) => state.counter.wantedList);
	const currentArtistBookings = useSelector(
		(state) => state.counter.currentArtistBookings,
	);

	// useEffect(() => {
	//   setTimeout(() => {
	//     currentArtistBookings && console.log("THIS ", currentArtistBookings);
	//   }, 1000);
	// }, [currentArtistBookings]);

	const [width, height] = useWindowSize();
	const [map, setMap] = React.useState(null);
	const [allVenueIDs, setAllVenueIDs] = useState([]);
	const [foundVenues, setFoundVenues] = useState([]);
	const [zoomValue, setZoomValue] = useState(6);
	const [tooltip, setTooltip] = useState();
	// function extractVenueIds() {
	//   const venueIds = availableVenuesAndDates.map((date) => date.venueId);
	//   setAllVenueIDs(venueIds);
	// }

	// useEffect(() => {
	//   extractVenueIds();
	// }, [props.availableDates]);

	// useEffect(() => {
	//   getVenuesByIds(allVenueIDs).then((result) => {
	//     setFoundVenues(result);
	//   });
	// }, [allVenueIDs]);
	// useEffect(() => {
	//   console.log("foundVenues123412341234", foundVenues);
	// }, [foundVenues]);

	// const handleBoundsChange = (mapBounds) => {
	//   const currentZoom = mapBounds.getZoom();
	//   const previousZoom = this.state.mapZoom;
	//   if (currentZoom !== previousZoom) {
	//     // user has zoomed
	//   }
	//   this.setState({ mapZoom: currentZoom });
	// };

	const containerStyle = {
		width: `${width}px`,
		height: `${height}px`,
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	});

	const onLoad = useCallback(function callback(loadedMap) {
		setMap(loadedMap);
		const bounds = new window.google.maps.LatLngBounds(center);
	}, []);

	React.useEffect(() => {
		if (map) map.setZoom(6);
		// if (map) setZoomValue(map.getZoom());
	}, [map]);

	// const handleBoundsChange = () => {
	// setZoomValue(map.getZoom());
	// console.log(zoomValue);
	// };

	function showPickedDates(venueid) {
		let proposedDates = [];
		wantedList.forEach((venue) => {
			if (venue.venueid === venueid) {
				proposedDates.push(venue.proposedDate);
			}
		});
		return (
			<div>
				{proposedDates.map((pickedDate) => (
					<div>
						{/* <WhatDayIsIt numberOfLetters={3} date={pickedDate} /> */}
						{/* {availableVenuesAndDates.includes(pickedDate) && <span style={{ background: "red" }}>{pickedDate}</span>} */}
						<span
							className='mapDate'
							style={{ background: 'orange' }}
						>
							<WhatDayIsIt
								date={pickedDate}
								numberOfLetters={'3'}
							/>
							{pickedDate}
						</span>
					</div>
				))}
			</div>
		);
	}

	function showBookedDates(venueid) {
		let bookedDates = [];
		// setTimeout(() => {
		//   console.log(bookedDates);
		// }, 2000);
		currentArtistBookings &&
			currentArtistBookings.forEach((booking) => {
				if (booking.venueid === venueid) {
					bookedDates.push(booking);
				}
			});

		return (
			<div>
				{bookedDates.map((bookedDate) => (
					<div className='mapDate'>
						{bookedDate.status === 'Pending Request' && (
							<div>
								<div className={['blobPending']}></div>
								<WhatDayIsIt
									date={bookedDate.date}
									numberOfLetters={'3'}
								/>
								{bookedDate.date}
							</div>
						)}
						{bookedDate.status === 'Awaiting Artist' && (
							<div>
								<div className={['blobAwaiting']}></div>
								<WhatDayIsIt
									date={bookedDate.date}
									numberOfLetters={'3'}
								/>
								{bookedDate.date}
							</div>
						)}
						{bookedDate.status === 'Confirmed' && (
							<div>
								<div className={['blobConfirmed']}></div>
								<WhatDayIsIt
									date={bookedDate.date}
									numberOfLetters={'3'}
								/>
								{bookedDate.date}
							</div>
						)}
					</div>
				))}
			</div>
		);
	}

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={50}
			onLoad={onLoad}
			options={{ styles: darkMapStyles, disableDefaultUI: true }}
			// onBoundsChanged={handleBoundsChange()}
			// onZoomChanged={handleBoundsChange}
		>
			{/* {setZoomValue(map.getZoom())} */}

			{availableVenuesAndDates.map((venue) => {
				// console.log("venue5453453", venue);
				if (!venue.venueLat || !venue.venueLng) {
					return;
				}
				return (
					<>
						{/* <MarkerF
                label={{
                  text: venue.venue,
                  fontSize: "12px",
                  color: "#aaa",
                  fontWeight: "600",
                }}
                onClick={() => {
                  props.setSelectedVenue(venue);
                  props.setSelectedVenueId(venue.venueid);
                  props.setShowVenueInfo(true);
                }}
                key={venue.venueid}
                position={{
                  lat: Number(venue.venueLat),
                  lng: Number(venue.venueLng),
                }}
                icon={
                  {
                    // url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                  }
                }
              /> */}

						<a
							onMouseOver={() =>
								setTooltip(
									<>
										<div
											className='mapTooltip'
											style={{ width: '500px' }}
										>
											<FlatVenueCard venue={venue} />
										</div>
										<div>
											<ShowAvails venue={venue} />
										</div>
									</>,
								)
							}
						>
							<OverlayView
								position={{
									lat: Number(venue.venueLat),
									lng: Number(venue.venueLng),
								}}
								mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
							>
								<div
									style={{
										width: 'auto',
										height: 'auto',
										borderRadius: '10px',
										// backdropFilter: "blur(2px)",
										padding: '15px',
										// background: "#11111166",
									}}
								>
									<Badge
										color='info'
										badgeContent={
											<Tooltip title={tooltip}>
												<span>
													{venue.numberOfAvails - 1}
												</span>
											</Tooltip>
										}
										className='mapBadge'
									></Badge>

									{showPickedDates(venue.venueid)}
									{showBookedDates(venue.venueid)}
								</div>
							</OverlayView>
						</a>
					</>
				);
			})}
		</GoogleMap>
	) : (
		<div>Loading...</div>
	);
};

const center = {
	lat: 60,
	lng: 15,
};

// const darkMapStyles = [
//   { elementType: "geometry", stylers: [{ color: secondaryColorDark }] },
//   {
//     elementType: "labels.icon",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: primaryColorLight,
//       },
//     ],
//   },
//   {
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         color: secondaryColorDark,
//       },
//     ],
//   },
//   {
//     featureType: "administrative",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: secondaryColorDark,
//       },
//       {
//         weight: 1.2,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.land_parcel",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.locality",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: primaryColorLight,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: secondaryColorDark,
//       },
//       {
//         lightness: 5,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: tertiaryColor,
//       },
//       {
//         lightness: 20,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: secondaryColorDark,
//         visibility: "off",
//       },
//       {
//         lightness: 10,
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "geometry.stroke",
//     stylers: [
//       {
//         // color: secondaryColor,
//         color: "#000",
//         visibility: "off",
//       },
//       {
//         lightness: 2,
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry.fill",
//     stylers: [
//       {
//         // color: primaryColorDark,
//         color: "#000",
//       },
//       {
//         lightness: 17,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry.stroke",
//     stylers: [
//       {
//         color: primaryColorDark,
//       },
//       {
//         lightness: 2,
//         visibility: "off",
//       },
//       {
//         weight: 0.2,
//       },
//     ],
//   },
//   {
//     featureType: "road.arterial",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: secondaryColorDark,
//       },
//       {
//         lightness: 18,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road.local",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: secondaryColorDark,
//       },
//       {
//         lightness: 16,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "transit",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: secondaryColorDark,
//       },
//       {
//         lightness: 19,
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "geometry",
//     stylers: [
//       {
//         // color: '#002432',
//         color: waterColor,
//       },
//       {
//         lightness: 5,
//         visibility: "off",
//       },
//     ],
//   },
// ];

const darkMapStyles = [
	{
		elementType: 'geometry',
		stylers: [
			{
				color: '#242f3e',
			},
		],
	},
	{
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#746855',
			},
		],
	},
	{
		elementType: 'labels.text.stroke',
		stylers: [
			{
				color: '#242f3e',
			},
		],
	},
	{
		featureType: 'administrative',
		elementType: 'geometry',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'administrative.locality',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#d59563',
			},
		],
	},
	{
		featureType: 'administrative.neighborhood',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels.text',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#d59563',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [
			{
				color: '#263c3f',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#6b9a76',
			},
		],
	},
	{
		featureType: 'road',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry',
		stylers: [
			{
				color: '#38414e',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#212a37',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#9ca5b3',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry',
		stylers: [
			{
				color: '#746855',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#1f2835',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#f3d19c',
			},
		],
	},
	{
		featureType: 'transit',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'geometry',
		stylers: [
			{
				color: '#2f3948',
			},
		],
	},
	{
		featureType: 'transit.station',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#d59563',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				color: '#17263c',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#ffeb3b',
			},
			{
				visibility: 'on',
			},
			{
				weight: 8,
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#515c6d',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.stroke',
		stylers: [
			{
				color: '#17263c',
			},
		],
	},
];
