/**
 * Old CreateTimeline
 *
 * @format
 */

// Gets a list of proposed available dates and venues (availableDatesAndVenues) from CreateTour
// then adds the manually picked dates and Venues (wantedList) to the list timeline.

import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { useDispatch, useSelector } from "react-redux";
import SimpleSelectAvailDates from "../../components/UI helpers/SimpleSelectAvailDates";
import { Avatar, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import WhatDayIsIt from "../../components/MiscUIComponents/WhatDayIsIt";
import Slide from "@mui/material/Slide";
import TourCalculation from "./TourCalculation";
import SendMultiRequest from "../../helpers/SendMultiRequest";
import FlatVenueCard from "./FlatVenueCard";
import { storeAvailableVenuesAndDates, storeWantedList } from "../venueFilter/venueFilterSlice";
import DeleteIcon from "@mui/icons-material/Delete";

export default function CreateTimeline() {
  const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);
  const wantedList = useSelector((state) => state.counter.wantedList);
  const timelineModal = useSelector((state) => state.counter.timelineModal);
  let tempData = [];
  const [displayData, setDisplayData] = useState([]);
  const dispatch = useDispatch();

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    async function mergeWantedAndProposed() {
      let uniqueData = [];

      // First add wantedList to tempData
      await wantedList.map((item, index) => {
        const newObject = { ...item };
        tempData.push(newObject);
      });

      // ...then add proposed from availableVenuesAndDates
      // await availableVenuesAndDates.map((item, index) => {
      //   const newObject = { ...item };
      //   tempData.push(newObject);
      // });

      // Remove duplicates
      const uniqueArray = tempData.reduce((acc, curr) => {
        const found = acc.find((item) => item.venueid === curr.venueid && item.proposedDate === curr.proposedDate);
        if (!found) {
          acc.push(curr);
        }
        return acc;
      }, []);

      //sort by date
      const sortedArray = uniqueArray.sort((a, b) => {
        return new Date(a.proposedDate) - new Date(b.proposedDate);
      });

      //lastly dispatch to redux
      // await dispatch(storeAvailableVenuesAndDates(sortedArray));
      setDisplayData(sortedArray);
    }
    // merge wantedList and availableVenuesAndDates into tempData.
    mergeWantedAndProposed();
  }, [wantedList]);

  //Remove proposed from (A) availableVenuesAndDates and (B) wantedList
  const removeProposed = async (venueid, proposedDate) => {
    // const removeA = availableVenuesAndDates.filter((item) => item.venueId !== venueid || item.proposedDate !== proposedDate);
    // dispatch(storeAvailableVenuesAndDates(removeA));

    const removeB = wantedList.filter((item) => item.venueid !== venueid || item.proposedDate !== proposedDate);
    console.log(venueid, proposedDate);
    console.log("wantedList", wantedList);
    dispatch(storeWantedList(removeB));
  };

  return (
    <div>
      <h3>Tour</h3>
      <div style={{ marginBottom: "50px" }}>
        <TourCalculation />
      </div>
      <div style={{ position: "relative" }}>
        {/* {availableVenuesAndDates.map((venue, index) => { */}
        {displayData.map((venue, index) => {
          return (
            <div className="tl">
              {venue.proposedDate && (
                <h6 className="tlDate">
                  {venue.proposedDate} <WhatDayIsIt numberOfLetters={9} date={venue.proposedDate} />
                </h6>
              )}
              <FlatVenueCard venue={venue} />
              <div className="timeLineLine"></div>
              {venue.proposedDate && (
                <Button className="removeProposed" size="small" onClick={() => removeProposed(venue.venueid, venue.proposedDate)}>
                  <DeleteIcon />
                </Button>
              )}
            </div>
          );
        })}
      </div>
      {wantedList.length > 0 && <SendMultiRequest />}
    </div>
  );
}
