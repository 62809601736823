/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Switch } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { countries } from "../../dbObjects.js/dbCountries";
import VatSelector from "../MiscUIComponents/VatSelector";

const CateringModule = ({ featureColor, feature, handleSelect, handleChangeValue, getIcon }) => {
  const [calculatedPrice, setCalculatedPrice] = useState(0);

  function calculatePrice(featureId, optionId, field, value) {
    const each = feature.options[optionId].each;
    const quantity = feature.options[optionId].quantity;

    setTimeout(() => {
      handleChangeValue(featureId, optionId, "price", each * quantity);
    }, 100);
    // handleChangeValue(featureId, optionId, "price", value);
    // handleChangeValue(featureId, optionId, "price", feature.options[optionId].each * feature.options[optionId].quantity);
    // handleChangeValue(featureId, optionId, "price", each * quantity);
  }

  function handleOnFocus(event) {
    event.target && event.target.select();
  }

  return (
    <>
      <h6 style={{ textAlign: "left", color: featureColor }}>{getIcon(feature.additional)} Catering</h6>
      <table>
        <thead>
          <th>Select</th>
          <th>Option</th>
          {/* <th>Quantity</th> */}
          <th>Price</th>
          <th>Vat</th>
          {/* <th>Sum</th> */}
          <th>CO2e</th>
        </thead>
        {feature.options &&
          Object.values(feature.options).map((option) => (
            <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
              <td>
                <Switch
                  onChange={(event) => handleSelect(event.target.checked, feature.additionalId, option.optionId)}
                  checked={option.selected ? true : false}
                />
              </td>
              <td>{option.option}</td>
              {/* <td>
                <input
                  disabled={!option.selected}
                  type="number"
                  // value={option.quantity ? option.quantity : 0}
                  value={option.quantity}
                  onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "quantity", e.target.value)}
                  onBlur={(e) => calculatePrice(feature.additionalId, option.optionId)}
                  //   onChange={() => calculatePrice(feature.additionalId, option.optionId)}
                />
              </td> */}
              <td>
                <input
                  disabled={!option.selected}
                  type="number"
                  value={option.each}
                  defaultValue={0}
                  onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "each", e.target.value)}
                  //   onChange={(e) => calculatePrice(feature.additionalId, option.optionId, "each", e.target.value)}
                  onBlur={(e) => calculatePrice(feature.additionalId, option.optionId)}
                  onFocus={handleOnFocus}
                />
              </td>
              <td>
                {/* <input
                  disabled={!option.selected}
                  type="number"
                  value={option.vat}
                  onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "vat", e.target.value)}
                /> */}
                <VatSelector handleChangeOption={handleChangeValue} value={option.vat} item={feature} optionId={option.optionId} />
              </td>
              {/* <td>
                <input
                  disabled={false}
                  type="number"
                  value={option.price}
                  //   onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "price", option.each * option.quantity)}
                />
              </td> */}
              <td>{option.climate}</td>
            </tr>
          ))}
      </table>
      {/* </AccordionDetails> */}
      {/* </Accordion> */}
    </>
  );
};

export default CateringModule;
