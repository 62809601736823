/** @format */

import { Grid } from "@mui/material";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import jsPDF from "jspdf";
import React from "react";
import { site } from "../../utils/site";

const SendInvoiceEmail = async (booking, paymentId, clientId, invoiceUrl) => {
  /** @format */
  alert("seeennidng");
  const { db } = require("../../firebase");
  const currentYear = new Date().getFullYear();

  setTimeout(() => {
    console.log("booking for email and invoice", booking);
    console.log("email:", booking.agent.email);
    console.log("summaryTotal:", booking.summaryTotal);
    console.log("summary:", booking.summary);
    console.log("venue:", booking.venue.venue);
    console.log("paymentId", paymentId);
    console.log("clientId", clientId);
  }, 1000);

  let subject = "";
  let actionButton;
  if (booking && !booking.deductFromTickets) {
    subject = `Invoice - ${booking.venue.venue} ${booking.date}`;
    actionButton = `<a
    href="${site}/payment?paymentId=${paymentId}&clientId=${clientId}&venue=${booking.venue.venue}&date=${booking.date}"
    style="text-decoration: none"
  >
    <table cellpadding="0" cellspacing="0" border="0" bgcolor="#333333" style="border-radius: 2mm; margin: 20px">
      <tr>
        <td
          align="center"
          style="padding: 15px 30px; font-size: 18px; font-weight: bold; color: white; border: 1px solid #00ffd4;border-radius:5px;"
        >
          MAKE PAYMENT
        </td>
      </tr>
    </table>
  </a>`;
  }
  if (booking && booking.deductFromTickets) subject = `Settlement - ${booking.venue.venue} ${booking.date}`;

  let clickableLink = "";
  if (booking && !booking.deductFromTickets) clickableLink = "<button>Click to pay</button>";

  const msg = {
    to: [booking.agent.email],
    // to: "marcus.dernulf@venuehub.live",
    message: {
      subject: `${subject}`,
      text: "",
      html: `<body style="background:white">
      <table cellpadding="0" cellspacing="0" border="0" width="100%" >
        <tr>
          <td align="left" style="padding: 10px">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" >
              <tr>
                <td align="center" style="padding: 15px">
                  <table cellpadding="0" cellspacing="0" border="0" width="100%" >
                    
                    <tr style="background: linear-gradient(0deg, #111, #333);width:100%;">
                      <td align="center" style="margin-top:10px;padding: 15px; font-size: 18px; font-family: avenir;border-radius:10px;box-shadow:2px 2px 6px black">
                        <span style="font-size: 28px; font-weight: bold;color:#fff;text-decoration:none;">${subject}</span>
                        <table cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr>
                            <td align="center" style="color: #aaa; padding-top: 20px;;font-size:18px;">
                             </br>Here's a <a href="${invoiceUrl}" target="_blank" style='color:#00ffd4;font-weight:900;'>summary</a> regarding the event at ${booking.venue.venue} on ${booking.date}.
                            </td>
                          </tr>
                          <tr>
                            <td align="center" style="color: #aaa; padding-bottom: 0px;font-size:18px;">
                             </br>
                            </td>
                          </tr>
                          <tr>
                          <td align="center" style="color: #aaa; padding-bottom: 0px;font-size:18px;">
                          We have attached a PDF document for you in this email.
                          </td>
                          </tr>
                          <tr>
                            <td align="center" style="padding-top: 30px;">
                              ${actionButton}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td
            align="center"
            style="
              font-family: avenir;
              color: #777;
              padding: 20px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              position:absolute;
              bottom:20px;
            "
          >
          <a href="https://venuehub.live" style="text-decoration:none;color:#000;">
            © ${currentYear} Powered by 
            <img
            alt="Venuehub AB"
              src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&amp;token=517af342-186c-4317-9204-47580e024ce4"
              style="margin-left:20px;width: 24px; height: 24px; vertical-align: middle; margin-right: 5px"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&amp;token=afb33ecb-24d6-4158-8206-5254c01618a3"
              style="width: 100px; height: 17px; vertical-align: middle"
            /></br> </a>
            <br />
            
          </td>
        </tr>
      </table>
    </body>`,
      attachments: [
        {
          filename: `${subject}.pdf`,
          path: invoiceUrl,
        },
      ],
    },
  };
  //   setDoc(doc(db, "mail", booking["bookingid"]), { ...booking });
  setDoc(doc(db, "mail", `invoice${booking.documentid}`), { ...msg });
  //   setDoc(doc(db, "mail", booking.documentid), { ...msg });
  //   var mailid;
  //   await setDoc(doc(db, "mail", `${booking.documentid}-invoice`), { ...msg });
  //   addDoc(collection(db, "mail"), { ...msg }).then((docRef) => {
  //     mailid = docRef.id;
  //     // EmailSent("Email Sent!");
  //     console.log(`Document has been added successfully to mail! ${mailid}`);
  //   });
  //   .catch((error) => {
  //   console.log(error);
  //   });

  return (
    <Grid container p={4}>
      SendInvoiceEmail
    </Grid>
    // <Button variant="contained" color="primary" onClick={() => handleSend()} endIcon={<SendIcon fontSize="small" />}>
    //   Send
    // </Button>
  );
};

export default SendInvoiceEmail;
