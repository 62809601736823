/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import { Button, Avatar, Select, MenuItem, FormControl, Switch } from "@mui/material";
import SelectArtist from "../../helpers/SelectArtist";
import { Chart, PieSeries, Title } from "@devexpress/dx-react-chart-material-ui";
import { Animation, Palette } from "@devexpress/dx-react-chart";

// Pie data
const randomCircle = ((Math.floor(Math.random() * (100 - 20 + 1)) + 20) * 62.8319) / 100 + ", 62.8319";

const columns = [
  { field: "id", headerName: "ID", width: 60 },
  //   {
  //     field: "date",
  //     headerName: "Date",
  //     type: "text",
  //     width: 110,
  //     editable: true,
  //   },
  {
    field: "artistImage",
    headerName: "Image",
    type: "image",
    sortable: false,
    // renderCell: (params) => <img style={{ width: "40px", height: "40px", borderRadius: "20px" }} src={params.value} />,
    renderCell: (params) => <Avatar alt="Remy Sharp" src={params.value} sx={{ marginRight: "15px", float: "left", border: "1px solid" }} />,
    width: 100,
  },
  //   {
  //     field: "artist",
  //     headerName: "Artist",
  //     width: 150,
  //     editable: true,
  //   },
  {
    field: "agent",
    headerName: "Contact",
    width: 150,
    editable: true,
  },
  {
    field: "agentEmail",
    headerName: "Email",
    type: "text",
    sortable: false,
    width: 160,
  },
  //   {
  //     field: "agentid",
  //     headerName: "id",
  //     type: "text",
  //     sortable: false,
  //     width: 160,
  //   },
  {
    field: "discount",
    headerName: "Discount",
    type: "checkbox",
    sortable: false,
    renderCell: (params) => <>{discountSelector()}</>,
    width: 115,
  },
  {
    field: "graphA",
    headerName: "Share",
    type: "checkbox",
    renderCell: (params) => (
      <svg className="newPie" width="40" height="40">
        <circle stroke="rgba(73, 249, 255, 0.3)" stroke-dasharray="62.8319, 62.8319" />
        <circle
          stroke="rgb(73, 249, 255)"
          stroke-dasharray={((Math.floor(Math.random() * (100 - 0 + 1)) + 0) * 62.8319) / 100 + ", 62.8319"}
        />
      </svg>
    ),
    width: 80,
  },
  {
    field: "graphB",
    headerName: "Sales Trend",
    type: "checkbox",
    renderCell: (params) => (
      <svg className="newPie" width="40" height="40">
        <circle stroke="rgba(73, 249, 255,0.3)" stroke-dasharray="62.8319, 62.8319" />
        <circle
          stroke="rgb(173, 249, 55)"
          stroke-dasharray={((Math.floor(Math.random() * (100 - 0 + 1)) + 0) * 62.8319) / 100 + ", 62.8319"}
        />
      </svg>
    ),
    width: 80,
  },
  {
    field: "graphC",
    headerName: "Success Rate",
    type: "checkbox",
    renderCell: (params) => (
      <svg className="newPie" width="40" height="40">
        <circle stroke="rgba(173, 50, 55, 0.3)" stroke-dasharray="62.8319, 62.8319" />
        <circle
          stroke="rgb(173, 50, 55)"
          stroke-dasharray={((Math.floor(Math.random() * (100 - 0 + 1)) + 0) * 62.8319) / 100 + ", 62.8319"}
        />
      </svg>
    ),
    width: 80,
  },
  //   {
  //     field: "price",
  //     headerName: "Price",
  //     type: "number",
  //     sortable: false,
  //     width: 100,
  //   },
  {
    field: "preConfirmed",
    headerName: "Auto",
    type: "checkbox",
    renderCell: (params) => <>{preConfirmed()}</>,
    sortable: true,
    editable: true,
    width: 100,
  },
  {
    field: "numberofevents",
    headerName: "Bookings",
    type: "number",
    sortable: true,
    width: 60,
  },
];

const discountSelector = () => {
  return (
    <FormControl fullWidth>
      <Select defaultValue={0} sx={{ width: "100px", background: "#000" }} size="small">
        <MenuItem value={0}>0 %</MenuItem>
        <MenuItem value={5}>5 %</MenuItem>
        <MenuItem value={10}>10 %</MenuItem>
        <MenuItem value={15}>15 %</MenuItem>
        <MenuItem value={20}>20 %</MenuItem>
        <MenuItem value={25}>25 %</MenuItem>
        <MenuItem value={30}>30 %</MenuItem>
        <MenuItem value={40}>40 %</MenuItem>
        <MenuItem value={50}>50 %</MenuItem>
      </Select>
    </FormControl>
  );
};

let tempBookings = [];

const preConfirmed = (agentid) => {
  return (
    <FormControl>
      <Switch size="small" sx={{ transform: "translateX(-50%, 50%)" }} onChange={handleChange} value={agentid}></Switch>
    </FormControl>
  );
};

const handleChange = (e) => {
  console.log(e);
};

export default function CustomersListed() {
  const [data, setData] = React.useState();
  const [bookings, setBookings] = React.useState([{ Date: "2022-01-03", Artist: "Robyn" }]);
  const [agents, setAgents] = React.useState();
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  // Get bookings for chosen venue and add to tempVenue before dispatch below
  const [rows, setRows] = React.useState([]);

  const newPies = () => {
    return (
      <svg className="newPie" width="40" height="40">
        <circle stroke-dasharray="calc(50 * 62.8319 / 100), 62.8319" />
      </svg>
    );
  };

  //get bookings
  React.useEffect(() => {
    const getData = async () => {
      const q = await query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
      const data = await getDocs(q);
      tempBookings = data.docs.map((doc) => doc.data());
      console.log(bookings);
      setBookings(tempBookings);
    };
    getData();
  }, [currentVenue.venueid]);

  //get agents
  React.useEffect(() => {
    const getData = async () => {
      let tempAgents = [];
      const q = await query(collection(db, "Agents"), where("agentid", "array-contains-any", bookings.agentid));
      const data = await getDocs(q);
      tempAgents = data.docs.map((doc) => doc.data());
      console.log(tempAgents);
      setAgents(data.docs.map((doc) => doc.data()));
    };
    getData();
  }, [currentVenue.venueid]);

  //Setup rows data
  React.useEffect(() => {
    let temp = [];
    let agentsListed = [...new Map(bookings.map((e) => [e.agentid, e])).values()];

    agentsListed.map((b, id) => {
      let sum = 0;
      bookings.forEach((booking) => booking.agentid === b.agentid && sum++);
      console.log(sum);
      temp.push({
        id: id,
        artist: b.artist,
        agent: b.agent,
        date: b.date,
        agentEmail: b.agentEmail,
        agentid: b.agentid,
        artistImage: b.artistImage,
        price: b.price,
        numberofevents: sum,
      });
    });
    console.log(temp);
    console.log("agents", agents);
    setRows(temp);
  }, [bookings]);

  return (
    <Box sx={{ height: "70vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
