/** @format */

import React, { useState } from "react";

/** @format */

export async function createPayout({ body, setPaymentId, setClientId }) {
  var payoutBody = {
    description: `Settlement ${body.externalReference}`,
    amount: {
      currency: "SEK",
      value: body.amount.value,
    },
    creditor: {
      iban: "SE1060000000000515034762",
      bban: "6154515034762",
      name: "Marcus Dernulf",
      personalNumber: "7201260192",
      email: "mdernulf@gmail.com",
    },
    externalReference: body.externalReference,
  };

  var paymentId = "";
  // var at = await getToken();
  var accessToken = "";

  // Create accessToken to authorize API call in createPayout
  // function getToken() {
  // 	var my1Headers = new Headers();
  // 	my1Headers.append('Content-Type', 'application/x-www-form-urlencoded');

  // 	var urlencoded = new URLSearchParams();
  // 	urlencoded.append(
  // 		'client_id',
  // 		process.env.REACT_APP_CLIENT_ID_FINSHARK,
  // 	);
  // 	urlencoded.append(
  // 		'client_secret',
  // 		process.env.REACT_APP_CLIENT_SECRET_FINSHARK,
  // 	);
  // 	urlencoded.append('grant_type', 'client_credentials');

  // 	var requestOptions = {
  // 		method: 'POST',
  // 		headers: my1Headers,
  // 		body: urlencoded,
  // 		redirect: 'follow',
  // 	};

  // 	fetch('https://auth.finshark.io/connect/token', requestOptions)
  // 		.then((response) => response.json())
  // 		.then((result) => {
  // 			// console.log(result.access_token);
  // 			accessToken = result.access_token;
  // 			createPayoutId(accessToken);
  // 		})
  // 		.catch((error) => console.log('error', error));
  // }

  // Create Payout
  function createPayoutId() {
    var my3Headers = new Headers();
    my3Headers.append("Content-Type", "application/json");
    my3Headers.append("Authorization", `Bearer ${accessToken}`);

    var bodyData = JSON.stringify(payoutBody);
    // '{\n    id: "",\n    clientId: "",\n    ClientAccess: "SANDBOX",\n    status: "CREATED",\n    amount: {\n      currency: "SEK",\n      value: 1,\n    },\n    chargeBearer: "SHAR",\n    creditor: {\n      account: {\n        accountNumber: "SE2550000000054400047903",\n        accountType: "IBAN",\n        bic: "ESSESESS",\n        currencyCode: "SEK",\n      },\n      address: {\n        city: "Stockholm",\n        street: "Landsvagen 39",\n        country: "Sweden",\n      },\n      message: "Tjena biffen",\n      name: "Peter Olof",\n    },\n    debtor: {\n      account: {\n        accountNumber: "string",\n        accountType: "IBAN",\n        bic: "string",\n        currencyCode: "string",\n      },\n      email: "string",\n      message: "172800000",\n      name: "string",\n      phoneNumber: "string",\n    },\n    externalReference: "string",\n    remittanceInformation: {\n      value: "Darfor betalar du...",\n      type: "UNSTRUCTURED",\n    },\n    region: "SE",\n    paymentProductId: "se-domestic-credit-transfer",\n    paymentTemplateId: "string",\n    settlementCreditors: [\n      {\n        accountNumber: "string",\n        accountType: "IBAN",\n        bic: "string",\n        amount: 1,\n        currencyCode: "string",\n        name: "string",\n      },\n    ],\n  }';

    var requestOptions = {
      method: "POST",
      headers: my3Headers,
      body: bodyData,
      redirect: "follow",
    };

    fetch("https://api.finshark.io/v1/payouts", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        // setPaymentId(result.id);
        // setClientId(result.clientId);
      })
      .catch((error) => console.log("error", error));
  }
}
