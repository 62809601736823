/** @format */

import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const GetEventImageFromGoogle = ({ query, setEventImage, eventImage }) => {
  const [results, setResults] = useState([]);
  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const GOOGLE_CX = process.env.REACT_APP_GOOGLE_CX;
  const [newQuery, setNewQuery] = useState(query);

  const searchGoogle = async () => {
    const response = await fetch(
      `https://www.googleapis.com/customsearch/v1?key=${GOOGLE_API_KEY}&cx=${GOOGLE_CX}&q=${newQuery}&searchType=image`
    );
    const data = await response.json();

    const topResults =
      data.items &&
      data.items.slice(0, 10).map((item) => {
        return {
          title: item.title && item.title,
          link: item.link && item.link,
          snippet: item.snippet && item.snippet,
          image: item.link && item.link,
        };
      });
    console.log(data);
    setResults(topResults);
  };

  useEffect(() => {
    searchGoogle();
  }, []);

  useEffect(() => {
    // results.length && setEventImage(results[1].link);
  }, [results]);

  function newSearch(value) {
    setNewQuery(value);
    setTimeout(() => {
      searchGoogle();
    }, 100);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "500px",
          overflowX: "scroll",
          border: "1px dashed #444",
          borderRadius: "5px",
        }}
      >
        {/* {eventImage && (
        <div>
          <img src={eventImage} />
        </div>
      )} */}
        {results &&
          !eventImage &&
          results.map((item, index) => (
            <div
              style={{ width: "100px", height: "100px", background: "#222", borderRadius: "5px", boxShadow: "5px 5px 5px #000" }}
              onClick={() => setEventImage(results[index].link)}
            >
              {/* {item.link} */}
              <img src={item.link} height="100px" width="100px" />
            </div>
          ))}
      </div>
      <TextField placeholder="🔍 Search image" onChange={(e) => newSearch(e.target.value)} size="small" style={{ marginTop: "10px" }} />
    </div>
  );
  //   <Button onClick={() => searchGoogle()}>Get Event Image From Google</Button>;
};

export default GetEventImageFromGoogle;
