/** @format */

import React, { useState, useEffect } from "react";
import { Grid, Divider, IconButton, CircularProgress } from "@mui/material";
import { MonthNavigator } from "../MiscUIComponents/MonthNavigator";
import { ConfirmedBookings } from "../MiscUIComponents/ConfirmedBookings";
import { AwaitingResponseBooking } from "../MiscUIComponents/AwaitingResponseBooking";
import { AwaitingArtistResponseBooking } from "../MiscUIComponents/AwaitingArtistResponseBooking";
import { Event } from "@mui/icons-material";
import { BookingsByMonthContainer } from "../Containers/BookingsByMonthContainer";
import { useSelector, useDispatch } from "react-redux";
import { storeCurrentBookings, storeCurrentSettings } from "../../features/venueFilter/venueFilterSlice";
import { getWeek } from "date-fns";
import { BookingsByWeekContainer } from "../Containers/BookingsByWeekContainer";

const VenueCalendar = (props) => {
  const cs = useSelector((state) => state.counter.currentSettings);

  const [selectedMonth, setSelectedMonth] = useState(cs.vcMonth);
  const [selectedYear, setSelectedYear] = useState(cs.vcYear);
  const [loading, setLoading] = useState(false);
  // const [eventsInSelectedMonth, setEventsInSelectedMonth] = useState([]);
  const [showBookingStatusType, setShowBookingStatusType] = useState(null);
  const [eventsByStatus, setEventsByStatus] = useState([]);
  const [eventsByWeek, setEventsByWeek] = useState([]);
  const [weeklyMode, setWeeklyMode] = useState(false);
  const [calendarMode, setCalendarMode] = useState(false);
  const { currentVenue, currentBookings } = useSelector((state) => state.counter);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  // console.log("currentVenue", currentVenue);
  const dispatch = useDispatch();

  const getEventsInMonth = (eventsArray, month, year) => {
    return eventsArray.filter((event) => {
      const eventDate = new Date(event.date);
      return eventDate.getMonth() === month && eventDate.getFullYear() === year;
    });
  };

  const convertEventByMonthToWeeks = () => {
    const weeks = [];

    currentBookings.forEach((event) => {
      const date = new Date(event.date);
      const week = getWeek(date);

      let weekExists = false;
      weeks.forEach((w) => {
        if (w.week === week) {
          w.events.push(event);
          weekExists = true;
        }
      });

      if (!weekExists) {
        weeks.push({
          week: week,
          events: [event],
        });
      }
    });

    return weeks;
  };

  function countEventsByStatus(events) {
    const counts = {
      Confirmed: 0,
      "Pending Request": 0,
      "Awaiting Artist": 0,
    };

    events.forEach((event) => {
      if (event.status in counts) {
        counts[event.status]++;
      }
    });
    return counts;
  }

  useEffect(() => {
    if (currentVenue.venueid === "none") return;
    setLoading(true);
    // if (currentVenue.bookings) {
    //   const filteredBookings = currentVenue.bookings.filter((booking) => {
    if (currentVenueBookings) {
      const filteredBookings = currentVenueBookings.filter((booking) => {
        if (showBookingStatusType) {
          return booking.status === showBookingStatusType;
        } else return booking;
      });
      const uniqueBookings = filteredBookings.filter((booking, index, self) => {
        return self.findIndex((b) => b.bookingid === booking.bookingid) === index;
      });
      console.log("starting dispatch", uniqueBookings);

      async function sortOrderAndDispatch() {
        await dispatch(
          storeCurrentBookings(
            //Sort by "date" and then by "venueSignature" and lastly by "created"
            // getEventsInMonth(uniqueBookings, selectedMonth, selectedYear).sort((a, b) => {
            getEventsInMonth(currentVenueBookings, selectedMonth, selectedYear).sort((a, b) => {
              // Compare the date properties
              const dateComparison = a.date.localeCompare(b.date);

              if (dateComparison !== 0) {
                // If the dates are not equal, return the comparison result
                return dateComparison;
              } else {
                // Compare the venueSignature properties (exist or null)
                const aHasVenue = !!a.venueSignature;
                const bHasVenue = !!b.venueSignature;

                if (aHasVenue !== bHasVenue) {
                  // If only one has a venueSignature (null or not null), return the comparison result
                  return aHasVenue ? -1 : 1;
                } else {
                  // If both have the same venueSignature status, compare the created properties
                  return a.created.localeCompare(b.created);
                }
              }
            })
          )
        );
        setLoading(false);
      }
      sortOrderAndDispatch();
    }
  }, [selectedMonth, selectedYear, showBookingStatusType, currentVenue]);

  useEffect(() => {
    setEventsByStatus(countEventsByStatus(currentBookings));
  }, [currentBookings, eventsByWeek]);

  useEffect(() => {
    if (weeklyMode) {
      setEventsByWeek(convertEventByMonthToWeeks());
    } else {
      setEventsByWeek([]);
    }
  }, [currentBookings, weeklyMode]);

  useEffect(() => {
    dispatch(storeCurrentSettings({ ...cs, vcMonth: selectedMonth, vcYear: selectedYear }));
    console.log("dispatching", cs, selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  return (
    <Grid container sx={{ padding: "10px 20px 0 20px" }}>
      {console.log("currentVenue", currentVenue)}
      <Grid container>
        <Grid container xs={8} sx={{}}>
          {/* <span>{loading && <CircularProgress />}</span> */}
          <Grid item>
            <MonthNavigator
              setSelectedMonth={setSelectedMonth}
              setSelectedYear={setSelectedYear}
              weeklyMode={weeklyMode}
              setWeeklyMode={setWeeklyMode}
              eventsByWeek={eventsByWeek}
              setEventsByWeek={setEventsByWeek}
            />
          </Grid>
        </Grid>
        <Grid container xs={3} spacing={1} justifyContent="flex-end">
          <Grid item>
            <ConfirmedBookings
              noConfirmed={eventsByStatus.Confirmed}
              showBookingStatusType={showBookingStatusType}
              setShowBookingStatusType={setShowBookingStatusType}
            />
          </Grid>
          <Grid item>
            <AwaitingArtistResponseBooking
              noAwaitingResponse={eventsByStatus["Awaiting Artist"]}
              showBookingStatusType={showBookingStatusType}
              setShowBookingStatusType={setShowBookingStatusType}
            />
          </Grid>
          <Grid item>
            <AwaitingResponseBooking
              noPendingRequest={eventsByStatus["Pending Request"]}
              showBookingStatusType={showBookingStatusType}
              setShowBookingStatusType={setShowBookingStatusType}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="middle" sx={{ backgroundColor: "#FFFFFF", height: "2px" }} />
      </Grid>
      <Grid container sx={{ marginTop: "20px" }}>
        {weeklyMode ? <BookingsByWeekContainer eventsByWeek={eventsByWeek} /> : <BookingsByMonthContainer />}
      </Grid>
    </Grid>
  );
};
export default VenueCalendar;
