/** @format */

/** @format */

import React from "react";

class VenueRating extends React.Component {
  getRandomStars() {
    // let stars = Math.floor(Math.random() * 3) + 3;
    const stars = 5;
    let str = "";
    for (let i = 0; i < stars; i++) {
      str += "★";
    }
    return str;
  }

  render() {
    return <div style={{ color: "orange" }}>{this.getRandomStars()}</div>;
  }
}

export default VenueRating;

// import React from "react";

// class VenueRating extends React.Component {
//   getRandomRating() {
//     let rating = Math.floor(Math.random() * 3) + 3;
//     let decimal = Math.floor(Math.random() * 9) + 1;
//     return Number(rating + "." + decimal).toFixed(1);
//   }

//   getStars() {
//     let rating = this.getRandomRating();
//     let stars = Math.floor(rating);
//     let decimal = Math.floor(rating * 10) % 10;
//     let str = "";
//     for (let i = 0; i < stars; i++) {
//       str += "★";
//     }
//     if (decimal) {
//       str += "☆";
//     }
//     return str;
//   }

//   render() {
//     return (
//       <div>
//         {this.getRandomRating()} {this.getStars()}
//       </div>
//     );
//   }
// }

// export default VenueRating;

// import React from "react";

// class VenueRating extends React.Component {
//   getRandomRating() {
//     let rating = Math.floor(Math.random() * 3) + 2;
//     let decimal = Math.floor(Math.random() * 9) + 1;
//     return Number(rating + "." + decimal).toFixed(1);
//   }

//   getStars() {
//     let rating = this.getRandomRating();
//     let stars = Math.floor(rating);
//     let decimal = Math.floor(rating * 10) % 10;
//     let str = "";
//     for (let i = 0; i < stars; i++) {
//       str += "★";
//     }
//     if (decimal) {
//       str += "☆";
//     }
//     return str;
//   }

//   render() {
//     return (
//       <div>
//         {this.getStars()} {this.getRandomRating()}
//       </div>
//     );
//   }
// }

// export default VenueRating;
