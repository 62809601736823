/** @format */

import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import { addDoc, collection, doc, setDoc, query, where, getDocs } from "firebase/firestore";
import { connectStorageEmulator } from "firebase/storage";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import LoginMUI from "../components/Authentication/LoginMUI";
import { premisesObj } from "../dbObjects.js/dbPremises";
import GreenStepper from "../features/owner/greenDeal/GreenStepper";
import { db } from "../firebase";
import DisplayObject from "../helpers/displayObject";
import GeneralForm from "../helpers/GeneralForm";

const SustainabilityForm = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const [formData, setFormData] = useState({});
  const [openLogin, setOpenLogin] = useState({});
  const [thankYou, setThankYou] = useState("");
  const [open, setOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const handleChange = (key, value) => {
    // console.log(key, value);
    // console.log(formData);
    key === "email" && getDataBasedOnEmail(value);
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  async function handleSave() {
    await setDoc(doc(db, "SustainabilityForm", formData.venue), formData);
    await sendEmail();
    setThankYou("Thank you!");
    setOpen(true);
  }

  // Snackbar
  const handleClose = () => {
    setOpen(false);
    navigate("/Home");
  };

  //Get form data if email exists
  async function getDataBasedOnEmail(email) {
    const q = query(collection(db, "SustainabilityForm"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setFormData(doc.data());
    });
  }

  //Send email to respondent
  const sendEmail = async () => {
    function createHtmlTable(formData) {
      let tableHtml = `<table><tbody>
      <h2>Thank you for submitting data</h2></br>
      <b>Below is a quick extraction of the data submitted:</b></br>
      Feel free to continue adding additional data to this form. Click <b><a href="https://venuehub.live/SustainabilityForm">HERE</a></b> and enter the same email address in the form to fetch you stored data and continue.</br></br>`;

      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          tableHtml += `
            <tr>
              <td><b style="opacity:0.5;">${key}</b></td>
              <td>${formData[key]}</td>
            </tr>
          `;
        }
      }
      tableHtml += "</tbody></table>";
      return tableHtml;
    }

    const msg = {
      // to: [formData.email],
      to: `${formData.email},mail@venuehub.live`,
      message: {
        subject: `Sustainability Form submitted`,
        text: "This is the plaintext section of the email body.",
        html: createHtmlTable(formData),
      },
    };
    await addDoc(collection(db, "mail"), { ...msg });
  };

  useEffect(() => {
    // setFormData((previous) => ({ ...previous, electricityUse: 23000 }));
  }, [formData]);

  return (
    <Grid style={{ height: "100vh", width: "100vw", overflow: "scroll" }}>
      <Grid container xs={12} p={10} style={{ display: "flex", gap: "15px" }} justifyContent="center" alignItems="center">
        {currentUser.userRole !== "Sustainability" && (
          <Grid>{/* <LoginMUI setOpenLogin={setOpenLogin} openLogin="true" userName="mail@venuehub.live" /> */}</Grid>
        )}
        <Grid item>
          {/* <h1>Certificates</h1> */}
          <h2>Sustainability Form</h2>
        </Grid>
        <Grid item xs={12} mt={4}>
          {/* <GreenStepper /> */}
        </Grid>

        <Grid item xs={12} sm={6} style={{ display: "flex", gap: "15px" }} flexDirection="column">
          <h4>Premises</h4>
          <Grid item flexDirection="row" style={{ display: "flex", gap: "15px" }}>
            <TextField
              label="Venue"
              required
              error={!formData.venue}
              value={formData.venue}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange("venue", e.target.value)}
            />
            <TextField
              label="Reporting year"
              value={currentYear}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange("year", e.target.value)}
            />
            <TextField
              label="Square meter area of the venue"
              type="number"
              value={formData.propertySize}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange("propertySize", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">m2</InputAdornment>,
              }}
            />
            <TextField
              label="Visitor capacity"
              defaultValue={currentVenue.capacity}
              value={formData.capacity}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange("capacity", e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              required
              error={!formData.venue}
              defaultValue={formData.email ? formData.email : ""}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={(e) => handleChange("email", e.target.value)}
            />
          </Grid>
          <h4>Electricity</h4>
          <h6>1.1 Electricity use</h6>
          Purchased electrcitiy during the reporting year
          <Slider
            name={"electricityUse"}
            value={formData.electricityUse ? formData.electricityUse : 1000}
            label="Purchased electrcitiy during the reporting year"
            min={1000}
            max={200000}
            step={1000}
            marks
            valueLabelDisplay={formData.electricityUse === "not available" ? "off" : "on"}
            valueLabelFormat={(v) => `${v.toLocaleString()} kWh`}
            onChange={(e, newValue) => handleChange("electricityUse", newValue)}
            style={{ width: "300px", marginTop: "30px" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.electricityUse === "not available"}
                onChange={(e) => handleChange("electricityUse", "not available")}
              />
            }
            label="I don't know"
          />
          <h6>1.2 Electricity source</h6>
          If you purchase electricity from renewable sources such as wind, hydro or solar, provide the answer in the field below. Statements
          should be backed up by evidence from the energy supplier, with a so called GO (garantuee of origin) or REC (Renewable energy
          certificate)
          <FormControl variant="outlined">
            <InputLabel shrink>Type of electricity</InputLabel>
            <Select
              value={formData.electricitySource ? formData.electricitySource : 0}
              onChange={(e) => handleChange("electricitySource", e.target.value)}
              style={{ minWidth: "200px" }}
            >
              <MenuItem value="Renewable">Renewable electricity</MenuItem>
              <MenuItem value="Non renewable">Non renewable electricity</MenuItem>
              <MenuItem value="Dont know">Dont know</MenuItem>
            </Select>
          </FormControl>
          <h6>1.3 On-site produced electricity </h6>
          The quantity of on-site generated electricity, from for example solar energy.
          <Slider
            name={"electricityGenerated"}
            label="Electrcitiy generated during the reporting year"
            value={formData.electricityGenerated ? formData.electricityGenerated : 1000}
            min={1000}
            max={200000}
            step={1000}
            marks
            valueLabelDisplay={formData.electricityGenerated === "not applicable" ? "off" : "on"}
            valueLabelFormat={(v) => `${v.toLocaleString()} kWh`}
            onChange={(e) => handleChange("electricityGenerated", e.target.value)}
            style={{ width: "300px", marginTop: "30px" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.electricityGenerated === "not applicable"}
                onChange={(e) => handleChange("electricityGenerated", "not applicable")}
              />
            }
            label="Not applicable"
          />
          <h4>Heating and fuel use</h4>
          Heating and on-site fuel use from the facility during the reporting year.
          <h6>2.1 Type and quantity of heating used</h6>
          Specifify the type of heating used for the facility and the quantity used for the reporting year
          <Grid item flexDirection="row" style={{ display: "flex", gap: "15px" }}>
            <FormControl variant="outlined">
              <InputLabel shrink>Type of heating</InputLabel>
              <Select
                value={formData.heatingUsed ? formData.heatingUsed : 0}
                onChange={(e) => handleChange("heatingUsed", e.target.value)}
                style={{ minWidth: "150px" }}
              >
                <MenuItem value="dont know">I don't know</MenuItem>
                <MenuItem value="not">Not applicable</MenuItem>
                <MenuItem value="district heating">District heating</MenuItem>
                <MenuItem value="natural gas">Natural gas</MenuItem>
                <MenuItem value="electricity heating">Electricity heating</MenuItem>
                <MenuItem value="lng">LNG</MenuItem>
                <MenuItem value="lpg">LPG</MenuItem>
                <MenuItem value="cng">CNG</MenuItem>
                <MenuItem value="burning oil">Burning oil</MenuItem>
                <MenuItem value="diesel">Diesel</MenuItem>
              </Select>
            </FormControl>
            {formData.heatingUsed !== ("dont know" || "not") && (
              <>
                <TextField
                  label="Quantity"
                  type="number"
                  value={formData.heatingQuantity}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleChange("heatingQuantity", e.target.value)}
                  style={{ width: "100px" }}
                />
                <FormControl variant="outlined">
                  <InputLabel shrink>Unit</InputLabel>
                  <Select
                    label="Unit"
                    value={formData.heatingUnit ? formData.heatingUnit : 0}
                    onChange={(e) => handleChange("heatingUnit", e.target.value)}
                    style={{ minWidth: "50px" }}
                  >
                    <MenuItem value="tonnes">Tonnes</MenuItem>
                    <MenuItem value="litres">Litres</MenuItem>
                    <MenuItem value="kWh">KWh</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </Grid>
          <h4>Refrigerants</h4>
          Refrigerants are used in air conditioning systems and heat pumps.
          <h6>3.1 Type and quantity of refrigerants refilled</h6>
          If known, specifiy the type if refrigerants used and the refilled amount during the reporting year
          <Grid item style={{ display: "flex", gap: "15px" }} flexDirection="row">
            <FormControl variant="outlined">
              <InputLabel shrink>Type</InputLabel>
              <Select
                value={formData.refrigerantType ? formData.refrigerantType : 0}
                onChange={(e) => handleChange("refrigerantType", e.target.value)}
                style={{ minWidth: "100px" }}
              >
                <MenuItem value="dont-know">I don't know</MenuItem>
                <MenuItem value="not-applicable">Not applicable</MenuItem>
                <MenuItem value="hfc-23">HFC-23</MenuItem>
                <MenuItem value="hfc-32">HFC-32</MenuItem>
                <MenuItem value="hfc-41">HFC-41</MenuItem>
                <MenuItem value="hfc-125">HFC-125</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Quantity"
              value={formData.refrigerantQuantity}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange("refrigerantQuantity", e.target.value)}
              style={{ width: "50%" }}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
            />
          </Grid>
          <h4>Water</h4>
          Water used on the premises during the reporting year.
          <h6>4.1 Quantity of water used on the premises</h6>
          <Slider
            name={"waterUse"}
            label="Quantity of water"
            value={formData.waterUse ? formData.waterUse : 1000}
            min={1000}
            max={200000}
            step={1000}
            marks
            valueLabelDisplay={formData.waterUse === "not available" ? "off" : "on"}
            valueLabelFormat={(v) => `${v.toLocaleString()} litres`}
            onChange={(e) => handleChange("waterUse", e.target.value)}
            style={{ width: "300px", marginTop: "30px" }}
          />
          <FormControlLabel
            control={
              <Checkbox checked={formData.waterUse === "not available"} onChange={(e) => handleChange("waterUse", "not available")} />
            }
            label="I don't know"
          />
          <h4>Waste</h4>
          Generated waste during the reporting year from the premises.
          <h6>5.1 Quantity of waste used on the premises per waste fraction</h6>
          <Grid item flexDirection="row" style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
            <TextField
              label="Recycled metal waste"
              type="number"
              variant="outlined"
              value={formData.recycledMetal}
              onChange={(e) => handleChange("recycledMetal", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ minWidth: "200px" }}
            />
            <TextField
              label="Recycled plastic waste"
              type="number"
              variant="outlined"
              value={formData.recycledPlastic}
              onChange={(e) => handleChange("recycledPlastic", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ minWidth: "200px" }}
            />
            <TextField
              label="Recycled paper waste"
              type="number"
              variant="outlined"
              value={formData.recycledPaper}
              onChange={(e) => handleChange("recycledPaper", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ minWidth: "200px" }}
            />
            <TextField
              label="Recycled electric waste"
              type="number"
              variant="outlined"
              value={formData.recycledElectric}
              onChange={(e) => handleChange("recycledElectric", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ minWidth: "200px" }}
            />
            <TextField
              label="Mixed household waste"
              type="number"
              variant="outlined"
              value={formData.mixedHousehold}
              onChange={(e) => handleChange("mixedHousehold", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ minWidth: "200px" }}
            />
            <TextField
              label="Landfilled waste"
              type="number"
              variant="outlined"
              value={formData.landfilledWaste}
              onChange={(e) => handleChange("landfilledWaste", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ minWidth: "200px" }}
            />
          </Grid>
          {/* <Grid item>
            <Grid item>Year: {formData.year}</Grid>
            <Grid item>Size: {formData.propertySize}</Grid>
            <Grid item>Capacity: {formData.capacity}</Grid>
            <Grid item>Electricity use: {formData.electricityUse}</Grid>
            <Grid item>Electricity source: {formData.electricitySource}</Grid>
            <Grid item>Electricity generated: {formData.electricityGenerated}</Grid>
            <Grid item>Heating used: {formData.heatingUsed}</Grid>
            <Grid item>
              Heating quantity: {formData.heatingQuantity} {formData.heatingUnit}
            </Grid>
            <Grid item>Refrigeration used: {formData.refrigerantType}</Grid>
            <Grid item>Refrigeration quantity: {formxData.refrigerantQuantity} kg</Grid>
            <Grid item>Water use quantity: {formData.waterUse} litres</Grid>
          </Grid> */}
          <Grid item flexDirection="row" style={{ display: "flex", gap: "15px", marginTop: "50px" }}>
            <Grid item xs={12}>
              {thankYou === "Thank you!" && (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Data submitted!">
                  <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    <h2>{thankYou}</h2>
                  </Alert>
                </Snackbar>
              )}
            </Grid>
            <Tooltip label="Make sure to enter Venue and Email">
              <Button onClick={() => handleSave()} variant="contained" disabled={!formData.email || !formData.venue}>
                Save
              </Button>
            </Tooltip>
            <Button onClick={() => navigate(-1)} variant="outlined">
              Cancel
            </Button>
          </Grid>
        </Grid>
        {/* <DisplayObject data={formData} /> */}
      </Grid>
    </Grid>
  );
};

export default SustainabilityForm;
