/** @format */

import { Button, Grid, TextField } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../firebase";

const MailingListFormExtended = () => {
  const [emailInput, setEmailInput] = useState("");
  const [companyInput, setCompanyInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const date = new Date();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, "MailingList"), {
      email: emailInput,
      company: companyInput,
      phone: phoneInput,
      timestamp: date,
    });
    setEmailInput("");
  };

  return (
    <Grid xs={10} sm={5} md={3}>
      <form onSubmit={handleSubmit}>
        {/* <h5 style={{ color: "#00373c" }}> */}
        <div style={{ fontFamily: "Anton", fontSize: "7vh", textTransform: "uppercase" }}>Run a venue?</div>

        <b style={{ color: "#00ffd4", fontSize: "18px" }}>[ Beta release due December 2023 ]</b>
        <br></br>
        <br></br>
        <h5 style={{ color: "#ffffff", fontWeight: "100" }}>
          <b>Sign up</b> with your email address to get a chance to be the first to experience Venuehub. <br></br>
          Don't miss out!<br></br>
        </h5>

        <TextField
          fullWidth
          type="text"
          label="Company"
          value={emailInput}
          placeholder="Enter Company"
          onChange={(e) => setCompanyInput(e.target.value)}
          sx={{ marginTop: "20px" }}
        />
        <TextField
          fullWidth
          type="email"
          label="Email"
          value={emailInput}
          placeholder="Enter Email"
          onChange={(e) => setEmailInput(e.target.value)}
          sx={{ marginTop: "20px" }}
        />
        <TextField
          fullWidth
          type="phone"
          label="Phone"
          value={emailInput}
          placeholder="Enter Phone Number"
          onChange={(e) => setPhoneInput(e.target.value)}
          sx={{ marginTop: "20px" }}
        />
        <br></br>
        <Button type="submit" variant="contained" sx={{ marginTop: "20px" }} fullWidth size="large">
          Submit
        </Button>
      </form>
      <br></br>

      <br></br>
      <h2 style={{ fontFamily: "Anton", textTransform: "uppercase" }}>The sustainable way to play</h2>
    </Grid>
  );
};

export default MailingListFormExtended;
