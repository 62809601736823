/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import { Button, CircularProgress } from "@mui/material";
import { wait } from "../MiscUIComponents/Wait";
// import { db, doc } from "../../firebase";
import { collection, doc, updateDoc } from "firebase/firestore";
import { set } from "date-fns";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../../firebase";
import { Download } from "@mui/icons-material";

function PdfHandler({ pdfDoc, setPdfUrl, includedAttachments, agreement }) {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const agreementId = agreement.agreementId;
  const pdfName = !agreement.agentSignature ? "Offert-" + agreementId : "Avtal-" + agreementId;

  let link = document.createElement("a");
  // const [link, setLink] = useState(null);
  const storage = getStorage();

  async function handlePDF() {
    setLoading(true);
    const htmlData = {
      html: `
      <style>

      .text {
        white-space: break-spaces;
      }

      .page-break {
        page-break-before: always;
      }
      
      table th, table td {
        text-align:left;
      }

      .categoryHeader{
      opacity:0.3;
      border: 1px solid black;
      }

      </style>
            <head>
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Anton&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Secular+One&family=Shrikhand&display=swap" rel="stylesheet">
      </head>
      <body>
        <div style="font-family: 'Roboto Condensed', sans-serif;">
        ${pdfDoc}
        </div>
        
      </div>
      </body>
            `,
    };

    try {
      // Using Axios to send a POST request
      const response = await axios.post("https://europe-west3-venuehub-64e72.cloudfunctions.net/generatePdf", htmlData, {
        responseType: "blob", // Important: Set the response type to 'blob' for PDF download
      });
      console.log(response);

      // Creating a Blob for the PDF and generating a download link
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);
      console.log("blob", blob);
      //   const link = document.createElement("a");
      link.href = downloadUrl;
      // link.setAttribute("download", agreementId + ".pdf"); // Name the download file
      link.setAttribute("download", pdfName + ".pdf"); // Name the download file
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // const storageRef = ref(storage, `agreements/${agreementId}.pdf`);
      const storageRef = ref(storage, `agreements/${pdfName}.pdf`);
      // Upload the PDF to Firebase Storag
      if (blob.size > 10000) storePDF();
      async function storePDF() {
        const snapshot = await uploadBytes(storageRef, blob);
        const pdfUrl = await getDownloadURL(snapshot.ref);
        setPdfUrl(pdfUrl);
        await updateDoc(doc(db, "Agreements", agreementId), {
          agreementPdf: pdfUrl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    }
  }

  function downloadPDF() {
    console.log("clicked");
    link.click();
  }

  return (
    <>
      <div>
        {/* <Button onClick={downloadPDF} size="small"> */}
        <Button onClick={() => handlePDF()} size="small">
          {loading && <CircularProgress style={{ zoom: "0.4", marginRight: "20px" }} />}
          {!loading ? (
            <>
              <Download style={{ marginRight: "10px" }} /> Download PDF
            </>
          ) : (
            "Creating PDF..."
          )}
        </Button>
      </div>

      {/* <div>
        {includedAttachments.length > 0 && (
          <div>
            {includedAttachments.map((attachment) => (
              <div>
                {attachment.url.includes(".pdf") ? (
                  <>
                    🩻{" "}
                    <a href={attachment.url} style={{ color: "black" }}>
                      {attachment.name}
                    </a>
                  </>
                ) : attachment.url.includes(".doc") || attachment.url.includes(".docx") ? (
                  <>
                    🩻{" "}
                    <a href={attachment.url} style={{ color: "black" }}>
                      {attachment.name}
                    </a>
                  </>
                ) : attachment.url.includes(".xls") || attachment.url.includes(".xlsx") ? (
                  <>
                    🩻{" "}
                    <a href={attachment.url} style={{ color: "black" }}>
                      {attachment.name}
                    </a>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div> */}
    </>
  );
}

export default PdfHandler;
