/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_CX = process.env.REACT_APP_GOOGLE_CX;

const SetImage = ({ setEvent, showSelected, incomingQuery }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [expanded, setExpanded] = useState(false);

  const searchGoogle = async () => {
    const response = await fetch(
      `https://www.googleapis.com/customsearch/v1?key=${GOOGLE_API_KEY}&cx=${GOOGLE_CX}&q=${query}&searchType=image`
    );
    const data = await response.json();

    const topResults = data.items.slice(0, 10).map((item) => {
      return {
        title: item.title && item.title,
        link: item.link && item.link,
        snippet: item.snippet && item.snippet,
        image: item.link && item.link,
      };
    });
    console.log(data);
    setResults(topResults);
  };

  const handleValue = (value) => {
    setQuery(value);
  };

  useEffect(() => {
    setQuery(incomingQuery);
  }, [incomingQuery]);

  useEffect(() => {
    if (query === "") {
      setResults([]);
      return;
    }
    setTimeout(() => {
      searchGoogle();
    }, 2000);
  }, [query]);

  useEffect(() => {
    setTimeout(() => {
      setExpanded(true);
    }, 1000);
  }, [results]);

  useEffect(() => {
    setTimeout(() => {
      setExpanded(false);
      setEvent((prev) => ({ ...prev, image: selectedImage }));
    }, 1000);
  }, [selectedImage]);

  return (
    <div>
      <Grid container spacing={4}>
        {/* <Grid item>
          <Button onClick={searchGoogle} size="small" variant="outlined">
            Search
          </Button>
        </Grid> */}
        {console.log(results)}
      </Grid>
      {results.length > 0 ? (
        <Grid item mt={5}>
          <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Select Image</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <p>Select an image:</p> */}
              <div className="image-gallery">
                {results.map((result, index) => (
                  <div
                    key={index}
                    className="image-container"
                    style={{
                      width: "100px",
                      height: "100px",
                      overflow: "hidden",
                      float: "left",
                      borderRadius: "10px",
                      margin: "10px",
                      background: "#555",
                    }}
                  >
                    <img
                      src={result.image}
                      alt={result.title}
                      onClick={() => setSelectedImage(result.image)}
                      style={{ width: "100%", height: "auto" }}
                    />
                    {/* <p>{result.title}</p> */}
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          {expanded && (
            <Grid item xs={12}>
              {/* <TextField type="text" value={query} onChange={(e) => setQuery(e.target.value)} size="small" /> */}
              <Tooltip title="Enter search phrase to find a fitting image. (For internal use only)">
                <TextField
                  type="text"
                  label="Find another image"
                  placeholder="Enter image search phrase"
                  onChange={(e) => handleValue(e.target.value)}
                  size="small"
                  fullWidth
                />
              </Tooltip>
            </Grid>
          )}
          {expanded && (
            <Grid item mt={4}>
              <Button onClick={() => setExpanded(false)}>Skip image</Button>
            </Grid>
          )}
          {selectedImage && showSelected && (
            <div
              style={{
                width: "300px",
                height: "auto",
                overflow: "hidden",
                borderRadius: "10px",
                margin: "20px",
              }}
            >
              <img src={selectedImage} alt="Selected image" width="300px" height="auto" />
            </div>
          )}
        </Grid>
      ) : null}
    </div>
  );
};
export default SetImage;
