/** @format */

export function timeAdjust(originalTime, adjustment) {
  // Split the original time string into hours and minutes
  const [originalHours, originalMinutes] = originalTime.split(":").map(Number);

  // Split the adjustment string into hours and minutes
  const [adjustmentHours, adjustmentMinutes] = adjustment.split(":").map(Number);

  // Calculate the new hours and minutes
  let newHours = originalHours + adjustmentHours;
  let newMinutes = originalMinutes + adjustmentMinutes;

  // Handle cases where minutes exceed 60
  newHours += Math.floor(newMinutes / 60);
  newMinutes %= 60;

  // Handle cases where hours exceed 24
  newHours %= 24;

  // If the adjustment is negative and the new time is negative,
  // adjust by adding 24 hours to handle wrapping around to the previous day
  if (adjustmentHours < 0 && newHours < 0) {
    newHours += 24;
  }

  // Format the new time as "HH:MM"
  const newTime = `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")}`;

  return newTime;
}
