/** @format */

// TO DO
// Dispatch formdata to Redux on update
// CheckIfExists needs to be a cloud function for security reasons.Fix it!

/** @format */

import React, { useEffect, useState } from "react";
import { venueObj } from "../dbObjects.js/dbVenue";
// import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { productObj } from "../dbObjects.js/dbProducts";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  IconButton,
  Zoom,
  InputAdornment,
} from "@mui/material";
import { DatePicker, StaticTimePicker, TimePicker } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useDispatch, useSelector } from "react-redux";
import Products from "../components/Forms/Products";
import AddLocation from "../components/Forms/AddLocation";
import { collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { createId, db, updateDocument } from "../firebase";
import { ImageUploader } from "../components/UI helpers/ImageUpload/ImageUploader";
import InfoBadge from "../components/MiscUIComponents/InfoBadge";
import ConfirmDialog from "./ConfirmDialog";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { artistObj } from "../dbObjects.js/dbArtists";
import { Navigate, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PhoneInput from "../components/UI helpers/PhoneInput";
import { AES, enc } from "crypto-js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import {
  storeCurrentAgent,
  storeCurrentArtist,
  storeCurrentBooking,
  storeCurrentUser,
  storeCurrentVenue,
} from "../features/venueFilter/venueFilterSlice";
import DisplayObject from "./displayObject";
import { ConstructionOutlined } from "@mui/icons-material";
import { addNewVenueToUsersVenueAccess } from "./AddNewVenueToUsersVenueAccess";
import CheckIfEmailExists from "../utils/CheckIfEmailExists";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IBANTextField from "../components/UI helpers/IBANTextField";
import BICTextField from "../components/UI helpers/BICTextField";
import VenueAdditionals from "../components/MiscUIComponents/VenueAdditionals";

// import { set } from "date-fns";
// import { TimeClock } from "@mui/x-date-pickers/TimeClock";

const GeneralForm = (props) => {
  // const alertColor = "#00ffd455";
  const alertColor = "red";
  const navigateAfter = props.navigateAfter;
  const secretKey = process.env.REACT_APP_FORM_KEY; // Replace with your secret key
  const [formFunction, setFormFunction] = useState(props.formFunction);
  // let formArray = props.form;
  const [formArray, setFormArray] = useState(props.form);
  const lowestPriorityShown = props.priority ? props.priority : 20;
  const formName = props.formName;
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentBooking = useSelector((state) => state.counter.currentBooking);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const currentAgent = useSelector((state) => state.counter.currentAgent);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const [formData, setFormData] = useState([currentVenue && { currentVenue }]);
  const [inRedux, setInRedux] = useState();
  // const [formData, setFormData] = useState();
  const [documentid, setDocumentid] = useState();
  const [phoneNumber, setPhoneNumber] = useState(formData.phone);
  const [IBAN, setIBAN] = useState(formData.IBAN);
  const [BIC, setBIC] = useState(formData.BIC);
  const [matchingPassword, setMatchingPassword] = useState();
  const [checkSocialId, setCheckSocialId] = useState();
  const [emailOk, setEmailOk] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleTimeChange = (e, id) => {
    console.log(e.$d.toLocaleTimeString().slice(0, 5));
    let value = e.$d;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };
  const disabledFields = props.disabledFields;

  // If fields are included in props, only show those fields
  // const [fieldArray, setFieldArray] = useState([props.fields]);
  const fieldArray = props.fields;

  useEffect(() => {
    if (fieldArray) {
      // const filteredFormArray = formArray.filter((obj) => fieldArray.includes(obj.id));
      const filteredFormArray = [];
      for (let i = 0; i < formArray.length; i++) {
        const fieldId = formArray[i].id;
        if (fieldArray.includes(fieldId)) {
          filteredFormArray.push(formArray[i]);
          console.log("new formarray", filteredFormArray);
        }
      }
      setFormArray(filteredFormArray);
    }
  }, []);

  const handleChange = (event, id, type) => {
    // console.log(event.target.value, id, formData);
    let value;
    type === "switch" ? (value = event.target.checked) : (value = event.target.value);
    // if (event.target.checked || !event.target.checked) {
    //   value = event.target.checked;
    // }
    // if (event.target.value) {
    //   value = event.target.value;
    // }

    // Encrypt certain values before storage
    if (
      id === "socialId" ||
      // id === "IBAN" || id === "BIC" ||
      id === "password" ||
      id === "passwordConfirm"
    ) {
      const encryptedValue = AES.encrypt(value, secretKey).toString();
      value = encryptedValue;
    }
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleToggleButton = (event, newValues, id) => {
    // keyValue = "preferredDays";
    console.log(id, newValues);
    setFormData((prev) => ({ ...prev, [id]: newValues }));

    // console.log(event, key, value);
    console.log(formData);
  };

  function executeCode(code) {
    return Function('"use strict";return (' + code + ")")()();
  }

  async function handleUpdate(collection, docid) {
    // alert("saving");
    // console.log("formData", formData);
    // await delete formData.bookings;
    // console.log("= = = = = ", collection, docid, formData);

    //Store form data to the right collection

    console.log(`Storing ${formData} to id:${docid} in collection:${collection}`, formData);

    await setDoc(doc(db, collection, docid), { ...formData }, { merge: true });

    // ...and dispatch to redux and some other stuff.
    formName === "venueForm" && dispatch(storeCurrentVenue(formData));
    formName === "venueForm" && addNewVenueToUsersVenueAccess(formData, currentUser);
    // formName === "venueForm" && alert("storing");
    // formName === "venueForm" && alert(formName);
    formName === "userForm" && (await dispatch(storeCurrentUser(formData)));
    formName === "bookingForm" && (await dispatch(storeCurrentBooking(formData)));

    navigateAfter && navigate(navigateAfter);
  }

  async function handleDelete(collection, id) {
    console.log("Deleting:", collection, id);
    await deleteDoc(doc(db, collection, id));
    navigate(-1);
  }

  //Check if email is an email address, and is free or already in use
  async function checkEmail(email) {
    //first check that the format is an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      //if not the correct format
      setEmailOk(false);
      setFormData((prev) => ({ ...prev, email: "Wrong format..." }));
      setTimeout(() => {
        setFormData((prev) => ({ ...prev, email: "" }));
      }, 3000);
      return;
    }

    //then if format is correct, check if already in use
    if ((await CheckIfEmailExists(email)) === "Exists") {
      setEmailOk(false);
      await setTimeout(() => {
        setFormData((prev) => ({ ...prev, email: "This email is already in use..." }));
      }, 100);
      setTimeout(() => {
        setFormData((prev) => ({ ...prev, email: "" }));
      }, 1000);
    }
    // if format correct and email not in use...
    else {
      setEmailOk(true);
    }
  }

  // Send formdata back via props (for instance when using stepper to show form)
  useEffect(() => {
    formName === "venueForm" && props.setVenueData(formData);
    formName === "userForm" && props.setUserData(formData);
    formName === "bookingForm" && props.setBookingData(formData);
  }, [formData]);

  // Depending on wich form use corresponding redux storage
  useEffect(() => {
    formName === "venueForm" && setFormData(currentVenue);
    formName === "ownerForm" && setFormData(currentUser);
    formName === "userForm" && setFormData(currentUser);
    formName === "agentForm" && setFormData(currentAgent);
    formName === "artistForm" && setFormData(currentArtist);
    formName === "bookingForm" && setFormData(currentBooking);
  }, [currentVenue, currentArtist, currentUser, currentAgent, currentBooking]);

  useEffect(() => {
    phoneNumber && setFormData((prev) => ({ ...prev, phone: phoneNumber }));
  }, [phoneNumber]);

  useEffect(() => {
    IBAN && setFormData((prev) => ({ ...prev, IBAN: IBAN }));
    console.log("4", IBAN);
  }, [IBAN]);

  useEffect(() => {
    BIC && setFormData((prev) => ({ ...prev, BIC: BIC }));
  }, [BIC]);

  //Validate Password
  useEffect(() => {
    const password = formData.password && AES.decrypt(formData.password, secretKey).toString(enc.Utf8);
    const passwordConfirm = formData.passwordConfirm && AES.decrypt(formData.passwordConfirm, secretKey).toString(enc.Utf8);
    if (password === passwordConfirm) {
      setMatchingPassword(true);
    }
    if (password !== passwordConfirm) {
      setMatchingPassword(false);
    }
  }, [formData.password, formData.passwordConfirm]);

  //Validate socialId is 12 letters
  useEffect(() => {
    const numberRegex = /^\d{12}$/;
    let tmp = formData.socialId && AES.decrypt(formData.socialId, secretKey).toString(enc.Utf8);
    if (!numberRegex.test(tmp)) {
      setCheckSocialId(false);
    } else {
      setCheckSocialId(true);
    }
  }, [formData.socialId]);

  //if props.validation is needed (to turn on or off Submitbutton) this checks if email,socialId and passwords are correct and returns true or false
  useEffect(() => {
    // console.log({ emailOk, checkSocialId, matchingPassword });
    if (emailOk && checkSocialId && matchingPassword) {
      props.setValidation && props.setValidation(true);
    } else {
      props.setValidation && props.setValidation(false);
    }
  }, [emailOk, checkSocialId, matchingPassword]);
  // }, []);

  // if venueForm make sure to remove bookings otherwise formData gets too big to store...
  useEffect(() => {
    if (formName === "venueForm") {
      setFormData((prev) => ({ ...prev, bookings: null }));
    }
    console.log(formData);
  }, [formData.bookings]);

  const renderFormField = (field) => {
    switch (field.type) {
      case "productsComponent": {
        return (
          <Grid item sx={12} className="formSectionLabel" style={{ width: "100%" }}>
            <Products />
          </Grid>
        );
      }

      case "addLocationComponent": {
        return (
          <Grid item sx={12} className="formSectionLabel" style={{ width: "100%" }}>
            <AddLocation setFormValues={setFormData} fieldPrepend={field.fieldPrepend} />
          </Grid>
        );
      }

      case "Section":
        return (
          <div style={{ width: "100%" }}>
            <Grid item sx={12} className="formSectionLabel" style={{ width: "100%" }}>
              {field.section}
            </Grid>
          </div>
        );

      case "select":
        return (
          <FormControl>
            <InputLabel>{field.label}</InputLabel>
            <Select
              size="small"
              value={formData[field.id]}
              onChange={(e) => {
                handleChange(e, field.id);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={field.style && field.style}
              {...field}
            >
              {field.options.map((option) => (
                <MenuItem value={option} name={field.id} field={field.id}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "text":
      case "number":
        return (
          <TextField
            InputLabelProps={{
              shrink: formData[field.id] || field.defaultValue ? true : false,
            }}
            size="small"
            id={field.id}
            label={field.label}
            required={field.required}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            type={field.type}
            value={formData[field.id]}
            disabled={field.disabled}
            onChange={(e) => handleChange(e, field.id)}
            {...field}
            // InputProps={{
            //   placeholder: formData[field.id] ? "" : field.placeholder,
            // }}
          />
        );

      case "IBAN":
        console.log("5", formData[field.id]);
        return <IBANTextField formValue={IBAN || formData[field.id] ? formData[field.id] : ""} setIBAN={setIBAN} />;

      case "BIC":
        return <BICTextField formValue={BIC || formData[field.id] ? formData[field.id] : ""} setBIC={setBIC} />;

      case "encrypted":
        return (
          <>
            <Tooltip
              title={
                (field.id === "socialId" && !checkSocialId && "Enter Social ID with 12 numbers") ||
                (field.id === "password" && !matchingPassword && "Make sure Password and Confirm password is matching") ||
                (field.id === "passwordConfirm" && !matchingPassword && "Make sure Password and Confirm password is matching")
              }
            >
              <TextField
                size="small"
                id={field.id}
                required={field.required}
                label={field.label}
                InputLabelProps={{ shrink: field.label.length > 0 }}
                value={formData[field.id] ? AES.decrypt(formData[field.id], secretKey).toString(enc.Utf8) : ""}
                disabled={field.disabled}
                type={field.id === "password" || field.id === "passwordConfirm" ? "password" : ""}
                onChange={(e) => handleChange(e, field.id)}
                style={{
                  borderRadius: "10px",
                  boxShadow:
                    ((field.id === "password" || field.id === "passwordConfirm") && !matchingPassword) ||
                    (field.id === "socialId" && !checkSocialId)
                      ? `0px 0px 4px ${alertColor}`
                      : "",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {field.id === "socialId" && checkSocialId && <CheckCircleIcon style={{ color: "#0080006b" }} />}
                      {(field.id === "password" || field.id === "passwordConfirm") && matchingPassword && (
                        <CheckCircleIcon style={{ color: "#0080006b" }} />
                      )}
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="Encrypted">
                        <EnhancedEncryptionIcon style={{ color: "#555", zIndex: "100000" }} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </>
        );

      case "password":
        return (
          <TextField
            size="small"
            id={field.id}
            label={field.label}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            type={field.type}
            value={formData[field.id]}
            disabled={field.disabled}
            onChange={(e) => handleChange(e, field.id)}
            {...field}
            InputLabelProps={{
              shrink: formData[field.id] ? true : false,
            }}
          />
        );

      case "email":
        return (
          <>
            <TextField
              size="small"
              id={field.id}
              label={field.label}
              placeholder={field.placeholder}
              // defaultValue={field.defaultValue}
              type={field.type}
              value={formData[field.id]}
              disabled={field.disabled}
              onChange={(e) => handleChange(e, field.id)}
              onBlur={(e) => field.checkIfEmailExists && checkEmail(formData[field.id])}
              style={{
                width: "300px",
                borderRadius: "10px",
                boxShadow: !emailOk && `0px 0px 4px ${alertColor}`,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {field.checkIfEmailExists && emailOk && <CheckCircleIcon style={{ color: "#0080006b" }} />}
                  </InputAdornment>
                ),
              }}
            />
          </>
        );

      case "phone":
        return <PhoneInput formValue={formData[field.id]} setPhoneNumber={setPhoneNumber} />;

      case "textarea":
        return (
          <TextField
            name={field.name}
            label={field.label}
            value={formData[field.id] || ""}
            multiline={field.multiline}
            rows={field.rows}
            onChange={(event) => setFormData({ ...formData, [field.name]: event.target.value })}
          />
        );

      case "slider":
        return (
          <Slider
            name={field.label}
            // value={formData[field.id] || "50"}
            min={field.min}
            max={field.max}
            step={field.step}
            onChange={(e) => handleChange(e, field.id)}
            style={{ width: "200px" }}
          />
        );

      case "date":
        return (
          <DatePicker
            name={field.name}
            label={field.label}
            value={formData[field.name] || null}
            onChange={(newValue) => setFormData({ ...formData, [field.name]: newValue })}
            renderInput={(params) => <TextField {...params} />}
          />
        );

      case "range":
        return (
          <DateRangePicker
            startText={field.startText}
            endText={field.endText}
            value={formData[field.name] || [null, null]}
            onChange={(newValue) => setFormData({ ...formData, [field.name]: newValue })}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <TextField {...endProps} />
              </>
            )}
          />
        );

      case "time":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl>
              <TextField
                {...field}
                defaultValue={currentVenue[field.id]}
                value={formData[field.id]}
                onChange={(e) => handleChange(field.id, e.target.value)}
                size="small"
                style={{ width: "150px" }}
              />
              {/* <MobileTimePicker /> */}
              {/* <StaticTimePicker
                label={field.id}
                value={formData[field.id]}
                minutesStep={5}
                defaultValue={new Date("Mon Apr 17 2023 16:25:13")}
                onChange={(e) => handleTimeChange(e, field.id)}
                ampm={false}
                className={field.section}
              /> */}
            </FormControl>
          </LocalizationProvider>
        );

      case "Button":
        return (
          <Button size="small" variant="contained" color="primary" id={field.id}>
            {field.label}
          </Button>
        );

      case "switch":
        return (
          <FormControlLabel
            // id={field.id}
            control={
              <Switch checked={formData[field.id] ? formData[field.id] : false} onChange={(e) => handleChange(e, field.id, field.type)} />
            }
            label={field.label}
          />
        );

      case "toggleButton" || "checkbox":
        return (
          <Grid item className={field.id}>
            <InputLabel className="inputLabel">{field.label}</InputLabel>
            <ToggleButtonGroup
              size="small"
              exclusive={field.exclusive}
              key={field.id}
              value={formData[field.id]}
              defaultValue={field.defaultValue && field.defaultValue}
              onChange={(event, newValues) => handleToggleButton(event, newValues, field.id)}
            >
              {field.options.map((option) => (
                <ToggleButton key="preferredDays" value={option} color="primary">
                  {option}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        );

      case "url":
        return (
          <TextField
            {...field}
            value={formData[field.id]}
            className={field.id}
            InputLabelProps={{
              // shrink: formData[field.id] ? true : false,
              shrink: true,
            }}
          />
        );

      case "imageUpload":
        return (
          <Grid
            item
            className="imageUpload"
            direction="column"
            p={2}
            xs={12}
            style={{
              background: "linear-gradient(0deg, #111, #222)",
              border: "1px solid #555",
              borderRadius: "10px",
              boxShadow: " 5px 5px 10px black",
              minWidth: "500px",
            }}
          >
            <ImageUploader storageFolder={field.storage} formImageKey={field.id} setFormValues={setFormData} formValues={formData} />
            {formData[field.id] && (
              <Button
                style={{ marginTop: "10px" }}
                onClick={() => {
                  // console.log("removing image");
                  setFormData({
                    ...formData,
                    [field.id]: "",
                  });
                }}
              >
                Change Image
              </Button>
            )}
          </Grid>
        );

      case "fileUpload":
        return (
          <Grid
            item
            className="fileUpload"
            direction="column"
            p={2}
            xs={12}
            style={{
              background: "linear-gradient(0deg, #111, #222)",
              border: "1px solid #555",
              borderRadius: "10px",
              boxShadow: " 5px 5px 10px black",
              minWidth: "100px",
            }}
          >
            <ImageUploader storageFolder={field.storage} formImageKey={field.id} setFormData={setFormData} formValues={formData} />
            {formData[field.id] && (
              <Button
                onClick={() => {
                  // console.log("removing image");
                  setFormData({
                    ...formData,
                    [field.id]: "",
                  });
                }}
              >
                Change Image
              </Button>
            )}
          </Grid>
        );

      case "VenueAdditionals":
        return (
          <Grid item xs={12}>
            <VenueAdditionals />
          </Grid>
        );

      case "update":
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              id={field.id}
              // onClick={() => handleUpdate(field.collection, currentVenue.venueid)}
              onClick={() => handleUpdate(field.collection, formData.documentid)}
              // onClick={() => updateDocument(field.collection, formData, field.documentId)}
            >
              {/* <SaveAltIcon fontSize="small" style={{ marginRight: "10px" }} /> */}
              {field.label}
            </Button>
          </>
        );

      case "goback":
        return (
          <Button onClick={() => navigate(-1)} variant="outlined">
            {/* <ArrowBackIosIcon fontSize="small" style={{ marginRight: "10px" }} /> */}
            Cancel
          </Button>
        );

      case "deleteVenue":
        return (
          <ConfirmDialog onConfirm={() => handleDelete(field.collection, currentVenue.venueid)}>
            <DeleteIcon fontSize="small" style={{ marginRight: "10px" }} />
            Delete
          </ConfirmDialog>
        );

      default:
        return null;
    }
  };

  return (
    <form>
      {inRedux}
      {/* <DisplayObject data={formData} /> */}
      <Grid container gap={2} xs={12} sm={10} md={10} mt={4} className={"generalForm " + formName}>
        {
          // formArray &&
          formData.documentid &&
            formArray.map((field, index) => (
              <Grid item key={index} style={{ display: "flex" }}>
                {field.priority <= lowestPriorityShown && (
                  <Tooltip title={field.info} placement="top" arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                    {renderFormField(field)}
                  </Tooltip>
                )}
              </Grid>
            ))
        }
      </Grid>
    </form>
  );
};

export default GeneralForm;
