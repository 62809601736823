/** @format */

import { Button, CircularProgress, Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AES, enc } from "crypto-js";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { subscriptionsObj } from "../dbObjects.js/dbSubscriptions";
import { storeCurrentStripe } from "../features/venueFilter/venueFilterSlice";
import CreateSubscription from "../finshark/CreateSubscription";
import { cancelFinsharkSubscription } from "../services/finshark/cancelSubscription";
// import { checkFinsharkSubscription } from "../services/finshark/checkSubscription";
import { createFinsharkSubscription } from "../services/finshark/createSubscripton";
import { cancelStripeSubscription } from "../services/stripe/cancelSubscription";
import { createStripeCheckout } from "../services/stripe/createCheckout";
import { createStripeSubscription } from "../services/stripe/createSubscriptionStripe";
import ConfirmDialog from "./ConfirmDialog";
import { makeNumber, numberFormatted } from "./Numbers";

const SubscriptionInput = ({ currentVenue }) => {
  const [open, setOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Card");
  const [selectedSubscription, setSelectedSubscription] = useState(currentVenue.subscriptionPlan ? currentVenue.subscriptionPlan : "Free");
  const [finsharkResponse, setFinsharkResponse] = useState();
  const [stripeResponse, setStripeResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const subscriptionOptions = subscriptionsObj;
  //   const subscriptionOptions = [
  //     { value: "basic", label: "Basic" },
  //     { value: "premium", label: "Premium" },
  //     { value: "pro", label: "Pro" },
  //     // ... other options
  //   ];
  console.log("ALMOST ==>", currentVenue);
  const secretKey = process.env.REACT_APP_FORM_KEY;
  const IBAN = AES.decrypt(currentVenue.IBAN, secretKey).toString(enc.Utf8);
  const BIC = AES.decrypt(currentVenue.BIC, secretKey).toString(enc.Utf8);

  const handleChange = (event) => {
    setSelectedSubscription(event.target.value);
  };

  const matchingOption = subscriptionOptions.find((option) => option.label === selectedSubscription);
  const monthlyValue = matchingOption ? matchingOption.monthly : null;

  //
  // FINSHARK
  //

  const finsharkBody = {
    amount: {
      currency: "SEK",
      value: makeNumber(monthlyValue),
    },
    chargeBearer: "SHAR",
    creditor: {
      account: {
        accountNumber: IBAN,
        accountType: "IBAN",
        bic: BIC,
        currencyCode: "SEK",
      },
      address: {
        city: currentVenue.postalCity,
        street: currentVenue.postalStreetAddress,
        country: currentVenue.country,
      },
      message: `Venuehub ${selectedSubscription}`,
      name: "string",
    },
    frequency: "MONTHLY",
    paymentProductId: "se-domestic-credit-transfer",
    region: "SE",
    remittanceInformation: {
      value: "string",
      type: "UNSTRUCTURED",
    },
    startDate: 1703688054087,
  };

  async function handleCreateFinsharkSubsciption() {
    setLoading(true);
    console.log("testing");
    handleDialogOpen();
    const response = await createFinsharkSubscription(finsharkBody, currentVenue, selectedSubscription);
    console.log("now", response);
    setFinsharkResponse(response);
    setLoading(false);
  }

  //
  // STRIPE
  //
  async function handleCreateStripeSubsciption() {
    setLoading(true);
    const response = await createStripeCheckout(currentVenue, selectedSubscription, monthlyValue);
    setStripeResponse(response);
    setLoading(false);
  }

  useEffect(() => {
    stripeResponse && runStripe();
  }, stripeResponse);

  async function runStripe() {
    dispatch(storeCurrentStripe(stripeResponse.id));
    stripeResponse && window.open(stripeResponse.url, "_blank", "width=800,height=600");
    setTimeout(() => {
      window.close();
    }, 2000);
  }

  return (
    <FormControl fullWidth>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            value={selectedSubscription ? currentVenue.subscription && currentVenue.subscription.plan : ""}
            size="small"
            // label={currentVenue.subscription.id}
            label="Current"
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            value={selectedSubscription ? currentVenue.subscription && currentVenue.subscription.id : ""}
            size="small"
            // label={currentVenue.subscription.id}
            label="Id"
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            value={selectedSubscription ? currentVenue.subscription && currentVenue.subscription.service : ""}
            size="small"
            // label={currentVenue.subscription.id}
            label="Service"
            disabled
          />
        </Grid>
        <Grid item>
          <Button onClick={() => setOpen(!open)}>
            {selectedSubscription === "Premium" || selectedSubscription === "Enterprise" ? "Change" : "Upgrade"}
          </Button>
        </Grid>
        {open && (
          <>
            <Grid item>
              {/* <InputLabel id="subscription-select-label">Subscription</InputLabel> */}
              <Select
                size="small"
                labelId="subscription-select-label"
                id="subscription-select"
                defaultValue="Premium"
                value={selectedSubscription}
                onChange={handleChange}
                sx={{ width: "300px", lineHeight: "auto" }}
              >
                {subscriptionOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id} disabled={option.label === currentVenue.subscription.plan}>
                    <div style={{ float: "left" }}>{option.label} </div>
                    <div style={{ float: "right", textAlign: "right", opacity: "0.5" }}>
                      {numberFormatted(option.monthly)}
                      {option.monthly > 0 && ` ${currentVenue.currency}/month`}
                      {option.label === "Free" && "Free"}
                      {option.label === "Enterprise" && "Call"}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Select size="small" defaultValue="Bank Transfer" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                {/* <MenuItem value="Bank Transfer">Bank Transfer</MenuItem> */}
                <MenuItem value="Card">Card</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              {paymentMethod === "Bank Transfer" && Number(monthlyValue) > 0 && (
                <Button onClick={() => handleCreateFinsharkSubsciption()}>
                  {loading && <CircularProgress style={{ zoom: "0.4", marginRight: "15px" }} />}
                  Subscribe
                </Button>
              )}
              {paymentMethod === "Card" && Number(monthlyValue) > 0 && (
                <Button onClick={() => handleCreateStripeSubsciption()} variant="contained">
                  Subscribe
                </Button>
              )}
              {currentVenue.subscriptionService === "Finshark" && (
                <ConfirmDialog
                  onConfirm={() => cancelFinsharkSubscription(currentVenue.subscription.id)}
                  header="Cancel subscription."
                  message="Are you sure you want to cancel your subscription?"
                >
                  Cancel Subscription
                </ConfirmDialog>
              )}
              {currentVenue.subscription.service === "Stripe" && (
                <ConfirmDialog
                  onConfirm={() => cancelStripeSubscription(currentVenue.subscription.id)}
                  header="Cancel subscription."
                  message="Are you sure you want to cancel your subscription?"
                  noButton
                >
                  <Button variant="outlined">Cancel Subscription</Button>
                </ConfirmDialog>
              )}
            </Grid>
            {finsharkResponse && (
              <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="iframe-dialog-title" maxWidth="md">
                <DialogContent>
                  <iframe
                    src={`https://flows.finshark.io?type=recurring&clientId=d150af90-d4da-c684-ad8d-0d8312f53d5a&region=SE&paymentId=${finsharkResponse.paymentId}&redirectUri=https://venuehub.live/Subscribed?id=${finsharkResponse.paymentId}&plan=${selectedSubscription}&service=Finshark&venueid=${currentVenue.venueid}&oldSubscriptionId=${currentVenue.subscription.id}`}
                    height="700px"
                    width="560px"
                  ></iframe>
                </DialogContent>
              </Dialog>
            )}
          </>
        )}
      </Grid>
    </FormControl>
  );
};

export default SubscriptionInput;
