/** @format */

import React from "react";
import { generateColor } from "./generateColor";

const CircleGraph = (props) => {
  var randy = 50;
  var stroke = props.stroke;
  var radius = props.size - stroke;
  var size = props.size;
  var circumference = radius * 2 * Math.PI;
  var value = props.value;
  if (value === "random") value = Math.floor(Math.random() * (100 - 0 + 1)) + 0;
  const dasharrayA = `${circumference} ${circumference}`;
  const dasharrayB = `${(value * circumference) / 100} ${circumference}`;
  const transform = `rotate(-180deg) translate(${-size}, ${-size})`;
  // const strokeColor = `rgb(0, ${(100 - value) * 2.55}, ${100 - value * 2.55})`;
  // const strokeColor = `orange`;
  const strokeColor = generateColor(value);
  // console.log(strokeColor);
  return (
    <div>
      <svg className="newPie" width={size * 2} height={size * 2} fill="#555" background="#555">
        <circle
          stroke="rgba(0, 255, 212, 0.3)"
          stroke-width={stroke}
          r={radius}
          cx={size}
          cy={size}
          fill="transparent"
          stroke-dasharray={dasharrayA}
          style={{ opacity: "0.9" }}
        />
        <circle
          // stroke="rgb(0, 255, 212)"
          stroke={strokeColor}
          stroke-width={stroke}
          r={radius}
          cx={size}
          cy={size}
          fill="transparent"
          stroke-dasharray={dasharrayB}
          style={{ transform: `rotate(-90deg) translate(-${2 * size}px, -0px` }}
        />
      </svg>
      <p
        style={{
          marginTop: -size * 1.35,
          marginLeft: radius * 0.7,
          opacity: "0.3",
          fontSize: size / 2,
          fontWeight: "100",
        }}
      >
        {props.noNumber ? "" : <span>{value}%</span>}
      </p>
    </div>
  );
};

export default CircleGraph;
