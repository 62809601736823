/** @format */

import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useSelector, useDispatch } from "react-redux";
import { storeArtists } from "../features/venueFilter/venueFilterSlice";

const GetArtists = () => {
  const [artists, setArtists] = useState([]);
  const artistCollectionRef = collection(db, "Artists");
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getDocs(artistCollectionRef);
      setArtists(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getData();
  }, []);
  // console.log(artists);

  setTimeout(() => {
    dispatch(storeArtists(artists));
  }, 100);
};

export default GetArtists;
