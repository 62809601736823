/** @format */

import React from "react";
import { useRef, useState } from "react";
import { signup, login, logout, useAuth, db } from "../../firebase";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { CircularProgress, TextField } from "@mui/material";
// import { Button, Modal } from '@mui/material';

const Login = () => {
  const [socialId, setSocialId] = useState("");
  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const roleRef = useRef();
  const phoneRef = useRef();
  const nameRef = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const currentUser = useAuth();

  async function handleSignup() {
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match!");
    }
    try {
      setLoading(true);
      // Signup new user...
      await signup(emailRef.current.value, passwordRef.current.value);
      var user = getAuth().currentUser;
      // ...and create corresponding document in User collection with same ID
      await setDoc(doc(db, "Users", user.uid), {
        email: emailRef.current.value,
        password: passwordRef.current.value,
        role: roleRef.current.value,
        phone: phoneRef.current.value,
        name: nameRef.current.value,
        userid: user.uid,
      });
    } catch {
      alert("Failed to sign up new user");
    }
    setLoading(false);
    setModal(false);
  }

  return (
    <div>
      <div onClick={() => Toggle()}>
        <div>Sign up</div>
      </div>
      {modal ? (
        <div>
          <Modal show={modal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Title id="contained-modal-title-vcenter" className="text-center py-3" style={{ background: "#111" }}>
              <h3>
                <strong>Sign Up</strong>
              </h3>
              {loading ? (
                <div class="d-flex justify-content-center mt-5">
                  <div class="spinner-border" role="status" />
                  <CircularProgress />
                </div>
              ) : null}
              {error && <Alert variant="danger">{error}</Alert>}
            </Modal.Title>
            <Modal.Body className="justify-content-center align-items-center" style={{ background: "#333" }}>
              <Form>
                <TextField label="Name" ref={nameRef} placeholder="Enter Name" fullWidth="true" size="small" />

                <Form.Select ref={roleRef} aria-label="Default select example" size="md">
                  <option disabled selected required>
                    Role
                  </option>
                  <option value="owner">Owner</option>
                  <option value="agent">Agent/Artist</option>
                  <option value="editor">Editor</option>
                  <option value="admin">Admin</option>
                </Form.Select>

                <TextField label="Email" ref={emailRef} type="email" placeholder="Enter Email" fullWidth="true" size="small" />

                <TextField label="Password" ref={passwordRef} type="password" placeholder="Enter Password" fullWidth="true" size="small" />

                <TextField
                  label="Corfirm Password"
                  ref={passwordConfirmRef}
                  type="password"
                  placeholder="Comfirm Password"
                  fullWidth="true"
                  size="small"
                />

                <TextField label="Phone number" ref={phoneRef} type="number" placeholder="Enter Phone" fullWidth="true" size="small" />
              </Form>
              <TextField
                label="Social Security number"
                value={socialId}
                onChange={(e) => {
                  setSocialId(e.target.value);
                }}
                type="number"
                placeholder="yyyyMMddXXXX"
                fullWidth="true"
                size="small"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  fetch(
                    `https://europe-west3-venuehub-64e72.cloudfunctions.net/verifyId?socialId=${socialId}&phoneNumber=${phoneRef.current.value}`
                  )
                    .then((response) => response.json())
                    .then((data) => window.open(data.url, "_blank"))
                    .catch((error) => console.error(error));
                }}
              >
                Check bankId
              </Button>

              <Button disabled={loading || currentUser} onClick={handleSignup}>
                Submit
              </Button>
              <Button onClick={() => Toggle()} variant="secondary">
                Close
              </Button>
              {/* {currentUser ? <Button variant="" disabled={loading || !currentUser} onClick={handleLogout}>Log Out</Button> : <div></div>} */}
            </Modal.Footer>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default Login;
