/** @format */

import { Tooltip } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoBox = ({ text }) => {
  return (
    <div className="InfoBox">
      <Tooltip title={text} placement="top">
        <infoIcon>i</infoIcon>
      </Tooltip>
    </div>
  );
};

export default InfoBox;
