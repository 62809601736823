/** @format */

import React, { useState } from "react";
import { SingleBookingContainer } from "./SingleBookingContainer";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ArtistInfoModal } from "../Modals/ArtistInfoModal";
import { Portal } from "react-portal";
import { GeneralModal } from "../Modals/GeneralModal";

export const BookingsByWeekContainer = (props) => {
  const [showArtistInfo, setShowArtistInfo] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const onClickHandler = (artist) => {
    setShowArtistInfo(true);
    setSelectedArtist(artist);
  };

  return (
    <>
      <ArtistInfoModal
        setOpen={() => {
          setShowArtistInfo(true);
        }}
        open={showArtistInfo}
        onClose={() => setShowArtistInfo(false)}
        selectedArtist={selectedArtist}
        selectedEvent={selectedEvent}
      />
      <Grid container>
        {props.eventsByWeek.map((week, i) => {
          return (
            <Grid container xs={12}>
              <h1>{week.week}.</h1>
              {week.events.map((event, i) => {
                if (!event.bookingid) {
                  return;
                }
                return (
                  <Grid
                    container
                    xs={1.5}
                    key={event.bookingid}
                    sx={{
                      marginLeft: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <SingleBookingContainer
                      event={event}
                      action={() => {
                        setShowArtistInfo(true);
                      }}
                      setSelectedArtist={setSelectedArtist}
                      setSelectedEvent={setSelectedEvent}
                    />
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
