/** @format */

import { Button, CircularProgress } from "@mui/material";
import { getDataGridUtilityClass } from "@mui/x-data-grid";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import GetTixlyEvents from "../features/tixly/GetTixlyEvents";
import GetTixlyTickets from "../features/tixly/GetTixlyTickets";
import { createId, db } from "../firebase";
import { log } from "../helpers/log";
import { SendRequest } from "../helpers/SendRequest";

const SyncTixlyEvents = () => {
  const tixlyVenueId = 289;
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const venueid = currentVenue && currentVenue.venueid;
  let tmpVenue = { ...currentVenue }; //in order to empty the "bookings".
  tmpVenue.bookings = "empty";
  tmpVenue.deductFromTickets = true;

  // const q = query(collection(db, "BookingsTest"), where("venue", "==", "Scalateatern"));
  // const querySnapshot = await getDocs(q);
  log("existingBookings", existingBookings);

  const [tixlyImportCount, setTixlyImportCount] = useState(0);
  const [tixlyImportLength, setTixlyImportLength] = useState(0);
  const [existingBookings, setExistingBookings] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getExisting() {
    let tmp = [];
    console.log("venueid", venueid);
    const qry = venueid && query(collection(db, "Bookings"), where("venueid", "==", venueid));
    const querySnapshot = await getDocs(qry);
    querySnapshot.forEach((doc) => {
      tmp.push(doc.data());
      console.log("tmp", tmp);
    });
    setExistingBookings(tmp);
  }

  async function getEventData() {
    let tmp = [];
    const q = query(collection(db, "BookingsTest"), where("venueId", "==", tixlyVenueId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      tmp.push(doc.data());
    });
    setTixlyImportLength(tmp.length);
    setEventData(tmp);
  }

  useEffect(() => {
    getExisting(); //from bookings
  }, [currentVenue]);

  useEffect(() => {
    getEventData(); // from tickets
  }, []);

  useEffect(() => {
    console.log("existingBookings", existingBookings);
  }, [existingBookings]);

  async function syncEvents() {
    let index = 0;
    eventData.forEach(async (doc) => {
      setLoading(true);
      if (index < 1000) {
        const bookingid = createId();
        const data = doc;

        const selectedDate = new Date(data.eventStart).toLocaleDateString();
        const agentEmail = "marcus.dernulfl@venuehub.live";
        const tixlyId = data.bookingId.toString();
        const firstName = data.agent;
        const doors = data.eventStart.split("T")[1].substring(0, 5);
        const loadOut = data.eventEnd.split("T")[1].substring(0, 5);
        const venue = { venue: tmpVenue, tixlyId, venueid: currentVenue.venueid, doors, loadOut, status: "Confirmed" };
        // console.log(index, "🌿", firstName);
        // console.log(data, selectedDate, agentEmail, tixlyId, firstName, doors, loadOut, venue, { documentid: bookingid });
        console.log("tixlyId", tixlyId);
        index++;
        setTixlyImportCount(index);

        // SendRequest = (currentArtist, currentAgent, availData, proposedDate, agreementId, bookingid)  // requestmall
        console.log(existingBookings);

        // if (existingBookings.some((booking) => booking.tixlyId === tixlyId)) {
        if (existingBookings.some((booking) => booking.tixlyId === tixlyId)) {
          console.log("Already existing:", tixlyId);
        } else {
          await SendRequest(
            { artistid: "-", artist: data.artist },
            { agentid: "-", agent: agentEmail, firstName: firstName, lastName: "" },
            { ...venue },
            selectedDate,
            "",
            bookingid
          );
        }

        setTixlyImportCount(index);
      }
      setLoading(false);
    });
    getExisting();
  }

  // useEffect(() => {
  //   log("count", tixlyImportCount);
  //   log("eventData", eventData);
  // }, [tixlyImportCount, eventData]);

  return (
    <div style={{ display: "flex", gap: "15px" }}>
      {tixlyImportCount}
      <Button onClick={() => syncEvents()} style={{ marginTop: "105px" }} variant="outlined">
        {loading && <CircularProgress sx={{ zoom: "0.4" }} style={{ marginRight: "5px" }} />}
        {loading && tixlyImportCount}
        SyncTixlyEvents {tixlyImportLength - tixlyImportCount}
      </Button>
      <GetTixlyEvents />
      <GetTixlyTickets />
    </div>
  );
};

export default SyncTixlyEvents;
