/** @format */

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { timeDifference } from "../../components/Forms/ProductsGraph";

const AvailableSlots = () => {
  const selectedDate = useSelector((state) => state.counter.selectedDate);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const [items, setItems] = useState([]);
  let freetime = [];

  useEffect(() => {
    let bookingsThisDate = currentBookings.filter(
      (booking) => new Date(booking.date).toLocaleString() === new Date(selectedDate).toLocaleString()
    );
    setItems(bookingsThisDate);
    console.log("this", bookingsThisDate);
    console.log("items", items);
  }, [currentBookings]);

  return (
    <div>
      <stats>AvailableSlots</stats>
      <div>
        {/* {items.map((item) => (
          <div>{timeDifference(item.loadIn, item.loadOut)}</div>
        ))} */}
      </div>
    </div>
  );
};

export default AvailableSlots;
