/** @format */

import React from "react";

export const privateIcon = (
  <div
    style={{
      color: "#00ffd499",
      fontSize: "10px",
      border: "1px solid #00ffd499",
      background: "transparent",
      borderRadius: "5px",
      padding: "0px 5px",
      marginRight: "5px",
      display: "initial",
    }}
  >
    Private
  </div>
);

export const publicIcon = (
  <div
    style={{
      color: "black",
      fontSize: "10px",
      background: "#00ffd499",
      borderRadius: "5px",
      padding: "0px 5px",
      marginRight: "5px",
      display: "initial",
    }}
  >
    Public
  </div>
);
