/** @format */

// Create array of form fields.
export let subscriptionsObj = [
  { section: "Subscriptions", type: "Section" },
  {
    section: "Subscriptions",
    label: "Free",
    id: "Free",
    type: "text",
    monthly: "free",
    disabled: true,
  },
  {
    section: "Subscriptions",
    label: "Pro",
    id: "Pro",
    type: "text",
    monthly: 0.5,
    disabled: true,
  },
  {
    section: "Subscriptions",
    label: "Premium",
    id: "Premium",
    type: "text",
    monthly: 0.6,
    disabled: true,
  },
  {
    section: "Subscriptions",
    label: "Enterprise",
    id: "Enterprise",
    type: "text",
    monthly: "Call",
    disabled: true,
  },
];
