/** @format */

import React, { useState, useEffect, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

const ShowDb = () => {
  const [dataSource, setDataSource] = useState("Bookings");
  const [selectedTarget, setSelectedTarget] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedField, setSelectedField] = useState("date");
  const [selectedValue, setSelectedValue] = useState("");
  const [showAddColumn, setShowAddColumn] = useState(false);
  const rowsRef = useRef(rows);
  const collections = [
    "Agents",
    "Artists",
    "Avails",
    "Bookings",
    "Products",
    "Users",
    "Venues",
    "mail",
    "NewData",
    "nuAvails",
    "2ndData",
    "ArtistsBackup",
    "VenuesBackup",
    "BookingsBackup",
    "xtraVenueBackup",
  ];

  useEffect(() => {
    async function getData() {
      const querySnapshot = await getDocs(collection(db, dataSource));
      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      // Collect all keys from all documents
      const allKeys = new Set();
      data.forEach((row) => {
        Object.keys(row).forEach((key) => allKeys.add(key));
      });
      // Create columns for each key
      const columns = Array.from(allKeys).map((key) => ({
        field: key,
        headerName: key,
        width: 150,
        editable: true,
      }));
      setRows(data);
      setColumns(columns);
    }
    getData();
  }, [dataSource]);

  const [columns, setColumns] = useState([]);

  const handleSelect = (e) => {
    setDataSource(e.target.value);
  };

  const handleSelectTarget = (e) => {
    setSelectedTarget(e.target.value);
  };

  const handleEditCellChange = (params) => {
    const { id, field, value } = params;
    const updatedRows = [...rowsRef.current];
    const rowIndex = updatedRows.findIndex((row) => row.id === id);

    for (let i = 0; i < selectedIds.length; i++) {
      const selectedId = selectedIds[i];
      const selectedRowIndex = updatedRows.findIndex((row) => row.id === selectedId);
      if (selectedRowIndex === rowIndex) {
        updatedRows[selectedRowIndex] = {
          ...updatedRows[selectedRowIndex],
          [field]: value,
        };
      }
    }

    setRows(updatedRows);
  };

  const handleSelectionChange = (selection) => {
    setSelectedIds(selection);
    console.log(selection);
  };

  const handleFieldChange = (e) => {
    setSelectedField(e.target.value);
  };

  const handleValueChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleBatchUpdate = () => {
    console.log(selectedIds, selectedField, selectedValue);
    console.log(rows);
    const updatedRows = rows.map((row) =>
      selectedIds.includes(row.id)
        ? {
            ...row,
            [selectedField]: selectedValue,
          }
        : row
    );
    setRows(updatedRows);
    console.log(updatedRows);
    // setSelectedField("");
    // setSelectedValue("");
  };

  const addColumn = () => {
    let tmp = [];
    console.log(rows);
    rows.map((row) => {
      console.log(row);
      tmp.push({ ...row, [selectedValue]: "-" });
    });
    setRows(tmp);
  };

  const copyAndCreateColumn = () => {
    let tmp = [];
    console.log(rows);
    rows.map((row) => {
      console.log(row);
      tmp.push({ ...row, [selectedValue]: row[selectedField] });
    });
    setRows(tmp);
  };

  const deleteColumn = () => {
    console.log(selectedField);
    const newArray = rows.map((obj) => ({ ...obj })).filter((obj) => delete obj[selectedField]);
    setRows(newArray);
  };

  const deleteSelected = (ids) => {
    // console.log("Oops! Just deleted:", ids);
    ids.map(async (id) => {
      console.log("deleting:", id);
      await deleteDoc(doc(db, dataSource, id));
    });
  };

  const copyToNewCollection = () => {
    // const collectionRef = db.collection(selectedValue);
    rows.map(async (row) => {
      await setDoc(doc(db, selectedTarget, row.id), row);
    });
  };

  const handlePermanentChange = () => {
    console.log(rows);
    rows.map(async (row) => {
      await setDoc(doc(db, dataSource, row.id), row);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item justifyContent="flex-end">
        <FormControl fullWidth>
          <InputLabel id="dataSourceSelect">Selected Source</InputLabel>
          <Select onChange={handleSelect} style={{ width: "200px" }} size="small" value={dataSource}>
            {collections.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item justifyContent="flex-end">
        <FormControl fullWidth>
          <InputLabel id="dataTargetSelect">Selected Target</InputLabel>
          <Select onChange={handleSelectTarget} style={{ width: "200px" }} size="small" value={selectedTarget}>
            {collections.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Field ID</InputLabel>
              <Select label="Select Field" onChange={handleFieldChange} value={selectedField} style={{ width: "200px" }} size="small">
                <MenuItem>Select Field</MenuItem>
                {columns.map((column) => (
                  <MenuItem key={column.field} value={column.field}>
                    {column.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField label="Value" variant="outlined" value={selectedValue} onChange={handleValueChange} size="small" />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleBatchUpdate} size="small">
              Change
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={addColumn} size="small">
              Add Column
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={copyAndCreateColumn} size="small">
              Copy "{selectedField}" and Create Column "{selectedValue}"
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={deleteColumn} size="small">
              Delete Column "{selectedField}"
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={copyToNewCollection} size="small">
              Copy All to "{selectedTarget}"
            </Button>
          </Grid>
          <Grid item>
            <ConfirmDialog onConfirm={() => handlePermanentChange()}>
              <CloudSyncIcon fontSize="small" style={{ marginRight: "10px" }} />
              Finalize
            </ConfirmDialog>
          </Grid>
          {selectedIds.length > 0 && (
            <Grid item>
              <ConfirmDialog onConfirm={() => deleteSelected(selectedIds)}>
                <DeleteIcon fontSize="small" style={{ marginRight: "10px" }} />
                Delete
              </ConfirmDialog>
              {/* <Button variant="contained" onClick={handleBatchUpdate} variant="outlined" onClick={() => deleteSelected()}>
                Delete Selected
              </Button> */}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div style={{ height: "60vh", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            components={{
              Toolbar: GridToolbar,
            }}
            onSelectionModelChange={handleSelectionChange}
            onEditCellChange={handleEditCellChange}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default ShowDb;
