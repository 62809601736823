/** @format */
export function calculateDistances() {
  const cities = [
    {
      visitors: "1",
      City: "San Francisco",
      zipCode: "94109-6023",
      lat: 37.7929789,
      lng: -122.4212424,
    },
    {
      visitors: "2",
      City: "Dundas",
      zipCode: "L9H 4N8",
      lat: 43.2599259,
      lng: -79.940868,
    },
    {
      visitors: "3",
      City: "Årsta",
      zipCode: "12054",
      lat: 59.298572,
      lng: 18.0499684,
    },
    {
      visitors: "2",
      City: "Årsta",
      zipCode: "12057",
      lat: 59.2965475,
      lng: 18.0433218,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11255",
      lat: 59.3304997,
      lng: 18.0061127,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11622",
      lat: 59.3183437,
      lng: 18.0807468,
    },
    {
      visitors: "2",
      City: "Spånga",
      zipCode: "16347",
      lat: 59.370147,
      lng: 17.891974,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12647",
      lat: 59.30310060000001,
      lng: 17.9948274,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12154",
      lat: 59.2872186,
      lng: 18.1084212,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11252",
      lat: 59.3371223,
      lng: 17.9987951,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11750",
      lat: 59.3189319,
      lng: 18.0194102,
    },
    {
      visitors: "1",
      City: "Edmonton",
      zipCode: "T6R 0V3",
      lat: 53.4452181,
      lng: -113.552485,
    },
    {
      visitors: "3",
      City: "Trångsund",
      zipCode: "14264",
      lat: 59.2333647,
      lng: 18.1570187,
    },
    {
      visitors: "2",
      City: "Västerås",
      zipCode: "72338",
      lat: 59.6294025,
      lng: 16.5720828,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75220",
      lat: 59.8684038,
      lng: 17.6125938,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11252",
      lat: 59.3371223,
      lng: 17.9987951,
    },
    {
      visitors: "2",
      City: "Täby",
      zipCode: "18371",
      lat: 59.4360563,
      lng: 18.0774666,
    },
    {
      visitors: "1",
      City: "Huddinge",
      zipCode: "14141",
      lat: 59.2556161,
      lng: 17.9706454,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11727",
      lat: 59.3188049,
      lng: 18.046335,
    },
    {
      visitors: "1",
      City: "Bagarmossen",
      zipCode: "12847",
      lat: 59.2827044,
      lng: 18.1346562,
    },
    {
      visitors: "1",
      City: "Tullinge",
      zipCode: "14646",
      lat: 59.2109972,
      lng: 17.9134321,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11728",
      lat: 59.3176261,
      lng: 18.044173,
    },
    {
      visitors: "2",
      City: "Huddinge",
      zipCode: "14139",
      lat: 59.2596728,
      lng: 17.9839052,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75318",
      lat: 59.8471124,
      lng: 17.6614483,
    },
    {
      visitors: "1",
      City: "Calgary",
      zipCode: "T2Y 3N5",
      lat: 50.9002875,
      lng: -114.0976334,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11726",
      lat: 59.3169608,
      lng: 18.0488263,
    },
    {
      visitors: "2",
      City: "Sevelen",
      zipCode: "9475",
      lat: 47.1251828,
      lng: 9.478797199999999,
    },
    {
      visitors: "4",
      City: "Lidingö",
      zipCode: "18141",
      lat: 59.3542694,
      lng: 18.1855698,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12635",
      lat: 59.30018450000001,
      lng: 18.0074495,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11230",
      lat: 59.33273399999999,
      lng: 18.037369,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11829",
      lat: 59.3121569,
      lng: 18.0681005,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12154",
      lat: 59.2872186,
      lng: 18.1084212,
    },
    {
      visitors: "2",
      City: "Upplands Väsby",
      zipCode: "19467",
      lat: 59.4881501,
      lng: 17.9468851,
    },
    {
      visitors: "1",
      City: "Tyresö",
      zipCode: "13543",
      lat: 59.24025160000001,
      lng: 18.2380671,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11646",
      lat: 59.3185448,
      lng: 18.0728497,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11233",
      lat: 59.33592359999999,
      lng: 18.0358744,
    },
    {
      visitors: "1",
      City: "SANTA CRUZ DE TENERIFE",
      zipCode: "38007",
      lat: 28.4640211,
      lng: -16.2669247,
    },
    {
      visitors: "4",
      City: "Johanneshov",
      zipCode: "12146",
      lat: 59.29829720000001,
      lng: 18.1038021,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12070",
      lat: 59.30728790000001,
      lng: 18.1111431,
    },
    {
      visitors: "1",
      City: "Appenweier",
      zipCode: "77767",
      lat: 48.5476784,
      lng: 7.9744954,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11535",
      lat: 59.3450277,
      lng: 18.0983595,
    },
    {
      visitors: "1",
      City: "Göteborg",
      zipCode: "41522",
      lat: 57.7377142,
      lng: 12.0194705,
    },
    {
      visitors: "2",
      City: "Årsta",
      zipCode: "12039",
      lat: 59.2999432,
      lng: 18.0715683,
    },
    {
      visitors: "1",
      City: "Ichikawa",
      zipCode: "2720123",
      lat: 35.6796936,
      lng: 139.927079,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11421",
      lat: 59.3523354,
      lng: 18.0590139,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13172",
      lat: 59.31745950000001,
      lng: 18.1258105,
    },
    {
      visitors: "2",
      City: "Oslo",
      zipCode: "0556",
      lat: 59.9266659,
      lng: 10.7625752,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12628",
      lat: 59.29790389999999,
      lng: 17.9999777,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12629",
      lat: 59.2974064,
      lng: 18.0034648,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11860",
      lat: 59.3039771,
      lng: 18.0745671,
    },
    {
      visitors: "1",
      City: "HENDAYE",
      zipCode: "64700",
      lat: 43.340423,
      lng: -1.7392812,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12060",
      lat: 59.3019122,
      lng: 18.0370145,
    },
    {
      visitors: "1",
      City: "Solna",
      zipCode: "16939",
      lat: 59.36680390000001,
      lng: 17.9902061,
    },
    {
      visitors: "1",
      City: "BARCELONA",
      zipCode: "08021",
      lat: 41.397617,
      lng: 2.1413415,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "16361",
      lat: 59.3903559,
      lng: 17.9068651,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "31006",
      lat: 59.32932349999999,
      lng: 18.0685808,
    },
    {
      visitors: "1",
      City: "Oslo",
      zipCode: "0752",
      lat: 59.9414893,
      lng: 10.6441288,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11361",
      lat: 59.3393414,
      lng: 18.0471034,
    },
    {
      visitors: "3",
      City: "Göteborg",
      zipCode: "41762",
      lat: 57.70298049999999,
      lng: 11.9163353,
    },
    {
      visitors: "2",
      City: "Farsta",
      zipCode: "12361",
      lat: 59.2548802,
      lng: 18.0875745,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12361",
      lat: 59.2548802,
      lng: 18.0875745,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12241",
      lat: 59.28009220000001,
      lng: 18.0439691,
    },
    {
      visitors: "1",
      City: "Sollentuna",
      zipCode: "191 44",
      lat: 59.41953699999999,
      lng: 17.9477363,
    },
    {
      visitors: "1",
      City: "Spånga",
      zipCode: "16343",
      lat: 59.37175299999999,
      lng: 17.927891,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11830",
      lat: 59.3119331,
      lng: 18.0742087,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75444",
      lat: 59.8682314,
      lng: 17.6791596,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11638",
      lat: 59.31045859999999,
      lng: 18.0890415,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12455",
      lat: 59.26699319999999,
      lng: 18.0419635,
    },
    {
      visitors: "1",
      City: "Göteborg",
      zipCode: "41468",
      lat: 57.6867861,
      lng: 11.9315504,
    },
    {
      visitors: "1",
      City: "Söderhamn",
      zipCode: "82630",
      lat: 61.30381989999999,
      lng: 17.0589494,
    },
    {
      visitors: "2",
      City: "Sundbyberg",
      zipCode: "17273",
      lat: 59.3673762,
      lng: 17.9613382,
    },
    {
      visitors: "1",
      City: "Gustavsberg",
      zipCode: "13434",
      lat: 59.3356305,
      lng: 18.3861127,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12944",
      lat: 59.2931988,
      lng: 17.9573203,
    },
    {
      visitors: "3",
      City: "Årsta",
      zipCode: "12060",
      lat: 59.3019122,
      lng: 18.0370145,
    },
    {
      visitors: "2",
      City: "Solna",
      zipCode: "16939",
      lat: 59.36680390000001,
      lng: 17.9902061,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11637",
      lat: 59.31242160000001,
      lng: 18.0792381,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11138",
      lat: 59.3376433,
      lng: 18.0654819,
    },
    {
      visitors: "2",
      City: "Årsta",
      zipCode: "12056",
      lat: 59.2925275,
      lng: 18.0506254,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11229",
      lat: 59.3324448,
      lng: 18.0406945,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11726",
      lat: 59.3169608,
      lng: 18.0488263,
    },
    {
      visitors: "2",
      City: "Årsta",
      zipCode: "12053",
      lat: 59.300929,
      lng: 18.0542907,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11368",
      lat: 59.3483105,
      lng: 18.0338022,
    },
    {
      visitors: "1",
      City: "Lidingö",
      zipCode: "18161",
      lat: 59.3398201,
      lng: 18.1721703,
    },
    {
      visitors: "1",
      City: "Norrköping",
      zipCode: "60235",
      lat: 58.5821652,
      lng: 16.1899022,
    },
    {
      visitors: "1",
      City: "Hässelby",
      zipCode: "16553",
      lat: 59.3703538,
      lng: 17.8484156,
    },
    {
      visitors: "2",
      City: "Zürich",
      zipCode: "8005",
      lat: 47.3893214,
      lng: 8.5131587,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12635",
      lat: 59.30018450000001,
      lng: 18.0074495,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11756",
      lat: 59.3036688,
      lng: 18.0325305,
    },
    {
      visitors: "1",
      City: "Lidingö",
      zipCode: "18148",
      lat: 59.3754397,
      lng: 18.1936855,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11559",
      lat: 59.3452485,
      lng: 18.1110931,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11750",
      lat: 59.3189319,
      lng: 18.0194102,
    },
    {
      visitors: "1",
      City: "Munsö",
      zipCode: "17891",
      lat: 59.3757512,
      lng: 17.5987563,
    },
    {
      visitors: "2",
      City: "NORTH PERTH",
      zipCode: "6906",
      lat: -31.9273844,
      lng: 115.852492,
    },
    {
      visitors: "1",
      City: "'S-GRAVENHAGE",
      zipCode: "2561 TX",
      lat: 52.0769986,
      lng: 4.280090299999999,
    },
    {
      visitors: "1",
      City: "Reno",
      zipCode: "89511-4761",
      lat: 39.3809028,
      lng: -119.9277947,
    },
    {
      visitors: "2",
      City: "Lund",
      zipCode: "22646",
      lat: 55.7222132,
      lng: 13.2176092,
    },
    {
      visitors: "2",
      City: "Nacka Strand",
      zipCode: "13152",
      lat: 59.31764539999999,
      lng: 18.1607449,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12079",
      lat: 59.3031194,
      lng: 18.0974169,
    },
    {
      visitors: "1",
      City: "Örebro",
      zipCode: "70224",
      lat: 59.2660965,
      lng: 15.2093626,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11639",
      lat: 59.3102435,
      lng: 18.084054,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11824",
      lat: 59.31996059999999,
      lng: 18.06296,
    },
    {
      visitors: "2",
      City: "Enskededalen",
      zipCode: "12132",
      lat: 59.288124,
      lng: 18.0891528,
    },
    {
      visitors: "2",
      City: "Älvsjö",
      zipCode: "12573",
      lat: 59.2884782,
      lng: 18.0373359,
    },
    {
      visitors: "2",
      City: "Lima",
      zipCode: "78064",
      lat: 60.91627320000001,
      lng: 13.1691588,
    },
    {
      visitors: "1",
      City: "Sundbyberg",
      zipCode: "17443",
      lat: 59.3737775,
      lng: 17.9686476,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11732",
      lat: 59.3198462,
      lng: 18.040352,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11640",
      lat: 59.31196459999999,
      lng: 18.0837254,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12064",
      lat: 59.30459020000001,
      lng: 18.0988338,
    },
    {
      visitors: "2",
      City: "Solna",
      zipCode: "16959",
      lat: 59.36393449999999,
      lng: 18.0064324,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75426",
      lat: 59.8789732,
      lng: 17.652243,
    },
    {
      visitors: "1",
      City: "Mirassol",
      zipCode: "15130055",
      lat: -20.8153322,
      lng: -49.51320579999999,
    },
    {
      visitors: "1",
      City: "Sollentuna",
      zipCode: "19143",
      lat: 59.41423400000001,
      lng: 17.9850728,
    },
    {
      visitors: "2",
      City: "Winterthur",
      zipCode: "8400",
      lat: 47.485687,
      lng: 8.7292922,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11539",
      lat: 59.34788439999999,
      lng: 18.0991992,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11827",
      lat: 59.311614,
      lng: 18.0705928,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11448",
      lat: 59.33879330000001,
      lng: 18.0795415,
    },
    {
      visitors: "2",
      City: "Nacka",
      zipCode: "13139",
      lat: 59.31278,
      lng: 18.1790219,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12653",
      lat: 59.30652240000001,
      lng: 17.9838643,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11761",
      lat: 59.31138329999999,
      lng: 18.0253904,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75334",
      lat: 59.8780353,
      lng: 17.6320128,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11255",
      lat: 59.3304997,
      lng: 18.0061127,
    },
    {
      visitors: "2",
      City: "Nacka",
      zipCode: "13133",
      lat: 59.2911155,
      lng: 18.1692636,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12249",
      lat: 59.2609651,
      lng: 18.0818234,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12629",
      lat: 59.2974064,
      lng: 18.0034648,
    },
    {
      visitors: "1",
      City: "Solna",
      zipCode: "17149",
      lat: 59.35145989999999,
      lng: 17.9843165,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11862",
      lat: 59.30889029999999,
      lng: 18.0753247,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11247",
      lat: 59.3357892,
      lng: 18.0303035,
    },
    {
      visitors: "2",
      City: "Gustavsberg",
      zipCode: "13431",
      lat: 59.328938,
      lng: 18.3706928,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11824",
      lat: 59.31996059999999,
      lng: 18.06296,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11740",
      lat: 59.31734159999999,
      lng: 18.0227335,
    },
    {
      visitors: "1",
      City: "Linghem",
      zipCode: "58565",
      lat: 58.4317822,
      lng: 15.7802153,
    },
    {
      visitors: "3",
      City: "Malmö",
      zipCode: "21438",
      lat: 55.59542769999999,
      lng: 13.0258558,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12432",
      lat: 59.272704,
      lng: 18.0436291,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11661",
      lat: 59.30814040000001,
      lng: 18.0805588,
    },
    {
      visitors: "1",
      City: "Tucson",
      zipCode: "85719-3068",
      lat: 32.2438145,
      lng: -110.9447027,
    },
    {
      visitors: "1",
      City: "Hässelby",
      zipCode: "16557",
      lat: 59.37057549999999,
      lng: 17.8389402,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12632",
      lat: 59.30246469999999,
      lng: 18.0149229,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12632",
      lat: 59.30246469999999,
      lng: 18.0149229,
    },
    {
      visitors: "2",
      City: "Uppsala",
      zipCode: "75329",
      lat: 59.8643491,
      lng: 17.6473871,
    },
    {
      visitors: "1",
      City: "Lerdala",
      zipCode: "54197",
      lat: 58.47676569999999,
      lng: 13.711188,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11140",
      lat: 59.3394881,
      lng: 18.0629894,
    },
    {
      visitors: "1",
      City: "Ljungsbro",
      zipCode: "58574",
      lat: 58.4977499,
      lng: 15.4943216,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13146",
      lat: 59.32235420000001,
      lng: 18.2106977,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12058",
      lat: 59.29982289999999,
      lng: 18.0438234,
    },
    {
      visitors: "2",
      City: "Nacka",
      zipCode: "13175",
      lat: 59.3160409,
      lng: 18.1423506,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12476",
      lat: 59.25863,
      lng: 18.0094438,
    },
    {
      visitors: "2",
      City: "Skarpnäck",
      zipCode: "12838",
      lat: 59.27395809999999,
      lng: 18.1126974,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11849",
      lat: 59.3179366,
      lng: 18.0563075,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11767",
      lat: 59.3179883,
      lng: 18.0077781,
    },
    {
      visitors: "1",
      City: "Saltsjö-Boo",
      zipCode: "13243",
      lat: 59.3169698,
      lng: 18.2479527,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11138",
      lat: 59.3376433,
      lng: 18.0654819,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12432",
      lat: 59.272704,
      lng: 18.0436291,
    },
    {
      visitors: "2",
      City: "Saltsjö-Boo",
      zipCode: "13236",
      lat: 59.3320304,
      lng: 18.3187143,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11822",
      lat: 59.31937139999999,
      lng: 18.0618785,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "12071",
      lat: 59.3087092,
      lng: 18.1038324,
    },
    {
      visitors: "1",
      City: "Helsinki",
      zipCode: "00150",
      lat: 60.15114759999999,
      lng: 24.9235532,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11642",
      lat: 59.3092808,
      lng: 18.0868777,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16738",
      lat: 59.33946329999999,
      lng: 17.9928083,
    },
    {
      visitors: "2",
      City: "Årsta",
      zipCode: "12050",
      lat: 59.2948111,
      lng: 18.0632524,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12947",
      lat: 59.2927139,
      lng: 17.9659512,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11625",
      lat: 59.3159875,
      lng: 18.0764192,
    },
    {
      visitors: "2",
      City: "Johanneshov",
      zipCode: "12150",
      lat: 59.2979718,
      lng: 18.1164323,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16845",
      lat: 59.34257479999999,
      lng: 17.8871032,
    },
    {
      visitors: "3",
      City: "Halmstad",
      zipCode: "30293",
      lat: 56.66606,
      lng: 12.8093096,
    },
    {
      visitors: "2",
      City: "Johanneshov",
      zipCode: "12135",
      lat: 59.2906003,
      lng: 18.0887437,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11636",
      lat: 59.3141967,
      lng: 18.0902143,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11531",
      lat: 59.34258080000001,
      lng: 18.0868707,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12632",
      lat: 59.30246469999999,
      lng: 18.0149229,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12240",
      lat: 59.26856259999999,
      lng: 18.0881073,
    },
    {
      visitors: "2",
      City: "Nacka",
      zipCode: "13136",
      lat: 59.308732,
      lng: 18.1347586,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11820",
      lat: 59.3203432,
      lng: 18.0667845,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12458",
      lat: 59.2618757,
      lng: 18.043949,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11625",
      lat: 59.3159875,
      lng: 18.0764192,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11625",
      lat: 59.3159875,
      lng: 18.0764192,
    },
    {
      visitors: "2",
      City: "Sollentuna",
      zipCode: "19164",
      lat: 59.4325558,
      lng: 17.9425355,
    },
    {
      visitors: "1",
      City: "Brooklyn",
      zipCode: "11221-6372",
      lat: 40.6903213,
      lng: -73.9271644,
    },
    {
      visitors: "1",
      City: "Rönninge",
      zipCode: "14431",
      lat: 59.20141570000001,
      lng: 17.7607207,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11553",
      lat: 59.3411242,
      lng: 18.1009289,
    },
    {
      visitors: "1",
      City: "Seoul",
      zipCode: "03007",
      lat: 37.56521290000001,
      lng: 126.9773517,
    },
    {
      visitors: "2",
      City: "Södertälje",
      zipCode: "15157",
      lat: 59.22748999999999,
      lng: 17.5692172,
    },
    {
      visitors: "2",
      City: "Zürich",
      zipCode: "8003",
      lat: 47.37220629999999,
      lng: 8.5162396,
    },
    {
      visitors: "2",
      City: "Brandon",
      zipCode: "IP27 9GD",
      lat: 52.38505989999999,
      lng: 0.5416854,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11728",
      lat: 59.3176261,
      lng: 18.044173,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11526",
      lat: 59.3365032,
      lng: 18.0994189,
    },
    {
      visitors: "1",
      City: "London",
      zipCode: "SW1V 3QX",
      lat: 51.4865198,
      lng: -0.1351375,
    },
    {
      visitors: "1",
      City: "Northville",
      zipCode: "48168-2601",
      lat: 42.4311464,
      lng: -83.4832692,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11352",
      lat: 59.34406019999999,
      lng: 18.0583375,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12052",
      lat: 59.29488430000001,
      lng: 18.054947,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11523",
      lat: 59.33356089999999,
      lng: 18.0965827,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11853",
      lat: 59.3151166,
      lng: 18.0513174,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11264",
      lat: 59.32267,
      lng: 17.9958121,
    },
    {
      visitors: "1",
      City: "Täby",
      zipCode: "18768",
      lat: 59.46818320000001,
      lng: 18.0872061,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11351",
      lat: 59.3447329,
      lng: 18.0588375,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11256",
      lat: 59.33132930000001,
      lng: 18.0002944,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12051",
      lat: 59.3016082,
      lng: 18.0599405,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12930",
      lat: 59.2931754,
      lng: 17.9862008,
    },
    {
      visitors: "1",
      City: "Saltsjö-Duvnäs",
      zipCode: "13150",
      lat: 59.3008006,
      lng: 18.1982809,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "113 45",
      lat: 59.34790940000001,
      lng: 18.0470283,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12354",
      lat: 59.25389449999999,
      lng: 18.0733111,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12953",
      lat: 59.28397870000001,
      lng: 17.9646367,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11359",
      lat: 59.3396915,
      lng: 18.0576664,
    },
    {
      visitors: "1",
      City: "Sundsvall",
      zipCode: "85243",
      lat: 62.38472100000001,
      lng: 17.3059514,
    },
    {
      visitors: "2",
      City: "Schlieren",
      zipCode: "8952",
      lat: 47.3983398,
      lng: 8.446563099999999,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11633",
      lat: 59.31377189999999,
      lng: 18.0853919,
    },
    {
      visitors: "2",
      City: "Årsta",
      zipCode: "12054",
      lat: 59.298572,
      lng: 18.0499684,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12358",
      lat: 59.25763719999999,
      lng: 18.0761314,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12039",
      lat: 59.2999432,
      lng: 18.0715683,
    },
    {
      visitors: "1",
      City: "Sundsvall",
      zipCode: "85242",
      lat: 62.3876202,
      lng: 17.2862441,
    },
    {
      visitors: "1",
      City: "Lidingö",
      zipCode: "18155",
      lat: 59.34440350000001,
      lng: 18.1608684,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11750",
      lat: 59.3189319,
      lng: 18.0194102,
    },
    {
      visitors: "5",
      City: "Stockholm",
      zipCode: "11761",
      lat: 59.31138329999999,
      lng: 18.0253904,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11425",
      lat: 59.3428511,
      lng: 18.0654899,
    },
    {
      visitors: "4",
      City: "Skärholmen",
      zipCode: "12732",
      lat: 59.30003349999999,
      lng: 17.9353987,
    },
    {
      visitors: "4",
      City: "Stockholm",
      zipCode: "11243",
      lat: 59.33270169999999,
      lng: 18.0167517,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11769",
      lat: 59.3149276,
      lng: 17.9875107,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11848",
      lat: 59.3179864,
      lng: 18.0624583,
    },
    {
      visitors: "1",
      City: "Lidingö",
      zipCode: "18146",
      lat: 59.37341960000001,
      lng: 18.1663352,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11630",
      lat: 59.3156702,
      lng: 18.0942083,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12053",
      lat: 59.300929,
      lng: 18.0542907,
    },
    {
      visitors: "1",
      City: "Enskede Gård",
      zipCode: "12047",
      lat: 59.2901978,
      lng: 18.0668989,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12154",
      lat: 59.2872186,
      lng: 18.1084212,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11326",
      lat: 59.34433610000001,
      lng: 18.044696,
    },
    {
      visitors: "3",
      City: "Örebro",
      zipCode: "70382",
      lat: 59.2837775,
      lng: 15.261588,
    },
    {
      visitors: "2",
      City: "Vicenza",
      zipCode: "36100",
      lat: 45.5461815,
      lng: 11.5413779,
    },
    {
      visitors: "2",
      City: "14467 Potsdam",
      zipCode: "14467",
      lat: 52.39976910000001,
      lng: 13.0665558,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11734",
      lat: 59.3163132,
      lng: 18.0364435,
    },
    {
      visitors: "1",
      City: "Lisboa",
      zipCode: "1170-378",
      lat: 38.7194937,
      lng: -9.1239852,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11824",
      lat: 59.31996059999999,
      lng: 18.06296,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12154",
      lat: 59.2872186,
      lng: 18.1084212,
    },
    {
      visitors: "1",
      City: "Gävle",
      zipCode: "80255",
      lat: 60.6656551,
      lng: 17.1580821,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11358",
      lat: 59.3404938,
      lng: 18.0611609,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11666",
      lat: 59.3079362,
      lng: 18.0858772,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11419",
      lat: 59.3598885,
      lng: 18.0530312,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12359",
      lat: 59.2556382,
      lng: 18.0900656,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12635",
      lat: 59.30018450000001,
      lng: 18.0074495,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12951",
      lat: 59.2846625,
      lng: 17.9702772,
    },
    {
      visitors: "1",
      City: "Jönköping",
      zipCode: "55313",
      lat: 57.7825547,
      lng: 14.1457433,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11259",
      lat: 59.32908450000001,
      lng: 18.013427,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13131",
      lat: 59.315617,
      lng: 18.1334547,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11824",
      lat: 59.31996059999999,
      lng: 18.06296,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11225",
      lat: 59.33027149999999,
      lng: 18.0480923,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11629",
      lat: 59.31471440000001,
      lng: 18.0833575,
    },
    {
      visitors: "1",
      City: "LE PECQ",
      zipCode: "78230",
      lat: 48.8934955,
      lng: 2.1089728,
    },
    {
      visitors: "1",
      City: "Vagnhärad",
      zipCode: "61994",
      lat: 58.9636027,
      lng: 17.5269353,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12135",
      lat: 59.2906003,
      lng: 18.0887437,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11750",
      lat: 59.3189319,
      lng: 18.0194102,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12652",
      lat: 59.30729299999999,
      lng: 17.9915032,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12932",
      lat: 59.2977865,
      lng: 17.9825444,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12056",
      lat: 59.2925275,
      lng: 18.0506254,
    },
    {
      visitors: "2",
      City: "Villars-Sur-Glâne",
      zipCode: "1752",
      lat: 46.7934868,
      lng: 7.116231,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11422",
      lat: 59.3481825,
      lng: 18.0633348,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11822",
      lat: 59.31937139999999,
      lng: 18.0618785,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11731",
      lat: 59.3186634,
      lng: 18.0356141,
    },
    {
      visitors: "2",
      City: "Årsta",
      zipCode: "12038",
      lat: 59.2980063,
      lng: 18.0669126,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11829",
      lat: 59.3121569,
      lng: 18.0681005,
    },
    {
      visitors: "1",
      City: "Oslo",
      zipCode: "0550",
      lat: 59.91899969999999,
      lng: 10.758037,
    },
    {
      visitors: "1",
      City: "Linköping",
      zipCode: "58227",
      lat: 58.4086037,
      lng: 15.6199407,
    },
    {
      visitors: "1",
      City: "Solna",
      zipCode: "16931",
      lat: 59.36121430000001,
      lng: 17.9889637,
    },
    {
      visitors: "1",
      City: "Moledo",
      zipCode: "4910-691",
      lat: 41.8490076,
      lng: -8.856517499999999,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11668",
      lat: 59.3057526,
      lng: 18.0855397,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11140",
      lat: 59.3394881,
      lng: 18.0629894,
    },
    {
      visitors: "1",
      City: "Eskilstuna",
      zipCode: "63360",
      lat: 59.3788103,
      lng: 16.5166282,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11862",
      lat: 59.30889029999999,
      lng: 18.0753247,
    },
    {
      visitors: "1",
      City: "Lausanne",
      zipCode: "1005",
      lat: 46.52013909999999,
      lng: 6.6427496,
    },
    {
      visitors: "1",
      City: "Skärholmen",
      zipCode: "12733",
      lat: 59.2925416,
      lng: 17.9279509,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12634",
      lat: 59.3022899,
      lng: 18.0109368,
    },
    {
      visitors: "1",
      City: "Södertälje",
      zipCode: "15167",
      lat: 59.17210309999999,
      lng: 17.5993182,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12247",
      lat: 59.2727321,
      lng: 18.0642114,
    },
    {
      visitors: "4",
      City: "Stockholm",
      zipCode: "11624",
      lat: 59.3131745,
      lng: 18.0765799,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11826",
      lat: 59.3156423,
      lng: 18.068439,
    },
    {
      visitors: "2",
      City: "Mililani",
      zipCode: "96789",
      lat: 21.4513314,
      lng: -158.0152807,
    },
    {
      visitors: "1",
      City: "Sundsvall",
      zipCode: "85234",
      lat: 62.39617119999999,
      lng: 17.2934324,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12236",
      lat: 59.32932349999999,
      lng: 18.0685808,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11429",
      lat: 59.34096289999999,
      lng: 18.0669843,
    },
    {
      visitors: "1",
      City: "Flint",
      zipCode: "48503-3554",
      lat: 43.0145534,
      lng: -83.6720756,
    },
    {
      visitors: "2",
      City: "Västerås",
      zipCode: "72359",
      lat: 59.60642389999999,
      lng: 16.5748394,
    },
    {
      visitors: "1",
      City: "Linköping",
      zipCode: "58224",
      lat: 58.4110533,
      lng: 15.6193685,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13171",
      lat: 59.31543139999999,
      lng: 18.1088404,
    },
    {
      visitors: "1",
      City: "Frankfurt am Main",
      zipCode: "60389",
      lat: 50.1495363,
      lng: 8.726027499999999,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16878",
      lat: 59.364959,
      lng: 17.9500921,
    },
    {
      visitors: "1",
      City: "Brighton",
      zipCode: "BN2 5JP",
      lat: 50.819076,
      lng: -0.1092769,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "167 74",
      lat: 59.330921,
      lng: 17.9273459,
    },
    {
      visitors: "2",
      City: "Eskilstuna",
      zipCode: "63350",
      lat: 59.3669072,
      lng: 16.5310162,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12038",
      lat: 59.2980063,
      lng: 18.0669126,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16875",
      lat: 59.3435472,
      lng: 17.9379458,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11228",
      lat: 59.33442669999999,
      lng: 18.0463503,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12231",
      lat: 59.2839296,
      lng: 18.0924643,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11847",
      lat: 59.3174468,
      lng: 18.0675278,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11532",
      lat: 59.3429221,
      lng: 18.0922798,
    },
    {
      visitors: "1",
      City: "Waterford",
      zipCode: "X91X0W8",
      lat: 52.2438666,
      lng: -7.1194388,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75646",
      lat: 59.8174854,
      lng: 17.6059542,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11528",
      lat: 59.3424775,
      lng: 18.1122499,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12153",
      lat: 59.2900858,
      lng: 18.1247144,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11634",
      lat: 59.31365819999999,
      lng: 18.097861,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11244",
      lat: 59.3337853,
      lng: 18.011764,
    },
    {
      visitors: "1",
      City: "Motala",
      zipCode: "59127",
      lat: 58.5311181,
      lng: 15.0209584,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12057",
      lat: 59.2965475,
      lng: 18.0433218,
    },
    {
      visitors: "1",
      City: "Sollentuna",
      zipCode: "19146",
      lat: 59.4302185,
      lng: 17.9143775,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11825",
      lat: 59.3217552,
      lng: 18.0543176,
    },
    {
      visitors: "2",
      City: "Sundbyberg",
      zipCode: "17461",
      lat: 59.3810514,
      lng: 17.9514925,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11765",
      lat: 59.3119428,
      lng: 18.008444,
    },
    {
      visitors: "1",
      City: "Umeå",
      zipCode: "90729",
      lat: 63.8156737,
      lng: 20.3114674,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11249",
      lat: 59.3375856,
      lng: 18.0242345,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12137",
      lat: 59.2952652,
      lng: 18.093823,
    },
    {
      visitors: "1",
      City: "Vällingby",
      zipCode: "16268",
      lat: 59.3653564,
      lng: 17.8627368,
    },
    {
      visitors: "6",
      City: "Stockholm",
      zipCode: "11425",
      lat: 59.3428511,
      lng: 18.0654899,
    },
    {
      visitors: "2",
      City: "Oakham",
      zipCode: "LE159RE",
      lat: 52.59386310000001,
      lng: -0.7283137,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12234",
      lat: 59.28191320000001,
      lng: 18.0909642,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11771",
      lat: 59.3167961,
      lng: 17.9986892,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12931",
      lat: 59.29174210000001,
      lng: 17.9832141,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11367",
      lat: 59.34903049999999,
      lng: 18.0378786,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12455",
      lat: 59.26699319999999,
      lng: 18.0419635,
    },
    {
      visitors: "1",
      City: "Danderyd",
      zipCode: "18253",
      lat: 59.4023919,
      lng: 18.0420733,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11630",
      lat: 59.3156702,
      lng: 18.0942083,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12452",
      lat: 59.26656629999999,
      lng: 18.037151,
    },
    {
      visitors: "1",
      City: "Lidingö",
      zipCode: "18161",
      lat: 59.3398201,
      lng: 18.1721703,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11221",
      lat: 59.32820659999999,
      lng: 18.0430189,
    },
    {
      visitors: "1",
      City: "Herenthout",
      zipCode: "2270",
      lat: 51.1356598,
      lng: 4.7460081,
    },
    {
      visitors: "1",
      City: "New York",
      zipCode: "10035-1281",
      lat: 40.794065,
      lng: -73.9271644,
    },
    {
      visitors: "1",
      City: "North Hills",
      zipCode: "91343-7819",
      lat: 34.2386799,
      lng: -118.4805569,
    },
    {
      visitors: "1",
      City: "Enskededalen",
      zipCode: "12130",
      lat: 59.2875429,
      lng: 18.0957961,
    },
    {
      visitors: "1",
      City: "Vagnhärad",
      zipCode: "61994",
      lat: 58.9636027,
      lng: 17.5269353,
    },
    {
      visitors: "2",
      City: "Lidingö",
      zipCode: "18144",
      lat: 59.3774415,
      lng: 18.1383511,
    },
    {
      visitors: "1",
      City: "Solna",
      zipCode: "16960",
      lat: 59.359652,
      lng: 18.0077653,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12055",
      lat: 59.2963017,
      lng: 18.0476402,
    },
    {
      visitors: "2",
      City: "San Francisco",
      zipCode: "94133-7290",
      lat: 37.8059887,
      lng: -122.4099154,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "17234",
      lat: 59.36524049999999,
      lng: 17.9645861,
    },
    {
      visitors: "1",
      City: "Gustavsberg",
      zipCode: "13437",
      lat: 59.33391109999999,
      lng: 18.4081091,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11361",
      lat: 59.3393414,
      lng: 18.0471034,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11637",
      lat: 59.31242160000001,
      lng: 18.0792381,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11661",
      lat: 59.30814040000001,
      lng: 18.0805588,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11854",
      lat: 59.3147506,
      lng: 18.0603755,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12642",
      lat: 59.2904947,
      lng: 17.9893568,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11328",
      lat: 59.3416126,
      lng: 18.0494344,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16744",
      lat: 59.33467470000001,
      lng: 17.9924796,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11327",
      lat: 59.343504,
      lng: 18.0505177,
    },
    {
      visitors: "5",
      City: "Stockholm",
      zipCode: "11733",
      lat: 59.32239069999999,
      lng: 18.0290508,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11733",
      lat: 59.32239069999999,
      lng: 18.0290508,
    },
    {
      visitors: "1",
      City: "Vagnhärad",
      zipCode: "61994",
      lat: 58.9636027,
      lng: 17.5269353,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11741",
      lat: 59.31435019999999,
      lng: 18.0436713,
    },
    {
      visitors: "2",
      City: "Ennis",
      zipCode: "V95 W7C3",
      lat: 52.928926,
      lng: -9.3416675,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11737",
      lat: 59.31781279999999,
      lng: 18.0285502,
    },
    {
      visitors: "2",
      City: "Johanneshov",
      zipCode: "12144",
      lat: 59.2949099,
      lng: 18.0755459,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12653",
      lat: 59.30652240000001,
      lng: 17.9838643,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "117 26",
      lat: 59.3169608,
      lng: 18.0488263,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11726",
      lat: 59.3169608,
      lng: 18.0488263,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11346",
      lat: 59.35097649999999,
      lng: 18.0476967,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12931",
      lat: 59.29174210000001,
      lng: 17.9832141,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11338",
      lat: 59.3419191,
      lng: 18.0290587,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11848",
      lat: 59.3179864,
      lng: 18.0624583,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12136",
      lat: 59.292824,
      lng: 18.0875031,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13135",
      lat: 59.3082204,
      lng: 18.1227845,
    },
    {
      visitors: "1",
      City: "Saltsjö-Boo",
      zipCode: "13248",
      lat: 59.3263828,
      lng: 18.2240432,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11864",
      lat: 59.3131127,
      lng: 18.0601238,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11624",
      lat: 59.3131745,
      lng: 18.0765799,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12052",
      lat: 59.29488430000001,
      lng: 18.054947,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11758",
      lat: 59.3076962,
      lng: 18.0303731,
    },
    {
      visitors: "2",
      City: "Bagarmossen",
      zipCode: "12844",
      lat: 59.27263550000001,
      lng: 18.142589,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11750",
      lat: 59.3189319,
      lng: 18.0194102,
    },
    {
      visitors: "1",
      City: "Mölnbo",
      zipCode: "15396",
      lat: 59.0337179,
      lng: 17.4193674,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11240",
      lat: 59.33166970000001,
      lng: 18.0278909,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11240",
      lat: 59.33166970000001,
      lng: 18.0278909,
    },
    {
      visitors: "1",
      City: "London",
      zipCode: "SE22 0NQ",
      lat: 51.4520012,
      lng: -0.0595275,
    },
    {
      visitors: "2",
      City: "Brandbergen",
      zipCode: "13663",
      lat: 59.1691094,
      lng: 18.1643156,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11531",
      lat: 59.34258080000001,
      lng: 18.0868707,
    },
    {
      visitors: "1",
      City: "Älvsjö",
      zipCode: "12545",
      lat: 59.27927409999999,
      lng: 18.0054609,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12944",
      lat: 59.2931988,
      lng: 17.9573203,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12635",
      lat: 59.30018450000001,
      lng: 18.0074495,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12056",
      lat: 59.2925275,
      lng: 18.0506254,
    },
    {
      visitors: "1",
      City: "Norsborg",
      zipCode: "14565",
      lat: 59.2353432,
      lng: 17.8097478,
    },
    {
      visitors: "2",
      City: "Johanneshov",
      zipCode: "12138",
      lat: 59.29619499999999,
      lng: 18.1003061,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11215",
      lat: 59.338047,
      lng: 18.0050293,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11458",
      lat: 59.3369938,
      lng: 18.0856104,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16754",
      lat: 59.3266462,
      lng: 17.9678926,
    },
    {
      visitors: "2",
      City: "Johanneshov",
      zipCode: "12150",
      lat: 59.2979718,
      lng: 18.1164323,
    },
    {
      visitors: "1",
      City: "Kista",
      zipCode: "16453",
      lat: 59.40066789999999,
      lng: 17.9482831,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13165",
      lat: 59.3023385,
      lng: 18.1171117,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12030",
      lat: 59.30197709999999,
      lng: 18.0885223,
    },
    {
      visitors: "1",
      City: "Skarpnäck",
      zipCode: "12838",
      lat: 59.27395809999999,
      lng: 18.1126974,
    },
    {
      visitors: "2",
      City: "Köln",
      zipCode: "50733",
      lat: 50.96482710000001,
      lng: 6.9538234,
    },
    {
      visitors: "1",
      City: "Västerås",
      zipCode: "72219",
      lat: 59.61869009999999,
      lng: 16.5488925,
    },
    {
      visitors: "1",
      City: "Lund",
      zipCode: "22354",
      lat: 55.696737,
      lng: 13.1920055,
    },
    {
      visitors: "2",
      City: "Älvsjö",
      zipCode: "125 47",
      lat: 59.2819643,
      lng: 18.0074521,
    },
    {
      visitors: "2",
      City: "Hässelby",
      zipCode: "16557",
      lat: 59.37057549999999,
      lng: 17.8389402,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11453",
      lat: 59.33413720000001,
      lng: 18.0847723,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11666",
      lat: 59.3079362,
      lng: 18.0858772,
    },
    {
      visitors: "1",
      City: "Sundbyberg",
      zipCode: "17278",
      lat: 59.3649252,
      lng: 17.9746515,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11225",
      lat: 59.33027149999999,
      lng: 18.0480923,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11766",
      lat: 59.3159005,
      lng: 18.014591,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12039",
      lat: 59.2999432,
      lng: 18.0715683,
    },
    {
      visitors: "2",
      City: "Älvsjö",
      zipCode: "12553",
      lat: 59.2722225,
      lng: 17.9636588,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11848",
      lat: 59.3179864,
      lng: 18.0624583,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11322",
      lat: 59.3408062,
      lng: 18.0433622,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11761",
      lat: 59.31138329999999,
      lng: 18.0253904,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11852",
      lat: 59.315929,
      lng: 18.0625382,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16838",
      lat: 59.3406511,
      lng: 17.9216682,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11249",
      lat: 59.3375856,
      lng: 18.0242345,
    },
    {
      visitors: "1",
      City: "Västerås",
      zipCode: "72358",
      lat: 59.603056,
      lng: 16.5693667,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11739",
      lat: 59.3140818,
      lng: 18.0325367,
    },
    {
      visitors: "1",
      City: "Lidingö",
      zipCode: "18141",
      lat: 59.3542694,
      lng: 18.1855698,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12636",
      lat: 59.30151129999999,
      lng: 17.9981495,
    },
    {
      visitors: "1",
      City: "Mirassol",
      zipCode: "15130055",
      lat: -20.8153322,
      lng: -49.51320579999999,
    },
    {
      visitors: "2",
      City: "Edinburgh",
      zipCode: "EH3 5LL",
      lat: 55.9641372,
      lng: -3.1986332,
    },
    {
      visitors: "1",
      City: "Kumla",
      zipCode: "69233",
      lat: 59.13356419999999,
      lng: 15.1270592,
    },
    {
      visitors: "1",
      City: "Sundbyberg",
      zipCode: "17271",
      lat: 59.3658363,
      lng: 17.968245,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11739",
      lat: 59.3140818,
      lng: 18.0325367,
    },
    {
      visitors: "2",
      City: "Killybegs",
      zipCode: "",
      lat: 54.6347625,
      lng: -8.4547084,
    },
    {
      visitors: "1",
      City: "Lidingö",
      zipCode: "18161",
      lat: 59.3398201,
      lng: 18.1721703,
    },
    {
      visitors: "5",
      City: "Stockholm",
      zipCode: "11853",
      lat: 59.3151166,
      lng: 18.0513174,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12234",
      lat: 59.28191320000001,
      lng: 18.0909642,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11862",
      lat: 59.30889029999999,
      lng: 18.0753247,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12634",
      lat: 59.3022899,
      lng: 18.0109368,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12652",
      lat: 59.30729299999999,
      lng: 17.9915032,
    },
    {
      visitors: "1",
      City: "Sundbyberg",
      zipCode: "17457",
      lat: 59.37589469999999,
      lng: 17.938027,
    },
    {
      visitors: "1",
      City: "Helsinki",
      zipCode: "00530",
      lat: 60.1809045,
      lng: 24.9526712,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11850",
      lat: 59.3164285,
      lng: 18.0590482,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11629",
      lat: 59.31471440000001,
      lng: 18.0833575,
    },
    {
      visitors: "1",
      City: "Sundsvall",
      zipCode: "85233",
      lat: 62.3918306,
      lng: 17.2859016,
    },
    {
      visitors: "1",
      City: "Sollentuna",
      zipCode: "19252",
      lat: 59.44851329999999,
      lng: 17.9743488,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11661",
      lat: 59.30814040000001,
      lng: 18.0805588,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12636",
      lat: 59.30151129999999,
      lng: 17.9981495,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11767",
      lat: 59.3179883,
      lng: 18.0077781,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12635",
      lat: 59.30018450000001,
      lng: 18.0074495,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11458",
      lat: 59.3369938,
      lng: 18.0856104,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11767",
      lat: 59.3179883,
      lng: 18.0077781,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11858",
      lat: 59.3092637,
      lng: 18.0714195,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11861",
      lat: 59.3054695,
      lng: 18.0589493,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11215",
      lat: 59.338047,
      lng: 18.0050293,
    },
    {
      visitors: "2",
      City: "Bromma",
      zipCode: "16751",
      lat: 59.3303737,
      lng: 17.9835081,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11264",
      lat: 59.32267,
      lng: 17.9958121,
    },
    {
      visitors: "3",
      City: "Västerhaninge",
      zipCode: "13736",
      lat: 59.132637,
      lng: 18.0718642,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12650",
      lat: 59.3079066,
      lng: 18.0054548,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "12932",
      lat: 59.2977865,
      lng: 17.9825444,
    },
    {
      visitors: "2",
      City: "Sundbyberg",
      zipCode: "17458",
      lat: 59.3737092,
      lng: 17.9377001,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "12062",
      lat: 59.30420359999999,
      lng: 18.0898573,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11415",
      lat: 59.37283759999999,
      lng: 18.0613711,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11646",
      lat: 59.3185448,
      lng: 18.0728497,
    },
    {
      visitors: "1",
      City: "Upplands Väsby",
      zipCode: "19446",
      lat: 59.52107479999999,
      lng: 17.8904672,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11822",
      lat: 59.31937139999999,
      lng: 18.0618785,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11842",
      lat: 59.31015600000001,
      lng: 18.0469908,
    },
    {
      visitors: "3",
      City: "Horsham",
      zipCode: "RH13 6RG",
      lat: 51.0151317,
      lng: -0.3030787,
    },
    {
      visitors: "1",
      City: "Sundbyberg",
      zipCode: "17268",
      lat: 59.3633198,
      lng: 17.9702454,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11543",
      lat: 59.35304730000001,
      lng: 18.0956333,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11851",
      lat: 59.3169244,
      lng: 18.0529817,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11667",
      lat: 59.30675269999999,
      lng: 18.0785613,
    },
    {
      visitors: "1",
      City: "Berlin",
      zipCode: "10119",
      lat: 52.530741,
      lng: 13.4058114,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12155",
      lat: 59.2853779,
      lng: 18.1160577,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12930",
      lat: 59.2931754,
      lng: 17.9862008,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11771",
      lat: 59.3167961,
      lng: 17.9986892,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11736",
      lat: 59.3174805,
      lng: 18.0308767,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12655",
      lat: 59.30993299999999,
      lng: 18.000807,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11852",
      lat: 59.315929,
      lng: 18.0625382,
    },
    {
      visitors: "1",
      City: "Bagarmossen",
      zipCode: "12848",
      lat: 59.2780101,
      lng: 18.1465992,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13136",
      lat: 59.308732,
      lng: 18.1347586,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11730",
      lat: 59.3179512,
      lng: 18.0366939,
    },
    {
      visitors: "2",
      City: "Tallinn",
      zipCode: "13424",
      lat: 59.4128496,
      lng: 24.7032706,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11129",
      lat: 59.3244212,
      lng: 18.070782,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11129",
      lat: 59.3244212,
      lng: 18.070782,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11320",
      lat: 59.3394968,
      lng: 18.0382051,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11824",
      lat: 59.31996059999999,
      lng: 18.06296,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11824",
      lat: 59.31996059999999,
      lng: 18.06296,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12636",
      lat: 59.30151129999999,
      lng: 17.9981495,
    },
    {
      visitors: "1",
      City: "Sundbyberg",
      zipCode: "17461",
      lat: 59.3810514,
      lng: 17.9514925,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11421",
      lat: 59.3523354,
      lng: 18.0590139,
    },
    {
      visitors: "1",
      City: "Järna",
      zipCode: "15338",
      lat: 59.11343429999999,
      lng: 17.5786783,
    },
    {
      visitors: "1",
      City: "Danderyd",
      zipCode: "18233",
      lat: 59.4015377,
      lng: 18.032407,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12638",
      lat: 59.2934389,
      lng: 17.9921767,
    },
    {
      visitors: "2",
      City: "Åre",
      zipCode: "83752",
      lat: 63.3990428,
      lng: 13.0815059,
    },
    {
      visitors: "1",
      City: "Södertälje",
      zipCode: "15138",
      lat: 59.16755209999999,
      lng: 17.6583856,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11632",
      lat: 59.314452,
      lng: 18.0936233,
    },
    {
      visitors: "1",
      City: "Sundsvall",
      zipCode: "85741",
      lat: 62.40406900000001,
      lng: 17.261249,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11254",
      lat: 59.3300618,
      lng: 17.9961397,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11730",
      lat: 59.3179512,
      lng: 18.0366939,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11769",
      lat: 59.3149276,
      lng: 17.9875107,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11254",
      lat: 59.3300618,
      lng: 17.9961397,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12638",
      lat: 59.2934389,
      lng: 17.9921767,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11726",
      lat: 59.3169608,
      lng: 18.0488263,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12636",
      lat: 59.30151129999999,
      lng: 17.9981495,
    },
    {
      visitors: "1",
      City: "Solna",
      zipCode: "17159",
      lat: 59.3513667,
      lng: 18.0019466,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12636",
      lat: 59.30151129999999,
      lng: 17.9981495,
    },
    {
      visitors: "2",
      City: "Älvsjö",
      zipCode: "12532",
      lat: 59.28134720000001,
      lng: 17.9971212,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12150",
      lat: 59.2979718,
      lng: 18.1164323,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11851",
      lat: 59.3169244,
      lng: 18.0529817,
    },
    {
      visitors: "1",
      City: "Gustavsberg",
      zipCode: "13434",
      lat: 59.3356305,
      lng: 18.3861127,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11734",
      lat: 59.3163132,
      lng: 18.0364435,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11368",
      lat: 59.3483105,
      lng: 18.0338022,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11741",
      lat: 59.31435019999999,
      lng: 18.0436713,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12957",
      lat: 59.28603409999999,
      lng: 17.9524659,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11264",
      lat: 59.32267,
      lng: 17.9958121,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11530",
      lat: 59.3428362,
      lng: 18.0902826,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12628",
      lat: 59.29790389999999,
      lng: 17.9999777,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11419",
      lat: 59.3598885,
      lng: 18.0530312,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11638",
      lat: 59.31045859999999,
      lng: 18.0890415,
    },
    {
      visitors: "2",
      City: "Sundbyberg",
      zipCode: "17233",
      lat: 59.3660793,
      lng: 17.9639192,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16844",
      lat: 59.3487101,
      lng: 17.8594983,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11231",
      lat: 59.3308822,
      lng: 18.0347074,
    },
    {
      visitors: "2",
      City: "Bagarmossen",
      zipCode: "12845",
      lat: 59.2760739,
      lng: 18.1367893,
    },
    {
      visitors: "4",
      City: "Stockholm",
      zipCode: "11735",
      lat: 59.3167248,
      lng: 18.0309594,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12150",
      lat: 59.2979718,
      lng: 18.1164323,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11336",
      lat: 59.3394061,
      lng: 18.0336313,
    },
    {
      visitors: "2",
      City: "Skarpnäck",
      zipCode: "12832",
      lat: 59.26675640000001,
      lng: 18.1369173,
    },
    {
      visitors: "2",
      City: "Bandhagen",
      zipCode: "12433",
      lat: 59.26968239999999,
      lng: 18.0439577,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11765",
      lat: 59.3119428,
      lng: 18.008444,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12059",
      lat: 59.2983042,
      lng: 18.0388388,
    },
    {
      visitors: "5",
      City: "Stockholm",
      zipCode: "11750",
      lat: 59.3189319,
      lng: 18.0194102,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12629",
      lat: 59.2974064,
      lng: 18.0034648,
    },
    {
      visitors: "1",
      City: "MADRID",
      zipCode: "28028",
      lat: 40.4319062,
      lng: -3.664859,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16736",
      lat: 59.3398526,
      lng: 17.9770145,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11857",
      lat: 59.31030930000001,
      lng: 18.0680142,
    },
    {
      visitors: "1",
      City: "Enskededalen",
      zipCode: "12132",
      lat: 59.288124,
      lng: 18.0891528,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11629",
      lat: 59.31471440000001,
      lng: 18.0833575,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11248",
      lat: 59.338765,
      lng: 18.0263967,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11664",
      lat: 59.3074681,
      lng: 18.0800587,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11247",
      lat: 59.3357892,
      lng: 18.0303035,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11632",
      lat: 59.314452,
      lng: 18.0936233,
    },
    {
      visitors: "1",
      City: "Solna",
      zipCode: "17155",
      lat: 59.35422999999999,
      lng: 17.9831491,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12934",
      lat: 59.30122799999999,
      lng: 17.9818769,
    },
    {
      visitors: "4",
      City: "Hägersten",
      zipCode: "12629",
      lat: 59.2974064,
      lng: 18.0034648,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11351",
      lat: 59.3447329,
      lng: 18.0588375,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11351",
      lat: 59.3447329,
      lng: 18.0588375,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11738",
      lat: 59.31558519999999,
      lng: 18.0272198,
    },
    {
      visitors: "1",
      City: "Umeå",
      zipCode: "90738",
      lat: 63.8070395,
      lng: 20.3018459,
    },
    {
      visitors: "4",
      City: "Stockholm",
      zipCode: "11239",
      lat: 59.3315906,
      lng: 18.0310499,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12154",
      lat: 59.2872186,
      lng: 18.1084212,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11255",
      lat: 59.3304997,
      lng: 18.0061127,
    },
    {
      visitors: "2",
      City: "Göteborg",
      zipCode: "41522",
      lat: 57.7377142,
      lng: 12.0194705,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11266",
      lat: 59.32071959999999,
      lng: 17.9860103,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11357",
      lat: 59.34272139999999,
      lng: 18.062495,
    },
    {
      visitors: "1",
      City: "Enskededalen",
      zipCode: "12133",
      lat: 59.28385359999999,
      lng: 18.1007693,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11223",
      lat: 59.3294253,
      lng: 18.0436019,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12930",
      lat: 59.2931754,
      lng: 17.9862008,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12930",
      lat: 59.2931754,
      lng: 17.9862008,
    },
    {
      visitors: "1",
      City: "Göteborg",
      zipCode: "41758",
      lat: 57.7079705,
      lng: 11.928119,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12146",
      lat: 59.29829720000001,
      lng: 18.1038021,
    },
    {
      visitors: "3",
      City: "Lidingö",
      zipCode: "18144",
      lat: 59.3774415,
      lng: 18.1383511,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11853",
      lat: 59.3151166,
      lng: 18.0513174,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11223",
      lat: 59.3294253,
      lng: 18.0436019,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11761",
      lat: 59.31138329999999,
      lng: 18.0253904,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12931",
      lat: 59.29174210000001,
      lng: 17.9832141,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11560",
      lat: 59.34379910000001,
      lng: 18.1118038,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11739",
      lat: 59.3140818,
      lng: 18.0325367,
    },
    {
      visitors: "6",
      City: "Stockholm",
      zipCode: "11765",
      lat: 59.3119428,
      lng: 18.008444,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12476",
      lat: 59.25863,
      lng: 18.0094438,
    },
    {
      visitors: "2",
      City: "Ljungsbro",
      zipCode: "58574",
      lat: 58.4977499,
      lng: 15.4943216,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11622",
      lat: 59.3183437,
      lng: 18.0807468,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11550",
      lat: 59.3456448,
      lng: 18.0849637,
    },
    {
      visitors: "3",
      City: "Hägersten",
      zipCode: "12947",
      lat: 59.2927139,
      lng: 17.9659512,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11261",
      lat: 59.32516560000001,
      lng: 18.0056989,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11740",
      lat: 59.31734159999999,
      lng: 18.0227335,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11241",
      lat: 59.3311632,
      lng: 18.0262281,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11861",
      lat: 59.3054695,
      lng: 18.0589493,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11846",
      lat: 59.31849860000001,
      lng: 18.0692752,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11529",
      lat: 59.3422116,
      lng: 18.0959392,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12347",
      lat: 59.242296,
      lng: 18.0923523,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16771",
      lat: 59.31900700000001,
      lng: 17.9326937,
    },
    {
      visitors: "1",
      City: "Vällingby",
      zipCode: "16261",
      lat: 59.3614352,
      lng: 17.8720638,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16752",
      lat: 59.3296905,
      lng: 17.9778584,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11735",
      lat: 59.3167248,
      lng: 18.0309594,
    },
    {
      visitors: "2",
      City: "Linköping",
      zipCode: "58752",
      lat: 58.39103600000001,
      lng: 15.6287925,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12432",
      lat: 59.272704,
      lng: 18.0436291,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16766",
      lat: 59.3245933,
      lng: 17.9509504,
    },
    {
      visitors: "1",
      City: "Saltsjö-Boo",
      zipCode: "13237",
      lat: 59.35019320000001,
      lng: 18.2962117,
    },
    {
      visitors: "1",
      City: "Linköping",
      zipCode: "58224",
      lat: 58.4110533,
      lng: 15.6193685,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11739",
      lat: 59.3140818,
      lng: 18.0325367,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75242",
      lat: 59.84190739999999,
      lng: 17.5964867,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12247",
      lat: 59.2727321,
      lng: 18.0642114,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16755",
      lat: 59.328577,
      lng: 17.9673911,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11253",
      lat: 59.3353572,
      lng: 17.9981311,
    },
    {
      visitors: "3",
      City: "Hägersten",
      zipCode: "12637",
      lat: 59.2984889,
      lng: 17.998483,
    },
    {
      visitors: "3",
      City: "Bagarmossen",
      zipCode: "12844",
      lat: 59.27263550000001,
      lng: 18.142589,
    },
    {
      visitors: "1",
      City: "Bagarmossen",
      zipCode: "12844",
      lat: 59.27263550000001,
      lng: 18.142589,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11331",
      lat: 59.3456742,
      lng: 18.0405381,
    },
    {
      visitors: "1",
      City: "Nacka",
      zipCode: "13144",
      lat: 59.31110210000001,
      lng: 18.2112924,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12363",
      lat: 59.24458319999999,
      lng: 18.1152535,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11242",
      lat: 59.3320932,
      lng: 18.0301356,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12363",
      lat: 59.24458319999999,
      lng: 18.1152535,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11164",
      lat: 59.33049159999999,
      lng: 18.0556587,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11853",
      lat: 59.3151166,
      lng: 18.0513174,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11853",
      lat: 59.3151166,
      lng: 18.0513174,
    },
    {
      visitors: "1",
      City: "Osøyro",
      zipCode: "5210",
      lat: 60.18471659999999,
      lng: 5.469273400000001,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12652",
      lat: 59.30729299999999,
      lng: 17.9915032,
    },
    {
      visitors: "1",
      City: "Tübingen",
      zipCode: "72070",
      lat: 48.5226576,
      lng: 9.0047096,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11758",
      lat: 59.3076962,
      lng: 18.0303731,
    },
    {
      visitors: "2",
      City: "Nacka",
      zipCode: "13141",
      lat: 59.3035431,
      lng: 18.1759789,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11853",
      lat: 59.3151166,
      lng: 18.0513174,
    },
    {
      visitors: "1",
      City: "Spånga",
      zipCode: "16351",
      lat: 59.38505989999999,
      lng: 17.8759422,
    },
    {
      visitors: "1",
      City: "Älvsjö",
      zipCode: "12553",
      lat: 59.2722225,
      lng: 17.9636588,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11620",
      lat: 59.3173323,
      lng: 18.0774194,
    },
    {
      visitors: "2",
      City: "Tallinn",
      zipCode: "10145",
      lat: 59.4320048,
      lng: 24.7606811,
    },
    {
      visitors: "2",
      City: "Lidingö",
      zipCode: "18150",
      lat: 59.36383459999999,
      lng: 18.1254991,
    },
    {
      visitors: "1",
      City: "Kalmar",
      zipCode: "39255",
      lat: 56.6617726,
      lng: 16.3355439,
    },
    {
      visitors: "2",
      City: "Englewood",
      zipCode: "07631-2015",
      lat: 40.8928771,
      lng: -73.9726381,
    },
    {
      visitors: "1",
      City: "Täby",
      zipCode: "18731",
      lat: 59.44595140000001,
      lng: 18.0551133,
    },
    {
      visitors: "2",
      City: "Täby",
      zipCode: "18746",
      lat: 59.453974,
      lng: 18.0197297,
    },
    {
      visitors: "1",
      City: "Enskede",
      zipCode: "12246",
      lat: 59.26502409999999,
      lng: 18.0764794,
    },
    {
      visitors: "1",
      City: "Bandhagen",
      zipCode: "12434",
      lat: 59.27002950000001,
      lng: 18.0519243,
    },
    {
      visitors: "2",
      City: "Solna",
      zipCode: "16941",
      lat: 59.36238650000001,
      lng: 17.9969452,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11631",
      lat: 59.3114214,
      lng: 18.0862177,
    },
    {
      visitors: "1",
      City: "Älvsjö",
      zipCode: "12542",
      lat: 59.2777194,
      lng: 18.0293596,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11349",
      lat: 59.3475834,
      lng: 18.0545151,
    },
    {
      visitors: "1",
      City: "Lindau (Bodensee)",
      zipCode: "88131",
      lat: 47.55365430000001,
      lng: 9.693054499999999,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12950",
      lat: 59.2870225,
      lng: 17.9745887,
    },
    {
      visitors: "2",
      City: "Bromma",
      zipCode: "16853",
      lat: 59.352842,
      lng: 17.8933748,
    },
    {
      visitors: "3",
      City: "Malmö",
      zipCode: "21150",
      lat: 55.6006142,
      lng: 13.0104662,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11635",
      lat: 59.3128091,
      lng: 18.0882159,
    },
    {
      visitors: "1",
      City: "Brasília",
      zipCode: "70683190",
      lat: -15.7975154,
      lng: -47.89188739999999,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11227",
      lat: 59.33123699999999,
      lng: 18.0478438,
    },
    {
      visitors: "1",
      City: "London",
      zipCode: "N5 1BH",
      lat: 51.5562701,
      lng: -0.1005768,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11537",
      lat: 59.3473789,
      lng: 18.0975334,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11853",
      lat: 59.3151166,
      lng: 18.0513174,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11264",
      lat: 59.32267,
      lng: 17.9958121,
    },
    {
      visitors: "1",
      City: "Gävle",
      zipCode: "80324",
      lat: 60.67687660000001,
      lng: 17.1183734,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11255",
      lat: 59.3304997,
      lng: 18.0061127,
    },
    {
      visitors: "1",
      City: "Ostermundigen",
      zipCode: "3072",
      lat: 46.9543249,
      lng: 7.4967031,
    },
    {
      visitors: "2",
      City: "Älta",
      zipCode: "13832",
      lat: 59.2598887,
      lng: 18.1691039,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11245",
      lat: 59.3343961,
      lng: 18.0257303,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11336",
      lat: 59.3394061,
      lng: 18.0336313,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11727",
      lat: 59.3188049,
      lng: 18.046335,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12054",
      lat: 59.298572,
      lng: 18.0499684,
    },
    {
      visitors: "2",
      City: "Lindome",
      zipCode: "43741",
      lat: 57.58427,
      lng: 12.0372046,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "12335",
      lat: 59.32932349999999,
      lng: 18.0685808,
    },
    {
      visitors: "1",
      City: "Lund",
      zipCode: "22354",
      lat: 55.696737,
      lng: 13.1920055,
    },
    {
      visitors: "1",
      City: "Dublin",
      zipCode: "D16 C4H3",
      lat: 53.27389239999999,
      lng: -6.313651999999999,
    },
    {
      visitors: "1",
      City: "Wien",
      zipCode: "1220",
      lat: 48.2351828,
      lng: 16.4810643,
    },
    {
      visitors: "2",
      City: "Gävle",
      zipCode: "80262",
      lat: 60.6580878,
      lng: 17.1473019,
    },
    {
      visitors: "1",
      City: "København Ø",
      zipCode: "2100",
      lat: 55.7096303,
      lng: 12.5768655,
    },
    {
      visitors: "1",
      City: "Malmö",
      zipCode: "21211",
      lat: 55.6072255,
      lng: 13.0211419,
    },
    {
      visitors: "1",
      City: "Vällingby",
      zipCode: "16261",
      lat: 59.3614352,
      lng: 17.8720638,
    },
    {
      visitors: "3",
      City: "Sollentuna",
      zipCode: "19146",
      lat: 59.4302185,
      lng: 17.9143775,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11360",
      lat: 59.3393085,
      lng: 18.05384,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11253",
      lat: 59.3353572,
      lng: 17.9981311,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12632",
      lat: 59.30246469999999,
      lng: 18.0149229,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11730",
      lat: 59.3179512,
      lng: 18.0366939,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11823",
      lat: 59.31906499999999,
      lng: 18.0523194,
    },
    {
      visitors: "1",
      City: "Guadalajara",
      zipCode: "44600",
      lat: 20.6769383,
      lng: -103.3717253,
    },
    {
      visitors: "1",
      City: "Kalmar",
      zipCode: "39247",
      lat: 56.65394089999999,
      lng: 16.3455101,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11852",
      lat: 59.315929,
      lng: 18.0625382,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11123",
      lat: 59.33510630000001,
      lng: 18.0520055,
    },
    {
      visitors: "2",
      City: "Linköping",
      zipCode: "58434",
      lat: 58.41229919999999,
      lng: 15.5608754,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12145",
      lat: 59.29921249999999,
      lng: 18.0948304,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11857",
      lat: 59.31030930000001,
      lng: 18.0680142,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11245",
      lat: 59.3343961,
      lng: 18.0257303,
    },
    {
      visitors: "2",
      City: "Uppsala",
      zipCode: "75229",
      lat: 59.8636601,
      lng: 17.6091257,
    },
    {
      visitors: "1",
      City: "Oslo",
      zipCode: "0267",
      lat: 59.9205714,
      lng: 10.7033458,
    },
    {
      visitors: "1",
      City: "GIF-SUR-YVETTE",
      zipCode: "91190",
      lat: 48.714637,
      lng: 2.1263358,
    },
    {
      visitors: "2",
      City: "Ljusne",
      zipCode: "82675",
      lat: 61.2114046,
      lng: 17.1266474,
    },
    {
      visitors: "2",
      City: "Täby",
      zipCode: "18374",
      lat: 59.4448173,
      lng: 18.0891596,
    },
    {
      visitors: "1",
      City: "Johanneshov",
      zipCode: "12136",
      lat: 59.292824,
      lng: 18.0875031,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "116 20",
      lat: 59.3173323,
      lng: 18.0774194,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12651",
      lat: 59.3092333,
      lng: 17.9961525,
    },
    {
      visitors: "1",
      City: "Rathgar",
      zipCode: "D06 WF51",
      lat: 53.3164662,
      lng: -6.2744794,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16831",
      lat: 59.33626659999999,
      lng: 17.9499288,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11734",
      lat: 59.3163132,
      lng: 18.0364435,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11631",
      lat: 59.3114214,
      lng: 18.0862177,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11730",
      lat: 59.3179512,
      lng: 18.0366939,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11762",
      lat: 59.30390569999999,
      lng: 18.023062,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11649",
      lat: 59.3116502,
      lng: 18.0976444,
    },
    {
      visitors: "1",
      City: "Kista",
      zipCode: "16438",
      lat: 59.4131842,
      lng: 17.9269306,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16743",
      lat: 59.33600080000001,
      lng: 17.9831696,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12950",
      lat: 59.2870225,
      lng: 17.9745887,
    },
    {
      visitors: "1",
      City: "Umeå",
      zipCode: "90331",
      lat: 63.8243163,
      lng: 20.2814656,
    },
    {
      visitors: "1",
      City: "Ichikawa",
      zipCode: "2720123",
      lat: 35.6796936,
      lng: 139.927079,
    },
    {
      visitors: "1",
      City: "Donegal",
      zipCode: "F94 YY76",
      lat: 54.6560764,
      lng: -8.1171246,
    },
    {
      visitors: "2",
      City: "WYNNUM",
      zipCode: "4178",
      lat: -27.443694,
      lng: 153.173618,
    },
    {
      visitors: "1",
      City: "Älvsjö",
      zipCode: "12530",
      lat: 59.2744881,
      lng: 18.0051298,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11739",
      lat: 59.3140818,
      lng: 18.0325367,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12628",
      lat: 59.29790389999999,
      lng: 17.9999777,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11642",
      lat: 59.3092808,
      lng: 18.0868777,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11240",
      lat: 59.33166970000001,
      lng: 18.0278909,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11127",
      lat: 59.322194,
      lng: 18.0694479,
    },
    {
      visitors: "1",
      City: "Huddinge",
      zipCode: "14152",
      lat: 59.22214109999999,
      lng: 17.9395548,
    },
    {
      visitors: "2",
      City: "Bromma",
      zipCode: "16743",
      lat: 59.33600080000001,
      lng: 17.9831696,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11737",
      lat: 59.31781279999999,
      lng: 18.0285502,
    },
    {
      visitors: "3",
      City: "Luleå",
      zipCode: "97245",
      lat: 65.5804792,
      lng: 22.177818,
    },
    {
      visitors: "1",
      City: "Uppsala",
      zipCode: "75645",
      lat: 59.8314112,
      lng: 17.6239295,
    },
    {
      visitors: "1",
      City: "Solna",
      zipCode: "17171",
      lat: 59.35031799999999,
      lng: 18.0027788,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12053",
      lat: 59.300929,
      lng: 18.0542907,
    },
    {
      visitors: "1",
      City: "Sundsvall",
      zipCode: "85733",
      lat: 62.4126218,
      lng: 17.2535858,
    },
    {
      visitors: "1",
      City: "Älvsjö",
      zipCode: "12555",
      lat: 59.2776043,
      lng: 17.9676321,
    },
    {
      visitors: "1",
      City: "Skarpnäck",
      zipCode: "12838",
      lat: 59.27395809999999,
      lng: 18.1126974,
    },
    {
      visitors: "1",
      City: "Mölnbo",
      zipCode: "153 60",
      lat: 59.04784,
      lng: 17.42373,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11726",
      lat: 59.3169608,
      lng: 18.0488263,
    },
    {
      visitors: "1",
      City: "Sundbyberg",
      zipCode: "17236",
      lat: 59.3659855,
      lng: 17.9593446,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12372",
      lat: 59.2348445,
      lng: 18.1105751,
    },
    {
      visitors: "2",
      City: "Bagarmossen",
      zipCode: "12844",
      lat: 59.27263550000001,
      lng: 18.142589,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11732",
      lat: 59.3198462,
      lng: 18.040352,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11347",
      lat: 59.3536674,
      lng: 18.0496961,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11347",
      lat: 59.3536674,
      lng: 18.0496961,
    },
    {
      visitors: "1",
      City: "Bromma",
      zipCode: "16875",
      lat: 59.3435472,
      lng: 17.9379458,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11531",
      lat: 59.34258080000001,
      lng: 18.0868707,
    },
    {
      visitors: "1",
      City: "London",
      zipCode: "SE16 4UT",
      lat: 51.4996435,
      lng: -0.06867039999999999,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11241",
      lat: 59.3311632,
      lng: 18.0262281,
    },
    {
      visitors: "2",
      City: "Brighton",
      zipCode: "BN2 9UH",
      lat: 50.8296821,
      lng: -0.1284165,
    },
    {
      visitors: "1",
      City: "Vällingby",
      zipCode: "16253",
      lat: 59.360387,
      lng: 17.8873601,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11730",
      lat: 59.3179512,
      lng: 18.0366939,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11852",
      lat: 59.315929,
      lng: 18.0625382,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11620",
      lat: 59.3173323,
      lng: 18.0774194,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "12032",
      lat: 59.30222449999999,
      lng: 18.084202,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11730",
      lat: 59.3179512,
      lng: 18.0366939,
    },
    {
      visitors: "1",
      City: "Saltsjöbaden",
      zipCode: "13342",
      lat: 59.29530279999999,
      lng: 18.2587708,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11228",
      lat: 59.33442669999999,
      lng: 18.0463503,
    },
    {
      visitors: "3",
      City: "Skellefteå",
      zipCode: "93236",
      lat: 64.71249130000001,
      lng: 21.1847493,
    },
    {
      visitors: "3",
      City: "Solna",
      zipCode: "16940",
      lat: 59.36741839999999,
      lng: 17.9819688,
    },
    {
      visitors: "1",
      City: "Karlsborg",
      zipCode: "54631",
      lat: 58.5179334,
      lng: 14.4919284,
    },
    {
      visitors: "20",
      City: "",
      zipCode: "",
    },
    {
      visitors: "3",
      City: "Ekolsund",
      zipCode: "74950",
      lat: 59.61970050000001,
      lng: 17.3766542,
    },
    {
      visitors: "4",
      City: "Bromma",
      zipCode: "16841",
      lat: 59.33068939999999,
      lng: 17.9027637,
    },
    {
      visitors: "4",
      City: "Hägersten",
      zipCode: "12632",
      lat: 59.30246469999999,
      lng: 18.0149229,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11863",
      lat: 59.313106,
      lng: 18.0549714,
    },
    {
      visitors: "1",
      City: "Årsta",
      zipCode: "12039",
      lat: 59.2999432,
      lng: 18.0715683,
    },
    {
      visitors: "1",
      City: "Bagarmossen",
      zipCode: "12847",
      lat: 59.2827044,
      lng: 18.1346562,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11733",
      lat: 59.32239069999999,
      lng: 18.0290508,
    },
    {
      visitors: "3",
      City: "Hägersten",
      zipCode: "12957",
      lat: 59.28603409999999,
      lng: 17.9524659,
    },
    {
      visitors: "4",
      City: "Stockholm",
      zipCode: "11356",
      lat: 59.3475067,
      lng: 18.0602555,
    },
    {
      visitors: "4",
      City: "Bagarmossen",
      zipCode: "12845",
      lat: 59.2760739,
      lng: 18.1367893,
    },
    {
      visitors: "1",
      City: "Göteborg",
      zipCode: "41308",
      lat: 57.69322260000001,
      lng: 11.9526278,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11732",
      lat: 59.3198462,
      lng: 18.040352,
    },
    {
      visitors: "4",
      City: "Stockholm",
      zipCode: "11856",
      lat: 59.31006250000001,
      lng: 18.0723352,
    },
    {
      visitors: "2",
      City: "Hägersten",
      zipCode: "12933",
      lat: 59.3001462,
      lng: 17.986859,
    },
    {
      visitors: "4",
      City: "Johanneshov",
      zipCode: "12138",
      lat: 59.29619499999999,
      lng: 18.1003061,
    },
    {
      visitors: "2",
      City: "Vallentuna",
      zipCode: "18642",
      lat: 59.5186485,
      lng: 18.0886304,
    },
    {
      visitors: "3",
      City: "Enskede",
      zipCode: "12248",
      lat: 59.2739164,
      lng: 18.0715179,
    },
    {
      visitors: "3",
      City: "Stockholm",
      zipCode: "11343",
      lat: 59.3478588,
      lng: 18.0408724,
    },
    {
      visitors: "4",
      City: "Stockholm",
      zipCode: "11760",
      lat: 59.3085567,
      lng: 18.0290851,
    },
    {
      visitors: "1",
      City: "Stockholm",
      zipCode: "11828",
      lat: 59.3142975,
      lng: 18.0674393,
    },
    {
      visitors: "1",
      City: "Hägersten",
      zipCode: "12647",
      lat: 59.30310060000001,
      lng: 17.9948274,
    },
    {
      visitors: "4",
      City: "Solna",
      zipCode: "17066",
      lat: 59.3771328,
      lng: 18.0104218,
    },
    {
      visitors: "1",
      City: "Farsta",
      zipCode: "12359",
      lat: 59.2556382,
      lng: 18.0900656,
    },
    {
      visitors: "2",
      City: "Stockholm",
      zipCode: "11644",
      lat: 59.31248979999999,
      lng: 18.1060048,
    },
  ];

  const basePoint = { lat: 59.292443, lng: 18.078725 };
  // Haversine formula to calculate distance between two points on Earth
  function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  return cities.map((city) => {
    const distance = getDistance(basePoint.lat, basePoint.lng, city.lat, city.lng);
    const visitors = parseInt(city.visitors, 10);
    return {
      City: city.City,
      distance: distance,
      visitors: visitors,
    };
  });
}

// Your array of cities
