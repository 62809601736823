/** @format */

import React, { useEffect, useState } from "react";
import { DataGrid, GridRowModes, useGridApiContext } from "@mui/x-data-grid";
import { Autocomplete, Button, Checkbox, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import WhatDayIsIt from "../MiscUIComponents/WhatDayIsIt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import { addDoc, collection, deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { createId, db } from "../../firebase";
import { CollectionsOutlined, DoNotTouchOutlined } from "@mui/icons-material";
import shortText from "../../helpers/shortText";
import ChooseBetween from "./ChooseBetween";
import DisplayObject from "../../helpers/displayObject";
import { reloadCalendarBookings } from "../../helpers/Listeners";
// import { GridApi, GridApiContext, useGridApiContext } from "@mui/x-data-grid-pro";
import { productColors } from "./productColors";
import { makeNumber, numberFormatted } from "../../helpers/Numbers";
import BatchAgreementModal from "../Documents/BatchAgreementModal";
import { useDispatch } from "react-redux";
import { storeCurrentBookings, storeTimelineIsOpen } from "../../features/venueFilter/venueFilterSlice";
import ConnectAgreement from "../../helpers/ConnectAgreement";

const BatchEditBookings = ({ bookings }) => {
  const [numberOfBookings, setNumberOfBookings] = useState(0);
  const [rowModesModel, setRowModesModel] = useState({});
  const timeColor = "#00ffd499";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = React.useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showAgreement, setShowAgreement] = useState(false);
  const agreementId = bookings.length > 0 && bookings[0].agreementId;
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);
  const currentAgreement = currentAgreements.filter((agreement) => agreement.agreementId === agreementId)[0];

  const rowsInitialized = bookings.map((booking, index) => ({
    // id: index + 1, // Unique ID for each row (you can customize this)
    id: booking.bookingid, // Unique ID for each row (you can customize this)
    name: shortText(booking.artist.artist, 20),
    day: booking.date,
    date: booking.date,
    product: booking.product ? booking.product : "",
    price: booking.price ? booking.price : "",
    capacity: booking.capacity ? booking.capacity : "",
    getIn: booking.getIn ? booking.getIn : "",
    loadIn: booking.loadIn ? booking.loadIn : "",
    doors: booking.doors ? booking.doors : "",
    show: booking.show ? booking.show : "",
    loadOut: booking.loadOut ? booking.loadOut : "",
    soundcheck: booking.soundcheck ? booking.soundcheck : "",
    curfew: booking.curfew ? booking.curfew : "",
  }));
  //   const [rows,setRows]=useState
  const [rows, setRows] = useState([]);
  const [sumPrice, setSumPrice] = useState();
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  // let products = [];
  // if (currentVenue.products !== undefined) products = currentVenue.products.map((item) => item.product);
  // console.log("bookings for agreementIds", bookings);
  const [products, setProducts] = useState(currentVenue.products && currentVenue.products.map((item) => item.product));
  // console.log("products", products);
  // Set selected rows when selected
  function handleSelectedRow(newSelection) {
    setSelectedRows(newSelection);
  }

  // console.log(GridRowModes);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await Promise.all(
        selectedRows.map(async (item) => {
          const bookingIds = currentAgreement.bookingids.filter((id) => id !== item);
          await (bookingIds.length
            ? updateDoc(doc(db, "Agreements", agreementId), { bookingids: bookingIds })
            : deleteDoc(doc(db, "Agreements", agreementId)));
          await deleteDoc(doc(db, "Bookings", item));
        })
      );
    } catch (error) {
      console.error("Error deleting documents:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDuplication = () => {
    selectedRows.map(async (item) => {
      const newId = createId();
      //get booking with selected bookingid
      const foundBooking = bookings.filter((booking) => booking.bookingid === item)[0];
      // copy foundBooking and add new bookingid and documentid
      const newCopy = foundBooking ? { ...foundBooking, documentid: newId, bookingid: newId } : null;
      //...then add to "Bookings"
      setLoading(true);
      await setDoc(doc(db, "Bookings", newId), {
        ...newCopy,
      });
    });
  };

  useEffect(() => {
    setNumberOfBookings(bookings.length);
  }, [bookings]);

  // Gathering data from updated row for handleCustomEdit
  const [currentRow, setCurrentRow] = useState();
  const [currentField, setCurrentField] = useState();
  const [newRowData, setNewRowData] = useState();
  const wichFieldWasEdited = async (params) => {
    setCurrentField(params.field);
    setCurrentRow(params.id);
  };
  const andWichValue = (newRow) => {
    setNewRowData(newRow);
  };
  useEffect(() => {
    if (currentRow && currentField && newRowData) {
      const value = newRowData[currentField];
      if (currentField === "product") {
        changeProductForBooking(currentRow, currentField, value);
      } else {
        handleCustomEdit(currentRow, currentField, value);
      }
    }
  }, [newRowData]);

  const handleEdit = (params, event) => {
    setTimeout(() => {
      // console.log("THIZ!!", "1", params, "2", event);
    }, 2000);

    //reshape 'params' and 'event' into...
    const bookingid = params.row.id;
    const key = params.field;
    const value = event.target.value;

    //...in order to store edits with...
    handleCustomEdit(bookingid, key, value);
  };

  // Change the value on all selected rows
  const handleCustomEdit = (bookingid, key, value) => {
    setLoading(true);
    let rowsToUpdate = selectedRows;

    // In case the row updated is not selected make changes to that row anyway...
    !selectedRows.includes(bookingid) && rowsToUpdate.push(bookingid);
    setSelectedRows(rowsToUpdate); // ...and select it

    //map through all rowsToUpdate
    rowsToUpdate.map(async (id) => {
      const foundBooking = bookings.filter((booking) => booking.bookingid === id)[0];
      const updatedBooking = foundBooking ? { ...foundBooking, [key]: value } : null;

      // store to db
      await updateDoc(doc(db, "Bookings", id), {
        [key]: value,
      });

      setLoading(false);
    });
  };

  const justUpdate = () => {
    // console.log(rows);

    setLoading(true);
    //map through all rows
    rows.map(async (id) => {
      // store to db
      // await updateDoc(doc(db, "Bookings", id), {
      //   ...id,
      // });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(false);
    const newlyUpdated = bookings.map((booking, index) => ({
      id: booking.bookingid, // Unique ID for each row (you can customize this)
      name: shortText(booking.artist.artist, 20),
      day: booking.date,
      date: booking.date,
      product: booking.product ? booking.product : "",
      price: booking.price ? booking.price : "",
      capacity: booking.capacity ? booking.capacity : "",
      getIn: booking.getIn ? booking.getIn : "",
      loadIn: booking.loadIn ? booking.loadIn : "",
      doors: booking.doors ? booking.doors : "",
      show: booking.show ? booking.show : "",
      loadOut: booking.loadOut ? booking.loadOut : "",
      soundcheck: booking.soundcheck ? booking.soundcheck : "",
      curfew: booking.curfew ? booking.curfew : "",
      status: booking.status ? booking.status : "",
      bookingFeatures: booking.venue.features ? booking.venue.features : "",
    }));
    setRows(newlyUpdated);
    const priceSumUp = bookings.reduce((total, booking) => total + (makeNumber(booking.price) || 0), 0);
    setSumPrice(numberFormatted(priceSumUp));
  }, [bookings, currentVenue]);

  useEffect(() => {
    // console.log(rows);
    // console.log("allbookings", bookings);
    justUpdate();
  }, [rows]);

  const productSelector = (id, currentProduct) => {
    return (
      <FormControl fullWidth>
        <Select
          defaultValue={currentProduct}
          sx={{ width: "130px", color: "white" }}
          size="small"
          onChange={(e) => changeProductForBooking(id, "product", e.target.value)}
          // onChange={(e) => discountedPrice(e.target.value)}
        >
          {currentVenue.products.map((product, index) => (
            <MenuItem value={product.product}>{product.product}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const setBookingFeatures = () => {};

  async function changeProductForBooking(id, key, value) {
    const foundProduct = await currentVenue.products.filter((product) => product.product === value)[0]; //THIS
    // console.log("foundProduct", foundProduct);
    // Now store all times accordingly for selected rows...
    selectedRows.map(async (item) => {
      setLoading(true);
      await setDoc(
        doc(db, "Bookings", item),
        {
          getIn: foundProduct.getIn ? foundProduct.getIn : "-",
          loadIn: foundProduct.loadIn ? foundProduct.loadIn : "-",
          soundcheck: foundProduct.loadIn ? foundProduct.loadIn : "-",
          doors: foundProduct.doors ? foundProduct.doors : "-",
          show: foundProduct.show ? foundProduct.show : "-",
          loadOut: foundProduct.loadOut ? foundProduct.loadOut : "-",
          curfew: foundProduct.curfew ? foundProduct.curfew : "-",
          price: foundProduct.price ? foundProduct.price : "-",
          priceIncVat: foundProduct.priceIncVat ? foundProduct.priceIncVat : "-",
          capacity: foundProduct.capacity ? foundProduct.capacity : "-",
          bookingFeatures: foundProduct.features ? foundProduct.features : "-",
          productid: foundProduct.productid ? foundProduct.productid : "-",
          // product: foundProduct.product,
        },
        { merge: true }
      );
      await updateDoc(doc(db, "Bookings", item), {
        bookingFeatures: foundProduct.features ? foundProduct.features : "-",
        // product: foundProduct.product,
      });
      // console.log("New product set. Check if bookingFeatures are present in booking..!", item);
    });
    setLoading(false);
    // ...and finally change the product
    handleCustomEdit(id, key, value);
  }

  const getColorForProduct = (product) => {
    const productIndex = products && products.findIndex((item) => item === product);
    return productColors[productIndex];
  };

  const timeSelector = (id, key, currentTime) => {
    return (
      <FormControl fullWidth>
        <Select
          className="timeSelector"
          defaultValue={currentTime}
          sx={{ width: "90px" }}
          size="small"
          onChange={(e) => handleCustomEdit(id, key, e.target.value)}
          renderValue={(selectedValue) => <p>{selectedValue}</p>}
          //   style={{ color: "transparent" }}
        >
          {times.map((time, index) => (
            <MenuItem value={time}>{time}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleSendOffer = () => {
    setShowAgreement(true);
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 250 },
    { field: "name", headerName: "Name", width: 120 },
    {
      field: "day",
      headerName: "Day",
      width: 100,
      renderCell: (params) => (
        <span className="dataGridTime">
          <WhatDayIsIt date={params.row.day} numberOfLetters="9" />
        </span>
      ),
    },
    { field: "date", headerName: "Date", width: 120, renderCell: (params) => <span className="dataGridTime">{params.value}</span> },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      editable: false,
    },

    {
      field: "capacity",
      headerName: "Capacity",
      width: 80,
      editable: true,
    },
    // {
    //   field: "price",
    //   headerName: "Price",
    //   width: 80,
    //   editable: true,
    // },
    {
      field: "product",
      headerName: "Product",
      width: 150,
      type: "singleSelect",
      valueOptions: products && [...products],
      editable: true,
      renderCell: (params) => (
        <span
          style={{
            color: getColorForProduct(params.product),
          }}
        >
          {params.value}
        </span>
      ),
      //   renderEditCell: (params) => <>{productSelector(params.row.id, params.row.product)}</>,
    },
    {
      field: "getIn",
      headerName: "Get In",
      width: 100,
      type: "singleSelect",
      valueOptions: [...times],
      editable: true,
      renderCell: (params) => <span className="dataGridTime">{params.value}</span>,

      //   renderCell: (params) => <>{timeSelector(params.row.id, params.field, params.row.getIn)}</>,
    },
    {
      field: "loadIn",
      headerName: "Load In",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [...times],
      editable: true,
      renderCell: (params) => <span className="dataGridTime">{params.value}</span>,
    },
    {
      field: "soundcheck",
      headerName: "Soundcheck",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [...times],
      editable: true,
      renderCell: (params) => <span className="dataGridTime">{params.value}</span>,
    },
    {
      field: "doors",
      headerName: "Doors",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [...times],
      editable: true,
      renderCell: (params) => <span className="dataGridTime">{params.value}</span>,
    },
    {
      field: "show",
      headerName: "Show",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [...times],
      editable: true,
      renderCell: (params) => <span className="dataGridTime">{params.value}</span>,
    },
    {
      field: "loadOut",
      headerName: "Load Out",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [...times],
      editable: true,
      renderCell: (params) => <span className="dataGridTime">{params.value}</span>,
    },
    {
      field: "curfew",
      headerName: "Curfew",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [...times],
      editable: true,
      renderCell: (params) => <span className="dataGridTime">{params.value}</span>,
    },
  ];

  return (
    <>
      {/* <DisplayObject data={rows} /> */}
      {/* <h1>{selectedRows.length}</h1> */}
      <Grid container xs={12}>
        {/* <DisplayObject data={rows} /> */}
        <Grid xs={12} style={{ height: "45vh", width: "80vw" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            selectionModel={selectedRows}
            onSelectionModelChange={(newSelection) => handleSelectedRow(newSelection)}
            experimentalFeatures={{ newEditingApi: true }}
            pageSize={50} // Adjust the number of rows per page as needed
            checkboxSelection={true} // Set to true if you want checkboxes
            onCellEditStop={wichFieldWasEdited}
            processRowUpdate={andWichValue}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="BatchEditTools">
            <div className="BatchSummary">{sumPrice}</div>
            <Tooltip title="Duplicate selected">
              <ConfirmDialog
                onConfirm={() => handleDuplication()}
                header="Duplicate selected"
                message="Are you sure you want to duplicate selected?"
                noButton={true}
              >
                <ContentCopyIcon />
              </ConfirmDialog>
            </Tooltip>{" "}
            <Tooltip title="Delete selected">
              <ConfirmDialog
                onConfirm={() => handleDelete()}
                header="Delete selected"
                message="Permanently remove selected?"
                noButton={true}
              >
                {loading ? <CircularProgress style={{ zoom: "0.4" }} /> : <DeleteIcon />}
              </ConfirmDialog>
            </Tooltip>
            {numberOfBookings > 0 && (
              // <ConfirmDialog
              //   onConfirm={() => handleSendOffer()}
              //   header={`Confirm preliminary booking${numberOfBookings > 1 ? "s" : ""}`}
              //   message="Want to send offer?"
              //   variant="outlined"
              //   color="warning"
              // >
              <>
                <Button onClick={() => handleSendOffer()} variant="outlined">
                  Show {numberOfBookings} Offered Booking{numberOfBookings > 1 ? "s" : ""}
                </Button>
              </>
              // </ConfirmDialog>
            )}
            {/* <ConnectAgreement /> */}
          </div>
        </Grid>
        {showAgreement && (
          <div>
            <BatchAgreementModal
              agreementId={agreementId}
              // bookingids={rows.map((item) => item.id)} // (used to be bookingid)
              sign="venueSignature"
              open={true}
              setShowAgreement={setShowAgreement}
              climateCompensation={false}
              // bookingFeatures={bookingFeatures}
              // relevantBookings={relevantBookings}
            />
          </div>
        )}
      </Grid>
      {/* {console.log("BOOKINGS", bookings)} */}
      {/* {console.log(selectedRows.length)} */}
      {/* {console.log("ROWS", rows)} */}
    </>
  );
};

export default BatchEditBookings;

const times = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
