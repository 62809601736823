/** @format */

import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import DisplayObject from "../../helpers/displayObject";
import { numberFormatted } from "../../helpers/Numbers";
import LoadingDots from "../UI helpers/LoadingDots";
import PieChartCenteredLabel from "../UI helpers/PieChartCenteredLabel";

const VisualDataSummary = ({ formData }) => {
  console.log("formData", formData);

  const [CO2eSum, setCO2eSum] = useState(0);

  const wasteSumCO2e =
    formData.recycledMetalCO2e +
    formData.mixedHouseholdCO2e +
    formData.landfilledWasteCO2e +
    formData.recycledPlasticCO2e +
    formData.recycledPaperCO2e +
    formData.recycledElectricCO2e;

  console.log(
    "test",
    formData.electricityUseCO2e,
    "2",
    formData.heatingQuantityCO2e,
    "3",
    formData.waterUseCO2e,
    "4",
    wasteSumCO2e,
    "5",
    formData.refrigerantQuantityCO2e
  );

  // const CO2eSum =
  //   formData.electricityUseCO2e +
  //   formData.heatingQuantityCO2e +
  //   formData.waterUseCO2e +
  //   wasteSumCO2e +
  //   formData.refrigerantQuantityCO2e +
  //   55 +
  //   340;

  useEffect(() => {
    setCO2eSum(
      Number(formData.electricityUseCO2e) +
        // Number(formData.heatingQuantityCO2e) +
        Number(formData.waterUseCO2e) +
        Number(wasteSumCO2e) +
        Number(formData.refrigerantQuantityCO2e) +
        55 +
        340
    );
  }, [formData, wasteSumCO2e]);

  const electricityFormatted = (
    <table>
      <thead>
        <th>Used</th>
        <th>Type</th>
        <th>On Site Produced</th>
      </thead>
      <td>{formData.electricityUse ? <span>{numberFormatted(formData.electricityUse)} kWh</span> : "-"}</td>
      <td>{formData.electricitySource && <span>{formData.electricitySource}</span>}</td>
      <td>{formData.onSiteProduced && <span>{numberFormatted(formData.onSiteProduced)} kWh</span>}</td>
    </table>
  );
  const heatingFormatted = (
    <table>
      <thead>
        <th>Type</th>
      </thead>
      <td>
        {formData.heatingUsed ? (
          <span>
            {formData.heatingUsed} {numberFormatted(formData.heatingQuantity)} {formData.heatingUnit}
          </span>
        ) : (
          "-"
        )}
      </td>
    </table>
  );
  const refrigerantsFormatted = (
    <table>
      <thead>
        <th>Used</th>
      </thead>
      <td>
        {formData.refrigerantType ? (
          <span>
            {formData.refrigerantType}: {numberFormatted(formData.refrigerantQuantity)} kg
          </span>
        ) : (
          "-"
        )}
      </td>
    </table>
  );
  const waterFormatted = (
    <table>
      <thead>
        <th>Used</th>
      </thead>
      <td>{formData.waterUse ? <span>{numberFormatted(formData.waterUse)} litres</span> : "-"}</td>
    </table>
  );
  const wasteFormatted = (
    <table>
      <thead>
        <th>Metal</th>
        <th>Plastic</th>
        <th>Paper</th>
        <th>Electronic</th>
        <th>Household</th>
        <th>Landfilled</th>
      </thead>
      <td>{formData.recycledMetal ? <span>{numberFormatted(formData.recycledMetal)} kg</span> : "-"}</td>
      <td>{formData.recycledPlastic ? <span>{numberFormatted(formData.recycledPlastic)} kg</span> : "-"}</td>
      <td>{formData.recycledPaper ? <span>{numberFormatted(formData.recycledPaper)} kg</span> : "-"}</td>
      <td>{formData.recycledElectric ? <span>{numberFormatted(formData.recycledElectric)} kg</span> : "-"}</td>
      <td>{formData.mixedHousehold ? <span>{numberFormatted(formData.mixedHousehold)} kg</span> : "-"}</td>
      <td>{formData.landfilledWaste ? <span>{numberFormatted(formData.landfilledWaste)} kg</span> : "-"}</td>
    </table>
  );

  return (
    <div>
      <h7>Summary report</h7>
      <div>
        {console.log("CO2eSum", CO2eSum)}
        <PieChartCenteredLabel
          width={"300px"}
          height={"300px"}
          data={[
            { value: formData.electricityUseCO2e ? formData.electricityUseCO2e : "0", label: "Electricity", color: "#00ffd499" },
            { value: formData.heatingQuantityCO2e, label: "Heating", color: "#00ffd433" },
            { value: formData.waterUseCO2e, label: "Water", color: "#ffa50044" },
            { value: wasteSumCO2e, label: "Waste", color: "#ffa50099" },
            { value: formData.refrigerantQuantityCO2e, label: "Refrigerant", color: "#00ffd4" },
            { value: 55, label: "Transport", color: "#ffa500" },
            { value: 340, label: "Travel", color: "#ffa522" },
          ]}
          label={CO2eSum ? `${numberFormatted(Math.floor(CO2eSum))} kg` : "Emissions"}
          // label={CO2eSummary ? `${numberFormatted(Math.floor(CO2eSummary))} kg` : "Emissions"}
        />
      </div>
      <table className="sustainabilityReport">
        {/* <thead>
          <th>Electricity</th>
          <th>Heating</th>
          <th>Refrigeration</th>
          <th>Water</th>
          <th>Waste</th>
          <th></th>
          <th></th>
        </thead> */}
        <td>Electricity</td>
        <td>{electricityFormatted}</td>
        <tr>
          <td>Heating</td>
          <td>{heatingFormatted}</td>
        </tr>
        <tr>
          <td>Refrigerants</td>
          <td>{refrigerantsFormatted}</td>
        </tr>
        <tr>
          <td>Water</td>
          <td>{waterFormatted}</td>
        </tr>
        <tr>
          <td>Waste</td>
          <td>{wasteFormatted}</td>
        </tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </table>
      <div style={{ fontSize: "12px", opacity: "0.4", marginTop: "5px" }}>
        ** Data exclusively sourced from the Greenhouse Gas Protocol (GHG Protocol) and the UK Department for Business, Energy & Industrial
        Strategy (BEIS).
      </div>
    </div>
  );
};

export default VisualDataSummary;
