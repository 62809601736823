/** @format */

export const countries = [
  {
    country: "Austria",
    currency: "EUR",
    vatOptions: [
      { vatRate: "20%", info: "Standard Rate" },
      { vatRate: "10%", info: "Reduced Rate (some foodstuffs, certain pharmaceutical products, domestic passenger transport)" },
      { vatRate: "13%", info: "Special Rate (certain agricultural supplies)" },
    ],
  },
  {
    country: "Belgium",
    currency: "EUR",
    vatOptions: [
      { vatRate: "21%", info: "Standard Rate" },
      { vatRate: "12%", info: "Reduced Rate (restaurants and certain building works)" },
      {
        vatRate: "6%",
        info: "Super-Reduced Rate (some foodstuffs, certain pharmaceutical products, books, newspapers, hotel accommodations)",
      },
    ],
  },

  {
    country: "Bulgaria",
    currency: "BGN",
    vatOptions: [
      { vatRate: "20%", info: "Standard Rate" },
      { vatRate: "9%", info: "Reduced Rate (hotel services)" },
    ],
  },
  {
    country: "Croatia",
    currency: "HRK",
    vatOptions: [
      { vatRate: "25%", info: "Standard Rate" },
      { vatRate: "13%", info: "Reduced Rate (certain goods and services)" },
      { vatRate: "5%", info: "Super-Reduced Rate (newspapers, bread, etc.)" },
    ],
  },

  {
    country: "Italy",
    currency: "EUR",
    vatOptions: [
      { vatRate: "22%", info: "Standard Rate" },
      { vatRate: "10%", info: "Reduced Rate (foodstuffs, pharmaceuticals, passenger transport)" },
      { vatRate: "5%", info: "Super-Reduced Rate (certain social services)" },
      { vatRate: "4%", info: "Particularly Reduced Rate (certain foodstuffs, books)" },
    ],
  },
  {
    country: "Netherlands",
    currency: "EUR",
    vatOptions: [
      { vatRate: "21%", info: "Standard Rate" },
      {
        vatRate: "9%",
        info: "Reduced Rate (foodstuffs, books, pharmaceutical products, water supplies, and certain services like hairdressing)",
      },
    ],
  },
  {
    country: "Luxembourg",
    currency: "EUR",
    vatOptions: [
      { vatRate: "17%", info: "Standard Rate" },
      { vatRate: "14%", info: "Intermediate Rate (wine, advertising material)" },
      { vatRate: "8%", info: "Reduced Rate (certain types of publications, admission to cultural events)" },
      { vatRate: "3%", info: "Super-Reduced Rate (foodstuffs, children's clothing, pharmaceutical products, books, newspapers)" },
    ],
  },
  {
    country: "Denmark",
    currency: "DKK",
    vatOptions: [
      { vatRate: "25%", info: "Standard Rate" },
      // Denmark generally has a flat VAT rate with few exceptions
    ],
  },
  {
    country: "Finland",
    currency: "EUR",
    vatOptions: [
      { vatRate: "24%", info: "Standard Rate" },
      { vatRate: "14%", info: "Reduced Rate (foodstuffs, restaurant and catering services)" },
      { vatRate: "10%", info: "Super-Reduced Rate (medicines, books, passenger transport, accommodation services)" },
    ],
  },
  {
    country: "Sweden",
    currency: "SEK",
    vatOptions: [
      { vatRate: "25%", info: "Standard Rate" },
      { vatRate: "12%", info: "Reduced Rate (foodstuffs, restaurants, catering services, hotel stays)" },
      { vatRate: "6%", info: "Super-Reduced Rate (newspapers, books, passenger transport, cultural events, sports events)" },
      { vatRate: "0%", info: "Zero Rate (certain services exported outside the EU)" },
    ],
  },
  {
    country: "Germany",
    currency: "EUR",
    vatOptions: [
      { vatRate: "19%", info: "Standard Rate" },
      { vatRate: "7%", info: "Reduced Rate (foodstuffs, water supplies, certain pharmaceutical products, books, newspapers, periodicals)" },
    ],
  },
  {
    country: "France",
    currency: "EUR",
    vatOptions: [
      { vatRate: "20%", info: "Standard Rate" },
      { vatRate: "10%", info: "Reduced Rate (passenger transport, certain entertainment services)" },
      { vatRate: "5.5%", info: "Super-Reduced Rate (foodstuffs, water supplies, certain medical devices)" },
      { vatRate: "2.1%", info: "Particularly Reduced Rate (certain pharmaceutical products)" },
    ],
  },
  //

  // Non-EU Member Countries Included
  {
    country: "Norway",
    currency: "NOK",
    vatOptions: [
      { vatRate: "25%", info: "Standard Rate" },
      { vatRate: "15%", info: "Reduced Rate (foodstuffs)" },
      {
        vatRate: "12%",
        info: "Super-Reduced Rate (public transport, hotel accommodation, cinema tickets, museum and theme park entry fees)",
      },
    ],
  },
  {
    country: "United Kingdom",
    currency: "GBP",
    vatOptions: [
      { vatRate: "20%", info: "Standard Rate" },
      { vatRate: "5%", info: "Reduced Rate (some goods and services like children's car seats and home energy)" },
      { vatRate: "0%", info: "Zero Rate (books, children's clothes, most food products)" },
    ],
  },
];
