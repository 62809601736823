/** @format */

import React, { useState } from "react";
import VenueForm from "../components/Forms/VenueForm";
import { Button, Grid } from "@mui/material";
import AddVenue from "../components/Forms/AddVenue";
import GeneralForm from "../helpers/GeneralForm";
import { venueObj } from "../dbObjects.js/dbVenue";
import ConfirmDialog from "../helpers/ConfirmDialog";
import DisplayObject from "../helpers/displayObject";
import SyncTixlyEvents from "../components/SyncTixlyEvents";
import GeneralVenueForm from "../helpers/GeneralVenueForm";
import { useSelector } from "react-redux";

const OwnerVenueForm = () => {
  const [venueData, setVenueData] = useState();
  const superOwner = useSelector((state) => state.counter.superOwner);

  return (
    <Grid container p={{ xs: 0, sm: 4 }} xs={12}>
      <Grid item xs={12} sm={10}>
        {venueData && <h2>{venueData.venue}</h2>}
      </Grid>
      <Grid item xs={12} sm={2} display="flex" flexDirection="row-reverse">
        <AddVenue location="venueForm" />
      </Grid>

      {/* <VenueForm /> */}
      {/* <GeneralForm form={venueObj} formName="venueForm" priority={9} setVenueData={setVenueData} navigateAfter={-1} /> */}
      {/* <GeneralVenueForm form={venueObj} formName="venueForm" priority={9} setVenueData={setVenueData} navigateAfter={-1} /> */}
      <GeneralVenueForm form={venueObj} formName="venueForm" priority={9} setVenueData={setVenueData} navigateAfter={-1} />
      <Grid item mt={8}>
        {/* <DisplayObject data={venueData} /> */}
      </Grid>
      {/* <SyncTixlyEvents /> */}
    </Grid>
  );
};

export default OwnerVenueForm;
