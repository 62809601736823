import React from 'react';
import { Tooltip, Badge, IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

type Props = {};

export const ConfirmedBookings = (props: Props) => {
	const onClickHandler = () => {
		props.setShowBookingStatusType(
			props.showBookingStatusType === 'Confirmed' ? '' : 'Confirmed',
		);
	};
	return (
		<IconButton onClick={onClickHandler}>
			<Tooltip title={props.noConfirmed + ' confirmed bookings'}>
				<Badge
					badgeContent={props.noConfirmed}
					sx={{
						'& .MuiBadge-badge': {
							color: 'white',
							border: '1px solid #509a6c',
							backgroundColor: 'green',
							// backgroundColor: "green",
						},
					}}
				>
					<Done color='action' />
				</Badge>
			</Tooltip>
		</IconButton>
	);
};
