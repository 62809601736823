/** @format */

import { Grid, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import OwnerSignup from "./OwnerSignup";
import OwnerSignupStepper from "./OwnerSignupStepper";

const OwnerSignupPage = () => {
  const [passed, setPassed] = useState(false);
  const checkCode = (value) => {
    value === "jerrythedog" && setPassed(true);
  };
  return (
    <Grid container p={4} mt={4} justifyContent="center">
      <Grid container p={4} xs={12} md={8} style={{ background: "linear-gradient(0, #111, #333)", borderRadius: "10px" }}>
        <Grid item xs={12}>
          <h1>Set up your account</h1>
        </Grid>

        <Grid item xs={12} mt={10}>
          {!passed && <TextField placeholder="Enter code" onChange={(e) => checkCode(e.target.value)} />}
          {passed && <OwnerSignupStepper />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OwnerSignupPage;
