/** @format */

// TO DO..!!
// Needs some validation checks.
// If you supply an already existing email address a new account is not created.
// 1. Make a check on existing user when email is entered.
// 2. Disable continue button until ready.
// Also Password confirm needs to be setup and validated.

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import GeneralSignup from "../components/Authentication/GeneralSignup";
import { userObj } from "../dbObjects.js/dbUser";
import { venueObj } from "../dbObjects.js/dbVenue";
import GeneralForm from "../helpers/GeneralForm";
import { createId, db, login, signup, useAuth } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { storeCurrentUser, storeCurrentVenue } from "../features/venueFilter/venueFilterSlice";
import { useEffect } from "react";
import { useState } from "react";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StadiumIcon from "@mui/icons-material/Stadium";
import VerifiedSignup from "../components/Authentication/VerifiedSignUp";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { CircularProgress } from "@mui/material";
import ObjectDisplay from "../helpers/ObjectDisplay";
import { AES, enc } from "crypto-js";
import DisplayObject from "../helpers/displayObject";

export default function OwnerSignupStepper() {
  const [userData, setUserData] = useState({ Hej: "då", God: "dag" });
  const [venueData, setVenueData] = useState({});
  const [passedVerification, setPassedVerification] = useState(false);
  // const [validation,setValidation]=useState()
  const currentUSR = useAuth();
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentUserId = useSelector((state) => state.counter.currentUserId);
  const userID = createId();
  const venueID = createId();
  const productID = createId();
  let ownerid = "";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const data = "hej";

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userIdFromURL = urlParams.get("user");

  const [validation, setValidation] = useState(false);

  // Create a User and store to currentUser
  let newUser = {};
  const dispatch = useDispatch();
  const createUser = async () => {
    //Create documentid
    newUser.documentid = userID;
    newUser.userid = userID;
    newUser.role = "owner";
    newUser.venueAccess = venueID;
    ownerid = newUser.userid;

    dispatch(storeCurrentUser(newUser));

    newUser = [];
  };

  async function fucker() {
    console.log("creatinguser");
    createUserWithEmailAndPassword(getAuth(), "email@email.live", "password");
  }

  async function storeTemporary() {
    try {
      console.log("RUNNING STORE TEMPORARY", userData);
      const docRef = await setDoc(doc(db, "Temporary", userData.documentid), {
        ...userData,
      });
      console.log("= = = = = = >", docRef);
    } catch (error) {
      console.error("Error storing temporary data: ", error);
      // Handle the error here
    }
  }

  async function signupFunc() {
    // If already passed verification return...
    if (passedVerification) return;

    // otherwise store userData to Temporary in order to refill the form when passedVerification
    await storeTemporary();

    // return; //Remove THIS
    // Now pass necessary userData to verifiedSignup in order to check bankid...
    // ===> ...then wait for userIdFromURL (see useffect)
    console.log("READY TO FETCH", userData);
    const secretKey = process.env.REACT_APP_FORM_KEY; // Replace with your secret key
    const socialId = AES.decrypt(userData.socialId, secretKey).toString(enc.Utf8);
    const phoneNumber = userData.phone;
    const returnAddress = window.location.href;
    const userId = userData.userid;
    fetch(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/verifiedSignup?socialId=${socialId}&phoneNumber=${phoneNumber}&userId=${userId}&returnAddress=${returnAddress}&`
    )
      .then((response) => response.json())
      //   .then((data) => window.open(data.url, "_blank"))

      // .then((data) => window.open(data.url))
      .then((data) => (window.location.href = data.url))
      .catch((error) => console.error(error));
  }

  // Create a Venue and store to currentVenue
  let newVenue = [];
  const createVenue = async () => {
    //Create documentid
    newVenue.venueid = venueID;
    newVenue.documentid = venueID;
    newVenue.ownerid = ownerid;

    dispatch(storeCurrentVenue(newVenue));
    newVenue = [];
    console.log(newVenue);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    activeStep === 0 && signupFunc();

    activeStep === 1 && dispatch(storeCurrentVenue(venueData));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // If clicking "Back" from venue, then store venue values.
    activeStep === 1 && dispatch(storeCurrentVenue(venueData));
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Store to db then redirect to ownerHome
  const storeData = async () => {
    const docId = currentUser.venueAccess[0];
    // if (venueData.venueImage === undefined)
    //   venueData.venueImage =
    //     "https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/venueImages%2FvenuehubStandardImage.jpg?alt=media&token=7efa6926-985b-4d90-8eff-09fcbcdb1be7";
    await setDoc(doc(db, "Venues", docId), {
      ...venueData,
      venueid: docId,
      documentid: docId,
      ownerid: currentUserId,
      products: [
        {
          preferredDays: ["Thursday", "Friday", "Saturday"],
          product: "Night Event",
          productid: productID,
          documentid: productID,
          price: "10 000",
          getIn: "10:00",
          loadIn: "12:00",
          soundcheck: "15:00",
          doors: "18:00",
          show: "19:00",
          loadOut: "22:00",
          curfew: "23:00",
          typeOfEvent: ["Band"],
          product: "Night Event",
          sectionsToShow: { "Preferred Days": true, Pricing: true, Product: true, "Standard Running Times": true, "Type of Event": true },
        },
      ],
      created: serverTimestamp(),
    });
    // console.log("now navigate to Owner Home");
    setTimeout(() => {
      // navigate("/Owner/Home");
      window.location.href = "/Owner/Home";
    }, 1000);
  };

  useEffect(() => {
    async function createData() {
      await createUser();
      createVenue();
    }
    createData();
  }, []);

  //If passed Verification... signup new user, create "User" in user db, and so on...
  useEffect(() => {
    !passedVerification && setActiveStep(0);
    if (passedVerification && activeStep === 1) {
      signupUser();
      async function signupUser() {
        try {
          // Signup new user...
          const secretKey = process.env.REACT_APP_FORM_KEY;
          const decryptedPassword = AES.decrypt(currentUser.password, secretKey).toString(enc.Utf8); // decypting the password
          console.log(currentUser.email, decryptedPassword, currentUser.telephone);
          await signup(currentUser.email, decryptedPassword);
          currentUSR.uid && console.log("New User signed up:", currentUSR.uid);
        } catch {
          console.log("error");
        }
        console.log("current (fel) user: ", currentUser);
        currentUser.length === 0 && navigate("/Home");
        currentUserId && setRealUserData();
      }
    }
  }, [activeStep]);

  async function setRealUserData() {
    console.log("Setting real UserData");
    console.log(currentUser);
    //To avvoid accidently writing over all user data with nothing...

    let tempo = {
      ...currentUser,
      documentid: currentUserId,
      userid: currentUserId,
      venueAccess: [currentUser.venueAccess],
      created: serverTimestamp(),
    };
    if (currentUser.length === 0) {
      navigate("/Home");
    } else {
      await dispatch(storeCurrentVenue({ documentid: currentUser.venueAccess, venueid: currentUser.venueAccess, ownerid: currentUserId }));
      console.log(tempo, currentUserId);
      console.log("currentUser:", currentUser);
      console.log("tempo:", tempo, currentUserId);
      await setDoc(doc(db, "Users", currentUserId), {
        ...tempo,
        // created: serverTimestamp(),
      });
    }
    // THIS IS TO EARLY
    // await storeData();
  }

  useEffect(() => {
    console.log("Current User: ", currentUser);
  }, [currentUser]);

  useEffect(() => {
    console.log("CURRENT_USR", currentUSR);
  }, [currentUSR]);

  //if userIdFromURL changes that means it passed verification
  //then we need to get the userdocument from "Temporary" and fill up the form again.
  useEffect(() => {
    async function getData() {
      const docSnap = await getDoc(doc(db, "Temporary", userIdFromURL));

      dispatch(storeCurrentUser(docSnap.data()));
      console.log("> < > > > > ></>", userIdFromURL, docSnap.data());
      if (userIdFromURL === docSnap.data().userid) {
        setPassedVerification(true);
        setActiveStep(1);
      } else setActiveStep(0);
    }
    userIdFromURL && getData();
  }, [userIdFromURL]);

  useEffect(() => {
    // currentUserId && console.log("currentUserId is set!!!!!!", currentUserId);
    currentUserId && setRealUserData();
  }, [currentUserId]);

  //Using GeneralForms to show first userForm then venueForm, showing only priority 1 fields.
  const steps = [
    {
      label: <div>User Info</div>,
      description: "",
      form: (
        <div className="formInABox">
          <GeneralForm
            form={userObj}
            formName="userForm"
            priority={1}
            setUserData={setUserData}
            fields={["firstName", "lastName", "phone", "email", "password", "passwordConfirm", "socialId"]}
            setValidation={setValidation}
          />
        </div>
      ),
    },
    {
      label: (
        <div>
          <> Add Venue</>
        </div>
      ),
      description: "",
      form: (
        <div className="formInABox">
          {loading && <CircularProgress />}
          <GeneralForm form={venueObj} formName="venueForm" priority={1} setVenueData={setVenueData} />
        </div>
      ),
    },
    // {
    //   label: "Finish",
    //   description: ``,
    // },
  ];

  return (
    <Box sx={{ maxWidth: "80vw" }}>
      {/* <div>passed: {passedVerification}</div>
      <div>from redux: {currentUser.userid}</div>
      <div>from url: {userIdFromURL}</div> */}
      <span>CurrentUserId: {currentUserId} </span>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}>
              <h4>{step.label}</h4>
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              {step.form}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 10, mr: 1 }} color="primary" disabled={!validation}>
                    {index === 0 && !passedVerification && "Verify identity"}
                    {index === 0 && passedVerification && "Continue"}
                    {index === 1 && "Finish"}
                    {/* {index === 2 && "Finish"} */}
                  </Button>
                  {/* <Button disabled={index === 1} onClick={handleBack} sx={{ mt: 10, mr: 1 }} color="primary">
                    Back
                  </Button> */}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }} color="secondary">
            Reset
          </Button>
          {storeData()}
        </Paper>
      )}
    </Box>
  );
}
