/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import HomeIcon from "@mui/icons-material/Home";
import LibraryAdd from "@mui/icons-material/LibraryAdd";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import { MdOutlineCalendarToday } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import IncomingRequests from "../UI helpers/IncomingRequests";
import { Grid, Modal } from "@mui/material";
import { useState } from "react";
import InboxIcon from "@mui/icons-material/Inbox";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

export default function VenueSpeedDial() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const actions = [
    { icon: <CalendarMonth />, name: "Calendar", address: "/Venue/VenueCalendar" },
    { icon: <HomeIcon />, name: "Home", address: "/Owner/Home" },
    { icon: <AssignmentReturnIcon />, name: "Requests", address: "/Owner/BookingRequests" },
    { icon: <LibraryAdd />, name: "Venue", address: "/Owner/Venue" },
    { icon: <FormatListBulleted />, name: "Avails", address: "/Venue/Avails" },
  ];

  function GoTo(address) {
    navigate(address);
  }

  return (
    // <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1 }}>
    <Box sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
      {/* <Backdrop open={open} /> */}
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16, zIndex: "speedDial" }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={(e) => {
              e.preventDefault();
              navigate(action.address);
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
