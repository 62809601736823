/** @format */

export const today = () => {
  const date = new Date();

  //   return date.toLocaleString().substring(0, 10);
  return date.toLocaleDateString("sv-SE");
};

export const monthsFromNow = (month) => {
  const date = new Date();
  date.setMonth(date.getMonth() + month);
  // return date.toLocaleString().substring(0, 10);
  return date.toLocaleDateString("sv-SE");
};

export const monthsFrom = (d, m) => {
  const date = new Date(d);
  date.setMonth(date.getMonth() + m);
  // return date.toLocaleString().substring(0, 10);
  return date.toLocaleDateString("sv-SE");
};
