/** @format */

import { Avatar, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
// import { currentArtistConfirmed } from '../helpers/BookingManagement';
import SelectArtist from "../helpers/SelectArtist";
import SimpleAccordion from "./UI helpers/SimpleAccordion";
import SimpleSelect from "./UI helpers/SimpleSelect";

const ArtistInfoSmall = () => {
  let currentArtistBookings = [];

  const bookings = useSelector((state) => state.counter.bookings);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  console.log(currentArtist.artist);

  const confirmedBookings = bookings.filter((bookings) => currentArtist.artistid == bookings.artistid && bookings.status == "Confirmed");

  const currentArtistBookingsAndRequests = bookings.filter((bookings) => currentArtist.artistid == bookings.artistid);

  return (
    <div>
      <Grid container alignItems={"left"} direction={"row"} style={{ borderRadius: "10px" }}>
        <Grid item xs={12}>
          <SelectArtist />
        </Grid>
      </Grid>
    </div>
  );
};

export default ArtistInfoSmall;
