/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeThemeMode } from "../venueFilter/venueFilterSlice";
import LightModeIcon from "@mui/icons-material/LightMode";
import NightlightIcon from "@mui/icons-material/Nightlight";
import ToggleButton from "@mui/material/ToggleButton";

const ThemeSwitch = () => {
  const themeMode = useSelector((state) => state.counter.themeMode);
  const dispatch = useDispatch();
  const [theme, setTheme] = useState("darkTheme");
  const [selected, setSelected] = useState(true);

  useEffect(() => {
    selected ? setTheme("darkTheme") : setTheme("lightTheme");
    dispatch(storeThemeMode(theme));
    console.log(theme);
    console.log(themeMode);
  }, [selected]);

  return (
    <div>
      <ToggleButton
        className="themeToggle"
        size="small"
        value={theme}
        selected={selected}
        onChange={() => {
          setSelected(!selected);
        }}
      >
        {theme === "darkTheme" ? (
          <LightModeIcon className="mx-3" size={25} color={"white"} />
        ) : (
          <NightlightIcon className="mx-3" size={25} color={"white"} />
        )}
      </ToggleButton>
    </div>
  );
};

export default ThemeSwitch;
