/**
 * old Agent
 *
 * @format
 */

/** @format */

import React, { useEffect, useState } from "react";
// import Container from 'react-bootstrap/Container';
import SidebarAgent from "../components/Sidebar/SidebarAgent";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import FilterVenues from "../features/venueFilter/FilterVenues";
import GetVenues from "../helpers/GetVenues";
import GetBookings from "../helpers/GetBookings";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CheckUserRole from "../components/Authentication/CheckUserRole";
import CreateTimeline from "../features/create tour/CreateTimeline";
import CreateTour from "../features/create tour/CreateTour";
import SimpleSelectAvailDates from "../components/UI helpers/SimpleSelectAvailDates";
import Betalcompoonent from "../components/UI helpers/Betalcompoonent";
import GetArtists from "../helpers/GetArtists";
import SendRequest from "../helpers/SendRequest";
import ArtistInfoSmall from "../components/ArtistInfoSmall";
import { listenAllBookings } from "../helpers/Listeners";
import { MainGoogleMapComponent } from "../components/Maps/MainGoogleMapComponent";
import HomeIcon from "@mui/icons-material/Home";
import RecommendIcon from "@mui/icons-material/Recommend";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useDispatch, useSelector } from "react-redux";
import BottomAgentControls from "../components/Sidebar/BottomAgentControls";
import SearchInput from "../components/MiscUIComponents/SearchInput";
import { bgcolor } from "@mui/system";
import { storeMapIsOpen, storeTimelineIsOpen } from "../features/venueFilter/venueFilterSlice";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MapIcon from "@mui/icons-material/Map";
import store from "../app/store";
import DelayedTimeline from "../features/create tour/DelayedTimeline";
import IncomingPing from "../components/Sounds/IncomingPing";
import { DatePicker } from "@mui/x-date-pickers";
import ArtistEventList from "../features/bookings/ArtistEventList";
import ViewListIcon from "@mui/icons-material/ViewList";
import { ExpandMore } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SidebarAgentControls from "../components/Sidebar/SidebarAgentControls";
import TuneIcon from "@mui/icons-material/Tune";

const drawerWidth = 300;
const userType = "agent";
const myImage = "src/components/images/brown-wooden-flooring_53876-90802.jpg";

const Agent = () => {
  const dispatch = useDispatch();
  // const timelineModal = useSelector((state) => state.counter.timelineModal);
  const timelineIsOpen = useSelector((state) => state.counter.timelineIsOpen);
  const mapIsOpen = useSelector((state) => state.counter.mapIsOpen);
  const wantedList = useSelector((state) => state.counter.wantedList);

  const imgColor = "#00ffd4";
  const imgSize = "150px";

  const [mapIsChecked, setMapIsChecked] = useState(true);
  const [showVenues, setShowVenues] = useState("none");
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [selectedVenueId, setSelectedVenueId] = useState("");
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [showVenueInfo, setShowVenueInfo] = useState(false);
  const [numberOfVenues, setNumberOfVenues] = useState(0);
  const [mapBlur, setMapBlur] = useState(1);
  const [timelineModal, setTimelineModal] = useState("none");
  // const handleOpen = () => {
  //   setTimelineModal("block");
  //   dispatch(storeTimelineIsOpen(true));
  // };
  // const handleClose = () => {
  //   setTimelineModal("none");
  //   dispatch(storeTimelineIsOpen(false));
  // };

  listenAllBookings();
  useEffect(() => {
    setTimeout(() => {
      // handleClose();
      dispatch(storeTimelineIsOpen(false));
    }, 100);
  }, []);
  // const availableVenuesAndDates = useSelector((state) => state.counter.availableVenuesAndDates);

  // setTimeout(() => {
  //   setMapBlur(10);
  // }, 2000);

  const style = {
    // position: "absolute",
    // top: "50%",
    // right: "2%",
    // transform: "translate(-50%, -50%)",
    // width: "35%",
    // bgcolor: "#ffffff11",
    // backdropFilter: "blur(10px)",
    // // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
  };

  // const showMap = (e) => {
  //   console.log(e.target.checked);
  // };

  useEffect(() => {
    if (timelineIsOpen) {
      setTimelineModal("block");
    }
    if (!timelineIsOpen) {
      setTimelineModal("none");
    }
  }, [timelineIsOpen]);

  useEffect(() => {
    wantedList.length > 0 ? dispatch(storeTimelineIsOpen(true)) : dispatch(storeTimelineIsOpen(false));
  }, [wantedList]);

  useEffect(() => {
    if (mapIsOpen) {
      setMapBlur(0);
      setShowVenues("none");
    }
    if (!mapIsOpen) {
      setMapBlur(10);
      setShowVenues("block");
    }
  }, [mapIsOpen]);

  return (
    <div>
      <Grid container xs={12}>
        <Grid
          item
          sx={12}
          sm={5}
          style={{ background: "orange" }}
          sx={{
            minWidth: drawerWidth,
            maxWidth: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
            display: { xs: "none", md: "block" },
          }}
        >
          <Drawer
            variant="permanent"
            sx={{
              minWidth: drawerWidth,
              maxWidth: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
                backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
                border: "none",
              },
              display: { xs: "none", md: "block" },
            }}
          >
            <Toolbar />
            <Box sx={{ overflow: "auto" }}>
              <div style={{ padding: "20px" }}>
                <ArtistInfoSmall />
              </div>
              {/* <List> */}
              {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>{index % 2 === 0 ? <HomeIcon /> : <RecommendIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
              <div>{/* <ArtistEventList /> */}</div>
              <Accordion sx={{ background: "transparent" }}>
                <ListItemButton>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />} sx={{ left: "-15px" }}>
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    Upcoming Events
                  </AccordionSummary>{" "}
                </ListItemButton>
                <AccordionDetails sx={{ background: "#222" }}>
                  <Typography>
                    <ArtistEventList />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* </List> */}
              <Divider />
              <Accordion sx={{ background: "transparent" }} classname="sidebarFilter" style={{ display: "none" }}>
                <ListItemButton>
                  <AccordionSummary aria-controls="" id="" expandIcon={<ExpandMore />} sx={{ left: "-15px" }}>
                    <ListItemIcon>
                      <TuneIcon />
                    </ListItemIcon>
                    Filter
                  </AccordionSummary>
                </ListItemButton>
                <AccordionDetails sx={{ background: "#222" }}>
                  <SidebarAgentControls timelineModal={timelineModal} />
                </AccordionDetails>
              </Accordion>
              <div style={{ position: "absolute", bottom: "50px", left: "25px" }}>
                <div className="tourSwitch">
                  <span style={{ marginRight: "15px" }}>
                    <ViewListIcon />
                  </span>
                  <FormControlLabel
                    control={
                      <Switch checked={timelineIsOpen} onChange={(e) => dispatch(storeTimelineIsOpen(e.target.checked))} size="small" />
                    }
                    label="Tour"
                  />
                </div>
                <div className="mapSwitch">
                  <span style={{ marginRight: "15px" }}>
                    <MapIcon />
                  </span>
                  <FormControlLabel
                    control={<Switch checked={mapIsOpen} onChange={(e) => dispatch(storeMapIsOpen(e.target.checked))} size="small" />}
                    label="Map"
                  />
                </div>
              </div>
            </Box>
            <div style={{ padding: "20px" }}>
              {/* <TextField size="small" fullWidth placeholder="Where to?" /> */}
              {/* <SearchInput /> */}
            </div>
            {/* <div style={{ marginBottom: "15px", marginLeft: "20px", marginRight: "20px", fontWeight: "600", borderBottom: "1px solid #333" }}>
          VENUES | {numberOfVenues} within scope
        </div>
        <div style={{ height: "58vh", overflow: "scroll" }}>
          <FilterVenues numberOfVenues={setNumberOfVenues} />
        </div> */}
          </Drawer>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            overflow: "hidden",
            width: "100%",
            position: "fixed",
            filter: `blur(${mapBlur}px)`,
            zIndex: "-10",
            transition: "all 1s",
            background: "black",
          }}
        >
          <MainGoogleMapComponent
            // availableDates={availableDates}
            setSelectedVenueId={setSelectedVenueId}
            setSelectedVenue={setSelectedVenue}
            setShowVenueInfo={setShowVenueInfo}
          />
        </Grid>
        <IncomingPing />
        <Grid item xs={12} sm={10} bgcolor={"#000000ce"} style={{ marginLeft: "300px", backdropFilter: "blur(0px)", display: showVenues }}>
          <Grid item p={4} height={"35vh"} style={{ backgroundImage: "linear-gradient(#00373c, transparent)" }}>
            {/* <h4 style={{ marginTop: "50px" }}>Add ticket price to get revenue and cost calculation instantly.</h4> */}
            {/* <h4 style={{ marginTop: "50px" }}> Tour nearby to reduce Co2e footprint.</h4> */}
            <h1 style={{ marginTop: "50px" }}>A sustainable way to play</h1>
            <div style={{ width: imgSize, height: imgSize, overflow: "hidden", float: "left", position: "relative" }}>
              <div
                style={{
                  background: imgColor,
                  height: imgSize,
                  width: imgSize,
                  zIndex: "2",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  mixBlendMode: "multiply",
                }}
              ></div>
              <img
                width="auto"
                height={imgSize}
                style={{ position: "absolute", top: "0px", left: "0px", filter: "saturate(0)" }}
                src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/venueImages%2Faee28c4b-13f8-46a0-8e26-e1dd40e86220?alt=media&token=5287e9c7-8e7c-48bc-ba6d-1ff157d5a553"
              />
            </div>
            <div style={{ width: imgSize, height: imgSize, overflow: "hidden", float: "left", position: "relative" }}>
              <div
                style={{
                  background: imgColor,
                  height: imgSize,
                  width: imgSize,
                  zIndex: "2",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  mixBlendMode: "multiply",
                }}
              ></div>
              <img
                width="auto"
                height={imgSize}
                style={{ position: "absolute", top: "0px", left: "0px", filter: "saturate(0)" }}
                src="https://firebasestorage.googleapis.com/v0/b/test-db-n-forms.appspot.com/o/venueImages%2FlEjxGdjZthQkEQ9HnbUJ?alt=media&token=c914faed-57e7-4fa9-8cd3-6e67ae34afd7"
              />
            </div>
            <div style={{ width: imgSize, height: imgSize, overflow: "hidden", float: "left", position: "relative" }}>
              <div
                style={{
                  background: imgColor,
                  height: imgSize,
                  width: imgSize,
                  zIndex: "2",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  mixBlendMode: "multiply",
                }}
              ></div>
              <img
                width="auto"
                height={imgSize}
                style={{ position: "absolute", top: "0px", left: "0px", filter: "saturate(0)" }}
                src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/venueImages%2Ff9ec3a29-21e4-4bb4-8b7b-fcba2e41092e?alt=media&token=e812b7e6-b7de-4fa2-b7a0-1a530ed90bc1"
              />
            </div>
            <div style={{ width: imgSize, height: imgSize, overflow: "hidden", float: "left", position: "relative" }}>
              <div
                style={{
                  background: imgColor,
                  height: imgSize,
                  width: imgSize,
                  zIndex: "2",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  mixBlendMode: "multiply",
                }}
              ></div>
              <img
                width="auto"
                height={imgSize}
                style={{ position: "absolute", top: "0px", left: "0px", filter: "saturate(0)" }}
                src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/venueImages%2F12988a22-bf40-40d6-a546-adc57d59bf0e?alt=media&token=638f4ae0-1e00-431e-8e4a-e6ed8ac9bd2a"
              />
            </div>

            <div style={{ marginTop: "120px", fontWeight: "10", width: "400px", position: "relative" }}>
              <b>Set date range and capacity</b> to get going! Add ticket price to get revenue and cost calculation instantly. Tour nearby
              to reduce CO2e footprint.
            </div>
          </Grid>
          <Grid container mt={30}>
            <Grid item p={4} xs={12} sm={8}>
              {/* <Button onClick={handleClose}>Close modal</Button> */}
              VENUES | {numberOfVenues} within scope
              {/* {timelineModal} */}
              <FilterVenues numberOfVenues={setNumberOfVenues} />
            </Grid>
            {/* <Grid item xs={12} sm={4} style={{ background: "#ff00dd", height: "50vh" }}></Grid> */}
          </Grid>
        </Grid>
        {/* {timelineModal && handleOpen} */}

        {/* <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby=" modal-modal-description"> */}
        <Box sx={style} className="timelineModal" style={{ display: timelineModal }}>
          {/* {timelineModal}
          {open} */}
          <Button
            onClick={() => {
              dispatch(storeTimelineIsOpen(false));
            }}
            variant="outlined"
            className="tlCloseBtn"
            size="small"
          >
            <CloseIcon />
          </Button>
          <div style={{ height: "80vh", overflow: "scroll", marginTop: "50px" }}>
            <CreateTimeline />
          </div>
        </Box>
        {/* </Modal> */}

        <GetVenues />
        <GetArtists />

        <Toolbar
          // style={{ display: "none" }}
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            bottom: "0",
            position: "fixed",
            zIndex: "2",
            background: "#111111ee",
            height: "100px",
            backdropFilter: "blur(5px)",
            borderTop: "1px solid #333",
            // zoom: "0.8",
          }}
        >
          <BottomAgentControls timelineModal={timelineModal} />
        </Toolbar>
        {/* </Grid> */}
      </Grid>

      {/* Agreement Modal */}
      {/* <Modal open={agreementModalOpen} onClose={handleCloseAgreementModal} aria-labelledby="bookingform" aria-describedby="bookingform">
        <div
          style={{
            background: "white",
            color: "black",
            width: "90vh",
            height: "90vh",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          Review agreement and click "Send"
          <AgreementModal bookingid={currentBookingId} />
          <Button
            style={{ position: "absolute", bottom: "20px", right: "50px", color: "white" }}
            variant="contained"
            color="success"
            onClick={() => {
              confirmAndSendAgreement();
            }}
            startIcon={<SendIcon />}
            size={"small"}
          >
            Sign and send agreement
          </Button>
        </div>
      </Modal> */}
    </div>
  );
};

export default Agent;
