/** @format */

export const artistObj = [
  { section: "Document", type: "Section" },
  {
    section: "Document",
    placeholder: "Document ID",
    label: "Document ID",
    id: "documentid",
    type: "text",
    disabled: true,
  },
  { section: "Document", label: "Document Type", documentType: "", id: "", type: "text", disabled: true },
  { section: "Image", type: "Section" },
  {
    section: "Artist Image",
    name: "image",
    placeholder: "Image",
    label: "Image",
    id: "image",
    defaultValue:
      "https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/venueImages%2FvenuehubStandardImage.jpg?alt=media&token=7efa6926-985b-4d90-8eff-09fcbcdb1be7",
    type: "imageUpload",
    storage: "artistImages",
  },
  {
    section: "Artist",
    type: "Section",
  },
  {
    section: "Artist",
    name: "artist",
    label: "Artist",
    placeholder: "Artist",
    id: "artist",

    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "Artist",
    name: "artistid",
    label: "ArtistID",
    placeholder: "ArtistID",
    id: "artistid",

    type: "text",
    disabled: "true",
    size: "small",
    priority: 1,
  },
  {
    section: "Artist",
    name: "userid",
    label: "UserID",
    placeholder: "UserID",
    id: "uid",

    type: "text",
    disabled: "true",
    size: "small",
    priority: 1,
  },
  {
    section: "Artist",
    name: "city",
    label: "City",
    placeholder: "City",
    id: "artistCity",

    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "Artist",
    name: "country",
    label: "Country",
    placeholder: "Country",
    id: "artistCountry",

    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "About",
    type: "Section",
  },
  {
    section: "About",
    name: "about",
    label: "About",
    placeholder: "About",
    id: "about",
    multiline: true,
    rows: 4,
    style: { width: "500px" },
    type: "textarea",
    size: "small",
    priority: 1,
  },
  {
    section: "Socials",
    type: "Section",
  },
  {
    section: "Socials",
    name: "Website",
    label: "Website",
    placeholder: "Website",
    id: "website",
    type: "url",
    size: "small",
    priority: 1,
  },
  {
    section: "Socials",
    name: "facebook",
    label: "Facebook URL",
    placeholder: "Facebook URL",
    id: "facebook",
    type: "url",
    size: "small",
    priority: 1,
  },
  {
    section: "Socials",
    name: "twitter",
    label: "Twitter URL",
    placeholder: "Twitter URL",
    id: "twitter",
    type: "url",
    size: "small",
    priority: 1,
  },
  {
    section: "Socials",
    name: "Instagram",
    label: "Instagram URL",
    placeholder: "Instagram URL",
    id: "Instagram",
    type: "url",
    size: "small",
    priority: 1,
  },
  {
    section: "Socials",
    name: "Tiktok",
    label: "Tiktok URL",
    placeholder: "Tiktok URL",
    id: "Tiktok",
    type: "url",
    size: "small",
    priority: 1,
  },
  {
    section: "Socials",
    name: "spotify",
    label: "Spotify URL",
    placeholder: "Spotify URL",
    id: "spotify",
    type: "url",
    size: "small",
    priority: 1,
  },
  {
    section: "Preferred Days",
    type: "Section",
  },
  {
    section: "Preferred Days",
    id: "prefDays",
    label: "Preferred Days",
    options: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    type: "toggleButton",
    size: "small",
  },
  {
    section: "Location",
    type: "Section",
  },
  {
    section: "Location",
    name: "lat",
    label: "Latitude",
    placeholder: "Latitude",
    id: "lat",

    type: "text",
    standardValue: 18,
    size: "small",
    priority: 1,
  },
  {
    section: "Location",
    name: "lng",
    label: "Longitude",
    placeholder: "Longitude",
    id: "lng",

    type: "text",
    standardValue: 59,
    size: "small",
    priority: 1,
  },
  {
    section: "Location",
    type: "addLocationComponent",
    fieldPrepend: "artist",
  },
  // {
  // 	section:'location',
  // 	name:'radius',
  // 	placeholder:'Preferred distance',
  // 	id:'radius',
  // 	class:'form',
  // 	type:'range',
  // 	currency:'km'
  // },
  {
    section: "Ticket Price",
    type: "Section",
  },
  {
    section: "Ticket Price",
    name: "ticketprice",
    label: "Ticket Price",
    placeholder: "Ticket Price",
    id: "ticketprice",

    type: "number",
    steps: "10",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    type: "Section",
  },
  {
    section: "Contact Agent",
    name: "agent",
    label: "Agent Name",
    placeholder: "Agent Name",
    id: "agent",
    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    name: "agentid",
    label: "Agent ID",
    placeholder: "Agent ID",
    id: "agentid",

    type: "text",
    disabled: "true",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    name: "agentPhone",
    label: "Telephone Agent",
    placeholder: "Telephone Agent",
    id: "agentPhone",

    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    name: "agentEmail",
    label: "Email Agent",
    placeholder: "Email Agent",
    id: "agentEmail",
    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    name: "agentStreetAddress",
    label: "Street Address",
    placeholder: "Street Address",
    id: "agentStreetAddress",

    source: "Venues",
    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    name: "agentZipCode",
    label: "Zip Code",
    placeholder: "Zip Code",
    id: "agentZipCode",
    source: "Venues",
    type: "number",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    name: "agentCity",
    label: "City",
    placeholder: "City",
    id: "agentCity",

    source: "Venues",
    type: "text",
    size: "small",
    priority: 1,
  },
  {
    section: "Contact Agent",
    name: "agentCountry",
    label: "Country",
    placeholder: "Country",
    id: "agentCountry",

    source: "Venues",
    type: "text",
    size: "small",
    priority: 1,
  },
  // {
  // 	section: 'Images',
  // 	type: 'Section',
  // },
  // {
  // 	section: 'Main Image',
  // 	name: 'image',
  // 	label: 'Image',
  // 	placeholder: 'Image',
  // 	id: 'artistImage',
  // 	type: 'upload',
  // 	size: 'small',
  // 	priority: 1,
  // },
  { type: "Section", section: "" },
  {
    id: "update",
    type: "update",
    label: "Save",
    collection: "Testing",
    documentId: "g6nzg0PO3YPriuG9JDP1",
  },

  {
    id: "delete",
    type: "delete",
    label: "Delete",
    collection: "Testing",
    documentId: "artistid",
  },
];
