/** @format */

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import { connectStorageEmulator } from "firebase/storage";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { premisesObj } from "../dbObjects.js/dbPremises";
import GreenStepper from "../features/owner/greenDeal/GreenStepper";
import DisplayObject from "../helpers/displayObject";
import GeneralForm from "../helpers/GeneralForm";

const Certificates = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [formData, setFormData] = useState({});
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const handleChange = (key, value) => {
    console.log(key, value);
    console.log(formData);
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleSave = () => {
    alert("storing data...");
  };
  return (
    <Grid container xs={12} p={6} style={{ display: "flex", gap: "15px" }}>
      <Grid item>
        {/* <h1>Certificates</h1> */}
        <h2>Sustainability</h2>
        In order to start becoming a greener venue, please answer the questions below.
      </Grid>
      <Grid item xs={12} mt={4}>
        {/* <GreenStepper /> */}
      </Grid>

      <Grid item xs={12} sm={6} style={{ display: "flex", gap: "15px" }} flexDirection="column">
        <h4>Premises</h4>
        <TextField
          label="Reporting year"
          value={currentYear}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChange("year", e.target.value)}
        />
        <TextField
          label="Square meter area of the venue"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChange("propertySize", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">m2</InputAdornment>,
          }}
        />
        <TextField
          label="Visitor capacity"
          defaultValue={currentVenue.capacity}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChange("capacity", e.target.value)}
        />
        <h4>Electricity</h4>
        <h6>1.1 Electricity use</h6>
        Purchased electrcitiy during the reporting year
        <Slider
          name={"electricityUse"}
          label="Purchased electrcitiy during the reporting year"
          min={1000}
          max={200000}
          step={1000}
          marks
          valueLabelDisplay={formData.electricityUse === "not available" ? "off" : "on"}
          valueLabelFormat={(v) => `${v.toLocaleString()} kWh`}
          onChange={(e) => handleChange("electricityUse", e.target.value)}
          style={{ width: "300px", marginTop: "30px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.electricityUse === "not available"}
              onChange={(e) => handleChange("electricityUse", "not available")}
            />
          }
          label="I don't know"
        />
        <h6>1.2 Electricity source</h6>
        If you purchase electricity from renewable sources such as wind, hydro or solar, provide the answer in the field below. Statements
        should be backed up by evidence from the energy supplier, with a so called GO (garantuee of origin) or REC (Renewable energy
        certificate)
        <FormControl variant="outlined">
          <InputLabel shrink>Type of electricity</InputLabel>
          <Select
            value={formData.electricitySource}
            onChange={(e) => handleChange("electricitySource", e.target.value)}
            style={{ minWidth: "200px" }}
          >
            <MenuItem value="Renewable">Renewable electricity</MenuItem>
            <MenuItem value="Non renewable">Non renewable electricity</MenuItem>
            <MenuItem value="Dont know">Dont know</MenuItem>
          </Select>
        </FormControl>
        <h6>1.3 On-site produced electricity </h6>
        The quantity of on-site generated electricity, from for example solar energy.
        <Slider
          name={"electricityGenerated"}
          label="Electrcitiy generated during the reporting year"
          min={1000}
          max={200000}
          step={1000}
          marks
          valueLabelDisplay={formData.electricityGenerated === "not applicable" ? "off" : "on"}
          valueLabelFormat={(v) => `${v.toLocaleString()} kWh`}
          onChange={(e) => handleChange("electricityGenerated", e.target.value)}
          style={{ width: "300px", marginTop: "30px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.electricityGenerated === "not applicable"}
              onChange={(e) => handleChange("electricityGenerated", "not applicable")}
            />
          }
          label="Not applicable"
        />
        <h4>Heating and fuel use</h4>
        Heating and on-site fuel use from the facility during the reporting year.
        <h6>2.1 Type and quantity of heating used</h6>
        Specifify the type of heating used for the facility and the quantity used for the reporting year
        <Grid item flexDirection="row" style={{ display: "flex", gap: "15px" }}>
          <FormControl variant="outlined">
            <InputLabel shrink>Type of heating</InputLabel>
            <Select
              value={formData.heatingUsed}
              onChange={(e) => handleChange("heatingUsed", e.target.value)}
              style={{ minWidth: "150px" }}
            >
              <MenuItem value="dont know">I don't know</MenuItem>
              <MenuItem value="not">Not applicable</MenuItem>
              <MenuItem value="district heating">District heating</MenuItem>
              <MenuItem value="natural gas">Natural gas</MenuItem>
              <MenuItem value="electricity heating">Electricity heating</MenuItem>
              <MenuItem value="lng">LNG</MenuItem>
              <MenuItem value="lpg">LPG</MenuItem>
              <MenuItem value="cng">CNG</MenuItem>
              <MenuItem value="burning oil">Burning oil</MenuItem>
              <MenuItem value="diesel">Diesel</MenuItem>
            </Select>
          </FormControl>
          {formData.heatingUsed !== ("dont know" || "not") && (
            <>
              <TextField
                label="Quantity"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("heatingQuantity", e.target.value)}
                style={{ width: "100px" }}
              />
              <FormControl variant="outlined">
                <InputLabel shrink>Unit</InputLabel>
                <Select
                  label="Unit"
                  value={formData.heatingUnit}
                  onChange={(e) => handleChange("heatingUnit", e.target.value)}
                  style={{ minWidth: "50px" }}
                >
                  <MenuItem value="tonnes">Tonnes</MenuItem>
                  <MenuItem value="litres">Litres</MenuItem>
                  <MenuItem value="kWh">KWh</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </Grid>
        <h4>Refrigerants</h4>
        Refrigerants are used in air conditioning systems and heat pumps.
        <h6>3.1 Type and quantity of refrigerants refilled</h6>
        If known, specifiy the type if refrigerants used and the refilled amount during the reporting year
        <Grid item style={{ display: "flex", gap: "15px" }} flexDirection="row">
          <FormControl variant="outlined">
            <InputLabel shrink>Type</InputLabel>
            <Select
              value={formData.refrigerantType}
              onChange={(e) => handleChange("refrigerantType", e.target.value)}
              style={{ minWidth: "100px" }}
            >
              <MenuItem value="dont-know">I don't know</MenuItem>
              <MenuItem value="not-applicable">Not applicable</MenuItem>
              <MenuItem value="hfc-23">HFC-23</MenuItem>
              <MenuItem value="hfc-32">HFC-32</MenuItem>
              <MenuItem value="hfc-41">HFC-41</MenuItem>
              <MenuItem value="hfc-125">HFC-125</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Quantity"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleChange("refrigerantQuantity", e.target.value)}
            style={{ width: "50%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
        <h4>Water</h4>
        Water used on the premises during the reporting year.
        <h6>4.1 Quantity of water used on the premises</h6>
        <Slider
          name={"waterUse"}
          label="Quantity of water"
          min={1000}
          max={200000}
          step={1000}
          marks
          valueLabelDisplay={formData.waterUse === "not available" ? "off" : "on"}
          valueLabelFormat={(v) => `${v.toLocaleString()} litres`}
          onChange={(e) => handleChange("waterUse", e.target.value)}
          style={{ width: "300px", marginTop: "30px" }}
        />
        <FormControlLabel
          control={<Checkbox checked={formData.waterUse === "not available"} onChange={(e) => handleChange("waterUse", "not available")} />}
          label="I don't know"
        />
        <h4>Waste</h4>
        Generated waste during the reporting year from the premises.
        <h6>5.1 Quantity of waste used on the premises per waste fraction</h6>
        <TextField
          label="Recycled metal waste"
          type="number"
          variant="outlined"
          value={formData.recycledMetal}
          onChange={(e) => handleChange("recycledMetal", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        />
        <TextField
          label="Recycled plastic waste"
          type="number"
          variant="outlined"
          value={formData.recycledPlastic}
          onChange={(e) => handleChange("recycledPlastic", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        />
        <TextField
          label="Recycled paper waste"
          type="number"
          variant="outlined"
          value={formData.recycledPaper}
          onChange={(e) => handleChange("recycledPaper", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        />
        <TextField
          label="Recycled electric waste"
          type="number"
          variant="outlined"
          value={formData.recycledElectric}
          onChange={(e) => handleChange("recycledElectric", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        />
        <TextField
          label="Mixed household waste"
          type="number"
          variant="outlined"
          value={formData.mixedHousehold}
          onChange={(e) => handleChange("mixedHousehold", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        />
        <TextField
          label="Landfilled waste"
          type="number"
          variant="outlined"
          value={formData.landfilledWaste}
          onChange={(e) => handleChange("landfilledWaste", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        />
        {/* <Grid item>
          <Grid item>Year: {formData.year}</Grid>
          <Grid item>Size: {formData.propertySize}</Grid>
          <Grid item>Capacity: {formData.capacity}</Grid>
          <Grid item>Electricity use: {formData.electricityUse}</Grid>
          <Grid item>Electricity source: {formData.electricitySource}</Grid>
          <Grid item>Electricity generated: {formData.electricityGenerated}</Grid>
          <Grid item>Heating used: {formData.heatingUsed}</Grid>
          <Grid item>
            Heating quantity: {formData.heatingQuantity} {formData.heatingUnit}
          </Grid>
          <Grid item>Refrigeration used: {formData.refrigerantType}</Grid>
          <Grid item>Refrigeration quantity: {formData.refrigerantQuantity} kg</Grid>
          <Grid item>Water use quantity: {formData.waterUse} litres</Grid>
        </Grid> */}
        <Grid item flexDirection="row" style={{ display: "flex", gap: "15px", marginTop: "50px" }}>
          <Button onClick={() => handleSave()} variant="contained">
            Save
          </Button>
          <Button onClick={() => navigate(-1)} variant="outlined">
            Cancel
          </Button>
        </Grid>
      </Grid>
      {/* <DisplayObject data={formData} /> */}
    </Grid>
  );
};

export default Certificates;
