/** @format */

import React, { useEffect } from "react";
import axios from "axios";
import { RepeatOneSharp } from "@mui/icons-material";
import DisplayObject from "../helpers/displayObject";
import { useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";

const PaymentSuccess = () => {
  // Get PaymentId from URL
  var urlParams = new URLSearchParams(window.location.search);
  const [paymentId, setPaymentId] = useState(urlParams.get("paymentId"));

  const [responseData, setResponseData] = useState(null);

  // Chack status of payment
  async function checkPayment() {
    setResponseData(null);
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/checkPaymentStatus`, {
      paymentId: paymentId,
    });
    setResponseData(response.data.response);
    console.log(response.data.response);
  }

  useEffect(() => {
    console.log("PAYMENTID", paymentId);
    checkPayment();
  }, [paymentId]);

  return (
    <Grid container mt={4} xs={12} p={4}>
      <Grid item xs={12}>
        {responseData ? (
          <h6>Payment status {responseData}</h6>
        ) : (
          <h4>
            <CircularProgress style={{ zoom: "0.5" }} size="20" />
          </h4>
        )}
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
