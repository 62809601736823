/** @format */

import { Button, CircularProgress, Grid, Slider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { numberFormatted } from "../helpers/Numbers";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { set } from "date-fns";
import { PieChart } from "@mui/icons-material";
import PieChartCenteredLabel from "../components/UI helpers/PieChartCenteredLabel";
import axios from "axios";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Timestamp } from "@google-cloud/firestore";
import SelectCountry from "../helpers/SelectCountry";
import LoadingDots from "../components/UI helpers/LoadingDots";

const EventTravelForm = () => {
  var urlParams = new URLSearchParams(window.location.search);
  const bookingid = urlParams.get("id") || "test";
  console.log("bookingid", bookingid);
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState(0);
  const [remaining, setRemaining] = useState(100);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const sliderSettings = {
    step: 5, // Disable continuous sliding by setting step to null
    marks: [
      { value: 0, label: "0" },
      { value: 100, label: "100" },
    ],
    min: 0,
    max: 100,
    valueLabelDisplay: "off", // Automatically display the value label
  };
  const [ip, setIp] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [countryCode, setCountryCode] = useState("SE");
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [formData, setFormData] = useState({
    Plane: 0,
    Car: 0,
    Bus: 0,
    Train: 0,
    Bike: 0,
    Walk: 0,
  });

  const keyIcon = (key) => {
    const icon = {
      Car: <DirectionsCarIcon />,
      Bus: <DirectionsBusIcon />,
      Train: <DirectionsRailwayIcon />,
      Plane: <AirplanemodeActiveIcon />,
      Bike: <DirectionsBikeIcon />,
      Walk: <DirectionsWalkIcon />,
    };
    return icon[key];
  };

  const indexColor = (key) => {
    const color = { Car: "red", Bus: "orange", Train: "green", Plane: "darkred", Bike: "lightgreen", Walk: "darkgreen" };
    return color[key];
  };

  const handleChange = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSliderChange = (key) => (event, newValue) => {
    let totalExcludingCurrent = Object.keys(formData)
      .filter((k) => k !== key)
      .reduce((sum, k) => sum + formData[k], 0);

    if (totalExcludingCurrent + newValue > 100) {
      newValue = 100 - totalExcludingCurrent;
    }

    let newFormData = { ...formData, [key]: newValue };
    let total = Object.keys(newFormData).reduce((sum, k) => sum + newFormData[k], 0);

    setRemaining(100 - total);
    setFormData(newFormData);
  };

  const next = () => (
    <Button onClick={() => setValue(value + 1)} fullWidth variant="contained">
      Nästa
    </Button>
  );
  const previous = () => (
    <Button onClick={() => setValue(value - 1)} variant="outlined" style={{ opacity: "0.5" }}>
      <ArrowBackIosIcon /> Tillbaka
    </Button>
  );
  const send = () => (
    <Button onClick={() => sendData()} fullWidth variant="contained" disabled={!loaded}>
      Skicka
    </Button>
  );

  async function sendData() {
    await storeData();
    setValue(value + 1);
  }

  useEffect(() => {
    setTimeout(() => {
      setValue(1);
    }, 2000);
  }, []);

  useEffect(() => {
    async function getCoordinates() {
      if (value !== 2) return;

      try {
        setLoaded(false);
        const response = await axios.get(`https://europe-west3-venuehub-64e72.cloudfunctions.net/getCoordinates`, {
          // const response = await axios.get(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/getCoordinates`, {
          params: { zipCode, countryCode: countryCode },
        });
        console.log(zipCode, countryCode);
        console.log(response.data);
        alert(`${response.data.countryCode} is not ${countryCode}`);
        if ((await response.data.countryResponse) !== (await response.data.countryCode)) {
          alert("wrong country or zip code?");
          setValue(1); // return to zip code input
        }

        setCoordinates(response.data);
        setLoaded(true);
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    }
    getCoordinates();
  }, [value]);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching the IP address", error);
      }
    };
    fetchIp();
  }, []);

  async function getData() {
    console.log("bookingid", bookingid);
    try {
      const docSnap = await getDoc(doc(db, "BenchmarkTravelData", bookingid));
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setBenchmarkData(docSnap.data());
        return docSnap.data();
      } else {
        console.log("No such document!");
        setBenchmarkData({ lat: 18, lng: 59 });
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  }

  //   New storeData because of previous mishap. Gets the data from the previous benchmarkData and adds the new data to it.
  async function storeData() {
    try {
      const previousBenchmarkData = await getData();
      let timestamp = new Date();
      let tmp = [];
      let newData = { ...formData, ip: ip, ...coordinates, timestamp, countryCode: countryCode, zipCode: zipCode };
      console.log("newData", newData);
      console.log("prevoius", previousBenchmarkData);
      Object.values(previousBenchmarkData || {}).forEach((data) => {
        tmp.push(data);
      });
      tmp.push(newData);

      await setDoc(doc(db, "BenchmarkTravelData", bookingid), { ...tmp });
      console.log("Data successfully saved!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }

  return (
    <Grid container xs={12} sm={5} md={3} p={2} justifyContent="center" mt={"5vh"} style={{ height: "90vh", alignContent: "center" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px", width: "100%", alignContent: "center" }}>
        {value === 0 && (
          <div style={{}}>
            <h1 style={{ textAlign: "center", zoom: "1.3" }}>🚗 🚆 ✈️</h1>
            <h1 style={{ textAlign: "center", zoom: "1.3" }}>Travel survey</h1>
            <h1 style={{ textAlign: "center", zoom: "1.3" }}>
              <CircularProgress />
            </h1>
          </div>
        )}
        {value === 1 && (
          <>
            <h1 style={{ textAlign: "center", zoom: "1.3" }}>✏️</h1>
            <h4 style={{ textAlign: "center" }}>Ange det postnummer du rest ifrån</h4>
            {/* <div>{ip}</div>
            <div>{bookingid}</div> */}
            <TextField
              label="Enter Number"
              variant="outlined"
              type="number"
              value={zipCode.length <= 5 ? zipCode : zipCode.slice(0, 5)}
              inputProps={{
                maxLength: 5, // Allows only up to 10 characters to be entered
              }}
              label="Postnummer"
              variant="outlined"
              onChange={(e) => setZipCode(e.target.value.slice(0, 5))}
            />
            {/* <TextField type="number" value={zipCode} label="Postnummer" variant="outlined" onChange={(e) => setZipCode(e.target.value)} /> */}
            <SelectCountry countryCode={countryCode} setCountryCode={setCountryCode} />
            {zipCode.length === 5 && <div>{next()}</div>}
          </>
        )}
        {value === 2 && (
          <Grid item justifyContent="center" style={{ display: "flex", flexDirection: "column" }}>
            <div>{previous()}</div>

            {/* <PieChartCenteredLabel
              width={"300px"}
              height={"300px"}
              data={Object.keys(formData).map((key) => ({ value: formData[key], label: key, color: indexColor(key) }))}
              label={Math.round(100 - remaining) + " %"}
            /> */}
            <Grid item style={{ height: "200px", alignContent: "center" }}>
              {remaining === 100 && (
                <Grid item style={{ display: "flex", justifyContent: "center" }}>
                  <h4>Ange färdmedel procentuellt</h4>
                </Grid>
              )}
              {remaining < 100 && (
                <Grid item style={{ display: "flex", justifyContent: "center" }}>
                  <PieChartCenteredLabel
                    width={"200px"}
                    height={"200px"}
                    data={[
                      ...Object.keys(formData).map((key) => ({
                        value: formData[key],
                        label: key,
                        color: indexColor(key),
                      })),
                      // Add the static value here
                      { value: remaining, label: "", color: "transparent" },
                    ]}
                    label={Math.round(100 - remaining) + " %"}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item mt={-5}>
              {Object.keys(formData)
                .filter((key) => key !== "zipCode" && key !== "country")
                .map((key) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      height: "50px",
                      overflow: "hidden",
                      alignItems: "center", // Align items vertically
                    }}
                  >
                    <span>{keyIcon(key)}</span>
                    <span style={{ width: "100%", marginTop: "20px" }}>
                      <Slider
                        {...sliderSettings}
                        value={formData[key]}
                        onChange={handleSliderChange(key)}
                        style={{ height: "20px", width: "100%" }}
                      />
                    </span>
                    <span>
                      <h4>{Math.round(formData[key])}</h4>
                    </span>
                  </div>
                ))}
            </Grid>
            <Grid item mt={2}>
              {!loaded && (
                <h4 style={{ textAlign: "center", color: "#00ffd444" }}>
                  fetching distance
                  <LoadingDots speed={300} />
                </h4>
              )}
              {remaining === 0 && <div>{send()}</div>}
            </Grid>
          </Grid>
        )}
        {value === 3 && (
          <Grid xs={12} p={4} justifyContent="center">
            <h1 style={{ textAlign: "center", zoom: "2" }}>🌿</h1>
            <h1 style={{ textAlign: "center" }}>Thank you for submitting your travel info!</h1>
          </Grid>
        )}
      </div>
    </Grid>
  );
};

export default EventTravelForm;

// const testData = [
//   {
//     Train: 0,
//     countryCode: "SE",
//     ip: "94.234.99.243",
//     Walk: 0,
//     zipCode: "11666",
//     lat: 59.3079362,
//     timestamp: {
//       seconds: 1717878847,
//       nanoseconds: 790000000,
//     },
//     Car: 100,
//     Bus: 0,
//     Bike: 0,
//     Plane: 0,
//     lng: 18.0858772,
//   },
//   {
//     Walk: 0,
//     lng: 13.2118909,
//     timestamp: {
//       seconds: 1717879122,
//       nanoseconds: 437000000,
//     },
//     countryCode: "SE",
//     ip: "94.234.99.157",
//     zipCode: "22644",
//     Plane: 0,
//     lat: 55.7214243,
//     Bike: 0,
//     Bus: 0,
//     Car: 0,
//     Train: 100,
//   },
//   {
//     timestamp: {
//       seconds: 1717879326,
//       nanoseconds: 724000000,
//     },
//     zipCode: "13248",
//     Bike: 0,
//     ip: "94.234.99.157",
//     Walk: 0,
//     Plane: 0,
//     lat: 59.3263828,
//     Bus: 0,
//     lng: 18.2240432,
//     Car: 100,
//     Train: 0,
//     countryCode: "SE",
//   },
//   {
//     lng: 18.2052198,
//     lat: 59.2978365,
//     Car: 100,
//     zipCode: "13150",
//     ip: "94.234.99.157",
//     Train: 0,
//     countryCode: "SE",
//     Plane: 0,
//     timestamp: {
//       seconds: 1717879339,
//       nanoseconds: 127000000,
//     },
//     Bus: 0,
//     Bike: 0,
//     Walk: 0,
//   },
//   {
//     zipCode: "11336",
//     timestamp: {
//       seconds: 1717879416,
//       nanoseconds: 166000000,
//     },
//     Bike: 0,
//     lat: 59.3394061,
//     ip: "94.234.99.157",
//     lng: 18.0336313,
//     Plane: 0,
//     countryCode: "SE",
//     Walk: 0,
//     Bus: 0,
//     Train: 0,
//     Car: 100,
//   },
//   {
//     countryCode: "SE",
//     ip: "94.234.99.157",
//     lng: 18.0856104,
//     lat: 59.3369938,
//     Bus: 0,
//     Bike: 0,
//     Walk: 0,
//     zipCode: "11458",
//     Train: 0,
//     timestamp: {
//       seconds: 1717879444,
//       nanoseconds: 414000000,
//     },
//     Plane: 0,
//     Car: 100,
//   },
//   {
//     lng: -73.9976946,
//     zipCode: "10012",
//     Car: 0,
//     ip: "94.234.99.157",
//     countryCode: "SE",
//     Bus: 0,
//     timestamp: {
//       seconds: 1717879475,
//       nanoseconds: 181000000,
//     },
//     lat: 40.7250632,
//     Bike: 0,
//     Walk: 0,
//     Plane: 100,
//     Train: 0,
//   },
//   {
//     Walk: 0,
//     lng: 13.0131285,
//     zipCode: "50733",
//     ip: "94.234.99.157",
//     Train: 0,
//     Car: 0,
//     Bus: 0,
//     Plane: 100,
//     Bike: 0,
//     lat: 57.73253159999999,
//     timestamp: {
//       seconds: 1717879511,
//       nanoseconds: 834000000,
//     },
//     countryCode: "SE",
//   },
//   {
//     Car: 0,
//     lng: 8.726027499999999,
//     Bus: 0,
//     Plane: 0,
//     zipCode: "60389",
//     Bike: 0,
//     countryCode: "SE",
//     lat: 50.1495363,
//     timestamp: {
//       seconds: 1717879540,
//       nanoseconds: 573000000,
//     },
//     Train: 100,
//     ip: "94.234.99.157",
//     Walk: 0,
//   },
//   {
//     lat: 59.35304730000001,
//     Train: 0,
//     countryCode: "SE",
//     zipCode: "11543",
//     lng: 18.0956333,
//     Car: 100,
//     timestamp: {
//       seconds: 1717879593,
//       nanoseconds: 592000000,
//     },
//     Plane: 0,
//     Walk: 0,
//     Bus: 0,
//     Bike: 0,
//     ip: "94.234.99.157",
//   },
//   {
//     Train: 0,
//     Plane: 0,
//     Walk: 100,
//     ip: "94.234.99.157",
//     timestamp: {
//       seconds: 1717879638,
//       nanoseconds: 505000000,
//     },
//     lng: -0.3518548,
//     Car: 0,
//     Bike: 0,
//     Bus: 0,
//     countryCode: "SE",
//     zipCode: "14123",
//     lat: 49.1468895,
//   },
//   {
//     timestamp: {
//       seconds: 1717879669,
//       nanoseconds: 576000000,
//     },
//     Bus: 0,
//     zipCode: "12056",
//     Walk: 0,
//     Plane: 0,
//     Train: 100,
//     countryCode: "SE",
//     lng: 18.0506254,
//     lat: 59.2925275,
//     Car: 0,
//     ip: "94.234.99.157",
//     Bike: 0,
//   },
//   {
//     countryCode: "SE",
//     Bike: 0,
//     Bus: 0,
//     lat: 59.36121430000001,
//     Train: 100,
//     Car: 0,
//     timestamp: {
//       seconds: 1717879680,
//       nanoseconds: 861000000,
//     },
//     Plane: 0,
//     zipCode: "16931",
//     ip: "94.234.99.157",
//     Walk: 0,
//     lng: 17.9889637,
//   },
//   {
//     timestamp: {
//       seconds: 1717888801,
//       nanoseconds: 774000000,
//     },
//     Bus: 0,
//     zipCode: "12151",
//     lat: 59.28957299999999,
//     ip: "217.213.118.242",
//     Plane: 0,
//     countryCode: "SE",
//     Bike: 0,
//     lng: 18.1127486,
//     Train: 0,
//     Walk: 0,
//     Car: 100,
//   },
//   {
//     Plane: 0,
//     Car: 0,
//     Bus: 95,
//     Train: 0,
//     Bike: 0,
//     Walk: 5,
//     ip: "83.185.40.228",
//     zipCode: "81633",
//     lng: -108.4038672,
//     countryCode: "SE",
//     lat: 40.3305891,
//     timestamp: "2024-06-09T12:45:10.458Z",
//   },
// ];
