/** @format */

import { Button, CircularProgress, FormControlLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { setYear } from "date-fns";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import ConfirmDialog from "../../helpers/ConfirmDialog";

const DataSheetPremises = () => {
  const userRole = useSelector((state) => state.counter.userRole);
  const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  const keys = Object.keys(data[0]);
  const [currentData, setCurrentData] = useState([]);
  const [previousYearData, setPreviousYearData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storeOnType, setStoreOnType] = useState(false);
  const [unlockPreviousYears, setUnlockPreviousYears] = useState(false);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  async function storeData() {
    const filePath = `datasheet/premises/${year}`;
    await setDoc(doc(db, "ClimateData", filePath), { ...currentData });
  }

  // State to manage the form data and data list
  const [formData, setFormData] = useState();
  const [dataList, setDataList] = useState([]);

  // Handle form field changes and update formData
  const handleChange = (index, name, value) => {
    let tmp = JSON.parse(JSON.stringify(currentData));
    // let tmp = { ...currentData };
    tmp[index][name] = value;
    tmp[index]["DateUpdated"] = currentYear; // set year updated
    console.log(tmp);
    console.log(name, value);
    setCurrentData(Object.values(tmp));
  };

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const path = `datasheet/premises/${year}`;
      const docSnap = await getDoc(doc(db, "ClimateData", path));

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setCurrentData(Object.values(docSnap.data()));
        setLoading(false);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    getData();

    async function getPreviousYearData() {
      setStoreOnType(false);
      setLoading(true);
      const path = `datasheet/premises/${year - 1}`;
      const docSnap = await getDoc(doc(db, "ClimateData", path));

      if (docSnap.exists()) {
        console.log("Previous year:", docSnap.data());
        setPreviousYearData(Object.values(docSnap.data()));
        setLoading(false);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    getPreviousYearData();
  }, [year]);

  useEffect(() => {
    storeOnType && storeData();
  }, [currentData]);

  return (
    <div>
      <h7>DataSheet Premises</h7>
      {loading && <CircularProgress />}
      <Select value={year} onChange={(e) => setYear(e.target.value)} style={{ color: "transparent" }}>
        {years.map((y) => (
          <MenuItem value={y}>
            <p style={{ color: "white" }}>{y}</p>
          </MenuItem>
        ))}
      </Select>
      <FormControlLabel
        style={{ marginLeft: "20px" }}
        control={<Switch checked={storeOnType} onChange={() => setStoreOnType(!storeOnType)} />}
        label={<span style={{ color: `${storeOnType ? "orange" : "#fff"}` }}>Store on type</span>}
      />
      <Button onClick={() => storeData()} variant="outlined" style={{ marginLeft: "20px" }}>
        Store
      </Button>
      {userRole === "admin" && (
        <FormControlLabel
          style={{ marginLeft: "20px" }}
          control={<Switch checked={unlockPreviousYears} onChange={() => setUnlockPreviousYears(!unlockPreviousYears)} />}
          label={<span style={{ color: `${unlockPreviousYears ? "red" : "#fff"}` }}>Unlock previous data (unsafe)</span>}
        />
      )}
      {currentData && (
        <div className="climateDataSheet">
          <table>
            {/* TABLE HEAD */}
            <thead>
              <th>Index</th>
              {keys.map((key) => (
                <th>{key}</th>
              ))}
            </thead>
            {/* TABLE DATA */}
            <tbody>
              {currentData &&
                currentData.map((row, index) => (
                  <tr>
                    <td>{index}</td>
                    {keys.map((item) => (
                      <td>
                        {/* not writable... */}
                        {item === "EmissionArea" ||
                        item === "Category" ||
                        item === "Specification" ||
                        item === "Country" ||
                        item === "Unit" ||
                        item === "DateUpdated" ||
                        item === "Link" ? (
                          <>{row[item] !== null ? row[item] : ""}</>
                        ) : (
                          // ...and writable
                          <>
                            {item === "Comment" || item === "CommentOnSource" ? (
                              <textarea // Use html input instead of mui TextField due to poor performance
                                placeholder={previousYearData[index][item]} // Show previous years value
                                value={row[item] !== null ? row[item] : ""}
                                rows="2"
                                // value={currentData[index].item}
                                size="small"
                                onChange={(e) => handleChange(index, item, e.target.value)}
                                disabled={year < currentYear && !unlockPreviousYears}
                              />
                            ) : (
                              <input // Use html input instead of mui TextField due to poor performance
                                placeholder={previousYearData[index][item]} // Show previous years value
                                value={row[item] !== null ? row[item] : ""}
                                // value={currentData[index].item}
                                size="small"
                                onChange={(e) => handleChange(index, item, e.target.value)}
                                disabled={year < currentYear && !unlockPreviousYears}
                              />
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DataSheetPremises;

const data = [
  {
    EmissionArea: "Premises",
    Category: "Purchased electrcity",
    Specification: "Residual mix",
    Country: "Sweden",
    Comment: "",
    Scope1Value: null,
    Scope2Value: "39,0",
    Scope3Value: "",
    TotalValue: "38,95",
    Unit: "CO2/Kwh",
    DateUpdated: 2023,
    Source: "AIB, 2022",
    Link: "",
    CommentOnSource: "",
  },
  {
    EmissionArea: "Premises",
    Category: "Purchased electrcity",
    Specification: "Residual mix",
    Country: "Denmark",
    Comment: "",
    Scope1Value: null,
    Scope2Value: "557,4",
    Scope3Value: "",
    TotalValue: "557,4",
    Unit: "CO2/Kwh",
    DateUpdated: 2023,
    Source: "AIB, 2022",
    Link: "",
    CommentOnSource: "",
  },
];
