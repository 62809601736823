/** @format */

import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const Payment = () => {
  const [iframeURL, setIframeURL] = useState();
  const urlParams = new URLSearchParams(window.location.search);

  // get paymentId and clientId from url...
  let paymentId = urlParams.get("paymentId");
  let clientId = urlParams.get("clientId");
  let venue = urlParams.get("venue");
  let date = urlParams.get("date");
  let redirectUri = "http://localhost:3000/Owner/Home";

  const region = "SE";

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //...then show iframe
  useEffect(() => {
    if (paymentId && clientId) {
      setOpen(true);
      setIframeURL(
        `https://flows.finshark.io?type=payments&clientId=${clientId}&redirectUri=${redirectUri}&region=${region}&paymentId=${paymentId}`
      );
    }
  }, [paymentId && clientId]);

  const style = {
    position: "absolute",
    top: "25%",
    left: "10%",
    transform: "translate(-10%, -10%)",
    width: "80%",
    height: "80%",
    // bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    p: 4,
  };

  return (
    <Grid container p={1} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
          <h2>Settle payment</h2>
          <h4>
            {venue} - {date}
          </h4>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" mt={4}>
          <iframe src={iframeURL} height="700px" width="100%"></iframe>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Payment;
