/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { storeCurrentArtist, storePrefDays } from "../features/venueFilter/venueFilterSlice";
import { listenAllBookings } from "./Listeners";
import { current } from "@reduxjs/toolkit";
import { Avatar, Button, Grid } from "@mui/material";
import { ka } from "date-fns/locale";
import { Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";

export default function SelectArtist() {
  const [age, setAge] = React.useState("");
  const artists = useSelector((state) => state.counter.artists);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const prefDays = useSelector((state) => state.counter.prefDays);

  const dispatch = useDispatch();
  const handleChange = async (event) => {
    await dispatch(storeCurrentArtist(artists[artists.findIndex((x) => x.id === event.target.value)]));
    setArtistName(event.target.value.artist);
    // dispatch(storePrefDays("Monday"));
    // setPrefDays();
  };
  const [artistName, setArtistName] = useState("Ronny");
  const defaultValue = "6JGWlhwoGsg1yX0XSr3j";

  //Set Mando Diao by default
  useEffect(() => {
    setTimeout(() => {
      artists.length > 50 && dispatch(storeCurrentArtist(artists[5]));
      artists.length > 50 && setArtistName(artists[5].artist);
    }, 4000);
  }, [artists]);

  // Set prefDays in Redux
  // const setPrefDays = () => {};
  // useEffect(() => {
  //   currentArtist.prefDays && dispatch(storePrefDays(currentArtist.prefDays));
  // }, [setPrefDays]);

  return (
    <div>
      <FormControl fullWidth>
        <Select
          onChange={handleChange}
          fullWidth={false}
          size="small"
          sx={{ height: "30px", color: "transparent" }}
          defaultValue={defaultValue}
        >
          {artists.map((a) => (
            <MenuItem value={a.artistid} name={a.artist}>
              <span style={{ float: "left", lineHeight: "40px" }}>
                <Avatar
                  alt="Remy Sharp"
                  src={a.image}
                  sx={{
                    marginRight: "15px",
                    float: "left",
                    border: "1px solid white",
                  }}
                />

                <span style={{ color: "white" }}>{a.artist}</span>
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
