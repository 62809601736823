/** @format */

import React, { useEffect, useState } from "react";
import { SingleBookingContainer } from "./SingleBookingContainer";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ArtistInfoModal } from "../Modals/ArtistInfoModal";
import { Portal } from "react-portal";
import { GeneralModal } from "../Modals/GeneralModal";

export const BookingsByMonthContainer = (props) => {
  const [showArtistInfo, setShowArtistInfo] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const currentLoadingBookingsProgress = useSelector((state) => state.counter.currentLoadingBookingsProgress);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const onClickHandler = (artist) => {
    setShowArtistInfo(true);
    setSelectedArtist(artist);
  };

  useEffect(() => {
    console.log("currentBookings", currentBookings);
  }, [currentBookings]);

  return (
    <>
      {/* <ArtistInfoModal
        setOpen={() => {
          setShowArtistInfo(true);
        }}
        open={showArtistInfo}
        onClose={() => setShowArtistInfo(false)}
        selectedArtist={selectedArtist}
        selectedEvent={selectedEvent}
      /> */}
      <Grid container>
        {/* {console.log("currentBookings", currentBookings)} */}
        {currentBookings.map((booking, i) => {
          if (!booking.bookingid) {
            return;
          }
          if (booking.status === "Confirmed" || booking.status === "Awaiting Artist" || booking.status === "Pending Request") {
            return (
              <Grid
                container
                xs={12}
                sm={4.5}
                md={3.5}
                lg={2.5}
                key={booking.bookingid}
                sx={{
                  marginLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                {booking.product === "Concert3" && alert("booking 54", booking)}
                <SingleBookingContainer
                  event={booking}
                  action={() => {
                    setShowArtistInfo(true);
                  }}
                  setSelectedArtist={setSelectedArtist}
                  setSelectedEvent={setSelectedEvent}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </>
  );
};
