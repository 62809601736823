/** @format */

import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import DisplayObject from "../../helpers/displayObject";
import { numberFormatted } from "../../helpers/Numbers";
import LoadingDots from "../UI helpers/LoadingDots";
import PieChartCenteredLabel from "../UI helpers/PieChartCenteredLabel";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useSelector } from "react-redux";

const VisualDataSummaryBooking = ({ venueid }) => {
  const [formData, setFormData] = useState({ year: 2023 }); // Default year is 2023
  const year = formData.year;

  const currentVenue = useSelector((state) => state.counter.currentVenue);

  const [numberOfEvents, setNumberOfEvents] = useState(200); // Default number of events is 200 per year

  useEffect(() => {
    async function getData(venueid) {
      // Check if venueid and year are valid before proceeding
      if (venueid && formData.year) {
        // Create a valid Firestore document path by concatenating venueid and year

        const documentPath = `venues/${venueid}/${year ? year : formData.year}`;
        const docSnap = await getDoc(doc(db, "ClimateData", documentPath));

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());

          setFormData({ ...docSnap.data(), venueid: venueid });
        } else {
          setFormData((prev) => ({ venueid: venueid, year: year }));
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    }
    getData(venueid);
  }, [venueid]);

  //   useEffect(() => {
  //     async function getExistingBookings() {
  //       let tmp = [];
  //       console.log("ticketVenueId", currentVenue.ticketVenueId);

  //       const querySnapshot = await getDocs(
  //         currentVenue && query(collection(db, "Bookings"), where("ticketVenueId", "==", currentVenue.ticketVenueId))
  //       );
  //       querySnapshot.forEach((doc) => {
  //         tmp.push(doc.data());
  //       });
  //       //   filter tmp to all bookings that are within the current   //
  //       tmp = tmp.filter((booking) => {
  //         const bookingYear = new Date(booking.date).getFullYear();
  //         console.log("bookingYear", bookingYear, year + 1);
  //         return bookingYear === parseInt(year + 1); // +1 because the year is the year of the event, not the year of the emissiondata
  //       });

  //       console.log("tmp", tmp);
  //       setNumberOfEvents(tmp.length);
  //     }
  //     getExistingBookings();
  //   }, []);

  console.log("formData", formData);

  const wasteSumCO2e =
    formData.recycledMetalCO2e +
    formData.mixedHouseholdCO2e +
    formData.landfilledWasteCO2e +
    formData.recycledPlasticCO2e +
    formData.recycledPaperCO2e +
    formData.recycledElectricCO2e;

  const CO2eSum =
    formData.electricityUseCO2e +
    formData.heatingQuantityCO2e +
    formData.waterUseCO2e +
    wasteSumCO2e +
    formData.refrigerantQuantityCO2e +
    55 +
    340;

  const electricityFormatted = (
    <table>
      <thead>
        <th>Used</th>
        <th>Type</th>
        <th>On Site Produced</th>
      </thead>
      <td>{formData.electricityUse ? <span>{numberFormatted(formData.electricityUse / numberOfEvents)} kWh</span> : "-"}</td>
      <td>{formData.electricitySource && <span>{formData.electricitySource}</span>}</td>
      <td>{formData.onSiteProduced && <span>{numberFormatted(formData.onSiteProduced / numberOfEvents)} kWh</span>}</td>
    </table>
  );
  const heatingFormatted = (
    <table>
      <thead>
        <th>Type</th>
      </thead>
      <td>
        {formData.heatingUsed ? (
          <span>
            {formData.heatingUsed} {numberFormatted(formData.heatingQuantity / numberOfEvents)} {formData.heatingUnit}
          </span>
        ) : (
          "-"
        )}
      </td>
    </table>
  );
  const refrigerantsFormatted = (
    <table>
      <thead>
        <th>Used</th>
      </thead>
      <td>
        {formData.refrigerantType ? (
          <span>
            {formData.refrigerantType}: {numberFormatted(formData.refrigerantQuantity / numberOfEvents)} kg
          </span>
        ) : (
          "-"
        )}
      </td>
    </table>
  );
  const waterFormatted = (
    <table>
      <thead>
        <th>Used</th>
      </thead>
      <td>{formData.waterUse ? <span>{numberFormatted(formData.waterUse / numberOfEvents)} litres</span> : "-"}</td>
    </table>
  );
  const wasteFormatted = (
    <table>
      <thead>
        <th>Metal</th>
        <th>Plastic</th>
        <th>Paper</th>
        <th>Electronic</th>
        <th>Household</th>
        <th>Landfilled</th>
      </thead>
      <td>{formData.recycledMetal ? <span>{numberFormatted(formData.recycledMetal / numberOfEvents)} kg</span> : "-"}</td>
      <td>{formData.recycledPlastic ? <span>{numberFormatted(formData.recycledPlastic / numberOfEvents)} kg</span> : "-"}</td>
      <td>{formData.recycledPaper ? <span>{numberFormatted(formData.recycledPaper / numberOfEvents)} kg</span> : "-"}</td>
      <td>{formData.recycledElectric ? <span>{numberFormatted(formData.recycledElectric / numberOfEvents)} kg</span> : "-"}</td>
      <td>{formData.mixedHousehold ? <span>{numberFormatted(formData.mixedHousehold / numberOfEvents)} kg</span> : "-"}</td>
      <td>{formData.landfilledWaste ? <span>{numberFormatted(formData.landfilledWaste / numberOfEvents)} kg</span> : "-"}</td>
    </table>
  );
  if (formData === null)
    return (
      <div>
        No data yet
        <LoadingDots speed={400} />
      </div>
    );
  return (
    <div>
      {/* <h7>Summary report</h7> */}
      {/* <div>
        <PieChartCenteredLabel
          width={"300px"}
          height={"300px"}
          data={[
            { value: formData.electricityUseCO2e ? formData.electricityUseCO2e : "0", label: "Electricity", color: "#00ffd499" },
            { value: formData.heatingQuantityCO2e, label: "Heating", color: "#00ffd433" },
            { value: formData.waterUseCO2e, label: "Water", color: "#ffa50044" },
            { value: wasteSumCO2e, label: "Waste", color: "#ffa50099" },
            { value: formData.refrigerantQuantityCO2e, label: "Refrigerant", color: "#00ffd4" },
            { value: 55, label: "Transport", color: "#ffa500" },
            { value: 340, label: "Travel", color: "#ffa522" },
          ]}
          label={CO2eSum ? `${numberFormatted(Math.floor(CO2eSum))} kg` : "Emissions"}
        />
      </div> */}
      <table className="sustainabilityReport">
        <td>Electricity</td>
        <td>{electricityFormatted}</td>
        <tr>
          <td>Heating</td>
          <td>{heatingFormatted}</td>
        </tr>
        <tr>
          <td>Refrigerants</td>
          <td>{refrigerantsFormatted}</td>
        </tr>
        <tr>
          <td>Water</td>
          <td>{waterFormatted}</td>
        </tr>
        <tr>
          <td>Waste</td>
          <td>{wasteFormatted}</td>
        </tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </table>
      <div style={{ fontSize: "12px", opacity: "0.4", marginTop: "5px" }}>
        ** Data exclusively sourced from the Greenhouse Gas Protocol (GHG Protocol) and the UK Department for Business, Energy & Industrial
        Strategy (BEIS).
      </div>
    </div>
  );
};

export default VisualDataSummaryBooking;
