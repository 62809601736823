/** @format */

import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import { Button, IconButton, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import GradingIcon from "@mui/icons-material/Grading";
import AgreementSign from "./AgreementSign";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import UserForm from "../Forms/UserForm";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { userObj } from "../../dbObjects.js/dbUser";
import GeneralForm from "../../helpers/GeneralForm";
import UserFormAgent from "../Forms/UserFormAgent";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { VolunteerActivismOutlined } from "@mui/icons-material";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const useStyles = {
  paper: {
    position: "absolute",
    width: "60%",
    height: "90%",
    backgroundColor: "yellow",
    border: "2px solid #000",
    boxShadow: "5px 5px 10px black",
    padding: "10px",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const AgreementModal = (props) => {
  let keys = [];
  let values = [];
  const [open, setOpen] = useState(props.open);
  const [pdf, setPdf] = useState();
  const [bookingData, setBookingData] = useState();
  const [userData, setUserData] = useState();
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const currentArtistBookings = useSelector((state) => state.counter.currentArtistBookings);
  const currentUser = useSelector((state) => state.counter.currentUser);

  const [statusColor, setStatusColor] = useState();
  let bookings = [];

  const handleOpen = () => {
    setTimeout(() => {
      setOpen(true);
    }, 200);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSocialId = (e) => {
    alert(e.target.value);
  };

  var docPDF = new jsPDF();

  // Store pdf to storage folder "/agreements"
  const storage = getStorage();
  async function uploadPdfToStorage(docPDF, bookingid) {
    const storageRef = ref(storage, `agreements/${bookingid}.pdf`);
    await uploadBytes(storageRef, docPDF);
    console.log("PDF uploaded successfully.");

    // Get the download URL
    getDownloadURL(storageRef).then(async (url) => {
      console.log("url:", url);

      // Store URL (agreement) to Booking
      // try {
      //   await updateDoc(doc(db, "Bookings", bookingData && bookingData.bookingid), {
      //     agreement: url,
      //   });
      //   console.log("Document updated successfully.");
      // } catch (error) {
      //   console.error("Error updating document:", error);
      // }
    });
  }

  useEffect(() => {
    uploadPdfToStorage(docPDF, bookingData && bookingData.bookingid);
  }, [docPDF]);

  //update booking with agreementURL
  // async function updateBookingAgreement(downloadURL) {
  //   const bookingDocPDFRef = doc(db, "Bookings", bookingData && bookingData.bookingid);
  //   try {
  //     await updateDoc(bookingDocRef, {
  //       agreement: downloadURL,
  //     });
  //     console.log("Document updated successfully.");
  //   } catch (error) {
  //     console.error("Error updating document:", error);
  //   }
  // }

  function savePDF() {
    docPDF.html(document.querySelector("#agreement"), {
      callback: function (docPDF) {
        var img = new Image();
        img.src =
          "https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FVenuehub_Blackonwhite.png?alt=media&token=f6fe81d1-74f8-424f-8f48-1f4ddf89a943";

        img.onload = function () {
          docPDF.addImage(this, 10, 10);
        };
        docPDF.save();
      },
    });
  }

  function isWeekend(d) {
    var date = new Date(d);
    return date.getDay() === 5 || date.getDay() === 6 || date.getDay() === 0;
  }

  console.log(currentUser);
  console.log("Booking Data", bookingData);
  console.log("current bookings", currentBookings);
  console.log("current Artist bookings", currentArtistBookings);

  //if on venuepage bookings are stored in currentBookings. While if on agentpage they're instead stored in currentArtistBookings
  useEffect(() => {
    currentBookings.map((tmp) => {
      if (tmp.bookingid === props.bookingid) {
        setBookingData(tmp);
        // keys = Object.keys(tmp);
        // values = Object.values(tmp);
        console.log("bData", tmp);
        setStatusColor(
          "#aaa"
          // tmp.status == "Confirmed"
          //   ? "green"
          //   : tmp.status == "Pending Request"
          //   ? "red"
          //   : tmp.status == "Pending"
          //   ? "red"
          //   : tmp.status == "Awaiting Artist"
          //   ? "orange"
          //   : tmp.status == "Killed"
          //   ? "gray"
          //   : tmp.status == "Declined"
          //   ? "purple"
          //   : "purple"
        );
      }
    });
  }, [currentBookings]);

  //   useEffect(() => {
  //     const doc = new jsPDF();
  //     const agreementDiv = document.querySelector("#agreement");
  //     doc.html(agreementDiv);
  //     setPdf(doc);
  //   }, []);

  //   const classes = useStyles;
  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(10px)",
  };

  useEffect(() => {
    props.open === true && handleOpen();
    props.open === false && handleClose();
  }, [props.open]);

  if (bookingData)
    return (
      <div>
        <div variant="contained" onClick={handleOpen}>
          {/* show correct icon depending on status */}
          {bookingData.status === "Confirmed" && <GradingIcon className="agreementIcon" size={25} style={{ color: "#00ffd499" }} />}
          {bookingData.status === "Pending Request" && (
            <PendingActionsIcon className="agreementIcon" size={25} style={{ color: "#00ffd4" }} />
          )}
          {bookingData.status === "Awaiting Artist" && (
            <DriveFileRenameOutlineIcon className="agreementIcon" size={25} style={{ color: "#00ffd4" }} />
          )}
        </div>
        {console.log("bookingdata:", bookingData)}
        <Modal open={open} onClose={handleClose}>
          <Box sx={boxStyle}>
            <div>
              <div style={{ zoom: "4" }}>
                <div id="agreement" style={{ color: "black", background: "white", padding: "20px", fontSize: "5pt", width: "210px" }}>
                  {/* <img src="agreement.svg" style="width: 100%; max-width: 200px;" /> */}
                  <h2 style={{ fontSize: "4pt" }}>
                    <img
                      width="30px"
                      height="auto"
                      style={{ marginLeft: "-1px" }}
                      src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FVenuehub_Blackonwhite.png?alt=media&token=f6fe81d1-74f8-424f-8f48-1f4ddf89a943"
                    />
                    <br></br>
                    <b>Rental Agreement</b>
                  </h2>
                  <table style={{ fontSize: "2.5pt" }}>
                    This Agreement is made on <strong>{bookingData.venueAcceptedDate}</strong> between{" "}
                    <strong>{bookingData.venue.venue}</strong> (hereinafter referred to as "Venue") and{" "}
                    <strong>
                      {bookingData.agent.firstName} {bookingData.agent.lastName}
                    </strong>
                    {"  "}
                    (hereinafter referred to as "Customer"). Venue leases to Customer and Customer rents from Venue a certain residential
                    unit located at{" "}
                    <strong>
                      {bookingData.venue.streetAddress}, {bookingData.venue.zipCode} {bookingData.venue.city}{" "}
                    </strong>
                    (hereinafter referred to as "Premises"). The Premises shall be used as agreed upon. Customer shall pay to Venue a fee of{" "}
                    <strong>{bookingData.price}</strong> payable on the <strong>{bookingData.date}</strong>.
                  </table>
                  <br></br>
                  <table style={{ fontSize: "2.5pt", padding: "50pt" }}>
                    <tr>
                      <b>Venue: </b> {bookingData.venue.venue}
                    </tr>
                    <tr>
                      {bookingData.venue.streetAddress}, {bookingData.venue.zipCode} {bookingData.venue.city}
                    </tr>
                    <br></br>
                    <tr>
                      <b>Customer: </b> {bookingData.agent.firstName} {bookingData.agent.lastName}
                    </tr>
                    <tr>
                      {bookingData.agent.streetAddress}, {bookingData.agent.zipCode} {bookingData.agent.city}
                    </tr>
                    <tr>
                      <b>Email: </b> {bookingData.agent.email}
                    </tr>
                    <tr>
                      <b>Phone: </b> {bookingData.agent.phone}
                    </tr>
                    <tr>
                      <b>Corporate ID: </b> {bookingData.agent.orgNumber}
                    </tr>
                    <tr>
                      <b>Artist: </b> {bookingData.artist.artist}
                    </tr>
                    <tr>
                      <br></br>
                      <b>Fee: </b> {bookingData.price} kr
                    </tr>
                    <tr>
                      <br></br>
                      {/* <b>Load in: </b> {isWeekend(bookingData.date) ? <>{bookingData.loadInWeekends}</> : bookingData.loadInWeekdays} */}
                      <b>Load in: </b> {bookingData.loadIn}
                      <br></br>
                      <b>Soundcheck: </b>
                      {/* {isWeekend(bookingData.date) ? <>{bookingData.soundcheckWeekends}</> : bookingData.soundcheckWeekdays} */}
                      {bookingData.soundcheck}
                      <br></br>
                      <b>Doors: </b> {bookingData.doors}
                      <br></br>
                      <b>Show: </b> {bookingData.show}
                      <br></br>
                      <b>Curfew: </b>
                      {/* {isWeekend(bookingData.date) ? bookingData.curfewWeekends : bookingData.curfewWeekdays} */}
                      {bookingData.curfew}
                    </tr>
                    <tr>
                      <br></br>
                      <b>Agreement Reference: </b>
                      <span>{bookingData.bookingid}</span>
                      <br></br>
                      <b>Created: </b> {bookingData.created && bookingData.created}
                      <br></br>
                      <b>Date of Agreement: </b> {bookingData.venueSignature && bookingData.venueSignature.date}
                      <br></br>
                      <b>Date of Signature: </b> {bookingData.agentSignature && bookingData.agentSignature.date}
                    </tr>
                  </table>
                  {/* Signatures */}
                  <table style={{ fontSize: "2.5pt" }}>
                    <tr height="20pt" width="100pt"></tr>
                    {bookingData.venueSignature && (
                      <span style={{ margin: "2pt", color: "blue" }}>
                        Signed by {bookingData.venueSignature.userId} at {bookingData.venueSignature.date}
                      </span>
                    )}
                    <tr style={{ borderTop: "0.1pt solid black", marginTop: "2pt" }}>Venue: {bookingData.venue.venue}</tr>
                    <tr height="20pt" width="100pt"></tr>
                    {bookingData.agentSignature && (
                      <span style={{ margin: "2pt", color: "blue" }}>
                        Signed by {bookingData.agentSignature.userId} at {bookingData.agentSignature.date}
                      </span>
                    )}
                    <tr style={{ borderTop: "0.1pt solid black" }}>
                      Customer: {bookingData.agent.firstName}
                      {bookingData.agent.lastName}
                    </tr>
                  </table>
                </div>
              </div>
              <div style={{}}>
                <IconButton style={{ marginTop: "20px" }} variant="outlined" onClick={() => savePDF()}>
                  <DownloadIcon />
                </IconButton>
                {bookingData.status !== "Confirmed" && (
                  <AgreementSign
                    bookingid={bookingData.bookingid}
                    sign={props.sign}
                    socialId={currentUser.socialId}
                    phone={currentUser.phone}
                    reciever={bookingData.agent.email}
                    bookingData={bookingData}
                  />
                )}

                {/* {!currentUser.socialId && (
                  <>
                    <div>Complete your user information below in order to sign agreements.</div>
                    <GeneralForm
                      form={userObj}
                      formName="userForm"
                      priority={10000}
                      setUserData={setUserData}
                      fields={["firstName", "lastName", "socialId", "phone", "update"]}
                    />
                  </>
                )} */}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
};

export default AgreementModal;
