/** @format */

import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { getSettlementChargeBody } from "../../finshark/finsharkSettlementChargeBody";
import { createMidId, db } from "../../firebase";
import store from "../../app/store";

export async function createPaymentPostForSettlement(type, amount, agreementId, email) {
  const referenceId = await `set${createMidId()}`;
  console.log("referenceId", referenceId);
  let agreement = {};
  let bookings = [];
  const today = new Date();
  amount < 0 ? (amount = amount * -1) : (amount = amount);

  await getAgreement();
  await getBookingsByAgreementId();

  let ticketEventIds = bookings.map((booking) => booking.ticketEventId);
  console.log("ticketEventIds", ticketEventIds);
  let ticketVenueId = bookings[0].ticketVenueId;
  console.log("ticketVenueId", ticketVenueId);

  await storePaymentPost();

  async function getAgreement() {
    if (!agreementId) return;
    const docSnap = await getDoc(doc(db, "Agreements", agreementId));
    agreement = docSnap.data();
    console.log("agreement", agreement);
    // return docSnap.data();
  }

  async function getBookingsByAgreementId() {
    if (bookings.length > 0) return; // Only get bookings once
    let tmp = [];
    const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", agreementId)));
    querySnapshot.forEach((doc) => {
      tmp.push({ id: doc.id, ...doc.data() });
    });
    bookings = tmp;
    console.log("bookings", bookings);
  }

  async function storePaymentPost() {
    const newPaymentPost = {
      OCR: agreement.OCR,
      artist: agreement.artist.artist,
      email: email,
      originalAmount: amount,
      amount: amount,
      due: today.toISOString().split("T")[0],
      nextDateToCheck: today.toISOString().split("T")[0],
      bookingids: agreement.bookingids,
      status: "IDLE",
      paymentBody: getSettlementChargeBody(amount, agreement.OCR),
      reference: referenceId,
      venue: agreement.venue,
      vat: 0,
      type: type,
      agreementId: agreementId,
      ticketEventIds: ticketEventIds,
      ticketVenueId: ticketVenueId,
    };

    console.log("newPaymentPost", newPaymentPost);

    console.log("Storing item", newPaymentPost);
    await setDoc(doc(db, "PaymentPosts", referenceId), {
      ...newPaymentPost,
    });
  }

  return null;
}
