import React, { useEffect, useState } from "react";
import GetBookings from './GetBookings'
import GetBookingsForCurrentVenue from './GetBookingsForCurrentVenue'
import { storeBookings, storeCapMax } from '../features/venueFilter/venueFilterSlice';
import { useSelector, useDispatch } from 'react-redux';
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { storeCurrentVenue } from "../features/venueFilter/venueFilterSlice";

const ReloadBookings = () => {
  /*
    const [ tempBookings, setTempBookings ] = useState([]);
    const dispatch = useDispatch()
    const bookingCollectionRef = collection(db, "Bookings");
    const currentVenue = useSelector((state) => state.counter.currentVenue);
    const [ tempVenue, setTempVenue ] = useState(currentVenue);
    const bookings = useSelector((state) => state.counter.bookings);
    const reloadBookings = useSelector((state) => state.counter.reloadBookings);


    //Get bookings
    useEffect( () => {
        const getData = async () => {
        const data = await getDocs(bookingCollectionRef);
        dispatch(storeBookings(await data.docs.map((doc) => (doc.data()))))
      };
         getData();
    }, [])


    // Get bookings for chosen venue and add to tempVenue before dispatch below
    useEffect(() => {
        const getData = async () => {
        const q = await query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
        const data = await getDocs(q);
        await setTempVenue({bookings:data.docs.map((doc) => (doc.data())), ...currentVenue})
        };
        // getData();
        currentVenue.venueid != null ? getData() : console.log('no venueID found!')
        }, [])
      // }, [currentVenue.venueid])


    //...and then dispatch
    useEffect(()=> {
        let temp = JSON.parse(JSON.stringify(tempVenue))
        dispatch(storeCurrentVenue(temp))
		    // dispatch(storeReloadBookings(false))
    }, []) 
  // }, [tempVenue]) 


  return (
    <div>
        {currentVenue.venueid == null ? <div>No venueID Found!</div> : <>{currentVenue.venue}</>}

    </div>
  )*/
}

export default ReloadBookings

/*
export async function reloadCalendarBookings(){
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const bookingCollectionRef = collection(db, "Bookings");
  const dispatch = useDispatch();
  const [ tempCurrentVenue, setTempCurrentVenue ] = useState([]);
  // const data = await getDocs(bookingCollectionRef);
  let bookings = [];


  await chain();
  // useEffect(()=>{
  //   dispatch(storeCurrentVenue({...tempCurrentVenue}))
  // },[])
async function chain(){
  await getStuff();
  console.log('Now 3');
      // dispatch(storeCurrentVenue({...currentVenue, bookings}))

}

 function getStuff(){
  const q = query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
   querySnapshot.forEach((doc) => {
      bookings.push(doc.data()); 
      // bookings.push({venue:'Ullevi', venueid:'korven'});
      // console.log(doc.data());
      
      // dispatch(storeBookings(doc.data()))
      // dispatch(storeCurrentVenue({...currentVenue, bookings}))
       console.log('Now1');
  });
   console.log('Now 2');
   dispatch(storeCapMax({...currentVenue, bookings}));
      // dispatch(storeCurrentVenue({...currentVenue, bookings}))
  // dispatch(storeBookings( bookings.map((doc) => (doc.data()))))
});
}
// const q = query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
// const unsubscribe = onSnapshot(q, (querySnapshot) => {
//   querySnapshot.forEach((doc) => {
//       bookings.push(doc.data()); 
//       // bookings.push({venue:'Ullevi', venueid:'korven'});
//       // console.log(doc.data());
      
//       // dispatch(storeBookings(doc.data()))
//       // dispatch(storeCurrentVenue({...currentVenue, bookings}))
//        console.log('Now1');
//   });
//    console.log('Now 2');
//       // dispatch(storeCurrentVenue({...currentVenue, bookings}))
//   // dispatch(storeBookings( bookings.map((doc) => (doc.data()))))
// });
      // console.log('Now 3');
      // await dispatch(storeCurrentVenue({...currentVenue, bookings}))
  // setTempCurrentVenue({...currentVenue, bookings})
}
*/