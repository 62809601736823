/** @format */

import { Autocomplete, Box, Button, Card, Grid, Modal, Stack, TextField } from "@mui/material";
import { setWeekYearWithOptions } from "date-fns/fp";
import { db } from "../../firebase";
import { addDoc, collection, deleteDoc, doc, FieldValue, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
// import { storeBookingModalOpen } from "../features/venueFilter/venueFilterSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  storeBookingModalOpen,
  storeCurrentBookingId,
  storeCurrentEventlist,
  storeIsNewBooking,
  storeReloadBookings,
} from "../../features/venueFilter/venueFilterSlice";
import SearchArtist from "../../helpers/SearchArtist";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import VenueCalendar from "../Sidebar/VenueCalendar";
import id from "date-fns/locale/id";
import { v4 as uuid } from "uuid";
import GetBookings from "../../helpers/GetBookings";
import { Navigate, useNavigate } from "react-router-dom";
import GetBookingsForCurrentVenue from "../../helpers/GetBookingsForCurrentVenue";
import GetArtists from "../../helpers/GetArtists";
import ReloadBookings from "../../helpers/ReloadBookings";
import Chip from "@mui/material/Chip";
import { bookingsObj } from "../../dbObjects.js/dbBookings";
import NewBookingStepper from "./NewBookingStepper";
import { EditText, EditTextarea } from "react-edit-text";
// import ContractMaker from "../Documents/ContractMaker";
import AgreementModal from "../Documents/AgreementModal";

// var currentBooking = [];
// currentBooking['bookingid'] = uuid();
// console.log(currentBooking['bookingid'])

const BookingForm = (props) => {
  const style = {
    position: "absolute",
    top: "15%",
    left: "15%",
    right: "15%",
    maxHeight: "70%",
    width: "70%",
    // overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #333",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  let bookingFormArray = bookingsObj;
  // const currentBooking = useSelector((state) => state.counter.currentBooking);
  var currentBooking = [];
  const isNewBooking = useSelector((state) => state.counter.isNewBooking);
  const currentBookingId = useSelector((state) => state.counter.currentBookingId);
  const currentEventlist = useSelector((state) => state.counter.currentEventlist);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const bookings = useSelector((state) => state.counter.bookings);
  const selectedDate = useSelector((state) => state.counter.selectedDate);
  const artists = useSelector((state) => state.counter.artists);
  const reloadBookings = useSelector((state) => state.counter.reloadBookings);
  var currentSection = "";
  const handleOpen = () => {
    dispatch(storeBookingModalOpen(true));
  };
  const handleClose = () => {
    dispatch(storeBookingModalOpen(false));
    // currentBooking = [];
    dispatch(storeReloadBookings(true));
    setBookingData([null]);

    // setBookingData = [];
    // console.log('FUNKAR');
    // console.log(bookingData)
    // console.log(currentVenue)
    // navigate("/Venue/VenueCalendar");
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const bookingModalOpen = useSelector((state) => state.counter.bookingModalOpen);
  const handleNewSection = (newSection) => {
    currentSection = newSection;
    return (
      <Grid item xs={12} md={4} spacing={3}>
        <br />
        {newSection}
      </Grid>
    );
  };
  const searchableArtists = artists.map(({ artist: label, artistid: id, image: image }) => ({
    id,
    label,
    image,
  }));
  // setTimeout(() => {
  //   console.log(searchableArtists);
  // }, 2000);

  // const nuSearchableArtists = artists.map((artist) => artist.artist);
  // setTimeout(() => {
  //   console.log(nuSearchableArtists);
  // }, 2000);
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const handleOpenAgreementModal = () => {
    setAgreementModalOpen(true);
  };
  const handleCloseAgreementModal = () => {
    setAgreementModalOpen(false);
  };
  const [newBooking, setNewBooking] = useState([]);
  const [value, setValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [artist, setArtist] = useState();
  const [price, setPrice] = useState();
  const [currentFieldValue, setCurrentFieldValue] = useState([{ key: "key", value: "value" }]);
  const [bookingData, setBookingData] = useState([null]);
  const [formlock, setFormlock] = useState(false);
  // const [ reloadCalendar, setReloadCalendar] = useState(false);
  var bookingKeys = Object.keys(bookingData);
  var venueKeys = Object.keys(currentVenue);

  // isNewBooking ? (currentBooking = []) : (currentBooking = useSelector((state) => state.counter.currentBooking));

  // currentBooking.date = selectedDate;
  // currentBooking.bookingid = currentBookingId;

  // console.log(artists);

  function isWeekend(d) {
    var date = new Date(d);
    return date.getDay() === 5 || date.getDay() === 6 || date.getDay() === 0;
  }

  const handleChange = (id, value) => {
    console.log(bookingData);
    setTimeout(() => {
      console.log(id, value);
    }, 1000);
    // console.log(id, value);
    // console.log(currentBooking[id]);
    // currentBooking[id] = value
    setCurrentFieldValue({ key: id, value: value });
    // console.log(currentBooking);
    setBookingData((prev) => {
      if (id === "artist") return { ...prev, artist: value };
      if (id === "image") return { ...prev, artistImage: value };
      if (id === "status") return { ...prev, venueAcceptedDate: "2023-01-01" };
      return { ...prev, [id]: value };
    });

    // id === "artist" ? setArtist(value) : setArtist(null);
  };

  // setTimeout(() => {
  //   console.log(bookingData);
  //   console.log(currentBooking);
  // }, 2000);

  const saveBooking = async () => {
    bookingKeys.map((key) => {
      if (bookingData[key] == null) {
        bookingData[key] = "-";
      }
      // console.log(key, bookingData[key]);
    });
    // bookingData['date'] = selectedDate
    // console.log(bookingData);

    //change the date in curentVenue array quickly for snappyness
    // const index = currentVenue.bookings.map(object => object.bookingid).indexOf(currentBookingId)
    // var tempCurrentVenue = JSON.parse(JSON.stringify(currentVenue))
    // console.log(tempCurrentVenue.bookings[index])
    //THIS
    handleClose();
    await setDoc(doc(db, "Bookings", bookingData["bookingid"]), {
      ...bookingData,
    });
    // isNewBooking ? await addDoc(collection(db, "Bookings"), {...bookingData}) : await setDoc(doc(db, "Bookings", bookingData['bookingid']), { ...bookingData});
    // (await isNewBooking) ? console.log("New booking stored") : console.log("Old booking updated");
    // await console.log(bookingData);

    // dispatch(storeIsNewBooking(false))
    // setTimeout(() => {

    dispatch(storeReloadBookings(true));
    // }, 1000);
  };

  async function handleDelete() {
    // await deleteDoc(doc(db, "Bookings", currentBookingId));
    handleClose();
    await updateDoc(doc(db, "Bookings", currentBookingId), {
      status: "Killed",
    });

    // console.log("Killed " + currentBookingId);
    // console.log(currentVenue);
  }

  useEffect(() => {
    !isNewBooking && setCurrentBookingFromBookingId();
  }, [isNewBooking]);

  function checkIsNewBooking() {
    // console.log(isNewBooking)
    // isNewBooking ? console.log("NEW") : console.log("OLD");
    !isNewBooking && setCurrentBookingFromBookingId();
  }

  function setCurrentBookingFromBookingId() {
    // console.log("old booking");
    console.log(currentBookingId);
    const index = currentVenue.bookings.map((object) => object.bookingid).indexOf(currentBookingId);
    currentBooking = currentVenue.bookings[index];
    setBookingData(currentBooking);
    setTimeout(() => {
      // console.log(bookingData)
    }, 500);
  }

  // async function createNewBooking() {
  //   // console.log('new booking')
  //   // currentBooking = [];
  //   var newID = uuid();
  //   dispatch(storeCurrentBookingId(newID));
  //   currentBooking.bookingid = newID;
  //   // dispatch(storeIsNewBooking(false))
  //   // if (currentVenue) {
  //   await bookingFormArray.map((formfield) => {
  //     if (formfield.source === "Venues") currentBooking[formfield.id] = currentVenue[formfield.id];
  //     if (formfield.id === "price" && isWeekend(selectedDate)) currentBooking["price"] = currentVenue.priceWknd;
  //     else if (formfield.id === "price" && !isWeekend(selectedDate)) currentBooking["price"] = currentVenue.priceWkday;
  //   });
  //   // }
  //   currentBooking["date"] = selectedDate;
  //   // setBookingData((prev) => {
  //   //   console.log(currentBooking);
  //   //   return { ...prev, currentBooking };
  //   // });
  // }

  function confirmAndSendAgreement() {
    const socialId = 197201260192;
    const phoneRef = +46707402114;
    const action = "venueSignature";
    const userId = "mdernulf@gmail.com";
    const returnAddress = window.location.href;

    fetch(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/verifyId?socialId=${socialId}&phoneNumber=${phoneRef}&type=${action}&userId=${userId}&bookingId=${currentBookingId}&returnAddress=${returnAddress}&`
    )
      .then((response) => response.json())

      .then((data) => window.open(data.url, "_blank"))
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    checkIsNewBooking();
    // console.log(bookingData)
  }, [bookingModalOpen]);

  useEffect(() => {
    console.log(bookingData);
    // if (bookingData.status === "Confirmed") setFormlock(true);
    // if (bookingData.status !== "Confirmed") setFormlock(false);
  }, [bookingData]);

  return (
    <div>
      <Modal open={bookingModalOpen} onClose={handleClose} aria-labelledby="bookingform" aria-describedby="bookingform">
        <Card sx={{ ...style }}>
          {isNewBooking ? <h4>Create a new Booking</h4> : <h4>Review Booking</h4>}
          {isNewBooking ? (
            <NewBookingStepper modal={handleClose} setBookingData={setBookingData} />
          ) : (
            <>
              <form>
                {bookingData.artist && bookingData.date && (
                  <h3>
                    {bookingData.artist}, {selectedDate}
                  </h3>
                )}
                <Grid container name="BookingForm" item xs={12} style={{ overflow: "scroll", height: "60vh", width: "100%" }} spacing={1}>
                  {currentBookingId > 0 ? (currentBooking.bookingid = currentBookingId) : null}

                  {bookingFormArray.map((formfield) => (
                    <>
                      {/* {console.log(formfield.type)} */}
                      {formfield.type == "Section" && <div className="formSectionLabel">{formfield.section}</div>}

                      {/* {formfield.section != currentSection && formfield.priority < 1000 ?  handleNewSection(formfield.section) : <div></div> } */}
                      <Grid item>
                        {/* {formfield.search ? formfield.search : <div></div>} */}
                        {/* {formfield.priority < 1000 && !formfield.search ? <TextField {...formfield} onChange={(e) => handleChange(formfield.id, e.target.value)}/> : <div></div>} */}

                        {formfield.id == "artistImage" ? (
                          <div
                            className="artistImageB"
                            bookingid={formfield.bookingid}
                            style={{
                              width: "180px",
                              height: "180px",
                              float: "left",
                              marginRight: "20px",
                              resizeMode: "cover",
                              borderRadius: "10px",
                            }}
                          >
                            <img
                              // src={formfield.artistImage}
                              src={bookingData.artistImage}
                              style={{
                                // width: "300px",
                                // height: "auto",
                                // float: "right",
                                overFlow: "hidden",
                              }}
                            />
                          </div>
                        ) : null}

                        {formfield.type == "text" && formfield.priority < 10000 ? (
                          // <TextField
                          //   {...formfield}
                          //   value={bookingData[formfield.id]}
                          //   onChange={(e) => handleChange(formfield.id, e.target.value)}
                          // />
                          <TextField
                            {...formfield}
                            value={bookingData[formfield.id]}
                            onChange={(e) => handleChange(formfield.id, e.target.value)}
                            disabled={formlock}
                          />
                        ) : null}
                        {formfield.type == "phone" && formfield.priority < 10000 ? (
                          <TextField
                            className="bookingFormField"
                            // style={{ color: "transparent", background: "transparent" }}
                            {...formfield}
                            value={bookingData[formfield.id]}
                            onChange={(e) => handleChange(formfield.id, e.target.value)}
                            size="small"
                            disabled={formlock}
                          />
                        ) : null}
                        {/* { formfield.type == 'text' && formfield.priority < 1000 ? <TextField {...formfield} value={bookingData[formfield.id]} onChange={(e) => bookingData[formfield.id] = e.target.value}/> : <div></div>} */}
                        {formfield.type == "number" && formfield.priority < 10000 ? (
                          <TextField
                            {...formfield}
                            value={bookingData[formfield.id]}
                            onChange={(e) => handleChange(formfield.id, e.target.value)}
                            size="small"
                            disabled={formlock}
                          />
                        ) : null}
                        {/* {formfield.type == 'number' && formfield.priority < 1000 ? <TextField {...formfield} value={bookingData[formfield.id]} onChange={(e) => bookingData[formfield.id] = e.target.value}/> : <div></div>} */}
                        {formfield.type == "checkbox" && formfield.priority < 10000 ? (
                          <TextField
                            {...formfield}
                            value={bookingData[formfield.id]}
                            onChange={(e) => handleChange(formfield.id, e.target.value)}
                            disabled={formlock}
                          />
                        ) : null}
                        {formfield.type == "time" &&
                        formfield.priority < 10000 &&
                        formfield.isWeekend === "yes" &&
                        isWeekend(bookingData.date) ? (
                          <TextField
                            {...formfield}
                            size="small"
                            value={bookingData[formfield.id]}
                            style={{ width: "170px" }}
                            onChange={(e) => handleChange(formfield.id, e.target.value)}
                            disabled={formlock}
                          />
                        ) : null}
                        {formfield.type == "time" &&
                        formfield.priority < 10000 &&
                        formfield.isWeekend === "no" &&
                        !isWeekend(bookingData.date) ? (
                          <TextField
                            {...formfield}
                            size="small"
                            value={bookingData[formfield.id]}
                            style={{ width: "170px" }}
                            onChange={(e) => handleChange(formfield.id, e.target.value)}
                            disabled={formlock}
                          />
                        ) : null}

                        {formfield.type == "time" && formfield.priority < 10000 && formfield.isWeekend === "both" ? (
                          <TextField
                            {...formfield}
                            size="small"
                            value={bookingData[formfield.id]}
                            style={{ width: "170px" }}
                            onChange={(e) => handleChange(formfield.id, e.target.value)}
                            disabled={formlock}
                          />
                        ) : null}

                        {/* {formfield.type == 'checkbox' && formfield.priority < 1000 ? <TextField {...formfield} value={bookingData[formfield.id]} onChange={(e) => bookingData[formfield.id] = e.target.value}/> : <div></div>} */}
                        {formfield.type == "select" && formfield.priority < 10000 && bookingData[formfield.id] ? (
                          <Autocomplete
                            id="status-select"
                            value={bookingData[formfield.id]}
                            options={formfield.options}
                            defaultValue={bookingData[formfield.id]}
                            sx={{ width: 300 }}
                            disabled={formlock}
                            renderInput={(params) => <TextField {...params} label={formfield.placeholder} size={"small"} />}
                            onChange={(event, newValue) => {
                              handleChange(formfield.id, newValue);
                            }}
                          />
                        ) : null}

                        {/* {formfield.type == "search" && formfield.priority < 10000 && bookingData[formfield.id] && searchableArtists ? (
                          <Autocomplete
                            freeSolo
                            // onInputChange={(event, newInputValue) => {
                            //   bookingData[formfield.id] = newInputValue;
                            //   console.log(newInputValue);
                            // }}
                            onChange={(event, newValue) => {
                              handleChange("artistid", newValue.id);
                              handleChange("artist", newValue.label);
                              handleChange("image", newValue.image);
                            }}
                            id="controllable-states-demo"
                            getOptionId={(option) => option.id}
                            getOptionLabel={(option) => option.label}
                            options={searchableArtists}
                            // value={bookingData[formfield.id]}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={formfield.placeholder}
                                size={"small"}
                                defaultValue={bookingData[formfield.id]}
                              />
                            )}
                            // renderInput={(params) => (
                            //   <TextField {...params} label={formfield.placeholder} value={bookingData[formfield.id]} variant="standard" />
                            // )}
                          />
                        ) : (
                          <div></div>
                        )} */}
                      </Grid>
                    </>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    background: "#222",
                    height: "30px",
                    width: "100%",
                    zIndex: "10",
                    display: "inline-flex",
                    flexWrap: "wrap",
                    gap: "12px",
                  }}
                >
                  {/* <Stack
                    direction="row"
                    spacing={2}
                    height={10}
                    mt={20}
                    // className="modal-fixed-footer"
                  > */}
                  {bookingData.status === "Pending Request" && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleOpenAgreementModal();
                      }}
                      // onClick={<AgreementModal bookingid={currentBookingId} sign="venueSignature" open={true} />}
                      endIcon={<SendIcon />}
                      size={"small"}
                    >
                      Accept Booking
                    </Button>
                  )}
                  <Button variant="contained" onClick={saveBooking} endIcon={<SendIcon />} size={"small"} disabled={formlock}>
                    Save
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleClose} startIcon={<CloseIcon />} size={"small"}>
                    Close
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleDelete}
                    startIcon={<DeleteIcon />}
                    size={"small"}
                    disabled={formlock}
                  >
                    Kill Booking
                  </Button>

                  {/* </Stack> */}
                </Grid>
              </form>
            </>
          )}
        </Card>
      </Modal>
      <Grid container xs={12} style={{ fontSize: 10 }}></Grid>
      {bookingModalOpen != true ? <ReloadBookings /> : ""}
      {/* {bookingModalOpen != true ? <GetBookingsForCurrentVenue /> : 'open Done!' } */}
      {/* {console.log("current venue", currentVenue)} */}
      {bookingData && (
        <Modal open={agreementModalOpen} onClose={handleCloseAgreementModal} aria-labelledby="bookingform" aria-describedby="bookingform">
          <div
            style={{
              background: "white",
              color: "black",
              width: "90vh",
              height: "90vh",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Review agreement and click "Send"
            {/* <ContractMaker data={bookingData} /> */}
            <AgreementModal bookingid={currentBookingId} sign="venueSignature" open={true} />
            {/* <Button
              style={{ position: "absolute", bottom: "20px", right: "50px", color: "white" }}
              variant="contained"
              color="success"
              onClick={() => {
                confirmAndSendAgreement();
              }}
              startIcon={<SendIcon />}
              size={"small"}
            >
              Sign and send agreement
            </Button> */}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BookingForm;
