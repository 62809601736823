/** @format */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Switch, Modal, Box } from "@mui/material";
import DisplayObject from "./displayObject";
const InRedux = () => {
  const startDate = useSelector((state) => state.counter.startDate);
  const endDate = useSelector((state) => state.counter.endDate);
  // const chosenDateRange = useSelector((state) => state.counter.chosenDateRange);
  const prefDays = useSelector((state) => state.counter.prefDays);
  const ownedVenues = useSelector((state) => state.counter.ownedVenues);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const UIDrawerOpen = useSelector((state) => state.counter.UIDrawerOpen);
  const userRole = useSelector((state) => state.counter.userRole);
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const themeMode = useSelector((state) => state.counter.venueAccess);
  const monthDisplayed = useSelector((state) => state.counter.monthDisplayed);
  const currentBookingId = useSelector((state) => state.counter.currentBookingId);
  const artists = useSelector((state) => state.counter.artists);
  const currentEditors = useSelector((state) => state.counter.currentEditors);
  const currentMonth = useSelector((state) => state.counter.currentMonth);
  const currentProduct = useSelector((state) => state.counter.currentProduct);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  return (
    <div>
      <Switch
        id="inReduxSwitch"
        size="small"
        checked={open}
        // defaultChecked={false}
        onChange={(e) => setOpen(e.target.checked)}
      />

      <Modal open={open} onClose={handleClose} aria-labelledby="inredux-title" aria-describedby="inredux-description">
        <Box sx={style}>
          <div>
            <h2>InRedux</h2>
            <div>StartDate: {startDate}</div>
            <div>EndDate: {endDate}</div>
            {/* <div>chosenDateRange: {chosenDateRange}</div> */}
            <div>prefDays:{prefDays}</div>
            {currentVenue.venue && <div>currentVenue: {currentVenue.venue}</div>}
            <div>UIDrawerOpen:{UIDrawerOpen}</div>
            <div>userRole:{userRole}</div>
            {/* <div>ownedVenues:{ownedVenues}</div> */}
            {venueAccess && (
              <div>
                venueAccess({venueAccess.length}):{venueAccess}
              </div>
            )}
            <div>currentUser:{console.log("currentUser", currentUser)}</div>
            {/* <div>themeMode:{themeMode}</div>
						<div>monthDisplayed:{monthDisplayed}</div>
						<div>currentBookingId:{currentBookingId}</div> */}
            <div>
              Artists:
              {artists.map((artist) => artist.artist + ", ")}
            </div>
            <div>
              Editors:{currentEditors.length}
              {currentEditors.map((e) => (
                <div>{e.name + ", " + e.phone + ", " + e.email + ", " + e.venueAccess.map((v) => v + ", ")}</div>
              ))}
            </div>
            <div>currentMonth:{currentMonth}</div>
            {/* prefDays: {prefDays != null ? prefDays.map((item) => <>{item}</>) : <>none</>} */}
            ownedVenues({ownedVenues.length}):{ownedVenues}
            {/* venueAccess:{venueAccess && venueAccess.map((item) => item)} */}
          </div>
          <div>
            <h6>Current Product:</h6> <DisplayObject data={currentProduct} />
          </div>
          <h2>venue phone: {currentVenue.phone}</h2>
        </Box>
      </Modal>
    </div>
  );
};

export default InRedux;
