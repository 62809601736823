/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Switch } from "@mui/material";
import React from "react";
import VatSelector from "../MiscUIComponents/VatSelector";

const RentalFeeModule = ({ featureColor, feature, handleSelect, handleChangeValue, getIcon }) => {
  return (
    <>
      {/* <Accordion> */}
      {/* <AccordionSummary> */}
      <h6 style={{ textAlign: "left", color: featureColor }}>{getIcon(feature.additional)} Rental fee</h6>
      {/* </AccordionSummary> */}
      {/* <AccordionDetails> */}
      <table>
        <thead>
          <th>Select</th>
          <th>Option</th>
          <th>Price</th>
          <th>Vat</th>
          <th>CO2e</th>
        </thead>
        {feature.options &&
          Object.values(feature.options).map((option) => (
            <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
              <td>
                <Switch
                  onChange={(event) =>
                    handleSelect(
                      event.target.checked,
                      feature.additionalId,
                      option.optionId
                      // "unique"
                    )
                  }
                  checked={option.selected ? true : false}
                />
              </td>
              <td>{option.option}</td>
              <td>
                <input
                  disabled={!option.selected}
                  type="number"
                  value={option.price}
                  onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value)}
                />
              </td>
              <td>
                <VatSelector handleChangeOption={handleChangeValue} value={option.vat} item={feature} optionId={option.optionId} />
              </td>
              <td>{option.climate}</td>
            </tr>
          ))}
      </table>
      {/* </AccordionDetails> */}
      {/* </Accordion> */}
    </>
  );
};

export default RentalFeeModule;
