/** @format */

import { Button, Grid, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { now } from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreImageFromUrl } from "../components/UI helpers/StoreImageFromUrl";
import SetImage from "../features/Admin Backend/SetImage";
import { storeCurrentArtist } from "../features/venueFilter/venueFilterSlice";
import { createId, db } from "../firebase";
import { today } from "../helpers/DateManagement";
import DisplayObject from "../helpers/displayObject";
import FindArtist from "../helpers/FindArtists";
import GetArtists from "../helpers/GetArtists";

const unofficialArtistid = createId();

const SetEventInfo = () => {
  const artists = useSelector((state) => state.counter.artists);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const [event, setEvent] = useState({ artist: "", artistid: "", image: "" });
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     console.log(event.image);
  //     console.log(event.artistid, unofficialArtistid);
  //     // StoreImageFromUrl(event.image, "eventImages", unofficialArtistid);
  //     event.image && StoreImageFromUrl(event.image, "eventImages", unofficialArtistid);
  //   }, [event.image]);

  useEffect(() => {
    //if not a stored artist
    event.artistid === null && handleUnofficialArtistCreation();

    async function handleUnofficialArtistCreation() {
      dispatch(storeCurrentArtist({ ...event, isOfficial: false, artistid: unofficialArtistid, documentid: unofficialArtistid }));
      await setDoc(doc(db, "Artists", unofficialArtistid), {
        ...event,
        isOfficial: false,
        artistid: unofficialArtistid,
        documentid: unofficialArtistid,
        email: currentUser.email,
        agent: currentUser.userid,
      });
    }

    //if artist is stored and available
    event.artistid !== null &&
      event.artistid !== unofficialArtistid &&
      dispatch(storeCurrentArtist(artists[artists.findIndex((x) => x.id === event.artistid)]));
  }, [event.artist + event.image]);

  return (
    <>
      <GetArtists />
      {/* <DisplayObject data={currentUser} /> */}
      <Grid
        container
        xs={12}
        //   className="availsSelectArtist"
        // spacing={4}
        mt={4}
      >
        <h6>Choose artist or name the event...</h6>
        <Grid item xs={12}>
          <FindArtist setEvent={setEvent} />
        </Grid>
        {event.artistid === null && (
          <>
            <Grid item xs={8} mt={2}>
              <SetImage setEvent={setEvent} incomingQuery={event.artist} />
            </Grid>
            {/* <Grid item xs={4} mt={2.5}>
              <Button variant="outlined" style={{ marginLeft: "10px" }} size="small">
                Search
              </Button>
            </Grid> */}
          </>
        )}
        <Grid item mt={4} xs={12}>
          <h4>{event.artist}</h4>
          <div style={{ filter: "blur(20px)", float: "left", height: "80px", width: "auto", borderRadius: "50px", overflow: "hidden" }}>
            <img src={event.image} height="100px" width="auto" />
          </div>
          <div style={{ position: "absolute", height: "80px", width: "auto", borderRadius: "50px", overflow: "hidden" }}>
            <img src={event.image} height="100px" width="auto" />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SetEventInfo;
