/** @format */

import { CircularProgress, Grid, Modal } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BookingFormGeneral from "../components/Forms/BookingFormGeneral";
import { storeCurrentBooking } from "../features/venueFilter/venueFilterSlice";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginMUI from "../components/Authentication/LoginMUI";

const BookingPreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingid = queryParams.get("bookingid");
  const userEmail = queryParams.get("userid");
  //   const booking = async () => getDoc(doc(db, "Bookings", "gj2yscsy4et6hqcusurzlsftr64p4kgz"));
  const [booking, setBooking] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const userRole = useSelector((state) => state.counter.userRole);
  const [openLogin, setOpenLogin] = useState(false);

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
      navigate("/Owner/Home");
    }, 10);
  };

  useEffect(() => {
    currentUser.email === userEmail ? setOpenLogin(false) : setOpenLogin(true);
  }, []);

  useEffect(() => {
    async function getData() {
      setLoaded(false);
      const docSnap = await getDoc(doc(db, "Bookings", bookingid));
      setBooking(docSnap.data());
      setOpen(true);
      setLoaded(true);
    }
    currentUser.email === userEmail && getData();
  }, [currentUser.email]);

  console.log("👍 BOOKINGID", bookingid);
  console.log("👍 BOOKING", booking);
  return (
    <Grid p={0} mt={0}>
      <LoginMUI openLogin={openLogin} setOpenLogin={setOpenLogin} userName={userEmail} />
      <h1>BookingPreview</h1>
      {!loaded && <CircularProgress />}
      {/* <Grid item>{loaded && <BookingFormGeneral currentBooking={booking} />}</Grid> */}
      <Modal
        className="bookingFormModal"
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="bookingFormModal"
        sx={{ borderRadius: "20px" }}
      >
        {booking && <BookingFormGeneral currentBooking={booking} handleClose={handleClose} />}
      </Modal>
    </Grid>
  );
};

export default BookingPreview;
