/** @format */

export const calculateAdditionalCosts = (summaryData, type) => {
  // console.log("bookingFeatures", bookingFeatures, type);
  let tmpIncludedPostsSum = [];
  // This too is only applicable when bookingFeatures are set.
  if (!summaryData || summaryData === "-") return 0;
  summaryData.map((post) => {
    const result = post.cost;
    result !== undefined && result > 0 && tmpIncludedPostsSum.push({ amount: Number(result), vat: post.vat });
  });

  const totalsByVat = tmpIncludedPostsSum.reduce((acc, { amount, vat }) => {
    if (vat === undefined) vat = "25%";
    vat = parseFloat(vat.replace("%", ""));
    if (acc[vat]) {
      acc[vat] += (amount * vat) / 100; // If VAT exists, add to its total
    } else {
      acc[vat] = (amount * vat) / 100; // Otherwise, initialize it
    }
    return acc;
  }, {});

  const totalSum = tmpIncludedPostsSum.reduce((acc, { amount }) => acc + amount, 0);
  const totalVat = Object.values(totalsByVat).reduce((acc, value) => acc + value, 0);

  // return by type (default without type is totalSum without VAT included)
  if (type === "totalSum" || type === undefined) return totalSum; // Returns total sum without VAT
  if (type === "totalVat") return totalVat; // Returns total VAT
  if (type === "totalIncVat") return totalSum + totalVat; // Returns total sum with VAT included
  if (type === "totalsByVat") return totalsByVat; // Returns an object with VAT as keys and total VAT as values
};
