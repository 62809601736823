/** @format */

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { CheckBox } from "@mui/icons-material";

const CreateTicketCategoriesNortic = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const settings = { size: "small", width: 3, margin: "normal" };
  const [loading, setLoading] = useState(false);
  // const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: currentVenue.ticketVenueId });
  const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: 3305 });
  const [ticketCategories, setTicketCategories] = useState([]);
  async function getTicketCategories() {
    setLoading(true);
    const data = { organizerId: newTicketCategory.organizerId };
    console.log("Data", data);
    try {
      // console.log("Body", body);

      const result = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicketCategoriesNortic", data);
      console.log("Ticket Categories", result.data);
      setTicketCategories(result.data.values);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!newTicketCategory.organizerId) return;
    getTicketCategories();
  }, [newTicketCategory.organizerId]);

  async function createTicketCategory() {
    setLoading(true);
    const body = {
      name: newTicketCategory.name,
      //   sortWeight: newTicketCategory.sortWeight || 0,
      // price: newTicketCategory.price,
      // maxAvailable: newTicketCategory.maxAvailable,
      sortWeight: 0,
      organizerId: newTicketCategory.organizerId,
    };

    try {
      console.log("Body creating", body);
      const result = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/createTicketCategoryNortic", { body });
      await getTicketCategories();
      console.log("Ticket Category created", result.data);
      console.log("Bodyn", body);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      {/* {ticketCategories.length &&
        ticketCategories.map((category) => (
          <div key={category.id}>
            <FormControlLabel control={<Radio label={category.name} />} />
            {category.name}
          </div>
        ))} */}

      {/* <Button variant="outlined" onClick={() => getTicketCategories()}>
        {loading && <CircularProgress style={{ zoom: "0.4" }} />} Get Ticket Categories
      </Button> */}

      <Grid container gap="15px" alignItems="center">
        <Grid item width={150}>
          <TextField
            label="Name"
            type="text"
            {...settings}
            value={newTicketCategory.name}
            onChange={(e) => setNewTicketCategory((previous) => ({ ...previous, name: e.target.value }))}
          />
        </Grid>
        <TextField
          label="Price"
          type="text"
          {...settings}
          value={newTicketCategory.price}
          onChange={(e) => setNewTicketCategory((previous) => ({ ...previous, price: e.target.value }))}
        />
        <TextField
          label="Max Available"
          type="number"
          {...settings}
          value={newTicketCategory.maxAvailable}
          onChange={(e) => setNewTicketCategory((previous) => ({ ...previous, maxAvailable: e.target.value }))}
        />

        {/* <Grid item>
          <TextField
            label="sortWeight"
            type="number"
            value={newTicketCategory.sortWeight}
            {...settings}
            onChange={(e) => setNewTicketCategory((previous) => ({ ...previous, sortWeight: e.target.value }))}
          />
        </Grid> */}
        {/* <Grid item>
          <TextField label="organizerId" value={newTicketCategory.organizerId} type="text" {...settings} />
        </Grid> */}
        <Grid item>
          <IconButton onClick={() => createTicketCategory()}>
            {loading ? <CircularProgress style={{ zoom: "0.4" }} /> : <AddCircleOutlineIcon />}
          </IconButton>
        </Grid>
      </Grid>
      {/* <Button variant="outlined" onClick={() => createTicketCategory()}>
        {loading && <CircularProgress style={{ zoom: "0.4" }} />} Create Ticket Category
      </Button> */}
      {/* <IconButton>
        <AddCircleOutlineIcon />
      </IconButton> */}
    </div>
  );
};

export default CreateTicketCategoriesNortic;
